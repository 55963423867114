export const REFERRAL_STATUS_TABLE_HEAD = [
    {
        field: "serialNumber",
        Width: 20,
        headerName: "slno",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "referralId",
        Width: 50,
        headerName: "Referral ID",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "leadName",
        width: 180,
        headerName: "Name of student",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "class",
        width: 180,
        headerName: "student Class",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "prefferedCourse",
        headerName: "Preffered Course",
        width: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "parentName",
        width: 180,
        headerName: "Parent name",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "mobileNumber",
        width: 180,
        headerName: "Mobile Number",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "note",
        width: 180,
        headerName: "Note",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "locality",
        width: 180,
        headerName: "Locality",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "referredOn",
        width: 180,
        headerName: "Referred On",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "status",
        width: 100,
        headerName: "Curent status",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "lastUpdatedBy",
        width: 180,

        headerName: "Last updated By",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "lastUpdatedOn",
        width: 180,
        headerName: "Last updated On",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
];
