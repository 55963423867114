import { Icon } from "@iconify/react/dist/iconify.js"


export const Active = () => {
    return (
      <>
      <Icon
        icon="material-symbols:done-all-rounded"
        width="20"
        height="20"
        style={{
          color: ' #2acc1e',
          marginRight: '4px',
        }}
      /> Active
    </>
    )
  }

 export const Inactive = () => {
  return (
    <>
    <Icon
      icon="material-symbols:remove-done-rounded"
      width="20"
      height="20"
      style={{
        color: ' #d72323',
        marginRight: '4px',
      }}
    /> Inactive
  </>
  )
}