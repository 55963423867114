import { useEffect, useState } from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Container } from "@mui/system";
// others

import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { fetchStudents } from "../../redux/features/studentsSlice";
import Page from "../../components/Page";
import { MY_STUDENTS_TABLE_HEAD as columns } from "../../utils/constants/TableColumns";
import CustomGridToolbar from "../../components/datagrid/GridToolBar";
import Iconify from "../../components/Iconify";
import Breadcrumb from "../../components/breadcrumb/BreadCrumb";
import useResponsive from "../../Hooks/useResponsive";

export default function MyStudentsTable() {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const students = useSelector((state) => state.students);
    const [gridSize, setGridSize] = useState(5);

    useEffect(() => {
        dispatch(fetchStudents());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page title="My Students">
            {smUp && (
                <Container component="main">
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        My Students
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />

                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%" }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                height: 44,
                            }}
                        ></Box>
                        <Box sx={{ width: "100%" }}>
                            <DataGrid
                                loading={students.loading}
                                rows={students.data}
                                columns={columns}
                                sx={{
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                }}
                                pageSize={gridSize}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                                autoHeight
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                getRowId={(row) => row.serialNumber}
                                components={{ Toolbar: CustomGridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                </Container>
            )}

            {!smUp && (
                <>
                    <Typography variant="h4" gutterBottom>
                        My Students
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />

                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%" }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                height: 44,
                            }}
                        ></Box>
                        <Box sx={{ width: "100%" }}>
                            <DataGrid
                                loading={students.loading}
                                rows={students.data}
                                columns={columns}
                                pageSize={5}
                                autoHeight
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                // checkboxSelection
                                // disableSelectionOnClick
                                getRowId={(row) => row.serialNumber}
                                components={{ Toolbar: CustomGridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                </>
            )}
        </Page>
    );
}
