import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

export default function InterviewAction(params) {
    return (
        <div>
            <Button
                disabled={params.row.CVStatus === "Selected" ? false : true}
                sx={{ backgroundColor: "#eff4f9" }}
                component={Link}
                to={`/my-workspace/interviewprocedure/${params.row.id}`}
            >
                <Icon
                    icon="material-symbols:communication"
                    width="30"
                    height="30"
                />
                Take Interview
            </Button>
        </div>
    );
}
