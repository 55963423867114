import React from "react";
import { Paper, Box, Stack, Avatar, Typography, Chip } from "@mui/material";
import useResponsive from "../../../../../Hooks/useResponsive";

export const TerminationClearance = ({ data }) => {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  return (
    <div>
      <Box
        sx={{
          backgroundColor: "#013a64",
          height: 200,
          width: "100%",
          borderRadius: 3,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Paper
            elevation={3}
            sx={{
              width: smUp ? 500 : "100%",
              ":hover": {
                boxShadow: 20, // theme.shadows[20]
              },
              marginTop: 10,
              backgroundColor: "grey.200",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={{ margin: 3 }}>
              <Stack direction={"column"}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Avatar alt="Remy Sharp" sx={{ width: 56, height: 56 }} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="h6">
                    {data?.studentName ? data.studentName : "No data Found...!"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="body2">
                    {data?.courseName ? data.courseName : "No data Found...!"}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography variant="title">
                    <Chip
                      size="small"
                      color="primary"
                      variant="filled"
                      label={`AD NO -  ${
                        data?.admnNo ? data.admnNo : "No data Found...!"
                      } `}
                    />
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};
