import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
    Button,
    Stack,
    TextField,
    Card,
    Link,
    Typography,
    Container,
} from "@mui/material";
import useResponsive from "../../../../Hooks/useResponsive";
import Logo from "../../../../components/color-utils/Logo";
import OtpModal from "../../../../components/modal/OtpModal";
import { Box } from "@mui/system";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { getOtp } from "../../../../redux/features/authSlice";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import genieImg from "../../../../img/map.gif";
import { motion, useAnimation } from "framer-motion";
import forgotPass from "../../../../img/Forgot_password.png";

const ForgotPassword = () => {
    const controls = useAnimation();
    const smUp = useResponsive("up", "sm");

    const [isModal, setModal] = useState(false);
    const [email, setEmail] = useState("");

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.login.isLoading);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    //--------styling--------//

    const RootStyle = {
        display: "flex",
    };
    const ContentStyle = {
        maxWidth: "480px",
        margin: "auto",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "12px 0px",
    };
    const SectionStyle = styled(Card)(({ theme }) => ({
        width: "100%",
        maxWidth: 464,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: theme.spacing(2, 0, 2, 2),
    }));
    const HeaderStyle = styled("header")(({ theme }) => ({
        top: 0,
        zIndex: 9,
        lineHeight: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        position: "absolute",
        padding: theme.spacing(3),
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
            alignItems: "flex-start",
            padding: theme.spacing(7, 5, 0, 7),
        },
    }));

    //--------styling ends--------//

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("Email is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: email,
            remember: true,
        },
        validationSchema: LoginSchema,
        onSubmit: async (e) => {
            // setModal(true);

            dispatch(
                getOtp({
                    email: e.email,
                })
            ).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message);
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message);
                    setModal(true);
                }
            });
        },
    });
    const { errors, touched, handleSubmit, getFieldProps } = formik;
    // ------ otp modal setup ----

    const handleClose = () => {
        setModal(false);
    };

    const mdUp = useResponsive("up", "md");
    const keyframesExample = {
        hidden: { opacity: 0, x: -100 },
        halfway: { opacity: 0.5, x: 50 },
        visible: { opacity: 1, x: 0 },
    };
    const formKeyFrames = {
        hidden: { opacity: 0, y: -100 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.5, ease: "easeInOut" },
        },
    };
    return (
        <Box sx={RootStyle}>
            <HeaderStyle>
                <Stack direction={"row"}>
                    <Box sx={{ paddingTop: 1.6 }}>
                        <motion.div animate={controls}>
                            <Logo />
                        </motion.div>
                    </Box>

                    <Box>
                        <img
                            src={genieImg}
                            alt="login Img"
                            height={50}
                            width={50}
                        />
                    </Box>
                </Stack>
                {smUp && (
                    <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                        Back To Login Page
                        <Link
                            variant="subtitle2"
                            component={RouterLink}
                            to="/auth/login"
                        >
                            Login
                        </Link>
                    </Typography>
                )}
            </HeaderStyle>

            {mdUp && (
                <SectionStyle>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={keyframesExample}
                    >
                        <Typography variant="h3" sx={{ px: 5, mt: 15, mb: 1 }}>
                            Trouble to remember Your Password..?
                        </Typography>
                        <img src={forgotPass} alt="login" />
                    </motion.div>
                </SectionStyle>
            )}
            <Container>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={formKeyFrames}
                >
                    <Box sx={ContentStyle}>
                        <Typography variant="h5" gutterBottom>
                            Forgot Password...!
                        </Typography>

                        <Typography
                            sx={{ color: "text.secondary", mb: 3, ml: 1 }}
                        >
                            Enter your email below.
                        </Typography>

                        <FormikProvider value={formik}>
                            <Form onSubmit={handleSubmit}>
                                <Stack
                                    sx={{ width: "500px" }}
                                    direction="column"
                                    gap={2}
                                >
                                    <TextField
                                        name="email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        type="email"
                                        label="Email address"
                                        {...getFieldProps("email")}
                                        helperText={
                                            touched.email && errors.email
                                        }
                                        error={Boolean(
                                            touched.email && errors.email
                                        )}
                                    />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        type="submit"
                                    >
                                        {loading
                                            ? "Getting otp...."
                                            : "Get Otp"}
                                    </Button>
                                    {!smUp && (
                                        <Typography
                                            variant="body2"
                                            sx={{ mt: 3, textAlign: "center" }}
                                        >
                                            Back to Login{" "}
                                            <Link
                                                variant="subtitle2"
                                                to="auth/login"
                                                component={RouterLink}
                                            >
                                                Login
                                            </Link>
                                        </Typography>
                                    )}
                                </Stack>
                            </Form>
                        </FormikProvider>
                        {isModal && (
                            <OtpModal
                                isModal={isModal}
                                handleClose={handleClose}
                            />
                        )}
                    </Box>
                </motion.div>
            </Container>
        </Box>
    );
};

export default ForgotPassword;
