import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import {
  Paper,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material";
import { Container } from "@mui/system";
import { editNotification } from "../../../redux/features/notificationSlice";
import Page from "../../../components/Page";
import { getNotificationByID } from "../../../redux/features/notificationSlice";
import useResponsive from "../../../../src/Hooks/useResponsive";
import BasicButton from "../../../components/mui/Button";
import BasicTextField from "../../../components/mui/TextField";

export default function EditNotification() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const notification = useSelector((state) => state.notification);
  const [formData, setFormData] = useState({
    type: "CUSTOM_SINGLE",
    title: "",
    content: "",
  });
  useEffect(() => {
    dispatch(getNotificationByID(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        type: notification.single.type,
        title: notification.single.title,
        content: notification.single.content,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification.single]);

  const handleType = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        type: e.target.value,
      };
    });
  };
  const handleTitle = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        title: e.target.value,
      };
    });
  };
  const handleContent = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        content: e.target.value,
      };
    });
  };
  const clickHandler = (e) => {
    e.preventDefault();
    const finalData = {
      ...formData,
      id: id,
    };
    dispatch(editNotification(finalData)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        enqueueSnackbar(res.payload.message, { variant: "success" });
        navigate("/notifications/view", { replace: true });
      }
    });
  };

  return (
    <Page title="Create Notification">
      {smUp && (
        <Container component="main">
          {/*..................................HEADING.............................*/}
          <Stack direction="column" mb={5}>
            <Typography variant="h4" gutterBottom>
              Edit Notification Template
            </Typography>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper elevation={3} sx={{ width: 776, p: 7 }}>
              <Stack direction="column">
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{ display: "flex", pb: 2 }}
                >
                  {/* TYPE SELECT BOX */}
                  {notification.loading ? (
                    <FormControl sx={{ width: 350 }}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: 56, borderRadius: 1 }}
                      />
                    </FormControl>
                  ) : null}
                  {!notification.loading && notification.single?.type ? (
                    <FormControl sx={{ flex: 1 }}>
                      <InputLabel id="notification-type-field">
                        Select Type
                      </InputLabel>
                      <Select
                        labelId="notification-type-field"
                        id="notification-select"
                        label="Select Type"
                        value={formData.type || "CUSTOM_SINGLE"}
                        onChange={handleType}
                      >
                        <MenuItem value="BROADCAST">Broadcast</MenuItem>
                        <MenuItem value="CUSTOM_GROUP">Group</MenuItem>
                        <MenuItem value="CUSTOM_SINGLE">Single</MenuItem>
                      </Select>
                    </FormControl>
                  ) : null}
                  {notification.loading ? (
                    <FormControl sx={{ width: 350 }}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: 56, borderRadius: 1 }}
                      />
                    </FormControl>
                  ) : null}
                  {!notification.loading && notification.single?.title ? (
                    <BasicTextField
                      sx={{ flex: 2 }}
                      id="outlined-basic"
                      value={formData.title || ""}
                      label="Notification Title"
                      variant="outlined"
                      action={handleTitle}
                    />
                  ) : null}
                </Stack>
                {notification.loading ? (
                  <FormControl sx={{ width: 350 }}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: "100%", height: 56, borderRadius: 1 }}
                    />
                  </FormControl>
                ) : null}
                {!notification.loading && notification.single?.content ? (
                  <BasicTextField
                    value={formData.content || ""}
                    label="Content"
                    multiline
                    rows={4}
                    sx={{ pb: 4 }}
                    action={handleContent}
                  />
                ) : null}
              </Stack>
              <Stack direction={"row"} justifyContent="flex-end" spacing={2}>
                <BasicButton
                  component={Link}
                  to={`/notifications/view`}
                  variant="outlined"
                >
                  Cancel
                </BasicButton>
                <BasicButton
                  action={(e) => {
                    clickHandler(e);
                  }}
                  variant="contained"
                  size="small"
                >
                  {notification.loading ? "Saving..." : "Save"}
                </BasicButton>
              </Stack>
            </Paper>
          </Box>
        </Container>
      )}
      {!smUp && (
        <Container component="main">
          {/*..................................HEADING.............................*/}
          <Stack direction="column" mb={5}>
            <Typography variant="h4" gutterBottom>
              Edit Notification Template
            </Typography>
          </Stack>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper elevation={3} sx={{ width: 776, p: 7 }}>
              <Stack direction="column">
                <Stack
                  direction={"column"}
                  spacing={2}
                  sx={{ display: "flex", pb: 2 }}
                >
                  {/* TYPE SELECT BOX */}
                  {notification.loading ? (
                    <FormControl sx={{ width: 350 }}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: 56, borderRadius: 1 }}
                      />
                    </FormControl>
                  ) : null}
                  {!notification.loading && notification.single?.type ? (
                    <FormControl sx={{ flex: 1 }}>
                      <InputLabel id="notification-type-field">
                        Select Type
                      </InputLabel>
                      <Select
                        labelId="notification-type-field"
                        id="notification-select"
                        label="Select Type"
                        value={formData.type || "CUSTOM_SINGLE"}
                        onChange={handleType}
                      >
                        <MenuItem value="BROADCAST">Broadcast</MenuItem>
                        <MenuItem value="CUSTOM_GROUP">Group</MenuItem>
                        <MenuItem value="CUSTOM_SINGLE">Single</MenuItem>
                      </Select>
                    </FormControl>
                  ) : null}
                  {notification.loading ? (
                    <FormControl sx={{ width: 350 }}>
                      <Skeleton
                        variant="rectangular"
                        sx={{ width: "100%", height: 56, borderRadius: 1 }}
                      />
                    </FormControl>
                  ) : null}
                  {!notification.loading && notification.single?.title ? (
                    <BasicTextField
                      sx={{ flex: 2 }}
                      id="outlined-basic"
                      value={formData.title || ""}
                      label="Notification Title"
                      variant="outlined"
                      action={handleTitle}
                    />
                  ) : null}
                </Stack>
                {notification.loading ? (
                  <FormControl sx={{ width: 350 }}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: "100%", height: 56, borderRadius: 1 }}
                    />
                  </FormControl>
                ) : null}
                {!notification.loading && notification.single?.content ? (
                  <BasicTextField
                    value={formData.content || ""}
                    label="Content"
                    multiline
                    rows={4}
                    sx={{ pb: 4 }}
                    action={handleContent}
                  />
                ) : null}
              </Stack>
              <Stack direction={"row"} justifyContent="flex-end" spacing={2}>
                <BasicButton
                  component={Link}
                  to={`/notifications/view`}
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </BasicButton>

                <BasicButton
                  action={(e) => {
                    clickHandler(e);
                  }}
                  variant="contained"
                  size="small"
                >
                  Save
                </BasicButton>
              </Stack>
            </Paper>
          </Box>
        </Container>
      )}
    </Page>
  );
}
