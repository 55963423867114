import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const ListItem = ({ keyName, val , variant = "h6", fontFamily = "default", fontWeight=500, fontSize = "inherit"}) => {
    return (
        <Box>
            <Stack direction={"row"} spacing={1} pt={1}>
                <Typography  variant={variant} sx={{ fontFamily: fontFamily,fontSize: fontSize , fontWeight: fontWeight  }}>{keyName} :</Typography>
                <Typography  variant={variant} sx={{ fontFamily: fontFamily ,fontSize: fontSize, fontWeight: fontWeight }}>{val}</Typography>
            </Stack>
        </Box>
    );
};

export default ListItem;
