// @mui
import PropTypes from "prop-types";
import { Card, Typography, CardHeader, CardContent } from "@mui/material";
import { format, getTime, formatDistanceToNow } from "date-fns";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from "@mui/lab";
// utils

// ----------------------------------------------------------------------

InterviewTimeLine.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export default function InterviewTimeLine({
  title,
  subheader,
  list,
  ...other
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent
        sx={{
          "& .MuiTimelineItem-missingOppositeContent:before": {
            display: "none",
          },
        }}
      >
        <Timeline>
          {list.map((item, index) => (
            <OrderItem
              key={item.id}
              item={item}
              isLast={index === list.length - 1}
            />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  isLast: PropTypes.bool,
  item: PropTypes.shape({
    time: PropTypes.instanceOf(Date),
    title: PropTypes.string,
    type: PropTypes.string,
  }),
};

function OrderItem({ item, isLast }) {
  const { type, color, title, time, status, lastUpdatedOn, lastUpdatedBy } =
    item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={color} />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>

      <TimelineContent>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {fDateTime(time)}
        </Typography>
        <br />
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {lastUpdatedBy}
        </Typography>
        <br />
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {lastUpdatedOn}
        </Typography>
        <br />
        {status === "Selected" ||
        status === "Qualified" ||
        status === "Accepted" ||
        status === "Shortlisted" ? (
          <Typography
            variant="caption"
            sx={{ color: "#54d62c", fontWeight: "bolder" }}
          >
            {status}
          </Typography>
        ) : null}
        {status === "Pending" ||
        status === "Future Reference" ||
        status === "Test not Attended" ||
        status === "Interview Not Attended" ? (
          <Typography
            variant="caption"
            sx={{ color: "#efdd0b", fontWeight: "bolder" }}
          >
            {status}
          </Typography>
        ) : null}
        {status === "Rejected" || status === "Not Interested" ? (
          <Typography
            variant="caption"
            sx={{ color: "#e43c2f", fontWeight: "bolder" }}
          >
            {status}
          </Typography>
        ) : null}
      </TimelineContent>
    </TimelineItem>
  );
}
