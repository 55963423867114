import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
    getSingleUser,
    getPreData,
    editUser,
    getRolesOfUser,
    clearSingleUser,
} from "../../../redux/features/userSlice";
import { useParams, useNavigate } from "react-router-dom";
import {
    Paper,
    Typography,
    Container,
    Box,
    Stack,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Button,
    Skeleton,
    Divider,
    Avatar,
} from "@mui/material";
import Page from "../../../components/Page";
import { useFormik, Form, FormikProvider } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { useSnackbar } from "notistack";
import useResponsive from "../../../../src/Hooks/useResponsive";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import AddRole from "./AddRole";
import loader from "../../../Loader/loader.gif";

import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";

export default function EditEmployee() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const { loading } = useSelector((state) => state.users);
    const preData = useSelector((state) => state.users.preData);
    const singleUser = useSelector((state) => state.users.singleUser);
    const role = useSelector((state) => state.users.roles);
    const [data, SetData] = useState({});

    useEffect(() => {
        dispatch(getPreData());
        dispatch(getRolesOfUser(params.id));
        return () => dispatch(clearSingleUser());
    }, []);
    useEffect(() => {
        console.log(singleUser);
    }, [singleUser]);

    useEffect(() => {
        dispatch(getSingleUser(params.id));
    }, [preData]);

    useEffect(() => {}, [singleUser]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: singleUser?.name || "",
            phone: singleUser?.phone || "",
            departmentId: singleUser?.departmentId || "",
            userTypeId: singleUser?.roles || [],
            reportingHeadId: singleUser?.reportingHeadId || "",
            employmentTypeId: singleUser?.employmentTypeId || "",
            employmentStatusId: singleUser?.employmentStatusId || "",
            probationCompletedOn: singleUser?.probationCompletedOn || "",
            joinedOn: singleUser?.joinedOn || "",
            loginStatus: singleUser?.loginStatus || "",
            officeId: singleUser?.officeId || "",
            funnelId: singleUser?.funnelId || "",
            id: singleUser?.id ? singleUser.id : "",
        },
        // validationSchema: EditSchema,
        onSubmit: (values) => {
            const { phone, name, ...others } = values;

            dispatch(editUser(others)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });

                    navigate("/my-tasks/employees", { replace: true });
                }
            });
        },
    });

    const handleSelect = (e) => {
        const obj = preData.designations.filter((item) => {
            return item.usertypeID === e.target.value;
        });

        let arry = [...formik.values.userTypeId];
        arry.push(obj[0]);

        formik.setFieldValue("userTypeId", arry);
    };

    return (
        <div>
            {loading ? (
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "20%",
                        }}
                    >
                        <img src={loader} alt="loading..." />
                    </Box>
                </Box>
            ) : (
                <Page title="Edit Employee">
                    {smUp && (
                        <Container component="main">
                            {/*..................................HEADING.............................*/}
                            <Stack direction="column" mb={5}>
                                <Typography variant="h4" gutterBottom>
                                    Edit Employee
                                </Typography>
                                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                            </Stack>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        width: "100%",
                                        borderTopRightRadius: 20,
                                        borderTopLeftRadius: 20,
                                    }}
                                >
                                    {/* -------------------------------------------------------------------------------------------------- */}
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            width: "100%",
                                            height: 220,
                                            backgroundSize: "contain",
                                            backgroundSize: "cover",
                                            backgroundImage: `url(${"https://media.istockphoto.com/id/1029971592/vector/abstract-digital-landscape-or-waves-with-flowing-particles.jpg?s=612x612&w=0&k=20&c=LuT34-x9m5pEv93ixvn72cfRMIx30R1eoC9Y5X_-XbY="})`,
                                            borderTopRightRadius: 20,
                                            borderTopLeftRadius: 20,
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                        }}
                                    >
                                        <Box paddingTop={12} paddingLeft={5}>
                                            <Stack direction={"row"}>
                                                <Avatar
                                                    src={`https://workspace.teaminterval.net/assets/employee/photo/passport_size/${singleUser?.avatar}.jpg`}
                                                    variant="square"
                                                    sx={{
                                                        width: 130,
                                                        height: 150,
                                                        border: 3,
                                                        borderColor: "#e5e8eb",
                                                        borderTopRightRadius: 20,
                                                        borderTopLeftRadius: 20,
                                                    }}
                                                />
                                                <Box margin={4}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            mb: 1,
                                                            color: "grey.200",
                                                        }}
                                                    >
                                                        {singleUser?.name || ""}
                                                    </Typography>
                                                    <Stack
                                                        direction={"row"}
                                                        spacing={1}
                                                    >
                                                        <Typography
                                                            // variant="h6"
                                                            color="white"
                                                            textTransform="capitalize"
                                                        >
                                                            {singleUser?.phone ||
                                                                ""}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Paper>
                                    {/* -------------------------------------------------------------------------------------------------------- */}

                                    <Box padding={3}>
                                        <FormikProvider value={formik}>
                                            <Form
                                                onSubmit={formik.handleSubmit}
                                            >
                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                    padding={2}
                                                >
                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Department
                                                            </InputLabel>
                                                            <Select
                                                                name="departmentId"
                                                                variant="filled"
                                                                label="Department"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .departmentId ||
                                                                    ""
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "departmentId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                disabled
                                                            >
                                                                {preData?.departments?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.departmentId
                                                                                }
                                                                                key={
                                                                                    item.departmentId
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.departmentName
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}

                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                label="Date of Joining "
                                                                inputFormat="YYYY/MM/DD"
                                                                mask=""
                                                                name="joinedOn"
                                                                value={
                                                                    dayjs(
                                                                        formik
                                                                            .values
                                                                            .joinedOn
                                                                    ) || ""
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "joinedOn",
                                                                        e
                                                                            .toISOString()
                                                                            .split(
                                                                                "T"
                                                                            )[0]
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        {...params}
                                                                    />
                                                                )}
                                                                error={Boolean(
                                                                    formik
                                                                        .touched
                                                                        .dob &&
                                                                        formik
                                                                            .errors
                                                                            .dob
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    ) : null}
                                                </Stack>

                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                    padding={2}
                                                >
                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Reporting Head
                                                            </InputLabel>
                                                            <Select
                                                                name="reportingHeadId"
                                                                variant="outlined"
                                                                label="Reporting Head"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .reportingHeadId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "reportingHeadId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.reportingHeads?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}

                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Employment Type
                                                            </InputLabel>
                                                            <Select
                                                                name="employmentTypeId"
                                                                variant="outlined"
                                                                label="Employment Type"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .employmentTypeId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "employmentTypeId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.employmentTypes?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.type
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}
                                                </Stack>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                    padding={2}
                                                >
                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Employment
                                                                Status
                                                            </InputLabel>
                                                            <Select
                                                                name="employmentStatusId"
                                                                variant="outlined"
                                                                label="Employment Status"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .employmentStatusId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "employmentStatusId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.employmentStatus?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}

                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                label="Probation Completed On"
                                                                inputFormat="YYYY/MM/DD"
                                                                mask=""
                                                                name="dob"
                                                                value={dayjs(
                                                                    formik
                                                                        .values
                                                                        .probationCompletedOn
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "probationCompletedOn",
                                                                        e
                                                                            .toISOString()
                                                                            .split(
                                                                                "T"
                                                                            )[0]
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        {...params}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    ) : null}
                                                </Stack>

                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                    padding={2}
                                                >
                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Login Status
                                                            </InputLabel>
                                                            <Select
                                                                name="loginStatus"
                                                                variant="outlined"
                                                                label="Login Status"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .loginStatus
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "loginStatus",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                <MenuItem
                                                                    value={0}
                                                                >
                                                                    Inactive
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={1}
                                                                >
                                                                    Active
                                                                </MenuItem>
                                                            </Select>
                                                            {/* <FormHelperText>{touchedEducation ? (formik.values.course === "" ? 'Please fill..!' : ' ') : null}</FormHelperText> */}
                                                        </FormControl>
                                                    ) : null}

                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Office
                                                            </InputLabel>
                                                            <Select
                                                                name="officeId"
                                                                variant="outlined"
                                                                label="Office"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .officeId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "officeId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.offices?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                            {/* <FormHelperText>{touchedEducation ? (formik.values.course === "" ? 'Please fill..!' : ' ') : null}</FormHelperText> */}
                                                        </FormControl>
                                                    ) : null}
                                                </Stack>
                                                <Box padding={2}>
                                                    {loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!loading ? (
                                                        <Autocomplete
                                                            name="funnelId"
                                                            id="combo-box-demo"
                                                            // value={data}
                                                            options={
                                                                preData?.funnelDatas
                                                            }
                                                            defaultValue={{
                                                                applicantName:
                                                                    singleUser?.applicantName ||
                                                                    "",
                                                                id: singleUser?.funnelId,
                                                                applicantEmail:
                                                                    singleUser?.applicantEmail ||
                                                                    "",
                                                                applicantMobile:
                                                                    singleUser?.applicantMobile ||
                                                                    "",
                                                            }}
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                `${option.applicantName}-${option.applicantEmail}-${option.applicantMobile}`
                                                            }
                                                            sx={{
                                                                width: "49%",
                                                            }}
                                                            onChange={(
                                                                e,
                                                                value
                                                            ) => {
                                                                formik.setFieldValue(
                                                                    "funnelId",
                                                                    value.id
                                                                );
                                                            }}
                                                            renderOption={(
                                                                props,
                                                                option
                                                            ) => {
                                                                return (
                                                                    <li
                                                                        {...props}
                                                                        key={
                                                                            option.id
                                                                        }
                                                                    >
                                                                        {`${option.applicantName},${option.applicantEmail},${option.applicantMobile}`}
                                                                    </li>
                                                                );
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Applicant Name"
                                                                />
                                                            )}
                                                        />
                                                    ) : null}
                                                </Box>

                                                <Box
                                                    sx={{
                                                        paddingTop: 5,
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        size="medium"
                                                        component={Link}
                                                        to={`/my-tasks/employees`}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        type="submit"
                                                        value="Save"
                                                        sx={{ marginLeft: 2 }}
                                                    >
                                                        Save Changes
                                                    </Button>
                                                </Box>
                                            </Form>
                                        </FormikProvider>
                                    </Box>

                                    {/* -------------------------------------------------------------------- */}
                                </Paper>
                            </Box>
                            {/* ------------------------------------------------------------------------------------------ */}

                            {/* ------------------------------------------------------------------------------------------------- */}
                            <AddRole
                                singleUser={singleUser}
                                preData={preData}
                                role={role}
                            />
                        </Container>
                    )}
                    {!smUp && (
                        <Container component="main">
                            {/*..................................HEADING.............................*/}
                            <Stack direction="column" mb={5}>
                                <Typography variant="h4" gutterBottom>
                                    Edit Employee
                                </Typography>
                                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                            </Stack>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        width: "100%",
                                        borderTopRightRadius: 20,
                                        borderTopLeftRadius: 20,
                                    }}
                                >
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            width: "100%",
                                            height: 220,
                                            backgroundImage: `url(${"https://media.istockphoto.com/id/1029971592/vector/abstract-digital-landscape-or-waves-with-flowing-particles.jpg?s=612x612&w=0&k=20&c=LuT34-x9m5pEv93ixvn72cfRMIx30R1eoC9Y5X_-XbY="})`,
                                            borderTopRightRadius: 20,
                                            borderTopLeftRadius: 20,
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                paddingTop: 5,
                                            }}
                                        >
                                            <Stack direction={"column"}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <Avatar
                                                        src={`https://dev.teaminterval.net/assets/employee/photo/passport_size/${singleUser.avatar}.jpg`}
                                                        sx={{
                                                            width: 100,
                                                            height: 110,
                                                            border: 2,
                                                            borderColor:
                                                                "white",
                                                            borderTopRightRadius: 20,
                                                            borderTopLeftRadius: 20,
                                                        }}
                                                        variant="square"
                                                    />
                                                </Box>

                                                <Box>
                                                    <Typography
                                                        variant="h6"
                                                        textAlign={"center"}
                                                        sx={{
                                                            mb: 1,
                                                            color: "grey.200",
                                                        }}
                                                    >
                                                        {singleUser.name}
                                                    </Typography>
                                                </Box>

                                                <Box>
                                                    <Typography
                                                        // variant="h6"
                                                        color="#b6c5c2"
                                                        textTransform="capitalize"
                                                        textAlign={"center"}
                                                    >
                                                        {singleUser.phone}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Paper>

                                    <Box padding={3}>
                                        <FormikProvider value={formik}>
                                            <Form
                                                onSubmit={formik.handleSubmit}
                                            >
                                                <Stack
                                                    direction={"column"}
                                                    spacing={2}
                                                    padding={2}
                                                >
                                                    {singleUser.loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!singleUser.loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Department
                                                            </InputLabel>
                                                            <Select
                                                                name="departmentId"
                                                                variant="filled"
                                                                label="Department"
                                                                disabled
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .departmentId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "departmentId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.departments?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.departmentId
                                                                                }
                                                                                key={
                                                                                    item.departmentId
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.departmentName
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}

                                                    {singleUser.loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!singleUser.loading ? (
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                label="Date of Joining "
                                                                inputFormat="YYYY/MM/DD"
                                                                mask=""
                                                                name="joinedOn"
                                                                value={dayjs(
                                                                    formik
                                                                        .values
                                                                        .joinedOn
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "joinedOn",
                                                                        e
                                                                            .toISOString()
                                                                            .split(
                                                                                "T"
                                                                            )[0]
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        {...params}
                                                                    />
                                                                )}
                                                                error={Boolean(
                                                                    formik
                                                                        .touched
                                                                        .dob &&
                                                                        formik
                                                                            .errors
                                                                            .dob
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    ) : null}
                                                </Stack>

                                                <Stack
                                                    direction={"column"}
                                                    spacing={2}
                                                    padding={2}
                                                >
                                                    {singleUser.loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!singleUser.loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Reporting Head
                                                            </InputLabel>
                                                            <Select
                                                                name="reportingHeadId"
                                                                variant="outlined"
                                                                label="Reporting Head"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .reportingHeadId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "reportingHeadId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.reportingHeads?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}

                                                    {singleUser.loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!singleUser.loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Employment Type
                                                            </InputLabel>
                                                            <Select
                                                                name="employmentTypeId"
                                                                variant="outlined"
                                                                label="Employment Type"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .employmentTypeId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "employmentTypeId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.employmentTypes?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.type
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}
                                                </Stack>
                                                <Stack
                                                    direction={"column"}
                                                    spacing={2}
                                                    padding={2}
                                                >
                                                    {singleUser.loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!singleUser.loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Employment
                                                                Status
                                                            </InputLabel>
                                                            <Select
                                                                name="employmentStatusId"
                                                                variant="outlined"
                                                                label="Employment Status"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .employmentStatusId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "employmentStatusId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.employmentStatus?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}

                                                    {singleUser.loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!singleUser.loading ? (
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DesktopDatePicker
                                                                label="Probation Completed On"
                                                                inputFormat="YYYY/MM/DD"
                                                                mask=""
                                                                name="dob"
                                                                value={dayjs(
                                                                    formik
                                                                        .values
                                                                        .probationCompletedOn
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "probationCompletedOn",
                                                                        e
                                                                            .toISOString()
                                                                            .split(
                                                                                "T"
                                                                            )[0]
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        {...params}
                                                                    />
                                                                )}
                                                                error={Boolean(
                                                                    formik
                                                                        .touched
                                                                        .dob &&
                                                                        formik
                                                                            .errors
                                                                            .dob
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    ) : null}
                                                </Stack>

                                                <Stack
                                                    direction={"column"}
                                                    spacing={2}
                                                    padding={2}
                                                >
                                                    {singleUser.loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!singleUser.loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Login Status
                                                            </InputLabel>
                                                            <Select
                                                                name="loginStatus"
                                                                variant="outlined"
                                                                label="Login Status"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .loginStatus
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "loginStatus",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                <MenuItem
                                                                    value={0}
                                                                >
                                                                    Inactive
                                                                </MenuItem>
                                                                <MenuItem
                                                                    value={1}
                                                                >
                                                                    Active
                                                                </MenuItem>
                                                            </Select>
                                                            {/* <FormHelperText>{touchedEducation ? (formik.values.course === "" ? 'Please fill..!' : ' ') : null}</FormHelperText> */}
                                                        </FormControl>
                                                    ) : null}

                                                    {singleUser.loading ? (
                                                        <FormControl
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                variant="rectangular"
                                                                sx={{
                                                                    width: "100%",
                                                                    height: 56,
                                                                    borderRadius: 1,
                                                                }}
                                                            />
                                                        </FormControl>
                                                    ) : null}
                                                    {!singleUser.loading ? (
                                                        <FormControl fullWidth>
                                                            <InputLabel>
                                                                Office
                                                            </InputLabel>
                                                            <Select
                                                                name="officeId"
                                                                variant="outlined"
                                                                label="Office"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .officeId
                                                                }
                                                                onChange={(e) =>
                                                                    formik.setFieldValue(
                                                                        "officeId",
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                required
                                                            >
                                                                {preData.offices?.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    ) : null}
                                                </Stack>
                                                <Box padding={2}>
                                                    {" "}
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={
                                                            preData.funnelDatas
                                                        }
                                                        defaultValue={{
                                                            applicantName:
                                                                singleUser.applicantName
                                                                    ? singleUser.applicantName
                                                                    : "",
                                                            id: singleUser.funnelId,
                                                            applicantEmail:
                                                                singleUser.applicantEmail
                                                                    ? singleUser.applicantEmail
                                                                    : "",
                                                            applicantMobile:
                                                                singleUser.applicantMobile
                                                                    ? singleUser.applicantMobile
                                                                    : "",
                                                        }}
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            `${option.applicantName},${option.applicantEmail},${option.applicantMobile},`
                                                        }
                                                        onChange={(
                                                            e,
                                                            value
                                                        ) => {
                                                            formik.setFieldValue(
                                                                "funnelId",
                                                                value.id
                                                            );
                                                        }}
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => {
                                                            return (
                                                                <li
                                                                    {...props}
                                                                    key={
                                                                        option.id
                                                                    }
                                                                >
                                                                    {`${option.applicantName},${option.applicantEmail},${option.applicantMobile}`}
                                                                </li>
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Applicant Name"
                                                            />
                                                        )}
                                                    />
                                                </Box>

                                                <Box
                                                    sx={{
                                                        paddingTop: 5,
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        size="medium"
                                                        component={Link}
                                                        to={`/my-tasks/employees`}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        type="submit"
                                                        value="Save"
                                                        sx={{ marginLeft: 2 }}
                                                    >
                                                        Save
                                                    </Button>
                                                </Box>
                                            </Form>
                                        </FormikProvider>
                                    </Box>
                                </Paper>
                            </Box>
                            <AddRole
                                singleUser={singleUser}
                                preData={preData}
                            />
                        </Container>
                    )}
                </Page>
            )}
        </div>
    );
}
