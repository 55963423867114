import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { Box, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GlobalDatagrid from "../../../../components/mui/DataGrid/GlobalDatagrid";
import { HEAD_COMP_WORK_REQ } from "../../../../components/datagrid/EmployeeService/EpmCompWork/HeadCompWorkReqTableHead";
import { getReportingHeadCompWorkReq } from "../../../../redux/features/leaveSlice";
import ApproveAction from "./ApproveAction";
import ActionDetails from "../../../../components/mui/GlobalAnchor/ActionDetails";
import GlobalAnchorList from "../../../../components/mui/GlobalAnchor/GlobalAnchorList";

const ReportingHeadCompWorkReq = () => {
  const dispatch = useDispatch();
  const { loading, rhCompWorkReq } = useSelector((state) => state.leave);
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData;
  const data = {
    page: pageNum,
    limit: gridSize,
    search: search,
  };

  useEffect(() => {
    if (pageNum && gridSize) {
      dispatch(getReportingHeadCompWorkReq(data));
    }
  }, [gridSize, pageNum, search]);

  return (
    <GlobalWrapper title={" Compensatory Work Request"}>
      <Paper elevation={3}>
        <Box sx={{ width: "100%", padding: 0 }}>
          <GlobalDatagrid
            tableHead={HEAD_COMP_WORK_REQ}
            rows={rhCompWorkReq?.data}
            meta={rhCompWorkReq?.meta?.totalRecords || 0}
            rowUniqueId="id"
            rowClick={true}
            loading={loading}
            actionComponent={
              <>
                <ActionDetails val={rowData?.name} />
                <GlobalAnchorList
                  label={"Update Request"}
                  component={
                    <ApproveAction
                      update={data}
                      params={rowData}
                      paginationData={paginationData}
                    />
                  }
                />
              </>
            }
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  );
};

export default ReportingHeadCompWorkReq;
