import * as React from "react";
import Button from "@mui/material/Button";

export default function BasicButton({
    children,
    variant,
    type,
    action,
    size,
    disabled,
    component,
    to,
    sx,
    value,
}) {
    return (
        <Button
            variant={variant}
            value={value}
            sx={sx}
            size={size}
            component={component}
            to={to}
            type={type}
            disabled={disabled}
            onClick={action}
        >
            {children}
        </Button>
    );
}
