import React from 'react'
import { ApproveButton } from '../../mui/Buttons/Buttons'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { savePaginationData } from '../../../redux/features/globalDatagridSlice'
import { approveOrReject, getAllRequests } from '../../../redux/features/helpAndSupportSlice'

const ApproveRequest = ({isDisabled,requestId}) => {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch=useDispatch()
    const { paginationData } = useSelector((state) => state.globalDataGrid)
    const {loading } = useSelector((store) => store.helpAndSupport)
    const approveAction=()=>{
        const updateData={
            ticketId:requestId,
            status:"approved"
        }
        dispatch(approveOrReject(updateData)).then((res) => {
            if (res.payload.status === 'error') {
              enqueueSnackbar(res.payload.message, {
                variant: 'error',
              })
            } else if (res.payload.status === 'success') {
              dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
              dispatch(getAllRequests(paginationData))
              enqueueSnackbar(res.payload.message, {
                variant: 'success',
              })
            }
          })
    }
  return (
    <ApproveButton disabled={isDisabled || loading} action={approveAction}/>
  )
}

export default ApproveRequest