import React, { useEffect, useState } from 'react'
import GlobalDialog from '../../../../components/mui/Dialogue/GlobalDialog'
import { AddButton, LogButton } from '../../../../components/mui/Buttons/Buttons'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Box, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import LunchOrEvening from './LunchOrEvening'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFoodList,
  getFoodSlots,
  addFood,
  removeFood,
  getEveningFoodList,
  getFoodStatus,
} from '../../../../redux/features/foodSlice'
import { useSnackbar } from 'notistack'

const MarkFood = ({ dateChange, foodCategoryChange,selectedFoodCategory }) => {
  const { profile } = JSON.parse(localStorage.getItem('cred'))
  const dispatch = useDispatch('')
  const [open, setOpen] = useState(false)
  const [lunchDate, setLunchDate] = useState(dayjs().add(1, 'day'))
  const [eveningFoodDate, setEveningFoodDate] = useState(dayjs())
  const [isNon, setIsNon] = useState(false)
  const {
    loading,
    alredayLunchMarkedDetails,
    alreadyEveningMarkedDetails,
    lunchslots,
    eveningSlots,
    foodStatus,
    foodList,
  } = useSelector((store) => store.food)

  const { enqueueSnackbar } = useSnackbar()
  const [lunchAlreadyMarked, setLunchAlreadyMarked] = useState(false)
  const [eveingAlreadyMarked, setEveingAlreadyMarked] = useState(false)

  const [isDisabled, setIsDisabled] = useState(true)
  const [isEveningDisabled, setIsEveningDisabled] = useState(true)
  const [checked, setChecked] = useState({
    lunch: false,
    eveningFood: false,
    veg: false,
  })
  const [selectedSlotsLunch, setSelectedSlotsLunch] = useState('')
  const [selectedSlotsEvening, setSelectedSlotsEvening] = useState('')
  const [selectedOffice, setSelectedOffice] = useState(6)
  const offices = [
    { value: 'Pathanapuram', id: 6 },
    { value: 'office2', id: 7 },
  ]
  const [seatAvailableLunch, setSeatAvailableLunch] = useState(100)
  const [seatAvailableEvening, setSeatAvailableEvening] = useState(100)

  useEffect(() => {
    if (checked.lunch) {
      dispatch(getFoodList({ date: dayjs().add(1, 'day').format('YYYY-MM-DD'), userId: profile.userId }))
    } else if (checked.eveningFood) {
      dispatch(getEveningFoodList({ date: dayjs().format('YYYY-MM-DD'), userId: profile.userId }))
    }
  }, [checked.lunch, checked.eveningFood])

  useEffect(() => {
    //WHEN THE MODAL GETS OPEN IT WILL FETCH THE ALL SLOTS ,CHECK WHETHER ALREADY MARKED FOOD DETAILS FOR LUNCH,EVEING
    if (open) {
      dispatch(getFoodSlots())
      dispatch(getFoodStatus(lunchDate.format('YYYY-MM-DD')))
    }else {
      setChecked({
        lunch: false,
        eveningFood: false,
        veg: false,
      })
      setSelectedSlotsLunch('')
      setSelectedSlotsEvening('')
    }
  }, [open])



  useEffect(() => {
    if (open) {
      const seatCapacity = lunchslots.find((data) => data.food_slot_id == selectedSlotsLunch)?.food_slot_capacity ?? 100
      const count = foodList.filter((data) => data.food_slot_id == selectedSlotsLunch)?.length ?? 0
      setSeatAvailableLunch(seatCapacity - count)
    }
  }, [selectedSlotsLunch, checked.lunch, foodList])

  useEffect(() => {
    if (open) {
      const seatCapacity =
        eveningSlots.find((data) => data.food_slot_id == selectedSlotsEvening)?.food_slot_capacity ?? 100
      const count = foodList.filter((data) => data.food_slot_id == selectedSlotsEvening)?.length ?? 0
      setSeatAvailableEvening(seatCapacity - count)
    }
  }, [selectedSlotsEvening, checked.eveningFood, foodList])

  useEffect(() => {
    //THIS USEFFECT WILL TRACK THE TIMEING TO DISABLE FOOD MARKING LABELS
    const now = dayjs()
    const startTime = dayjs().set('hour', 14).set('minute', 0) // 2 PM
    const endTime = dayjs().set('hour', 23).set('minute', 59) // 11:59 AM
    if (now.isAfter(startTime) && now.isBefore(endTime)) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
    const eveningStart = dayjs().set('hour', 12).set('minute', 0) //12 PM
    const eveningEnd = dayjs().set('hour', 16).set('minute', 59) //4:59 PM
    if (now.isAfter(eveningStart) && now.isBefore(eveningEnd)) {
      setIsEveningDisabled(false)
    } else {
      setIsEveningDisabled(true)
    }
  }, [open])

  useEffect(() => {
    if (foodStatus.length > 0) {
      const isComingDayFoodAlreadyDefined = foodStatus[0].day_food_status_date == lunchDate.format('YYYY-MM-DD')
      if (isComingDayFoodAlreadyDefined) {
        const isNon = foodStatus[0].day_food_meal_status == 2
        setIsNon(isNon)
      }
    } else if (lunchDate.get('day') === 5) {
      setIsNon(true)
    } else {
      setIsNon(false)
    }
  }, [foodStatus])

  useEffect(() => {
    //THIS WILL TRACK THE USER ALREADY MARKED DETAILS AND IT WILL DECIDE WHETHER ADD FOOD OR REMOVE FOOD
    if (Object.keys(alredayLunchMarkedDetails).length > 0) {
      setLunchAlreadyMarked(true)
      setSelectedSlotsLunch(alredayLunchMarkedDetails.food_slot_id)
      if (alredayLunchMarkedDetails.food_category == 2) {
        setChecked((pvs) => ({
          ...pvs,
          veg: true,
        }))
      }
    } else {
      setLunchAlreadyMarked(false)
    }
    if (Object.keys(alreadyEveningMarkedDetails).length > 0) {
      setEveingAlreadyMarked(true)
      setSelectedSlotsEvening(alreadyEveningMarkedDetails.food_slot_id)
    } else {
      setEveingAlreadyMarked(false)
    }
  }, [alredayLunchMarkedDetails, alreadyEveningMarkedDetails])

  const handleChange = (event) => {
    const { checked, name } = event.target
    if (name === 'lunch' && checked) {
      setChecked((prev) => ({
        ...prev,
        lunch: true,
        eveningFood: false,
      }))
    } else if (name === 'eveningFood' && checked) {
      setChecked((prev) => ({
        ...prev,
        lunch: false,
        eveningFood: true,
      }))
    } else {
      setChecked((prev) => ({
        ...prev,
        [name]: checked,
      }))
    }
  }

  const handleLunchSlotChange = (e) => {
    setSelectedSlotsLunch(e.target.value)
  }

  const handleEveningSlotChange = (e) => {
    setSelectedSlotsEvening(e.target.value)
  }

  const handleOfficeSlotChange = (e) => {
    setSelectedOffice(e.target.value)
  }

  const lunchAction = () => {
    if (lunchAlreadyMarked) {
      dispatch(removeFood(alredayLunchMarkedDetails?.food_id)).then((res) => {
        if (res.payload.status == 'failed' || res.payload.status == 'error') {
          enqueueSnackbar(`${res.payload.message}`, { variant: 'error' })
        } else {
          setChecked((pvs) => ({
            ...pvs,
            veg: false,
          }))
          dispatch(
            getFoodList({
              date: lunchDate.format('YYYY-MM-DD'),
              userId: profile.userId,
            }),
          )
          setOpen(false)
          enqueueSnackbar(` ${res.payload.message}`, {
            variant: 'success',
          })
        }
      })
    } else {
      const data = {
        foodDate: lunchDate.format('YYYY-MM-DD'),
        foodOffice: selectedOffice,
        foodSlotId: selectedSlotsLunch,
        foodCategory: checked.veg ? 2 : 1,
      }
      dispatch(addFood(data)).then((res) => {
        if (res.payload.status == 'failed' || res.payload.status == 'error') {
          enqueueSnackbar(` ${res.payload.message}`, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar(` ${res.payload.message}`, {
            variant: 'success',
          })
          dispatch(
            getFoodList({
              date: lunchDate.format('YYYY-MM-DD'),
              userId: profile.userId,
            }),
          )
          setOpen(false)
        }
      })
    }
    dateChange(lunchDate)
    foodCategoryChange(1)
  }

  const eveningFoodAction = () => {
    if (eveingAlreadyMarked) {
      dispatch(removeFood(alreadyEveningMarkedDetails?.food_id)).then((res) => {
        if (res.payload.status == 'failed' || res.payload.status == 'error') {
          enqueueSnackbar(`${res.payload.message}`, { variant: 'error' })
        } else {
          enqueueSnackbar(` ${res.payload.message}`, {
            variant: 'success',
          })
          dispatch(
            getEveningFoodList({
              date: eveningFoodDate.format('YYYY-MM-DD'),
              userId: profile.userId,
            }),
          )
          setOpen(false)
        }
      })
    } else {
      const data = {
        foodDate: eveningFoodDate.format('YYYY-MM-DD'),
        foodOffice: selectedOffice,
        foodSlotId: selectedSlotsEvening,
        foodCategory: 1,
      }
      dispatch(addFood(data)).then((res) => {
        if (res.payload.status == 'failed' || res.payload.status == 'error') {
          enqueueSnackbar(` ${res.payload.message}`, {
            variant: 'error',
            // autoHideDuration: 3000,
          })
        } else {
          enqueueSnackbar(` ${res.payload.message}`, {
            variant: 'success',
          })
          dispatch(
            getEveningFoodList({
              date: eveningFoodDate.format('YYYY-MM-DD'),
              userId: profile.userId,
            }),
          )
          setOpen(false)
        }
      })
    }
    dateChange(eveningFoodDate)
    foodCategoryChange(2)
  }

  const closeButtonFunction=()=>{ //WITHOUT ANY ACTION USER CLOSE MODAL IT WILL CALL THE TOMORROW LUNCH DETAILS
   setOpen(false)
   dateChange(lunchDate)
   if(selectedFoodCategory==1){
    dispatch(getFoodList({ date: dayjs().add(1, 'day').format('YYYY-MM-DD'), userId: profile.userId }))
   }else{
    foodCategoryChange(1)
   }
  }
  return (
    <GlobalDialog
      maxWidth={'md'}
      fullWidth
      title={
        <>
          <Typography variant="body1">Lunch Marking Hours : 2 PM to 11:59 PM</Typography>
          <Typography variant="body1">Evening Food Marking Hours : 12 PM to 5 PM</Typography>
        </>
      }
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={closeButtonFunction}
      popupButton={
        <>
          <AddButton
            size="large"
            action={() => {
              setOpen(true)
            }}>
            Mark Food
          </AddButton>
        </>
      }
      content={
        <>
          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={handleChange} name="lunch" checked={checked.lunch} disabled={isDisabled} />
                }
                label="Lunch Tomorrow"
              />

              {checked.lunch && (
                <>
                  {isNon && !loading && (
                    <FormGroup row>
                      <FormControlLabel
                        control={<Checkbox onChange={handleChange} name="veg" checked={checked.veg} />}
                        label="Veg Required"
                        labelPlacement="start"
                      />
                    </FormGroup>
                  )}
                  <LunchOrEvening
                    seatNumberShow={selectedSlotsLunch ? true : false}
                    seatAvailable={seatAvailableLunch}
                    selectedSlots={selectedSlotsLunch}
                    handleSlotChange={handleLunchSlotChange}
                    slots={lunchslots}
                    date={lunchDate}
                    action={lunchAction}
                    alreadyMarked={lunchAlreadyMarked}
                    offices={offices}
                    selectedOffice={selectedOffice}
                    handleOfficeChange={handleOfficeSlotChange}
                    loading={loading}
                  />
                </>
              )}
              <FormControlLabel
                disabled={isEveningDisabled}
                control={<Checkbox onChange={handleChange} name="eveningFood" checked={checked.eveningFood} />}
                label="Food For This Evening"
              />
              {checked.eveningFood && (
                <LunchOrEvening
                  seatNumberShow={selectedSlotsEvening ? true : false}
                  seatAvailable={seatAvailableEvening}
                  selectedSlots={selectedSlotsEvening}
                  handleSlotChange={handleEveningSlotChange}
                  slots={eveningSlots}
                  date={eveningFoodDate}
                  action={eveningFoodAction}
                  alreadyMarked={eveingAlreadyMarked}
                  offices={offices}
                  selectedOffice={selectedOffice}
                  handleOfficeChange={handleOfficeSlotChange}
                  loading={loading}
                />
              )}
            </FormGroup>
          </Box>
        </>
      }
    />
  )
}

export default MarkFood
