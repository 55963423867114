import { AssignTutorPopupView } from "../RenderCellMain";

export const ASSIGN_TUTOR_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "SlNo",
        width: 50,
    },

    {
        field: "full_name",
        headerName: "Trainer Name",
        width: 250,
    },
    {
        field: "email_id",
        headerName: "Email Id",
        width: 250,
    },
    {
        field: "mob_no",
        headerName: "Phone",
        width: 250,
    },
    {
        field: "Assign Tutor",
        headerName: "Assign Tutor",
        width: 250,
        renderCell: (params) => <AssignTutorPopupView {...params.row} />,
    },
];
