export const TERMINATION_CLEARANCE_TABLE_HEAD = [
    {
        field: "id",
        headerName: "id",
        width: 50,
    },
    {
        field: "admissionNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
    },
    {
        field: "mobile",
        headerName: "Mobile",
        width: 150,
    },
    {
        field: "class",
        headerName: "Class",
        width: 90,
    },
    {
        field: "course",
        headerName: "Course",
        width: 180,
    },

    {
        field: "croName",
        headerName: "CRO Name",
        width: 150,
    },

    {
        field: "inactiveType",
        headerName: "Inactive Type",
        width: 200,
    },
    {
        field: "inactiveDate",
        headerName: "Inactive Date",
        width: 150,
    },
    {
        field: "inactiveRemark",
        headerName: "Inactive Remark",
        width: 150,
    },
    {
        field: "inactiveMarkedTime",
        headerName: "Inactive Marked Time",
        width: 150,
    },
    {
        field: "approval",
        headerName: "Approval",
        width: 150,
    },
];
