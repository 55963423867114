import React from "react";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
import { packageSubjectSessionListing } from "../../redux/features/mastersSlice";

export const PackageSubjectSessionListingStatus = ({ params }) => {
    return (
        <div>
            {" "}
            <StatusToggleSwitch
                initialValue={params.isListed}
                api={packageSubjectSessionListing(
                    params.packageSubjectSessionId
                )}
            />
        </div>
    );
};
