import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
    Button,
    Divider,
    IconButton,
    Paper,
    Slide,
    Typography,
} from "@mui/material";
import Iconify from "./Iconify";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { screenStatus } from "../redux/features/settingsSlice";
import TransitionSettings from "./TransitionSettings";
import SideBarOptions from "./SideBarOptions";

export const Settings = () => {
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        right: false,
    });

    const status = useSelector((state) => state.settings.screenStatus);

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <div>
            {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <IconButton
                        onClick={toggleDrawer(anchor, true)}
                        sx={{
                            color: "text.primary",
                            animation: "spin 5s linear infinite",
                            "@keyframes spin": {
                                "0%": {
                                    transform: "rotate(360deg)",
                                },
                                "100%": {
                                    transform: "rotate(0deg)",
                                },
                            },
                        }}
                    >
                        <Iconify icon="solar:settings-bold-duotone" />
                    </IconButton>

                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        BackdropProps={{ invisible: true }}
                        onClose={toggleDrawer(anchor, false)}
                        PaperProps={{
                            sx: {
                                borderRadius: 1,
                            },
                        }}
                    >
                        <Box
                            sx={{
                                width: 300,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "inline-flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                }}
                            >
                                <Box
                                    sx={{ display: "inline-flex", padding: 1 }}
                                >
                                    <Box>
                                        <IconButton
                                            sx={{
                                                color: "text.primary",
                                                animation:
                                                    "spin 5s linear infinite",
                                                "@keyframes spin": {
                                                    "0%": {
                                                        transform:
                                                            "rotate(360deg)",
                                                    },
                                                    "100%": {
                                                        transform:
                                                            "rotate(0deg)",
                                                    },
                                                },
                                            }}
                                        >
                                            <Iconify icon="solar:settings-bold-duotone" />
                                        </IconButton>
                                    </Box>
                                    <Box marginTop={0.5}>
                                        <Typography variant="h6">
                                            Settings
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: 0.6 }}>
                                    <IconButton
                                        onClick={toggleDrawer(anchor, false)}
                                    >
                                        <Icon icon="radix-icons:cross-2" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Divider sx={{ borderStyle: "dashed" }} />
                            <Box>
                                <TransitionSettings />
                            </Box>
                            <Box>
                                <SideBarOptions />
                            </Box>
                            <Box
                                sx={{
                                    marginTop: "calc(10% + 60px)",
                                    position: "fixed",
                                    bottom: 0,
                                    width: "100%",
                                }}
                            >
                                {status === "NotActive" ? (
                                    <Box>
                                        <Paper
                                            onClick={() => {
                                                dispatch(
                                                    screenStatus("Active")
                                                );
                                            }}
                                            elevation={3}
                                            sx={{ height: 50 }}
                                        >
                                            <Button
                                                sx={{ width: 300, height: 50 }}
                                            >
                                                <Icon
                                                    icon="ic:round-fullscreen"
                                                    width="25"
                                                    height="25"
                                                />
                                                Full Screen
                                            </Button>
                                        </Paper>
                                    </Box>
                                ) : (
                                    <Slide
                                        direction="up"
                                        in={status === "Active" ? true : null}
                                        mountOnEnter
                                        unmountOnExit
                                    >
                                        <Box>
                                            <Paper
                                                onClick={() => {
                                                    dispatch(
                                                        screenStatus(
                                                            "NotActive"
                                                        )
                                                    );
                                                }}
                                                elevation={3}
                                                sx={{ height: 50 }}
                                            >
                                                <Button
                                                    sx={{
                                                        width: 300,
                                                        height: 50,
                                                    }}
                                                >
                                                    <Icon
                                                        icon="material-symbols:fullscreen-exit"
                                                        width="25"
                                                        height="25"
                                                    />
                                                    Exit Full Screen
                                                </Button>
                                            </Paper>
                                        </Box>
                                    </Slide>
                                )}
                            </Box>
                        </Box>
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
};
