import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { Cards } from "../../../../components/Card/Card";
import { motion, AnimateSharedLayout } from "framer-motion";
import useResponsive from "../../../../Hooks/useResponsive";
import { useSelector, useDispatch } from "react-redux";
import { getAllCabins } from "../../../../redux/features/cabinBookingSlice";
import { BookingSlots } from "./BookingSlots";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { FindAvailableCabin } from "./FindAvailableCabin";
import { getAssetTimeSlotsForADay } from "../../../../redux/features/cabinBookingSlice";

export const CabinBooking = () => {
    const dispatch = useDispatch();
    const { allCabins, assetTimeSlotsForADay } = useSelector(
        (state) => state.cabin
    );
    useEffect(() => {
        dispatch(getAllCabins());
    }, []);

    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");

    const [data, setData] = useState(false);
    const [cabinDetails, setCabinDetails] = useState("");
    const [value, setValue] = React.useState(dayjs());
    const [id, setId] = useState("");
    const [asset, setAsset] = useState("");

    useEffect(() => {
        let data = {
            id: id,
            date: value.format("DD/MM/YYYY"),
        };
        setAsset(data);
        if (data.id && data.date) {
            setTimeout(() => {
                dispatch(getAssetTimeSlotsForADay(data));
            }, 500);
        }
    }, [id, value]);

    const variants = {
        hidden: { opacity: 0, x: "100%" },
        visible: { opacity: 1, x: 0 },
    };
    const keyframesExample = {
        hidden: { opacity: 0, x: -100 },
        halfway: { opacity: 0.5, x: 50 },
        visible: { opacity: 1, x: 0 },
    };

    const scrollToRef = useRef(null);

    useEffect(() => {
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [data]);
    // useEffect(() => {
    //     if (scrollToRef.current) {
    //         scrollToRef.current.scrollIntoView({ behavior: "smooth" });
    //     }
    // }, [data]);
    const useStyles = makeStyles((theme) => ({
        customScrollbar: {
            "&::-webkit-scrollbar": {
                width: "8px",
            },
            "&::-webkit-scrollbar-track": {
                background: theme.palette.grey[200],
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.primary.dark,
            },
        },
    }));
    const classes = useStyles();

    const [boxWidth, setBoxWidth] = useState("100%");
    const onKeyDown = (e) => {
        e.preventDefault();
    };

    return (
        <div>
            <Wrapper
                title="Cabin Booking"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingBottom: 2,
                            }}
                        >
                            <Box>
                                <FindAvailableCabin />
                            </Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Date"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            onKeyDown={onKeyDown}
                                            {...params}
                                        />
                                    )}
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box height={"50vh"} sx={{ borderRadius: 15 }}>
                            <Paper elevation={3} sx={{ borderRadius: 4 }}>
                                {data === true ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            backgroundColor: "grey.200",
                                            height: 48,
                                            borderTopRightRadius: 10,
                                            borderTopLeftRadius: 10,
                                        }}
                                    ></Box>
                                ) : null}

                                <Stack direction={"row"} spacing={2}>
                                    <Box
                                        className={classes.customScrollbar}
                                        sx={{
                                            paddingBottom: 3,
                                            width:
                                                !mdUp && data === true
                                                    ? "0%"
                                                    : data === true
                                                    ? 400
                                                    : "100%",
                                            height:
                                                data === true ? "80vh" : null,
                                            overflowY:
                                                data === true ? "scroll" : null,
                                        }}
                                    >
                                        <motion.div
                                            style={{
                                                width: boxWidth,
                                            }}
                                            animate={{ width: boxWidth }}
                                            transition={{
                                                duration: 0.3,
                                                ease: "easeInOut",
                                            }}
                                        >
                                            {data === false ? (
                                                <Box
                                                    sx={{
                                                        marginBottom: 2,
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        position: "sticky",

                                                        backgroundColor:
                                                            "#eff4f9",

                                                        padding: 1,
                                                        borderRadius: 15,
                                                    }}
                                                >
                                                    <Typography variant="h4">
                                                        CABINS
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box
                                                    sx={{
                                                        margin: 2,
                                                        marginBottom: 2,
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        position: "sticky",

                                                        backgroundColor:
                                                            "#eff4f9",

                                                        padding: 1,
                                                        borderRadius: 15,
                                                    }}
                                                >
                                                    <Typography variant="h4">
                                                        CABINS
                                                    </Typography>
                                                </Box>
                                            )}
                                            <Box sx={{ marginBottom: 2 }}>
                                                <Divider />
                                            </Box>

                                            <Grid container spacing={2}>
                                                {allCabins.map((item) => {
                                                    const cardRef =
                                                        item.asset_id === id
                                                            ? scrollToRef
                                                            : null;
                                                    return (
                                                        <Grid
                                                            item
                                                            key={item.asset_id}
                                                            xs={
                                                                data === true
                                                                    ? 12
                                                                    : 12
                                                            }
                                                            sm={
                                                                data === true
                                                                    ? 12
                                                                    : 6
                                                            }
                                                            md={
                                                                data === true
                                                                    ? 12
                                                                    : 4
                                                            }
                                                            lg={
                                                                data === true
                                                                    ? 12
                                                                    : 4
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <motion.div
                                                                initial="hidden"
                                                                animate="visible"
                                                                variants={
                                                                    keyframesExample
                                                                }
                                                            >
                                                                <Paper
                                                                    key={
                                                                        item.asset_id
                                                                    }
                                                                    ref={
                                                                        cardRef
                                                                    }
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "center",
                                                                        marginTop:
                                                                            "4",
                                                                    }}
                                                                >
                                                                    <Cards
                                                                        isActive={
                                                                            cabinDetails.asset_id ==
                                                                            item.asset_id
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        actions={
                                                                            <Box
                                                                                sx={{
                                                                                    display:
                                                                                        "flex",
                                                                                    justifyContent:
                                                                                        "center",
                                                                                    padding: 5,
                                                                                    width: 300,
                                                                                }}
                                                                            >
                                                                                <Button
                                                                                    variant={
                                                                                        id ==
                                                                                        item.asset_id
                                                                                            ? "contained"
                                                                                            : "outlined"
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setId(
                                                                                            item.asset_id
                                                                                        );
                                                                                        setData(
                                                                                            true
                                                                                        );
                                                                                        setCabinDetails(
                                                                                            item
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    Check
                                                                                    Time
                                                                                    Slots
                                                                                </Button>
                                                                            </Box>
                                                                        }
                                                                        data={
                                                                            item
                                                                        }
                                                                    />
                                                                </Paper>
                                                            </motion.div>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </motion.div>
                                    </Box>

                                    {data === true ? (
                                        <Box
                                            className={classes.customScrollbar}
                                            sx={{
                                                height:
                                                    data === true
                                                        ? "80vh"
                                                        : null,
                                                overflowY:
                                                    data === true
                                                        ? "scroll"
                                                        : null,
                                            }}
                                            width={!mdUp ? "100%" : "70%"}
                                        >
                                            <Box padding={2}>
                                                <Wrapper
                                                    transitionData={{
                                                        transition: "Slide",
                                                        otherProps: "down",
                                                    }}
                                                    title={`${cabinDetails?.asset_name}`}
                                                    actions={
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            sx={{
                                                                borderRadius: 3,
                                                            }}
                                                            onClick={() => {
                                                                setId("");
                                                                setData(false);
                                                            }}
                                                        >
                                                            X
                                                        </Button>
                                                    }
                                                    Content={
                                                        <BookingSlots
                                                            asset={asset}
                                                            value={value}
                                                            cabinDetails={
                                                                cabinDetails
                                                            }
                                                        />
                                                    }
                                                />
                                            </Box>
                                        </Box>
                                    ) : null}
                                </Stack>
                            </Paper>
                        </Box>

                        {/* --------------------------------------------------- */}
                    </>
                }
            />
        </div>
    );
};
