export const CRO_ENQUIRY_LOG_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        width: 150,
    },
    {
        field: "admissionNo",
        headerName: "admissionNo",
        width: 150,
    },
    {
        field: "name",
        headerName: "name",
        width: 250,
    },
    {
        field: "mobile",
        headerName: "mobile",
        width: 150,
    },
    {
        field: "class",
        headerName: "class",
        width: 150,
    },
    {
        field: "course",
        headerName: "Course",
        width: 150,
    },
    {
        field: "subject",
        headerName: "Subject",
        width: 150,
    },
    {
        field: "enquiryType",
        headerName: "Enquiry Type",
        width: 180,
    },
    {
        field: "enquiryRemarks",
        headerName: "Enquiry Remarks",
        width: 150,
    },
    {
        field: "enquiryDate",
        headerName: "Enquiry Date",
        width: 150,
    },
];
