import React, { useEffect } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    SaveButton,
} from "../../../mui/Buttons/Buttons";
import { Box, Button, Stack, TextField } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
    addModulesToBaseCourses,
    clearModulesData,
    getModulesForBaseCourses,
    modulesData,
} from "../../../../redux/features/researchSlice";
import * as yup from "yup";
import { useSnackbar } from "notistack";

export const AddModuleForm = ({ coursesBaseId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { modulesForBaseCourses, loading } = useSelector(
        (state) => state.research
    );
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [time, setTime] = React.useState([{ time: new Date() }]);

    const validationSchema = yup.object().shape({
        modules: yup.array().of(
            yup.object().shape({
                moduleSrlNo: yup.number().required("please fill moduleSrlNo"),
                // moduleContent: yup
                //     .string()
                //     .required("please fill moduleContent"),
            })
        ),
    });

    const initialModuleSrlNo = modulesForBaseCourses?.length + 1;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            baseCourseId: coursesBaseId,
            modules: [
                {
                    moduleSrlNo: initialModuleSrlNo,
                    moduleContent: "",
                },
            ],
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            const data = {
                baseCourseId: values.baseCourseId,
                modules: [
                    {
                        moduleSrlNo: values.modules.moduleSrlNo,
                        moduleContent: values.modules.moduleContent,
                    },
                ],
            };

            // dispatch(modulesData(values));
            dispatch(addModulesToBaseCourses(values)).then((res) => {
                if (
                    res.payload.status === "failed" ||
                    res.payload.status === "error"
                ) {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    resetForm();
                    setOpen(false);
                    // dispatch(clearModulesData());
                    dispatch(getModulesForBaseCourses(coursesBaseId));

                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });

            // setOpen(false);
        },
    });

    const handleAdd = () => {
        let timeItem = {
            time: "",
        };

        let newTime = [...time];
        newTime.push(timeItem);

        setTime(newTime);

        let item = {
            moduleSrlNo: initialModuleSrlNo + formik.values.modules.length,
            moduleContent: "",
        };

        let values = [...formik.values.modules];
        values.push(item);
        formik.setFieldValue("modules", values);
    };

    const handleRemove = (i) => {
        if (formik.values.modules.length > 1) {
            let values = [...formik.values.modules];
            let newTime = [...time];

            newTime.splice(i, 1);
            values.splice(i, 1);

            formik.setFieldValue("modules", values);
            setTime(newTime);
        } else {
            formik.setFieldValue("modules", [
                {
                    moduleSrlNo: initialModuleSrlNo,
                    moduleContent: "",
                },
            ]);
            setTime([{ time: new Date() }]);
        }
    };

    const handleSlNoChange = (i, e) => {
        let values = [...formik.values.modules];
        values[i] = { ...values[i], moduleSrlNo: e.target.value };
        formik.setFieldValue("modules", values);
    };

    const handleModuleNameChange = (i, e) => {
        let values = [...formik.values.modules];
        values[i] = { ...values[i], moduleContent: e.target.value };
        formik.setFieldValue("modules", values);
    };

    return (
        <div>
            <GlobalDialog
                title="Add Module"
                fullWidth
                maxWidth={"md"}
                open={open}
                setOpen={setOpen}
                popupButton={
                    <AddButton
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Add New Module
                    </AddButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    {formik.values.modules.map((item, i) => {
                                        return (
                                            <Box
                                                key={i}
                                                sx={{
                                                    backgroundColor: "grey.200",
                                                    padding: 2,
                                                    marginBottom: 2,
                                                    borderRadius: 2,
                                                }}
                                            >
                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                >
                                                    <TextField
                                                        type="number"
                                                        name="moduleSrlNo"
                                                        value={
                                                            formik.values
                                                                .modules[i]
                                                                .moduleSrlNo
                                                        }
                                                        label="slNo"
                                                        onChange={(e) =>
                                                            handleSlNoChange(
                                                                i,
                                                                e
                                                            )
                                                        }
                                                        error={
                                                            formik.touched
                                                                .moduleSrlNo &&
                                                            Boolean(
                                                                formik.errors
                                                                    .moduleSrlNo
                                                            )
                                                        }
                                                        helperText={
                                                            formik.errors
                                                                .moduleSrlNo
                                                        }
                                                        disabled
                                                    />
                                                    <TextField
                                                        name="moduleContent"
                                                        value={
                                                            formik.values
                                                                .modules[i]
                                                                .moduleContent
                                                        }
                                                        fullWidth
                                                        label="Module Content"
                                                        onChange={(e) =>
                                                            handleModuleNameChange(
                                                                i,
                                                                e
                                                            )
                                                        }
                                                        error={
                                                            formik.touched
                                                                .moduleContent &&
                                                            Boolean(
                                                                formik.errors
                                                                    .moduleContent
                                                            )
                                                        }
                                                        helperText={
                                                            formik.errors
                                                                .moduleContent
                                                        }
                                                        required
                                                    />
                                                </Stack>
                                                {formik.values.modules.length +
                                                    initialModuleSrlNo -
                                                    1 ===
                                                formik.values.modules[i]
                                                    .moduleSrlNo ? (
                                                    <Stack
                                                        direction={"row"}
                                                        gap={2}
                                                    >
                                                        <Button
                                                            onClick={handleAdd}
                                                        >
                                                            <Icon
                                                                icon="material-symbols:add"
                                                                height="28"
                                                            />
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                handleRemove(i)
                                                            }
                                                        >
                                                            <Icon
                                                                icon="material-symbols:remove"
                                                                height="28"
                                                            />
                                                        </Button>
                                                    </Stack>
                                                ) : null}
                                            </Box>
                                        );
                                    })}

                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={1}>
                                            <CancelButton
                                                action={() => {
                                                    if (
                                                        formik.values.modules[0]
                                                            .moduleSrlNo ===
                                                            1 &&
                                                        formik.values.modules[0]
                                                            .moduleContent ===
                                                            ""
                                                    ) {
                                                        dispatch(
                                                            clearModulesData()
                                                        );
                                                    }
                                                    setOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton
                                                action={() => {}}
                                                type="submit"
                                            >
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
