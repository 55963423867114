import { Box, Button, Chip, Paper, Typography } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import CartCourseComponent from "./CartCourseComponent";
import NoDataFound from "../../../../components/NodataIndication/NoDataFound";
import { motion } from "framer-motion";

const CartCourses = ({ totalCourses, setTotalCourses }) => {
  const handleSubmit = () => {
    alert("hello");
  };
  return (
    <Box sx={{ paddingTop: 2 }}>
      <Paper elevation={2} sx={{ borderRadius: 3, padding: 1 }}>
        <Box padding={2}>
          <Chip
            sx={{ backgroundColor: "#702dff", color: "white" }}
            variant="contained"
            icon={
              <Icon
                icon="hugeicons:course"
                height={22}
                width={22}
                color="white"
              />
            }
            label={
              <Typography variant="h6">Details of Added Courses</Typography>
            }
          />
        </Box>
        <Box padding={1}>
          <GlobalWrapper>
            {totalCourses.length != 0 ? (
              <Box>
                <CartCourseComponent
                  setTotalCourses={setTotalCourses}
                  totalCourses={totalCourses}
                />
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}
                >
                  <Button
                    onClick={handleSubmit}
                    sx={{
                      color: "white",
                      background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
                      borderRadius: 3,
                    }}
                  >
                    <Icon icon="mdi:cart-outline" height={22} width={22} />
                    Confirm Purchase
                  </Button>
                </Box>
              </Box>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
              >
                <NoDataFound />
              </motion.div>
            )}
          </GlobalWrapper>
        </Box>
      </Paper>
    </Box>
  );
};

export default CartCourses;
