import React from "react";
import { Icon } from "@iconify/react";
import { Button, Box, Paper } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LOGS_TABLE_HEAD as columns } from "../Todo/LogsTableHead";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreatedTaskUtilsMenu(params) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>
                {params.row.taskStatus}
                <Icon icon="carbon:view-filled" width="20" height="20" />
            </Button>

            <Dialog
                maxWidth={"lg"}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogActions>
                    <Button onClick={handleClose}>
                        <Icon icon="iconoir:cancel" width="30" height="30" />
                    </Button>
                </DialogActions>
                <DialogTitle>{"Approval Status"}</DialogTitle>

                <DialogContent>
                    <Box width={900}>
                        <Paper
                            elevation={3}
                            sx={{ borderRadius: 1, width: "100%" }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                    display: "flex",
                                }}
                            >
                                <DataGrid
                                    loading={params.row.loading}
                                    rows={params.row.taskLog}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    getRowId={(row) => row.cameOn}
                                    autoHeight
                                    getRowHeight={() => "auto"}
                                    // components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}
