import { Button } from '@mui/material'
import { Icon } from '@iconify/react'

const DeleteButton = ({ action, size, children, disabled, sx }) => {
  return (
    <Button
      sx={{ ...sx, backgroundColor: '#ffe4de', color: '#c03530' }}
      onClick={action}
      autoFocus={false}
      disabled={disabled}
      size={size}>
      <Icon icon="material-symbols:delete-outline-rounded" width="25" height="25" />
      {children}
    </Button>
  )
}

const CancelButton = ({ action, size, children, component, to }) => {
  return (
    <Button
      size={size}
      component={component}
      to={to}
      sx={{ backgroundColor: '#bbd1e4cf', color: '#3173b4' }}
      onClick={action}>
      <Icon icon="radix-icons:cross-2" width="22" height="22" /> {children}
    </Button>
  )
}

const SaveButton = ({ action, type, size, children, disabled }) => {
  return (
    <Button
      size={size}
      disabled={disabled}
      sx={{
        marginLeft: 2,
        backgroundColor: '#c8facd',
        color: '#0db05d',
      }}
      onClick={action}
      type={type}>
      <Icon icon="mdi:content-save-check-outline" width="25" height="25" />

      {children}
    </Button>
  )
}

const ResetButton = ({ action, size, type, disabled, children }) => {
  return (
    <Button
      type={type}
      size={size}
      sx={{ backgroundColor: '#eff4f9', color: '#3173b4' }}
      onClick={action}
      disabled={disabled}>
      {children}
    </Button>
  )
}

const SendButton = ({ type, size, action, disabled, children, variant }) => {
  return (
    <Button
      variant={variant}
      type={type}
      size={size}
      sx={{
        backgroundColor: '#7e4d8b36',
        color: '#7e4d8b',
        boxShadow: 5,
        margin: 0.5,
      }}
      onClick={action}
      disabled={disabled}>
      {children}
      <Icon icon="material-symbols:send-outline-rounded" width="20" height="20" />
    </Button>
  )
}

const NextButton = ({ action, disabled, children, type, size, variant }) => {
  return (
    <Button
      variant={variant}
      type={type}
      size={size}
      sx={{
        ':hover': {
          bgcolor: '#ffffff',
          color: '#2f72b3',
        },
        backgroundColor: '#2f72b3',
        color: '#ffffff',
        boxShadow: 5,
        margin: 0.5,
        boxShadow: 5,
      }}
      onClick={action}
      disabled={disabled}>
      {children}
      <Icon icon="tabler:player-track-next" width="25" height="25" />
    </Button>
  )
}

const SubmitButton = ({ action, disabled, children, type, size, variant, value, component, to, sx }) => {
  return (
    <Button
      variant={variant}
      type={type}
      size={size}
      onClick={action}
      value={value}
      component={component}
      to={to}
      disabled={disabled}
      sx={{ sx, backgroundColor: '#7e4d8b6e', color: '#7e4d8b' }}>
      <Icon icon="grommet-icons:status-good" width="25" height="25" />
      {children}
    </Button>
  )
}

const EditButton = ({ action, disabled, children, type, size, variant, value, component, to, sx }) => {
  return (
    <Button
      variant={variant}
      type={type}
      size={size}
      onClick={action}
      value={value}
      component={component}
      disabled={disabled}
      to={to}
      sx={{ ...sx, backgroundColor: '#7e4d8b6e', color: '#7e4d8b' }}>
      <Icon icon="material-symbols:edit-note" width="25" height="25" />
      {children}
    </Button>
  )
}

const UpdateButton = ({ action, type, size, children, disabled }) => {
  return (
    <Button
      disabled={disabled}
      size={size}
      sx={{
        marginLeft: 2,
        backgroundColor: '#c8facd',
        color: '#0db05d',
      }}
      onClick={action}
      type={type}>
      <Icon icon="radix-icons:update" width="25" height="25" />

      {children}
    </Button>
  )
}

const AddButton = ({ action, size, children, component, to, disabled, variant }) => {
  return (
    <Button
      variant={variant}
      size={size}
      component={component}
      to={to}
      sx={{ backgroundColor: '#eff4f9', color: '#3173b4' }}
      onClick={action}
      disabled={disabled}>
      <Icon icon="mingcute:add-line" width="20" height="20" />

      {children}
    </Button>
  )
}

const LogButton = ({ action, disabled, children, type, size, variant, value, sx }) => {
  return (
    <Button
      variant={variant}
      type={type}
      size={size}
      onClick={action}
      value={value}
      disabled={disabled}
      sx={{ sx, backgroundColor: '#7e4d8b6e', color: '#7e4d8b' }}>
      <Icon icon="gg:details-more" width="22" height="22" />

      {children}
    </Button>
  )
}

const SearchButton = ({ action, disabled, children, type, size, variant, value, sx }) => {
  return (
    <Button
      variant={variant}
      type={type}
      size={size}
      onClick={action}
      value={value}
      disabled={disabled}
      sx={{ sx, backgroundColor: '#7e4d8b6e', color: '#7e4d8b' }}>
      <Icon icon="mdi:file-search-outline" width="25" height="25" />

      {children}
    </Button>
  )
}

const ApproveButton = ({ action, type, size, children, disabled }) => {
  return (
    <Button
      disabled={disabled}
      size={size}
      sx={{
        marginLeft: 2,
        backgroundColor: '#c8facd',
        color: '#0db05d',
      }}
      onClick={action}
      type={type}>
      <Icon icon="mdi:approve" width="20" height="20" />
      {children}
    </Button>
  )
}
const RejectButton = ({ action, size, children, disabled, sx, type }) => {
  return (
    <Button
      sx={{ ...sx, backgroundColor: '#ffe4de', color: '#c03530' }}
      onClick={action}
      autoFocus={false}
      disabled={disabled}
      size={size}
      type={type}>
      <Icon icon="radix-icons:cross-2" />
      {children}
    </Button>
  )
}

const LogOutButton = ({ action, size, children, disabled, sx, type }) => {
  return (
    <Button
      sx={{ ...sx, backgroundColor: '#ffe4de', color: '#c03530' }}
      onClick={action}
      autoFocus={false}
      disabled={disabled}
      size={size}
      type={type}>
      <Icon icon="bx:log-out" width="20" height="20" />
      {children}
    </Button>
  )
}

const ActiveButton = ({ action, size, children, disabled, sx, type }) => {
  return (
    <Button
      sx={{ ...sx, backgroundColor: '#c8facd', color: '#0db05d' }}
      onClick={action}
      autoFocus={false}
      disabled={disabled}
      size={size}
      type={type}>
      <Icon
        width="30"
        height="30"
        style={{
          marginRight: '2px',
        }}
        icon="openmoji:toggle-button-state-b"
      />
      {children}
    </Button>
  )
}

const InactiveButton = ({ action, size, children, disabled, sx, type }) => {
  return (
    <Button
      sx={{ ...sx, backgroundColor: '#ffe4de', color: '#c03530' }}
      onClick={action}
      autoFocus={false}
      disabled={disabled}
      size={size}
      type={type}>
      <Icon
        width="30"
        height="30"
        style={{
          marginRight: '2px',
        }}
        icon="openmoji:toggle-button"
      />
      {children}
    </Button>
  )
}

export {
  DeleteButton,
  CancelButton,
  SaveButton,
  ResetButton,
  SendButton,
  NextButton,
  SubmitButton,
  EditButton,
  UpdateButton,
  AddButton,
  LogButton,
  SearchButton,
  ApproveButton,
  RejectButton,
  LogOutButton,
  ActiveButton,
  InactiveButton,
}
