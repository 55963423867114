import React, { useEffect, useState } from "react";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { Box, Card, CardHeader } from "@mui/material";
// utils
// import { fNumber } from "../../../../utils/formatNumber";
//
import BaseOptionChart from "./BaseOptionChart";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { leaveCountwithDateDuration } from "../../../redux/features/dashboardAnalyticsSlice";
import { useDispatch, useSelector } from "react-redux";

// ----------------------------------------------------------------------

const attendanceData = {
    present: 25172,
    absentCount: 110,
    weekoffCount: 5324,
    CompensatoryOff: 302.5,
    onDuty: 154,
    workFromHome: 694.5,
    halfDay: 0,
    holiday: 1015,
    leaveCasualLeave: 632.5,
    leaveSickLeave: 677,
    leavePaidLeave: 215.5,
    leaveMaternityLeave: 15,
    leaveWithoutPay: 253.5,
    Dot: 457,
};

export const BarChart = () => {
    const dispatch = useDispatch();
    const { leaveCountwithDateDurationData } = useSelector(
        (state) => state.dashboardAnalytics
    );
    const [fromDate, setFromeDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());

    const mappableArray = Object.entries(leaveCountwithDateDurationData).map(
        ([key, value]) => ({
            label: key,
            value,
        })
    );
    const dataLabel = mappableArray.map((item) => item.label);
    const dataValues = mappableArray.map((item) => item.value);
    const CHART_DATA = [{ data: dataValues }];

    const chartOptions = merge(BaseOptionChart(), {
        tooltip: {
            marker: { show: false },
            y: {
                formatter: (seriesName) => seriesName,
                title: {
                    formatter: () => "",
                },
            },
        },
        plotOptions: {
            bar: { horizontal: true, barHeight: "40%", borderRadius: 2 },
        },
        xaxis: {
            categories: dataLabel,
        },
    });
    useEffect(() => {
        const selectedFromDate = fromDate ? fromDate.toDate() : null;
        const selectedToDate = toDate ? toDate.toDate() : null;
        // const day = selectedDate ? selectedDate.getDate() : null;
        const fromMonth = selectedFromDate
            ? selectedFromDate.getMonth() + 1
            : null; // Month is zero-based
        const fromYear = selectedFromDate
            ? selectedFromDate.getFullYear()
            : null;
        const toMonth = selectedToDate ? selectedToDate.getMonth() + 1 : null; // Month is zero-based
        const toYear = selectedToDate ? selectedToDate.getFullYear() : null;
        const data = {
            fromYear: fromYear,
            fromMonth: fromMonth,
            toYear: toYear,
            toMonth: toMonth,
        };
        dispatch(leaveCountwithDateDuration(data));
    }, [toDate, fromDate]);

    return (
        <Card>
            <CardHeader
                title="Employee Attendance By Month Range"
                subheader="(+43%) than last year"
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box padding={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={["year", "month"]}
                            variant="filled"
                            label="From Date"
                            value={fromDate}
                            onChange={(newValue) => setFromeDate(newValue)}
                        />
                    </LocalizationProvider>
                </Box>
                <Box padding={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={["year", "month"]}
                            variant="filled"
                            label="TO Date"
                            value={toDate}
                            onChange={(newValue) => setToDate(newValue)}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box sx={{ mx: 3 }}>
                <ReactApexChart
                    type="bar"
                    series={CHART_DATA}
                    options={chartOptions}
                    height={364}
                />
            </Box>
        </Card>
    );
};
