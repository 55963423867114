import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../components/Wrapper/GlobalWrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { useSelector, useDispatch } from "react-redux";
import { ASSESSMENT_REPORT_TABLE_HEAD } from "../../../components/datagrid/myworkspace/speakpro/assessmentReport/AssessmentReportTableHead";
import { getAssessmentReports } from "../../../redux/features/assessmentToolSlice";
const AssessmentReports = () => {
    const dispatch = useDispatch();

    const [gridSize, setGridSize] = useState(10);
    const { loading, assessmentReportData } = useSelector(
        (state) => state.assessmentTool
    );

    useEffect(() => {
        dispatch(getAssessmentReports());
    }, []);

    return (
        <GlobalWrapper title="Assessment Reports">
            <Paper elevation={3}>
                <Box sx={{ width: "100%", padding: 0 }}>
                    <DataGrid
                        components={{ Toolbar: CustomGridToolbar }}
                        loading={loading}
                        autoHeight
                        sx={{
                            "& .MuiDataGrid-columnHeaderTitle": {
                                textOverflow: "clip",
                                whiteSpace: "break-spaces",
                                lineHeight: 1,
                            },
                        }}
                        // getRowHeight={() => "auto"}
                        rows={assessmentReportData}
                        columns={ASSESSMENT_REPORT_TABLE_HEAD}
                        getRowId={(row) => row.serialNumber}
                        pageSize={gridSize}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        onPageSizeChange={(newGridSize) =>
                            setGridSize(newGridSize)
                        }
                    />
                </Box>
            </Paper>
        </GlobalWrapper>
    );
};

export default AssessmentReports;
