import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { checkAttendanceMarked } from '../../redux/features/AttendanceCheckSlice'
import AttendanceView from '../../views/user/EmployeeServices/Attendance/AttendanceView'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

const AttendanceCheck = () => {
  const { isAttenanceMarked, loading } = useSelector((state) => state.attendanceMarked)

  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const handlePopUp = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    dispatch(checkAttendanceMarked()).then((res) => {
      if (!res.payload.data.isAttenanceMarked) {
        handlePopUp()
        const cred = JSON.parse(localStorage.getItem('cred')) || {}
        const updatedCred = {
          ...cred,
          approvalRequestsSeen: false,
        }
        localStorage.setItem('cred', JSON.stringify(updatedCred))
      }
    })
  }, [])

  if (!loading) {
    return isAttenanceMarked ? (
      <Outlet />
    ) : (
      <>
        <AttendanceView />
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            <Typography variant="h5" align="center" gutterBottom>
              Attendance Required
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
              Please Mark Your Attendance To proceed And Access The System
            </Typography>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              size="small"
              sx={{ width: '150px', borderRadius: '8px' }}>
             Check In
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return null
}

export default AttendanceCheck
