import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { AddButton } from "../../../../components/mui/Buttons/Buttons";
import { Box, Button, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getFoodList,
  monthlyFoodStatus,
  removeMealStatus,
} from "../../../../redux/features/foodSlice";
import { MealStatusTableColumn } from "../../../../components/datagrid/myworkspace/hr/Food/MealStatusTableColumn";
import dayjs from "dayjs";
import AddMealStatus from "./AddMealStatus";
import DatePickerCustom from "../../../../components/DatePicker/DatePickerCustom";
import AddFood from "./AddFood";


const MealStatus = () => {
  const [open, setOpen] = useState(false);
  const [gridSize, setGridSize] = useState(10);
  const { loading, foodList, monthlyStatus } = useSelector(
    (store) => store.food
  );
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  useEffect(() => {
    dispatch(monthlyFoodStatus(selectedDate));
  }, [open, selectedDate]);

  return (
    <GlobalDialog
      title={"Meal status"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"md"}
      closeButton={true}
      popupButton={
        <Stack direction= {{xs: "column", md: "row"}}  gap={1}>
          <AddFood />
          <Button  sx={{ backgroundColor: "#eff4f9", color: "#3173b4" }} onClick={() => setOpen(true)}>Meal Status</Button>       
        </Stack>
      }
      content={
        <>
          <Box>
            <Box sx={{ mb: 1 }}>
              <AddMealStatus date={selectedDate} />
            </Box>
            <Box sx={{mb:1,width:190}}>
              <DatePickerCustom
                value={dayjs(selectedDate)}
                setValue={(date) => setSelectedDate(date.format("YYYY-MM-DD"))}
                viewDetails={["month"]}
              />
            </Box>
            <DataGrid
              components={{ Toolbar: CustomGridToolbar }}
              rows={monthlyStatus}
              columns={MealStatusTableColumn}
              loading={loading}
              getRowId={(row) => row.serialNumber}
              pageSize={gridSize}
              autoHeight
              rowsPerPageOptions={[5, 10, 25, 50]}
              onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
            />
          </Box>
        </>
      }
    />
  );
};

export default MealStatus;
