import {
    Box,
    Button,
    Container,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import Page from "../../../../components/Page";
import { LEAVE_ANALYTICS_TABLE_HEAD as columns } from "../../../../components/datagrid/leave/LeaveAnalyticsTableHead";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveAnalytics } from "../../../../redux/features/leaveSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form, FormikProvider, useFormik } from "formik";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import BasicButton from "../../../../components/mui/Button";
import * as yup from "yup";
import { getPreData } from "../../../../redux/features/userSlice";
import { Link } from "react-router-dom";
import { MONTHS } from "../../../../utils/constants/daysConst";
import dayjs from "dayjs";

const LeaveAnalytics = () => {
    const [value, setValue] = useState(null);
    const [department, setDepartment] = useState([]);
    const [gridSize, setGridSize] = useState(5);

    const dispatch = useDispatch();
    const { leave, users } = useSelector((state) => state);

    const schema = yup.object().shape({
        date: yup.string().required("This field is required"),
        departmentId: yup.number().required("This field is Required"),
        status: yup.number().required("please fill this feild"),
    });

    const formik = useFormik({
        initialValues: {
            year: "",
            month: 0,
            departmentId: 0,
            status: 1,
        },
        validationSchema: schema,
        onSubmit: (values) => {
            dispatch(
                getLeaveAnalytics(
                    `${values.year}/${values.month}/${values.departmentId}/${values.status}`
                )
            );
        },
    });

    const handleChange = (e) => {
        setValue(e);
        const formated = e.toISOString().split("-")[0];

        formik.setFieldValue("year", formated);
    };

    useEffect(() => {
        dispatch(getPreData());
        let e = new Date();
        setValue(e);
        const year = e.toISOString().split("-")[0];

        formik.setFieldValue("year", year);
        dispatch(
            getLeaveAnalytics(
                `${year}/0/${formik.values.departmentId}/${formik.values.status}`
            )
        );
    }, []);

    useEffect(() => {
        if (users.preData.departments?.length > 0) {
            setDepartment([
                { departmentName: "All", departmentId: 0 },
                ...users.preData.departments,
            ]);
            formik.setFieldValue("departmentId", 0);
            formik.setFieldValue("status", 1);
        }
    }, [users.preData]);

    return (
        <div>
            <Page>
                <Container component={"main"}>
                    <Typography variant="h4">Leave Analytics</Typography>
                    <Breadcrumb />

                    <Box sx={{ width: "100%" }}>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Stack
                                    direction="row"
                                    sx={{
                                        width: "100%",
                                        justifyContent: "right",
                                        marginBottom: 2,
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        component={Link}
                                        to="/my-tasks/reporting-head-bypass-count"
                                    >
                                        Reporting Heads bypass count
                                    </Button>
                                </Stack>

                                <Stack
                                    spacing={2}
                                    direction="row"
                                    sx={{ padding: 2 }}
                                >
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            label="Year"
                                            value={dayjs(value)}
                                            inputFormat="YYYY"
                                            onChange={handleChange}
                                            disableFuture
                                        />
                                        {/* <DatePicker
                                            views={["year"]}
                                            label="Year"
                                            inputFormat="YYYY"
                                            value={dayjs(value)}
                                            onChange={handleChange}
                                            disableFuture
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="filled"
                                                    error={Boolean(
                                                        formik.errors.year
                                                    )}
                                                    helperText={
                                                        formik.errors.year
                                                    }
                                                />
                                            )}
                                        /> */}
                                    </LocalizationProvider>

                                    <GlobalSelectField
                                        options={{
                                            name: "month",
                                            formik: formik,
                                            label: "Months",
                                            data: MONTHS,
                                            keys: {
                                                id: "id",
                                                value: "name",
                                            },
                                        }}
                                    />

                                    <GlobalSelectField
                                        options={{
                                            name: "departmentId",
                                            formik: formik,
                                            label: "Department",
                                            data: department,
                                            keys: {
                                                value: "departmentName",
                                                id: "departmentId",
                                            },
                                        }}
                                    />
                                    <GlobalSelectField
                                        options={{
                                            name: "status",
                                            formik: formik,
                                            label: "Status",
                                            data: [
                                                {
                                                    id: 0,
                                                    value: "Pending",
                                                },
                                                {
                                                    id: 1,
                                                    value: "Approved",
                                                },
                                                {
                                                    id: 2,
                                                    value: "Rejected",
                                                },
                                            ],
                                            keys: {
                                                id: "id",
                                                value: "value",
                                            },
                                        }}
                                    />

                                    <BasicButton
                                        variant="contained"
                                        type="submit"
                                    >
                                        Apply Filter
                                    </BasicButton>
                                </Stack>
                            </Form>
                        </FormikProvider>
                        <DataGrid
                            loading={leave.loading}
                            rows={leave.analytics}
                            columns={columns}
                            getRowId={(row) => row.empId}
                            sx={{
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                            }}
                            pageSize={gridSize}
                            onPageSizeChange={(newGridSize) =>
                                setGridSize(newGridSize)
                            }
                            autoHeight
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            components={{ Toolbar: CustomGridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Box>
                </Container>
            </Page>
        </div>
    );
};

export default LeaveAnalytics;
