import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GlobalDialog({
    content,
    actionButton,
    title,
    open,
    setOpen,
    popupButton,
    maxWidth,
    closeButton,
    fullWidth,
    closeCondition,
}) {
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick" && "escapeKeyDown") return;
        setOpen(false);
    };
    const useStyles = makeStyles((theme) => ({
        customScrollbar: {
            "&::-webkit-scrollbar": {
                width: "8px",
            },
            "&::-webkit-scrollbar-track": {
                background: theme.palette.grey[200],
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "6px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.primary.dark,
            },
        },
    }));
    const classes = useStyles();
    return (
        <div>
            <Box>{popupButton}</Box>
            <Dialog
                // onClick={(event) => event.stopPropagation()}
                // onClick={(event) => event.target = document.body}
                fullWidth={fullWidth}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth={maxWidth}
            >
                <Box sx={{ backgroundColor: "grey.200" }}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box>
                            <DialogTitle>{title}</DialogTitle>
                        </Box>
                        {closeButton === true ? (
                            <Box
                                sx={{
                                    padding: 1,
                                }}
                            >
                                <Button
                                    size="small"
                                    sx={{ borderRadius: 3 }}
                                    variant="outlined"
                                    onClick={() => {
                                        if (closeCondition) {
                                          closeCondition();
                                        } else {
                                          setOpen(false);
                                        }
                                      }}
                                >
                                    x
                                </Button>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                <Divider />
                <DialogContent className={classes.customScrollbar}>
                    <Box>{content}</Box>
                </DialogContent>
                <DialogActions>{actionButton}</DialogActions>
            </Dialog>
        </div>
    );
}
