import { Stack, TextField, Box } from "@mui/material";
import React from "react";
import { useState } from "react";
import { apiCallData } from "../../../../../redux/features/crmSlice";
import { UpdateButton } from "../../../../mui/Buttons/Buttons";
import {
  updateSubjectSessionCount,
  getSubjectSessionCount,
  getAdmissionRegister,
} from "../../../../../redux/features/crmSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const Update = React.memo((params) => {
  const [data, setData] = useState(params.row.totalSessions);
  const dispatch = useDispatch();
  const datas = useSelector((state) => state.crm);
  const { enqueueSnackbar } = useSnackbar();

  const values = {
    sessionCount: data,
    subjectEnrollmentId: params.row.subjectEnrollmentId,
  };

  const handleClick = () => {
    dispatch(updateSubjectSessionCount(values)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        dispatch(getSubjectSessionCount(datas.callData.courseEnrollmentId));
        dispatch(getAdmissionRegister(datas.admissionRegData));

        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
      }
    });
  };
  return (
    <div>
      <form>
        <Stack direction={"row"}>
          <TextField
            required
            sx={{ width: 100 }}
            value={data}
            onChange={(e) => {
              setData(e.target.value);
            }}
          />
          <Box marginTop={1.3}>
            <UpdateButton
              type="submit"
              disabled={data ? false : true}
              action={handleClick}
            >
              Update
            </UpdateButton>
          </Box>
        </Stack>
      </form>
    </div>
  );
});

export default Update;
