import React from "react";
import { CancelButton, DeleteButton } from "../../../../mui/Buttons/Buttons";
import { useDispatch } from "react-redux";
import {
  deleteSubjectEnrollment,
  getSubjectSessionCount,
} from "../../../../../redux/features/crmSlice";
import { useSnackbar } from "notistack";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { useState } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

export const DeleteSubjectSessionCount = React.memo((params) => {
  const courseEnrollmentId = useSelector((state) => state.crm);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  return (
    <div>
      <GlobalDialog
        open={open}
        setOpen={setOpen}
        title="Delete.."
        popupButton={
          <DeleteButton action={() => setOpen(true)}>Delete</DeleteButton>
        }
        content={
          <>
            <Typography variant="body2">
              Are you sure .You want to delete this Session ?
            </Typography>
          </>
        }
        actionButton={
          <>
            <CancelButton action={() => setOpen(false)}>Cancel</CancelButton>
            <DeleteButton
              action={() => {
                dispatch(
                  deleteSubjectEnrollment(params.row.subjectEnrollmentId)
                ).then((res) => {
                  if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                  } else if (res.payload.status === "success") {
                    setOpen(false);
                    dispatch(
                      getSubjectSessionCount(
                        courseEnrollmentId.callData.courseEnrollmentId
                      )
                    );
                    enqueueSnackbar(res.payload.message, {
                      variant: "success",
                    });
                  }
                });
              }}
            >
              {courseEnrollmentId.loading ? "Deleting..." : "Delete"}
            </DeleteButton>
          </>
        }
      />
    </div>
  );
});
