import { Delete } from "./Delete";
import { Edit } from "./Edit";

export const MOBILE_APP_STUDENTS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slno",
    },
    {
        field: "admissionNumber",
        headerName: "Admission No",
        width: 120,
    },
    {
        field: "studentName",
        headerName: "Name",
        width: 300,
    },
    {
        field: "email",
        headerName: "User Name",
        width: 400,
    },
    {
        field: "edit",
        headerName: "Edit",
        width: 100,
        renderCell: (params) => <Edit params={params.row} />,
    },
    {
        field: "delete",
        headerName: "Delete",
        width: 200,
        renderCell: (params) => <Delete adno={params.row.admissionNumber} />,
    },
];
