import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Button, Box } from "@mui/material";
import { Icon } from "@iconify/react";
import { TotalSessionsData } from "./TotalSessionsData";
import { useDispatch, useSelector } from "react-redux";
import {
    getSubjectSessionCount,
    apiCallData,
} from "../../../../../redux/features/crmSlice";
import SimpleLeader from "../../../../Loader/SimpleLoader";

export const TotalSessions = React.memo(
    ({ totalSessions, courseEnrollmentId, rowData }) => {
        const data = useSelector((state) => state.crm);
        const dispatch = useDispatch();
        const [open, setOpen] = React.useState(false);
        console.log("stategggggg", open);
        return (
            <div>
                <GlobalDialog
                    title="Subjects and Total Sessions"
                    open={open}
                    setOpen={setOpen}
                    closeButton={true}
                    maxWidth="xl"
                    popupButton={
                        <>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    console.log("clickedddddd");
                                    setOpen(true);

                                    dispatch(
                                        getSubjectSessionCount(
                                            courseEnrollmentId
                                        )
                                    );
                                    dispatch(
                                        apiCallData({
                                            courseEnrollmentId:
                                                courseEnrollmentId,
                                        })
                                    );
                                }}
                                size="small"
                                sx={{
                                    backgroundColor: "#17a2b8",
                                    color: "#fff",
                                }}
                            >
                                <Icon
                                    icon="la:wpforms"
                                    width="20"
                                    height="20"
                                />
                                {totalSessions}
                            </Button>
                        </>
                    }
                    content={
                        <>
                            <Box sx={{ width: 700 }}>
                                {data.loading ? (
                                    <SimpleLeader />
                                ) : (
                                    <TotalSessionsData setOpen={setOpen} />
                                )}
                            </Box>
                        </>
                    }
                />
            </div>
        );
    }
);
