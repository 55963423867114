import React from "react";
import { Paper, Typography, Box, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { CRM_LEADS_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/CRM Leads/CrmleadsTableHead";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import useResponsive from "../../../../Hooks/useResponsive";
import { getNewAdmissions } from "../../../../redux/features/crmSlice";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

export default function CrmLeads() {
    const data = useSelector((state) => state.crm);
    const dispatch = useDispatch();
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const [gridSize, setGridSize] = useState(5);
    const [value, setValue] = React.useState(dayjs());

    const handleDate = (newValue) => {
        setValue(newValue);
        let yer = `${newValue.toISOString().split("T")[0].split("-")[0]}/${
            newValue.toISOString().split("T")[0].split("-")[1]
        }`;
        if (newValue !== null && JSON.stringify(newValue.$d) !== "null") {
            const formated = newValue.toISOString().split("T")[0];
        }

        // setDate(yer);
        dispatch(getNewAdmissions(yer));
    };
    // const handleDate = (newValue) => {
    //     setValue(newValue);

    //     if (newValue !== null && newValue.isValid()) {
    //         const formattedDate = newValue.format("YYYY-MM-DD"); // Format the date as needed

    //         let yearAndMonth = formattedDate.substr(0, 7); // Extract year and month
    //         dispatch(getNewAdmissions(yearAndMonth));
    //     }
    // };

    useEffect(() => {
        let newValue = new Date();
        setValue(newValue);
        const yer = `${newValue.toISOString().split("T")[0].split("-")[0]}/${
            newValue.toISOString().split("T")[0].split("-")[1]
        }`;
        // console.log("yer----", yer);

        dispatch(getNewAdmissions(yer));
    }, []);

    useEffect(() => {
        // console.log("ROW DATA",data.data);
    }, [data]);

    return (
        <Wrapper
            title={"CRM Leads"}
            Content={
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: 1,
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                                <DatePicker
                                    views={["year", "month"]}
                                    value={dayjs(value)}
                                    label="Year and Month"
                                    onChange={handleDate}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            size={smUp ? "" : "small"}
                                            sx={{ width: smUp ? null : 150 }}
                                            variant="filled"
                                            helperText={null}
                                        />
                                    )}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                views={["year", "month"]}
                                label="Year and Month"
                                value={value}
                                onChange={handleDate}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size={smUp ? "" : "small"}
                                        sx={{ width: smUp ? null : 150 }}
                                        variant="filled"
                                        helperText={null}
                                    />
                                )}
                            />
                        </LocalizationProvider> */}
                    </Box>
                    <Paper elevation={3}>
                        <Box>
                            <DataGrid
                                loading={data.loading}
                                rows={data.data}
                                columns={columns.map((field)=>({
                                    ...field,headerAlign:"center",align:"center"
                                }))}
                                columnBuffer={30}
                                pageSize={gridSize}
                                autoHeight
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                getRowId={(row) => {
                                    return row.serialNumber;
                                }}
                                components={{
                                    Toolbar: CustomGridToolbar,
                                }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: {
                                            debounceMs: 500,
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                </>
            }
        />
    );
}
