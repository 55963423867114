import React from 'react'
import GlobalDeleteDialog from '../../../../mui/Dialogue/GlobalDeleteDialog'
import { savePaginationData } from '../../../../../redux/features/globalDatagridSlice'
import {useSelector } from 'react-redux'
import { deleteIncome, getIncome } from '../../../../../redux/features/incomeSlice'

const DeleteIncome = ({incomeId,paginationDetails,isVerified}) => {
    const {selectedDate, pageNumber, pageLimit }=paginationDetails
    const {loading}=useSelector((store)=>store.income)
    const { paginationData } = useSelector((state) => state.globalDataGrid)
  return (
    <GlobalDeleteDialog
    disabled={isVerified == "Verified"}
    title={"Delete"}
    deleteNote={"Are you sure? you want to delete this Income..?"}
    api={deleteIncome(incomeId)}
    upDateCall={ getIncome(paginationDetails)  }
    updatePaginationCall={savePaginationData({ ...paginationData, openAnchor: false })}
    loading={loading}
    />
  )
}

export default DeleteIncome