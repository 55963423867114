import imageCompression from 'browser-image-compression';

export async function compressImage(imageFile,maxSize) {
    // console.log(`originalFile size: ${(imageFile.size / 1024).toFixed(2)} KB`);
    const options = {
      maxSizeMB: maxSize || 0.05,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
    //   console.log(`compressedFile size: ${(compressedFile.size / 1024).toFixed(2)} KB`); // Log in KB
      const url = URL.createObjectURL(compressedFile); //it can use as src or angertag with download
    //   console.log(url);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }