import React from "react";
import Page from "../../../components/Page";
import { Paper, Stack, TextField, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import BasicTextField from "../../../components/mui/TextField";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import BasicButton from "../../../components/mui/Button";
import { Icon } from "@iconify/react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const CroCourseEnroll = () => {
    return (
        <Page
            sx={{
                width: "85%",
                margin: "0 auto",
            }}
        >
            <Typography variant="h4" sx={{ mb: 1 }}>
                Course Enroll
            </Typography>
            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
            <Paper elevation={3} sx={{ borderRadius: 1, mt: 4 }}>
                <Stack
                    sx={{
                        padding: 5,
                        gap: 2,
                    }}
                >
                    <BasicTextField fullWidth label="Student Name" />
                    <GlobalSelectField
                        options={{
                            label: "Course",
                            sx: {
                                width: "100%",
                            },
                        }}
                    />
                    <BasicTextField
                        fullWidth
                        label="Student Details (in Brief)"
                        multiline
                        rows={3}
                    />
                    <BasicTextField
                        fullWidth
                        label="Subjects & Session Count per Subject"
                        multiline
                        rows={3}
                    />

                    <BasicTextField fullWidth label="fee per session" />

                    <BasicTextField fullWidth label="Session per Week" />

                    <BasicTextField
                        fullWidth
                        label="Time preffered"
                        multiline
                        rows={3}
                    />

                    <GlobalSelectField
                        options={{
                            label: "Trainer Preffered",
                            sx: {
                                width: "100%",
                            },
                        }}
                    />

                    <BasicTextField fullWidth label="Total Sessions" />

                    <BasicTextField fullWidth label="Total fees" />
                    <GlobalSelectField
                        options={{
                            label: "fee status",
                            sx: {
                                width: "100%",
                            },
                        }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="Admission Confirmed On"
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    {...params}
                                />
                            )}
                        />
                    </LocalizationProvider>

                    <BasicTextField
                        fullWidth
                        label="Required Books and Publications"
                    />

                    <Stack flexDirection="row" gap={2} justifyContent="center">
                        <BasicButton
                            variant="contained"
                            sx={{
                                display: "flex",
                                gap: 1,
                                background: "#73879c",
                            }}
                        >
                            Reset
                        </BasicButton>
                        <BasicButton
                            variant="contained"
                            sx={{
                                display: "flex",
                                gap: 1,
                            }}
                        >
                            <Icon icon="ic:sharp-save" width="30" height="30" />
                            Enroll
                        </BasicButton>
                    </Stack>
                </Stack>
            </Paper>
        </Page>
    );
};

export default CroCourseEnroll;
