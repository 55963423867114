import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Button } from "@mui/material";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { Details } from "./Details";
import { Actions } from "./Actions";
import SelectionHeader from "../../../../components/mui/SelectionHeader/SelectionHeader";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const data = [
    {
        id: 1,
        trainedId: "INT2306TR00238",
        name: "ZULAIKHA HYFA HYDER",
        gender: "Female",
        email: "zulaikhahyfa566@gmail.com ",
        mobileNo: "8086455308",
        place: "MANGALORE",
        district: "KANNUR",
        qualification:
            "Afsal-Ul-Ulama Preliminary (Afsal-Ul-Ulama Preliminary)",
        view: "",
        subjects: 3000,
        slots: "",
        activeInactive: "",
        trainerUnavailability: "",
        employmentStatus: "Working",
        trainerAvailability: "",
        tutorialWatchedOn: "",
        signedUpOn: "2021-12-28 13:55:21",
        lastUpdatedOn: "2021-12-28 13:55:21",
    },
];

const columns = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },
    {
        field: "trainedId",
        headerName: "Trained Id",
        width: 150,
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
    },
    {
        field: "gender",
        headerName: "Gender",
        width: 100,
    },
    {
        field: "email",
        headerName: "Email",
        width: 200,
    },
    {
        field: "mobileNo",
        headerName: "Mobile No",
        width: 150,
    },

    {
        field: "place",
        headerName: "Place",
        width: 150,
    },

    {
        field: "district",
        headerName: "District",
        width: 250,
    },
    {
        field: "qualification",
        headerName: "Qualification",
        width: 150,
    },
    {
        field: "view",
        headerName: "view",
        width: 150,
    },
    {
        field: "subjects",
        headerName: "subjects",
        width: 150,
    },
    {
        field: "slots",
        headerName: "Slots",
        width: 150,
    },
    {
        field: "activeInactive",
        headerName: "Active/Inactive",
        width: 150,
    },
    {
        field: "trainerUnavailability",
        headerName: "Trainer Unavailability",
        width: 150,
    },
    {
        field: "employmentStatus",
        headerName: "Employment Status",
        width: 150,
    },
    {
        field: "trainerAvailability",
        headerName: "Trainer Availability",
        width: 150,
    },
    {
        field: "tutorialWatchedOn",
        headerName: "Tutorial Watched On",
        width: 150,
    },
    {
        field: "signedUpOn",
        headerName: "Signed Up On",
        width: 150,
    },
    {
        field: "last Updated On",
        headerName: "Last Updated On",
        width: 150,
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function TrainersPortal() {
    const [value, setValue] = React.useState("");
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Trainers Portal"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginTop: 2,
                            }}
                        >
                            <Button variant="contained">
                                Available Trainers :-536376
                            </Button>
                        </Box>
                        <SelectionHeader
                            content={
                                <>
                                    <Stack direction={"row"} spacing={2}>
                                        <Box>
                                            <GlobalSelectField
                                                options={{
                                                    // formik: formik,
                                                    label: "Category",
                                                    name: "courseId",
                                                    sx: { width: "100%" },
                                                    // data: data.coursesPreData,

                                                    keys: {
                                                        id: "courseId",
                                                        value: "courseName",
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <GlobalSelectField
                                                options={{
                                                    // formik: formik,
                                                    label: "Subject",
                                                    name: "courseId",
                                                    sx: { width: "100%" },
                                                    // data: data.coursesPreData,

                                                    keys: {
                                                        id: "courseId",
                                                        value: "courseName",
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <TimePicker
                                                    label="Time"
                                                    value={value}
                                                    // onChange={handleChange}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                        <Box>
                                            <GlobalSelectField
                                                options={{
                                                    // formik: formik,
                                                    label: "Day",
                                                    name: "courseId",
                                                    sx: { width: "100%" },
                                                    // data: data.coursesPreData,

                                                    keys: {
                                                        id: "courseId",
                                                        value: "courseName",
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Stack>
                                </>
                            }
                        />
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.trainedId}
                                label="Trained Id"
                                name={selectedRows[0]?.name}
                                details={<Details data={selectedRows[0]} />}
                                actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
