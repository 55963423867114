import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { SESSIONS_LOG_TBL_HEAD } from "./SessionsLogTableHead";
import { formatDataForDataTable } from "../../../../utils/formatData";
import { Box } from "@mui/material";
import CustomGridToolbar from "../../GridToolBar";

const EvaluationsSessionsLog = ({ row }) => {
    const [gridSize, setGridSize] = useState(5);
    const [tblData, setTblData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetch("/evaluations/sessionsLog.json");
                if (!res.ok) {
                    throw new Error("error json Data");
                }
                const data = await res.json();

                const frData = formatDataForDataTable(data);

                setTblData(frData);
            } catch (err) {
                console.error("error while fetching json:", err);
            }
        };
        fetchData();
    }, []);

    return (
        <div
            style={{
                minWidth: "1200px",
            }}
        >
            <Box
                sx={{
                    height: 48,
                    backgroundColor: "grey.200",
                    borderRadius: "8px 8px 0 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "black",
                }}
            >
                <p>{`Name : ${row.studentName} | Class : ${row.className} | Course : ${row.courseName} | Subject : ${row.subjectName}`}</p>
            </Box>
            <DataGrid
                loading={false}
                rows={tblData}
                columns={SESSIONS_LOG_TBL_HEAD}
                autoHeight
                getRowId={(row) => row.serialNumber}
                pageSize={gridSize}
                rowsPerPageOptions={[5, 10, 25, 50]}
                onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
                components={{ Toolbar: CustomGridToolbar }}
            />
        </div>
    );
};

export default EvaluationsSessionsLog;
