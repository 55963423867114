import React, { useEffect, useState } from "react";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { DataGrid } from "@mui/x-data-grid";
import Page from "../../../components/Page";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Paper,
    Select,
    Typography,
} from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { formatDataForDataTable } from "../../../utils/formatData";
import { CRO_EVALUATIONS_TBL_HEAD } from "../../../components/datagrid/myworkspace/cro/CroEvaluationsTableHead";
import CustomDataGrid from "../../../components/mui/DataGrid/CustomDataGrid";
import CRoEvaluationsDetails from "../../../components/datagrid/myworkspace/cro/CRoEvaluationsDetails";
import CroEvaluationsActions from "../../../components/datagrid/myworkspace/cro/CroEvaluationsActions";

const CroEvaluations = () => {
    const [gridSize, setGridSize] = useState(5);
    const [selectedRows, setSelectedRows] = useState([]);

    const [tblHead, setTableHead] = useState(CRO_EVALUATIONS_TBL_HEAD);
    const [tableData, setTableData] = useState([]);

    const [filter, setFilter] = useState("all");

    const filterValues = ["all", "pending", "compleated"];

    const handleChange = (e) => {
        console.log(e.target.value, "value-=--=-=-=-==");
        setFilter(e.target.value);
    };

    useEffect(() => {
        if (filter === "compleated") {
            setTableHead((prev) => [
                ...prev,
                {
                    field: "croEvaluationAcknowledged",
                    headerName: "Action",
                    width: 150,
                    renderCell: ({ row }) =>
                        row.croEvaluationAcknowledged > 0 ? (
                            <p style={{ color: "green" }}>Acknowledged</p>
                        ) : (
                            <Button variant="contained">View</Button>
                        ),
                },
                {
                    field: "croEvaluationRemarks",
                    headerName: "CRO Remarks",
                    width: 150,
                },
                {
                    field: "croEvaluationAcknowledgedAt",
                    headerName: "CRO Acknowledged on",
                    width: 150,
                },
            ]);
        }

        const fetchData = async () => {
            console.log(`/public/evaluations/evaluationData-${filter}.json`);
            try {
                const response = await fetch(
                    `/evaluations/evaluationData-${filter}.json`
                );
                if (!response.ok) {
                    throw new Error("Error loading JSON file");
                }
                const data = await response.json();
                const frData = formatDataForDataTable(data);
                console.log(frData);
                setTableData(frData);
            } catch (error) {
                console.error("Error fetching JSON:", error);
            }
        };

        fetchData();

        setSelectedRows([]);
    }, [filter]);

    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4">Student Evaluations</Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                justifyContent: "right",
                                padding: "10px 10px",
                            }}
                        >
                            <FormControl
                                fullWidth
                                sx={{
                                    maxWidth: "300px",
                                }}
                            >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter}
                                    onChange={handleChange}
                                >
                                    {filterValues.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ width: "100%", padding: 0 }}>
                            <CustomDataGrid
                                data={tableData}
                                rowId={"serialNumber"}
                                columns={tblHead}
                                Head={[
                                    {
                                        field: "serialNumber",
                                        headerName: "SlNo",
                                        width: 50,
                                    },
                                    {
                                        field: "studentName",
                                        headerName: "Student Name",
                                        width: 250,
                                    },
                                ]}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                admissionNo={selectedRows[0]?.admnNo}
                                name={selectedRows[0]?.studentName}
                                label={"Admission Number"}
                                chipValue={selectedRows[0]?.admnNo}
                                details={
                                    <CRoEvaluationsDetails
                                        data={selectedRows[0]}
                                        filter={filter}
                                    />
                                }
                                actions={
                                    <CroEvaluationsActions
                                        data={selectedRows[0]}
                                        filter={filter}
                                    />
                                }
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default CroEvaluations;
