import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import BasicTextField from "../../../mui/TextField";

const EvaluationAcknowledgmentForm = ({ row }) => {
    return (
        <Box
            sx={{
                minWidth: "600px",
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontSize: "20px",
                    pb: "20px",
                    mb: "10px",
                    borderBottom: "1px solid #000",
                }}
            >
                Acknowledge Evaluation
            </Typography>
            <Stack
                gap={2}
                sx={{
                    borderBottom: "1px solid #000",
                    pb: "15px",
                    mb: "20px",
                }}
            >
                <BasicTextField label="Remarks" multiline rows={3} />
            </Stack>
            <div
                style={{
                    width: "150px",
                    margin: "0 auto",
                }}
            >
                <Button variant="contained">Acknowledge</Button>
            </div>
        </Box>
    );
};

export default EvaluationAcknowledgmentForm;
