import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import UserCard from "../../../../components/Card/UserCard";
import { Box, Button, Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    clearEmplyeeData,
    getDepartmentEmployeesDetails,
} from "../../../../redux/features/dashboardAnalyticsSlice";
import Skeleton from "@mui/material/Skeleton";

export const DepartmentEmpDetailsPopUp = ({ item }) => {
    const dispatch = useDispatch();
    const { DepartmentEployeesDetails, loading } = useSelector(
        (state) => state.dashboardAnalytics
    );
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open === false) {
            dispatch(clearEmplyeeData());
        }
    }, [open, dispatch]);

    const handleClick = () => {
        setOpen(true);

        const data = {
            department: item.department,
            type: [
                "Project-Classroom",
                "Project-Foundation",
                "Project-Little Genie",
                "Linguistic Programs ",
                "Top Management",
                "Procurement",
            ].includes(item.department)
                ? "reportingHead"
                : "",
        };

        setTimeout(() => {
            dispatch(getDepartmentEmployeesDetails(data));
        }, 100);
    };

    return (
        <div>
            <GlobalDialog
                title={`${item.department}`}
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth="xl"
                closeButton={true}
                popupButton={<Button onClick={handleClick}>View</Button>}
                content={
                    <Grid container spacing={3}>
                        {loading
                            ? Array.from(new Array(4)).map((_, index) => (
                                  <Grid item xs={12} md={4} lg={3} key={index}>
                                      <LoadingSkelton />
                                  </Grid>
                              ))
                            : DepartmentEployeesDetails.map((item, index) => (
                                  <Grid item xs={12} md={4} lg={3} key={index}>
                                      <UserCard user={item} />
                                  </Grid>
                              ))}
                    </Grid>
                }
            />
        </div>
    );
};

const LoadingSkelton = () => (
    <Box>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Skeleton
                animation="wave"
                sx={{
                    width: 300,
                    height: 200,
                }}
            />
        </Box>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Skeleton
                animation="wave"
                variant="circular"
                width={60}
                height={60}
            />
        </Box>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Skeleton animation="wave" width={200} />
        </Box>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Skeleton animation="wave" width={100} />
        </Box>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <Stack spacing={2} direction="row">
                <Skeleton animation="wave" width={80} height={100} />
                <Skeleton animation="wave" width={80} height={100} />
                <Skeleton animation="wave" width={80} height={100} />
            </Stack>
        </Box>
    </Box>
);
