import React from "react";
import { Box, Stack, TextField, Button, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import useResponsive from "../../../../../Hooks/useResponsive";

export default function OfficialInfo({ formik, step, setActiveStep }) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  console.log("formik.official", formik);
  return (
    <div>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {!smUp ? (
            <>
              <Typography padding={1} variant="h6">
                official Information
              </Typography>
            </>
          ) : null}
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="Aadhaar No."
                variant="outlined"
                fullWidth
                name="aadhaarCardNo"
                onChange={formik.handleChange}
                value={formik.values.aadhaarCardNo}
                type="number"
                error={Boolean(
                  formik.touched.aadhaarCardNo && formik.errors.aadhaarCardNo
                )}
                helperText={
                  formik.touched.aadhaarCardNo && formik.errors.aadhaarCardNo
                }
              />
              <TextField
                label="PAN"
                variant="outlined"
                fullWidth
                name="panCardNo"
                inputProps={{ style: { textTransform: "uppercase" } }}
                onChange={formik.handleChange}
                value={formik.values.panCardNo}
                error={Boolean(
                  formik.touched.panCardNo && formik.errors.panCardNo
                )}
                helperText={formik.touched.panCardNo && formik.errors.panCardNo}
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="PF Number (if any)"
                variant="outlined"
                fullWidth
                name="pfNo"
                onChange={formik.handleChange}
                value={formik.values.pfNo}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date Of Joining"
                  inputFormat="YYYY/MM/DD"
                  mask=""
                  name="doj"
                  value={formik.values.doj}
                  onChange={(e) => {
                    formik.setFieldValue("doj", e.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" fullWidth {...params} />
                  )}
                  error={Boolean(formik.touched.doj && formik.errors.doj)}
                  helperText={formik.touched.doj && formik.errors.doj}
                />
              </LocalizationProvider>
            </Stack>
          </Box>

          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <Stack direction={`${!smUp ? "column" : "row"}`} spacing={2}>
              <TextField
                label="mobileNo"
                variant="outlined"
                fullWidth
                name="mobileNo"
                onChange={formik.handleChange}
                value={formik.values.mobileNo}
                type="number"
                error={Boolean(
                  formik.touched.mobileNo && formik.errors.mobileNo
                )}
                helperText={formik.touched.mobileNo && formik.errors.mobileNo}
              />
              <TextField
                label="official MobileNo"
                variant="outlined"
                fullWidth
                name="officialMobileNo"
                onChange={formik.handleChange}
                value={formik.values.officialMobileNo}
                type="number"
                error={Boolean(
                  formik.touched.officialMobileNo &&
                    formik.errors.officialMobileNo
                )}
                helperText={
                  formik.touched.officialMobileNo &&
                  formik.errors.officialMobileNo
                }
              />
            </Stack>
          </Box>
          <Box sx={{ margin: !smUp ? 1 : 3 }}>
            <TextField
              label="official Email"
              variant="outlined"
              fullWidth
              name="officialEmail"
              onChange={formik.handleChange}
              value={formik.values.officialEmail}
              type="email"
              error={Boolean(
                formik.touched.officialEmail && formik.errors.officialEmail
              )}
              helperText={
                formik.touched.officialEmail && formik.errors.officialEmail
              }
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box margin={1}>
              <Button
                size={`${!smUp ? "small" : ""}`}
                onClick={() => setActiveStep(step - 1)}
                variant="outlined"
              >
                Back
              </Button>
            </Box>
            <Box margin={1}>
              <Button
                size={`${!smUp ? "small" : ""}`}
                variant="contained"
                type="submit"
              >
                Submit Details
                <Icon icon="tabler:player-track-next" width="25" height="25" />
              </Button>
            </Box>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
