import RoleActions from "./RoleActions";
export const DESIGNATION_TABLE_HEAD = [
    {
        field: "serialNumber",
        minWidth: 30,
        headerName: "No.",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "userTypeId",
        flex: 0.1,
        minWidth: 50,
        headerName: "User Type Id",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "usertype",
        flex: 0.2,
        minWidth: 250,
        headerName: "Designation",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "deptName",
        flex: 0.2,
        minWidth: 250,
        headerName: "Department",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Actions",
        minWidth: 90,
        headerName: "Actions",
        renderCell: (params) => RoleActions(params),
    },
];
