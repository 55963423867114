import { CreateTutor } from "./CreateTutor";

export const CREATE_TUTOR_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "full_name",
        headerName: "cro Name",
        width: 350,
    },
    {
        field: "email_id",
        headerName: "Email",
        width: 350,
    },
    {
        field: "mob_no",
        headerName: "Phone",
        width: 250,
    },
    {
        field: "Action",
        headerName: "Action",
        width: 150,
        renderCell: (params) => <CreateTutor params={params.row} />,
    },
];
