// ----------------------------------------------------------------------

// let name = JSON.parse(localStorage.getItem("cred")).name

// setting user accound details....

const account = {
    displayName: "",
    email: "demo@gmail.com",
    photoURL: "/static/mock-images/avatars/avatar_default.jpg",
};

export default account;
