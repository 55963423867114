import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    incomeData: [],
    rowCount: 250,
    invoicesDetails: [],
    screenshotSrc: "",
    studentsList: [],
    subjectList: [],
    currentLedger:{}
};

export const getIncome = createAsyncThunk(
  "income/getIncome",
  async (paginationDetails) => {
    const res = await apiRequest({
      method: "get",
      url: `general/income/${paginationDetails?.selectedDate}?page=${paginationDetails?.pageNumber}&limit=${paginationDetails?.pageLimit}&search=${paginationDetails?.searchValue? encodeURIComponent(paginationDetails?.searchValue):""}`,
    });
    return res;
  }
);


export const getStudentsList = createAsyncThunk(
  "income/getStudentsList",
  async () => {
    const res = await apiRequest({
      method: "get",
      url: `general/getStudents/1`,
    });
    return res;
  }
);

export const getIncomeScreenshot = createAsyncThunk(
  "income/getScreenshot",
  async (imageId) => {
    const res = await apiRequest({
      method: "get",
      url: `general/income/img/${imageId}`,
    });
    return res;
  }
);

export const getStudentYearAndMonth = createAsyncThunk(
  "income/getStudentYearAndMonth",
  async (enrolmentId) => {
    const res = await apiRequest({
      method: "get",
      url: `general/getCurrentLedger/${enrolmentId}`,
    });
    return res;
  }
);


export const createIncome = createAsyncThunk(
  "income/createIncome",
  async (data) => {    
    const res = await apiRequest({
      method: "post",
      url: `general/income`,
      data: data,
      headers: {
        'Accept':'application/json',
        'Content-Type': 'multipart/form-data'
      },
    });
    return res;
  }
);








export const deleteIncome = createAsyncThunk(
  "income/deleteIncome",
  async (id) => {
    const res = await apiRequest({
      method: "delete",
      url: `general/income/${id}`,
    });
    return res;
  }
);







export const updateIncome = createAsyncThunk(
  "income/updateIncome",
  async (data) => {
    const res = await apiRequest({
      method: "patch",
      url: `general/income/${data.id}`,
      data: data,
      headers: {
        'Accept':'application/json',
        'Content-Type': 'multipart/form-data'
      },
    });
    return res;
  }
);

const incomeSlice = createSlice({
  name: "incomeSlice",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //.................GET INCOMEDETAILS..............................
    builder.addCase(getIncome.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getIncome.fulfilled, (state, action) => {
      return {
        ...state,
        incomeData: action.payload?.data,
        rowCount: action.payload.meta?.totalRecords,
        loading: false,
      };
    });


    //.................GET STUDENTS LIST..............................
    builder.addCase(getStudentsList.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getStudentsList.fulfilled, (state, action) => {
      return {
        ...state,
        studentsList: action?.payload?.data?.students,
        loading: false,
      };
    });

    //.................GET getStudentYearAndMonth DETAILS..............................
    builder.addCase(getStudentYearAndMonth.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getStudentYearAndMonth.fulfilled, (state, action) => {
      return {
        ...state,
        currentLedger:action.payload?.data?.currentLedger,
        loading: false,
      };
    });


    //.................CREATE  INCOME..............................
    builder.addCase(createIncome.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(createIncome.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });


    //.................GET SCREENSHOT IMAGE..............................
    builder.addCase(getIncomeScreenshot.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getIncomeScreenshot.fulfilled, (state, action) => {
      return {
        ...state,
        screenshotSrc: action?.payload?.imageUrl,
        loading: false,
      };
    });



    //.................UPDATE  INCOME..............................
    builder.addCase(updateIncome.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(updateIncome.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });



        // //.................DELETE INVOICE ..............................
    // builder.addCase(deleteInvoice.pending, (state, action) => {
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // });
    // builder.addCase(deleteInvoice.fulfilled, (state) => {
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    // });



  },
});

export default incomeSlice.reducer;
