import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import { CancelButton, DeleteButton } from "../mui/Buttons/Buttons";
import { Typography } from "@mui/material";
import { deleteProject, getProjects } from "../../redux/features/mastersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

export const DeleteProjectPopup = ({ params }) => {
    const loading = useSelector((state) => state.masters.loading);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Delete Project"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                popupButton={
                    <DeleteButton
                        size="small"
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Delete
                    </DeleteButton>
                }
                content={
                    <>
                        <Typography>
                            Are you sure .You want Delete this project..?
                        </Typography>
                    </>
                }
                actionButton={
                    <>
                        <CancelButton
                            action={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </CancelButton>
                        <DeleteButton
                            action={() => {
                                dispatch(deleteProject(params)).then((res) => {
                                    if (res.payload.status === "failed") {
                                        setOpen(false);
                                        enqueueSnackbar(res.payload.message, {
                                            variant: "error",
                                        });
                                    } else if (
                                        res.payload.status === "success"
                                    ) {
                                        dispatch(getProjects());
                                        setOpen(false);
                                        enqueueSnackbar(res.payload.message, {
                                            variant: "success",
                                        });
                                    }
                                });
                            }}
                        >
                            {loading ? "Deleting..." : "Delete"}
                        </DeleteButton>
                    </>
                }
            />
        </div>
    );
};
