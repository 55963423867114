import { Box, Button, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { timeLine } from "../../../redux/features/jobApplicationSlice";
import InterviewTimeLine from "../../../views/user/mytasks/JobApplication/InterviewTimeLine";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import * as React from "react";
import Drawer from "@mui/material/Drawer";
import SimpleLoader from "../../Loader/SimpleLoader";

export default function ViewInterviewStatus(params) {
  const dispatch = useDispatch();
  const timeline = useSelector((state) => state.jobApplication);

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    dispatch(timeLine(params.params.row.id));
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  return (
    <div>
      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button
              disabled={
                params.params.row.CVStatus === "Selected" ? false : true
              }
              onClick={toggleDrawer(anchor, true)}
            >
              <Icon icon="mdi:eye-settings" width="20" height="20" />
              <Typography>{params.params.row.currentStatus}</Typography>
            </Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              <Box sx={{ width: 410, padding: 3, backgroundColor: "grey.200" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button onClick={toggleDrawer(anchor, false)}>
                    <Icon icon="iconoir:cancel" width="25" height="25" />
                  </Button>
                </Box>
                {timeline.loading ? (
                  <SimpleLoader />
                ) : (
                  <InterviewTimeLine
                    title="Interview Status Timeline"
                    list={timeline.timeline}
                  />
                )}
              </Box>
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
