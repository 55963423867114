import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    cleanUpPermissions,
    filterNavChild,
    settingPermissions,
    initialState as roleInitialState,
} from "../redux/features/roleSlice";

const useRoles = (navSection) => {
    const { role } = useSelector((state) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        const permission = role.otherConfig.filter(
            (item) => item.module === navSection
        );

        dispatch(settingPermissions(permission));

        return () => {
            dispatch(cleanUpPermissions());
        };
    }, [role.otherConfig]);
    useEffect(() => {
        if (role.navConfig.length > roleInitialState.navConfig.length) {
            dispatch(filterNavChild(navSection));
        }
    }, [role.navConfig]);

    return { navChild: role.navChild, permissions: role.permissions };
};

export default useRoles;
