import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../../mui/Dialogue/GlobalDialog";
import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { EnrollmentForm } from "./EnrollmentForm";
import { EnrollmemtDetails } from "./EnrollmemtDetails";
import { getAllStudentSubjectData } from "../../../../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import useResponsive from "../../../../../../Hooks/useResponsive";

export default function SubjectEnrollmentPopUp({ params }) {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const { loading, allStudentSubjectDatas } = useSelector(
        (state) => state.cro
    );
    const [selectedRows, setSelectedRows] = useState([]);
    const [open, setOpen] = useState(false);

    const head = [
        {
            field: "subject_name",
            headerName: "Enrolled Subject",
            minWidth: "200",
        },
    ];
    const handleClick = () => {
        const data = {
            adm_no: params.admn_no,
        };

        setOpen(true);
        setTimeout(() => {
            dispatch(getAllStudentSubjectData(data));
        }, 500);
    };
    return (
        <GlobalDialog
            open={open}
            setOpen={setOpen}
            closeButton={true}
            fullWidth
            maxWidth={"lg"}
            title="Subject Enrollment"
            popupButton={
                <>
                    <Button onClick={handleClick}>Subject Enrollment</Button>
                </>
            }
            content={
                <>
                    <Paper elevation={3} sx={{ width: "100%" }}>
                        <Stack direction={smUp ? "row" : "column"}>
                            <Box sx={{ width: smUp ? "30%" : "100%" }}>
                                <Box>
                                    <DataGrid
                                        loading={loading}
                                        autoHeight
                                        rows={allStudentSubjectDatas}
                                        columns={head}
                                        getRowId={(row) => row.serialNumber}
                                        onSelectionModelChange={(ids) => {
                                            const selectedIDs = new Set(ids);
                                            const selectedRows =
                                                allStudentSubjectDatas?.filter(
                                                    (row) =>
                                                        selectedIDs.has(
                                                            row.serialNumber
                                                        )
                                                );
                                            // dispatch(
                                            //     subjectEnrollmentDetailedId(
                                            //         selectedRows[0]
                                            //             ?.detailedSubjectEnrollments[0]
                                            //             ?.subjectEnrollmentDetailedId
                                            //     )
                                            // );

                                            setSelectedRows(selectedRows);
                                        }}
                                        hideFooter={true}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: smUp ? "70%" : "100%",
                                    backgroundColor: "grey.200",
                                }}
                            >
                                <Box sx={{ padding: 2 }}>
                                    <Typography variant="h6">
                                        Subject Details
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <EnrollmentForm params={params} />
                                    </Box>
                                    <Box p={1}>
                                        <EnrollmemtDetails
                                            data={selectedRows}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Stack>
                    </Paper>
                </>
            }
        />
    );
}
