import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import CustomGridToolbar from "../../../GridToolBar";

const data = [
    {
        slno: 1,
        category: "HS",
        subjectName: "Biology",
        preference: 3,
    },
];

const columns = [
    {
        field: "slno",
        headerName: "slno",
    },
    {
        field: "category",
        headerName: "category",
        width: 200,
    },
    {
        field: "subjectName",
        headerName: "subjectName",
        width: 250,
    },
    {
        field: "preference",
        headerName: "preference",
        width: 200,
    },
];
export const SubjectsListData = () => {
    const [gridSize, setGridSize] = React.useState(5);
    return (
        <div>
            <DataGrid
                // loading={loading}
                rows={data}
                columns={columns}
                getRowId={(row) => row.slno}
                autoHeight
                pageSize={gridSize}
                onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
                rowsPerPageOptions={[5, 10, 25, 50]}
                // components={{ Toolbar: CustomGridToolbar }}
            />
        </div>
    );
};
