import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    preData: [{ positionId: 0, positionName: "All" }],
    timeToHire: "",
    timeToFill: "",
    totalApplications: "",
    totalJoined: "",
    recruitmentFunnel: [],
    recruitmentFunnelPercentage: [],
    employeesHired: [],
    pieChartData: [],
    sources: [],
};

export const getAnalyticsPreData = createAsyncThunk(
    "analytics/getAnalytics_predata",
    async () => {
        const res = await apiRequest({
            url: "pre-data/getCareerPositions",
            method: "get",
        });
        return res;
    }
);
export const getAnalytics = createAsyncThunk(
    "analytics/getAnalytics",
    async (SearchQry) => {
        const res = await apiRequest({
            method: "get",
            url: `analytics/recruitmentAnalytics/${SearchQry}`,
        });
        return res;
    }
);

const analyticalSlice = createSlice({
    name: "analytics",
    initialState: initialState,
    reducers: {
        clearAnalytics: (state, action) => {
            return (state = initialState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAnalytics.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAnalytics.fulfilled, (state, action) => {
            const formatedEmployeesList = formatDataForDataTable(
                action.payload.data.employeesHired
            );
            const formatedSourceList = formatDataForDataTable(
                action.payload.data.sources
            );
            return {
                ...state,
                timeToHire: action.payload.data.timeToHire,
                timeToFill: action.payload.data.timeToFill,
                totalApplications: action.payload.data.totalApplications,
                totalJoined: action.payload.data.totalJoined,
                recruitmentFunnel: action.payload.data.recruitmentFunnel,
                employeesHired: formatedEmployeesList,
                recruitmentFunnelPercentage:
                    action.payload.data.recruitmentFunnelPercentage,
                pieChartData: action.payload.data.employmentStatus,
                sources: formatedSourceList,
                loading: false,
            };
        });

        builder.addCase(getAnalyticsPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAnalyticsPreData.fulfilled, (state, action) => {
            return {
                ...state,
                preData: [...state.preData, ...action.payload.data],
                loading: false,
            };
        });
    },
});

export const { clearAnalytics } = analyticalSlice.actions;

export default analyticalSlice.reducer;
