import React from "react";
import GlobalDeleteDialog from "../../../../mui/Dialogue/GlobalDeleteDialog";
import {
    deleteIndividualAcademicSession,
    getStudentAcademicSessions,
} from "../../../../../redux/features/researchSlice";
import { useSelector } from "react-redux";

export const DeleteIndividualAcademicSessions = ({ data }) => {
    const { subjectEnrollmentDetailedId, loading } = useSelector(
        (state) => state.research
    );
    return (
        <div>
            <GlobalDeleteDialog
                title="Delete Academic Session"
                api={deleteIndividualAcademicSession(data)}
                upDateCall={getStudentAcademicSessions(
                    subjectEnrollmentDetailedId
                )}
                deleteNote="Are you sure you want to delete..?"
                loading={loading}
            />
        </div>
    );
};
