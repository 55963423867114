import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { CustomRow, Mytable } from "../../../mui/DataGrid/Details";
import FeeStructurePopUp from "./FeeStructurePopUp";

const TermFeeCollectionActions = ({ data }) => {
    console.log(data,"term fee collections");
    return (
        <Box>
            <Grid container spacing={1}>
                <Mytable
                    tableRow={
                        <>
                            <CustomRow
                                label="Fee Structure"
                                component={<FeeStructurePopUp data={data} />}
                            />
                        </>
                    }
                />
                <Mytable
                    tableRow={
                        <>
                            <CustomRow
                                label="Action"
                                component={
                                    <Button variant="contained">
                                        Mark as paid
                                    </Button>
                                }
                            />
                        </>
                    }
                />
            </Grid>
        </Box>
    );
};

export default TermFeeCollectionActions;
