import React, { useEffect, useState } from "react";
import { FormikProvider, Form, useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Paper, Stack } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Skeleton from "@mui/material/Skeleton";
import {
    bookCabin,
    getAssetTimeSlotsForADay,
    getFreeAssetsForTimeRange,
} from "../../../../redux/features/cabinBookingSlice";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import {
    CancelButton,
    SaveButton,
} from "../../../../components/mui/Buttons/Buttons";
import dayjs from "dayjs";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { useSnackbar } from "notistack";

export const SlotBookingForm = ({
    cabinDetails,
    cabinId,
    setOpen,
    setViewForm,
    asset,
    timeRange,
    popupData,
    assetName,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { loading } = useSelector((state) => state.cabin);
    const [timeStatus, setTimeStatus] = useState(false);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(null);
    const [skelton, setSkelton] = useState(true);
    const [timeOverStatus, setTimeOverStatus] = useState({
        fromTime: false,
        toTime: false,
    });

    const Schema = Yup.object().shape({
        cabin_id: Yup.string().required("Cabin is required"),
        from_time: Yup.string().required("From time is required"),
        to_time: Yup.string().required("To time is required"),
        remarks: Yup.string().required("Remarks is required"),
        purpose: Yup.string().required("Purpose is required"),
        booking_date: Yup.string().required(" Booking date is required"),
    });

    useEffect(() => {
        setTimeout(() => {
            setSkelton(false);
        }, 1000);
    }, []);
    const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    };
    const formik = useFormik({
        enableReinitialize: timeRange ? true : false,
        initialValues: {
            booking_date: timeRange?.date || dayjs(),
            cabin_id: cabinDetails?.asset_id || cabinId,
            from_time: timeRange?.fromTime || "",
            to_time: timeRange?.toTime || "",
            remarks: "",
            purpose: "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            const data = {
                booking_date: timeRange?.date
                    ? values.booking_date.toLocaleDateString("en-IN", options)
                    : values.booking_date.format("DD/MM/YYYY"),
                cabin_id: values.cabin_id,
                from_time: values.from_time.format("HH:mm:00"),
                to_time: values.to_time.format("HH:mm:00"),
                remarks: values.remarks,
                purpose: values.purpose,
            };

            dispatch(bookCabin(data)).then((res) => {
                if (res.payload.status === "error") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    if (cabinId) {
                        const datas = {
                            id: data.cabin_id,
                            date: data.booking_date,
                        };
                        setOpen(false);
                        dispatch(getAssetTimeSlotsForADay(datas));
                        dispatch(getFreeAssetsForTimeRange(popupData));
                    }
                    if (cabinDetails) {
                        setViewForm(false);
                        dispatch(getAssetTimeSlotsForADay(asset));
                    }

                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    useEffect(() => {
        function isTimePassed(targetTimeString) {
            const currentTime = new Date();

            const targetTime = new Date(
                currentTime.toDateString() + " " + targetTimeString
            );

            return currentTime > targetTime;
        }
        if (
            formik.values.from_time &&
            formik.values.to_time &&
            formik.values.booking_date
        ) {
            const currentDate = new Date().toLocaleDateString(); // Get today's date
            const bookingDate = timeRange?.date
                ? timeRange.date.toLocaleDateString()
                : new Date(formik.values.booking_date).toLocaleDateString();
            if (currentDate === bookingDate) {
                const time1 = formik.values?.from_time.format("HH:mm:00");
                const time2 = formik.values?.to_time.format("HH:mm:00");
                const isTime1Passed = isTimePassed(time1);
                const isTime2Passed = isTimePassed(time2);
                const timeOverStatus = {
                    fromTime: isTime1Passed,
                    toTime: isTime2Passed,
                };
                setTimeOverStatus(timeOverStatus);
            } else {
                setTimeOverStatus({
                    fromTime: false,
                    toTime: false,
                });
            }
        }
    }, [
        formik.values.from_time,
        formik.values.to_time,
        formik.values.booking_date,
    ]);

    function isValidTimeRange(fromTime, toTime) {
        if (formik.values.from_time && formik.values.to_time) {
            const fromDate = fromTime;
            const toDate = toTime;

            if (toDate <= fromDate) {
                return false;
            }

            const durationInMinutes = (toDate - fromDate) / (1000 * 60);

            if (durationInMinutes < 30 - 1) {
                return false;
            }

            return true;
        }
    }

    useEffect(() => {
        if (isValidTimeRange(formik.values.from_time, formik.values.to_time)) {
            setTimeStatus(false);
        } else {
            setTimeStatus(true);
        }
    }, [formik.values.from_time, formik.values.to_time]);
    const [selectedTime, setSelectedTime] = useState(new Date());
    const minDate = new Date();

    return (
        <div>
            <Box>
                {/* ------------------------------------------------------------------------- */}
                {skelton === true ? (
                    <>
                        <Box>
                            <Skeleton
                                sx={{ borderRadius: 2 }}
                                variant="rounded"
                                width={"100%"}
                                height={60}
                            />
                        </Box>
                        <Box marginTop={2}>
                            <Stack direction={"row"} spacing={2}>
                                <Skeleton
                                    sx={{ borderRadius: 2 }}
                                    variant="rounded"
                                    width={"50%"}
                                    height={60}
                                />
                                <Skeleton
                                    sx={{ borderRadius: 2 }}
                                    variant="rounded"
                                    width={"50%"}
                                    height={60}
                                />
                            </Stack>
                        </Box>
                        <Box marginTop={2}>
                            <Stack direction={"column"} spacing={2}>
                                <Skeleton
                                    sx={{ borderRadius: 2 }}
                                    variant="rounded"
                                    width={"100%"}
                                    height={140}
                                />
                                <Skeleton
                                    sx={{ borderRadius: 2 }}
                                    variant="rounded"
                                    width={"100%"}
                                    height={140}
                                />
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <Stack direction={"row"} spacing={2}>
                                <Skeleton
                                    sx={{ borderRadius: 2 }}
                                    variant="rounded"
                                    width={80}
                                    height={60}
                                />
                                <Skeleton
                                    sx={{ borderRadius: 2 }}
                                    variant="rounded"
                                    width={80}
                                    height={60}
                                />
                            </Stack>
                        </Box>
                    </>
                ) : (
                    <>
                        <Wrapper
                            skelton={false}
                            title={timeRange ? assetName : ""}
                            transitionData={{
                                transition: "Slide",
                                otherProps: "down",
                            }}
                            Content={
                                <>
                                    <FormikProvider value={formik}>
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Paper
                                                elevation={3}
                                                borderRadius={3}
                                            >
                                                <Box
                                                    padding={3}
                                                    sx={{
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Box>
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                                label="Date"
                                                                name="booking_date"
                                                                disablePast
                                                                disabled={
                                                                    timeRange
                                                                        ? true
                                                                        : false
                                                                }
                                                                value={dayjs(
                                                                    formik
                                                                        .values
                                                                        .booking_date
                                                                )}
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    setValue(
                                                                        newValue
                                                                    );
                                                                    formik.setFieldValue(
                                                                        "booking_date",
                                                                        newValue
                                                                    );
                                                                }}
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        onKeyDown={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        error={
                                                                            formik
                                                                                .touched
                                                                                .booking_date &&
                                                                            Boolean(
                                                                                formik
                                                                                    .errors
                                                                                    .booking_date
                                                                            )
                                                                        }
                                                                        helperText={
                                                                            formik
                                                                                .errors
                                                                                .booking_date
                                                                        }
                                                                        fullWidth
                                                                        {...params}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    <Box marginTop={2}>
                                                        <Stack
                                                            direction={"row"}
                                                            spacing={2}
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                            >
                                                                <TimePicker
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                    name="from_time"
                                                                    label="From Time"
                                                                    minutesStep={
                                                                        30
                                                                    }
                                                                    disabled={
                                                                        timeRange
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .from_time
                                                                    }
                                                                    minDate={
                                                                        minDate
                                                                    }
                                                                    onChange={(
                                                                        newValue
                                                                    ) => {
                                                                        setValue(
                                                                            newValue
                                                                        );
                                                                        formik.setFieldValue(
                                                                            "from_time",
                                                                            newValue
                                                                        );
                                                                    }}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            onKeyDown={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                            error={
                                                                                formik
                                                                                    .touched
                                                                                    .from_time &&
                                                                                Boolean(
                                                                                    formik
                                                                                        .errors
                                                                                        .from_time
                                                                                )
                                                                            }
                                                                            helperText={
                                                                                formik
                                                                                    .errors
                                                                                    .from_time
                                                                            }
                                                                            fullWidth
                                                                            {...params}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                            >
                                                                <TimePicker
                                                                    sx={{
                                                                        width: "100%",
                                                                    }}
                                                                    label="To Time"
                                                                    name="to_time"
                                                                    minutesStep={
                                                                        30
                                                                    }
                                                                    disabled={
                                                                        timeRange
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .to_time
                                                                    }
                                                                    onChange={(
                                                                        newValue
                                                                    ) => {
                                                                        setValue(
                                                                            newValue
                                                                        );
                                                                        formik.setFieldValue(
                                                                            "to_time",
                                                                            newValue
                                                                        );
                                                                    }}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            onKeyDown={(
                                                                                e
                                                                            ) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                            error={
                                                                                formik
                                                                                    .touched
                                                                                    .to_time &&
                                                                                Boolean(
                                                                                    formik
                                                                                        .errors
                                                                                        .to_time
                                                                                )
                                                                            }
                                                                            helperText={
                                                                                formik
                                                                                    .errors
                                                                                    .to_time
                                                                            }
                                                                            fullWidth
                                                                            {...params}
                                                                        />
                                                                    )}
                                                                />
                                                            </LocalizationProvider>
                                                        </Stack>

                                                        {(timeStatus == true &&
                                                            formik.values
                                                                .to_time &&
                                                            formik.values
                                                                .from_time) ||
                                                        timeOverStatus.fromTime ===
                                                            true ||
                                                        timeOverStatus.toTime ===
                                                            true ? (
                                                            <Box marginTop={2}>
                                                                <Alert severity="warning">
                                                                    Invalid Time
                                                                    Range..!
                                                                    <br />
                                                                    {(timeOverStatus.fromTime ===
                                                                        true ||
                                                                        timeOverStatus.toTime ===
                                                                            true) &&
                                                                    timeStatus ===
                                                                        true
                                                                        ? "Time range expired...!,minimum time Range 30 minutes "
                                                                        : timeOverStatus.fromTime ===
                                                                              true ||
                                                                          (timeOverStatus.toTime ===
                                                                              true &&
                                                                              timeStatus ===
                                                                                  false)
                                                                        ? "Time range expired...!"
                                                                        : timeOverStatus.fromTime ===
                                                                              false &&
                                                                          timeOverStatus.toTime ===
                                                                              false &&
                                                                          timeStatus ===
                                                                              true
                                                                        ? "minimum time Range 30 minutes"
                                                                        : null}
                                                                </Alert>
                                                            </Box>
                                                        ) : null}
                                                    </Box>
                                                    <Box marginTop={2}>
                                                        <Stack
                                                            direction={"column"}
                                                            spacing={2}
                                                        >
                                                            <TextField
                                                                label="Purpose"
                                                                name="purpose"
                                                                fullWidth
                                                                multiline
                                                                rows={3}
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .purpose
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .purpose &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .purpose
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .purpose
                                                                }
                                                            />
                                                            <TextField
                                                                label="Remarks"
                                                                name="remarks"
                                                                fullWidth
                                                                multiline
                                                                rows={3}
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .remarks
                                                                }
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .remarks &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .remarks
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .remarks
                                                                }
                                                            />
                                                        </Stack>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "flex-end",
                                                            padding: 2,
                                                        }}
                                                    >
                                                        <Stack
                                                            direction={"row"}
                                                            spacing={2}
                                                        >
                                                            <CancelButton>
                                                                Clear
                                                            </CancelButton>
                                                            <SaveButton
                                                                disabled={
                                                                    timeStatus ==
                                                                    true
                                                                        ? true
                                                                        : timeOverStatus.fromTime ===
                                                                              true ||
                                                                          timeOverStatus.toTime ===
                                                                              true
                                                                        ? true
                                                                        : false
                                                                }
                                                                type="submit"
                                                            >
                                                                {loading
                                                                    ? "Saving..."
                                                                    : "Save"}
                                                            </SaveButton>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        </Form>
                                    </FormikProvider>
                                </>
                            }
                        />
                    </>
                )}

                {/* ------------------------------------------------------------------------------- */}
            </Box>
        </div>
    );
};
