import { Box, Paper } from "@mui/material";
import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";

const data = [
    {
        slno: 1,
        student: "THESNIM",
        class: 8,
        course: "Foundation Course",
        subject: "hindi",
        feePerSession: 120,
        trainer: "ZABEEBA RUKIYA HUSSAIN(+918129107922)",
        payment: "56566",
        duration: 221,
        tpRemarks: "",
        status: "",
        action: "",
    },
];

const columns = [
    {
        field: "slno",
        headerName: "slno",
    },
    {
        field: "student",
        headerName: "student",
        width: 300,
    },
    {
        field: "class",
        headerName: "class",
        width: 250,
    },
    {
        field: "course",
        headerName: "course",
        width: 200,
    },
    {
        field: "subject",
        headerName: "subject",
        width: 250,
    },
    {
        field: "feePerSession",
        headerName: "feePerSession",
        width: 250,
    },
    {
        field: "trainer",
        headerName: "trainer",
        width: 250,
    },
    {
        field: "payment",
        headerName: "payment",
        width: 250,
    },
    {
        field: "duration",
        headerName: "duration",
        width: 250,
    },
    {
        field: "tpRemarks",
        headerName: "tpRemarks",
        width: 250,
    },
    {
        field: "status",
        headerName: "status",
        width: 250,
    },
    {
        field: "action",
        headerName: "action",
        width: 250,
    },
];

export default function TrainerPayment() {
    const [gridSize, setGridSize] = React.useState(5);
    return (
        <div>
            {" "}
            <Wrapper
                title="Trainer Payment"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        // formik: formik,
                                        label: "CRO",
                                        name: "courseId",
                                        sx: { width: 200 },
                                        // data: data.coursesPreData,

                                        keys: {
                                            id: "courseId",
                                            value: "courseName",
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <Paper elevation={3}>
                            <Box
                                sx={{
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                    display: "flex",
                                    height: 44,
                                }}
                            ></Box>
                            <DataGrid
                                // loading={loading}
                                rows={data}
                                columns={columns}
                                getRowId={(row) => row.slno}
                                autoHeight
                                pageSize={gridSize}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                components={{ Toolbar: CustomGridToolbar }}
                            />
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
