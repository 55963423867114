import React, { useEffect } from "react";
import { COMPLTD_SESNS_TBL_HEAD } from "./CompleatedSessionsTableHead";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSubjectsSessionsCompleated } from "../../../../redux/features/croSlice";

const CompleatedSessionsTable = ({ setOpen, row }) => {
    const dispatch = useDispatch();
    const { subjectSessionCompleated, loading } = useSelector(
        (state) => state.cro
    );
    useEffect(() => {
        console.log(row.courseEnrollmentId);
        dispatch(getSubjectsSessionsCompleated(row.courseEnrollmentId));
    }, []);
    console.log(subjectSessionCompleated);
    return (
        <Box minWidth={500}>
            <DataGrid
                loading={loading}
                rows={subjectSessionCompleated}
                columns={COMPLTD_SESNS_TBL_HEAD}
                getRowId={(row) => row.serialNumber}
                autoHeight
            />
        </Box>
    );
};

export default CompleatedSessionsTable;
