import React from "react";

import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
        field: "date",
        headerName: "date",
        width: 400,
        editable: true,
    },
    {
        field: "FeePerSession",
        headerName: "FeePerSession",
        width: 300,
        editable: true,
    },
];

const rows = [{ id: 1, date: "2023-04-26", FeePerSession: 339 }];

export const FeePerSessionData = () => {
    return (
        <div>
            <Box
                sx={{
                    height: 400,
                    width: "100%",
                    backgroundColor: "grey.200",
                    borderRadius: 1,
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </Box>
        </div>
    );
};
