import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    data: {},
    classes: [],
    country: [],
    courses: [],
    districts: [],
    syllabus: [],
    medium: [],
    states: [],
    tableData: [],
};

export const getPreData = createAsyncThunk("referral/pre-data", async (e) => {
    const res = await apiRequest({
        method: "GET",
        url: "pre-data/employeereferral",
    });
    return res;
});

export const saveLead = createAsyncThunk("referral/save_lead", async (e) => {
    const res = await apiRequest({
        method: "post",
        url: "lead/emoloyee-referral",
        data: e,
    });
    return res;
});

export const saveCroLead = createAsyncThunk(
    "referral/save_cro_lead",
    async (e) => {
        const res = await apiRequest({
            method: "post",
            url: "lead/admission-referral",
            data: e,
        });

        return res;
    }
);

export const getLeads = createAsyncThunk("referral/get_lead", async () => {
    const res = await apiRequest({
        method: "get",
        url: `lead/emoloyee-referral/`,
    });
    return res;
});

const refferelSlice = createSlice({
    name: "referral",
    initialState,
    reducers: {
        filterStates: (state, action) => {
            const filStates = state.data.states?.filter(
                (item) => item.countryId === action.payload
            );
            return {
                ...state,
                states: filStates,
            };
        },
        filterDistrict: (state, action) => {
            const filStates = state.data.districts?.filter(
                (item) => item.stateId === action.payload
            );
            return {
                ...state,
                districts: filStates,
            };
        },

        clearState: (state, action) => {
            return (state = initialState);
        },
    },
    extraReducers: (builder) => {
        //---------- get predata -----------
        builder.addCase(getPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getPreData.fulfilled, (state, action) => {
            return {
                ...state,
                data: action.payload.data,
                classes: action.payload.data.classes,
                country: action.payload.data.country,
                courses: action.payload.data.courses,
                syllabus: action.payload.data.syllabuses,
                medium: action.payload.data.mediums,
                loading: false,
            };
        });

        //---------- save Lead -----------

        builder.addCase(saveLead.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(saveLead.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        //------cro lead-----------

        builder.addCase(saveCroLead.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(saveCroLead.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        //---------- get leads -----------

        builder.addCase(getLeads.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getLeads.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        builder.addCase(getLeads.fulfilled, (state, action) => {
            const formatedData = formatDataForDataTable(
                action.payload.data.leads
            );
            return {
                ...state,
                tableData: formatedData,
                loading: false,
            };
        });
    },
});

export const { filterStates, filterDistrict, clearState } =
    refferelSlice.actions;

export default refferelSlice.reducer;
