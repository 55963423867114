import { Button } from "@mui/material";
import React, { useState } from "react";
import PopUps from "./PopUps";
import AddExpectedSessionsForm from "./AddExpectedSessionsForm";

const AddExpectedSessions = ({ row }) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const componentProps = {
        setOpen: setOpen,
        row: row,
    };

    if (row.expectedSessions === null) {
        return (
            <div>
                <Button
                    variant="contained"
                    color="warning"
                    onClick={handleClickOpen}
                >
                    Add
                </Button>
                <PopUps
                    open={open}
                    setOpen={setOpen}
                    component={AddExpectedSessionsForm}
                    componentProps={componentProps}
                />
            </div>
        );
    }
    return <div>{row.expectedSessions}</div>;
};

export default AddExpectedSessions;
