import React, { forwardRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

// ---for implementing stop watch-----

import { useStopwatch } from "react-timer-hook";
import { verifyOtp, resendOtp as resend } from "../../redux/features/authSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OtpModal({ isModal, handleClose }) {
    const navigate = useNavigate();
    const [resendOtp, setResend] = useState(false);
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const handleResendOtp = async () => {
        setResend(false);
        reset();
        setLoading(true);
        dispatch(resend()).then((res) => {
            if (res.payload.status === "failed") {
                setLoading(false);
                enqueueSnackbar(res.payload.message);
            } else if (res.payload.status === "success") {
                setLoading(false);
                enqueueSnackbar(res.payload.message);
            }
        });
    };
    const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
        useStopwatch({ autoStart: true });
    function MyStopwatch() {
        return (
            <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: "18px", color: "#FFA900" }}>
                    <span>{days}</span>:<span>{hours}</span>:
                    <span>{minutes}</span>:<span>{seconds}</span>
                </div>
                {/* <p>{isRunning ? "Running" : "Not running"}</p>
                <button onClick={start}>Start</button>
                <button onClick={pause}>Pause</button>
                <button onClick={reset}>Reset</button> */}
            </div>
        );
    }

    const handleVerifyOtp = async (e) => {
        setLoading(true);
        dispatch(
            verifyOtp({
                otp,
            })
        ).then((res) => {
            if (res.payload.status === "failed") {
                setLoading(false);
                enqueueSnackbar(res.payload.message);
                setOtp("");
            } else if (res.payload.status === "success") {
                setLoading(false);
                setOtp("");
                enqueueSnackbar(res.payload.message);

                navigate("/auth/resetpswrd");
            }
        });
    };
    // const offTimer = () => {
    //
    //
    // };
    useEffect(() => {
        //
        //
        if (!resendOtp) {
            const unsub = setTimeout(() => {
                setResend(true);
            }, [120000]);
            return () => unsub;
        }
    }, [resendOtp]);

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Slide in alert dialog
            </Button> */}
            <Dialog
                open={isModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Enter Otp"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack
                            sx={{ width: "400px", paddingTop: "10px" }}
                            direction="column"
                            gap={2}
                        >
                            <TextField
                                type="text"
                                label="Enter Otp"
                                helperText="Enter the otp send to the registered email"
                                size="medium"
                                onChange={(e) => setOtp(e.target.value)}
                                error={Boolean(otp.length < 4)}
                                value={otp}
                            />
                            <Stack direction="row" gap={3}>
                                {resendOtp ? (
                                    <Button
                                        variant="text"
                                        color="primary"
                                        size="medium"
                                        onClick={handleResendOtp}
                                    >
                                        Resend Otp
                                    </Button>
                                ) : (
                                    <div>{<MyStopwatch />}</div>
                                )}

                                {!resendOtp ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={handleVerifyOtp}
                                    >
                                        Verify otp
                                    </Button>
                                ) : null}
                            </Stack>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button> */}
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
