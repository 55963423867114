import { Button } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";

export default function ResumeView(params) {
  const onResumeClick = () => {
    window.open(params.row.resume);
  };
  return (
    <div>
      {" "}
      <a onClick={onResumeClick}>
        <Button>
          <Icon icon="pepicons-pop:cv" width="30" height="30" />
        </Button>
      </a>
    </div>
  );
}
