import React from "react";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { Box, Stack, TextField } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
    CancelButton,
    UpdateButton,
} from "../../../../components/mui/Buttons/Buttons";

const status = [
    { id: 1, status: "Admission Confirmed" },
    { id: 2, status: "Postponed" },
    { id: 3, status: "Fee Issue" },
    { id: 4, status: "Not Needed Now" },
    { id: 5, status: "No Course to Enroll" },
];

export const UpdateForm = () => {
    const [value, setValue] = React.useState(null);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: "",
        },
        onSubmit: () => {},
    });
    return (
        <div>
            <Box padding={4}>
                <Box>
                    <GlobalSelectField
                        options={{
                            formik: formik,
                            sx: { width: "100%" },
                            label: "Status",
                            name: "status",
                            data: status,

                            keys: {
                                id: "id",
                                value: "status",
                            },
                        }}
                    />
                </Box>
                {formik.values.status === 1 ? (
                    <Box paddingTop={2}>
                        <Stack direction={"column"} spacing={2}>
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        formik: formik,
                                        sx: { width: "100%" },
                                        label: "Course",
                                        name: "Course",
                                        data: status,

                                        keys: {
                                            id: "id",
                                            value: "status",
                                        },
                                    }}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    label="Student Details (In Brief)"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    label="Subjects & Session Count per Subject"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Box>
                            <Box>
                                <TextField label="Fee per Session" fullWidth />
                            </Box>
                            <Box>
                                <TextField label="Session per Week" fullWidth />
                            </Box>
                            <Box>
                                <TextField
                                    label="Time Prefered"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Box>
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        formik: formik,
                                        sx: { width: "100%" },
                                        label: "Trainer Prefered",
                                        name: "TrainerPrefered",
                                        data: status,

                                        keys: {
                                            id: "id",
                                            value: "status",
                                        },
                                    }}
                                />
                            </Box>
                            <Box>
                                <TextField label="Total Sessions" fullWidth />
                            </Box>
                            <Box>
                                <TextField label="Total Fees" fullWidth />
                            </Box>
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        formik: formik,
                                        sx: { width: "100%" },
                                        label: "Fee Status ",
                                        name: "FeeStatus ",
                                        data: status,

                                        keys: {
                                            id: "id",
                                            value: "status",
                                        },
                                    }}
                                />
                            </Box>
                            <Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label="Admission Confirmed On "
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField fullWidth {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Box>
                                <TextField
                                    label="Required Books & Publications"
                                    fullWidth
                                />
                            </Box>
                        </Stack>
                    </Box>
                ) : null}

                <Box marginTop={2}>
                    <TextField label="Remark" fullWidth multiline rows={3} />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 2,
                    }}
                >
                    <Stack direction={"row"} spacing={2}>
                        <CancelButton>Cancel</CancelButton>
                        <UpdateButton>Update</UpdateButton>
                    </Stack>
                </Box>
            </Box>
        </div>
    );
};
