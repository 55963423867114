import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { Box, Stack } from "@mui/material";
import CartHeader from "./CartHeader";
import StudentProfile from "./StudentProfile";
import CartCourses from "./CartCourses";
import useResponsive from "../../../../Hooks/useResponsive";

const CourseDetails = [
  {
    id: 1,
    courseName: "Foundation",
    courseImg:
      "https://campustechnology.com/-/media/EDU/CampusTechnology/2019-Images/20191209online.jpg",
  },
  {
    id: 2,
    courseName: "ClassRoom",
    courseImg:
      "https://campustechnology.com/-/media/EDU/CampusTechnology/2019-Images/20191209online.jpg",
  },
  {
    id: 3,
    courseName: "SpeakPro",
    courseImg:
      "https://campustechnology.com/-/media/EDU/CampusTechnology/2019-Images/20191209online.jpg",
  },
];

const Cart = () => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const [totalCourses, setTotalCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  return (
    <GlobalWrapper title="Cart">
      <Box>
        <Stack direction={smUp ? "row" : "column"} spacing={1}>
          <Box width={mdUp ? "75%" : "100%"}>
            <CartHeader
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              CourseDetails={CourseDetails}
              setTotalCourses={setTotalCourses}
            />
            <Box>
              <CartCourses
                setTotalCourses={setTotalCourses}
                totalCourses={totalCourses}
              />
            </Box>
          </Box>
          <Box width={mdUp ? "25%" : "100%"}>
            <StudentProfile />
          </Box>
        </Stack>
      </Box>
    </GlobalWrapper>
  );
};

export default Cart;
