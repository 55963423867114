import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import {
  Department,
  Designation,
  EditEmployee,
  EmployeeData,
} from "../../../views/user/mytasks";
import Page404 from "../../../views/user/Auth/Page404";
import AllAttendance from "../../../views/user/mytasks/Attendance/AllAttendance";
import EditAttendance from "../../../views/user/mytasks/Attendance/EditAttendance";
import LeaveAnalytics from "../../../views/user/mytasks/leave/LeaveAnalytics";
import ReportingHeadBypassCount from "../../../views/user/mytasks/leave/ReportingHeadBypassCount";
import HrLeaveApplications from "../../../views/user/mytasks/leave/HrLeaveApplications";
import ApproveLeave from "../../../views/user/mytasks/leave/ApproveLeave";
import AllJobApplications from "../../../views/user/mytasks/JobApplication/AllJobApplications";
import InterviewProcedure from "../../../views/user/mytasks/JobApplication/InterviewProcedure";
import Holidays from "../../../views/hr/myworkspace/Holidays";
import FoodManagement from "../../../views/hr/myworkspace/Food/FoodManagement";

const HrRouter = () => {
  const { role } = useSelector((state) => state);

  return (
    !role.loading && (
      <Routes>
        {role.navChild.includes("employees") && (
          <Route path="employees" element={<EmployeeData />} />
        )}
        {role.permissions.includes("employee edit") && (
          <Route path="editemployee/:id" element={<EditEmployee />} />
        )}
        {role.navChild.includes("designations") && (
          <Route path="designations" element={<Designation />} />
        )}
        {role.navChild.includes("departments") && (
          <Route path="departments" element={<Department />} />
        )}
        {role.navChild.includes("all attendance") && (
          <Route path="all-attendance" element={<AllAttendance />} />
        )}
        {role.navChild.includes("all leave applications") && (
          <Route
            path="all-leave-applications"
            element={<HrLeaveApplications />}
          />
        )}
        {role.navChild.includes("leave analytics") && (
          <Route path="leave-analytics" element={<LeaveAnalytics />} />
        )}

        {role.navChild.includes("leave analytics") && (
          <Route
            path="reporting-head-bypass-count"
            element={<ReportingHeadBypassCount />}
          />
        )}
        {role.navChild.includes("all leave applications") && (
          <Route path="hr-leave-approve/:leaveId" element={<ApproveLeave />} />
        )}
        {/* {role.navChild.includes("all attendance") && (
          <Route path="edit-attendance/:id" element={<EditAttendance />} />
        )} */}
        {role.navChild.includes("job applications") && (
          <Route path="job-applications" element={<AllJobApplications />} />
        )}
        {role.navChild.includes("job applications") && (
          <Route
            path="interviewprocedure/:id"
            element={<InterviewProcedure />}
          />
        )}
        {role.navChild.includes("holiday calendar") && (
          <Route path="holiday-calendar" element={<Holidays />} />
        )}

        <Route
          path="*"
          element={
            <Page404
              message={
                "you don't have the permission to perform such an action"
              }
            />
          }
        />
      </Routes>
    )
  );
};

export default HrRouter;
