import React, { useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { AddButton } from "../../../../components/mui/Buttons/Buttons";
import { BookingForm } from "./BookingForm";

export const BookingPopUp = () => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <GlobalDialog
                title="Booking Form"
                open={open}
                setOpen={setOpen}
                closeButton={true}
                fullWidth
                maxWidth={"md"}
                popupButton={
                    <>
                        <AddButton action={() => setOpen(true)}>
                            Book Now
                        </AddButton>
                    </>
                }
                content={
                    <>
                        <BookingForm setOpen={setOpen} />
                    </>
                }
            />
        </div>
    );
};
