import { Box, Chip } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";

export const AvailabilityStatus = ({ params }) => {
    return (
        <div>
            <Box>
                <Chip
                    sx={{ width: 150 }}
                    variant="outlined"
                    color={params.row.isAvailable === 0 ? "error" : "success"}
                    icon={
                        params.row.isAvailable === 0 ? (
                            <Icon icon="radix-icons:cross-2" />
                        ) : (
                            <Icon icon="charm:tick" />
                        )
                    }
                    label={
                        params.row.isAvailable === 0
                            ? "Not Available"
                            : "Available"
                    }
                />
            </Box>
        </div>
    );
};
