import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import Page from "../../../components/Page";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import {
  addPermission,
  filterDeptPreData,
  getDepartmentPreData,
  getPermissionsPreData,
  getPermissions,
  clearDeptPreData,
} from "../../../redux/features/privilegesSlice";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import PrivilageViews from "../../../components/privileges/PrivilageViews";
import Loader from "../../../components/Loader/Loader";
import useResponsive from "../../../../src/Hooks/useResponsive";

const Privileges = () => {
  const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const [department, setDepartment] = useState({
        hrm: false,
        departmentId: null,
    });
    const [tableReload, setReload] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const state = useSelector((state) => state);

    const formSchema = yup.object().shape({
        permissionId: yup.number().required("This feild is required"),
        userTypeId: yup.array().min(1).required("This field is required"),
        departmentId: yup.number().when({
            is: () => {
                return department.hrm;
            },
            then: yup.number().required("This feild is required"),
        }),
    });

  const formik = useFormik({
    initialValues: {
      permissionId: "",
      departmentId: "",
      userTypeId: [],
    },
    validationSchema: formSchema,
    onSubmit: (values) => {
      const { departmentId, ...others } = values;
      dispatch(addPermission(others)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          setReload(res.payload.message);
          dispatch(getPermissions());
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getPermissionsPreData());
    dispatch(getDepartmentPreData());
    dispatch(getPermissions());

    const profile = JSON.parse(localStorage.getItem("cred")).profile;

        if (profile.roleID === 23) {
            setDepartment({
                ...department,
                hrm: true,
                departmentId: profile.departmentId,
            });
        } else {
            setDepartment({
                ...department,
                hrm: false,
                departmentId: profile.departmentId,
            });
        }

    return () => dispatch(clearDeptPreData());
  }, []);

    useEffect(() => {
        if (formik.values.departmentId) {
            if (department.hrm) {
                setDepartment({
                    ...department,
                    departmentId: formik.values.departmentId,
                });
            }
        }
    }, [formik.values.departmentId]);
    useEffect(() => {
        dispatch(filterDeptPreData(department.departmentId));
    }, [department, state.privileges.deptPreData]);

    return (
        <Page title="Privileges">
            <Container component="main">
                {/*..................................HEADING.............................*/}
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Set Privileges
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box
                    sx={{
                        paddingLeft: "70%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                ></Box>
                {state.privileges.loading ? (
                    //   <Typography variant="h1">Loading...</Typography>
                    <Loader />
                ) : (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mt: { md: 4, xs: 2 },
                            }}
                        >
                            <Paper
                                elevation={4}
                                sx={{
                                    width: "100%",
                                    backgroundColor: "grey.200",
                                }}
                            >
                                {/* GRAY-HEADING */}
                                <FormikProvider value={formik}>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Box sx={{ p: { md: 8, xs: 4 } }}>
                                            <Typography
                                                variant="h5"
                                                color={"#637381"}
                                            >
                                                Add Permission
                                            </Typography>
                                            <Divider
                                                sx={{
                                                    borderBottomWidth: 3,
                                                    marginBottom: 2,
                                                }}
                                            />
                                            {smUp && (
                                                <Stack
                                                    direction="row"
                                                    gap={2}
                                                    m={1}
                                                >
                                                    {state.privileges
                                                        .loading ? (
                                                        <Skeleton
                                                            variant="rectangular"
                                                            height={56}
                                                            sx={{
                                                                width: "300px",
                                                                borderRadius: 1,
                                                            }}
                                                            size="medium"
                                                        />
                                                    ) : (
                                                        <GlobalSelectField
                                                            options={{
                                                                label: "Select privileges",
                                                                formik: formik,
                                                                name: "permissionId",
                                                                data: state
                                                                    .privileges
                                                                    .privileges,
                                                                keys: {
                                                                    id: "permissionID",
                                                                    value: "title",
                                                                },
                                                                sx: {
                                                                    width: "300px",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                    {department.hrm ? (
                                                        <GlobalSelectField
                                                            options={{
                                                                label: "select Department",
                                                                name: "departmentId",
                                                                data: state
                                                                    .privileges
                                                                    .deptPreData,
                                                                formik,
                                                                keys: {
                                                                    id: "departmentId",
                                                                    value: "departmentName",
                                                                },
                                                                sx: {
                                                                    width: "300px",
                                                                },
                                                            }}
                                                        />
                                                    ) : null}
                                                </Stack>
                                            )}
                                            {!smUp && (
                                                <Stack
                                                    direction="column"
                                                    gap={2}
                                                    m={1}
                                                >
                                                    {state.privileges
                                                        .loading ? (
                                                        <Skeleton
                                                            variant="rectangular"
                                                            height={56}
                                                            sx={{
                                                                width: "300px",
                                                                borderRadius: 1,
                                                            }}
                                                            size="medium"
                                                        />
                                                    ) : (
                                                        <GlobalSelectField
                                                            options={{
                                                                label: "Select privileges",
                                                                formik: formik,
                                                                name: "permissionId",
                                                                data: state
                                                                    .privileges
                                                                    .privileges,
                                                                keys: {
                                                                    id: "permissionID",
                                                                    value: "name",
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                    {department.hrm ? (
                                                        <GlobalSelectField
                                                            options={{
                                                                label: "select Department",
                                                                name: "departmentId",
                                                                data: state
                                                                    .privileges
                                                                    .deptPreData,
                                                                formik,
                                                                keys: {
                                                                    id: "departmentId",
                                                                    value: "departmentName",
                                                                },
                                                            }}
                                                        />
                                                    ) : null}
                                                </Stack>
                                            )}
                                            {state.privileges.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    height={56}
                                                    sx={{
                                                        borderRadius: 1,
                                                    }}
                                                    size="medium"
                                                />
                                            ) : (
                                                // <Stack m={1} gap={2} justifyContent="center">
                                                <>
                                                    <Box padding={1}>
                                                        <Autocomplete
                                                            multiple
                                                            id="tags-filled"
                                                            options={
                                                                state.privileges
                                                                    .filteredPreData
                                                                    .userTypes ||
                                                                []
                                                            }
                                                            getOptionLabel={(
                                                                option
                                                            ) =>
                                                                option.userType
                                                            }
                                                            onChange={(
                                                                e,
                                                                value
                                                            ) => {
                                                                let items = [];
                                                                value.map(
                                                                    (item) => {
                                                                        items.push(
                                                                            item.userTypeId
                                                                        );
                                                                    }
                                                                );
                                                                formik.setFieldValue(
                                                                    "userTypeId",
                                                                    items
                                                                );
                                                            }}
                                                            freeSolo
                                                            renderTags={(
                                                                value,
                                                                getTagProps
                                                            ) =>
                                                                value.map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <Chip
                                                                            variant="outlined"
                                                                            label={
                                                                                option.userType
                                                                            }
                                                                            {...getTagProps(
                                                                                {
                                                                                    index,
                                                                                }
                                                                            )}
                                                                        />
                                                                    )
                                                                )
                                                            }
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    name="useTypeId"
                                                                    error={Boolean(
                                                                        formik
                                                                            .errors
                                                                            .userTypeId
                                                                    )}
                                                                    helperText={
                                                                        formik
                                                                            .errors
                                                                            .userTypeId
                                                                    }
                                                                    {...params}
                                                                    variant="outlined"
                                                                    label="User Type"
                                                                    placeholder="User Type"
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "flex-end",
                                                            margin: 2,
                                                        }}
                                                    >
                                                        <LoadingButton
                                                            type="submit"
                                                            size="medium"
                                                            sx={{
                                                                backgroundColor:
                                                                    "#00ab554a",
                                                                color: "#00ab55",
                                                            }}
                                                        >
                                                            <Icon
                                                                icon="material-symbols:add"
                                                                width="30"
                                                                height="30"
                                                            />

                              {state.privileges.loading
                                ? "Adding..."
                                : "Add permission"}
                            </LoadingButton>
                          </Box>
                        </>
                        // </Stack>
                      )}
                    </Box>
                  </Form>
                </FormikProvider>
              </Paper>
            </Box>
            <PrivilageViews data={state} />
          </>
        )}
      </Container>
    </Page>
  );
};

export default Privileges;
