import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { Box, Button, Paper, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
    getCoursesBaseTable,
    getClassPreData,
} from "../../../redux/features/mastersSlice";
import { useState } from "react";
import { BASE_COURSES_TABLE_HEAD } from "../../../components/datagrid/myworkspace/research/BaseCoursesTableHead";
import { AddProjectClassPopup } from "../../../components/Masters/AddProjectClassPopup";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
import {
    getCourseAvailableData,
    getSubjectsByClass,
    getMediums,
    getSyllabus,
    getProjectCategoriesPreData,
    getBaseCourseListingFiltered,
} from "../../../redux/features/researchSlice";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import SelectionHeader from "../../../components/mui/SelectionHeader/SelectionHeader";

export default function BaseCourses() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const [classData, setClassData] = useState([]);
    const { coursesBaseTable, loading, classPreData } = useSelector(
        (state) => state.masters
    );
    const {
        courseAvailablePreData,
        subjectsByClassPreData,
        mediumsPreData,
        syllabusPreData,
        projectCategoriesPreData,
        baseCourseFilteredData,
    } = useSelector((state) => state.research);

    useEffect(() => {
        dispatch(getClassPreData());
        dispatch(getCourseAvailableData());
        dispatch(getCoursesBaseTable());
        dispatch(getMediums());
        dispatch(getSyllabus());
        dispatch(getProjectCategoriesPreData());
    }, []);

    const formik = useFormik({
        initialValues: {
            courseAvailableId: "",
            classId: 0,
            subjectId: 0,
            mediumId: 0,
            syllabusId: 0,
            levelId: 0,
        },
        onSubmit: (values) => {
            dispatch(getBaseCourseListingFiltered(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
            console.log("onSubmit", values);
        },
    });
    console.log(formik.values);
    useEffect(() => {
        let classData = classPreData.filter((item) => {
            return item.courseAvailableId === formik.values.courseAvailableId;
        });
        setClassData(classData);
    }, [formik.values.courseAvailableId]);

    useEffect(() => {
        if (formik.values.classId) {
            dispatch(getSubjectsByClass(formik.values.classId));
        }
    }, [formik.values.classId]);

    return (
        <div>
            <Wrapper
                title="Base Courses"
                // actions={<AddProjectClassPopup />}
                Content={
                    <>
                        {/* <Box
                            sx={{
                                backgroundColor: "grey.200",
                                padding: 1,
                                mb: 2,
                                borderRadius: 2,
                            }}
                        ></Box> */}
                        <SelectionHeader
                            content={
                                <>
                                    <FormikProvider value={formik}>
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Stack
                                                direction={"column"}
                                                spacing={2}
                                            >
                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                >
                                                    <Box>
                                                        <GlobalSelectField
                                                            options={{
                                                                name: "courseAvailableId",
                                                                formik,
                                                                sx: {
                                                                    width: "100%",
                                                                    "& .MuiInputBase-root":
                                                                        {
                                                                            height: 50,
                                                                        },
                                                                },
                                                                label: "Course",
                                                                data: courseAvailablePreData,
                                                                keys: {
                                                                    id: "id",
                                                                    value: "courseName",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <GlobalSelectField
                                                            options={{
                                                                name: "classId",
                                                                formik,
                                                                sx: {
                                                                    "& .MuiInputBase-root":
                                                                        {
                                                                            height: 50,
                                                                        },
                                                                },
                                                                label: "class",
                                                                data: classData,
                                                                keys: {
                                                                    id: "id",
                                                                    value: "className",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <GlobalSelectField
                                                            options={{
                                                                name: "subjectId",
                                                                formik,
                                                                sx: {
                                                                    "& .MuiInputBase-root":
                                                                        {
                                                                            height: 50,
                                                                        },
                                                                },
                                                                label: "Subject",
                                                                data: subjectsByClassPreData,
                                                                keys: {
                                                                    id: "id",
                                                                    value: "name",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                </Stack>
                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                >
                                                    <Box>
                                                        <GlobalSelectField
                                                            options={{
                                                                name: "mediumId",
                                                                formik,
                                                                sx: {
                                                                    "& .MuiInputBase-root":
                                                                        {
                                                                            height: 50,
                                                                        },
                                                                },
                                                                label: "Medium",
                                                                data: mediumsPreData,
                                                                keys: {
                                                                    id: "id",
                                                                    value: "name",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <GlobalSelectField
                                                            options={{
                                                                name: "syllabusId",
                                                                formik,
                                                                sx: {
                                                                    "& .MuiInputBase-root":
                                                                        {
                                                                            height: 50,
                                                                        },
                                                                },
                                                                label: "Syllabus",
                                                                data: syllabusPreData,
                                                                keys: {
                                                                    id: "id",
                                                                    value: "name",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <GlobalSelectField
                                                            options={{
                                                                name: "levelId",
                                                                formik,
                                                                sx: {
                                                                    "& .MuiInputBase-root":
                                                                        {
                                                                            height: 50,
                                                                        },
                                                                },
                                                                label: "level",
                                                                data: projectCategoriesPreData,
                                                                keys: {
                                                                    id: "id",
                                                                    value: "name",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box pt={1}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                        >
                                                            Filter
                                                        </Button>
                                                    </Box>
                                                </Stack>
                                            </Stack>
                                        </Form>
                                    </FormikProvider>
                                </>
                            }
                        />
                        <Paper elevation={3}>
                            <Box sx={{ width: "100%", padding: 0 }}>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    loading={loading}
                                    autoHeight
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    getRowHeight={() => "auto"}
                                    rows={baseCourseFilteredData}
                                    columns={BASE_COURSES_TABLE_HEAD}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
