import DepartmentActions from "./DepartmentActions";

export const DEPARTMENT_TABLE_HEAD = [
    {
        field: "slNo",
        minWidth: 30,
        headerName: "slNo.",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "departmentId",
        minWidth: 30,
        headerName: "id.",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "departmentName",
        minWidth: 300,
        headerName: "Department Name.",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "hod",
        minWidth: 450,
        headerName: "Head Of Department",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Actions",
        minWidth: 300,
        headerName: "Actions",
        type: "string",

        renderCell: (params) => DepartmentActions(params),
    },
];
