import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box } from "@mui/material";

// this component can be used as global popUp, it accepts componets as props,
//component props which is the propes used inside the main content of the popup,
// also there is a state which is boolean used to manage the opening and closing

const PopUps = ({ component: Component, componentProps, open, setOpen }) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth={"xl"}
            >
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        close
                    </Button>
                </DialogActions>
                <DialogContent>
                    <Component {...componentProps} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PopUps;
