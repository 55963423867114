import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import { CardHeader, Box, Typography, Chip } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import BaseOptionChart from "../../DashBoardCharts/ReactApexCharts/BaseOptionChart";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(5),
    "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
    "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
        overflow: "visible",
    },
    "& .apexcharts-legend": {
        height: LEGEND_HEIGHT,
        alignContent: "center",
        position: "relative !important",
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

export default function GlobalDonutChart({ title, chipValue, chartData }) {
    const theme = useTheme();

    const labels = chartData.map((data) => data.label);
    const values = chartData.map((data) => data.value);
    

    const chartOptions = merge(BaseOptionChart(), {
        colors: [
            theme.palette.chart.green[0],
            theme.palette.primary.light,
            theme.palette.chart.yellow[0],
            theme.palette.primary.main,
            theme.palette.primary.dark,
            theme.palette.chart.violet[0],
        ],
        labels: labels,
        stroke: { colors: [theme.palette.background.paper] },
        legend: { floating: true, horizontalAlign: "center" },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName) => fNumber(seriesName),
                title: {
                    formatter: (seriesName) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "90%",
                    labels: {
                        show: true,
                        name: {
                            show: true,
                        },
                        value: {
                            show: true,
                            formatter: (val, opts) => {
                                const count =
                                    opts?.w?.globals?.series[opts.seriesIndex];
                                return fNumber(count);
                            },
                        },
                        total: {
                            show: true,
                            label: "Total",
                            formatter: (w) => {
                                const sum = w?.globals?.seriesTotals?.reduce(
                                    (a, b) => a + b,
                                    0
                                );
                                return fNumber(sum);
                            },
                        },
                    },
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: (val, opts) => {
                const count = opts?.w?.globals?.series[opts.seriesIndex];
                return fNumber(count);
            },
            dropShadow: { enabled: false },
            style: {
                fontSize: "14px",
                fontWeight: "bold",
                colors: [theme.palette.text.primary],
            },
        },
    });

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <CardHeader title={title} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                {chipValue ? (
                    <Chip
                        label={
                            <Typography variant="h6">{chipValue}</Typography>
                        }
                        variant="outlined"
                        color="primary"
                    />
                ) : null}
            </Box>

            <ChartWrapperStyle dir="ltr">
                <ReactApexChart
                    type="donut"
                    series={values}
                    options={chartOptions}
                    height={320}
                />
            </ChartWrapperStyle>
        </>
    );
}
