import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box} from "@mui/material";

const DatePickerCustom = ({ value, setValue, viewDetails,fullWidth=false,error,helperText ,readOnly=false,maxWidth,label}) => {

  return (
    <Box sx={{ maxWidth }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
        
          readOnly={readOnly}
          slotProps={{ textField: { fullWidth: fullWidth ,error: error,
          helperText: helperText,label:label} }}
          displayStaticWrapperAs="desktop"
          views={viewDetails}
          value={value} //NEEDED FULL DATE TO DISPLAY
          onChange={(newValue) => setValue(newValue)}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DatePickerCustom;
