import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";

const columns = [
    { field: "serialNumber", headerName: "SlNo", width: 90 },
    {
        field: "enquiryName",
        headerName: "Enquiry Name",
        width: 250,
    },
    {
        field: "enquiryDate",
        headerName: "Enquiry Date",
        width: 150,
    },
    {
        field: "enquiryRemarks",
        headerName: "enquiryRemarks",

        width: 400,
    },
];
export const LogData = () => {
    const data = useSelector((state) => state.cro);
    return (
        <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
                loading={data.loading}
                rows={data.croStudentEnquiryLog}
                columns={columns}
                getRowHeight={() => "auto"}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                getRowId={(row) => row.serialNumber}
                disableRowSelectionOnClick
            />
        </Box>
    );
};
