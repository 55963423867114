import { Icon } from "@iconify/react";
import { Chip } from "@mui/material";
import { AddAttendance } from "./RenderCellMain";
import { TrainerAttendanceDelete } from "./RenderCellMain";

export const TRAINER_ATTENDANCE_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Serial Number",
        width: 90,
    },
    {
        field: "sessionDate",
        headerName: "Session Date",
        width: 160,
    },
    {
        field: "fromTime",
        headerName: "From Time",
        width: 150,
    },
    {
        field: "toTime",
        headerName: "To Time",
        width: 150,
    },
    {
        field: "sessionContent",
        headerName: "Session Content",
        width: 300,
    },
    {
        field: "runningStatus",
        headerName: "Running Status",
        width: 150,
        renderCell: (params) => {
            return (
                <Chip
                    icon={
                        params.row.runningStatus === 0 ? (
                            <Icon icon="radix-icons:cross-2" />
                        ) : (
                            <Icon icon="charm:tick" />
                        )
                    }
                    label={
                        params.row.runningStatus === 0
                            ? "Not Running"
                            : "Running"
                    }
                    variant="outlined"
                    size="small"
                    color={
                        params.row.runningStatus === 0 ? "warning" : "primary"
                    }
                />
            );
        },
    },
    {
        field: "status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
            return (
                <Chip
                    icon={
                        params.row.status === "Completed" ? (
                            <Icon icon="charm:tick" />
                        ) : (
                            <Icon icon="radix-icons:cross-2" />
                        )
                    }
                    label={params.row.status}
                    variant="outlined"
                    size="small"
                    color={
                        params.row.status === "Completed"
                            ? "success"
                            : "warning"
                    }
                />
            );
        },
    },
    {
        field: "MarkAttendance",
        headerName: "Mark Attendance",
        width: 150,
        renderCell: (params) => {
            var currentDate = new Date();
            var givenDateString = params.row.sessionDate;
            var myDate = new Date(givenDateString);

            if (myDate <= currentDate) {
                return <AddAttendance {...params.row} />;
            } else {
                return null;
            }
        },
    },

    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => {
            if (params.row.status === "Completed") {
                return <TrainerAttendanceDelete {...params.row} />;
            } else {
                return null;
            }
        },
    },
];
