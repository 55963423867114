import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const Slots = ({ params }) => {
    const timeStr = "07:00 PM";
    const [hours, minutes] = timeStr.split(":");
    let actualTimeObject = new Date();

    // Set hours and minutes
    actualTimeObject.setHours(parseInt(hours, 10));
    actualTimeObject.setMinutes(parseInt(minutes, 10));

    return (
        <div>
            <Button
                size="small"
                variant="contained"
                component={Link}
                to={`/my-workspace/slot-settings/${params.asset_id}`}
            >
                Slots
            </Button>
        </div>
    );
};
