import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  CancelButton,
  EditButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdmissionRegisterOgd,
  getCourses,
  updateStudentDetails,
} from "../../../../../redux/features/ogdSlice";
import { useSnackbar } from "notistack";
import { savePaginationData } from "../../../../../redux/features/globalDatagridSlice";

const EditStudentAction = ({ row, data }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { loading, courses } = useSelector((store) => store.ogd);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { paginationData } = useSelector((state) => state.globalDataGrid)

  const handleOpen=()=>{
    dispatch(getCourses());
    setOpen(true)
  }

  // useEffect(() => {
  //   if (open) {
  //     dispatch(getCourses());
  //   }
  // }, [open]);

  const initialValues = {
    courseOpted: row.courseId,
    totalSessions: row.totalSessions,
    feePerSession: row.feePerSession,
    sessionPerWeek: row.sessionPerWeek,
  };
  const schema = Yup.object({
    courseOpted: Yup.number().required("Course Required"),
    totalSessions: Yup.number().required("Total Sessions Required"),
    feePerSession: Yup.number().required("Fee Per Session Required"),
    sessionPerWeek: Yup.number().required("Session Per Week Required"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const dataToSend = {
        ...values,
        courseEnrollmentId: row.courseEnrollmentId,
        croAssignmentId: row.croAssignmentId,
        admissionNo: row.admissionNo,
      };

      dispatch(updateStudentDetails(dataToSend)).then((res) => {
        if (res.payload.status === "error") {
          enqueueSnackbar(res.payload.message, {
            variant: "error",
          });
        } else if (res.payload.status === "success") {
          setOpen(false);
          dispatch(getAdmissionRegisterOgd(data));
          dispatch(savePaginationData({ ...paginationData, openAnchor: false }));
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
        }
      });
    },
  });

  const handleCourseChange = (e, option) => {
    formik.setFieldValue("courseOpted", option.id);
  };

  const handleCancel = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <GlobalDialog
      maxWidth={"md"}
      fullWidth={true}
      title={"Edit Student"}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      popupButton={
        <>
          <EditButton
           size="small"
            disabled={row.courseEnrollmentStatus !== 1}
            action={handleOpen}
          >
            Edit
          </EditButton>
        </>
      }
      content={
        <>
          <Box>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Box sx={{ mb: 2 }}>
                  <Autocomplete
                    disabled={loading}
                    name="courseOpted"
                    options={courses}
                    getOptionLabel={(option) => option.courseName || ""}
                    value={
                      courses.find(
                        (course) => course.id == formik.values.courseOpted
                      ) || null
                    }
                    onChange={handleCourseChange}
                    renderInput={(params) => (
                      <TextField
                        disabled
                        fullWidth
                        autoComplete="off"
                        {...params}
                        label="Course Opted"
                        error={
                          formik.touched.courseOpted &&
                          Boolean(formik.errors.courseOpted)
                        }
                        helperText={
                          formik.touched.courseOpted &&
                          formik.errors.courseOpted
                        }
                      />
                    )}
                  />
                </Box>
                <Stack gap={2}>
                  <TextField
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="totalSessions"
                    fullWidth
                    type="number"
                    value={formik.values.totalSessions}
                    label="Total Sessions"
                    error={
                      formik.touched.totalSessions &&
                      Boolean(formik.errors.totalSessions)
                    }
                    helperText={
                      formik.touched.totalSessions &&
                      formik.errors.totalSessions
                    }
                  />
                  <TextField
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="feePerSession"
                    fullWidth
                    type="number"
                    value={formik.values.feePerSession}
                    label="Fee Per Session"
                    error={
                      formik.touched.feePerSession &&
                      Boolean(formik.errors.feePerSession)
                    }
                    helperText={
                      formik.touched.feePerSession &&
                      formik.errors.feePerSession
                    }
                  />
                  <TextField
                    onChange={formik.handleChange}
                    autoComplete="off"
                    name="sessionPerWeek"
                    fullWidth
                    type="number"
                    value={formik.values.sessionPerWeek}
                    label="Session Per Week"
                    error={
                      formik.touched.sessionPerWeek &&
                      Boolean(formik.errors.sessionPerWeek)
                    }
                    helperText={
                      formik.touched.sessionPerWeek &&
                      formik.errors.sessionPerWeek
                    }
                  />
                </Stack>

                <Box
                  sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
                >
                  <Stack direction={"row"} spacing={1}>
                    <CancelButton action={handleCancel}>Cancel</CancelButton>

                    <UpdateButton type="submit">
                      {loading ? "Please Wait..." : "Update"}
                    </UpdateButton>
                  </Stack>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </>
      }
    />
  );
};

export default EditStudentAction;
