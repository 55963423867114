export const ASSESSMENT_REPORT_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "lead_name",
        headerName: "Student Name",
        width: 200,
    },
    {
        field: "user_type",
        headerName: "User Type",
        width: 200,
    },
    {
        field: "age",
        headerName: "Age",
        width: 200,
    },
    {
        field: "course_name",
        headerName: "Course Name",
        width: 200,
    },
    {
        field: "combination",
        headerName: "Combination",
        width: 200,
    },
    {
        field: "course_duration",
        headerName: "Course Duration",
        width: 200,
    },
    {
        field: "lead_mob_no",
        headerName: "Mobile",
        width: 200,
    },
    {
        field: "whatsapp_number",
        headerName: "whatsapp Number",
        width: 200,
    },
    {
        field: "favourite_subject",
        headerName: "Favourite Subject",
        width: 200,
    },
    {
        field: "lead_class",
        headerName: "Class",
        width: 200,
    },
    {
        field: "lead_email_id",
        headerName: "Email",
        width: 200,
    },
    {
        field: "lead_place",
        headerName: "Place",
        width: 200,
    },
    {
        field: "syllabus_name",
        headerName: "Syllabus",
        width: 200,
    },
    {
        field: "designation",
        headerName: "Designation",
        width: 200,
    },
    {
        field: "company_name",
        headerName: "Company Name",
        width: 200,
    },
    {
        field: "lead_school_name",
        headerName: "School",
        width: 200,
    },
    {
        field: "lead_father_name",
        headerName: "Father Name",
        width: 200,
    },
    {
        field: "lead_mother_name",
        headerName: "Mother Name",
        width: 200,
    },
    {
        field: "remarks",
        headerName: "Remarks",
        width: 200,
    },
];
