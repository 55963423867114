import DeleteAction from "./DeleteAction";

export const PRIVILAGE_TABLE_HEAD = [
  {
    field: "serialNumber",
    flex: 0,
    Width: 90,
    headerName: "slno",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "title",
    flex: 1,
    minWidth: 90,
    headerName: "Permission",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "userType",
    flex: 1,
    minWidth: 90,
    headerName: "User Type",
    type: "string",
    headerAlign: "left",
    align: "left",
  },

  {
    field: "description",
    flex: 1,
    minWidth: 90,
    headerName: "Description",
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "Action",
    flex: 0.1,
    minWidth: 130,
    headerName: "Action",
    type: "number",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => DeleteAction(params),
  },
];
