import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import { IconButton } from "@mui/material";
import Iconify from "../Iconify";
import { getScheduleById } from "../../redux/features/scheduleSlice";
import { useDispatch, useSelector } from "react-redux";
import { SessionEditForm } from "./SessionEditForm";

export const EditSessionPopup = ({ params, handleClose }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <GlobalDialog
                title="Edit Schedule"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                popupButton={
                    <IconButton
                        aria-label="edit"
                        size="small"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={() => {
                            dispatch(getScheduleById(params));

                            setOpen(true);
                        }}
                    >
                        <Iconify
                            sx={{ marginRight: 1 }}
                            color="primary.main"
                            icon="eva:edit-fill"
                            width={22}
                            height={22}
                        />
                        Edit
                    </IconButton>
                }
                closeButton={true}
                content={
                    <SessionEditForm
                        handleClose={handleClose}
                        setOpen={setOpen}
                        params={params}
                    />
                }
            />
        </div>
    );
};
