import CreatedTaskUtilsMenu from "./CreatedTaskUtilsMenu";
import CreatedTaskActions from "./CreatedTaskActions";
export const CREATEDTASK_TABLE_HEAD = [
    {
        field: "serialNumber",
        flex: 0,
        Width: 90,
        headerName: "slno",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "departmentName",
        flex: 0.1,
        minWidth: 90,
        headerName: "Department",
        type: "string",
        headerAlign: "left",
        align: "left",
    },

    {
        field: "task",
        flex: 0,
        minWidth: 90,
        headerName: "task",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskPriority",
        flex: 0,
        minWidth: 90,
        headerName: "priority",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskDeadLineDate",
        flex: 0.1,
        minWidth: 90,
        headerName: "Deadline Date",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskDeadLineTime",
        flex: 0.1,
        minWidth: 90,
        headerName: "Deadline Time",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "assignedTo",
        flex: 0.1,
        minWidth: 90,
        headerName: "Preferred To",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "approvalStatus",
        flex: 0.1,
        minWidth: 90,
        headerName: "Approval Status",
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => CreatedTaskUtilsMenu(params),
    },
    {
        field: "Action",
        flex: 0.1,
        minWidth: 90,
        headerName: "Action",
        type: "number",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => CreatedTaskActions(params),
    },
];
