export const CRO_INCOME_FORECAST_TBL_HEAD = [
    {
        field: "Sl No.",
        headerName: "Sl No.",
        width: 150,
    },
    {
        field: "Admission No",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "Name of student",
        headerName: "Name of student",
        width: 150,
    },
    {
        field: "Course Enrolled",
        headerName: "Course Enrolled",
        width: 100,
    },
    {
        field: "CRO Course Status",
        headerName: "CRO Course Status",
        width: 200,
    },
    {
        field: "Accountant Course Status",
        headerName: "Accountant Course Status",
        width: 150,
    },

    {
        field: "Student Status",
        headerName: "Student Status",
        width: 150,
    },

    {
        field: "SRO Remarks",
        headerName: "SRO Remarks",
        width: 250,
    },
    {
        field: "First Session Date",
        headerName: "First Session Date",
        width: 150,
    },
    {
        field: "June Expected Sessions",
        headerName: "June Expected Sessions",
        width: 150,
    },
    {
        field: "June Sessions",
        headerName: "June Sessions",
        width: 150,
    },
    {
        field: "June Fee Paid",
        headerName: "June Fee Paid",
        width: 150,
    },
    {
        field: "June Income Forecast",
        headerName: "June Income Forecast",
        width: 150,
    },
    {
        field: "June Notes",
        headerName: "June Notes",
        width: 150,
    },
    {
        field: "July Expected Sessions",
        headerName: "July Expected Sessions",
        width: 150,
    },
    {
        field: "July Sessions",
        headerName: "July Sessions",
        width: 150,
    },
    {
        field: "July Fee Paid",
        headerName: "July Fee Paid",
        width: 150,
    },
    {
        field: "Expected Total Sessions",
        headerName: "Expected Total Sessions",
        width: 150,
    },
    {
        field: "Total Required Sessions",
        headerName: "Total Required Sessions",
        width: 150,
    },
    {
        field: "Total Sessions Completed",
        headerName: "Total Sessions Completed",
        width: 150,
    },
    {
        field: "Sessions Completion %",
        headerName: "Sessions Completion %",
        width: 150,
    },
    {
        field: "SRO Sales Amount",
        headerName: "SRO Sales Amount",
        width: 150,
    },
    {
        field: "Expected Total Income",
        headerName: "Expected Total Income",
        width: 150,
    },
    {
        field: "Total Fee Paid",
        headerName: "Total Fee Paid",
        width: 150,
    },
    {
        field: "Balance",
        headerName: "Balance",
        width: 150,
    },
    {
        field: "July Income Forecast",
        headerName: "July Income Forecast",
        width: 150,
    },
    {
        field: "July Notes",
        headerName: "July Notes",
        width: 150,
    },
    {
        field: "Bad Debt",
        headerName: "Bad Debt",
        width: 150,
    },
    {
        field: "Cross Sell Course",
        headerName: "Cross Sell Course",
        width: 150,
    },
    {
        field: "Cross Sell Amount",
        headerName: "Cross Sell Amount",
        width: 150,
    },
    {
        field: "August Income Forecast",
        headerName: "August Income Forecast",
        width: 150,
    },
];

// {
//     "Sl No.": 1,
//     "Admission No": 8877,
//     "Name of student": "AJSAL",
//     "Course Enrolled": "Foundation Course",
//     "CRO Course Status": "Active",
//     "Accountant Course Status": "Active",
//     "Student Status": "Not Set",
//     "SRO Remarks":
//         "He is mainly facing issue to understand hindi and writing difficult words.",
//     "First Session Date": "2023-04-26",
//     "June Expected Sessions": 1,
//     "June Sessions": 1,
//     "June Fee Paid": 0,
//     "June Income Forecast": 0,
//     "June Notes": "Cross sell",
//     "July Expected Sessions": "Add",
//     "July Sessions": 0,
//     "July Fee Paid": 0,
//     "Expected Total Sessions": 20,
//     "Total Required Sessions": 20,
//     "Total Sessions Completed": 20,
//     "Sessions Completion %": "100 %",
//     "Fee Collected %": "100 %",
//     "SRO Sales Amount": 6799,
//     "Expected Total Income": 6780,
//     "Total Fee Paid": 6799,
//     Balance: 0,
//     "July Income Forecast": "Expected Sessions Not Added",
//     "July Notes": "Expected Sessions Not Added",
//     "Bad Debt": 0,
//     "Cross Sell Course": "nill",
//     "Cross Sell Amount": 0,
//     "August Income Forecast": 0,
// },
