import React, { useState } from 'react'
import GlobalDialog from '../../../../mui/Dialogue/GlobalDialog'
import { Button } from '@mui/material'
import { Icon } from '@iconify/react/dist/iconify.js'
import { Profile } from '../../../CRM/AdmissionRegister/StudentProfile/Profile'

const ProfileViewAdmissionRegister = ({courseEnrollmentId,url,storeName}) => {
  return (
    <Profile url={url} params={courseEnrollmentId} storeName={storeName} />
  )
}

export default ProfileViewAdmissionRegister