import React from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    AddButton,
    CancelButton,
    SaveButton,
} from "../../../mui/Buttons/Buttons";
import { useFormik, Form, FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";
import useResponsive from "../../../../Hooks/useResponsive";
import {
    getAttendanceTypes,
    getSubjectTopics,
    addTrainerAttendance,
} from "../../../../redux/features/croSlice";
import GlobalSelectField from "../../../mui/GlobalSelectField";
import { Icon } from "@iconify/react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

export const MarkAttendancePopup = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const [time, setTime] = React.useState([{ time: new Date() }]);
    const dispatch = useDispatch();
    const {
        attendanceTypes,
        subjectTopicsPreData,

        loading,
    } = useSelector((state) => state.cro);
    const [open, setOpen] = React.useState(false);
    const Status = [
        { statusId: 0, status: "partial" },
        { statusId: 1, status: "completed" },
    ];

    const Schema = Yup.object().shape({
        attendanceTypeId: Yup.string().required("attendanceTypeId is required"),
        attendanceRemarks: Yup.string().required(
            "attendanceRemarks is required"
        ),

        topicsCovered: Yup.array().of(
            Yup.object().shape({
                moduleSessionId: Yup.number().required(
                    "please fill moduleSessionId"
                ),
                status: Yup.string().required("please fill status"),
            })
        ),
    });

    const formik = useFormik({
        initialValues: {
            scheduleId: params.id,
            attendanceTypeId: "",
            attendanceRemarks: "",
            topicsCovered: [
                {
                    moduleSessionId: "",
                    status: "",
                },
            ],
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            dispatch(addTrainerAttendance(values)).then((res) => {
                if (
                    res.payload.status === "failed" ||
                    res.payload.status === "error"
                ) {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    // console.log("formik.values", formik.values);

    const handleAdd = () => {
        let timeItem = {
            time: new Date(),
        };

        let newTime = [...time];
        newTime.push(timeItem);

        setTime(newTime);

        let item = {
            moduleSessionId: "",
            status: "",
        };

        let values = [...formik.values.topicsCovered];
        values.push(item);
        formik.setFieldValue("topicsCovered", values);
    };

    const handleRemove = (i) => {
        if (formik.values.topicsCovered.length > 1) {
            let values = [...formik.values.topicsCovered];
            let newTime = [...time];

            newTime.splice(i, 1);
            values.splice(i, 1);

            formik.setFieldValue("topicsCovered", values);
            setTime(newTime);
        } else {
            formik.setFieldValue("topicsCovered", [
                {
                    moduleSessionId: "",
                    statusId: "",
                },
            ]);
            setTime([{ time: new Date() }]);
        }
    };

    const handleTopicsChange = (i, e) => {
        let values = [...formik.values.topicsCovered];
        values[i] = { ...values[i], moduleSessionId: e.target.value };
        formik.setFieldValue("topicsCovered", values);
    };
    const handleStatusChange = (i, e) => {
        let values = [...formik.values.topicsCovered];
        values[i] = { ...values[i], status: e.target.value };
        formik.setFieldValue("topicsCovered", values);
    };

    return (
        <div>
            <GlobalDialog
                open={open}
                title="Mark Attendance"
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                closeButton={true}
                popupButton={
                    <Box>
                        <AddButton
                            size="small"
                            action={() => {
                                dispatch(getSubjectTopics(80));
                                dispatch(getAttendanceTypes());
                                setOpen(true);
                            }}
                        >
                            Add Attendance
                        </AddButton>
                    </Box>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <TextField
                                            label="Session Date"
                                            value={params.sessionDate}
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="From Time"
                                            value={params.fromTime}
                                            fullWidth
                                            disabled
                                        />
                                        <GlobalSelectField
                                            options={{
                                                name: "attendanceTypeId",
                                                formik,
                                                sx: {
                                                    width: "100%",
                                                },
                                                label: "Attendance Type",
                                                data: attendanceTypes,
                                                keys: {
                                                    id: "id",
                                                    value: "name",
                                                },
                                            }}
                                        />
                                    </Stack>
                                    <Stack gap={2} alignItems="center">
                                        {formik.values.topicsCovered.map(
                                            (item, i) => {
                                                return (
                                                    <Box
                                                        key={i}
                                                        sx={{
                                                            width: "100%",
                                                            backgroundColor:
                                                                "grey.200",
                                                            padding: 2,
                                                            mt: 1,
                                                            borderRadius: 2,
                                                        }}
                                                    >
                                                        <Stack
                                                            direction={
                                                                smUp
                                                                    ? "row"
                                                                    : "column"
                                                            }
                                                            gap={2}
                                                            alignItems="center"
                                                            key={i}
                                                        >
                                                            <FormControl
                                                                variant="outlined"
                                                                sx={{
                                                                    minWidth:
                                                                        smUp
                                                                            ? 160
                                                                            : "100%",
                                                                    flex: 1,
                                                                }}
                                                                error={
                                                                    formik
                                                                        .errors
                                                                        .topicsCovered &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .topicsCovered[
                                                                            i
                                                                        ]
                                                                    )
                                                                }
                                                            >
                                                                <InputLabel>
                                                                    Topics
                                                                </InputLabel>
                                                                <Select
                                                                    label="Topics"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .topicsCovered[
                                                                            i
                                                                        ]
                                                                            .moduleSessionId
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleTopicsChange(
                                                                            i,
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    {subjectTopicsPreData.map(
                                                                        (
                                                                            option,
                                                                            index
                                                                        ) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    option.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.topic
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                            {/* ---------------------------status---------------------------- */}
                                                            <FormControl
                                                                variant="outlined"
                                                                sx={{
                                                                    minWidth:
                                                                        smUp
                                                                            ? 160
                                                                            : "100%",
                                                                    flex: 1,
                                                                }}
                                                                error={
                                                                    formik
                                                                        .errors
                                                                        .topicsCovered &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .topicsCovered[
                                                                            i
                                                                        ]
                                                                    )
                                                                }
                                                            >
                                                                <InputLabel>
                                                                    Status
                                                                </InputLabel>
                                                                <Select
                                                                    label=" Status"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .topicsCovered[
                                                                            i
                                                                        ].status
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleStatusChange(
                                                                            i,
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    {Status.map(
                                                                        (
                                                                            option,
                                                                            index
                                                                        ) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    index
                                                                                }
                                                                                value={
                                                                                    option.statusId
                                                                                }
                                                                            >
                                                                                {
                                                                                    option.status
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </FormControl>

                                                            <Box>
                                                                <Typography variant="subtitle2">
                                                                    Action
                                                                </Typography>
                                                                <Stack
                                                                    direction={
                                                                        "row"
                                                                    }
                                                                    gap={2}
                                                                >
                                                                    <Button
                                                                        onClick={
                                                                            handleAdd
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            icon="material-symbols:add"
                                                                            height="28"
                                                                        />
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() =>
                                                                            handleRemove(
                                                                                i
                                                                            )
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            icon="material-symbols:remove"
                                                                            height="28"
                                                                        />
                                                                    </Button>
                                                                </Stack>
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                );
                                            }
                                        )}
                                    </Stack>
                                    <Box>
                                        <TextField
                                            sx={{ mt: 2 }}
                                            name="attendanceRemarks"
                                            label="Attendance Remarks"
                                            onChange={formik.handleChange}
                                            fullWidth
                                            multiline
                                            rows={3}
                                            value={
                                                formik.values.attendanceRemarks
                                            }
                                            error={
                                                formik.touched
                                                    .attendanceRemarks &&
                                                Boolean(
                                                    formik.errors
                                                        .attendanceRemarks
                                                )
                                            }
                                            helperText={
                                                formik.errors.attendanceRemarks
                                            }
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            mt: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
