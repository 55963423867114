import { Container } from "@mui/system";
import FullScreenDialog from "./FullScreenDialogue";
import {
    createNotification,
    clearMessage,
} from "../../../redux/features/notificationSlice";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import BasicButton from "../../../components/mui/Button";
import { Icon } from "@iconify/react";

import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Page from "../../../components/Page";
import Iconify from "../../../components/Iconify";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { useNavigate } from "react-router-dom";
import CustomSelectField from "../../../components/mui/CustomSelectField";
import BasicTextField from "../../../components/mui/TextField";
import { VALUES } from "../../../utils/constants/InputValues";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { LoadingButton } from "@mui/lab";

//...............START............//
function Notification() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const Notification = useSelector((state) => state.notification);
    const loading = useSelector((state) => state.notification.loading);
    const CreateSchema = Yup.object().shape({
        type: Yup.string()
            .required("Type Required")
            .oneOf(["BROADCAST", "CUSTOM_GROUP", "CUSTOM_SINGLE"]),
        title: Yup.string().required("Title Required"),
        content: Yup.string().required("Content Required"),
    });
    useEffect(() => {
        if (Notification.message.length) {
            enqueueSnackbar(Notification.message, { variant: "success" });
            dispatch(clearMessage());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Notification.message]);

    const formik = useFormik({
        initialValues: {
            type: "",
            title: "",
            content: "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(createNotification(values)).then((res) => {
                if (res.payload.status === "success") {
                    formik.setFieldValue("type", "");
                    navigate("/notifications/view", { replace: true });
                    return resetForm();
                }
            });
        },
    });
    const resetForm = () => {};

    return (
        <Page title="Create Notification">
            <Container component="main">
                {/*..................................HEADING.............................*/}
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Create Notification Template
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box
                    sx={{
                        paddingLeft: "70%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                ></Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",

                        mt: { md: 4, xs: 2 },
                    }}
                >
                    <Paper elevation={4} sx={{ width: "100%" }}>
                        {/* GRAY-HEADING */}
                        <Box
                            sx={{
                                height: 48,
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                            }}
                        ></Box>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box sx={{ p: { md: 8, xs: 4 } }}>
                                    <Stack direction="column">
                                        <Stack
                                            direction={{
                                                md: "row",
                                                xs: "column",
                                            }}
                                            spacing={2}
                                            sx={{
                                                display: "flex",
                                                pb: 2,
                                                alignItems: "center",
                                            }}
                                        >
                                            {/* TYPE SELECT BOX */}

                                            <GlobalSelectField
                                                options={{
                                                    formik: formik,
                                                    label: "Select Type",
                                                    name: "type",
                                                    data: VALUES,
                                                    keys: {
                                                        id: "id",
                                                        value: "value",
                                                    },
                                                    sx: {
                                                        width: "100%",
                                                    },
                                                }}
                                            />
                                            {/*........ TITLE ........*/}

                                            <BasicTextField
                                                sx={{
                                                    flex: {
                                                        md: 2,
                                                        xs: 1,
                                                        width: "100%",
                                                    },
                                                }}
                                                value={formik.values.title}
                                                name="title"
                                                label="Title"
                                                action={formik.handleChange}
                                                error={
                                                    formik.touched.title &&
                                                    Boolean(formik.errors.title)
                                                }
                                                helperText={formik.errors.title}
                                            />
                                        </Stack>

                                        <BasicTextField
                                            label="Content"
                                            name="content"
                                            id="outlined-multiline-static"
                                            rows={4}
                                            sx={{ pb: 2 }}
                                            value={formik.values.content}
                                            action={formik.handleChange}
                                            error={
                                                formik.touched.content &&
                                                Boolean(formik.errors.content)
                                            }
                                            helperText={formik.errors.content}
                                            multiline
                                        />
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        justifyContent="flex-end"
                                        spacing={1.5}
                                    >
                                        <BasicButton
                                            variant="outlined"
                                            action={resetForm}
                                            size="medium"
                                        >
                                            Reset
                                        </BasicButton>

                                        <LoadingButton
                                            variant="contained"
                                            type="submit"
                                            size="medium"
                                        >
                                            <Icon
                                                icon="eos-icons:subscriptions-created-outlined"
                                                width="20"
                                                height="20"
                                            />
                                            {loading ? "Creating..." : "Create"}
                                        </LoadingButton>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </Paper>
                </Box>
            </Container>
        </Page>
    );
}

export default Notification;
