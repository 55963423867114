import React from "react";
import GlobalDeleteDialog from "../../../mui/Dialogue/GlobalDeleteDialog";
import { deleteIncome, getIncome } from "../../../../redux/features/croSlice";
import { useSelector } from "react-redux";

export const DeleteIncome = ({ incomeId }) => {
    const { loading } = useSelector((state) => state.cro);
    return (
        <div>
            <GlobalDeleteDialog
                title="Delete Income"
                deleteNote="Are you sure ? . You want to delete this income..!"
                api={deleteIncome(incomeId)}
                upDateCall={getIncome()}
                loading={loading}
                btnName={false}
            />
        </div>
    );
};
