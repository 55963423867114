import {
    Box,
    Button,
    Container,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import Page from "../../../components/Page";
import { PREFERED_SCHEDULE_HEAD as columns } from "../../../components/datagrid/schedules/PreferedSchedulesTableHead";

import {
    getBaseCoursePreferedSchedule,
    updateSingleUser,
} from "../../../redux/features/sessionSlice";

const PrefferedSchedulesView = () => {
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const navigate = useNavigate();

    const { loading, studentPreferredSchedules } = useSelector(
        (state) => state.sessions
    );
    const { subEId } = useParams();

    const { state } = useLocation();

    useEffect(() => {
        dispatch(getBaseCoursePreferedSchedule(subEId));
    }, []);

    const handleClick = () => {
        navigate("/schedules/aditional-schedules", {
            state: {
                ...state,
                studentPreferredSchedules: studentPreferredSchedules,
            },
        });
    };

    useEffect(() => {
        if (state) {
            dispatch(updateSingleUser(state));
        }
    }, [state]);

    return (
        <Page>
            <Container>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Preferred Schedule View
                </Typography>
                <Breadcrumb sx={{ marginBottom: 1 }} />

                <Stack
                    sx={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "right",
                        marginBottom: "10px",
                    }}
                >
                    <Button
                        sx={{ width: "100px" }}
                        variant="contained"
                        onClick={handleClick}
                    >
                        Add
                    </Button>
                </Stack>

                <Paper elevation={3} sx={{ borderRadius: 1, width: "100%" }}>
                    <Box
                        sx={{
                            backgroundColor: "grey.200",
                            padding: "10px 0",
                            borderRadius: "8px 8px 0 0",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "black",
                        }}
                    >
                        <p>
                            {`Name : ${state.studentName} | AdmNo : ${state.admissionNumber} | Phone : +${state.phoneCode} ${state.phoneNum} | Class : ${state.class}`}
                        </p>

                        <p>{`${state.course}(${state.courseDetailed})${state.subject}-class ${state.class} ${state.syllabus} ${state.medium}`}</p>
                    </Box>

                    <DataGrid
                        loading={loading}
                        rows={studentPreferredSchedules}
                        columns={columns}
                        getRowId={(row) => row.id}
                        autoHeight
                        pageSize={gridSize}
                        onPageSizeChange={(newGridSize) =>
                            setGridSize(newGridSize)
                        }
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        components={{ Toolbar: CustomGridToolbar }}
                    />
                </Paper>
            </Container>
        </Page>
    );
};

export default PrefferedSchedulesView;
