export const CRO_TERM_FEE_COLLECTION_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "admnNo",
        headerName: "Admission Number",
        width: 150,
    },
    {
        field: "studentName",
        headerName: "Name",
        width: 150,
    },
    {
        field: "studentMobNo",
        headerName: "Mobile No",
        width: 150,
    },
    {
        field: "className",
        headerName: "Class",
        width: 150,
    },

    {
        field: "courseName",
        headerName: "Course",
        width: 150,
    },

    {
        field: "feeStructure",
        headerName: "Fee Structure",
        width: 150,
    },
    {
        field: "term",
        headerName: "Term",
        width: 150,
    },
    {
        field: "amount",
        headerName: "Amount",
        width: 100,
    },
    {
        field: "action",
        headerName: "Action",
        width: 150,
    },
];
