import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
    CancelButton,
    SaveButton,
    UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { addDemoSessionDate } from "../../../../../redux/features/croSlice";
import { useSnackbar } from "notistack";

const DemoSessionDate = ({ Remark, Status, id, openStatus }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { loading } = useSelector((state) => state.cro);
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(dayjs());
    const dispatch = useDispatch();
    const profile = JSON.parse(localStorage.getItem("cred")).profile;
    const datas = {
        demo_session_date: dayjs(date).format("YYYY-MM-DD HH:mm:ss"),
        demo_session_scheduled_by: profile.userId,
        cro_remarks: Remark,
        cro_lead_status: Status,
        id: id,
    };
    const handleSave = () => {
        dispatch(addDemoSessionDate(datas)).then((res) => {
            if (
                res.payload.status === "failed" ||
                res.payload.status === "error"
            ) {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                setOpen(false);
                openStatus(false);
                enqueueSnackbar(res.payload.status, {
                    variant: "success",
                });
            }
        });
    };
    return (
        <Box>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <UpdateButton action={() => setOpen(true)}>
                        Update
                    </UpdateButton>
                }
                closeButton={true}
                content={
                    <>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        fullWidth
                                        sx={{ width: "100%" }}
                                        values={date}
                                        label="Add Demo Session Date"
                                        onChange={(newValue) =>
                                            setDate(newValue)
                                        }
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    padding: 3,
                                }}
                            >
                                <Stack direction={"row"} spacing={2}>
                                    <CancelButton>Cancel</CancelButton>
                                    <SaveButton action={handleSave}>
                                        {loading ? "Saving.." : "Save"}
                                    </SaveButton>
                                </Stack>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Box>
    );
};

export default DemoSessionDate;
