import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Box, Button, Stack, Typography } from "@mui/material";
import { CancelButton, UpdateButton } from "../../../../mui/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getTasks, markTask } from "../../../../../redux/features/croSlice";
export const BeforeDemoCheckListUndo = ({ taskName, croAssId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.cro);
    const [open, setOpen] = useState(false);
    const handleSubmit = () => {
        const data = {
            croAssignmentId: croAssId,
            taskName: taskName,
            completed: false,
        };
        dispatch(markTask(data)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (
                res.payload.message ===
                "Task completion status updated successfully"
            ) {
                dispatch(getTasks(croAssId));
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
            }
        });
    };
    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                title="Undo"
                closeButton={true}
                popupButton={
                    <>
                        <Button size="small" onClick={() => setOpen(true)}>
                            Undo
                        </Button>
                    </>
                }
                content={
                    <>
                        <Box>
                            <Typography>
                                Are you sure?. you want to Undo
                            </Typography>
                        </Box>
                    </>
                }
                actionButton={
                    <>
                        <CancelButton
                            size="small"
                            action={() => setOpen(false)}
                        />
                        <UpdateButton size="small" action={handleSubmit}>
                            {loading ? "Updating..." : "Update"}
                        </UpdateButton>
                    </>
                }
            />
        </div>
    );
};
