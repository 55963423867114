import React from 'react'
import { Avatar} from "@mui/material";

export default function (params) {
  return (
    <div sx={{height:100,width:100}}>
             <Avatar
               src={`https://dev.teaminterval.net/assets/employee/photo/passport_size/${params.value}.jpg`}
                 sx={{
                    width: 60,
                    height:60,
                    border: 1,
                    borderColor: "white",
                    }}
             />
    </div>
  )
}
