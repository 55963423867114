import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import {
    CancelButton,
    SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";

export const Form = ({ setOpen }) => {
    return (
        <div>
            <Box padding={2}>
                <Stack direction={"column"} spacing={2}>
                    <TextField label="Enquiry Name" />
                    <TextField
                        label="Enquiry Remarks "
                        multiline
                        rows={4}
                        variant="filled"
                    />
                </Stack>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        margin: 2,
                    }}
                >
                    <Stack direction={"row"} spacing={2}>
                        <CancelButton
                            action={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </CancelButton>
                        <SubmitButton>Submit</SubmitButton>
                    </Stack>
                </Box>
            </Box>
        </div>
    );
};
