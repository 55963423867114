import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Paper } from "@mui/material";

const data = [
    {
        slno: 1,
        trainerId: "INT2010TR00002",
        firstName: "athul",
        lastName: "p",
        contactNo: "808867677",
        dateOfBirth: "17/09/1999",
        nameOfFather: "teatttt",
        pan: "FZLPPP576RD",
        view: "",
    },
];

const columns = [
    {
        field: "slno",
        headerName: "slno",
    },
    {
        field: "trainerId",
        headerName: "trainerId",
        width: 200,
    },
    {
        field: "firstName",
        headerName: "firstName",
        width: 250,
    },
    {
        field: "lastName",
        headerName: "lastName",
        width: 200,
    },
    {
        field: "contactNo",
        headerName: "contactNo",
        width: 200,
    },
    {
        field: "dateOfBirth",
        headerName: "dateOfBirth",
        width: 200,
    },
    {
        field: "nameOfFather",
        headerName: "nameOfFather",
        width: 200,
    },
    {
        field: "pan",
        headerName: "pan",
        width: 200,
    },
    {
        field: "view",
        headerName: "view",
        width: 200,
    },
];

export default function TrainerPan() {
    const [gridSize, setGridSize] = React.useState(5);
    return (
        <div>
            <Wrapper
                title="Trainer PAN"
                Content={
                    <>
                        <Paper elevation={3}>
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <Box height={400}>
                                <DataGrid
                                    // loading={loading}
                                    rows={data}
                                    columns={columns}
                                    getRowId={(row) => row.slno}
                                    pageSize={gridSize}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    components={{ Toolbar: CustomGridToolbar }}
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
