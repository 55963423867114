import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const CheckComponensatoryLeave = () => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const cred = JSON.parse(localStorage.getItem('cred'))
  const pendingCounts = cred?.pendingCounts || { comp_count: 0, leave_count: 0 }
  const approvalRequestsSeen = cred.approvalRequestsSeen || false

  useEffect(() => {
    if ((pendingCounts.comp_count || pendingCounts.leave_count) && !approvalRequestsSeen) {
      setOpen(true)
    }
  }, [approvalRequestsSeen])

  const handleClose = () => {
    setOpen(false)
    const cred = JSON.parse(localStorage.getItem('cred'))
    const credUpdate = {
      ...cred,
      approvalRequestsSeen: true,
    }
    localStorage.setItem('cred', JSON.stringify(credUpdate))
  }

  return (
    <>
      <Outlet />
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography component={'div'} variant="h6" align="center" gutterBottom>
            Pending Approvals
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Stack gap={1}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="body1" color="textSecondary">
                Compensatory Work Requests : {pendingCounts?.comp_count}
              </Typography>
              <Button
                onClick={() => {
                  navigate('/my-workspace/reporting-head-comp-work-req')
                  handleClose()
                }}
                variant="outlined"
                color="primary"
                size="small"
                sx={{ width: '150px', borderRadius: '8px', marginLeft: '20px' }}>
                Navigate
              </Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="body1" color="textSecondary">
                Leave Requests : {pendingCounts?.leave_count}
              </Typography>
              <Button
                onClick={() => {
                  navigate('/my-workspace/reporting_head_or_hod/hod-leave-applications')
                  handleClose()
                }}
                variant="outlined"
                color="primary"
                size="small"
                sx={{ width: '150px', borderRadius: '8px', marginLeft: '20px' }}>
                Navigate
              </Button>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>{/* Optional buttons here */}</DialogActions>
      </Dialog>
    </>
  )
}

export default CheckComponensatoryLeave
