import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
    CancelButton,
    EditButton,
    SaveButton,
} from "../../../../mui/Buttons/Buttons";
import { useState } from "react";
import { Box, Stack, TextField } from "@mui/material";
import { useFormik, FormikProvider, Form } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
    getStudentAcademicSessions,
    updateStudentAcademicSchedules,
} from "../../../../../redux/features/researchSlice";
import { useSelector } from "react-redux";

export const EditAcademicSessions = ({ data }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const { loading, subjectEnrollmentDetailedId } = useSelector(
        (state) => state.research
    );

    const Schema = Yup.object().shape({
        sessionModuleContent: Yup.string().required(
            "sessionModuleContent is required"
        ),
        sessionContent: Yup.string().required("sessionContent is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentAcademicSchedulesId: data.studentAcademicSchedulesId,
            sessionModuleContent: data.sessionModuleContent || "",
            sessionContent: data.sessionContent || "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            dispatch(updateStudentAcademicSchedules(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    dispatch(
                        getStudentAcademicSessions(subjectEnrollmentDetailedId)
                    );
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                title="Edit Academic Session"
                closeButton={true}
                popupButton={
                    <EditButton size="small" action={() => setOpen(true)}>
                        Edit
                    </EditButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <TextField
                                            name="sessionModuleContent"
                                            label="Module Content"
                                            value={
                                                formik.values
                                                    .sessionModuleContent
                                            }
                                            onChange={formik.handleChange}
                                            multiline
                                            rows={2}
                                            error={
                                                formik.touched
                                                    .sessionModuleContent &&
                                                Boolean(
                                                    formik.errors
                                                        .sessionModuleContent
                                                )
                                            }
                                            helperText={
                                                formik.errors
                                                    .sessionModuleContent
                                            }
                                        />
                                        <TextField
                                            name="sessionContent"
                                            label="Session Content"
                                            value={formik.values.sessionContent}
                                            onChange={formik.handleChange}
                                            multiline
                                            rows={2}
                                            error={
                                                formik.touched.sessionContent &&
                                                Boolean(
                                                    formik.errors.sessionContent
                                                )
                                            }
                                            helperText={
                                                formik.errors.sessionContent
                                            }
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            pt: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
