import CreateRemaining from "./CreateRemaining";

export const LEAVE_ANALYTICS_TABLE_HEAD = [
    {
        field: "empName",
        flex: 1,
        minWidth: 200,
        headerName: "Employee Name",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "deptName",
        flex: 1,
        minWidth: 200,
        headerName: "Department",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "userType",
        flex: 1,
        minWidth: 200,
        headerName: "Designation",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "probationCompletedOn",
        flex: 1,
        minWidth: 200,
        headerName: "Probation Compleated On",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "totalLeaves",
        flex: 1,
        minWidth: 150,
        headerName: "Total Leaves",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "CL",
        flex: 1,
        minWidth: 150,
        headerName: "Casual Leave(Remaining)",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => CreateRemaining(params),
    },
    {
        field: "SL",
        flex: 1,
        minWidth: 150,
        headerName: "Sick leave (Remaining)",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => CreateRemaining(params),
    },
    {
        field: "PL",
        flex: 1,
        minWidth: 150,
        headerName: "Paid Leave",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "CO",
        flex: 1,
        minWidth: 150,
        headerName: "Compensatory Off",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "ML",
        flex: 1,
        minWidth: 150,
        headerName: "Meternity Leave",
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "LWP",
        flex: 1,
        minWidth: 150,
        headerName: "Leave Without Pay",
        headerAlign: "left",
        align: "left",
    },
];
