import React, { useState } from "react";
import PopUps from "./PopUps";
import OgaRetainForm from "./OgaRetainForm";
import { Button } from "@mui/material";

const OgaRetainCallPopUp = ({ data }) => {
    const [open, setOpen] = useState(false);

    const componetProps = {
        setOpen: setOpen,
        data: data,
    };

    return (
        <div>
            {data?.ogaRetainCallStatus === "Not Assigned" ? (
                <Button onClick={() => setOpen(true)} variant="contained">
                    Schedule
                </Button>
            ) : (
                <Button variant="contained" color="warning">
                    {data?.ogaRetainCallStatus}
                </Button>
            )}
            <PopUps
                open={open}
                setOpen={setOpen}
                component={OgaRetainForm}
                componentProps={componetProps}
            />
        </div>
    );
};

export default OgaRetainCallPopUp;
