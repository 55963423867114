import React from "react";
import GlobalDeleteDialog from "../../../../mui/Dialogue/GlobalDeleteDialog";
import {
    deleteObjective,
    getAllObjectives,
} from "../../../../../redux/features/assessmentToolSlice";
import { useSelector } from "react-redux";

const DeleteObjective = ({ id, childFlag }) => {
    const { loading } = useSelector((state) => state.assessmentTool);
    return (
        <GlobalDeleteDialog
            disabled={childFlag === 1 ? true : false}
            title={"Delete Objective"}
            deleteNote={"Are you sure? you wnat to delete this objective..?"}
            api={deleteObjective(id)}
            upDateCall={getAllObjectives()}
            loading={loading}
        />
    );
};

export default DeleteObjective;
