import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { FormikProvider, Form, useFormik } from "formik";
import { Divider, Paper } from "@mui/material";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { timeLine } from "../../../../redux/features/jobApplicationSlice";
import useResponsive from "../../../../Hooks/useResponsive";
import {
  getSingleJobApplication,
  updateJoining,
} from "../../../../redux/features/jobApplicationSlice";
import {
  CancelButton,
  SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JoiningForm = ({ setJoiningForm, joiningForm, params, singleData }) => {
  const preData = useSelector((state) => state.jobApplication);
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const handleClose = () => {
    setJoiningForm(false);
  };

  const CreateSchema = Yup.object().shape({
    status: Yup.string().required("status Required"),
  });

  const formik = useFormik({
    initialValues: {
      status: singleData.finalStatusId ? singleData.finalStatusId || "" : "",
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const data = {
        status: values.status,
        id: params.id,
      };
      dispatch(updateJoining(data)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });
          dispatch(timeLine(params.id));
          dispatch(getSingleJobApplication(params.id));
          setJoiningForm(false);
        }
      });
    },
  });

  return (
    <div>
      <Dialog
        open={joiningForm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Paper sx={{ backgroundColor: "grey.200", display: "flex" }}>
          <DialogTitle>{"Update Joining Status.."}</DialogTitle>
        </Paper>
        <Divider />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box sx={{ width: !smUp ? 250 : 400, padding: 1 }}>
                <Box margin={1}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="outlined"
                      required
                      label="Status"
                      name="status"
                      value={formik.values.status}
                      onChange={(e) => {
                        formik.setFieldValue("status", e.target.value);
                      }}
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                    >
                      {preData.preData.data?.map((item) => {
                        return (
                          <MenuItem value={item.id} key={item.id}>
                            {item.status}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{formik.errors.status}</FormHelperText>
                  </FormControl>
                </Box>
                <Divider sx={{ borderBottomWidth: 3 }} />
              </Box>
            </DialogContent>

            <DialogActions>
              <CancelButton action={handleClose}>Cancel</CancelButton>
              <SubmitButton type="submit">
                {preData.loading ? "Updating..." : "Update"}
              </SubmitButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
};

export { JoiningForm };
