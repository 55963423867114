import React, { useEffect, useState } from "react";
import { Button, Paper, Stack, TextField, Box } from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Wrapper from "../../../components/Wrapper/Wrapper";
import dayjs from "dayjs";
import { getCurrentLedger, addIncome } from "../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const AddIncome = ({ data, setOpen, open, params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { currentLedger, studentsforAddIncome } = useSelector(
        (state) => state.cro
    );
    const [formatedDate, setFormatedDAte] = useState("");

    useEffect(() => {
        if (params) {
            function formatDate(inputDateStr) {
                const date = dayjs(inputDateStr, "DD-MM-YYYY");

                const formattedDate = date.format("MM-DD-YYYY");

                return formattedDate;
            }

            const formattedDate = formatDate(params.transactionDate);
            setFormatedDAte(formattedDate);
        }
    }, [params]);

    const [gcc, setGcc] = useState(false);

    const Schema = Yup.object().shape({
        feeType: Yup.string().required("feeType is required"),
        year: Yup.string().required("year is required"),
        month: Yup.string().required("month is required"),
        paymentAccountId: Yup.string().required("paymentAccountId is required"),
        amount: Yup.string().required("amount is required"),
        paymentMode: Yup.string().required("paymentMode is required"),
        incomeScreenshot: Yup.string().required("incomeScreenshot is required"),
        incomeRemarks: Yup.string().required("incomeRemarks is required"),
    });

    const formik = useFormik({
        initialValues: {
            feeType: params ? params.feeType : "",
            courseEnrollmentId: params ? params.course_enrollment_id : "",
            year: "",
            month: "",
            transactionDate: params
                ? params.transactionDate
                : dayjs().format("YYYY-MM-DD"),
            paymentAccountId: params
                ? params.creditedAccount === "Indian A/c"
                    ? 1
                    : 2
                : "",
            amount: params ? params.incomeAmount : "",
            amountInAED: "",
            paymentMode: params ? params.paymentMode : "",
            incomeScreenshot: "",
            incomeRemarks: params ? params.incomeRemarks : "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            console.log("submitted", values);
            dispatch(addIncome(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    useEffect(() => {
        if (formik.values.courseEnrollmentId) {
            dispatch(getCurrentLedger(formik.values.courseEnrollmentId));
        }
    }, [formik.values.courseEnrollmentId]);

    useEffect(() => {
        if (currentLedger) {
            formik.setFieldValue("year", currentLedger.year);
            formik.setFieldValue("month", currentLedger.month);
        }
    }, [currentLedger, formik.values.courseEnrollmentId]);

    useEffect(() => {
        if (formik.values.paymentAccountId === 2) {
            setGcc(true);
        } else {
            setGcc(false);
        }
    }, [formik.values.paymentAccountId]);

    const handleFileUpload = (e) => {
        console.log(e.target.files[0]);
        
        formik.setFieldValue("incomeScreenshot", e.target.files[0]);
    };

    //<------------ trial datas for the form -------->

    const feeType = [
        { id: "Admission Fee", feeType: "Admission Fee" },
        { id: "Tuition Fee", feeType: "Tuition Fee" },
    ];

    const accountType = [
        { id: 2, type: "GCC Bank A/C" },
        { id: 1, type: "Indian Bank A/C" },
    ];

    const paymentMode = [
        { mode: "Bank Transfer" },
        { mode: "UPI" },
        { mode: "Cash" },
    ];

    return (
        <div>
            <Wrapper
            
                Content={
                    <>
                        <Paper elevation={4} sx={{ width: "100%", padding: 3 }}>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Stack gap={2}>
                                        <GlobalSelectField
                                            options={{
                                                name: "feeType",
                                                formik,
                                                label: "Fee type",
                                                data: feeType,
                                                keys: {
                                                    id: "id",
                                                    value: "feeType",
                                                },
                                            }}
                                        />
                                        <GlobalSelectField
                                            options={{
                                                name: "courseEnrollmentId",
                                                formik,
                                                label: "Student Name",
                                                data: params
                                                    ? studentsforAddIncome
                                                    : data,
                                                keys: {
                                                    id: "id",
                                                    value: "name",
                                                },
                                            }}
                                        />
                                        <Box>
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <TextField
                                                    name="year"
                                                    fullWidth
                                                    label="Year"
                                                    value={
                                                        formik.values.year || ""
                                                    }
                                                    error={
                                                        formik.touched.year &&
                                                        Boolean(
                                                            formik.errors.year
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.year
                                                    }
                                                    disabled
                                                />
                                                <TextField
                                                    name="month"
                                                    fullWidth
                                                    label="Month"
                                                    value={
                                                        formik.values.month ||
                                                        ""
                                                    }
                                                    error={
                                                        formik.touched.month &&
                                                        Boolean(
                                                            formik.errors.month
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.month
                                                    }
                                                    disabled
                                                />
                                            </Stack>
                                        </Box>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DatePicker
                                                name="transactionDate"
                                                label="Transaction Date"
                                                value={
                                                    params
                                                        ? dayjs(formatedDate)
                                                        : dayjs(
                                                              formik.values
                                                                  .transactionDate
                                                          )
                                                }
                                                onChange={(newValue) => {
                                                    formik.setFieldValue(
                                                        "transactionDate",
                                                        dayjs(newValue).format(
                                                            "YYYY-MM-DD"
                                                        )
                                                    );
                                                }}
                                            />
                                        </LocalizationProvider>

                                        <GlobalSelectField
                                            options={{
                                                name: "paymentAccountId",
                                                formik,
                                                label: "Amount Credited to",
                                                data: accountType,
                                                keys: {
                                                    id: "id",
                                                    value: "type",
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Amount"
                                            name="amount"
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.amount &&
                                                Boolean(formik.errors.amount)
                                            }
                                            helperText={formik.errors.amount}
                                        />
                                        {gcc ? (
                                            <TextField
                                                label="Amount (AED)"
                                                name="amountInAED"
                                                value={
                                                    formik.values.amountInAED
                                                }
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched
                                                        .amountInAED &&
                                                    Boolean(
                                                        formik.errors
                                                            .amountInAED
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.amountInAED
                                                }
                                            />
                                        ) : null}

                                        <GlobalSelectField
                                            options={{
                                                name: "paymentMode",
                                                formik,
                                                label: "Payment Mode",
                                                data: paymentMode,
                                                keys: {
                                                    id: "mode",
                                                    value: "mode",
                                                },
                                            }}
                                        />

                                        <TextField
                                            name="incomeScreenshot"
                                            type="file"
                                            onChange={handleFileUpload}
                                            error={
                                                formik.touched
                                                    .incomeScreenshot &&
                                                Boolean(
                                                    formik.errors
                                                        .incomeScreenshot
                                                )
                                            }
                                            helperText={
                                                formik.errors.incomeScreenshot
                                            }
                                        />

                                        <TextField
                                            label="Remarks"
                                            name="incomeRemarks"
                                            placeholder="Type your Remarks here"
                                            value={formik.values.incomeRemarks}
                                            multiline
                                            rows={3}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.incomeRemarks &&
                                                Boolean(
                                                    formik.errors.incomeRemarks
                                                )
                                            }
                                            helperText={
                                                formik.errors.incomeRemarks
                                            }
                                        />

                                        <Button
                                            variant="contained"
                                            type="submit"
                                            sx={{
                                                width: "300px",
                                                margin: "0 auto",
                                            }}
                                        >
                                            Add Income
                                        </Button>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    </>
                }
            />
        </div>
    );
};

export default AddIncome;
