import { Box, Container, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../../../components/Page";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveAplictions } from "../../../../redux/features/leaveSlice";
import { DataGrid } from "@mui/x-data-grid";
import { LEAVE_APPLICATION_TABLE_HEAD } from "../../../../components/datagrid/leave/LeaveApplicationsTableHead";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useResponsive from "../../../../Hooks/useResponsive";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";

export default function ViewApplications() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const [gridSize, setGridSize] = useState(5);
  const [value, setValue] = useState(new Date());

  const dispatch = useDispatch();
  const { leave } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getLeaveAplictions(new Date().getFullYear()));
  }, []);

  const handleDate = (e) => {
    setValue(e);
    const year = new Date(e).getFullYear();

    dispatch(getLeaveAplictions(year));
  };

  useEffect(() => {}, [leave.applications]);

  return (
    <div>
      <GlobalWrapper title="Leave Applications">
        <Box
          sx={{
            padding: 2,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {smUp && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year"]}
                label="Year and Month"
                inputFormat="YYYY"
                value={value}
                onChange={handleDate}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} variant="filled" />
                )}
              />
            </LocalizationProvider>
          )}
          {!smUp && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year"]}
                label="Year and Month"
                inputFormat="YYYY"
                value={value}
                onChange={handleDate}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    helperText={null}
                    variant="filled"
                  />
                )}
              />
            </LocalizationProvider>
          )}
        </Box>
        <Paper elevation={3} sx={{ width: "100%", mt: 3 }}>
          <DataGrid
            loading={leave.loading}
            rows={leave.applications}
            columns={LEAVE_APPLICATION_TABLE_HEAD}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            pageSize={gridSize}
            onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
            autoHeight
            getRowHeight={() => "auto"}
            rowsPerPageOptions={[5, 10, 25, 50]}
            getRowId={(row) => row.leaveId}
            components={{ Toolbar: CustomGridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Paper>
      </GlobalWrapper>
    </div>
  );
}
