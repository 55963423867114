import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import styled from "styled-components";
import "../../../components/css/calender.css";
import { Box, Paper } from "@mui/material";
import {
    clearStudentAttendance,
    getAllAttendensPerStudent,
    getCroStudents,
    navigatedDate,
} from "../../../redux/features/croSlice";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { BarChart } from "../../../components/DashBoardCharts/BarChart";
import { DashBoard } from "../../user/Dashboard/DashboadIndex/DashBoard";

export const AttendanceView = () => {
    const dispatch = useDispatch();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedAdmissionNumber, setSelectedAdmissionNumber] =
        useState(null);
    const [attendanceDate, setAttendanceDate] = useState({});
    const { croStudents, allAttendensPerStudent } = useSelector(
        (state) => state.cro
    );
    useEffect(() => {
        if (allAttendensPerStudent) {
            const studentDatas = allAttendensPerStudent;
            const { student_name, ...others } = studentDatas;
            setAttendanceDate(others);
        }
    }, [allAttendensPerStudent, currentDate]);

    console.log("allAttendensPerStudentkjgyug0-0--0-0", allAttendensPerStudent);
    useEffect(() => {
        dispatch(getCroStudents());
    }, []);
    // --------------------------------------------------------------------------------

    const resultArray = Object.entries(attendanceDate).map(
        ([dayKey, value], index) => ({
            id: index + 1,
            dayKey,
            value,
        })
    );
    const handleDayButtonClick = (date) => {
        alert(
            `Hello! You clicked the button for ${moment(date).format(
                "MMMM D, YYYY"
            )}`
        );
    };
    const resultVariable = resultArray;
    const dateString = dayjs(currentDate).format("MM/DD/YYYY");
    const parts = dateString.split("/");
    const month = parseInt(parts[0], 10);
    const year = parseInt(parts[2], 10);
    const transformedData = resultVariable.map((item) => ({
        title: item.value,
        start: new Date(`${month}/${item.id}/${year}`),
        end: new Date(`${month}/${item.id}/${year}`),
    }));
    console.log("transformedData", transformedData);

    const localizer = momentLocalizer(moment);
    const StyledCalendar = styled.div`
        .rbc-header {
            background-color: #3498db;
            color: #fff;
        }

        .rbc-header > a {
            color: #fff;
        }
    `;

    const handleNavigate = (newDate, view, action) => {
        setCurrentDate(newDate);
    };

    const handleOnChange = (event, value) => {
        // Update the local state with the selected admission number
        if (value) {
            setSelectedAdmissionNumber(value.admissionNumber);
        } else {
            setSelectedAdmissionNumber(null);
        }
    };

    useEffect(() => {
        const dateString = dayjs(currentDate).format("MM/DD/YYYY");
        const parts = dateString.split("/");
        const month = parseInt(parts[0], 10);
        const year = parseInt(parts[2], 10);
        const data = {
            selectedAdmissionNumber: selectedAdmissionNumber,
            year: year,
            month: month,
        };
        if (data.selectedAdmissionNumber && data.year && data.month) {
            dispatch(clearStudentAttendance());
            dispatch(getAllAttendensPerStudent(data));
        }
    }, [currentDate, selectedAdmissionNumber]);
    return (
        <div>
            <Wrapper
                title=" AttendanceView"
                Content={
                    <>
                        <div>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    paddingBottom: 3,
                                }}
                            >
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={croStudents}
                                    getOptionLabel={(option) => option.student}
                                    onChange={handleOnChange}
                                    sx={{ width: 250 }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Students"
                                        />
                                    )}
                                />
                            </Box>
                            <Paper elevation={3} sx={{ padding: 2 }}>
                                <StyledCalendar>
                                    <Calendar
                                        localizer={localizer}
                                        startAccessor="start"
                                        endAccessor="end"
                                        events={transformedData}
                                        style={{ height: 500 }}
                                        eventPropGetter={(event) => ({
                                            className:
                                                event.title === 1
                                                    ? "parent-container-event-123"
                                                    : event.title === 0
                                                    ? "parent-container-event-12"
                                                    : event.title ===
                                                      "Not Marked"
                                                    ? "not-marked"
                                                    : "",
                                        })}
                                        components={{
                                            event: ({ date, event }) => (
                                                <Paper
                                                    sx={{
                                                        height: 20,
                                                        width: 20,
                                                        fontSize: 15,
                                                    }}
                                                    onClick={(date) =>
                                                        handleDayButtonClick(
                                                            event.start
                                                        )
                                                    }
                                                >
                                                    {event.title}
                                                </Paper>
                                            ),
                                        }}
                                        defaultDate={currentDate}
                                        views={["month", "week", "day"]}
                                        onNavigate={handleNavigate}
                                    />
                                </StyledCalendar>
                            </Paper>
                        </div>
                    </>
                }
            />

            {/* <BarChart /> */}
        </div>
    );
};
