import React, { useEffect } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Box, Chip, Paper, Stack } from "@mui/material";
import {
    AddButton,
    CancelButton,
    DeleteButton,
    SaveButton,
} from "../../../mui/Buttons/Buttons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddModuleForm } from "./AddModuleForm";
import { useSelector, useDispatch } from "react-redux";
import NoDataFound from "../../../NodataIndication/NoDataFound";
import {
    addModulesToBaseCourses,
    clearModulesData,
    modulesData,
    getModulesForBaseCourses,
    deleteModulesForBaseCourses,
    editmodulestoBaseCourses,
} from "../../../../redux/features/researchSlice";
import { useSnackbar } from "notistack";
import GlobalDeleteDialog from "../../../mui/Dialogue/GlobalDeleteDialog";
import { EditModulePopup } from "./EditModulePopup";
import SimpleLoader from "../../../Loader/SimpleLoader";

export const AddModulePopup = ({ params }) => {
    const dispatch = useDispatch();
    const { modulesData, baseCourseId, loading, modulesForBaseCourses } =
        useSelector((state) => state.research);

    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState();
    const { enqueueSnackbar } = useSnackbar();

    const newData = [...modulesForBaseCourses];

    const myNewData = [];

    for (const item of newData) {
        myNewData.push({
            moduleSrlNo: item.moduleSrlNo,
            moduleContent: item.moduleContent,
            courseMasterModulesId: item.courseMasterModulesId,
        });
    }

    //   ----------------------------------------------------------------------------------------------
    const itemData = [...myNewData];

    const [items, setItems] = React.useState(itemData);

    useEffect(() => {
        setItems(itemData);
    }, [modulesForBaseCourses]);

    const onDragEnd = (result) => {
        if (!result.destination) return; // Item was dropped outside of the list

        const srcI = result.source.index + 1;
        const desI = result.destination.index + 1;
        let sourceIndx, destIndx;
        items.map((item, index) => {
            if (item.moduleSrlNo == srcI) sourceIndx = index;
            if (item.moduleSrlNo == desI) destIndx = index;
        });
        const temp = items[sourceIndx].moduleContent;
        items[sourceIndx].moduleContent = items[destIndx].moduleContent;
        items[destIndx].moduleContent = temp;
        const tempcourseMasterModulesId =
            items[sourceIndx].courseMasterModulesId;
        items[sourceIndx].courseMasterModulesId =
            items[destIndx].courseMasterModulesId;
        items[destIndx].courseMasterModulesId = tempcourseMasterModulesId;

        const updateLists = [...items];
        setItems(updateLists);
    };
    const modulesList = {
        baseCourseId: params.baseCourseId,
        modules: items,
    };

    const handleClick = () => {
        dispatch(editmodulestoBaseCourses(modulesList)).then((res) => {
            if (
                res.payload.status === "failed" ||
                res.payload.status === "error"
            ) {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                setOpen(false);
                dispatch(clearModulesData());
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
            }
        });
    };
    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                title="Module"
                maxWidth={"md"}
                closeButton={true}
                popupButton={
                    <Box padding={1}>
                        <AddButton
                            size="small"
                            action={() => {
                                dispatch(
                                    getModulesForBaseCourses(
                                        params.baseCourseId
                                    )
                                );
                                setOpen(true);
                            }}
                        >
                            Module
                        </AddButton>
                    </Box>
                }
                content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end   ",
                                marginBottom: 2,
                            }}
                        >
                            <AddModuleForm
                                coursesBaseId={params.baseCourseId}
                            />
                        </Box>
                        {modulesForBaseCourses[0] ? (
                            <>
                                {loading ? (
                                    <SimpleLoader />
                                ) : (
                                    <>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                backgroundColor: "#4a84bd54",
                                                borderRadius: 1,
                                                padding: 1,
                                            }}
                                        >
                                            <Stack
                                                direction={"column"}
                                                spacing={2}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <Chip
                                                        label={
                                                            <h2>Module List</h2>
                                                        }
                                                        variant="outlined"
                                                        color="primary"
                                                        size="large"
                                                    />
                                                </Box>

                                                <DragDropContext
                                                    onDragEnd={onDragEnd}
                                                >
                                                    <Droppable droppableId="droppable">
                                                        {(provided) => (
                                                            <Box
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.droppableProps}
                                                            >
                                                                {items.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <>
                                                                                <Stack
                                                                                    direction={
                                                                                        "row"
                                                                                    }
                                                                                    spacing={
                                                                                        2
                                                                                    }
                                                                                    padding={
                                                                                        1
                                                                                    }
                                                                                >
                                                                                    <Box>
                                                                                        <Paper
                                                                                            elevation={
                                                                                                3
                                                                                            }
                                                                                            sx={{
                                                                                                height: 50,
                                                                                                width: 70,
                                                                                                display:
                                                                                                    "flex",
                                                                                                justifyContent:
                                                                                                    "center",
                                                                                                alignItems:
                                                                                                    "center",
                                                                                                backgroundColor:
                                                                                                    "#4a84bd54",
                                                                                            }}
                                                                                            key={
                                                                                                item.moduleSrlNo
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                item.moduleSrlNo
                                                                                            }
                                                                                        </Paper>
                                                                                    </Box>
                                                                                    <Box
                                                                                        sx={{
                                                                                            minWidth: 500,
                                                                                        }}
                                                                                    >
                                                                                        <Draggable
                                                                                            key={
                                                                                                item.moduleSrlNo
                                                                                            }
                                                                                            draggableId={
                                                                                                "draggable-" +
                                                                                                item.moduleSrlNo
                                                                                            }
                                                                                            index={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {(
                                                                                                provided,
                                                                                                snapshot
                                                                                            ) => (
                                                                                                <Box
                                                                                                    ref={
                                                                                                        provided.innerRef
                                                                                                    }
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    style={{
                                                                                                        ...provided
                                                                                                            .draggableProps
                                                                                                            .style,
                                                                                                        boxShadow:
                                                                                                            snapshot.isDragging
                                                                                                                ? "0 0 .4rem #666"
                                                                                                                : "none",
                                                                                                    }}
                                                                                                >
                                                                                                    <Paper
                                                                                                        elevation={
                                                                                                            3
                                                                                                        }
                                                                                                        sx={{
                                                                                                            height: 50,
                                                                                                            minWidth: 500,
                                                                                                            display:
                                                                                                                "flex",
                                                                                                            justifyContent:
                                                                                                                "center",
                                                                                                            alignItems:
                                                                                                                "center",
                                                                                                        }}
                                                                                                    >
                                                                                                        {`${item.moduleContent}(${item.courseMasterModulesId})`}
                                                                                                    </Paper>
                                                                                                </Box>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    </Box>
                                                                                    <Box
                                                                                        pt={
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        <GlobalDeleteDialog
                                                                                            title="Delete Module"
                                                                                            loading={
                                                                                                loading
                                                                                            }
                                                                                            deleteNote="Are you sure you want delete this module..?"
                                                                                            api={deleteModulesForBaseCourses(
                                                                                                {
                                                                                                    baseCourseId:
                                                                                                        params.baseCourseId,
                                                                                                    moduleSrlNos:
                                                                                                        [
                                                                                                            item.moduleSrlNo,
                                                                                                        ],
                                                                                                }
                                                                                            )}
                                                                                            upDateCall={getModulesForBaseCourses(
                                                                                                params.baseCourseId
                                                                                            )}
                                                                                        />
                                                                                    </Box>
                                                                                    <Box
                                                                                        pt={
                                                                                            1
                                                                                        }
                                                                                    >
                                                                                        <EditModulePopup
                                                                                            baseCourseId={
                                                                                                params.baseCourseId
                                                                                            }
                                                                                            data={
                                                                                                item
                                                                                            }
                                                                                        />
                                                                                    </Box>
                                                                                </Stack>
                                                                            </>
                                                                        );
                                                                    }
                                                                )}
                                                            </Box>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </Stack>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                marginTop: 2,
                                            }}
                                        >
                                            <Stack
                                                direction={"row"}
                                                spacing={1}
                                            >
                                                <CancelButton
                                                    action={() => {
                                                        setOpen(false);
                                                    }}
                                                >
                                                    Cancel
                                                </CancelButton>
                                                <SaveButton
                                                    action={handleClick}
                                                >
                                                    {loading
                                                        ? "Saving..."
                                                        : "Save"}
                                                </SaveButton>
                                            </Stack>
                                        </Box>
                                    </>
                                )}
                            </>
                        ) : (
                            <NoDataFound />
                        )}
                    </>
                }
            />
        </div>
    );
};
