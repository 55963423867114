import { Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import Page from "../../../components/Page";
import {
    getStudentBaseCourses,
    updateSingleUser,
} from "../../../redux/features/sessionSlice";
import { COURSE_TABLE_HEAD as columns } from "../../../components/datagrid/schedules/CourseTableHead";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";

const CoursesView = () => {
    const dispatch = useDispatch();
    const { studentBaseCourses, loading } = useSelector(
        (state) => state.sessions
    );
    const [gridSize, setGridSize] = useState(5);

    const { adNo } = useParams();
    const {
        state: { name, admissionNumber, phoneNum, phoneCode, class: classes },
    } = useLocation();

    useEffect(() => {
        dispatch(getStudentBaseCourses(adNo));
        dispatch(
            updateSingleUser({
                studentName: name,
                admissionNumber,
                phoneNum,
                phoneCode,
                class: classes,
            })
        );
    }, []);

    return (
        <Page>
            <Container>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Courses
                </Typography>
                <Breadcrumb sx={{ marginBottom: 1 }} />

                <Paper elevation={3} sx={{ borderRadius: 1, width: "100%" }}>
                    <Box
                        sx={{
                            height: 48,
                            backgroundColor: "grey.200",
                            borderRadius: "8px 8px 0 0",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "black",
                        }}
                    >
                        <p>{`Name : ${name} | AdmNo : ${admissionNumber} | Phone : +${phoneCode} ${phoneNum} | Class : ${classes}`}</p>
                    </Box>

                    <DataGrid
                        loading={loading}
                        rows={studentBaseCourses}
                        columns={columns}
                        getRowId={(row) => row.subjectEnrollmentId}
                        autoHeight
                        pageSize={gridSize}
                        onPageSizeChange={(newGridSize) =>
                            setGridSize(newGridSize)
                        }
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        components={{ Toolbar: CustomGridToolbar }}
                    />
                </Paper>
            </Container>
        </Page>
    );
};

export default CoursesView;
