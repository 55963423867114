import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { saveduserinfo, clearUserInfo, SwitchRole } from "../../../src/Common";
const data = JSON.parse(localStorage.getItem("cred"));
const initialState = {
    isLoading: false,
    isLogin: false,
    error: "",
    data: data || {},
    user: [],
};

// creating login action...

export const isLogin = createAsyncThunk("user/isLogin", async (event) => {
    const res = await apiRequest({
        method: "post",
        url: "users/login",
        data: { email: event.email, password: event.password },
    });

    if (res.status === "success") {
        res.data.isLogin = true;
        saveduserinfo(res.data);
        // have to create a store for user and using that state create private router
    }
    return res;
});

// creating otp action....

export const getOtp = createAsyncThunk("users/get_otp", async (event) => {
    const res = await apiRequest({
        method: "post",
        url: `users/${event.email}/send-otp-mail?type=PASSWORD_RESET`,
    });
    if (res.status === "success") {
        res.data.isLogin = false;
        saveduserinfo(res.data);
    }
    return res;
});

// creating verify otp action .....

export const verifyOtp = createAsyncThunk("users/verify_otp", async (e) => {
    const res = await apiRequest({
        method: "get",
        url: `users/${e.otp}/verify-otp?type=PASSWORD_RESET`,
    });

    if (res.status === "success") {
        res.data.isLogin = false;
        saveduserinfo(res.data);
    }
    return res;
});

// creating reset password action....
export const resetPassword = createAsyncThunk(
    "users/reset_pswrd",
    async (e) => {
        const res = await apiRequest({
            method: "put",
            url: "users/reset-password",
            data: { password: e.password },
        });

        return res;
    }
);

// creating resend otp action...

export const resendOtp = createAsyncThunk("users/resend_otp", async (e) => {
    const res = await apiRequest({
        method: "get",
        url: `users/resend-otp-mail?type=PASSWORD_RESET`,
    });

    if (res.status === "success") {
        res.data.isLogin = false;
        saveduserinfo(res.data);
    }

    return res;
});

//       mail verification.....

export const mailVerification = createAsyncThunk(
    "users/mailVerification",
    async (email) => {
        const res = await apiRequest({
            method: "post",
            url: `/users/${email}/send-otp-mail?type=REGISTRATION`,
        });
        return res;
    }
);

export const verifyRegisterOtp = createAsyncThunk(
    "users/verifyRegisterOtp",
    async (e) => {
        const res = await apiRequest({
            method: "get",
            url: `/users/${e}/verify-otp?type=REGISTRATION`,
        });
        return res;
    }
);

export const resendRegisterOtp = createAsyncThunk(
    "users/resendRegisterOtp",
    async (email) => {
        const res = await apiRequest({
            method: "get",
            url: `/users/resend-otp-mail?type=REGISTRATION`,
        });
        return res;
    }
);

export const registerUser = createAsyncThunk(
    "users/registerUser",
    async (values) => {
        const res = await apiRequest({
            method: "post",
            url: "/users/register",
            data: {
                name: values.fullName,
                password: values.password,
            },
        });
        return res;
    }
);

export const getUserPreData = createAsyncThunk(
    "users/getUserPreData",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "pre-data/user",
        });
        return res;
    }
);

export const switchRole = createAsyncThunk("users/switchRole", async (id) => {
    const res = await apiRequest({
        method: "get",
        url: `users/${id}/switch-role`,
    });
    return res;
});

const authSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        loginPending: (state) => {
            state.isLoading = true;
        },
        loginSuccess: (state) => {
            state.isLoading = false;
            state.isLogin = true;
            state.error = "";
        },
        loginFailed: (state, { payload }) => {
            state.isLoading = false;
            state.isLogin = false;
            state.error = payload;
        },
        clearUser: (state) => {
            state.isLogin = false;
            state.isLoading = false;
            state.data = {};
        },
        profileUpdateSuccess: (state, action) => {
            const data = JSON.parse(localStorage.getItem("cred"));
            let newprofile = { ...data.profile, profileCompletion: 1 };
            localStorage.setItem(
                "cred",
                JSON.stringify({ ...data, profile: newprofile })
            );
            return {
                ...state,
                data: { ...data, profile: newprofile },
            };
        },
        bankAccntCompleate: (state, action) => {
            const data = JSON.parse(localStorage.getItem("cred"));
            let newprofile = { ...data.profile, bankAccountCompletion: 1 };
            localStorage.setItem(
                "cred",
                JSON.stringify({ ...data, profile: newprofile })
            );
            return {
                ...state,
                data: { ...data, profile: newprofile },
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(isLogin.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(isLogin.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.isLoading = false;
        });
        builder.addCase(isLogin.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message;
        });

        builder.addCase(getOtp.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getOtp.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getOtp.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload.message;
        });

        builder.addCase(verifyOtp.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(verifyOtp.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        builder.addCase(resetPassword.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(resetPassword.fulfilled, (state, action) => {
            state.isLoading = false;
        });

        builder.addCase(resendOtp.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(resendOtp.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        // -------------------mailVerification-----------------------------------------------------
        builder.addCase(mailVerification.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(mailVerification.fulfilled, (state, action) => {
            //
            if (action.payload.status === "success") {
                action.payload.data.isLogin = false;
                saveduserinfo(action.payload.data);
            }
            state.isLoading = false;
        });
        builder.addCase(mailVerification.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload.message;
        });

        // ---------------------verifyRegisterOtp---------------------------------------------------

        builder.addCase(verifyRegisterOtp.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(verifyRegisterOtp.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                action.payload.data.isLogin = false;
                clearUserInfo();
                saveduserinfo(action.payload.data);
            }
            state.isLoading = false;
        });
        builder.addCase(verifyRegisterOtp.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload.message;
        });

        // -------------------------------registerUser--------------------------------------------------
        builder.addCase(registerUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(registerUser.fulfilled, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(registerUser.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload.message;
        });
        // -----------------------------resendRegisterOtp-----------------------------------------------------------
        builder.addCase(resendRegisterOtp.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(resendRegisterOtp.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                action.payload.data.isLogin = false;
                clearUserInfo();
                saveduserinfo(action.payload.data);
            }
            state.isLoading = false;
        });
        builder.addCase(resendRegisterOtp.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload.message;
        });
        // ------------------------getUserPreData--------------------------------------
        builder.addCase(getUserPreData.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getUserPreData.fulfilled, (state, action) => {
            state.user = action.payload.data.userData.roles;
            state.isLoading = false;
        });
        builder.addCase(getUserPreData.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload.message;
        });

        // ----------------------------switchRole-------------------------------
        builder.addCase(switchRole.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(switchRole.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                SwitchRole(action.payload.data);
            }

            state.isLoading = false;
        });
        builder.addCase(switchRole.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload.message;
        });
    },
});
const { reducer, actions } = authSlice;
export const {
    loginPending,
    loginSuccess,
    loginFailed,
    clearUser,
    profileUpdateSuccess,
    bankAccntCompleate,
} = actions;

export default reducer;
