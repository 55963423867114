import React from "react";
import { Route, Routes } from "react-router-dom";
import CrmCampaignAnalytics from "../../views/crm/analytics/CrmCampaignAnalytics";
import CroAnalytics from "../../views/crm/analytics/CroAnalytics";
import CroAccounts from "../../views/crm/analytics/CroAccounts";
import CourseSourceAnalytics from "../../views/crm/analytics/CourseSourceAnalytics";
import RetainCallAnalytics from "../../views/crm/analytics/RetainCallAnalytics";
import LeadCourseAnalytics from "../../views/crm/analytics/LeadCourseAnalytics";
import CrmPaidAssessmentAnalytics from "../../views/crm/analytics/CrmPaidAssessmentAnalytics";
import OgaConversionAnalytics from "../../views/crm/analytics/OgaConversionAnalytics";
import CrmEvaluationAnalytics from "../../views/crm/analytics/CrmEvaluationAnalytics";

const AnalyticsRouter = () => {
    return (
        <Routes>
            <Route
                path="crm-campaign-analytics"
                element={<CrmCampaignAnalytics />}
            />
            <Route path="/cro-analytics" element={<CroAnalytics />} />
            <Route path="/cro-accounts" element={<CroAccounts />} />
            <Route
                path="/course-source-analytics"
                element={<CourseSourceAnalytics />}
            />
            <Route
                path="/retain-call-analytics"
                element={<RetainCallAnalytics />}
            />
            <Route
                path="/lead-course-analytics"
                element={<LeadCourseAnalytics />}
            />

            <Route
                path="crm-paid-assessment-analytics"
                element={<CrmPaidAssessmentAnalytics />}
            />

            <Route
                path="crm-oga-conversion-analytics"
                element={<OgaConversionAnalytics />}
            />

            <Route
                path="crm-evaluation-analytics"
                element={<CrmEvaluationAnalytics />}
            />
        </Routes>
    );
};

export default AnalyticsRouter;
