import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import { IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import { RescheduleSessionForm } from "./RescheduleSessionForm";
import { useDispatch } from "react-redux";
import { getSessionCancellationReasons } from "../../redux/features/croSlice";
import { set } from "lodash";

export const RescheduleSessionPopup = ({ params, handleClose }) => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    return (
        <div>
            <GlobalDialog
                setOpen={setOpen}
                open={open}
                fullWidth
                maxWidth={"md"}
                closeButton={true}
                title="Reschedule Session"
                popupButton={
                    <>
                        <IconButton
                            aria-label="edit"
                            size="small"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={() => {
                                dispatch(getSessionCancellationReasons());
                                setOpen(true);
                            }}
                        >
                            <Icon
                                icon="mdi:calendar-sync-outline"
                                width="22"
                                height="22"
                                color="#2f72b3"
                            />
                            Reschedule
                        </IconButton>
                    </>
                }
                content={
                    <RescheduleSessionForm
                        handleClose={handleClose}
                        params={params}
                        setOpen={setOpen}
                    />
                }
            />
        </div>
    );
};
