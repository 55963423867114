import AddExpectedSessions from "./AddExpectedSessions";

export const EXPECTED_SESSIONS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "admnNo",
        headerName: "Admission Number",
        headerAlign: "left",
        align: "left",
        minWidth: 200,
    },
    {
        field: "courseEnrollmentId",
        headerName: "Course Enrollment Id",
        headerAlign: "left",
        align: "left",
        minWidth: 200,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        headerAlign: "left",
        align: "left",
        minWidth: 200,
    },
    {
        field: "className",
        headerName: "Class Name",
        headerAlign: "left",
        align: "left",
        minWidth: 200,
    },
    {
        field: "courseName",
        headerName: "Course Name",
        headerAlign: "left",
        align: "left",
        minWidth: 200,
    },
    {
        field: "expectedSessions",
        headerName: "Expected sessions",
        headerAlign: "left",
        align: "center",
        width: 200,
        renderCell: (params) => <AddExpectedSessions row={params.row} />,
    },
];
