import React from "react";
import CollapseList from "../../../../../CollapseList";
import {
    Box,
    Grid,
    Paper,
    Stack,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

const Mytable = ({ tableRow }) => {
    return (
        <Grid item xs={12} lg={12} md={12}>
            <Paper
                sx={{
                    margin: 1,
                    borderRadius: 4,
                }}
            >
                <Table>{tableRow}</Table>
            </Paper>
        </Grid>
    );
};
const Rows = ({ label, value, actions }) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell variant="head" sx={{ padding: 1 }}>
                    <Stack direction={"row"} spacing={1}>
                        <Box>{label}</Box>
                    </Stack>
                </TableCell>
                <TableCell sx={{ padding: 0.5 }}>
                    <Stack direction={"row"} spacing={1}>
                        <Box>{value}</Box>
                        <Box>{actions}</Box>
                    </Stack>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export const EnrollmemtDetails = ({ data }) => {
    return (
        <div>
            <CollapseList
                label={data[0]?.student_name}
                content={
                    <>
                        <Paper elevation={3}>
                            <Mytable
                                tableRow={
                                    <>
                                        {data?.map((item) => {
                                            return (
                                                <>
                                                    <Rows
                                                        label="Course"
                                                        value={item.course_name}
                                                    />
                                                    <Rows
                                                        label="Level"
                                                        value={item.level}
                                                    />
                                                    <Rows
                                                        label="Subject"
                                                        value={
                                                            item.subject_name
                                                        }
                                                    />
                                                    <Rows
                                                        label="No.Of Sessions"
                                                        value={
                                                            item.no_of_sessions
                                                        }
                                                    />
                                                    <Rows
                                                        label="Completed Sessions"
                                                        value={
                                                            item.total_completed_no_of_sessions
                                                        }
                                                    />
                                                </>
                                            );
                                        })}
                                    </>
                                }
                            />
                        </Paper>
                    </>
                }
            />
        </div>
    );
};
