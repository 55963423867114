import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    data: [],
    loading: false,
    error: "",
    allCabins: [],
    assetTimeSlotsForADay: [],
    freeAssetsForTimeRange: [],
    bookingDetailsById: [],
    slotesById: [],
    cabinsForAdministration: [],
};

export const getAllCabins = createAsyncThunk(
    "cabinBooking/getAllCabins",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "booking/getAllCabins",
        });
        return res;
    }
);

export const bookCabin = createAsyncThunk(
    "cabinBooking/bookCabin",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: "booking/createBookings",
            data: data,
        });
        return res;
    }
);
export const getAssetTimeSlotsForADay = createAsyncThunk(
    "cabinBooking/getAssetTimeSlotsForADay",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `booking/getAssetTimeSlotsForADay/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const getFreeAssetsForTimeRange = createAsyncThunk(
    "cabinBooking/getFreeAssetsForTimeRange",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: "booking/freeAssetsForTimeRange",
            data: data,
        });
        return res;
    }
);
export const getBookingDetailsById = createAsyncThunk(
    "cabinBooking/getBookingDetailsById",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `booking/getBookingDetails/${id}`,
        });
        return res;
    }
);
export const deleteCabin = createAsyncThunk(
    "cabinBooking/deleteCabin",
    async (id) => {
        const res = await apiRequest({
            method: "put",
            url: `booking/deleteCabin/${id}`,
        });
        return res;
    }
);
export const updateCabin = createAsyncThunk(
    "cabinBooking/updateCabin",
    async (data) => {
        const res = await apiRequest({
            method: "put",
            url: `booking/updateCabins/${data.id}`,
            data: data,
        });
        return res;
    }
);

export const createCabin = createAsyncThunk(
    "cabinBooking/createCabin",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `booking/createCabins`,
            data: data,
        });
        return res;
    }
);

export const getSlotesById = createAsyncThunk(
    "cabinBooking/getSlotesById",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `booking/getAllSlots/${id}`,
        });
        return res;
    }
);
export const createTimeSlot = createAsyncThunk(
    "cabinBooking/createTimeSlot",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `booking/createTimeSlots`,
            data: data,
        });
        return res;
    }
);
export const updateTimeSlot = createAsyncThunk(
    "cabinBooking/updateTimeSlot",
    async (data) => {
        const res = await apiRequest({
            method: "put",
            url: `booking/updateCabinsTimeSlots/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const deleteTimeSlot = createAsyncThunk(
    "cabinBooking/deleteTimeSlot",
    async (id) => {
        const res = await apiRequest({
            method: "put",
            url: `booking/deleteCabinSlots/${id}`,
        });
        return res;
    }
);
export const getAllCabinsForAdministration = createAsyncThunk(
    "cabinBooking/getAllCabinsForAdministration",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `booking/getAllCabinsForAdministration`,
        });

        return res;
    }
);
export const deleteBooking = createAsyncThunk(
    "cabinBooking/deleteBooking",
    async (id) => {
        const res = await apiRequest({
            method: "put",
            url: `booking/cancelTheBooking/${id}`,
        });

        return res;
    }
);

const cabinBookingSlice = createSlice({
    name: "cabinBooking",
    initialState,
    reducers: {
        clearFindAvailableCabins: (state, action) => {
            return {
                ...state,
                freeAssetsForTimeRange: [],
            };
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getAllCabins.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllCabins.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data?.allCabins);

            return {
                ...state,
                allCabins: data,
                loading: false,
            };
        });
        // -------------------------bookCabin-----------------------------------
        builder.addCase(bookCabin.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(bookCabin.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -----------------------getAssetTimeSlotsForADay----------------------
        builder.addCase(getAssetTimeSlotsForADay.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAssetTimeSlotsForADay.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.getAllSlots
            );

            return {
                ...state,
                assetTimeSlotsForADay: data,
                loading: false,
            };
        });
        // -------------------getFreeAssetsForTimeRange------------------------
        builder.addCase(getFreeAssetsForTimeRange.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getFreeAssetsForTimeRange.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.getAllSlots
                );
                return {
                    ...state,
                    freeAssetsForTimeRange: data,
                    loading: false,
                };
            }
        );
        // ----------------------getBookingDetailsById--------------------
        builder.addCase(getBookingDetailsById.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getBookingDetailsById.fulfilled, (state, action) => {
            const data = action.payload.booking;
            return {
                ...state,
                bookingDetailsById: data,
                loading: false,
            };
        });
        // ---------------------deleteCabin----------------------------------
        builder.addCase(deleteCabin.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteCabin.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------------updateCabin----------------------
        builder.addCase(updateCabin.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateCabin.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ---------------------------createCabin-------------------------------
        builder.addCase(createCabin.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(createCabin.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // --------------------getSlotesById---------------------
        builder.addCase(getSlotesById.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSlotesById.fulfilled, (state, action) => {
            const data = action.payload.data.AllSlots;
            return {
                ...state,
                slotesById: data,
                loading: false,
            };
        });
        // -------------------createTimeSlot------------------------
        builder.addCase(createTimeSlot.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(createTimeSlot.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------------updateTimeSlot------------------------
        builder.addCase(updateTimeSlot.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateTimeSlot.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ----------------------deleteTimeSlot--------------------------
        builder.addCase(deleteTimeSlot.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteTimeSlot.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------------getAllCabinsForAdministration--------------------
        builder.addCase(
            getAllCabinsForAdministration.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getAllCabinsForAdministration.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.allCabinsForAdministration
                );

                return {
                    ...state,
                    cabinsForAdministration: data,
                    loading: false,
                };
            }
        );
        // --------------------deleteBooking----------------------------
        builder.addCase(deleteBooking.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteBooking.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
    },
});
export const { clearFindAvailableCabins } = cabinBookingSlice.actions;
export default cabinBookingSlice.reducer;
