import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { MOBILE_APP_STUDENTS_TABLE_HEAD as columns } from "../../../components/datagrid/mytasks/cro/MobileAppStudents/MobileAppStudentsTableHead";
import { AddNewStudent } from "../../../components/datagrid/mytasks/cro/MobileAppStudents/AddNewStudent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMobileAppStudents } from "../../../../src/redux/features/croSlice";

const MobileAppStudents = () => {
    const profile = JSON.parse(localStorage.getItem("cred")).profile;

    const data = useSelector((state) => state.cro);
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = React.useState(5);

    useEffect(() => {
        dispatch(getMobileAppStudents(profile.userId));
    }, []);
    return (
        <div>
            <Wrapper
                title="Mobile App Students"
                actions={
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: 2,
                        }}
                    >
                        <AddNewStudent />
                    </Box>
                }
                Content={
                    <>
                        <Paper elevation={3}>
                            <Box>
                                <DataGrid
                                    loading={data.loading}
                                    rows={data.mobileAppStudents}
                                    autoHeight
                                    columns={columns}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    components={{ Toolbar: CustomGridToolbar }}
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
};

export default MobileAppStudents;
