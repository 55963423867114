import React from "react";
import Page from "../../../components/Page";
import { Box, Paper, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { DataGrid } from "@mui/x-data-grid";
import BasicButton from "../../../components/mui/Button";
import { CRO_FEE_UPDATE_TABLE_HEAD } from "../../../components/datagrid/myworkspace/cro/CrofeeUpdateTableHead";

const UpdateTotalFee = () => {
    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Update Total Fee
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                            }}
                        ></Box>

                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                rows={[]}
                                columns={CRO_FEE_UPDATE_TABLE_HEAD}
                                autoHeight
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default UpdateTotalFee;
