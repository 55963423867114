import React from "react";
import GlobalDeleteDialog from "../../../mui/Dialogue/GlobalDeleteDialog";
import {
    deleteBooking,
    getUserBookings,
} from "../../../../redux/features/mentoringSlice";
import { useSelector } from "react-redux";

export const DeleteBooking = ({ params }) => {
    const { loading } = useSelector((state) => state.mentoring);
    return (
        <div>
            {params.status === 0 ? (
                <GlobalDeleteDialog
                    btnName={false}
                    title="Delete Booking"
                    api={deleteBooking(params.mentoring_bookings_id)}
                    loading={loading}
                    deleteNote={
                        "Are you sure ? You want to delete this booking..!"
                    }
                    upDateCall={getUserBookings()}
                />
            ) : null}
        </div>
    );
};
