import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Paper } from "@mui/material";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { useState } from "react";
import { INACTIVE_STUDENTS_DATA_ANALYTICS_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/InactiveStudentsDataAnalytics/InactiveStudentsDataAnalyticsTableHead";

const data = [
    {
        serialNumber: 1,
        croName: "Ajvad skc",
        totalInactiveStudents: 135,
        admissionConfirmed: 50,
        postponed: 60,
        feeIssue: 20,
        notNeededNow: 30,
        noCourseToEnroll: 16,
        notAttended: 40,
    },
];
export default function InactiveStudentsDataAnalytics() {
    const [gridSize, setGridSize] = useState(5);
    return (
        <div>
            <Wrapper
                title="Inactive Students Data Analytics"
                Content={
                    <>
                        <Paper elevation={3}>
                            <Box
                                sx={{
                                    height: 50,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <Box>
                                <DataGrid
                                    // loading={data.loading}
                                    rows={data}
                                    columns={columns}
                                    columnBuffer={30}
                                    pageSize={gridSize}
                                    autoHeight
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    getRowId={(row) => {
                                        return row.serialNumber;
                                    }}
                                    components={{
                                        Toolbar: CustomGridToolbar,
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
