import {
    Alert,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import BasicTextField from "../../../components/mui/TextField";
import Page from "../../../components/Page";
import { Icon } from "@iconify/react";
import { Form, FormikProvider, useFormik } from "formik";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import BasicAutocomplete from "../../../components/mui/Select";
import { useDispatch, useSelector } from "react-redux";
import {
    createSchedulePreferences,
    getAcademicYears,
    getClassess,
    getCourseEnrollments,
    getCroStudents,
    getFilteredBasecourses,
    getSchedulePreview,
    getSessionCounts,
    getSubjectEnrollments,
    preferences,
    previewData,
    updatedPreview,
} from "../../../redux/features/sessionSlice";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DAYS } from "../../../utils/constants/daysConst.js";
import { SchedulePreview } from "./SchedulePreview";

const AddSchedule = () => {
    const [time, setTime] = useState([{ time: new Date() }]);
    const [startsFrom, setStartsFrom] = useState(new Date());
    const [customShedule, setCustomShedule] = useState(false);
    const [sessionCountArry, setSessionCount] = useState([]);
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [students, setStudents] = React.useState([]);
    const [course, setCourse] = React.useState([]);
    const [validateDate, setValidateDate] = React.useState();
    const [startDate, setStartDate] = React.useState();

    const {
        croStudents,
        courseEnrollments,
        subjectEnrollments,
        classes,
        filteredBaseCourses,
        sessionCounts,
        // academicYears,
        loading,
    } = useSelector((state) => state.sessions);

    useEffect(() => {
        setStudents([...croStudents]);
    }, [croStudents]);

    useEffect(() => {
        setCourse([...courseEnrollments]);
    }, [courseEnrollments]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        // courseEnrollmentId: yup.number().required("please select a course"),
        subjectEnrollmentId: yup.number().required("please select a subject"),
        // classId: yup.number().required("please select a class"),
        // baseCourseId: yup.number().required("please select a base course"),
        prefererdSchedules: yup.array().of(
            yup.object().shape({
                weekDayId: yup.number().required("please fill week day"),
                fromTime: yup.string().required("please fill from time"),
            })
        ),
        // academicYear: yup.string().required("Please select an academics year"),
    });
    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            courseEnrollmentId: "",
            courseId: "",
            subjectEnrollmentId: "",
            classStartsFrom: "",
            sessionsCompleted: "",
            fromSessionNumber: "",
            toSessionNumber: "",
            subjectId: "",
            // classId: "",
            // baseCourseId: "",
            baseCourseName: "",
            // academicYear: "",
            totalSessions: "",
            scheduledSessions: "",

            prefererdSchedules: [
                {
                    weekDayId: "",
                    fromTime: moment(new Date()).format("HH:mm:00"),
                },
            ],
        },
        validationSchema,
        onSubmit: (values) => {
            const {
                // courseEnrollmentId,
                courseId,
                // subjectId,
                baseCourseName,
                // classId,
                ...others
            } = values;

            dispatch(preferences({ preference: values.prefererdSchedules }));

            dispatch(getSchedulePreview(values)).then((res) => {
                if (
                    res.payload.status === "error" ||
                    res.payload.status === "failed"
                ) {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    setOpen(true);

                    // navigate("/schedules/sessions");
                }
            });
        },
    });
    console.log("formik.values", formik.values);
    const handleAdd = () => {
        let timeItem = {
            time: new Date(),
        };

        let newTime = [...time];
        newTime.push(timeItem);

        setTime(newTime);

        let item = {
            weekDayId: "",
            fromTime: moment(new Date()).format("HH:mm:00"),
        };

        let values = [...formik.values.prefererdSchedules];
        values.push(item);
        formik.setFieldValue("prefererdSchedules", values);
    };

    const handleRemove = (i) => {
        if (formik.values.prefererdSchedules.length > 1) {
            let values = [...formik.values.prefererdSchedules];
            let newTime = [...time];

            newTime.splice(i, 1);
            values.splice(i, 1);

            formik.setFieldValue("prefererdSchedules", values);
            setTime(newTime);
        } else {
            formik.setFieldValue("prefererdSchedules", [
                {
                    weekDayId: "",
                    fromTime: moment(new Date()).format("HH:mm:00"),
                },
            ]);
            setTime([{ time: new Date() }]);
        }
    };

    const handleTimeChange = (i, e) => {
        const Ftime = moment(new Date(e)).format("HH:mm:00");
        let timeVal = [...time];
        timeVal[i].time = e;
        setTime(timeVal);

        let values = [...formik.values.prefererdSchedules];
        values[i] = { ...values[i], fromTime: Ftime };
        formik.setFieldValue("prefererdSchedules", values);
    };

    const handleDayChange = (i, e) => {
        let values = [...formik.values.prefererdSchedules];
        values[i] = { ...values[i], weekDayId: e.target.value };
        formik.setFieldValue("prefererdSchedules", values);
    };

    const handleChangeStudent = ({ admissionNumber }) => {
        dispatch(getCourseEnrollments(admissionNumber));
    };

    const handleChangeDate = (e) => {
        setStartsFrom(e);
        formik.setFieldValue("classStartsFrom", dayjs(e).format("YYYY-MM-DD"));
        setStartDate(dayjs(e).format("YYYY-MM-DD"));
        // formik.setFieldValue("classStartsFrom", startDate);
    };

    useEffect(() => {
        dispatch(getCroStudents());
    }, []);
    useEffect(() => {
        if (formik.values.courseEnrollmentId) {
            dispatch(getSubjectEnrollments(formik.values.courseEnrollmentId));
        }
        if (course.length > 0) {
            course.forEach((item) => {
                if (
                    (item.courseEnrollmentId ===
                        formik.values.courseEnrollmentId &&
                        item.courseId === 35) ||
                    item.courseId === 39 ||
                    item.courseId === 40 ||
                    item.courseId === 41 ||
                    item.courseId === 42 ||
                    item.courseId === 43 ||
                    item.courseId === 44
                ) {
                    const tempId = 2;
                    formik.setFieldValue("courseId", tempId);
                } else {

                    formik.setFieldValue("courseId", item.courseId);
                }
            });
        }
    }, [formik.values.courseEnrollmentId]);

    useEffect(() => {
        if (formik.values.courseEnrollmentId) {
            subjectEnrollments.forEach((item) => {
                if (
                    item.subjectEnrollmentId ===
                    formik.values.subjectEnrollmentId
                ) {
                    formik.setFieldValue("subjectId", item.subjectId);
                    formik.setFieldValue("baseCourseName", item.baseCourseName);
                    formik.setFieldValue("totalSessions", item.totalSessions);
                    formik.setFieldValue("toSessionNumber", item.totalSessions);

                    if (item.sessionStartDate) {
                        setValidateDate(true);
                        formik.setFieldValue("classStartsFrom", startDate);
                        formik.setFieldValue(
                            "classStartsFrom",
                            item.sessionStartDate
                        );
                    } else {
                        setValidateDate(false);
                        formik.setFieldValue("classStartsFrom", startDate);
                    }
                    if (customShedule === false) {
                        formik.setFieldValue(
                            "fromSessionNumber",

                            (formik.values.sessionsCompleted >
                            formik.values.scheduledSessions
                                ? item.sessionsCompleted
                                : item.scheduledSessions) + 1
                        );
                        formik.setFieldValue(
                            "toSessionNumber",
                            item.totalSessions
                        );
                    }

                    formik.setFieldValue(
                        "sessionsCompleted",
                        item.sessionsCompleted
                    );

                    formik.setFieldValue(
                        "scheduledSessions",
                        item.scheduledSessions
                    );
                    formik.setFieldValue(
                        "fromSessionNumber",

                        (formik.values.sessionsCompleted >
                        formik.values.scheduledSessions
                            ? item.sessionsCompleted
                            : item.scheduledSessions) + 1
                    );
                }
            });
            dispatch(getClassess());
        }
    }, [formik.values.subjectEnrollmentId, customShedule]);

    useEffect(() => {
        if (formik.values.classId) {
            dispatch(
                getFilteredBasecourses(
                    `${formik.values.courseId}/${formik.values.subjectId}/${formik.values.classId}`
                )
            );
        }
    }, [
        formik.values.classId,
        formik.values.courseId,
        formik.values.subjectId,
    ]);

    // useEffect(() => {
    //     if (formik.values.baseCourseId) {
    //         dispatch(getAcademicYears(formik.values.baseCourseId));
    //     }

    //     if (formik.values.baseCourseId && formik.values.academicYear) {
    //         dispatch(
    //             getSessionCounts({
    //                 baseCId: formik.values.baseCourseId,
    //                 year: formik.values.academicYear,
    //             })
    //         );
    //     }
    // }, [formik.values.baseCourseId, formik.values.academicYear]);

    useEffect(() => {
        if (formik.values.fromSessionNumber && formik.values.toSessionNumber) {
            let res = [];
            for (
                let i =
                    formik.values.sessionsCompleted >
                    formik.values.scheduledSessions
                        ? formik.values.sessionsCompleted
                        : formik.values.scheduledSessions + 1;
                i <= formik.values.totalSessions;
                i++
            ) {
                res.push({
                    id: i,
                });
            }
            setSessionCount(res);
            // formik.setFieldValue("toSessionNumber", sessionCounts);
        }
    }, [
        sessionCounts,
        formik.values.fromSessionNumber,
        formik.values.toSessionNumber,
    ]);

    return (
        <Page>
            <Container>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Add Sessions
                </Typography>
                <Breadcrumb sx={{ marginBottom: 1 }} />

                <Paper elevation={4} sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            height: 48,
                            backgroundColor: "grey.200",
                            borderRadius: "8px 8px 0 0",
                        }}
                    ></Box>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Stack
                                sx={{
                                    width: "85%",
                                    margin: "0 auto",
                                    p: 4,
                                }}
                                gap={2}
                            >
                                <FormControl>
                                    <BasicAutocomplete
                                        options={students}
                                        getOptionLabel={(option) =>
                                            option.studentName
                                        }
                                        action={(e, value) => {
                                            handleChangeStudent(value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Student"
                                            />
                                        )}
                                        renderOption={(props, option) => {
                                            return (
                                                <li
                                                    {...props}
                                                    value={
                                                        option.admissionNumber
                                                    }
                                                    key={option.admissionNumber}
                                                    data-subenid={
                                                        option.admissionNumber
                                                    }
                                                >
                                                    {option.studentName} : (
                                                    {option.admissionNumber})
                                                </li>
                                            );
                                        }}
                                    />
                                </FormControl>
                                <GlobalSelectField
                                    options={{
                                        label: "Course",
                                        name: "courseEnrollmentId",
                                        formik,
                                        data: courseEnrollments,
                                        keys: {
                                            id: "courseEnrollmentId",
                                            value: "courseName",
                                        },
                                    }}
                                />
                                <GlobalSelectField
                                    options={{
                                        label: "Subject",
                                        name: "subjectEnrollmentId",
                                        formik,
                                        data: subjectEnrollments,
                                        keys: {
                                            id: "subjectEnrollmentId",
                                            value: "subjectName",
                                        },
                                    }}
                                />
                                {/* <GlobalSelectField
                                    options={{
                                        label: "class",
                                        name: "classId",
                                        formik,
                                        data: classes,
                                        keys: {
                                            id: "classId",
                                            value: "className",
                                        },
                                    }}
                                /> */}
                                {/* <GlobalSelectField
                                    options={{
                                        label: "Base Course",
                                        name: "baseCourseId",
                                        formik,
                                        data: filteredBaseCourses,
                                        keys: {
                                            id: "baseCourseId",
                                            value: "displayValue",
                                        },
                                    }}
                                /> */}
                                <BasicTextField
                                    name="baseCourseName"
                                    sx={{ flex: 1 }}
                                    value={formik.values.baseCourseName || ""}
                                    label="Base Course Name"
                                    variant="outlined"
                                    disabled
                                />
                                {/* <GlobalSelectField
                                    options={{
                                        label: "Academic Year",
                                        name: "academicYear",
                                        formik: formik,
                                        data: academicYears,
                                        keys: {
                                            id: "academicYear",
                                            value: "academicYear",
                                        },
                                    }}
                                /> */}
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DesktopDatePicker
                                        label="Class Starts From"
                                        inputFormat="DD/MM/YYYY"
                                        value={
                                            formik.values.classStartsFrom || ""
                                        }
                                        onChange={handleChangeDate}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                        disablePast={
                                            validateDate ? false : true
                                        }
                                        disabled={validateDate ? true : false}
                                    />
                                </LocalizationProvider>
                                <BasicTextField
                                    name="sessionsCompleted"
                                    sx={{
                                        flex: 1,
                                        cursor: "not-allowed",
                                    }}
                                    value={formik.values.sessionsCompleted}
                                    id="outlined-basic"
                                    label="Sessions completed"
                                    variant="outlined"
                                    disabled
                                />
                                <BasicTextField
                                    sx={{ flex: 1 }}
                                    value={formik.values.totalSessions}
                                    id="outlined-basic"
                                    label="Total sessions"
                                    variant="outlined"
                                    disabled
                                />

                                <BasicTextField
                                    sx={{ flex: 1 }}
                                    value={formik.values.scheduledSessions}
                                    id="outlined-basic"
                                    label="Scheduled Sessions"
                                    variant="outlined"
                                    disabled
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={customShedule}
                                            onChange={(e) => {
                                                setCustomShedule(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                    }
                                    label="Custom Schedules"
                                />

                                {formik.values.scheduledSessions <
                                    formik.values.totalSessions &&
                                customShedule === true &&
                                formik.values.subjectEnrollmentId ? (
                                    <>
                                        <Stack direction={"row"} gap={2}>
                                            <GlobalSelectField
                                                options={{
                                                    label: "from session",
                                                    name: "fromSessionNumber",
                                                    formik,
                                                    data: sessionCountArry,
                                                    keys: {
                                                        id: "id",
                                                        value: "id",
                                                    },
                                                }}
                                            />

                                            <GlobalSelectField
                                                options={{
                                                    label: "to session",
                                                    name: "toSessionNumber",
                                                    formik,
                                                    data: sessionCountArry,
                                                    keys: {
                                                        id: "id",
                                                        value: "id",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                    </>
                                ) : null}

                                {formik.values.scheduledSessions >=
                                    formik.values.totalSessions &&
                                customShedule === true &&
                                formik.values.subjectEnrollmentId ? (
                                    <Alert severity="error">
                                        There is no sessions for customization !
                                    </Alert>
                                ) : null}
                                <Box>
                                    <Typography
                                        variant="subtitle1"
                                        marginBottom={1}
                                    >
                                        Preferred Schedules
                                    </Typography>
                                    <Stack gap={2} alignItems="center">
                                        {formik.values.prefererdSchedules.map(
                                            (item, i) => {
                                                return (
                                                    <Stack
                                                        direction={"row"}
                                                        gap={2}
                                                        alignItems="center"
                                                        key={i}
                                                    >
                                                        <FormControl
                                                            variant="outlined"
                                                            sx={{
                                                                minWidth: 160,
                                                                flex: 1,
                                                            }}
                                                            error={
                                                                formik.errors
                                                                    .prefererdSchedules &&
                                                                Boolean(
                                                                    formik
                                                                        .errors
                                                                        .prefererdSchedules[
                                                                        i
                                                                    ]
                                                                )
                                                            }
                                                        >
                                                            <InputLabel>
                                                                Week days
                                                            </InputLabel>
                                                            <Select
                                                                label="Week days"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .prefererdSchedules[
                                                                        i
                                                                    ].weekDayId
                                                                }
                                                                onChange={(e) =>
                                                                    handleDayChange(
                                                                        i,
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                {DAYS.map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                index
                                                                            }
                                                                            value={
                                                                                option.id
                                                                            }
                                                                        >
                                                                            {
                                                                                option.day
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </FormControl>

                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <TimePicker
                                                                label="Time"
                                                                minutesStep={15}
                                                                value={
                                                                    time[i].time
                                                                }
                                                                onChange={(e) =>
                                                                    handleTimeChange(
                                                                        i,
                                                                        e
                                                                    )
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        {...params}
                                                                        sx={{
                                                                            flex: 1,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>

                                                        <Box>
                                                            <Typography variant="subtitle2">
                                                                Action
                                                            </Typography>
                                                            <Stack
                                                                direction={
                                                                    "row"
                                                                }
                                                                gap={2}
                                                            >
                                                                <Button
                                                                    onClick={
                                                                        handleAdd
                                                                    }
                                                                >
                                                                    <Icon
                                                                        icon="material-symbols:add"
                                                                        height="28"
                                                                    />{" "}
                                                                </Button>
                                                                <Button
                                                                    onClick={() =>
                                                                        handleRemove(
                                                                            i
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        icon="material-symbols:remove"
                                                                        height="28"
                                                                    />
                                                                </Button>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                );
                                            }
                                        )}
                                    </Stack>
                                </Box>
                                <Stack
                                    direction={"row"}
                                    gap={2}
                                    sx={{
                                        width: "100%",
                                        justifyContent: "center",
                                        marginTop: 2,
                                    }}
                                >
                                    <SchedulePreview
                                        data={formik.values}
                                        setOpen={setOpen}
                                        open={open}
                                    />
                                    <Button type="submit" variant="contained">
                                        {loading ? "Loading..." : "Preview"}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => formik.resetForm()}
                                    >
                                        CLEAR
                                    </Button>
                                </Stack>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </Paper>
            </Container>
        </Page>
    );
};

export default AddSchedule;
