import {
    Box,
    Button,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import Page from "../../../../components/Page";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { Form, FormikProvider, useFormik } from "formik";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    approveLeaveHr,
    approveLeaveReportingHead,
    getLeaveApplicationById,
    getLeaveTypes,
} from "../../../../redux/features/leaveSlice";
import * as yup from "yup";
import { useSnackbar } from "notistack";

const ApproveLeave = () => {
    const { leaveId } = useParams();
    const dispatch = useDispatch();
    const { singleApplication, data, loading } = useSelector(
        (state) => state.leave
    );

    const { enqueueSnackbar } = useSnackbar();
    const { pathname } = useLocation();
    const page = pathname.split("/");

    const navigate = useNavigate();

    const schema = yup.object().shape({
        status: yup.number().required("please fill this field"),
        remarks: yup.string().required("This field is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            leaveId: parseInt(leaveId) || "",
            status: "",
            leaveTypeId: singleApplication?.leaveTypeId || "",
            remarks: "",
        },
        validationSchema: schema,
        onSubmit: (values) => {
            if (page.includes("hod-leave-approve")) {
                dispatch(approveLeaveReportingHead(values)).then((res) => {
                    if (res.payload.status === "success") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                        navigate(
                            "/my-tasks/reporting_head_or_hod/hod-leave-applications"
                        );
                    } else {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    }
                });
            }
            if (page.includes("hr-leave-approve")) {
                dispatch(approveLeaveHr(values)).then((res) => {
                    if (res.payload.status === "success") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                        navigate("/my-tasks/all-leave-applications");
                    } else {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    }
                });
            }
        },
    });

    useEffect(() => {
        dispatch(getLeaveApplicationById(leaveId));
        dispatch(getLeaveTypes());
    }, []);

    useEffect(() => {}, [data]);

    return (
        <Page>
            <Container>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Leave Approval
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />

                <Paper elevation={3} sx={{ marginTop: 4 }}>
                    <Box
                        sx={{
                            height: 48,
                            backgroundColor: "grey.200",
                            borderRadius: "8px 8px 0 0",
                        }}
                    ></Box>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Stack gap={2} margin={7}>
                                <GlobalSelectField
                                    disabled={page.includes(
                                        "hod-leave-approve"
                                    )}
                                    options={{
                                        name: "leaveTypeId",
                                        label: "Type Of Leave",
                                        formik,
                                        data: data,
                                        keys: {
                                            id: "leaveTypeId",
                                            value: "leaveNameFull",
                                        },
                                    }}
                                />
                                <TextField
                                    label="From Date"
                                    variant="filled"
                                    value={singleApplication?.fromDate || ""}
                                    disabled
                                />
                                <TextField
                                    label="To Date"
                                    variant="filled"
                                    value={singleApplication?.toDate || ""}
                                    disabled
                                />
                                <TextField
                                    label="NO of Days"
                                    variant="filled"
                                    value={singleApplication?.noOfdays || ""}
                                    disabled
                                />
                                <TextField
                                    label="Reason"
                                    disabled
                                    variant="filled"
                                    value={singleApplication?.reason || ""}
                                    multiline
                                    rows={3}
                                />
                                <TextField
                                    name="remarks"
                                    label="Remarks"
                                    value={formik.values.remarks}
                                    onChange={formik.handleChange}
                                    multiline
                                    rows={3}
                                    error={Boolean(formik.errors.remarks)}
                                    helperText={formik.errors.remarks}
                                />
                                <GlobalSelectField
                                    options={{
                                        name: "status",
                                        label: "Action",
                                        formik: formik,
                                        data: [
                                            { id: 1, value: "Approve" },
                                            { id: 0, value: "Reject" },
                                        ],
                                        keys: {
                                            id: "id",
                                            value: "value",
                                        },
                                    }}
                                />
                            </Stack>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    padding: 3,
                                }}
                            >
                                <Stack direction={"row"} spacing={1}>
                                    <Button
                                        variant="outlined"
                                        component={Link}
                                        to={`/my-tasks/all-leave-applications`}
                                    >
                                        Cancel
                                    </Button>
                                    <Button variant="contained" type="submit">
                                        {loading ? "Submitting..." : "Submit"}
                                    </Button>
                                </Stack>
                            </Box>
                        </Form>
                    </FormikProvider>
                </Paper>
            </Container>
        </Page>
    );
};

export default ApproveLeave;
