import React from "react";
import { AddModulePopup } from "./AddModulePopup";
import { AddSessionPopup } from "./AddSessionPopup";

const AddModule = React.memo((params) => {
    return <AddModulePopup params={params} />;
});

const AddSession = React.memo((params) => {
    return <AddSessionPopup params={params} />;
});

export { AddModule, AddSession };
