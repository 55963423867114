import { Box, Grid } from "@mui/material";
import React from "react";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";

export const Details = ({ data }) => {
    return (
        <div>
            <Box>
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="email" value={data?.email} />
                                <Rows label="Mobile" value={data?.mobileNo} />

                                <Rows label="Place" value={data?.place} />
                                <Rows label="Gender" value={data?.gender} />
                                <Rows label="District" value={data?.district} />
                                <Rows
                                    label="Qualification"
                                    value={data?.qualification}
                                />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows
                                    label="Employment Status"
                                    value={data?.employmentStatus}
                                />
                                <Rows
                                    label="Trainer Availability"
                                    value={data?.trainerAvailability}
                                />
                                <Rows
                                    label="Tutorial Watched On"
                                    value={data?.tutorialWatchedOn}
                                />
                                <Rows
                                    label="SignedUp On"
                                    value={data?.signedUpOn}
                                />
                                <Rows
                                    label="Last Updated On"
                                    value={data?.lastUpdatedOn}
                                />
                            </>
                        }
                    />
                </Grid>
            </Box>
        </div>
    );
};
