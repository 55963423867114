import React from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { useState } from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { SubmitButton } from "../../../../components/mui/Buttons/Buttons";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { useDispatch, useSelector } from "react-redux";
import {
    clearFindAvailableCabins,
    getFreeAssetsForTimeRange,
} from "../../../../redux/features/cabinBookingSlice";
import { useEffect } from "react";
import { Icon } from "@iconify/react";
import { PopupForm } from "./PopupForm";
import useResponsive from "../../../../Hooks/useResponsive";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const FindAvailableCabin = () => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const { freeAssetsForTimeRange, loading } = useSelector(
        (state) => state.cabin
    );
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    const [data, setData] = useState("");

    const Schema = Yup.object().shape({
        // from_time: Yup.string().required("From time is required"),
        // to_time: Yup.string().required("To time is required"),

        booking_date: Yup.string().required(" Booking date is required"),
    });

    const formik = useFormik({
        initialValues: {
            booking_date: dayjs(),
            from_time: "",
            to_time: "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            const data = {
                date: values.booking_date.format("DD/MM/YYYY"),
                fromTime: values.from_time.format("HH:mm:00"),
                toTime: values.to_time.format("HH:mm:00"),
            };
            dispatch(getFreeAssetsForTimeRange(data));
            setData(data);
        },
    });
    useEffect(() => {
        if (open === false && freeAssetsForTimeRange) {
            setTimeout(() => {
                dispatch(clearFindAvailableCabins());
                formik.setFieldValue("booking_date", dayjs());
                formik.setFieldValue("from_time", null);
                formik.setFieldValue("to_time", null);
            }, 500);
        }
    }, [open]);
    const [gridSize, setGridSize] = useState(5);

    const CRM_LEADS_TABLE_HEAD = [
        {
            field: "serialNumber",
            headerName: "slNo",
            width: 250,
        },

        {
            field: "asset_name",
            headerName: "Cabin Name",
            width: 350,
        },
        {
            field: "fromTime",
            headerName: "from Time",
            width: 120,
            renderCell: (params) => {
                const fromTime = params.row.fromTime;

                const [hours, minutes, seconds] = fromTime.split(":");
                const date = new Date(2000, 0, 1, hours, minutes, seconds);
                const time12hr = date.toLocaleTimeString([], {
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                });

                return time12hr;
            },
        },
        {
            field: "toTime",
            headerName: "To Time",
            width: 120,
            renderCell: (params) => {
                const toTime = params.row.toTime;

                const [hours, minutes, seconds] = toTime.split(":");
                const date = new Date(2000, 0, 1, hours, minutes, seconds);
                const time12hr = date.toLocaleTimeString([], {
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true,
                });

                return time12hr;
            },
        },

        {
            field: "Book",
            headerName: "Book",
            width: 250,
            renderCell: (params) => (
                <PopupForm params={params.row} popupData={data} />
            ),
        },
    ];

    return (
        <div>
            <GlobalDialog
                title="Find Available Cabin"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"lg"}
                closeButton={true}
                popupButton={
                    <Button
                        size={smUp ? "large" : "small"}
                        onClick={() => setOpen(true)}
                        sx={{ backgroundColor: "#eff4f9" }}
                    >
                        <Icon icon="mdi:tab-search" width="22" height="22" />
                        Find Available Cabin
                    </Button>
                }
                content={
                    <>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box>
                                        <Stack
                                            direction={smUp ? "row" : "column"}
                                            spacing={2}
                                        >
                                            <Box>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        label="Date"
                                                        name="booking_date"
                                                        disablePast
                                                        format="DD/MM/YYYY"
                                                        value={
                                                            formik.values
                                                                .booking_date
                                                        }
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            setValue(newValue);
                                                            formik.setFieldValue(
                                                                "booking_date",
                                                                newValue
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                onKeyDown={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                }}
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .booking_date &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .booking_date
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .booking_date
                                                                }
                                                                fullWidth
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                            <Box>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <TimePicker
                                                        name="from_time"
                                                        label="From Time"
                                                        minutesStep={30}
                                                        // minTime={dayjs()
                                                        //     .set("hour", 8)
                                                        //     .set("minute", 0)} // 8:00 AM
                                                        // maxTime={dayjs()
                                                        //     .set("hour", 20)
                                                        //     .set("minute", 30)} // 8:00 PM
                                                        value={
                                                            formik.values
                                                                .from_time
                                                        }
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            setValue(newValue);
                                                            formik.setFieldValue(
                                                                "from_time",
                                                                newValue
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                required
                                                                onKeyDown={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                }}
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .from_time &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .from_time
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .from_time
                                                                }
                                                                fullWidth
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                            <Box>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <TimePicker
                                                        label="To Time"
                                                        name="to_time"
                                                        minutesStep={30}
                                                        // minTime={dayjs()
                                                        //     .set("hour", 8)
                                                        //     .set("minute", 0)} // 8:00 AM
                                                        // maxTime={dayjs()
                                                        //     .set("hour", 20)
                                                        //     .set("minute", 30)} // 8:00 PM
                                                        value={
                                                            formik.values
                                                                .to_time
                                                        }
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            setValue(newValue);
                                                            formik.setFieldValue(
                                                                "to_time",
                                                                newValue
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                required
                                                                onKeyDown={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                }}
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .to_time &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .to_time
                                                                    )
                                                                }
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .to_time
                                                                }
                                                                fullWidth
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                            <Box paddingTop={1}>
                                                <SubmitButton type="submit">
                                                    Submit
                                                </SubmitButton>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Box>
                        <Box sx={{ paddingTop: 2 }}>
                            <Paper elevation={3}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        padding: 0,
                                    }}
                                >
                                    <DataGrid
                                        components={{
                                            Toolbar: CustomGridToolbar,
                                        }}
                                        loading={loading}
                                        autoHeight
                                        sx={{
                                            "& .MuiDataGrid-columnHeaderTitle":
                                                {
                                                    textOverflow: "clip",
                                                    whiteSpace: "break-spaces",
                                                    lineHeight: 1,
                                                },
                                        }}
                                        rows={freeAssetsForTimeRange}
                                        columns={CRM_LEADS_TABLE_HEAD}
                                        getRowId={(row) => row.serialNumber}
                                        pageSize={gridSize}
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        onPageSizeChange={(newGridSize) =>
                                            setGridSize(newGridSize)
                                        }
                                    />
                                </Box>
                            </Paper>
                        </Box>
                    </>
                }
            />
        </div>
    );
};
