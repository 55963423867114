import React, { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box, Stack, TextField } from "@mui/material";
import {
  AddButton,
  DeleteButton,
} from "../../../../components/mui/Buttons/Buttons";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DatePickerCustom from "../../../../components/DatePicker/DatePickerCustom";
import useResponsive from "../../../../Hooks/useResponsive";
import Skeleton from "@mui/material/Skeleton";

const LunchOrEvening = ({
  selectedSlots,
  date,
  slots,
  handleSlotChange,
  action,
  alreadyMarked,
  offices,
  selectedOffice,
  handleOfficeChange,
  loading,
  seatAvailable,
  seatNumberShow
}) => {
  console.log(slots);

  const [direction, setDirection] = useState("row");
  const mdDown = useResponsive("down", "md");
  useEffect(() => {
    if (mdDown) {
      setDirection("column");
    } else {
      setDirection("row");
    }
  }, [mdDown]);

  return (
    <>
      {loading ? (
        <Stack
          direction={direction}
          gap={2}
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: mdDown ? "start" : "center",
            padding: 1,
          }}
        >
          <Skeleton variant="rectangular" width={210} height={60} />
          <Skeleton variant="rectangular" width={180} height={60} />
          <Skeleton variant="rounded" width={210} height={60} />
          <Skeleton variant="square" width={80} height={40} />
        </Stack>
      ) : (
        <Stack
          direction={direction}
          gap={2}
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: mdDown ? "start" : "center",
            padding: 1,
          }}
        >
          <Box>
            <FormControl>
              <InputLabel>Time slots</InputLabel>
              <Select
                sx={{ width: "220px" }}
                name="timeSlots"
                label="Time Slots"
                value={selectedSlots}
                onChange={handleSlotChange}
              >
                {slots?.map((item) => {
                  return (
                    <MenuItem key={item.food_slot_id} value={item.food_slot_id}>
                      {item.food_slot_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box>
            {seatNumberShow &&  <TextField  sx={{width:"120px"}} label="Available Seats" value={seatAvailable}/>}     
          </Box>
          <Box>
            <DatePickerCustom
              value={date}
              viewDetails={["year", "month", "day"]}
              readOnly={true}
              maxWidth={"160px"}
            />
          </Box>
          <Box>
            <FormControl>
              <InputLabel>Office</InputLabel>
              <Select
                sx={{ width: "160px" }}
                name="offices"
                label="offices"
                value={selectedOffice}
                onChange={handleOfficeChange}
                disabled
              >
                {offices?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            {alreadyMarked ? (
              <DeleteButton action={action}> Remove </DeleteButton>
            ) : (
              <AddButton disabled={seatAvailable==0}  action={action}> Add </AddButton>
            )}
          </Box>
        </Stack>
      )}
    </>
  );
};

export default LunchOrEvening;
