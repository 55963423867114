import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    SubmitButton,
} from "../../../../../mui/Buttons/Buttons";
import { useFormik, Form, FormikProvider } from "formik";
import { Box, Button, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllStudentSubjectData,
    getLevelType,
    getSubjectsByClass,
    subjectEnrollment,
} from "../../../../../../redux/features/croSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSnackbar } from "notistack";
import * as Yup from "yup";

export const EnrollmentForm = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState(dayjs());
    const [date, setDate] = React.useState(dayjs());
    const { levelTypePreData, subjectsByClassPreData, loading } = useSelector(
        (state) => state.cro
    );
    const [formFields, setFormFields] = useState([
        { subject_id: "", subject_level: "", no_of_sessions: "" },
    ]);
    const subjectSchema = Yup.object().shape({
        no_of_sessions: Yup.string().required("Number of sessions is required"),
        subject_level: Yup.string().required("Level is required"),
        subject_id: Yup.string().required("Subject is required"),
    });
    const Schema = Yup.object().shape({
        cro_class_per_week: Yup.string().required(
            "cro_class_per_week is required"
        ),
        cro_fee_per_session: Yup.string().required(
            "cro_fee_per_session is required"
        ),
        total_sessions: Yup.string().required("total_sessions is required"),
        subjects: Yup.array().of(subjectSchema),
    });
    const admissionNumber = {
        adm_no: params.admn_no,
    };
    const formik = useFormik({
        initialValues: {
            course_enrollment_id: params.courseEnrollmentId,
            subjects: formFields,
            cro_class_per_week: "",
            cro_fee_per_session: "",
            subject_schedule_time: "",
            academic_year: dayjs(date).format("YYYY"),
            total_sessions: "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            const subject_id = values.subjects.map((item) => item.subject_id);
            const subject_level = values.subjects.map(
                (item) => item.subject_level
            );
            const no_of_sessions = values.subjects.map((item) =>
                parseInt(item.no_of_sessions)
            );
            const data = {
                course_enrollment_id: parseInt(values.course_enrollment_id),
                cro_class_per_week: parseInt(values.cro_class_per_week),
                cro_fee_per_session: parseInt(values.cro_fee_per_session),
                subject_schedule_time: values.subject_schedule_time,
                academic_year: values.academic_year,
                total_sessions: values.total_sessions,
                subject_id: subject_id,
                subject_level: subject_level,
                no_of_sessions: no_of_sessions,
            };

            dispatch(subjectEnrollment(data)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getAllStudentSubjectData(admissionNumber));
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    useEffect(() => {
        const no_of_sessions = formik.values.subjects.map((item) =>
            parseInt(item.no_of_sessions)
        );
        const sum = no_of_sessions.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
        );
        formik.setFieldValue("total_sessions", sum);
    }, [formik.values.subjects]);

    useEffect(() => {
        dispatch(getLevelType());
    }, []);

    const addFormFields = () => {
        setFormFields([
            ...formFields,
            { subject_id: "", subject_level: "", no_of_sessions: "" },
        ]);
    };

    const removeFormFields = (index) => {
        const newFormFields = [...formFields];
        newFormFields.splice(index, 1);
        setFormFields(newFormFields);
        formik.setFieldValue(
            "subjects",
            formik.values.subjects.filter((_, i) => i !== index)
        );
    };

    return (
        <GlobalDialog
            title="Add Subject"
            open={open}
            setOpen={setOpen}
            fullWidth
            maxWidth={"sm"}
            closeButton={true}
            popupButton={
                <>
                    <AddButton
                        action={() => {
                            setOpen(true);
                            setTimeout(() => {
                                dispatch(
                                    getSubjectsByClass(params.student_class_id)
                                );
                            }, 500);
                        }}
                    >
                        Add Subject
                    </AddButton>
                </>
            }
            content={
                <>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Box
                                sx={{
                                    backgroundColor: "grey.200",
                                    borderRadius: 2,
                                    padding: 2,
                                }}
                            >
                                {formFields?.map((field, index) => (
                                    <Box key={index}>
                                        <Stack
                                            direction={"column"}
                                            spacing={1}
                                            key={index}
                                        >
                                            <Box key={index}>
                                                <FormControl fullWidth>
                                                    <InputLabel>
                                                        Subject
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        label="Subject"
                                                        name={`subjects[${index}].subject_id`}
                                                        value={
                                                            formik.values
                                                                .subjects[index]
                                                                ?.subject_id ||
                                                            ""
                                                        }
                                                        onChange={(e) =>
                                                            formik.setFieldValue(
                                                                `subjects[${index}].subject_id`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {subjectsByClassPreData.map(
                                                            (item) => (
                                                                <MenuItem
                                                                    key={
                                                                        item.subjId
                                                                    }
                                                                    value={
                                                                        item.subjId
                                                                    }
                                                                >
                                                                    {
                                                                        item.subjectName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <FormControl fullWidth>
                                                    <InputLabel>
                                                        Level
                                                    </InputLabel>
                                                    <Select
                                                        required
                                                        label="Level"
                                                        name={`subjects[${index}].subject_level`}
                                                        value={
                                                            formik.values
                                                                .subjects[index]
                                                                ?.subject_level ||
                                                            ""
                                                        }
                                                        onChange={(e) =>
                                                            formik.setFieldValue(
                                                                `subjects[${index}].subject_level`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        {levelTypePreData.map(
                                                            (item) => (
                                                                <MenuItem
                                                                    key={
                                                                        item.miscMastersId
                                                                    }
                                                                    value={
                                                                        item.miscMastersId
                                                                    }
                                                                >
                                                                    {
                                                                        item.mastersName
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Box>
                                                <TextField
                                                    required
                                                    name={`subjects[${index}].no_of_sessions`}
                                                    label="No. of Sessions"
                                                    fullWidth
                                                    value={
                                                        formik.values.subjects[
                                                            index
                                                        ]?.no_of_sessions || ""
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    // error={
                                                    //     formik.touched.subjects[
                                                    //         index
                                                    //     ]?.no_of_sessions &&
                                                    //     Boolean(
                                                    //         formik.errors
                                                    //             .subjects[index]
                                                    //             ?.no_of_sessions
                                                    //     )
                                                    // }
                                                    // helperText={
                                                    //     formik.errors.subjects[
                                                    //         index
                                                    //     ]?.no_of_sessions
                                                    // }
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                {" "}
                                                <Button
                                                    onClick={() =>
                                                        removeFormFields(index)
                                                    }
                                                >
                                                    -
                                                </Button>
                                            </Box>
                                        </Stack>
                                    </Box>
                                ))}
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button onClick={addFormFields}>+</Button>
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Stack direction={"column"} spacing={1}>
                                    <Box>
                                        <TextField
                                            name="cro_class_per_week"
                                            label="Class Per Week"
                                            value={
                                                formik.values.cro_class_per_week
                                            }
                                            fullWidth
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched
                                                    .cro_class_per_week &&
                                                Boolean(
                                                    formik.errors
                                                        .cro_class_per_week
                                                )
                                            }
                                            helperText={
                                                formik.errors.cro_class_per_week
                                            }
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            name="cro_fee_per_session"
                                            label="Fee Per Session"
                                            value={
                                                formik.values
                                                    .cro_fee_per_session
                                            }
                                            fullWidth
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched
                                                    .cro_fee_per_session &&
                                                Boolean(
                                                    formik.errors
                                                        .cro_fee_per_session
                                                )
                                            }
                                            helperText={
                                                formik.errors
                                                    .cro_fee_per_session
                                            }
                                        />
                                    </Box>
                                    <Box>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DemoContainer
                                                components={[
                                                    "TimePicker",
                                                    "TimePicker",
                                                ]}
                                            >
                                                <TimePicker
                                                    name="subject_schedule_time"
                                                    label="Schedule Time"
                                                    value={value}
                                                    onChange={(newValue) => {
                                                        setValue(newValue);
                                                        formik.setFieldValue(
                                                            "subject_schedule_time",
                                                            dayjs(
                                                                newValue
                                                            ).format("HH:mm:00")
                                                        );
                                                    }}
                                                    sx={{ width: "100%" }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DemoContainer
                                                components={[
                                                    "DatePicker",
                                                    "DatePicker",
                                                ]}
                                            >
                                                <DatePicker
                                                    name="academic_year"
                                                    views={["year"]}
                                                    label="Year"
                                                    value={date}
                                                    onChange={(newValue) => {
                                                        setDate(newValue);
                                                        formik.setFieldValue(
                                                            "academic_year",
                                                            dayjs(
                                                                newValue
                                                            ).format("YYYY")
                                                        );
                                                    }}
                                                    sx={{ width: "100%" }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                    <Box>
                                        <TextField
                                            name="total_sessions"
                                            label="Total Sessions"
                                            disabled
                                            value={formik.values.total_sessions}
                                            fullWidth
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.total_sessions &&
                                                Boolean(
                                                    formik.errors.total_sessions
                                                )
                                            }
                                            helperText={
                                                formik.errors.total_sessions
                                            }
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SubmitButton type="submit">
                                                {loading
                                                    ? "Submitting..."
                                                    : "Submit"}
                                            </SubmitButton>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>
                        </Form>
                    </FormikProvider>
                </>
            }
        />
    );
};
