import React, { useState } from 'react'
import GlobalDialog from '../../mui/Dialogue/GlobalDialog'
import { AddButton, CancelButton, SubmitButton, UpdateButton } from '../../mui/Buttons/Buttons'
import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'
import GlobalSelectField from '../../mui/GlobalSelectField'
import dayjs from 'dayjs'
import DatePickerCustomFormik from '../../DatePicker/DatePickerCustomFormik'
import { dayjsToIsostring } from '../../../utils/constants/DateConvertions'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRequests, RaiseRequest, updateRaiseRequest } from '../../../redux/features/helpAndSupportSlice'
import { useSnackbar } from 'notistack'
import { savePaginationData } from '../../../redux/features/globalDatagridSlice'

export const requestFor = [
  { idNo: 1, valueNo: 'Access / Privilege in Portal' },
  { idNo: 2, valueNo: 'Data Extraction' },
  { idNo: 3, valueNo: 'Data Migration' },
  { idNo: 4, valueNo: 'Data Updation' },
  { idNo: 5, valueNo: 'Data Validation' },
  { idNo: 6, valueNo: 'Feature Addition' },
  { idNo: 7, valueNo: 'Fee per Session Change' },
  { idNo: 8, valueNo: 'Fee Transfer' },
  { idNo: 9, valueNo: 'Meeting with Tech Team' },
  { idNo: 10, valueNo: 'New Requirement ' },
  { idNo: 11, valueNo: 'Report Error' },
  { idNo: 12, valueNo: 'Student Transfer' },
  { idNo: 13, valueNo: 'TP related Issues' },
  { idNo: 14, valueNo: 'Others' },
]

const AddOrUpdateRequest = ({ row }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [day, setDay] = useState(dayjs())

  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const {loading } = useSelector((store) => store.helpAndSupport)
  const initialValues = {
    requestFor: row?.type || '',
    title: row?.title || '',
    content: row?.description || '',
    deliveryDate: row ? dayjs(row.deliveryDate) : day,
  }

  const schema = Yup.object({
    requestFor: Yup.number().required('Request For Required'),
    title: Yup.string().required('Title  Required'),
    content: Yup.string().required('Content required'),
    deliveryDate: Yup.date().required('Delivery Date required'),
  })

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const data = {
        type: values.requestFor,
        title: values.title,
        description: values.content,
        deliveryDate: dayjsToIsostring(values.deliveryDate),
      }
      if (row) {
        const updateData = {
          type: values.requestFor,
          title: values.title,
          description: values.content,
          ticketId: row.id,
          deliveryDate: dayjsToIsostring(values.deliveryDate),
        }
        dispatch(updateRaiseRequest(updateData)).then((res) => {
          if (res.payload.status === 'error') {
            enqueueSnackbar(res.payload.message, {
              variant: 'error',
            })
          } else if (res.payload.status === 'success') {
            setOpen(false)
            dispatch(savePaginationData({ ...paginationData, openAnchor: false }))
            dispatch(getAllRequests(paginationData))
            enqueueSnackbar(res.payload.message, {
              variant: 'success',
            })
          }
        })
      } else {
        dispatch(RaiseRequest(data)).then((res) => {
          if (res.payload.status === 'error') {
            enqueueSnackbar(res.payload.message, {
              variant: 'error',
            })
          } else if (res.payload.status === 'success') {
            setOpen(false)
            enqueueSnackbar(res.payload.message, {
              variant: 'success',
            })
            dispatch(getAllRequests(paginationData))
          }
        })
      }
    },
  })

  const handleCancel = () => {
    setOpen(false)
    formik.resetForm()
  }
  return (
    <GlobalDialog
      maxWidth={'md'}
      fullWidth
      title={'Raise Request'}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      closeCondition={handleCancel}
      popupButton={
        <>
          {row ? (
            <UpdateButton disabled={row.status !== 'pending'} size="small" action={() => setOpen(true)}>
              Update
            </UpdateButton>
          ) : (
            <AddButton
              size="large"
              action={() => {
                setOpen(true)
              }}>
              Raise Request
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Stack gap={1}>
                <GlobalSelectField
                  options={{
                    formik: formik,
                    label: 'Request For',
                    name: 'requestFor',
                    data: requestFor,
                    keys: {
                      id: 'idNo',
                      value: 'valueNo',
                    },
                    sx: {
                      width: '100%',
                    },
                  }}
                />

                <TextField
                  name="title"
                  fullWidth
                  type="text"
                  value={formik.values.title}
                  label="Title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />

                <TextField
                  fullWidth
                  id="content"
                  label="Content"
                  name="content"
                  multiline
                  rows={4}
                  value={formik.values.content}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.content && Boolean(formik.errors.content)}
                  helperText={formik.touched.content && formik.errors.content}
                />

                <DatePickerCustomFormik
                  fullWidth={true}
                  label={'Expected Delivery Date'}
                  viewDetails={['year', 'month', 'day']}
                  name={'deliveryDate'}
                  formik={formik}
                />
              </Stack>

              <Box sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Stack direction={'row'} spacing={1}>
                    <CancelButton action={handleCancel}>Cancel</CancelButton>
                    {row ? (
                      <UpdateButton disabled={loading} type="submit">{loading ? 'Updating...' : 'Update'}</UpdateButton>
                    ) : (
                      <SubmitButton disabled={loading} type="submit">{loading ? ' Please Wait' : 'Save'}</SubmitButton>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Form>
          </FormikProvider>
        </>
      }
    />
  )
}

export default AddOrUpdateRequest
