import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useResponsive from "../../Hooks/useResponsive";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    // marginBottom: theme.spacing(1),
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    // borderBottom: `1px solid ${theme.palette.divider}`,
    "& .MuiAccordionSummary-expandIcon": {
        color: theme.palette.primary.contrastText,
    },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));

export default function Accordian({ title, children, onClick, variant, icon }) {
    const mdUp = useResponsive("up", "md");
    const [padding, setPadding] = useState(0);
    useEffect(() => {
        if (mdUp) {
            setPadding(1);
        } else {
            setPadding(0);
        }
    }, [mdUp]);

    return (
        <Paper sx={{ padding: padding, marginY: 2 }}>
            <StyledAccordion>
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    onClick={onClick}
                >
                    <Box display="flex" alignItems="center">
                        {icon && icon}
                        <Typography variant={variant}>{title}</Typography>
                    </Box>
                </StyledAccordionSummary>
                <StyledAccordionDetails>{children}</StyledAccordionDetails>
            </StyledAccordion>
        </Paper>
    );
}
