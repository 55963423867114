import { Button, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BasicTextField from "../../../components/mui/TextField";

function CreateSessionTextBox({ setList, List, formik }) {
    const handleChange = (e, index) => {
        //
        const { value } = e.target;
        const list = [...formik.values.sessionContents];
        list[index].content = value;
        formik.setFieldValue("sessionContents", list);
        // setList(list);
    };
    const handleRemove = (index) => {
        const list = [...formik.values.sessionContents];
        list.splice(index, 1);
        formik.setFieldValue("sessionContents", list);
    };

    const handleAdd = () => {
        let temp = [...formik.values.sessionContents, { content: "" }];
        formik.setFieldValue("sessionContents", temp);
        // setList([...List, { content:"" }]);
    };

    return (
        // <form autoComplete="off">
        <div>
            {formik.values.sessionContents.map((single, index) => (
                <div key={index}>
                    <div>
                        <BasicTextField
                            action={(e) => handleChange(e, index)}
                            id="description"
                            label="Description"
                            value={single.content}
                            multiline
                            rows={4}
                            type="text"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ step: 300 }}
                            sx={{ width: "100%" }}
                        />
                    </div>
                    <div>
                        <Stack
                            direction={"row"}
                            spacing={2}
                            justifyContent="flex-end"
                            sx={{ my: 2 }}
                        >
                            {formik.values.sessionContents.length !== 1 && (
                                <Button
                                    onClick={() => handleRemove(index)}
                                    variant="contained"
                                    size="medium"
                                >
                                    <RemoveIcon />
                                </Button>
                            )}

                            {formik.values.sessionContents.length - 1 ===
                                index &&
                                formik.values.sessionContents.length < 4 && (
                                    <Button
                                        onClick={handleAdd}
                                        variant="contained"
                                        size="medium"
                                    >
                                        <AddIcon />
                                    </Button>
                                )}
                        </Stack>
                    </div>
                </div>
            ))}
        </div>
        // </form>
    );
}

export default CreateSessionTextBox;
