import {
  CreateUpdateObjectiveView,
  DeleteObjectiveView,
} from "../RenderCellMain";
import CreateUpdateObjective from "./CreateUpdateObjective";
import DeleteObjective from "./DeleteObjective";
import StatusSwitch from "./StatusSwitch";

export const OBJECTIVE_TABLE_HEAD = [
  {
    field: "serialNumber",
    headerName: "slNo",
  },
  {
    field: "objective_name",
    headerName: "Objective Name",
    width: 200,
  },
  {
    field: "matrix_name",
    headerName: "Matrix Name",
    width: 200,
  },
  {
    field: "level_name",
    headerName: "Level Name",
    width: 200,
  },
  {
    field: "objective_description",
    headerName: "Objective Description",
    width: 200,
  },
  {
    field: "objective_created_at",
    headerName: "Objective Created At",
    width: 200,
  },
  {
    field: "Status",
    headerName: "Status",
    width: 120,
    renderCell: (params) => <StatusSwitch params={params.row} />,
  },
  {
    field: "Update",
    headerName: "Update",
    width: 120,
    renderCell: (params) => <CreateUpdateObjectiveView params={params.row} />,
  },
  {
    field: "Delete",
    headerName: "Delete",
    width: 120,
    renderCell: (params) => (
      <DeleteObjectiveView
        id={params.row.objective_id}
        childFlag={params.row.has_child_element}
      />
    ),
  },
];
