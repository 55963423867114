import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { SubjectsListData } from "./SubjectsListData";
import { Button } from "@mui/material";

export const SubjectsList = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Subjects List"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                content={<SubjectsListData />}
                popupButton={
                    <Button variant="outlined" onClick={() => setOpen(true)}>
                        View
                    </Button>
                }
                closeButton={true}
            />
        </div>
    );
};
