import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
// components
import Iconify from "../../components/color-utils/Iconify";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
// import LanguagePopover from './LanguagePopover';
import NotificationsPopover from "./NotificationsPopover";
import { getUserPreData } from "../../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Rotate90DegreesCcw } from "@mui/icons-material";
import { Settings } from "../Settings";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 60;
const DRAWER_SM_WIDTH = 90;

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "openSideBar",
})(({ theme, openSideBar }) => ({
    boxShadow: "none",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    // backgroundColor: alpha(theme.palette.common.white, 0.72),
    backgroundColor: "white",
    [theme.breakpoints.up("lg")]: {
        width: openSideBar
            ? `calc(100% - ${DRAWER_WIDTH + 1}px)`
            : `calc(100% - ${DRAWER_SM_WIDTH + 1}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up("lg")]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({
    onOpenSidebar,
    user,
    setOpenSideBar,
    openSideBar,
}) {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.login);
    const loading = useSelector((state) => state.login.isLoading);

    // console.log(openSideBar, "sdfs==========================================");

    return (
        <RootStyle openSideBar={openSideBar}>
            <ToolbarStyle>
                <IconButton
                    onClick={() => setOpenSideBar(!openSideBar)}
                    sx={{
                        mr: 1,
                        color: "text.primary",
                        // display: { lg: "none" },
                    }}
                >
                    <div
                        style={{
                            transition: "transform .5s",
                        }}
                    >
                        <Icon
                            icon="line-md:chevron-small-double-right"
                            rotate={openSideBar === true ? "180deg" : ""}
                        />
                    </div>
                </IconButton>

                <Searchbar />
                <Box sx={{ flexGrow: 1 }} />

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{ xs: 5, sm: 4 }}
                >
                    {/* <LanguagePopover /> */}
                    <Settings />
                    <Box sx={{ paddingTop: 0.5 }}>
                        <NotificationsPopover />
                    </Box>

                    <AccountPopover
                        userDetails={userDetails}
                        user={user}
                        loading={loading}
                        setOpenSideBar={setOpenSideBar}
                    />
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
