import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Box, Typography } from "@mui/material";
import { CancelButton, DeleteButton } from "../../../../mui/Buttons/Buttons";

export const Delete = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                fullWidth
                maxWidth={"xs"}
                title="Delete"
                open={open}
                setOpen={setOpen}
                popupButton={
                    <DeleteButton
                        action={() => {
                            setOpen(true);
                        }}
                    />
                }
                content={
                    <Box>
                        <Typography variant="subtitle2">
                            Are you sure. You want to delete ?
                        </Typography>
                    </Box>
                }
                actionButton={
                    <>
                        <CancelButton
                            action={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </CancelButton>
                        <DeleteButton>Delete</DeleteButton>
                    </>
                }
            />
        </div>
    );
};
