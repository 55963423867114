import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../../views/user/Auth/Login/ForgotPassword";
import Login from "../../views/user/Auth/Login/Login";
import ResetPswrd from "../../views/user/Auth/Login/ResetPswrd";
import Page404 from "../../views/user/Auth/Page404";
import LandingMain from "../../views/user/Auth/Register/Landing/LandingMain";
import MailVerification from "../../views/user/Auth/Register/MailVerification/MailVerification";
import Register from "../../views/user/Auth/Register/Registraion";

const PublicRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/mailverification" element={<MailVerification/>} />
      <Route path="/landingpage" element={<LandingMain />} />
      <Route path="/forgott" element={<ForgotPassword />} />
      <Route path="/resetpswrd" element={<ResetPswrd />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default PublicRouter;
