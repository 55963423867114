import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import BasicTextField from "../../../components/mui/TextField";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import BasicButton from "../../../components/mui/Button";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    croEnrollSubjects,
    getEnrolledSubjects,
    getStudentCourseEnrollments,
    getSubjects,
} from "../../../redux/features/croSlice";
import Loader from "../../../components/Loader/Loader";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const StudentErollForm = () => {
    const dispatch = useDispatch();
    const { singleStudent, loading, subjects, subjectEnrolled } = useSelector(
        (state) => state.cro
    );
    const { cEid } = useParams();
    const [erolled, setEnrolled] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const ValidationSchema = Yup.object().shape({
        courseBatchId: Yup.number().required("Please select a course batch"),
        sessionsPerWeek: Yup.number().required("This feild is required"),
        feePerSession: Yup.number().required("This feild is required"),
        subjectIds: Yup.array()
            .min(1, "Select at least one preferred subject")
            .required("Select preferred subjects"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: ValidationSchema,
        initialValues: {
            courseEnrollmentId: singleStudent.courseEnrollmentId || "",
            courseBatchId:
                singleStudent.courseBatchId >= 0
                    ? singleStudent.courseBatchId
                    : "",
            sessionsPerWeek:
                singleStudent.sessionsPerWeek >= 0
                    ? singleStudent.sessionsPerWeek
                    : "",
            feePerSession:
                singleStudent.feePerSession >= 0
                    ? singleStudent.feePerSession
                    : "",
            subjectIds: [],
        },
        onSubmit: (values) => {
            console.log(values, "=-=-=-==-=-");
            dispatch(croEnrollSubjects(values)).then((res) => {
                console.log(res);
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    navigate("/my-tasks/cro-students");
                } else {
                    enqueueSnackbar(
                        "something went wrong please try again!!!",
                        {
                            variant: "error",
                        }
                    );
                }
            });
        },
    });

    const handleCheckBox = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        console.log(checked, value);
        if (checked) {
            formik.setFieldValue("subjectIds", [
                ...formik.values.subjectIds,
                value,
            ]);
        } else {
            formik.setFieldValue(
                "subjectIds",
                formik.values.subjectIds.filter((ids) => ids !== value)
            );
        }
    };

    const handleChange = (e) => {
        console.log(typeof e.target.value);
        formik.setFieldValue(e.target.name, parseInt(e.target.value));
    };

    useEffect(() => {
        console.log(cEid);
        dispatch(getStudentCourseEnrollments(cEid));
        dispatch(getSubjects());
    }, []);
    useEffect(() => {
        if (singleStudent.subjectsEnrolled?.length > 0)
            dispatch(getEnrolledSubjects(singleStudent.subjectsEnrolled));
    }, [singleStudent]);

    return loading ? (
        <Loader />
    ) : (
        <Page
            sx={{
                width: "85%",
                margin: "0 auto",
            }}
        >
            <Typography variant="h4" sx={{ mb: 1 }}>
                Student Enroll
            </Typography>
            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
            <Paper elevation={3} sx={{ borderRadius: 1, mt: 4 }}>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Stack
                            sx={{
                                padding: 5,
                                gap: 2,
                            }}
                        >
                            <BasicTextField
                                value={singleStudent.studentName || ""}
                                disabled
                                fullWidth
                                label="Student Name"
                            />
                            <BasicTextField
                                value={singleStudent.admnNo || ""}
                                disabled
                                fullWidth
                                label="Admission Number"
                            />
                            <BasicTextField
                                value={singleStudent.courseName || ""}
                                disabled
                                fullWidth
                                label="Course"
                            />

                            <GlobalSelectField
                                options={{
                                    name: "courseBatchId",
                                    formik: formik,
                                    label: "Batch",
                                    sx: {
                                        width: "100%",
                                    },
                                    data: singleStudent.batchList,
                                    keys: {
                                        id: "batchId",
                                        value: "batchName",
                                    },
                                }}
                            />

                            <FormControl
                                error={Boolean(formik.errors.subjectIds)}
                            >
                                <FormLabel>Select subject</FormLabel>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        width: "100%",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {subjects.map((item, i) => {
                                        return (
                                            <div
                                                key={i}
                                                style={{
                                                    width: "20%",
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            onChange={
                                                                handleCheckBox
                                                            }
                                                            disabled={subjectEnrolled.includes(
                                                                item.subjectId
                                                            )}
                                                            checked={
                                                                subjectEnrolled.includes(
                                                                    item.subjectId
                                                                ) ||
                                                                formik.values.subjectIds.includes(
                                                                    `${item.subjectId}`
                                                                )
                                                            }
                                                            value={
                                                                item.subjectId
                                                            }
                                                        />
                                                    }
                                                    label={item.subjectName}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <p
                                    style={{
                                        color: "red",
                                        fontSize: "14px",
                                    }}
                                >
                                    {formik.errors.subjectIds}
                                </p>
                            </FormControl>

                            <BasicTextField
                                name="sessionsPerWeek"
                                type="number"
                                value={formik.values.sessionsPerWeek}
                                fullWidth
                                label="Class per Week"
                                action={handleChange}
                            />
                            <BasicTextField
                                name="feePerSession"
                                value={formik.values.feePerSession}
                                type="number"
                                fullWidth
                                label="fee per session"
                                action={handleChange}
                            />

                            <Stack
                                flexDirection="row"
                                gap={2}
                                justifyContent="center"
                            >
                                <BasicButton
                                    variant="contained"
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                        background: "#73879c",
                                    }}
                                >
                                    Reset
                                </BasicButton>
                                <BasicButton
                                    variant="contained"
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                    }}
                                    type="submit"
                                >
                                    <Icon
                                        icon="ic:sharp-save"
                                        width="30"
                                        height="30"
                                    />
                                    Enroll
                                </BasicButton>
                            </Stack>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Paper>
        </Page>
    );
};

export default StudentErollForm;
