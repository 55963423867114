import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

export const getJobApplications = createAsyncThunk(
    "jobapplication/getJobApplications",
    async (date) => {
        const res = await apiRequest({
            method: "get",
            url: `job/getApplications/${date}`,
        });
        return formatDataForDataTable(res.data);
    }
);
export const getCvStatusPreData = createAsyncThunk(
    "jobapplication/getCvStatusPreData",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `pre-data/getRecruitmentStatus/${id}`,
        });
        return res;
    }
);

export const updateCvStatus = createAsyncThunk(
    "jobapplication/updateCvStatus",
    async (data) => {
        const res = await apiRequest({
            method: "put",
            url: `job/resumeScreening/${data.id}`,
            data: data,
        });
        return res;
    }
);

export const getSingleJobApplication = createAsyncThunk(
    "jobapplication/getSingleJobApplication",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `job/getApplication/${id}`,
        });
        return res;
    }
);

export const initialScreeningInterview = createAsyncThunk(
    "jobapplication/initialScreeningInterview",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/initialScreening/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const updateTestStatus = createAsyncThunk(
    "jobapplication/updateTestStatus",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/test/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const updateFirstInterviewStatus = createAsyncThunk(
    "jobapplication/updateFirstInterviewStatus",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/updateInterview1Status/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const getInterviewrs = createAsyncThunk(
    "jobapplication/getInterviewrs",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `pre-data/getHRInterviewers`,
        });
        return res;
    }
);

export const initialScreeningAssigning = createAsyncThunk(
    "jobapplication/initialScreeningAssigning",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/initialScreeningAssigning/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const firstInterviewAssigning = createAsyncThunk(
    "jobapplication/firstInterviewAssigning",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/scheduleInterview1/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const secondInterviewAssigning = createAsyncThunk(
    "jobapplication/secondInterviewAssigning",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/scheduleInterview2/${data.id}`,
            data: data,
        });
        return res;
    }
);

export const updateSecondInterviewStatus = createAsyncThunk(
    "jobapplication/updateSecondInterviewStatus",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/updateInterview2Status/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const updateThirdInterviewStatus = createAsyncThunk(
    "jobapplication/updateThirdInterviewStatus",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/updateInterview3Status/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const updateConfirmationStatus = createAsyncThunk(
    "jobapplication/updateConfirmationStatus",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/oralConfirmation/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const updateOfferLetterStatus = createAsyncThunk(
    "jobapplication/updateOfferLetterStatus",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/updateOfferLetterStatus/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const getUsers = createAsyncThunk(
    "jobapplication/getUsers",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `pre-data/getUsers`,
        });
        console.log(res);
        return res;
    }
);

export const assignThirdInterview = createAsyncThunk(
    "jobapplication/assignThirdInterview",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/scheduleInterview3/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const updateJoining = createAsyncThunk(
    "jobapplication/updateJoining",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `job/updateFinalStatus/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const timeLine = createAsyncThunk(
    "jobapplication/timeLine",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `job/recruitmentProcessProgress/${id}`,
        });
        return res;
    }
);

const jobApplicationSlice = createSlice({
    name: "jobapplication",
    initialState: {
        users: [],
        single: {},
        data: [],
        preData: [],
        interviewers: [],
        timeline: [],
        loading: false,
        error: "",
    },

    extraReducers: (builder) => {
        builder.addCase(getJobApplications.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getJobApplications.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(getJobApplications.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------getCvStatusPreData-------------------------------
        builder.addCase(getCvStatusPreData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getCvStatusPreData.fulfilled, (state, action) => {
            state.loading = false;
            state.preData = action.payload;
        });
        builder.addCase(getCvStatusPreData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------updateCvStatus------------------------------------------

        builder.addCase(updateCvStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateCvStatus.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateCvStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------------getSingleJobApplication------------------------------
        builder.addCase(getSingleJobApplication.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSingleJobApplication.fulfilled, (state, action) => {
            state.loading = false;
            state.single = action.payload.data;
        });
        builder.addCase(getSingleJobApplication.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------------------initialScreeningInterview----------------------------------
        builder.addCase(initialScreeningInterview.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            initialScreeningInterview.fulfilled,
            (state, action) => {
                state.loading = false;
            }
        );
        builder.addCase(initialScreeningInterview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------------------------------updateTestStatus---------------------------------
        builder.addCase(updateTestStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateTestStatus.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateTestStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------------------------------updateFirstInterviewStatus------------------------------
        builder.addCase(updateFirstInterviewStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            updateFirstInterviewStatus.fulfilled,
            (state, action) => {
                state.loading = false;
            }
        );
        builder.addCase(
            updateFirstInterviewStatus.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }
        );
        // ------------------------------------------getINterviewrs------------------------------------------
        builder.addCase(getInterviewrs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getInterviewrs.fulfilled, (state, action) => {
            state.loading = false;
            state.interviewers = action.payload.data;
        });
        builder.addCase(getInterviewrs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------------------------------initialScreeningAssigning---------------------------------
        builder.addCase(initialScreeningAssigning.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            initialScreeningAssigning.fulfilled,
            (state, action) => {
                state.loading = false;
            }
        );
        builder.addCase(initialScreeningAssigning.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -------------------------------------------------------------firstInterviewAssigning-----------------------------
        builder.addCase(firstInterviewAssigning.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(firstInterviewAssigning.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(firstInterviewAssigning.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------------------------------secondInterviewAssigning--------------------------------------------
        builder.addCase(secondInterviewAssigning.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(secondInterviewAssigning.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(secondInterviewAssigning.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------------------------------------------updateSecondInterviewStatus------------------------------
        builder.addCase(updateSecondInterviewStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            updateSecondInterviewStatus.fulfilled,
            (state, action) => {
                state.loading = false;
            }
        );
        builder.addCase(
            updateSecondInterviewStatus.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }
        );
        // --------------------------------------------updateThirdInterviewStatus-------------------------------------
        builder.addCase(updateThirdInterviewStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            updateThirdInterviewStatus.fulfilled,
            (state, action) => {
                state.loading = false;
            }
        );
        builder.addCase(
            updateThirdInterviewStatus.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }
        );
        // --------------------------------------updateConfirmationStatus---------------------------------------
        builder.addCase(updateConfirmationStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateConfirmationStatus.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateConfirmationStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------------------------updateOfferLetterStatus------------------------------------
        builder.addCase(updateOfferLetterStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateOfferLetterStatus.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateOfferLetterStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------------------------------getUsers------------------------------------------
        builder.addCase(getUsers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.users = action.payload.data;
        });
        builder.addCase(getUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------------------------assignThirdInterview----------------------------------------
        builder.addCase(assignThirdInterview.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(assignThirdInterview.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(assignThirdInterview.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------------------------updateJoining------------------------------------
        builder.addCase(updateJoining.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateJoining.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateJoining.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------------------------------------timeLine---------------------------------
        builder.addCase(timeLine.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(timeLine.fulfilled, (state, action) => {
            state.loading = false;
            state.timeline = action.payload.data;
        });
        builder.addCase(timeLine.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});

export default jobApplicationSlice.reducer;
