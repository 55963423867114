import React, { useState } from "react";
import GlobalDialog from "../../../../../components/mui/Dialogue/GlobalDialog";
import { AddButton } from "../../../../mui/Buttons/Buttons";
import AddExpenseForm from "./AddExpenseForm";

export const OgaAddExpense = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <GlobalDialog
        title="Add Expense"
        open={open}
        setOpen={setOpen}
        fullWidth
        maxWidth={"md"}
        closeButton={true}
        popupButton={
          <AddButton action={() => setOpen(true)}>Add Expense</AddButton>
        }
        content={<AddExpenseForm open={open} setOpen={setOpen} data={data} />}
      />
    </div>
  );
};
