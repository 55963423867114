import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { Box } from "@mui/material";
import ProfileHeader from "./ProfileHeader";
import StudentReport from "../StudentReport";
import { useDispatch, useSelector } from "react-redux";
import {
    getFoundationAssessmentData,
    getSpeakProAssessmentData,
} from "../../../../redux/features/assessmentToolSlice";
import React, { useEffect } from "react";
import { LogOutButton } from "../../../../components/mui/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import StudentReportSpeakPro from "../SpeakProReport/StudentReportSpeakPro";
import Loader3D from "../../../../components/Loader/Loader3D";

const ReportGeneration = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { foundationData, speakProData, loading } = useSelector(
        (state) => state.assessmentTool
    );
    const storedStudentData = JSON.parse(localStorage.getItem("studentData"));
    useEffect(() => {
        if (storedStudentData?.courseName === 1) {
            dispatch(getSpeakProAssessmentData());
        } else {
            dispatch(
                getFoundationAssessmentData(storedStudentData?.courseName)
            );
        }
    }, []);

    useEffect(() => {
        if (!storedStudentData) {
            navigate("/assessment-tool/student-registration");
        }
    }, [storedStudentData]);

    return (
        <>
            <GlobalWrapper
                title="Report Generation"
                actions={
                    <LogOutButton
                        action={() => {
                            localStorage.removeItem("studentData");
                            navigate("/assessment-tool/student-registration");
                        }}
                    >
                        Clear Student
                    </LogOutButton>
                }
            >
                <Box>
                    <ProfileHeader />
                </Box>
                {loading ? (
                    <Loader3D />
                ) : (
                    <Box>
                        {storedStudentData.courseName === 1 ? (
                            <StudentReportSpeakPro data={speakProData} />
                        ) : (
                            <StudentReport data={foundationData} />
                        )}
                    </Box>
                )}
            </GlobalWrapper>
        </>
    );
};

export default ReportGeneration;
