import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { CancelButton, SubmitButton } from "../../../mui/Buttons/Buttons";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
import {
    cancelSession,
    getSessionCancellationReasons,
} from "../../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import GlobalSelectField from "../../../mui/GlobalSelectField";

export const DayWiseSessionActionsMenu = React.memo(({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.cro);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const Validation = Yup.object().shape({
        reasonId: Yup.string().required("reason is required"),
        remarks: Yup.string().required("remark is required"),
    });

    const formik = useFormik({
        initialValues: {
            reasonId: "",
            remarks: "",
            scheduleId: params,
        },
        validationSchema: Validation,
        onSubmit: (values) => {
            dispatch(cancelSession(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <Icon icon="charm:menu-kebab" />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    onClick={() => {
                        dispatch(getSessionCancellationReasons());
                        setOpen(true);
                        handleClose();
                    }}
                >
                    <Icon
                        icon="fluent:calendar-cancel-16-regular"
                        width="22"
                        height="22"
                    />
                    Cancel Session
                </MenuItem>
            </Menu>
            <GlobalDialog
                title="Cancel Session"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form
                                autoComplete="off"
                                noValidate
                                onSubmit={formik.handleSubmit}
                            >
                                <Box>
                                    <GlobalSelectField
                                        options={{
                                            name: "reasonId",
                                            formik,
                                            sx: {
                                                width: "100%",
                                                "& .MuiInputBase-root": {
                                                    height: 50,
                                                },
                                            },
                                            label: "Reason",
                                            data: data.sessionCancellationReasons,
                                            keys: {
                                                id: "id",
                                                value: "name",
                                            },
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        label="Remarks"
                                        fullWidth
                                        name="remarks"
                                        onChange={formik.handleChange}
                                        value={formik.values.remarks}
                                        multiline
                                        rows={3}
                                        error={Boolean(
                                            formik.touched.remarks &&
                                                formik.errors.remarks
                                        )}
                                        helperText={
                                            formik.touched.remarks &&
                                            formik.errors.remarks
                                        }
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        marginTop: 3,
                                    }}
                                >
                                    <Stack direction={"row"} spacing={2}>
                                        <CancelButton
                                            size="small"
                                            action={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </CancelButton>
                                        <SubmitButton
                                            type="submit"
                                            size="small"
                                        >
                                            {data.loading
                                                ? "Canceling..."
                                                : "Cancel Session"}
                                        </SubmitButton>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
});
