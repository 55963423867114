// import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import React from "react";
// import images from "./head.png";
// import Iconify from "../../../../components/Iconify";

// const col = [
//     // { field: "id", headerName: "ID", width: 90 },
//     {
//         field: "description",
//         headerName: "Description",
//         headerClassName: "super-app-theme--header",
//         width: 150,
//     },
//     {
//         field: "courseFee",
//         headerName: "Course Fee",
//         type: "number",
//         width: 100,
//         headerClassName: "super-app-theme--header",
//     },
//     {
//         field: "discount",
//         headerName: "Discount",
//         type: "number",
//         width: 100,
//         headerClassName: "super-app-theme--header",
//     },
//     {
//         field: "Fee",
//         headerName: "Fee",
//         type: "number",
//         width: 100,
//         headerClassName: "super-app-theme--header",
//     },
//     {
//         field: "cgst",
//         headerName: "CGST",
//         type: "number",
//         width: 100,
//         headerClassName: "super-app-theme--header",
//     },
//     {
//         field: "sgst",
//         headerName: "SGST",
//         type: "number",
//         width: 110,
//         headerClassName: "super-app-theme--header",
//     },
//     {
//         field: "total",
//         headerName: "Total",
//         type: "number",
//         width: 106,
//         headerClassName: "super-app-theme--header",
//     },
// ];

// const rows = [
//     {
//         id: 1,
//         description: "Admission Fee",
//         courseFee: 453,
//         discount: 35,
//         Fee: 2000,
//         cgst: 46,
//         sgst: 77,
//         total: 1000,
//     },
//     {
//         id: 2,
//         description: "Foundation Course - English",
//         courseFee: 444,
//         discount: 35,
//         Fee: 2000,
//         cgst: 46,
//         sgst: 77,
//         total: 1000,
//     },
// ];

// const Print = () => {
//     let printContents = document.getElementById("printablediv").innerHTML;
//     let originalContents = document.body.innerHTML;
//     document.body.innerHTML = printContents;
//     window.print();
//     document.body.innerHTML = originalContents;
// };

// export const Invoice = () => {
//     const [status, setStatus] = React.useState("connected");
//     return (
//         <>
//             <Box
//                 sx={{ display: "flex", justifyContent: "flex-end", padding: 3 }}
//             >
//                 <Button
//                     color="info"
//                     size="small"
//                     variant="contained"
//                     onClick={() => Print()}
//                     endIcon={<Iconify icon={"eva:eye-fill"} />}
//                     sx={{ mx: 1 }}
//                 >
//                     Preview
//                 </Button>
//             </Box>
//             <div>
//                 <Box
//                     id="printablediv"
//                     sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         paddingTop: "5",
//                     }}
//                 >
//                     <Paper elevation={3} sx={{ width: 800, height: 1120 }}>
//                         <Box
//                             sx={{
//                                 backgroundImage: `url(${images}) !important `,
//                                 backgroundRepeat: "no-repeat",
//                                 height: "270px",
//                                 width: "100%",
//                                 backgroundSize: `cover`,
//                                 padding: 5,
//                                 zIndex: 0,

//                                 WebkitPrintColorAdjust: "exact",
//                                 printColorAdjust: "exact",
//                             }}
//                         >
//                             <Box
//                                 sx={{
//                                     height: 50,
//                                     width: 250,
//                                     borderRadius: 3,
//                                     backgroundColor: "white",
//                                     display: "flex",
//                                     justifyContent: "center",
//                                     paddingTop: 0.5,
//                                 }}
//                             >
//                                 <img
//                                     height="45"
//                                     src="https://www.teaminterval.ae/wp-content/uploads/2022/03/interval-logo.png"
//                                     alt="header"
//                                 />
//                             </Box>
//                             <Box
//                                 sx={{
//                                     display: "flex",
//                                     justifyContent: "flex-end",
//                                     paddingRight: 5,
//                                     paddingTop: 13,
//                                 }}
//                             >
//                                 <Stack direction={"column"}>
//                                     <Typography
//                                         variant="h2"
//                                         sx={{
//                                             fontFamily: "sans - serif",
//                                             color: "grey.500",
//                                         }}
//                                     >
//                                         INVOICE
//                                     </Typography>
//                                     <Typography
//                                         paddingLeft={1}
//                                         fontWeight={"bolder"}
//                                         fontSize="8"
//                                     >
//                                         Invoice No : 3744
//                                     </Typography>
//                                     <Typography
//                                         paddingLeft={1}
//                                         fontWeight={"bolder"}
//                                         fontSize="8"
//                                     >
//                                         Date : 30-04-2023
//                                     </Typography>
//                                 </Stack>
//                             </Box>
//                         </Box>
//                         <Box sx={{ padding: 3 }}>
//                             <Typography variant="h6" color="#008fc3">
//                                 STUDENT DETAILS
//                             </Typography>
//                             <Divider sx={{ borderBottomWidth: 2 }} />
//                             <Typography>Name : ARDRAV ARUN</Typography>
//                             <Typography>Place : 16TH KANDAM</Typography>
//                             <Typography> Class : LKG</Typography>
//                             <Typography>OGA : AMAYA R P</Typography>
//                             <Typography>
//                                 Course : Little Genie - Three Months
//                             </Typography>
//                         </Box>

//                         <Box padding={2}>
//                             <Box
//                                 sx={{
//                                     width: "100%",
//                                     WebkitPrintColorAdjust: "exact",
//                                     printColorAdjust: "exact",

//                                     "& .super-app-theme--header": {
//                                         backgroundColor: "#008fc3",
//                                         color: "white",
//                                     },
//                                 }}
//                             >
//                                 <DataGrid
//                                     rows={rows}
//                                     columns={col}
//                                     autoHeight={true}
//                                 />
//                             </Box>
//                             <Divider
//                                 sx={{
//                                     borderBottomWidth: 2.5,
//                                     variant: "middle",
//                                     marginTop: 2,
//                                 }}
//                             />
//                         </Box>
//                         <Box>
//                             <Stack direction={"row"} spacing={42}>
//                                 <Box sx={{ width: 250, paddingLeft: 3 }}>
//                                     <Typography sx={{ textAlign: "justify" }}>
//                                         NOTE:- sum is simply dummy text of the
//                                         printing and typesetting industry. Lorem
//                                         Ipsum has been the industry's
//                                     </Typography>
//                                 </Box>
//                                 <Box
//                                     sx={{
//                                         display: "flex",
//                                         justifyContent: "flex-end",
//                                         paddingRight: 2,
//                                     }}
//                                 >
//                                     <Stack direction={"column"}>
//                                         <Typography>
//                                             GRAND TOTAL : ₹ 8,500.00
//                                         </Typography>
//                                         <Typography>
//                                             ADVANCE : ₹ 8,000.00
//                                         </Typography>
//                                         <Typography>
//                                             BALANCE : ₹ 500.00
//                                         </Typography>
//                                     </Stack>
//                                 </Box>
//                             </Stack>
//                         </Box>
//                         <Box paddingLeft={3} paddingTop={2}>
//                             <Box>
//                                 <img
//                                     src="https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg"
//                                     alt="qrcode"
//                                     height={100}
//                                     width={100}
//                                 />
//                             </Box>
//                             <Box>
//                                 <Stack direction={"column"}>
//                                     <Typography variant="caption">
//                                         GSTIN : 32ABFCS2058H1ZI
//                                     </Typography>
//                                     <Typography variant="caption">
//                                         SAC CODE : 9992
//                                     </Typography>
//                                     <Typography variant="caption">
//                                         SANCO INDIA EDU SOLUTIONS PRIVATE
//                                         LIMITED
//                                     </Typography>
//                                 </Stack>
//                             </Box>
//                         </Box>
//                         <Box
//                             sx={{
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 marginTop: 5,
//                             }}
//                         >
//                             <Box
//                                 sx={{
//                                     WebkitPrintColorAdjust: "exact",
//                                     printColorAdjust: "exact",
//                                     backgroundColor: "#004487",
//                                     width: "90%",
//                                     height: 40,
//                                     display: "flex",
//                                     justifyContent: "center",
//                                     borderRadius: 1,
//                                 }}
//                             >
//                                 <Typography
//                                     color="white"
//                                     padding={1.5}
//                                     variant="caption"
//                                 >
//                                     7th Floor, HiLITE Business Park, NH66,
//                                     Thondayad Bypass, Kozhikode 673014
//                                 </Typography>
//                             </Box>
//                         </Box>
//                     </Paper>
//                 </Box>
//             </div>
//         </>
//     );
// };

import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { InvoicePrint } from "./InvoicePrint"; // Import the print component

export const Invoice = () => {
    const [printMode, setPrintMode] = useState(false);

    const handlePrint = () => {
        setPrintMode(true);
        setTimeout(() => {
            let originalContents = document.body.innerHTML;

            // Get the contents of the printable div
            let printContents =
                document.getElementById("printablediv").innerHTML;

            // Replace the body contents with the printable div contents
            document.body.innerHTML = printContents;

            // Try to print
            try {
                window.print();
            } catch (error) {
                console.error("Error occurred while printing:", error);
            } finally {
                // Restore the original contents of the body
                document.body.innerHTML = originalContents;
            }
        }, 100); // Delay printing to ensure the component is fully rendered
    };

    return (
        <>
            <Box
                sx={{ display: "flex", justifyContent: "flex-end", padding: 3 }}
            >
                <Button
                    color="info"
                    size="small"
                    variant="contained"
                    onClick={handlePrint}
                    disabled={printMode} // Disable button during printing
                    sx={{ mx: 1 }}
                >
                    Preview
                </Button>
            </Box>
            <div>
                {!printMode && (
                    <div>
                        {/* Your invoice content */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "5",
                            }}
                        >
                            <InvoicePrint />
                        </Box>
                    </div>
                )}
                {printMode && <InvoicePrint />}
            </div>
        </>
    );
};
