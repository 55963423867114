import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Box, Button, Checkbox, Paper, Stack } from "@mui/material";
import { EditButton, SaveButton } from "../../../../mui/Buttons/Buttons";
import {
    getSessionsForBaseCourses,
    getStudentAcademicSessions,
} from "../../../../../redux/features/researchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SimpleLoader from "../../../../Loader/SimpleLoader";
import { EditAcademicSessions } from "./EditAcademicSessions";
import NoDataFound from "../../../../NodataIndication/NoDataFound";
import { DeleteIndividualAcademicSessions } from "./DeleteIndividualAcademicSession";
import { AddStudentAcademicSchedules } from "./AddStudentAcademicSchedules";
export const AcademicSessionsPopup = ({ params }) => {
    const dispatch = useDispatch();





    
    const { loading, subjectEnrollmentDetailedId, studentAcademicSessions } =
        useSelector((state) => state.research);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (studentAcademicSessions) {
            const updatedData = studentAcademicSessions?.map((item) => ({
                ...item,
                checked: false,
            }));
            setData(updatedData);
        }
    }, [studentAcademicSessions]);

    useEffect(() => {
        if (open === true) {
            dispatch(getStudentAcademicSessions(subjectEnrollmentDetailedId));
            dispatch(getSessionsForBaseCourses(params.baseCourseId));
        }
    }, [open]);
    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                title="Academic Sessions"
                closeButton={true}
                popupButton={
                    <>
                        {data.length === 0 ? (
                            <Button
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                View
                            </Button>
                        ) : (
                            <EditButton
                                size="small"
                                action={() => {
                                    setOpen(true);
                                }}
                            >
                                Edit
                            </EditButton>
                        )}
                    </>
                }
                content={
                    <>
                        {data.length === 0 ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    pb: 2,
                                }}
                            >
                                <AddStudentAcademicSchedules />
                            </Box>
                        ) : null}

                        {loading ? (
                            <SimpleLoader />
                        ) : (
                            <>
                                {data.length === 0 ? (
                                    <NoDataFound />
                                ) : (
                                    <Box
                                        sx={{
                                            backgroundColor: "grey.200",
                                            padding: 3,
                                            borderRadius: 1,
                                        }}
                                    >
                                        {data.map((item, i) => {
                                            return (
                                                <>
                                                    <Stack
                                                        direction={"row"}
                                                        spacing={2}
                                                        mt={1}
                                                        key={item.sessionSrlNo}
                                                    >
                                                        <Paper
                                                            sx={{
                                                                height: 30,
                                                                width: 30,
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                padding: 3,
                                                            }}
                                                        >
                                                            {item.sessionSrlNo}
                                                        </Paper>
                                                        <Paper
                                                            sx={{
                                                                minHeight: 30,
                                                                width: 400,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                padding: 3,
                                                            }}
                                                        >
                                                            {
                                                                item.sessionContent
                                                            }
                                                        </Paper>
                                                        <Paper
                                                            sx={{
                                                                height: 30,
                                                                width: 80,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                padding: 3,
                                                            }}
                                                        >
                                                            {
                                                                item.sessionModuleNo
                                                            }
                                                        </Paper>
                                                        <Box pt={1}>
                                                            <EditAcademicSessions
                                                                data={item}
                                                            />
                                                        </Box>
                                                        <Box pt={1}>
                                                            <DeleteIndividualAcademicSessions
                                                                data={
                                                                    item.studentAcademicSchedulesId
                                                                }
                                                            />
                                                        </Box>
                                                    </Stack>
                                                </>
                                            );
                                        })}
                                    </Box>
                                )}
                            </>
                        )}
                    </>
                }
            />
        </div>
    );
};
