import { React, useEffect } from "react";
import {
    Container,
    Button,
    Typography,
    Box,
    Stack,
    TextField,
    Paper,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Skeleton,
    Switch,
    FormControlLabel,
    FormHelperText,
    Autocomplete,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Page from "../../../components/Page";
import { useFormik, Form, FormikProvider } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { fetchStudents } from "../../../redux/features/studentsSlice";
import { fetchCoursesOfStudent } from "../../../redux/features/coursesSlice";
import { fetchModulesOfCourse } from "../../../redux/features/modulesSlice";
import {
    clearRes,
    scheduleSession,
    clearSchedule,
    getLastSessionDate,
} from "../../../redux/features/scheduleSlice";
import Iconify from "../../../components/Iconify";
import { useState } from "react";
import { useSnackbar } from "notistack";
import CreateSessionTextBox from "./CreateSessionTextBox";
import Tag from "../../../components/mui/ChipAutocomplete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import * as Yup from "yup";
import { number } from "prop-types";
import TextAutoComplete from "../../../components/mui/TextAutocomplete";
import BasicButton from "../../../components/mui/Button";
import useResponsive from "../../../../src/Hooks/useResponsive";
import BasicDatePicker from "../../../components/mui/DatePicker";
import BasicAutocomplete from "../../../components/mui/Select";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import CustomSelectField from "../../../components/mui/CustomSelectField";
import Loader from "../../../components/Loader/Loader";

export default function Schedule() {
    // can't set loader from state due to multiple api calls hence setted it inside the page
    const [loading, setLoading] = useState(false);

    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { students, courses, modules, schedule } = useSelector(
        (state) => state
    );

    let DateToString = (date) => {
        return date.toISOString().split("T")[0];
    };

    //Local States
    const [defaultMails, setDefaultMails] = useState([]);
    const [List, setList] = useState([{ content: "" }]);
    const [lastSessionDate, setLastSessionDate] = useState(
        DateToString(new Date())
    );
    const [lastDate, setLastDate] = useState(DateToString(new Date()));

    const formatDate = (date) => {
        return new Date(date).toISOString().split("T")[0];
    };
    const CreateSchema = Yup.object().shape({
        student: Yup.number("The selected student is invalid")
            .positive("Please select a student")
            .required("Please select a student")
            .integer(),
        course: Yup.string().required("Please select a course"),
        moduleId: Yup.string().required("Please select a module"),
    });
    const formik = useFormik({
        initialValues: {
            student: "",
            course: "",
            subjectEnrollmentId: "",
            moduleId: "",
            startDate: null,
            sessionContents: [{ content: "" }],
        },
        validationSchema: CreateSchema,
        onSubmit: (values, { resetForm }) => {
            setLoading(true);

            let formatedDate = new Date(values.startDate)
                .toISOString()
                .split("T")[0];
            values = {
                ...values,
                startDate: formatedDate,
            };

            dispatch(scheduleSession(values)).then((res) => {
                enqueueSnackbar(res.payload.message, { variant: "success" });
                if (res.payload.status == 200) {
                    setLoading(false);
                    resetForm();
                    return;
                } else {
                    setLoading(false);
                }
                // return res.payload.status === "success" ? resetForm() : null;
            });
        },
    });
    useEffect(() => {
        //---info---load students data in student selectbox on page load
        dispatch(fetchStudents());
        return () => {
            dispatch(clearRes());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loading ? (
        <Loader />
    ) : (
        <Page title="Create Additional Session">
            {smUp && (
                <Container component="main">
                    {/*..................................HEADING.............................*/}
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Create Additional Session
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: { md: 4, xs: 2 },
                        }}
                    >
                        <Paper elevation={4} sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Stack
                                        sx={{ p: { md: 8, xs: 4 } }}
                                        spacing={3}
                                    >
                                        <Stack
                                            direction={"row"}
                                            spacing={3}
                                            justifyContent="center"
                                        >
                                            {/* .........................STUDENT SELECTBOX............................ */}
                                            {students.loading ? (
                                                <Skeleton
                                                    height={56}
                                                    variant="cercular"
                                                    sx={{
                                                        flex: 1,
                                                        minWidth: 160,
                                                        borderRadius: 1,
                                                    }}
                                                    size="medium"
                                                />
                                            ) : (
                                                <FormControl
                                                    sx={{
                                                        minWidth: 160,
                                                        flex: 1,
                                                    }}
                                                    size="medium"
                                                    error={
                                                        formik.touched
                                                            .student &&
                                                        Boolean(
                                                            formik.errors
                                                                .student
                                                        )
                                                    }
                                                >
                                                    <BasicAutocomplete
                                                        sx={{ width: "100%" }}
                                                        name="student"
                                                        options={students.data}
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option.student +
                                                            " (" +
                                                            option.admissionNumber +
                                                            ")"
                                                        }
                                                        action={(e) => {
                                                            formik.setFieldValue(
                                                                "student",
                                                                e.target.value
                                                            );
                                                            dispatch(
                                                                fetchCoursesOfStudent(
                                                                    e.target
                                                                        .value
                                                                )
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Student"
                                                                error={
                                                                    formik
                                                                        .errors
                                                                        .student
                                                                }
                                                            />
                                                        )}
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => {
                                                            return (
                                                                <li
                                                                    {...props}
                                                                    value={
                                                                        option.admissionNumber
                                                                    }
                                                                    key={
                                                                        option.admissionNumber
                                                                    }
                                                                >
                                                                    {
                                                                        option.student
                                                                    }{" "}
                                                                    : (
                                                                    {
                                                                        option.admissionNumber
                                                                    }
                                                                    )
                                                                </li>
                                                            );
                                                        }}
                                                    />
                                                    <FormHelperText id="course-helper">
                                                        {formik.errors.student}
                                                    </FormHelperText>
                                                </FormControl>
                                            )}
                                            {/*--------------------------COURSE SELECTBOX-----------------------------*/}
                                            {courses.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    height={56}
                                                    sx={{
                                                        minWidth: 160,
                                                        flex: 1,
                                                        borderRadius: 1,
                                                    }}
                                                    size="medium"
                                                />
                                            ) : (
                                                // can't be changed to global
                                                <CustomSelectField
                                                    options={{
                                                        label: "Course",
                                                        formik: formik,
                                                        name: "course",
                                                        data: courses.data,
                                                        setLastSessionDate:
                                                            setLastSessionDate,
                                                        setLastDate:
                                                            setLastDate,
                                                    }}
                                                />
                                            )}

                                            {/* -------------------------MODULE SELECTBOX----------------------------- */}
                                            {modules.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    height={56}
                                                    sx={{
                                                        minWidth: 160,
                                                        flex: 1,
                                                        borderRadius: 1,
                                                    }}
                                                    size="medium"
                                                />
                                            ) : (
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Module",
                                                        formik: formik,
                                                        name: "moduleId",
                                                        data: modules.data,
                                                        keys: {
                                                            id: "courses_master_modules_id",
                                                            value: "module_num",
                                                        },
                                                        sx: {
                                                            height: 56,
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                        {/* ------------------------------DATE-PICKER----------------------------- */}
                                        <Stack direction={"row"} spacing={3}>
                                            <FormControl
                                                sx={{ width: "100%", flex: 1 }}
                                                error={
                                                    formik.touched.startDate &&
                                                    Boolean(
                                                        formik.errors.startDate
                                                    )
                                                }
                                            >
                                                <BasicDatePicker
                                                    readOnly={
                                                        lastSessionDate >
                                                        new Date(
                                                            new Date().toDateString()
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    disablePast
                                                    shouldDisableDate={(
                                                        date
                                                    ) => {
                                                        if (date < lastDate) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    }}
                                                    label="Start Date"
                                                    views={[
                                                        "year",
                                                        "month",
                                                        "day",
                                                    ]}
                                                    value={
                                                        formik.values.startDate
                                                    }
                                                    name="startDate"
                                                    format="YYYY-MM-DD"
                                                    action={(e) => {
                                                        formik.setFieldValue(
                                                            "startDate",
                                                            e
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            sx={{
                                                                width: "32%",
                                                                flex: 1,
                                                            }}
                                                            {...params}
                                                            error={
                                                                formik.touched
                                                                    .startDate &&
                                                                Boolean(
                                                                    formik
                                                                        .errors
                                                                        .startDate
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    error={
                                                        formik.touched
                                                            .startDate &&
                                                        Boolean(
                                                            formik.errors
                                                                .startDate
                                                        )
                                                    }
                                                />

                                                <FormHelperText id="course-helper">
                                                    {formik.errors.startDate}
                                                </FormHelperText>
                                            </FormControl>
                                            {/* --------------------------------------------------------- */}
                                        </Stack>
                                        {/* --------------------------------------------------------------- */}

                                        <CreateSessionTextBox
                                            formik={formik}
                                            sx={{ mb: 2 }}
                                            setList={setList}
                                            List={List}
                                        />
                                        <Box
                                            sx={{
                                                paddingTop: 5,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <BasicButton
                                                variant="outlined"
                                                size="large"
                                            >
                                                Reset
                                            </BasicButton>

                                            <BasicButton
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                                value="Save"
                                                sx={{ marginLeft: 2 }}
                                            >
                                                Save
                                            </BasicButton>
                                        </Box>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    </Box>
                </Container>
            )}
            {!smUp && (
                <>
                    {/*..................................HEADING.............................*/}
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Create Additional Session
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: { md: 4, xs: 2 },
                        }}
                    >
                        <Paper elevation={4} sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Stack
                                        sx={{ p: { md: 8, xs: 4 } }}
                                        spacing={3}
                                    >
                                        <Stack direction={"column"} spacing={3}>
                                            {/* .........................STUDENT SELECTBOX............................ */}
                                            {students.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    sx={{
                                                        width: "100%",
                                                        height: 56,
                                                        borderRadius: 1,
                                                    }}
                                                />
                                            ) : (
                                                <FormControl
                                                    sx={{ width: "100%" }}
                                                    error={
                                                        formik.touched
                                                            .student &&
                                                        Boolean(
                                                            formik.errors
                                                                .student
                                                        )
                                                    }
                                                >
                                                    <BasicAutocomplete
                                                        sx={{ width: "100%" }}
                                                        name="student"
                                                        options={students.data}
                                                        getOptionLabel={(
                                                            option
                                                        ) =>
                                                            option.student +
                                                            " (" +
                                                            option.admissionNumber +
                                                            ")"
                                                        }
                                                        action={(e) => {
                                                            formik.setFieldValue(
                                                                "student",
                                                                e.target.value
                                                            );
                                                            dispatch(
                                                                fetchCoursesOfStudent(
                                                                    e.target
                                                                        .value
                                                                )
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                label="Student"
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        .student &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .student
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        renderOption={(
                                                            props,
                                                            option
                                                        ) => {
                                                            return (
                                                                <li
                                                                    {...props}
                                                                    value={
                                                                        option.admissionNumber
                                                                    }
                                                                    key={
                                                                        option.admissionNumber
                                                                    }
                                                                >
                                                                    {
                                                                        option.student
                                                                    }{" "}
                                                                    : (
                                                                    {
                                                                        option.admissionNumber
                                                                    }
                                                                    )
                                                                </li>
                                                            );
                                                        }}
                                                    />
                                                    <FormHelperText id="course-helper">
                                                        {formik.errors.student}
                                                    </FormHelperText>
                                                </FormControl>
                                            )}
                                            {/*--------------------------COURSE SELECTBOX-----------------------------*/}
                                            {courses.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    height={56}
                                                    sx={{
                                                        m: 1,
                                                        minWidth: 160,
                                                        flex: 1,
                                                        borderRadius: 1,
                                                    }}
                                                    size="medium"
                                                />
                                            ) : (
                                                <CustomSelectField
                                                    options={{
                                                        label: "Course",
                                                        formik: formik,
                                                        name: "course",
                                                        data: courses.data,
                                                        setLastSessionDate:
                                                            setLastSessionDate,
                                                        setLastDate:
                                                            setLastDate,
                                                    }}
                                                />
                                            )}
                                            {/* -------------------------MODULE SELECTBOX----------------------------- */}
                                            {modules.loading ? (
                                                <Skeleton
                                                    variant="rectangular"
                                                    height={56}
                                                    sx={{
                                                        m: 1,
                                                        minWidth: 160,
                                                        flex: 1,
                                                        borderRadius: 1,
                                                    }}
                                                    size="medium"
                                                />
                                            ) : (
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Module",
                                                        formik: formik,
                                                        name: "moduleId",
                                                        data: modules.data,
                                                        keys: {
                                                            id: "courses_master_modules_id",
                                                            value: "module_num",
                                                        },
                                                    }}
                                                />
                                            )}
                                        </Stack>
                                        {/* ------------------------------DATE-PICKER----------------------------- */}
                                        <Stack direction={"column"} spacing={3}>
                                            <FormControl
                                                sx={{ width: "100%", flex: 1 }}
                                                error={
                                                    formik.touched.startDate &&
                                                    Boolean(
                                                        formik.errors.startDate
                                                    )
                                                }
                                            >
                                                <BasicDatePicker
                                                    readOnly={
                                                        lastSessionDate <
                                                        new Date(
                                                            new Date().toDateString()
                                                        )
                                                            ? true
                                                            : false
                                                    }
                                                    disablePast
                                                    shouldDisableDate={(
                                                        date
                                                    ) => {
                                                        if (date < lastDate) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    }}
                                                    label="Start Date"
                                                    views={[
                                                        "year",
                                                        "month",
                                                        "day",
                                                    ]}
                                                    value={
                                                        formik.values.startDate
                                                    }
                                                    name="startDate"
                                                    format="YYYY-MM-DD"
                                                    action={(e) => {
                                                        formik.setFieldValue(
                                                            "startDate",
                                                            e
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={
                                                                formik.touched
                                                                    .startDate &&
                                                                Boolean(
                                                                    formik
                                                                        .errors
                                                                        .startDate
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    error={
                                                        formik.touched
                                                            .startDate &&
                                                        Boolean(
                                                            formik.errors
                                                                .startDate
                                                        )
                                                    }
                                                />

                                                <FormHelperText id="course-helper">
                                                    {formik.errors.startDate}
                                                </FormHelperText>
                                            </FormControl>
                                            {/* ------------------------------------------------------ */}
                                        </Stack>
                                        {/* -------------------------------------------------------------- */}

                                        {/* <Tag formik={formik} sx={{mb:2}} /> */}
                                        <CreateSessionTextBox
                                            formik={formik}
                                            sx={{ mb: 2 }}
                                            setList={setList}
                                            List={List}
                                        />
                                        <Box
                                            sx={{
                                                paddingTop: 5,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <BasicButton
                                                variant="outlined"
                                                size="large"
                                            >
                                                Reset
                                            </BasicButton>

                                            <BasicButton
                                                variant="contained"
                                                size="large"
                                                type="submit"
                                                value="Save"
                                                sx={{ marginLeft: 2 }}
                                            >
                                                Save
                                            </BasicButton>
                                        </Box>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    </Box>
                </>
            )}
        </Page>
    );
}
