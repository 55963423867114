import {
    Box,
    Button,
    TextField,
    Paper,
    MenuItem,
    Stack,
    InputLabel,
    FormControl,
    Select,
} from "@mui/material";
import React from "react";
import Page from "../../../components/Page";
import { Icon } from "@iconify/react";
import { Divider } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { LoadingButton } from "@mui/lab";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
import useResponsive from "../../../../src/Hooks/useResponsive";
import { getPreData, addNewRole } from "../../../redux/features/userSlice";
import { getAllRoles } from "../../../redux/features/rolesSlice";
import { DESIGNATION_TABLE_HEAD as columns } from "../../../components/datagrid/Roles/RolesTableHead";
import * as Yup from "yup";
import FormHelperText from "@mui/material/FormHelperText";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../../../components/Wrapper/Wrapper";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export default function Designation() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const [gridSize, setGridSize] = useState(5);
    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const preData = useSelector((state) => state.users.preData);
    const loading = useSelector((state) => state.users.loading);
    const roles = useSelector((state) => state.roles);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPreData());
        dispatch(getAllRoles());
    }, []);

    const CreateSchema = Yup.object().shape({
        deptId: Yup.string().required("Department Required"),
        userType: Yup.string().required("Role Required"),
    });

    const formik = useFormik({
        initialValues: {
            deptId: "",
            userType: "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(addNewRole(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === 200) {
                    enqueueSnackbar("Created Successfully..", {
                        variant: "success",
                    });
                    dispatch(getAllRoles());
                    setOpen(false);
                    resetForm();
                }
            });
        },
    });
    return (
        <Wrapper
            title="Designation"
            Content={
                <div>
                    <Page>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Button
                                onClick={handleClickOpen}
                                sx={{
                                    backgroundColor: "#7e4d8b45",
                                    color: "#7e4d8b",
                                }}
                            >
                                <Icon
                                    icon="mdi:people-add-outline"
                                    width="30"
                                    height="30"
                                />
                                Add New Role
                            </Button>
                        </Box>
                        <Paper
                            elevation={3}
                            sx={{
                                borderRadius: 1,
                                width: "100%",
                                marginTop: 3,
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                <DataGrid
                                    loading={roles.loading}
                                    rows={roles.data}
                                    columns={columns}
                                    sx={{
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                    }}
                                    pageSize={gridSize}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                    autoHeight
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    getRowId={(row) => row.serialNumber}
                                    components={{ Toolbar: CustomGridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Page>
                    <div>
                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <DialogTitle>
                                        <Stack direction={"row"} marginTop={1}>
                                            <Box marginRight={1}>
                                                <Icon
                                                    icon="mdi:people-add-outline"
                                                    width="30"
                                                    height="30"
                                                    color="#7e4d8b"
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    marginTop: 0.5,
                                                    color: "#7e4d8b",
                                                }}
                                            >
                                                {"Add New Role To Departments"}
                                            </Box>
                                        </Stack>
                                    </DialogTitle>
                                    <Divider />
                                    <DialogContent>
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                width: smUp ? 500 : "100%",
                                                backgroundColor: "grey.200",
                                            }}
                                        >
                                            <Box padding={smUp ? 4 : 1}>
                                                <Box margin={2}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Select Department
                                                        </InputLabel>
                                                        <Select
                                                            name="deptId"
                                                            value={
                                                                formik.values
                                                                    .deptId ||
                                                                ""
                                                            }
                                                            label="Department"
                                                            fullWidth
                                                            variant="filled"
                                                            onChange={(e) =>
                                                                formik.setFieldValue(
                                                                    "deptId",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            {preData.departments?.map(
                                                                (item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                item.departmentName
                                                                            }
                                                                            value={
                                                                                item.departmentId
                                                                            }
                                                                        >
                                                                            {
                                                                                item.departmentName
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                        <FormHelperText>
                                                            {
                                                                formik.errors
                                                                    .deptId
                                                            }
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Box>
                                                <Box margin={2}>
                                                    <TextField
                                                        name="userType"
                                                        label="Enter New Role"
                                                        variant="filled"
                                                        value={
                                                            formik.values
                                                                .userType
                                                        }
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        fullWidth
                                                        error={
                                                            formik.touched
                                                                ?.userType &&
                                                            Boolean(
                                                                formik.errors
                                                                    .userType
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            sx={{
                                                marginLeft: 2,
                                                backgroundColor: "#eff4f9",
                                                color: "#008fc3",
                                            }}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            sx={{
                                                marginLeft: 2,
                                                backgroundColor: "#c8facd",
                                                color: "#0db05d",
                                            }}
                                            type="submit"
                                        >
                                            <Icon
                                                icon="mdi:content-save-check-outline"
                                                width="25"
                                                height="25"
                                            />
                                            {loading ? "Saving.." : "Save Role"}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            </FormikProvider>
                        </Dialog>
                    </div>
                </div>
            }
        />
    );
}
