import React from 'react'
import GlobalDeleteDialog from '../../../../mui/Dialogue/GlobalDeleteDialog'
import { deleteInvoice, getInvoice } from '../../../../../redux/features/invoiceSlice'
import {useSelector } from 'react-redux'
import { savePaginationData } from '../../../../../redux/features/globalDatagridSlice'

const DeleteInvoice = ({invoiceId,paginationDetails,isVerified}) => {
    const {selectedDate, pageNumber, pageLimit }=paginationDetails
    const {loading}=useSelector((store)=>store.invoice)
    const { paginationData } = useSelector((state) => state.globalDataGrid)
  return (
    <GlobalDeleteDialog
    disabled={isVerified==1}
    title={"Delete"}
    deleteNote={"Are you sure? you want to delete this invoice..?"}
    api={deleteInvoice(invoiceId)}
    upDateCall={ getInvoice({ selectedDate, pageNumber, pageLimit })  }
    updatePaginationCall={savePaginationData({ ...paginationData, openAnchor: false })}
    loading={loading}
    />
  )
}

export default DeleteInvoice