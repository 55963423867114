import { DayWiseSessionActionsMenu } from "./DayWiseSessionActionsMenu";

export const DAY_WISE_SESSIONS = [
    {
        field: "serialNumber",
        headerName: "Serial Number",
        width: 90,
    },
    {
        field: "admnNo",
        headerName: "admnNo",
        width: 90,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "trainerName",
        headerName: "Trainer Name",
        width: 250,
    },
    {
        field: "courseName",
        headerName: "Course Name",
        width: 250,
    },
    {
        field: "subjectName",
        headerName: "Subject Name",
        width: 150,
    },
    {
        field: "fromTime",
        headerName: "From Time",
        width: 150,
    },
    {
        field: "toTime",
        headerName: "To Time",
        width: 150,
    },
    {
        field: "completedSessions",
        headerName: "completed Sessions",
        width: 90,
    },
    {
        field: "totalSessions",
        headerName: "Total Sessions",
        width: 90,
    },
    {
        field: "actions",
        headerName: "Actions",
        width: 90,
        disableClickEventBubbling: true,
        renderCell: (params) => (
            <DayWiseSessionActionsMenu params={params.row.scheduleId} />
        ),
    },
];
