import { Button } from "@mui/material";
import React, { useState } from "react";
import PopUps from "./PopUps";
import TotalSessionsUpdateTable from "./TotalSessionsUpdateTable";

const TotalSessionsPop = ({ row }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const componentProps = {
        setOpen: setOpen,
        row: row,
    };

    return (
        <div>
            {row.totalSessions ? (
                <Button variant="contained" onClick={handleClick}>
                    {row.totalSessions}
                </Button>
            ) : (
                <Button variant="contained" onClick={handleClick}>
                    NA
                </Button>
            )}
            <PopUps
                open={open}
                setOpen={setOpen}
                component={TotalSessionsUpdateTable}
                componentProps={componentProps}
            />
        </div>
    );
};

export default TotalSessionsPop;
