import ToolTip from '../../ToolTip/ToolTip'
import noImage from '../../../../src/img/NoImage.jpg'
import { Icon } from '@iconify/react/dist/iconify.js'
import { Active, Inactive } from '../Common Icons/Icons'

export const courseTableHeader = [
  { field: 'sl_no', headerName: 'Serial No', width: 80 },
  {
    field: 'courseImg',
    headerName: 'Image',
    width: 100,
    renderCell: (params) => {
      return (
        <div
          key={params.row.sl_no}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <img src={params.row.img || noImage} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
      )
    },
  },
  { field: 'courseName', headerName: 'Course Name', width: 220 },
  { field: 'projectName', headerName: 'Project Name', width: 150 },
  {
    field: 'price_per_session',
    headerName: 'Price Per Session',
    width: 150,
    renderCell: (params) => {
      return params.row.price_per_session || 'Nill'
    },
  },
  {
    field: 'courseOutcomes',
    headerName: 'Description',
    width: 200,
    renderCell: (params) => {
      return <ToolTip expand={params?.row?.courseOutcomes || 'Nill'} content={params?.row?.courseOutcomes || 'Nill'} />
    },
  },
  {
    field: 'batchAvailability',
    headerName: 'Class Type',
    width: 120,
    renderCell: (params) => {
      return params.row.batchAvailability == 1 ? 'Group' : 'Induvidual'
    },
  },
  {
    field: 'courseAvailability',
    headerName: 'Course Current Status',
    width: 180,
    renderCell: (params) => {
      return params.row.courseAvailability == 1 ? 
        <Active/>
       : 
       <Inactive/>
      
    },
  },
  // {
  //   field: 'courseImg',
  //   headerName: 'Image',
  //   width: 100,
  //   renderCell: (params) => {
  //     return <img src={params.row.img} style={{width:"150px"}}/>
  //   },
  // },
]
