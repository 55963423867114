import DeleteFoodStatus from "./DeleteFoodStatus";

export const MealStatusTableColumn = [
    {
      field: "serialNumber",
      headerName: "slNo",
      width:100
    },
    {
      field: "day_food_status_date",
      headerName: "Date",
      width: 200,
    },
   
    {
      field: "day_food_meal_status",
      headerName: "Food Type",
      width: 180,
      renderCell:(params)=>params.row.day_food_meal_status==2 ?"Non Veg": "Veg"
    },
    {
      field: "Delete",
      headerName: "Delete",
      width: 150,
      renderCell:(params)=><DeleteFoodStatus date={params.row.day_food_status_date}  id={params.row.day_food_status_id}/>
    },
  ];
  