import {
    Box,
    Button,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { transitionStatus } from "../redux/features/settingsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

export default function TransitionSettings() {
    const data = useSelector((state) => state.settings.transitionStatus);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Box padding={2}>
                <Typography variant="subtitle2" sx={{ color: "#9aa6b2" }}>
                    Transitions
                </Typography>
            </Box>
            <Box width={"100%"} padding={1}>
                <Stack direction={"row"} spacing={2}>
                    <Box width={"50%"}>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor:
                                    data.transition === "Fade"
                                        ? "#d6c3eb"
                                        : "white",
                                width: "100%",
                                height: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "&:hover": {
                                    boxShadow:
                                        " -1px 5px 5px 0px rgba(0,0,0,0.4)",
                                },
                            }}
                            onClick={() => {
                                dispatch(
                                    transitionStatus({
                                        transition: "Fade",
                                        otherProps: "",
                                    })
                                );
                            }}
                        >
                            {data.transition === "Fade" ? (
                                <Box paddingTop={1}>
                                    <Icon
                                        icon="line-md:confirm-circle"
                                        width="22"
                                        height="22"
                                    />
                                </Box>
                            ) : null}
                            <Typography
                                fontWeight={"bolder"}
                                variant="subtitle2"
                            >
                                Fade
                            </Typography>
                        </Paper>
                    </Box>
                    <Box width={"50%"}>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor:
                                    data.transition === "Collapse"
                                        ? "#d6c3eb"
                                        : "white",
                                width: "100%",
                                height: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "&:hover": {
                                    boxShadow:
                                        " -1px 5px 5px 0px rgba(0,0,0,0.4)",
                                },
                            }}
                            onClick={() => {
                                dispatch(
                                    transitionStatus({
                                        transition: "Collapse",
                                        otherProps: "",
                                    })
                                );
                            }}
                        >
                            {data.transition === "Collapse" ? (
                                <Box paddingTop={1}>
                                    <Icon
                                        icon="line-md:confirm-circle"
                                        width="22"
                                        height="22"
                                    />
                                </Box>
                            ) : null}
                            <Typography variant="subtitle2">
                                Collapse
                            </Typography>
                        </Paper>
                    </Box>
                </Stack>

                <Stack direction={"row"} spacing={2} marginTop={2}>
                    <Box width={"50%"}>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor:
                                    data.transition === "Grow"
                                        ? "#d6c3eb"
                                        : "white",
                                width: "100%",
                                height: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "&:hover": {
                                    boxShadow:
                                        " -1px 5px 5px 0px rgba(0,0,0,0.4)",
                                },
                            }}
                            onClick={() => {
                                dispatch(
                                    transitionStatus({
                                        transition: "Grow",
                                        otherProps: "",
                                    })
                                );
                            }}
                        >
                            {data.transition === "Grow" ? (
                                <Box paddingTop={1}>
                                    <Icon
                                        icon="line-md:confirm-circle"
                                        width="22"
                                        height="22"
                                    />
                                </Box>
                            ) : null}
                            <Typography variant="subtitle2">Grow</Typography>
                        </Paper>
                    </Box>

                    <Box width={"50%"}>
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor:
                                    data.transition === "Zoom"
                                        ? "#d6c3eb"
                                        : "white",
                                width: "100%",
                                height: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "&:hover": {
                                    boxShadow:
                                        " -1px 5px 5px 0px rgba(0,0,0,0.4)",
                                },
                            }}
                            onClick={() => {
                                dispatch(
                                    transitionStatus({
                                        transition: "Zoom",
                                        otherProps: "",
                                    })
                                );
                            }}
                        >
                            {data.transition === "Zoom" ? (
                                <Box paddingTop={1}>
                                    <Icon
                                        icon="line-md:confirm-circle"
                                        width="22"
                                        height="22"
                                    />
                                </Box>
                            ) : null}
                            <Typography variant="subtitle2">Zoom</Typography>
                        </Paper>
                    </Box>
                </Stack>

                <Box marginTop={2}>
                    <Paper
                        elevation={3}
                        sx={{
                            backgroundColor:
                                data.transition === "Slide"
                                    ? "#d6c3eb"
                                    : "white",
                            width: "100%",
                            height: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                                boxShadow: " -1px 5px 5px 0px rgba(0,0,0,0.4)",
                            },
                        }}
                        onClick={handleClick}
                    >
                        {data.transition === "Slide" ? (
                            <Box paddingTop={1}>
                                <Icon
                                    icon="line-md:confirm-circle"
                                    width="22"
                                    height="22"
                                />
                            </Box>
                        ) : null}
                        <Typography variant="subtitle2">Slide</Typography>
                    </Paper>
                </Box>
            </Box>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    onClick={() => {
                        dispatch(
                            transitionStatus({
                                transition: "Slide",
                                otherProps: "up",
                            }),
                            handleClose()
                        );
                    }}
                >
                    <ListItemIcon>
                        <Icon
                            icon="line-md:chevron-small-double-up"
                            width="22"
                            height="22"
                        />
                    </ListItemIcon>
                    UP
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        dispatch(
                            transitionStatus({
                                transition: "Slide",
                                otherProps: "down",
                            }),
                            handleClose()
                        );
                    }}
                >
                    <ListItemIcon>
                        <Icon
                            icon="line-md:chevron-small-double-down"
                            width="22"
                            height="22"
                        />
                    </ListItemIcon>
                    Down
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        dispatch(
                            transitionStatus({
                                transition: "Slide",
                                otherProps: "left",
                            }),
                            handleClose()
                        );
                    }}
                >
                    <ListItemIcon>
                        <Icon
                            icon="line-md:chevron-small-double-left"
                            width="22"
                            height="22"
                        />
                    </ListItemIcon>
                    Left
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        dispatch(
                            transitionStatus({
                                transition: "Slide",
                                otherProps: "right",
                            }),
                            handleClose()
                        );
                    }}
                >
                    <ListItemIcon>
                        <Icon
                            icon="line-md:chevron-small-double-right"
                            width="22"
                            height="22"
                        />
                    </ListItemIcon>
                    Right
                </MenuItem>
            </Menu>
        </div>
    );
}
