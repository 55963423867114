import { Container, Typography, Box, Paper } from "@mui/material";
import React, { useEffect } from "react";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import Page from "../../../../components/color-utils/Page";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { JOB_APPLICATION_TABLE_HEAD as columns } from "../../../../components/datagrid/JobApplication/jobApplicationTableHead";
import {
    getJobApplications,
    getCvStatusPreData,
} from "../../../../redux/features/jobApplicationSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Fade from "@mui/material/Fade";

export default function AllJobApplications() {
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const jobApplications = useSelector((state) => state.jobApplication);
    const [value, setValue] = React.useState("");
    const [date, setDate] = React.useState();
    const [checked, setChecked] = React.useState(false);

    useEffect(() => {
        let newValue = new Date();
        setValue(newValue);
        let yer = dayjs().format("YYYY/MM");
        dispatch(getJobApplications(yer));
    }, []);

    const handleDate = (newValue) => {
        let yer = newValue.format("YYYY/MM");
        setValue(newValue);
        setDate(yer);
        dispatch(getJobApplications(yer));
    };

    useEffect(() => {
        dispatch(getCvStatusPreData(1));
    }, []);
    useEffect(() => {
        setChecked((prev) => !prev);
    }, []);

    return (
        <div>
            <Fade timeout={{ enter: 1000 }} in={checked}>
                <Page>
                    {/* <Container> */}
                    <Typography variant="h4">Job Applications</Typography>
                    <Breadcrumb sx={{ ml: 2 }} />

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                views={["year", "month"]}
                                label="Year and Month"
                                disableFuture
                                value={dayjs(value)}
                                onChange={handleDate}
                                inputProps={{ readOnly: true }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        helperText={null}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Paper elevation={3} sx={{ mt: 4 }}>
                        <Box sx={{ width: "100%" }}>
                            <DataGrid
                                loading={jobApplications.loading}
                                rows={jobApplications.data}
                                columns={columns}
                                sx={{
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                }}
                                pageSize={gridSize}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                                autoHeight
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                getRowId={(row) => row.serialNumber}
                                density={"comfortable"}
                                components={{ Toolbar: CustomGridToolbar }}
                                componentsProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 },
                                    },
                                }}
                            />
                        </Box>
                    </Paper>
                    {/* </Container> */}
                </Page>
            </Fade>
        </div>
    );
}
