import React from "react";
import { Route, Routes } from "react-router-dom";
import Privileges from "../../views/user/privileges/Privileges";

const PrivilegesRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<Privileges />} />
        </Routes>
    );
};

export default PrivilegesRouter;
