import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { AddButton, SaveButton } from "../../../../mui/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
    addStudentAcademicSchedules,
    getSessionsForBaseCourses,
    getStudentAcademicSessions,
} from "../../../../../redux/features/researchSlice";
import SimpleLoader from "../../../../Loader/SimpleLoader";
import { Box, Checkbox, Paper, Stack } from "@mui/material";
import NoDataFound from "../../../../NodataIndication/NoDataFound";
import { useSnackbar } from "notistack";

export const AddStudentAcademicSchedules = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [checked, setChecked] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const { sessionsForBaseCourses, loading, subjectEnrollmentDetailedId } =
        useSelector((state) => state.research);

    useEffect(() => {
        if (sessionsForBaseCourses) {
            const updatedData = sessionsForBaseCourses?.map((item) => ({
                ...item,
                checked: false,
            }));
            setData(updatedData);
        }
    }, [sessionsForBaseCourses]);

    const handleSlectAll = (e) => {
        setChecked(e.target.checked);
        setData(() =>
            data.map((item) =>
                checked === false
                    ? { ...item, checked: true }
                    : { ...item, checked: false }
            )
        );
    };

    const handleCheckboxChange = (sessionSrlNo) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.sessionSrlNo === sessionSrlNo
                    ? { ...item, checked: !item.checked }
                    : item
            )
        );
    };

    const handleSaveCheckedItems = () => {
        const checkedData = data.filter((item) => item.checked);
        const newData = checkedData?.map(
            ({ checked, SessionId, baseCourseCustomName, moduleId, ...rest }) =>
                rest
        );

        const updatedSessions = newData?.map((session) => ({
            ...session,
            sessionModuleNo: session.moduleSrlNo,
            sessionModuleContent: session.moduleContent,
        }));

        // for (let i = 0; i < updatedSessions.length; i++) {
        //     updatedSessions[i].sessionModuleNo = updatedSessions[i].moduleSrlNo;
        //     updatedSessions[i].sessionModuleContent =
        //         updatedSessions[i].moduleContent;
        //     delete updatedSessions[i].moduleSrlNo;
        //     delete updatedSessions[i].moduleContent;
        // }

        setCheckedItems(updatedSessions);

        const datas = {
            subjectEnrollmentDetailedId: subjectEnrollmentDetailedId,
            sessions: [...updatedSessions],
        };

        dispatch(addStudentAcademicSchedules(datas)).then((res) => {
            if (
                res.payload.status === "failed" ||
                res.payload.status === 422 ||
                res.payload.status === "error"
            ) {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                setOpen(false);
                dispatch(
                    getStudentAcademicSessions(subjectEnrollmentDetailedId)
                );

                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
            }
        });
    };

    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                closeButton={true}
                title="Add Academic Sessions"
                popupButton={
                    <AddButton size="small" action={() => setOpen(true)}>
                        Add Academic Sessions
                    </AddButton>
                }
                content={
                    <>
                        {loading ? (
                            <SimpleLoader />
                        ) : (
                            <>
                                {data.length === 0 ? (
                                    <NoDataFound />
                                ) : (
                                    <Box
                                        sx={{
                                            backgroundColor: "grey.200",
                                            padding: 3,
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box>
                                            <Checkbox
                                                sx={{
                                                    padding: 1,
                                                    margin: 0,
                                                }}
                                                checked={checked}
                                                onChange={handleSlectAll}
                                            />
                                        </Box>
                                        {data.map((item, i) => {
                                            return (
                                                <>
                                                    <Stack
                                                        direction={"row"}
                                                        spacing={2}
                                                        mt={1}
                                                        key={item.sessionSrlNo}
                                                    >
                                                        <Box>
                                                            <Checkbox
                                                                sx={{
                                                                    padding: 1,
                                                                    margin: 0,
                                                                }}
                                                                checked={
                                                                    item.checked
                                                                }
                                                                onChange={(e) =>
                                                                    handleCheckboxChange(
                                                                        item.sessionSrlNo
                                                                    )
                                                                }
                                                            />
                                                        </Box>

                                                        <Paper
                                                            sx={{
                                                                height: 30,
                                                                width: 30,
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                padding: 3,
                                                            }}
                                                        >
                                                            {item.sessionSrlNo}
                                                        </Paper>
                                                        <Paper
                                                            sx={{
                                                                minHeight: 20,
                                                                width: 400,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                padding: 3,
                                                            }}
                                                        >
                                                            {
                                                                item.sessionContent
                                                            }
                                                        </Paper>
                                                        <Paper
                                                            sx={{
                                                                height: 30,
                                                                width: 80,
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                padding: 3,
                                                            }}
                                                        >
                                                            {item.moduleSrlNo}
                                                        </Paper>
                                                    </Stack>
                                                </>
                                            );
                                        })}
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                pt: 2,
                                            }}
                                        >
                                            <SaveButton
                                                action={handleSaveCheckedItems}
                                            >
                                                Save
                                            </SaveButton>
                                        </Box>
                                    </Box>
                                )}
                            </>
                        )}
                    </>
                }
            />
        </div>
    );
};
