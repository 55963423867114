export const SQA_LEADS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "admissionNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
    },
    {
        field: "class",
        headerName: "Class",
        width: 150,
    },
    {
        field: "course",
        headerName: "Course",
        width: 150,
    },
    {
        field: "publication",
        headerName: "Publication",
        width: 150,
    },
    {
        field: "contactNo",
        headerName: "Contact No",
        width: 150,
    },
    {
        field: "place",
        headerName: "Place",
        width: 150,
    },
    {
        field: "cameOn",
        headerName: "Came On",
        width: 150,
    },
    {
        field: "oga",
        headerName: "OGA",
        width: 150,
    },
    {
        field: "ogaRemarks",
        headerName: "OGA Remarks",
        width: 150,
    },
    {
        field: "ogaRemarksDetailed",
        headerName: "OGA Remarks Detailed",
        width: 150,
    },
    {
        field: "cro",
        headerName: "CRO",
        width: 150,
    },
    {
        field: "croRemarks",
        headerName: "CRO Remarks",
        width: 150,
    },
    {
        field: "sqaStatus",
        headerName: "SQA Status",
        width: 150,
    },
    {
        field: "assignedOn",
        headerName: "Assigned On",
        width: 150,
    },
    {
        field: "assignedJqa",
        headerName: "Assigned Jqa",
        width: 150,
    },
    {
        field: "jqaStatus",
        headerName: "JQA Status",
        width: 150,
    },
    {
        field: "jqaLastUpdatedOn",
        headerName: "JQA Last Updated On",
        width: 150,
    },
    {
        field: "croAknowledgedOn",
        headerName: "CRO Aknowledged On",
        width: 150,
    },
];
