import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Typography } from "@mui/material";
import { CancelButton, DeleteButton } from "../../../../mui/Buttons/Buttons";
import {
    deleteMobileAppStudent,
    getMobileAppStudents,
} from "../../../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

export const Delete = ({ adno }) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.cro.loading);
    const [open, setOpen] = React.useState(false);
    const profile = JSON.parse(localStorage.getItem("cred")).profile;
    const { enqueueSnackbar } = useSnackbar();
    return (
        <div>
            <GlobalDialog
                title="Delete"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                content={
                    <Typography>
                        Are you sure . You want to delete...?
                    </Typography>
                }
                popupButton={
                    <DeleteButton size="small" action={() => setOpen(true)}>
                        Delete
                    </DeleteButton>
                }
                actionButton={
                    <>
                        <CancelButton action={() => setOpen(false)}>
                            Cancel
                        </CancelButton>
                        <DeleteButton
                            action={() => {
                                dispatch(deleteMobileAppStudent(adno)).then(
                                    (res) => {
                                        if (res.payload.status === "failed") {
                                            enqueueSnackbar(
                                                res.payload.message,
                                                { variant: "error" }
                                            );
                                        } else if (
                                            res.payload.status === "success"
                                        ) {
                                            setOpen(false);
                                            dispatch(
                                                getMobileAppStudents(
                                                    profile.userId
                                                )
                                            );
                                            enqueueSnackbar(
                                                res.payload.message,
                                                {
                                                    variant: "success",
                                                }
                                            );
                                        }
                                    }
                                );
                            }}
                        >
                            {loading ? "Deleting..." : "Delete"}
                        </DeleteButton>
                    </>
                }
            />
        </div>
    );
};
