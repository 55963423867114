import React from "react";
import { Icon } from "@iconify/react";
import {
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { useEffect } from "react";

const Mytable = ({ tableRow }) => {
    return (
        <Grid item xs={12} lg={6} md={6}>
            <Paper
                sx={{
                    margin: 1,
                    borderRadius: 4,
                }}
            >
                <Table>{tableRow}</Table>
            </Paper>
        </Grid>
    );
};

const Rows = ({ label, value }) => {
    return (
        <TableRow>
            <TableCell variant="head" sx={{ padding: 1 }}>
                <Stack direction={"row"} spacing={1}>
                    <Box>
                        <Icon
                            icon="uil:arrow-up"
                            rotate={1}
                            color="#2f72b3"
                            width="25"
                            height="25"
                        />
                    </Box>

                    <Box>{label}</Box>
                </Stack>
            </TableCell>
            <TableCell sx={{ padding: 0.5 }}>{value}</TableCell>
        </TableRow>
    );
};

function convertKeysToTitleCase(data) {
    const result = {};
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const titleCaseKey = key
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/^./, (str) => str.toUpperCase());
            result[titleCaseKey] = data[key];
        }
    }
    return result;
}

const convertUnderScoreKeysToTitlecase = (data) => {
    const transformKey = (key) => {
      return key.replace(/_([a-z])/g, (_, char) => ` ${char.toUpperCase()}`) // Replace _ and capitalize
                .replace(/^[a-z]/, char => char.toUpperCase()); // Capitalize first letter
    };
  
    const transformedData = Object.keys(data).reduce((acc, key) => {
      acc[transformKey(key)] = data[key]; // Assign transformed key with original value
      return acc;
    }, {});
    return transformedData;
  };

export const ListOfDetails = ({ data,isUnderScore }) => {
    let modifiedData;
    if(isUnderScore){
   modifiedData=convertUnderScoreKeysToTitlecase(data)
    }else{
         modifiedData = convertKeysToTitleCase(data);
    }
    const dataArray = Object.entries(modifiedData).map(
        ([key, value], index) => ({
            id: index + 1,
            key,
            value,
        })
    );  
    const [dataCount, setDataCount] = React.useState(0);
    const lenght = dataArray.length / 2;
    const number = parseInt(lenght);
    useEffect(() => {
        if (number % 2 === 0) {
            setDataCount(number);
        } else {
            setDataCount(number + 0.5);
        }
    }, [lenght]);
    const firstDivData = dataArray.slice(0, dataCount + 1);
    const secondDivData = dataArray.slice(dataCount + 1);
    return (
        <div>
            <Box marginTop={2}>
                {/* ------------------------------------------------------------------------------ */}

                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <TableBody>
                                    {firstDivData.map((item) => {
                                        return (
                                            <Rows
                                                key={item.id}
                                                label={`${item.key}`}
                                                value={item.value}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </>
                        }
                    />
                    <Mytable
                        tableRow={
                            <>
                                <TableBody>
                                    {secondDivData.map((item) => {
                                        return (
                                            <Rows
                                                key={item.id}
                                                label={`${item.key}`}
                                                value={item.value}
                                            />
                                        );
                                    })}
                                </TableBody>
                            </>
                        }
                    />
                </Grid>

                {/* ------------------------------------------------------------------------------------------ */}
            </Box>
        </div>
    );
};
