import React, { useEffect } from "react";
import BasicTextField from "../../../mui/TextField";
import { Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    closeAccounts,
    getAccountsSummery,
    getStudentMonthlyLedger,
} from "../../../../redux/features/croSlice";
import Loader from "../../../Loader/Loader";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const CloseAccoundForm = ({
    ledgerYear,
    ledgerMonth,
    courseEnrollmentId,
    setOpen,
}) => {
    console.log(ledgerYear, ledgerMonth, courseEnrollmentId);
    const dispatch = useDispatch();
    const { studentMonthlyLedgerSummary, loading } = useSelector(
        (state) => state.cro
    );
    const { enqueueSnackbar } = useSnackbar();

    const schema = Yup.object().shape({
        remarks: Yup.string().required("Please enter remarks"),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            accountsEntityId: studentMonthlyLedgerSummary?.accountsEntityId,
            monthAndYear: studentMonthlyLedgerSummary?.monthAndYear,
            student: studentMonthlyLedgerSummary?.student,
            openingBalance: studentMonthlyLedgerSummary?.openingBalance,
            totalIncome: studentMonthlyLedgerSummary?.totalIncome,
            totalExpense: studentMonthlyLedgerSummary?.totalExpense,
            closingBalance: studentMonthlyLedgerSummary?.closingBalance,
            nextMonthAndYear: studentMonthlyLedgerSummary?.nextMonthAndYear,
            nextOpeningBalance: studentMonthlyLedgerSummary?.nextOpeningBalance,
            remarks: "",
        },
        validationSchema: schema,
        onSubmit: ({ remarks, nextOpeningBalance, accountsEntityId }) => {
            console.log(remarks, nextOpeningBalance, accountsEntityId);
            dispatch(
                closeAccounts({
                    body: { nextOpeningBalance, remarks },
                    accountsEntityId,
                })
            ).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    setOpen(false);
                } else {
                    enqueueSnackbar(res.payload.message, {
                        variant: "error",
                    });
                }
            });
        },
    });
    useEffect(() => {
        dispatch(
            getAccountsSummery(
                `${courseEnrollmentId}/${ledgerYear}/${ledgerMonth}`
            )
        );
        return () => {
            dispatch(
                getStudentMonthlyLedger(
                    `${courseEnrollmentId}/${ledgerYear}/${ledgerMonth}`
                )
            );
        };
    }, []);

    return loading ? (
        <Loader />
    ) : (
        <div
            style={{
                textAlign: "center",
            }}
        >
            <Typography variant="h4" mb={2}>
                Close Accounts
            </Typography>

            <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                    <Stack width="450px" gap={2}>
                        <BasicTextField
                            disabled
                            label="Month & year"
                            value={formik.values.monthAndYear}
                        />

                        <BasicTextField
                            disabled
                            label="Student"
                            value={formik.values.student}
                        />
                        <BasicTextField
                            disabled
                            label="Opening Balance"
                            value={formik.values.openingBalance}
                        />
                        <BasicTextField
                            disabled
                            label="Total Income"
                            value={formik.values.totalIncome}
                        />
                        <BasicTextField
                            disabled
                            label="Total Expense"
                            value={formik.values.totalExpense}
                        />
                        <BasicTextField
                            disabled
                            label="Closing Balance"
                            value={formik.values.closingBalance}
                        />
                        <hr
                            style={{
                                borderBottom: "1px solid #000",
                            }}
                        />
                        <BasicTextField
                            disabled
                            label="Next Month & Year"
                            value={formik.values.nextMonthAndYear}
                        />
                        <BasicTextField
                            disabled
                            label="Opening Balance"
                            value={formik.values.nextOpeningBalance}
                        />
                        <BasicTextField
                            label="remarks"
                            name="remarks"
                            multiline
                            rows={3}
                            value={formik.values.remarks}
                            action={formik.handleChange}
                            error={Boolean(
                                formik.touched.remarks && formik.errors.remarks
                            )}
                            helperText={formik.errors.remarks}
                        />
                    </Stack>
                    <Button
                        variant="contained"
                        sx={{
                            width: "150px",
                            mt: 2,
                        }}
                        disabled={
                            studentMonthlyLedgerSummary.closeAccountsButtonsStatus ===
                            "Disabled"
                        }
                        type="submit"
                    >
                        Close Accounts
                    </Button>
                </Form>
            </FormikProvider>
        </div>
    );
};

export default CloseAccoundForm;
