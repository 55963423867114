import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../redux/features/userSlice";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { Box, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { ALL_USERS_TABLE_HEAD as columns } from "../../../components/datagrid/users/allUsersTableHead";

import { getPreData, addNewRole } from "../../../redux/features/userSlice";
import useResponsive from "../../../../src/Hooks/useResponsive";
import Wrapper from "../../../components/Wrapper/Wrapper";

const EmployeeData = () => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");

    const [gridSize, setGridSize] = useState(5);
    const userData = useSelector((state) => state.users);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPreData());
        dispatch(getAllUsers());
    }, []);

    return (
        <div>
            <Wrapper
                title="Employees"
                Content={
                    <>
                        <Paper
                            elevation={3}
                            sx={{
                                borderRadius: 1,
                                width: "100%",
                                marginTop: 3,
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                <DataGrid
                                    loading={userData.loading}
                                    rows={userData.data}
                                    columns={columns}
                                    sx={{
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                    }}
                                    pageSize={gridSize}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                    autoHeight
                                    density="comfortable"
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    getRowId={(row) => row.id}
                                    components={{ Toolbar: CustomGridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
};

export default EmployeeData;
