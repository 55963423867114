import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import MarkAttendanceStyle from "../../components/datagrid/MarkAttendanceStyle";
import { formatDataForDataTable } from "../../utils/formatData";
import { Attendance, MarkAttendance } from "../../views/user/EmployeeServices";
import AttendanceAction from "../../components/datagrid/Attendance/AttendanceAction";
import AttendanceNameImg from "../../components/datagrid/Attendance/AttendanceNameImg";
const initialState = {
    types: [],
    data: [],
    ats: [],
    TotalData: [],
    tableHead: [],
    loading: false,
    error: "",
};

export const getAttendanceTypes = createAsyncThunk(
    "attendance/getAttendanceTypes",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `attendance/getAttendanceTypes`,
        });
        return res;
    }
);

export const addAttendance = createAsyncThunk(
    "attendance/addAttendance",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `attendance/addAttendance`,
            data: data,
        });
        return res;
    }
);

export const getAttendance = createAsyncThunk(
    "attendance/getAttendance",
    async (date) => {
        const res = await apiRequest({
            method: "get",
            url: `attendance/getAttendance/${date}`,
        });
        return res;
    }
);

export const getAllAttendance = createAsyncThunk(
    "attendance/getAllAttendance",
    async (data) => {
        const res = await apiRequest({
            method: "get",
            url: `attendance/getAllAttendance/${data.date}/${data.dep}`,
        });
        return res;
    }
);

export const getReportingHeadAttendance = createAsyncThunk(
    "attendance/getReportingHeadAttendance",
    async (date) => {
        const res = await apiRequest({
            method: "get",
            url: `attendance/getReportingHeadAttendance/${date}`,
        });
        return res;
    }
);
export const approveAttendance = createAsyncThunk(
    "attendance/approveAttendance",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `attendance/approveAttendance`,
            data: data,
        });
        return res;
    }
);

export const editAttendance = createAsyncThunk(
    "attendance/editAttendance",
    async (data) => {
        const res = await apiRequest({
            method: "patch",
            url: `attendance/editAttendance`,
            data: data,
        });
        return res;
    }
);

export const markAbsentees = createAsyncThunk(
    "attendance/markAbsentees",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `attendance/markAbsentees`,
            data: data,
        });
        return res;
    }
);

const attendanceSlice = createSlice({
    name: "attendance",
    initialState: initialState,
    reducers: {
        createTableHead: (state, action) => {
            let [year, month, day] = action.payload.split("/");
            let num = new Date(year, month, 0).getDate();
            let arry = [];

            for (let i = 0; i < num; i++) {
                arry.push({
                    field: `${i}`,
                    headerName: `Day ${i + 1}`,
                    headerAlign: "left",
                    align: "left",
                    day: true,
                });
            }

            return {
                ...state,
                tableHead: arry,
            };
        },
        clearStates: (state) => {
            return (state = initialState);
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getAttendanceTypes.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAttendanceTypes.fulfilled, (state, action) => {
            state.loading = false;
            state.types = action.payload.data.attendanceTypes;
        });
        builder.addCase(getAttendanceTypes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------------addAttendance----------------------------------------
        builder.addCase(addAttendance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addAttendance.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(addAttendance.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ---------------------getAttendance-----------------------------------------------
        builder.addCase(getAttendance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAttendance.fulfilled, (state, action) => {
            state.loading = false;
            state.TotalData = action.payload.data;
            state.ats = formatDataForDataTable(action.payload.data.attendance);
        });
        builder.addCase(getAttendance.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------------getAllAttendance--------------------------
        builder.addCase(getAllAttendance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllAttendance.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data.attendances;
            action.payload.data.attendances.map((item) => {
                const it = item.attendance.map((ite) => {
                    return ite.status;
                });
                return Object.assign(item, { ...it });
            });
        });
        builder.addCase(getAllAttendance.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------getReportingHeadAttendance--------------------
        builder.addCase(getReportingHeadAttendance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getReportingHeadAttendance.fulfilled,
            (state, action) => {
                state.loading = false;
                state.data = action.payload.data.attendances;
                action.payload.data.attendances.map((item) => {
                    const items = item.attendance.map((days) => {
                        return days.status;
                    });

                    return Object.assign(item, { ...items });
                });
            }
        );
        builder.addCase(
            getReportingHeadAttendance.rejected,
            (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            }
        );
        // ----------------------approveAttendance----------------------------------------
        builder.addCase(approveAttendance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(approveAttendance.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(approveAttendance.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------EditAttendance----------------------------------
        builder.addCase(editAttendance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editAttendance.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(editAttendance.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------MarkAbsentees-----------------------------
        builder.addCase(markAbsentees.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(markAbsentees.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(markAbsentees.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});
export const { createTableHead, clearStates } = attendanceSlice.actions;
export default attendanceSlice.reducer;
