import {
  CreateUpdateDescriptionView,
  DeleteDescriptionView,
} from "../RenderCellMain";

export const DESCRIPTION_TABLE_HEAD = (data) => {
  const columns = [
    {
      field: "serialNumber",
      headerName: "slNo",
      width: 40,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
    },
    {
      field: "grade_name",
      headerName: "Grade Name",
      width: 90,
    },
    {
      field: "level_name",
      headerName: "Level Name",
      width: 120,
    },
    {
      field: "objective_name",
      headerName: "Objective Name",
      width: 200,
    },
    {
      field: "matrix_name",
      headerName: "Matrix Name",
      width: 150,
    },
    {
      field: "description_created_at",
      headerName: "Description Created At",
      width: 160,
    },
    {
      field: "Update",
      headerName: "Update",
      width: 120,
      renderCell: (params) => (
        <CreateUpdateDescriptionView params={params.row} />
      ),
    },
    {
      field: "Delete",
      headerName: "Delete",
      width: 120,
      renderCell: (params) => (
        <DeleteDescriptionView id={params.row.description_id} />
      ),
    },
  ];

  // Check if any row in the data has non-null values for level_name or objective_name
  const levelNameExists = data.some((row) => row.level_name !== null);
  const objectiveNameExists = data.some((row) => row.objective_name !== null);

  // Filter out the columns based on the existence of non-null values
  return columns.filter((column) => {
    if (column.field === "level_name" && !levelNameExists) {
      return false;
    }
    if (column.field === "objective_name" && !objectiveNameExists) {
      return false;
    }
    return true;
  });
};
