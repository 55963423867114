import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Paper, Stack } from "@mui/material";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { CRO_ENQUIRY_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/CroEnquiries/CroEnquiriesTableHead";
import { Form, FormikProvider, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../../../Hooks/useResponsive";
import * as Yup from "yup";
import {
    LogButton,
    SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import Details from "./Details";
import { Actions } from "./Actions";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAssignedCros } from "../../../../redux/features/crmSlice";
import SelectionHeader from "../../../../components/mui/SelectionHeader/SelectionHeader";
import { getCroPendingEnquiries } from "../../../../redux/features/croSlice";
import { useSnackbar } from "notistack";

export default function CroEnquiries() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const cros = useSelector((state) => state.crm);

    const datas = useSelector((state) => state.cro);

    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const navigate = useNavigate();
    const profile = JSON.parse(localStorage.getItem("cred")).profile;

    const pro = [
        {
            id: 1,
            name: "Foundation Programmes",
        },
        {
            id: 2,
            name: "Classroom Programmes",
        },
    ];

    useEffect(() => {
        dispatch(getAssignedCros());
    }, []);

    const Schema = () => {
        if (profile.roleID === 10) {
            return Yup.object().shape({
                CourseTypeId: Yup.string().required("CourseTypeId is required"),
            });
        } else {
            return Yup.object().shape({
                croId: Yup.string().required("croId is required"),
                CourseTypeId: Yup.string().required("CourseTypeId is required"),
            });
        }
    };

    const formik = useFormik({
        initialValues: {
            croId: "",
            CourseTypeId: "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            dispatch(getCroPendingEnquiries(values.CourseTypeId)).then(
                (res) => {
                    if (res.payload.status === "failed") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "error",
                        });
                    } else if (res.payload.status === "success") {
                        enqueueSnackbar(res.payload.message, {
                            variant: "success",
                        });
                    }
                }
            );
        },
    });

    const Head = [
        {
            field: "name",
            headerName: "Student Name",
            width: 250,
        },
    ];

    const [selectedRows, setSelectedRows] = React.useState([]);

    return (
        <div>
            <Wrapper
                title="CRO Enquiries"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <LogButton
                                action={() => {
                                    navigate("/my-workspace/cro-enquiry-log");
                                }}
                            >
                                Enquiry Log
                            </LogButton>
                        </Box>
                        {/* ---------------------------------------------------------------------------------------- */}
                        <SelectionHeader
                            content={
                                <Paper>
                                    <FormikProvider value={formik}>
                                        <Form onSubmit={formik.handleSubmit}>
                                            <Box
                                                sx={{
                                                    backgroundColor: "grey.200",
                                                }}
                                            >
                                                <Stack
                                                    direction={"row"}
                                                    spacing={2}
                                                >
                                                    <Box>
                                                        <GlobalSelectField
                                                            options={{
                                                                formik: formik,
                                                                label: "Course Type",
                                                                name: "CourseTypeId",
                                                                sx: {
                                                                    width: 400,
                                                                },
                                                                data: pro,

                                                                keys: {
                                                                    id: "id",
                                                                    value: "name",
                                                                },
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        {profile.roleID ==
                                                        106 ? (
                                                            <GlobalSelectField
                                                                options={{
                                                                    formik: formik,
                                                                    label: "CRO",
                                                                    name: "croId",
                                                                    sx: {
                                                                        width: 400,
                                                                    },
                                                                    data: cros?.AssignedCros,

                                                                    keys: {
                                                                        id: "id",
                                                                        value: "name",
                                                                    },
                                                                }}
                                                            />
                                                        ) : null}
                                                    </Box>

                                                    <Box paddingTop={1.2}>
                                                        <SubmitButton type="submit">
                                                            Submit
                                                        </SubmitButton>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </Form>
                                    </FormikProvider>
                                </Paper>
                            }
                        />
                        {/* ------------------------------------------------------------------ */}

                        {/* <Paper>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box
                                        sx={{
                                            backgroundColor: "grey.200",
                                            borderRadius: 2,
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Box width={"60%"} padding={3}>
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <GlobalSelectField
                                                    options={{
                                                        formik: formik,
                                                        label: "Course Type",
                                                        name: "CourseTypeId",
                                                        // sx: { width: "100%" },
                                                        data: pro,

                                                        keys: {
                                                            id: "id",
                                                            value: "name",
                                                        },
                                                    }}
                                                />
                                                <GlobalSelectField
                                                    options={{
                                                        formik: formik,
                                                        label: "CRO",
                                                        name: "croId",
                                                        // sx: { width: "100%" },
                                                        data: cros?.AssignedCros,

                                                        keys: {
                                                            id: "id",
                                                            value: "name",
                                                        },
                                                    }}
                                                />
                                                <Box paddingTop={1.2}>
                                                    <SubmitButton type="submit">
                                                        Submit
                                                    </SubmitButton>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Paper> */}
                        {/* ------------------------------------------------------------------------------------------ */}

                        <CustomDataGrid
                            loading={datas.loading}
                            data={datas.pendingEnquiries}
                            rowId={"serialNumber"}
                            columns={columns}
                            Head={Head}
                            setSelectedRows={setSelectedRows}
                            selectedRows={selectedRows}
                            label={"AdNo"}
                            chipValue={selectedRows[0]?.admissionNo}
                            name={selectedRows[0]?.name}
                            details={<Details data={selectedRows[0]} />}
                            actions={<Actions data={selectedRows[0]} />}
                        />
                    </>
                }
            />
        </div>
    );
}
