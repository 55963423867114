import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import {
    editDate,
    getStudentBaseCourses,
} from "../../../redux/features/sessionSlice";
import { useSnackbar } from "notistack";

const EditDateAction = ({ params }) => {
    const dispatch = useDispatch();
    const { singleStudentDetails } = useSelector((state) => state.sessions);
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(new Date(params.row.startDate));
    const [value, setValue] = useState(params.row.startDate);
    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDateDateChange = (e) => {
        setDate(e);
        setValue(e.toISOString().split("T")[0]);
    };

    const handleSubmit = () => {
        handleClose();
        dispatch(
            editDate({ subEId: params.row.subjectEnrollmentId, newDate: value })
        ).then((res) => {
            if ((res.payload.status = "success")) {
                enqueueSnackbar(res.payload.message, { variant: "success" });
                dispatch(
                    getStudentBaseCourses(singleStudentDetails.admissionNumber)
                );
            } else {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            }
        });
    };

    return (
        <div>
            {params.row.startDate}
            <Button onClick={handleClickOpen}>
                <Icon
                    icon="material-symbols:edit-note-rounded"
                    width="45"
                    height="45"
                />
            </Button>
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="Select Date"
                                inputFormat="YYYY/MM/DD"
                                disablePast
                                value={date}
                                mask=""
                                onChange={handleDateDateChange}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        {...params}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </DialogTitle>

                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button autoFocus onClick={handleSubmit}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};

export default EditDateAction;
