import React, { useEffect, useState } from "react";
import Page from "../../../Page";
import BasicTextField from "../../../mui/TextField";
import { Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    addExpectedSessions,
    getExpectedSessions,
} from "../../../../redux/features/croSlice";
import { useSnackbar } from "notistack";

const AddExpectedSessionsForm = ({ setOpen, row }) => {
    const [sessions, setSessions] = useState("");
    const [error, setError] = useState(false);
    const { filterDate } = useSelector((state) => state.cro);
    const { data: userDetails } = useSelector((state) => state.login);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (sessions) {
            dispatch(
                addExpectedSessions({
                    courseEnrollmentId: row.courseEnrollmentId,
                    year: filterDate.year,
                    month: filterDate.month,
                    expectedSessions: sessions,
                })
            ).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    setOpen(false);
                    dispatch(
                        getExpectedSessions({
                            date: `${filterDate.year}/${filterDate.month}`,
                            croId: userDetails.profile.userId,
                        })
                    );
                } else {
                    enqueueSnackbar("Something went wrong please try again!!", {
                        variant: "error",
                    });
                }
            });
        } else {
            setError(true);
        }
    };

    const handleChange = (e) => {
        if (e.target.value) {
            setSessions(e.target.value);
            setError(false);
        } else {
            setSessions("");
            setError(true);
        }
    };

    return (
        <Page
            sx={{
                minWidth: "700px",
                padding: "20px",
            }}
        >
            <Typography variant="h4" sx={{ mb: 1 }}>
                Add Expected Session
            </Typography>
            <form onSubmit={handleSubmit}>
                <Stack>
                    <BasicTextField
                        sx={{
                            width: "100%",
                            marginBottom: "10px",
                        }}
                        label="Expected Sessions"
                        type="number"
                        action={handleChange}
                        error={Boolean(error)}
                        helperText={error && "Please fill expected sessions"}
                    />
                    <Button
                        variant="contained"
                        sx={{
                            display: "inline-block",
                            margin: "0 auto",
                        }}
                        type="submit"
                    >
                        Update
                    </Button>
                </Stack>
            </form>
        </Page>
    );
};

export default AddExpectedSessionsForm;
