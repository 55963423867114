import React from "react";
import { Icon } from "@iconify/react";
import { Chip, Grid } from "@mui/material";
import {
  FirstInterviewAssignForm,
  InterviewAssignForm,
  SecondInterviewAssignForm,
  ThirdInterviewAssignForm,
} from "./InterviewAssignForm";
import {
  InitialScreeningForm,
  InitialScreeningFormUpdate,
} from "./InitialScreeningForm";
import {
  getCvStatusPreData,
  getInterviewrs,
  getUsers,
} from "../../../../redux/features/jobApplicationSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "@mui/material/Rating";
import {
  FirstInterviewStatusChip,
  InitialScreeningStatusChip,
  TestStatusChip,
  SecondInterviewStatusChip,
  ThirdInterviewStatusChip,
  ConfirmationStatusChip,
  OfferLetterStatusChip,
  JoiningStatusChip,
} from "./StatusChip";
import { TestForm, TestFormUpdate } from "./TestForm";
import { Interview1Form, Interview1FormUpdate } from "./Interview1Form";
import { Interview2Form, Interview2FormUpdate } from "./Interview2Form";
import { Interview3Form, Interview3FormUpdate } from "./Interview3Form";
import { OfferLetterForm } from "./OfferLetterForm";
import { ConfirmationForm } from "./ConfirmationForm";
import { getAllUsers } from "../../../../redux/features/userSlice";
import { JoiningForm } from "./JoiningForm";
import InterviewDetailsCard from "./InterviewCards/InterviewDetailsCard";
import InterviewInitialCard from "./InterviewCards/InterviewInitialCard";
import InterviewInitialCard2 from "./InterviewCards/InterviewInitialCard2";

export default function InterviewProcedureCards({ singleData, params }) {
  const dispatch = useDispatch();
  const [assignForm, setAssignForm] = React.useState(false);
  const [initialScreening, setInitialScreening] = React.useState(false);
  const [testForm, setTestForm] = React.useState(false);
  const [firstInterview, setFirstInterview] = React.useState(false);
  const [firstInterviewAssignForm, setFirstInterviewAssignForm] =
    React.useState(false);
  const [secondInterviewAssignForm, setSecondInterviewAssignForm] =
    React.useState(false);
  const [secondInterview, setSecondInterview] = React.useState(false);
  const [thirdInterviewAssignForm, setThirdInterviewAssignForm] =
    React.useState(false);
  const [thirdInterview, setThirdInterview] = React.useState(false);
  const [confirmationForm, setConfirmationForm] = React.useState(false);
  const [offerLetterForm, setOfferLetterForm] = React.useState(false);
  const [joiningForm, setJoiningForm] = React.useState(false);

  const handleOpenAssignForm = () => {
    dispatch(getInterviewrs());
    setAssignForm(true);
  };

  const handleTestForm = () => {
    dispatch(getCvStatusPreData(3));
    setTestForm(true);
  };
  const handleFirstInterview = () => {
    dispatch(getCvStatusPreData(4));
    setFirstInterview(true);
  };
  return (
    <div>
      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.CVStatus ? (
            <InterviewDetailsCard
              title={"Resume Screening"}
              Interviewer={singleData.CVUpdatedBy}
              date={singleData.CVUpdatedOn}
              disabled={true}
              status={
                <Chip
                  icon={<Icon icon="octicon:verified-24" />}
                  variant="oulined"
                  label="Selected"
                  color="success"
                />
              }
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.initUpdatedOn ? (
            <InterviewDetailsCard
              title={"Initial Screening"}
              Interviewer={singleData.initScreeningBy}
              date={singleData.initScreeningDate}
              status={<InitialScreeningStatusChip singleData={singleData} />}
              form={
                <InitialScreeningForm
                  singleData={singleData}
                  setInitialScreening={setInitialScreening}
                  initialScreening={initialScreening}
                  params={params}
                />
              }
              action={() => {
                dispatch(getCvStatusPreData(2));
                setInitialScreening(true);
              }}
            />
          ) : (
            <InterviewInitialCard
              title={"Initial Screening"}
              message={"  Initial Screening not taken..!"}
              checkAssigned={singleData.initScreeningBy}
              assignedby={singleData.initScreeningBy}
              assigneddate={singleData.initScreeningDate}
              disableUpdateButton={singleData.initAssignedOn ? false : true}
              interviewformaction={() => {
                dispatch(getCvStatusPreData(2));
                setInitialScreening(true);
              }}
              assignformaction={handleOpenAssignForm}
              assignform={
                <InterviewAssignForm
                  assignForm={assignForm}
                  setAssignForm={setAssignForm}
                  params={params}
                />
              }
              interviewform={
                <InitialScreeningForm
                  setInitialScreening={setInitialScreening}
                  initialScreening={initialScreening}
                  params={params}
                />
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.testStatus !== null ? (
            <InterviewDetailsCard
              title={"Test"}
              Interviewer={singleData.testUpdatedBy}
              date={singleData.testUpdatedOn}
              status={<TestStatusChip singleData={singleData} />}
              action={handleTestForm}
              form={
                <TestFormUpdate
                  testForm={testForm}
                  setTestForm={setTestForm}
                  singleData={singleData}
                  params={params}
                />
              }
            />
          ) : (
            <InterviewInitialCard2
              title={"Test"}
              message={"Test not taken..!"}
              disabled={
                singleData.initScreeningStatus === "Qualified" ? false : true
              }
              upadteaction={handleTestForm}
              updateform={
                <TestFormUpdate
                  testForm={testForm}
                  setTestForm={setTestForm}
                  singleData={singleData}
                  params={params}
                />
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.interview1Status ? (
            <InterviewDetailsCard
              title={" Interview 1"}
              Interviewer={singleData.interviewerOf1}
              date={singleData.interview1UpdatedOn}
              status={<FirstInterviewStatusChip singleData={singleData} />}
              action={handleFirstInterview}
              rating={
                <Rating
                  backgroundcolor="#fff"
                  name="read-only"
                  value={singleData.interview1Rating}
                  readOnly
                />
              }
              form={
                <Interview1Form
                  firstInterview={firstInterview}
                  setFirstInterview={setFirstInterview}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          ) : (
            <InterviewInitialCard
              title={"interview 1"}
              message={" Interview 1 not taken..!"}
              checkAssigned={singleData.interview1ScheduledBy}
              assignedby={singleData.interviewerOf1}
              assigneddate={singleData.interview1On}
              disableAssignButton={
                singleData.testStatus === "Qualified" ||
                singleData.testStatus === "Not Applicable"
                  ? false
                  : true
              }
              disableUpdateButton={
                singleData.interview1ScheduledBy ? false : true
              }
              interviewformaction={handleFirstInterview}
              assignformaction={() => {
                setFirstInterviewAssignForm(true);
              }}
              assignform={
                <FirstInterviewAssignForm
                  setFirstInterviewAssignForm={setFirstInterviewAssignForm}
                  firstInterviewAssignForm={firstInterviewAssignForm}
                  params={params}
                />
              }
              interviewform={
                <Interview1Form
                  firstInterview={firstInterview}
                  setFirstInterview={setFirstInterview}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.interview2Status ? (
            <InterviewDetailsCard
              title={"Interview 2"}
              Interviewer={singleData.interviewerOf2}
              date={singleData.interview2UpdatedOn}
              status={<SecondInterviewStatusChip singleData={singleData} />}
              action={() => {
                dispatch(getCvStatusPreData(5));
                setSecondInterview(true);
              }}
              rating={
                <Rating
                  backgroundcolor="#fff"
                  name="read-only"
                  value={singleData.interview2Rating}
                  readOnly
                />
              }
              form={
                <Interview2Form
                  secondInterview={secondInterview}
                  setSecondInterview={setSecondInterview}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          ) : (
            <InterviewInitialCard
              title={" Interview 2"}
              message={" Interview 2 not taken..!"}
              checkAssigned={singleData.interview2ScheduledBy}
              assignedby={singleData.interviewerOf2}
              assigneddate={singleData.interview2On}
              disableAssignButton={
                singleData.interview1Status === "Qualified" ? false : true
              }
              disableUpdateButton={
                singleData.interview2ScheduledBy ? false : true
              }
              interviewformaction={() => {
                dispatch(getCvStatusPreData(5));
                setSecondInterview(true);
              }}
              assignformaction={() => {
                setSecondInterviewAssignForm(true);
              }}
              assignform={
                <SecondInterviewAssignForm
                  setSecondInterviewAssignForm={setSecondInterviewAssignForm}
                  secondInterviewAssignForm={secondInterviewAssignForm}
                  params={params}
                />
              }
              interviewform={
                <Interview2Form
                  secondInterview={secondInterview}
                  setSecondInterview={setSecondInterview}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.interview3Status ? (
            <InterviewDetailsCard
              title={"Interview 3"}
              Interviewer={singleData.interviewerOf3}
              date={singleData.interview3UpdatedOn}
              status={<ThirdInterviewStatusChip singleData={singleData} />}
              action={() => {
                dispatch(getCvStatusPreData(6));
                setThirdInterview(true);
              }}
              rating={
                <Rating
                  backgroundcolor="#fff"
                  name="read-only"
                  value={singleData.interview3Rating}
                  readOnly
                />
              }
              form={
                <Interview3Form
                  thirdInterview={thirdInterview}
                  setThirdInterview={setThirdInterview}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          ) : (
            <InterviewInitialCard
              title={"  Interview 3"}
              message={" Interview 3 not taken..!"}
              checkAssigned={singleData.interview3ScheduledBy}
              assignedby={singleData.interviewerOf3}
              assigneddate={singleData.interview3On}
              disableAssignButton={
                singleData.interview2Status === "Qualified" ? false : true
              }
              disableUpdateButton={
                singleData.interview3ScheduledBy ? false : true
              }
              interviewformaction={() => {
                dispatch(getCvStatusPreData(6));
                setThirdInterview(true);
              }}
              assignformaction={() => {
                dispatch(getUsers());

                setThirdInterviewAssignForm(true);
              }}
              assignform={
                <ThirdInterviewAssignForm
                  setThirdInterviewAssignForm={setThirdInterviewAssignForm}
                  thirdInterviewAssignForm={thirdInterviewAssignForm}
                  params={params}
                />
              }
              interviewform={
                <Interview3Form
                  thirdInterview={thirdInterview}
                  setThirdInterview={setThirdInterview}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.oralStatusBy ? (
            <InterviewDetailsCard
              title={" Oral Confirmation"}
              Interviewer={singleData.oralStatusBy}
              date={singleData.oralStatusUpdatedOn}
              status={<ConfirmationStatusChip singleData={singleData} />}
              action={() => {
                dispatch(getCvStatusPreData(7));
                setConfirmationForm(true);
              }}
              form={
                <ConfirmationForm
                  confirmationForm={confirmationForm}
                  setConfirmationForm={setConfirmationForm}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          ) : (
            <InterviewInitialCard2
              title={" Confirmation"}
              message={" Confirmation not taken..!"}
              disabled={
                singleData.interview3Status === "Qualified" ||
                singleData.interview3Status === "Not Applicable"
                  ? false
                  : true
              }
              upadteaction={() => {
                dispatch(getCvStatusPreData(7));
                setConfirmationForm(true);
              }}
              updateform={
                <ConfirmationForm
                  confirmationForm={confirmationForm}
                  setConfirmationForm={setConfirmationForm}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.offerLetterIssuedBy ? (
            <InterviewDetailsCard
              title={"Offer Letter"}
              Interviewer={singleData.offerLetterIssuedBy}
              date={singleData.offerLetterStatusUpdatedOn}
              status={<OfferLetterStatusChip singleData={singleData} />}
              action={() => {
                dispatch(getCvStatusPreData(8));
                setOfferLetterForm(true);
              }}
              form={
                <OfferLetterForm
                  setOfferLetterForm={setOfferLetterForm}
                  offerLetterForm={offerLetterForm}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          ) : (
            <InterviewInitialCard2
              title={"Offer Letter"}
              message={"Offer Letter not taken..!"}
              disabled={singleData.oralCnfStatus === "Accepted" ? false : true}
              upadteaction={() => {
                dispatch(getCvStatusPreData(8));
                setOfferLetterForm(true);
              }}
              updateform={
                <OfferLetterForm
                  setOfferLetterForm={setOfferLetterForm}
                  offerLetterForm={offerLetterForm}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          {singleData.finalStatusUpdatedOn ? (
            <InterviewDetailsCard
              title={"Joining"}
              Interviewer={singleData.finalStatusUpdatedby}
              date={singleData.finalStatusUpdatedOn}
              status={<JoiningStatusChip singleData={singleData} />}
              action={() => {
                dispatch(getCvStatusPreData(9));
                setJoiningForm(true);
              }}
              form={
                <JoiningForm
                  setJoiningForm={setJoiningForm}
                  joiningForm={joiningForm}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          ) : (
            <InterviewInitialCard2
              title={" Joining"}
              message={"Joining not taken..!"}
              disabled={
                singleData.offerLetterStatus === "Accepted" ? false : true
              }
              upadteaction={() => {
                dispatch(getCvStatusPreData(9));
                setJoiningForm(true);
              }}
              updateform={
                <JoiningForm
                  setJoiningForm={setJoiningForm}
                  joiningForm={joiningForm}
                  params={params}
                  singleData={singleData}
                />
              }
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
