import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDayWiseSessions } from "../../../redux/features/croSlice";
import { DAY_WISE_SESSIONS } from "../../../../src/components/datagrid/myworkspace/cro/DayWiseSessionsTableHead";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";

import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export default function DayWiseSessions() {
    const data = useSelector((state) => state.cro);
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const [value, setValue] = React.useState(dayjs().format("YYYY-MM-DD"));
    const profile = JSON.parse(localStorage.getItem("cred")).profile;

    const handleChange = (newValue) => {
        setValue(newValue);
        const data = {
            croId: profile.userId,
            date: dayjs(value).format("YYYY-MM-DD"),
        };

        dispatch(getDayWiseSessions(data));
    };

    useEffect(() => {
        dispatch(getDayWiseSessions({ date: value, croId: profile.userId }));
    }, []);

    return (
        <div>
            <Wrapper
                title="DayWise Sessions"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                marginBottom: 2,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Date"
                                    openTo="year"
                                    views={["year", "month", "day"]}
                                    inputFormat="YYYY/MM/DD"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params) => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                components={{ Toolbar: CustomGridToolbar }}
                                loading={data.loading}
                                autoHeight
                                sx={{
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1,
                                    },
                                }}
                                rows={data.dayWiseSessions}
                                columns={DAY_WISE_SESSIONS}
                                getRowId={(row) => row.serialNumber}
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
