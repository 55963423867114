import { SubjectEnrollmentPopupView } from "../RenderCellMain";

export const STUDENT_ENROLLMENT_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "SlNo",
        width: 50,
    },
    {
        field: "admissionNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "courseName",
        headerName: "Course Name",
        width: 200,
    },

    {
        field: "noOfEnrollments",
        headerName: "No Of Enrollments",
        width: 150,
    },

    {
        field: "",
        headerName: "Actions",
        width: 150,
        renderCell: (params) => <SubjectEnrollmentPopupView {...params.row} />,
    },
];
