import React from "react";
import { Mytable, Rows } from "../../../../../components/mui/DataGrid/Details";
import { Box, Grid, TextField } from "@mui/material";

export const Details = ({ data }) => {
    return (
        <div>
            <Box>
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Class" value={data?.class} />
                                <Rows label="mobile" value={data?.mobile} />
                                <Rows label="course" value={data?.course} />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Subject" value={data?.subject} />
                                <Rows
                                    label="Enquiry Type"
                                    value={data?.enquiryType}
                                />
                                <Rows
                                    label="Enquiry Date"
                                    value={data?.enquiryDate}
                                />
                            </>
                        }
                    />
                </Grid>

                <Box padding={2}>
                    <TextField
                        variant="filled"
                        label="Enquiry Remarks"
                        fullWidth
                        multiline
                        rows={5}
                        InputProps={{ readOnly: true }}
                        value={data?.enquiryRemarks}
                    />
                </Box>
            </Box>
        </div>
    );
};
