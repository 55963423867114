import { Box } from "@mui/material";
import React from "react";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { TERMINATION_CLEARANCE_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/TerminationClearance/TerminationClearanceTableHead";
import { Details } from "./Details";
import { Actions } from "./Actions";

const data = [
    {
        id: 1,
        admissionNo: 6049,
        name: "AZIM JANSHID",
        mobile: "8848456953",
        class: 6,
        course: "Classroom - Short Term",
        croName: "DIYA FATHIMA.T U",
        inactiveType: "Course Completed	",
        inactiveDate: "2023-02-12",
        inactiveRemark:
            "total sale Arabic -40 session -23499 /- completed 32 sessions. 32 session aayappol thanne complete chapters and revision kayinju, so they stop their class",
        inactiveMarkedTime: "2023-02-28 16:24:09",
        approval: "",
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export const TerminationClearance = () => {
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Admission Close List"
                Content={
                    <>
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.admissionNo}
                                label="AdNo"
                                name={selectedRows[0]?.name}
                                details={<Details data={selectedRows[0]} />}
                                actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
};
