import React from "react";
import { projectStatus } from "../../redux/features/mastersSlice";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
export default function ProjectStatus({ params }) {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.isActive}
                api={projectStatus(params.projectId)}
            />
        </div>
    );
}
