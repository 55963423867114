import React, { useState } from "react";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { DataGrid } from "@mui/x-data-grid";
import Page from "../../../components/Page";
import { Box, Paper, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { NOT_MARKED_ATTENDANCE_TBL_HEAD } from "../../../components/datagrid/myworkspace/cro/CroNotMarkedAttTableHead";

const CroNotMarkedAttendance = () => {
    const [gridSize, setGridSize] = useState(5);

    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Daily Not Marked Attendance in Active Students
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                justifyContent: "right",
                                padding: "10px 10px",
                            }}
                        ></Box>

                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                rows={[]}
                                columns={NOT_MARKED_ATTENDANCE_TBL_HEAD}
                                autoHeight
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                                components={{ Toolbar: CustomGridToolbar }}
                                sortModel={[
                                    {
                                        field: "serialNumber",
                                        sort: "asc",
                                    },
                                ]}
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default CroNotMarkedAttendance;
