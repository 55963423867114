import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";

const ActionDetails = ({ label, val }) => {
  return (
    <div>
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <Box padding={2}>
          <Box
            sx={{
              backgroundColor: "grey.200",
              borderRadius: 3,
            }}
          >
            <List>
              <ListItem>
                <ListItemIcon>
                  <Icon icon="tabler:list-details" />
                </ListItemIcon>
                {/* <ListItemText>
                  <Typography fontWeight="bolder" fontSize={12}>
                    {label}
                  </Typography>
                </ListItemText> */}

                <Typography fontWeight="bolder" fontSize={12}>
                  {val}
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default ActionDetails;
