export const RETAIN_CALL_ANALYTICS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "cro",
        headerName: "CRO",
        width: 150,
    },
    {
        field: "totalAssigned",
        headerName: "Total Assigned",
        width: 150,
    },
    {
        field: "pending",
        headerName: "Pending",
        width: 150,
    },
    {
        field: "retained",
        headerName: "Retained",
        width: 150,
    },
    {
        field: "retainable",
        headerName: "Retainable",
        width: 150,
    },
    {
        field: "notRetainable",
        headerName: "Not Retainable",
        width: 150,
    },
    {
        field: "satisfiedNotRetainable",
        headerName: "Satisfied Not Retainable",
        width: 150,
    },
    {
        field: "feeIssue",
        headerName: "Fee Issue",
        width: 150,
    },
];
