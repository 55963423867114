import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../components/Wrapper/GlobalWrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";

import { useDispatch, useSelector } from "react-redux";
import { getAllDescriptions } from "../../../redux/features/assessmentToolSlice";

import CreateUpdateGrade from "../../../components/datagrid/myworkspace/speakpro/grade/CreateUpdateGrade";
import { DESCRIPTION_TABLE_HEAD } from "../../../components/datagrid/myworkspace/speakpro/description/DescriptionTableHead";
import CreateUpdateDescription from "../../../components/datagrid/myworkspace/speakpro/description/CreateUpdateDescription";

const Description = () => {
  const [gridSize, setGridSize] = useState(10);
  const dispatch = useDispatch();
  const { descriptions, loading } = useSelector(
    (state) => state.assessmentTool
  );
  const profile = JSON.parse(localStorage.getItem("cred")).profile;
  useEffect(() => {
    dispatch(getAllDescriptions(profile?.departmentId));
  }, []);

  return (
    <GlobalWrapper title={"Description"} actions={<CreateUpdateDescription />}>
      <Paper elevation={3}>
        <Box sx={{ width: "100%", padding: 0 }}>
          <DataGrid
            disableVirtualization
            components={{ Toolbar: CustomGridToolbar }}
            loading={loading}
            autoHeight
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
            }}
            getRowHeight={() => "auto"}
            rows={descriptions}
            columns={DESCRIPTION_TABLE_HEAD(descriptions)}
            getRowId={(row) => row.serialNumber}
            pageSize={gridSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  );
};

export default Description;
