import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    data: [],
    singleschedule: {},
    message: [],
    res: {},
    filter: "all",
    loading: false,
    previousSession: [],
    subjectEnrollmentId: [],
};

export const scheduleSession = createAsyncThunk(
    "schedule/scheduleSession",
    async (finalData) => {
        const res = await apiRequest({
            method: "POST",
            url: "sessions/schedules",
            data: {
                ...finalData,
            },
        });
        return res;
    }
);

export const previousSession = createAsyncThunk(
    "schedule/previousSession",
    async (id) => {
        const res = await apiRequest({
            method: "GET",
            url: `sessions/schedules/${id}`,
        });

        return res;
    }
);

export const deleteSession = createAsyncThunk(
    "schedule/deleteSession",
    async (id) => {
        const res = await apiRequest({
            method: "DELETE",
            url: `sessions/schedules/${id}`,
        });

        return res;
    }
);

export const getScheduleById = createAsyncThunk(
    "schedule/getScheduleById",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `sessions/schedules/${id}/student-schedule`,
        });

        return res;
    }
);

export const editSchedule = createAsyncThunk(
    "schedule/editSchedule",
    async (data) => {
        const res = await apiRequest({
            method: "PATCH",
            url: `sessions/schedules/${data.id}`,
            data: {
                startDate: data.startDate,
                startTime: data.startTime,
                sessionContent: data.sessionContent,
                meetLink: data.meetLink,
            },
        });
        console.log(res);

        return res;
    }
);

export const getLastSessionDate = createAsyncThunk(
    "schedule/getLastSessionDate",
    async (data) => {
        const res = await apiRequest({
            method: "get",
            url: `/sessions/last-session/${data}`,
        });
        return res;
    }
);

const schedulesSlice = createSlice({
    name: "schedule",
    initialState: initialState,
    reducers: {
        filterSchedules(state, action) {
            state.filter = action.payload;
        },
        clearSchedule(state) {
            state.data = [];
            state.filter = "all";
        },
        clearRes(state) {
            state.res = [];
        },
        subjectEnrollment(state, action) {
            return {
                ...state,
                subjectEnrollmentId: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(scheduleSession.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(scheduleSession.fulfilled, (state, action) => {
            state.loading = false;

            if (action.payload.status === 200) state.res = action.payload;
        });
        builder.addCase(scheduleSession.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //----------------previousSession-------------------------------
        builder.addCase(previousSession.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(previousSession.fulfilled, (state, action) => {
            state.loading = false;
            state.previousSession = action.payload.data.studentSessions;
        });
        builder.addCase(previousSession.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //-------------------delete-session--------------------------//
        builder.addCase(deleteSession.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteSession.fulfilled, (state, action) => {
            if (action.payload.status === "success") {
                const rowToUpdateIndex = state.data.findIndex(function (item) {
                    return item.schedule_id === action.meta.arg;
                });
                state.data.splice(rowToUpdateIndex, 1);
            }
            state.res = action.payload;
            state.loading = false;
        });
        builder.addCase(deleteSession.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //--------------------------getScheduleById---------------------------
        builder.addCase(getScheduleById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getScheduleById.fulfilled, (state, action) => {
            state.singleschedule = action.payload.data.studentSession;
            state.loading = false;
        });
        builder.addCase(getScheduleById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //---------------------editSchedule----------------------------------------
        builder.addCase(editSchedule.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editSchedule.fulfilled, (state, action) => {
            state.res = action.payload;
            console.log(state.res);
            state.loading = false;
        });
        builder.addCase(editSchedule.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------------getLastSessionDate---------------------------------------------

        builder.addCase(getLastSessionDate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getLastSessionDate.fulfilled, (state, action) => {
            state.res = action.payload;
            state.loading = false;
        });
        builder.addCase(getLastSessionDate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});
// Action creators are generated for each case reducer function
export const { filterSchedules, clearSchedule, clearRes, subjectEnrollment } =
    schedulesSlice.actions;
export default schedulesSlice.reducer;
