import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    EditButton,
    SaveButton,
} from "../mui/Buttons/Buttons";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { getUsers } from "../../redux/features/jobApplicationSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
    addTextPublisher,
    editTextPublisher,
    getTextPublisher,
} from "../../redux/features/mastersSlice";
import { useSnackbar } from "notistack";

export const EditTextPublisher = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const loading = useSelector((state) => state.masters.loading);

    const Schema = Yup.object().shape({
        textPublisherName: Yup.string().required(
            "textPublisherName is required"
        ),
        address1: Yup.string().required("address1 is required"),
        address2: Yup.string().required("address2 is required"),
        address3: Yup.string().required("address3 is required"),
        description: Yup.string().required("description is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            textPublisherId: params.text_publisher_id,
            textPublisherName: params.text_publisher_name || "",
            address1: params.address_1 || "",
            address2: params.address_2 || "",
            address3: params.address_3 || "",
            description: params.text_publisher_descr || "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            dispatch(editTextPublisher(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getTextPublisher());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                title="Add Text Publisher"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <EditButton
                        size="small"
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Edit
                    </EditButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <TextField
                                            label="Text Publisher Name"
                                            fullWidth
                                            name="textPublisherName"
                                            value={
                                                formik.values.textPublisherName
                                            }
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched
                                                    .textPublisherName &&
                                                Boolean(
                                                    formik.errors
                                                        .textPublisherName
                                                )
                                            }
                                            helperText={
                                                formik.errors.textPublisherName
                                            }
                                        />
                                        <TextField
                                            label="Address 1"
                                            fullWidth
                                            name="address1"
                                            value={formik.values.address1}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.address1 &&
                                                Boolean(formik.errors.address1)
                                            }
                                            helperText={formik.errors.address1}
                                        />
                                        <TextField
                                            label="Address 2"
                                            fullWidth
                                            name="address2"
                                            value={formik.values.address2}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.address2 &&
                                                Boolean(formik.errors.address2)
                                            }
                                            helperText={formik.errors.address2}
                                        />
                                        <TextField
                                            label="Address 3"
                                            fullWidth
                                            name="address3"
                                            value={formik.values.address3}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.address3 &&
                                                Boolean(formik.errors.address3)
                                            }
                                            helperText={formik.errors.address3}
                                        />
                                        <TextField
                                            label="description"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.description &&
                                                Boolean(
                                                    formik.errors.description
                                                )
                                            }
                                            helperText={
                                                formik.errors.description
                                            }
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack spacing={2} direction={"row"}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
