import React, { useEffect } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
    AddButton,
    CancelButton,
    SubmitButton,
} from "../../../../mui/Buttons/Buttons";
import {
    Autocomplete,
    Box,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    addStudentToMobileApp,
    getMobileAppStudents,
    getCROStudentsNotInApp,
    getCROStudentsEmailIds,
} from "../../../../../redux/features/croSlice";

import { useSnackbar } from "notistack";
import Iconify from "../../../../color-utils/Iconify";

export const AddNewStudent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.cro.loading);
    const [open, setOpen] = React.useState(false);
    const students = useSelector((state) => state.cro);
    const profile = JSON.parse(localStorage.getItem("cred")).profile;

    useEffect(() => {
        dispatch(getCROStudentsNotInApp());
        dispatch(getCROStudentsEmailIds());
    }, []);

    const CreateSchema = Yup.object().shape({
        admnNo: Yup.string().required("Admission Number Required"),
        email: Yup.string().required("Email required"),
        password: Yup.string()
            .required("Password required")
            .min(6, "Password is too short - should be 6 chars minimum."),
    });

    const formik = useFormik({
        initialValues: {
            studentName: "",
            leadRef: "",
            admnNo: "",
            email: "",
            password: "",
        },
        validationSchema: CreateSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(addStudentToMobileApp(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getMobileAppStudents(profile.userId));
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    const emailToFind = formik.values.email;
    const emails = students.croStudentsEmailIds;
    const findItemByEmail = (email) => {
        return emails.find((item) => item.email === email);
    };
    const foundItem = findItemByEmail(emailToFind);
    const validate = Boolean(foundItem);
    const [showPassword, setShowPassword] = React.useState(false);
    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <div>
            <GlobalDialog
                title="Add New Student"
                open={open}
                setOpen={setOpen}
                fullWidth
                closeButton={true}
                maxWidth={"sm"}
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box padding={3}>
                                    <Stack direction={"column"} spacing={2}>
                                        <Box>
                                            <Autocomplete
                                                disablePortal
                                                name="studentName"
                                                id="combo-box-demo"
                                                options={
                                                    students.croStudentsNotInApp
                                                }
                                                getOptionLabel={(option) =>
                                                    option.student
                                                }
                                                onChange={(e, option) => {
                                                    formik.setFieldValue(
                                                        "admnNo",
                                                        option.admissionNumber
                                                    );
                                                    formik.setFieldValue(
                                                        "leadRef",
                                                        option.leadId
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        disabled
                                                        fullWidth
                                                        {...params}
                                                        label="Student Name"
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                label="Admission No"
                                                name="admnNo"
                                                variant="filled"
                                                value={formik.values.admnNo}
                                                onChange={formik.handleChange}
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                error={
                                                    formik.touched.admnNo &&
                                                    Boolean(
                                                        formik.errors.admnNo
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.admnNo
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                label="Email"
                                                type="email"
                                                fullWidth
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                error={
                                                    (formik.touched.email &&
                                                        Boolean(
                                                            formik.errors.email
                                                        )) ||
                                                    validate
                                                }
                                                helperText={
                                                    formik.values.email === ""
                                                        ? "Email Required"
                                                        : "" ||
                                                          validate === false
                                                        ? ""
                                                        : "Email Already Exist"
                                                }
                                            />
                                        </Box>
                                        <Box>
                                            <TextField
                                                label="Password"
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                name="password"
                                                fullWidth
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={
                                                                    handleShowPassword
                                                                }
                                                                edge="end"
                                                            >
                                                                <Iconify
                                                                    icon={
                                                                        showPassword
                                                                            ? "eva:eye-fill"
                                                                            : "eva:eye-off-fill"
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                error={
                                                    formik.touched.password &&
                                                    Boolean(
                                                        formik.errors.password
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.password
                                                }
                                            />
                                        </Box>
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SubmitButton type="submit">
                                                {loading
                                                    ? "Submitting..."
                                                    : " Add Student"}
                                            </SubmitButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
                popupButton={
                    <AddButton action={() => setOpen(true)}>
                        Add New Student
                    </AddButton>
                }
            />
        </div>
    );
};
