import React, { useEffect, useState, useMemo } from "react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { Box, Stack, Paper, Typography, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import {
  getFoodList,
  getEveningFoodList,
  getFoodStatus,
} from "../../../../redux/features/foodSlice";
import { useDispatch, useSelector } from "react-redux";
import { FoodTableColumn } from "../../../../components/datagrid/myworkspace/hr/Food/FoodTableColumn";
import dayjs from "dayjs";
import DatePickerCustom from "../../../../components/DatePicker/DatePickerCustom";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import useResponsive from "../../../../Hooks/useResponsive";
import MealStatus from "./MealStatus";

const FoodManagement = () => {
  const [direction, setDirection] = useState("row");
  const mdDown = useResponsive("down", "md");
  const [gridSize, setGridSize] = useState(10);
  const dispatch = useDispatch("");
  const {
    loading,
    foodList,
    foodStatus,
  } = useSelector((store) => store.food);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedFoodCategoryId, setSelectedFoodCategoryId] = useState(1);
  const memoizedFoodList = useMemo(() => foodList, [foodList]);
  const [isNonVegDay, setIsNonVegDay] = useState(false);
  const [vegCount, setVegCount] = useState(null);
  const [NonvegCount, setNonVegCount] = useState(null);

  let category = [
    { id: 1, value: "Lunch" },
    { id: 2, value: "Evening Food" },
  ];

  useEffect(() => {
    if (mdDown) {
      setDirection("column");
    } else {
      setDirection("row");
    }
  }, [mdDown]);

  useEffect(() => {
    dispatch(getFoodStatus(selectedDate)); //TO KNOW THAT THE CURRENT DATE IS NON VEG DAY
    if (selectedFoodCategoryId == 1) {
      dispatch(getFoodList({ date: selectedDate }));
    } else if (selectedFoodCategoryId == 2) {
      dispatch(getEveningFoodList({ date: selectedDate }));
    }
  }, [selectedDate, selectedFoodCategoryId]);

  useEffect(() => {
    if (isNonVegDay) {
      let vegCount = 0;
      let nonVegCount = 0;
      memoizedFoodList.forEach((data) => {
        if (data.food_category === 2) {
          vegCount++;
        } else if (data.food_category === 1) {
          nonVegCount++;
        }
      });
      setVegCount(vegCount);
      setNonVegCount(nonVegCount);
    }
  }, [isNonVegDay, memoizedFoodList]);

  const handleChangeFoodSlot = (e) => {
    setSelectedFoodCategoryId(e.target.value);
  };

  useEffect(() => {
    if (foodStatus.length > 0) {
      const isComingDayFoodAlreadyDefined =
        foodStatus[0].day_food_status_date == selectedDate;
      if (isComingDayFoodAlreadyDefined) {
        const isNon = foodStatus[0].day_food_meal_status == 2;
        setIsNonVegDay(isNon);
      }
    } else if (dayjs(selectedDate).get("day") === 5) {
      setIsNonVegDay(true);
    } else {
      setIsNonVegDay(false);
    }
  }, [foodStatus]);

  return (
    <GlobalWrapper title={"Food"} actions={<MealStatus/>} >
      <Stack
        gap={2}
        direction={direction}
        sx={{
          marginBottom: 2,
          justifyContent: "space-between",
          alignItems: mdDown ? "start" : "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <DatePickerCustom
            value={dayjs(selectedDate)}
            setValue={(date) => setSelectedDate(date.format("YYYY-MM-DD"))}
            viewDetails={["year", "month", "day"]}
          />
          <Box sx={{ marginLeft: 1 }}>
            <FormControl sx={{ width: "160px" }}>
              <InputLabel>Food Category</InputLabel>
              <Select
                name="foodCategory"
                label="Food Category"
                value={selectedFoodCategoryId}
                onChange={handleChangeFoodSlot}
              >
                {category?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box>
          <Typography variant="h5">
            {`${dayjs(selectedDate).$y} ${dayjs(selectedDate).format("MMMM")} ${
              dayjs(selectedDate).$D
            } ${dayjs(selectedDate).format("dddd")}`}
          </Typography>
          <Box>
            <Typography sx={{ textJustify: "center" }} variant="subtitle1">
              {`Total ${
                selectedFoodCategoryId == 1 ? `Lunch Count` : `Evening Count`
              }`}
              : {memoizedFoodList.length}
              
            </Typography>
            {selectedFoodCategoryId == 1 && isNonVegDay && (
              <Box display={"flex"}>
                <Typography sx={{textJustify: "center" }} variant="subtitle2">
                  {`NonVeg : ${NonvegCount}`}
                </Typography>
                <Typography sx={{ marginLeft:2,textJustify: "center" }} variant="subtitle2">
                  {`Veg : ${vegCount}`}
                </Typography>
                
              </Box>
            )}
          </Box>
        </Box>
      </Stack>

      <Box>
        <DataGrid
          components={{ Toolbar: CustomGridToolbar }}
          rows={memoizedFoodList}
          columns={FoodTableColumn}
          loading={loading}
          getRowId={(row) => row.serialNumber}
          pageSize={gridSize}
          autoHeight
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
        />
      </Box>
    </GlobalWrapper>
  );
};

export default FoodManagement;
