import React, { useState } from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { AddButton } from "../../../../components/mui/Buttons/Buttons";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import DatePickerCustom from "../../../../components/DatePicker/DatePickerCustom";
import dayjs from "dayjs";
import {
  addMealStatus,
  monthlyFoodStatus,
} from "../../../../redux/features/foodSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const AddMealStatus = ({date}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [selectedMeal, setSelectedMeal] = useState(2);
  const { loading } = useSelector((store) => store.food);
  const dispatch=useDispatch()

  let meal = [
    { id: 1, value: "Veg" },
    { id: 2, value: "Non Veg" },
  ];

  const handleChange = (e) => setSelectedMeal(e.target.value);
  const handleSubmit = () => {
    const data = {
      date: selectedDate,
      mealStatusId: selectedMeal,
    };

    dispatch(addMealStatus(data)).then((res)=>{
        if(res.payload.status=="failed" || res.payload.status=="error"){
         enqueueSnackbar(`${res.payload.message}`, { variant: "error" });
        }else{
            setOpen(false)
           dispatch(monthlyFoodStatus(date))
        }
    })
  };

  return (
    <GlobalDialog
      title={"Add Meal Status"}
      open={open}
      setOpen={setOpen}
      closeButton={true}
      popupButton={
        <>
          <AddButton action={() => setOpen(true)}>Add Meal Status</AddButton>
        </>
      }
      content={
        <Stack gap={2}>
          <Box>
            <DatePickerCustom
              value={dayjs(selectedDate)}
              setValue={(date) => setSelectedDate(date.format("YYYY-MM-DD"))}
              viewDetails={["year", "month", "day"]}
            />
          </Box>
          <Box>
            <FormControl sx={{ width: "160px" }}>
              <InputLabel>Meal Type</InputLabel>
              <Select
                name="mealType"
                label="Meal Type"
                value={selectedMeal}
                onChange={handleChange}
              >
                {meal?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <AddButton disabled={loading} action={() => handleSubmit()}>ADD</AddButton>
          </Box>
        </Stack>
      }
    />
  );
};

export default AddMealStatus;
