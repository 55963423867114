import TooltipRemarkViewer from "./TooltipRemarkViewer";

export const CR_LEDGER_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "date",
        headerName: "Date",
        minWidth: 150,
    },
    {
        field: "particulars",
        headerName: "Particulars",
        minWidth: 200,
    },
    {
        field: "income",
        headerName: "Income",
        minWidth: 200,
    },
    {
        field: "expense",
        headerName: "Expence",
        minWidth: 200,
    },
    {
        field: "notes",
        headerName: "Notes",
        minWidth: 300,
        renderCell: (params) => (
            <TooltipRemarkViewer value={params.row.notes} />
        ),
    },
    {
        field: "trainerPayment",
        headerName: "Trainer's Payment",
        minWidth: 200,
    },
    {
        field: "accountantVerification",
        headerName: "Accountant Verification",
        minWidth: 200,
        renderCell: (params) => (
            <p
                style={{
                    color: "green",
                }}
            >
                {params.row.accountantVerification}
            </p>
        ),
    },
];
