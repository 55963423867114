import { Box, Grid } from "@mui/material";
import React from "react";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";

export const Details = ({ data }) => {
    return (
        <div>
            <Box>
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Mobile" value={data?.mobile} />
                                <Rows label="Class" value={data?.class} />
                                <Rows label="Course" value={data?.course} />
                                <Rows label="CRO Name" value={data?.croName} />
                                <Rows
                                    label="Total Sales Amount"
                                    value={data?.totalSalesAmount}
                                />
                                <Rows
                                    label="Toatal Fee Collected"
                                    value={data?.toatalFeeCollected}
                                />
                                <Rows
                                    label="Total Sessions Completed"
                                    value={data?.totalSessionsCompleted}
                                />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows
                                    label="Last Session Date"
                                    value={data?.lastSessionDate}
                                />
                                <Rows
                                    label="Inactive Reason"
                                    value={data?.inactiveReason}
                                />
                                <Rows
                                    label="inactive Remark"
                                    value={data?.inactiveRemarks}
                                />
                                <Rows
                                    label="Inactive Marked Time"
                                    value={data?.inactiveMarkedTime}
                                />
                                <Rows
                                    label="Mode Of Tc Upload"
                                    value={data?.modeOfTcUpload}
                                />
                                <Rows
                                    label="Active Status On Other Course"
                                    value={data?.activeStatusOnOtherCourse}
                                />
                            </>
                        }
                    />
                </Grid>
            </Box>
        </div>
    );
};
