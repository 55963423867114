import React, { useEffect } from "react";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getCompWorkReq } from "../../../../redux/features/leaveSlice";

const CartLeads = () => {
  const dispatch = useDispatch();
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData;

  const data = {
    page: pageNum,
    limit: gridSize,
    search: search,
    openAnchor,
  };
  useEffect(() => {
    const datas = {
      ...data,
    };
    if (pageNum && gridSize) {
      dispatch(getCompWorkReq(datas));
    }
  }, [pageNum, gridSize, search]);
  return <GlobalWrapper title="Cart Leads"></GlobalWrapper>;
};

export default CartLeads;
