import React from "react";
import { AddCourseEnrollmentDetailed } from "../AddCourseEnrollmentDetailed";
import OgaRemarksDetailedView from "../OgaRemarksDetailedView";
import { GridBox, ListItemBox } from "../../../../mui/DataGrid/Actions";
import { BeforeDemoCheckList } from "./BeforeDemoCheckList";
import { AfterDemoChecklist } from "./AfterDemoChecklist";
import { AddDemoSessionDate } from "./AddDemoSessionDate";
import UpdateLead from "./UpdateLead";

export const CroLeadsActions = ({ data }) => {
    return (
        <div>
            <>
                <GridBox
                    listItem={
                        <>
                            <ListItemBox
                                label="Add Course Enrollment"
                                component={
                                    <AddCourseEnrollmentDetailed
                                        params={data}
                                    />
                                }
                            />
                            <ListItemBox
                                label="OGA Remarks Detailed"
                                component={
                                    <OgaRemarksDetailedView row={data} />
                                }
                            />
                            {/* <ListItemBox
                                label="Before Demo CheckList"
                                component={<BeforeDemoCheckList data={data} />}
                            />
                            <ListItemBox
                                label="After Demo Checklist"
                                component={<AfterDemoChecklist />}
                            /> */}
                            {/* <ListItemBox
                                label="Add Demo Session Date"
                                component={<AddDemoSessionDate />}
                            /> */}
                            <ListItemBox
                                label="Update Lead"
                                component={<UpdateLead data={data} />}
                            />
                        </>
                    }
                />
            </>
        </div>
    );
};
