import NotificationUtilsMenu from "./NotificationUtilityMenu";
import NotificationStatus from "./NotificationStatus";

export const NOTIFICATIONS_TABLE_HEAD = [
  {
    field: "serialNumber",
    flex: 0.2,
    minWidth: 90,
    headerName: "No.",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "notification_id",
    flex: 0.13,
    minWidth: 90,
    headerName: "Id",
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "title",
    flex: 0.4,
    minWidth: 160,
    headerName: "Title",
    type: "string",
    headerAlign: "left",
    align: "left",
    // editable: true,
  },
  {
    field: "content",
    flex: 1,
    minWidth: 160,
    headerName: "Content",
    type: "string",
    headerAlign: "left",
    align: "left",
    // editable: true,
  },
  {
    field: "crm_approved",
    headerName: "Status",
    width: 90,
    // type: "boolean",
    headerAlign: "center",
    align: "center",
    renderCell: (params) => NotificationStatus(params),

    // editable: true,
  },
  //   {
  //     field: "subjectEnrollmentId",
  //     headerName: "SubEnId",
  //     width: 150,
  //     type: "number",
  //     headerAlign: "left",
  //     align: "left",
  //   },
  {
    field: "",
    flex: 0.13,
    align: "center",
    renderCell: (params) => NotificationUtilsMenu(params),
  },
];
