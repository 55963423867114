import { Box, Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { CustomRow, Mytable, Rows } from "../../../mui/DataGrid/Details";
import TotalSessionsPop from "./TotalSessionsPop";
import SessionsCompleatedAction from "./SessionsCompleatedAction";
import OgaRemarksDetailedView from "./OgaRemarksDetailedView";
import ShareAndEditAction from "./ShareAndEditAction";
import { AdmissionStatus } from "../../CRM/AdmissionRegister/AdmissionStatus/AdmissionStatus";
import { CroEnquiry } from "../../CRM/AdmissionRegister/CroEnquiryLog/CroEnquiry";
import { AssessmentDetails } from "../../CRM/AdmissionRegister/AssessmentDetails/AssessmentDetails";
import FeeStructurePopUp from "./FeeStructurePopUp";
import { useDispatch, useSelector } from "react-redux";
import {
    clearStudentFeeStructure,
    getStudentFeeStructure,
} from "../../../../redux/features/croSlice";
import OgaRetainCallPopUp from "./OgaRetainCallPopUp";
import { useNavigate } from "react-router-dom";

const CroAdmissionRegisterActions = ({ data }) => {
    const navigate = useNavigate();

    return (
        <Box>
            <Grid container spacing={1}>
                <Mytable
                    tableRow={
                        <>
                            <CustomRow
                                label="Total Sessions"
                                component={<TotalSessionsPop row={data} />}
                            />
                            <CustomRow
                                label="Sessions Compleated"
                                component={
                                    <SessionsCompleatedAction row={data} />
                                }
                            />
                            <CustomRow
                                label="OGA Remarks Details"
                                component={
                                    <OgaRemarksDetailedView row={data} />
                                }
                            />

                            <CustomRow
                                label="Fee Structure"
                                component={
                                    data.isFeeStructureAvailable === "true" ? (
                                        <FeeStructurePopUp
                                            courseEnrollmentId={
                                                data.courseEnrollmentId
                                            }
                                        />
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="error"
                                        >
                                            NA
                                        </Button>
                                    )
                                }
                            />

                            <CustomRow
                                label="Oga retain call"
                                component={<OgaRetainCallPopUp data={data} />}
                            />
                        </>
                    }
                />

                <Mytable
                    tableRow={
                        <>
                            <CustomRow
                                label="Profile"
                                component={<ShareAndEditAction row={data} />}
                            />
                            <CustomRow
                                label="Admission Status"
                                component={
                                    <AdmissionStatus
                                        params={data.courseStatus}
                                        courseEnrollmentId={
                                            data.courseEnrollmentId
                                        }
                                    />
                                }
                            />

                            <CustomRow
                                label="Cro enquiry Log"
                                component={<CroEnquiry params={data} />}
                            />

                            <CustomRow
                                label="Class Room meterials"
                                component={<AssessmentDetails params={data} />}
                            />

                            <CustomRow
                                label="Ledger view"
                                component={
                                    <Button
                                        onClick={() => {
                                            navigate(
                                                "/my-workspace/cr-ledger-view",
                                                {
                                                    state: {
                                                        ledgerYear:
                                                            data?.ledgerYear,
                                                        ledgerMonth:
                                                            data?.ledgerMonth,
                                                        courseEnrollmentId:
                                                            data?.courseEnrollmentId,
                                                    },
                                                }
                                            );
                                        }}
                                        variant="contained"
                                    >
                                        View
                                    </Button>
                                }
                            />
                        </>
                    }
                />
            </Grid>
        </Box>
    );
};

export default CroAdmissionRegisterActions;
