import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import Page from "../../../components/Page";
import { INWARD_AND_OUTWARD_TABLE_HEAD as columns } from "../../../components/datagrid/Todo/InwardAndOutwardTablehead";
import { clearRes, getOutwardTasks } from "../../../redux/features/todoSlice";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
const OutWardTask = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.todo);
  useEffect(() => {
    dispatch(getOutwardTasks());
    return () => dispatch(clearRes());
  }, []);

  return (
    <Page title="Inward tasks">
      <Container component="main">
        <Typography variant="h4" sx={{ mb: 1 }}>
          Outward tasks
        </Typography>
        <Breadcrumb sx={{ paddingLeft: 0.3 }} />
        <Box marginTop={4}>
          <Paper elevation={3} sx={{ borderRadius: 1, width: "100%" }}>
            <Box
              sx={{
                height: 48,
                backgroundColor: "grey.200",
                borderRadius: "8px 8px 0 0",
              }}
            ></Box>

            <Box sx={{ width: "100%" }}>
              <DataGrid
                loading={state.loading}
                rows={state.data}
                columns={columns}
                pageSize={5}
                autoHeight
                rowsPerPageOptions={[5, 10, 25, 50]}
                getRowId={(row) => row.serialNumber}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      debounceMs: 500,
                    },
                  },
                }}
              />
            </Box>
          </Paper>
        </Box>
      </Container>
    </Page>
  );
};

export default OutWardTask;
