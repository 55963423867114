import React, { useEffect } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
    EXPENSE_TABLE_HEAD,
    TP_TABLE_HEAD,
} from "../../../components/datagrid/myworkspace/cro/ExpenseTableHead";
import Wrapper from "../../../components/Wrapper/Wrapper";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { AddExpensePopup } from "./AddExpensePopup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
    getExpenseTypes,
    getExpenses,
    getStudentsforAddIncome,
} from "../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const ExpensePage = () => {
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = React.useState(5);
    const [expenseType, setExpenseType] = React.useState("");
    const [value, setValue] = React.useState(dayjs());
    const { expenseTypes, expenseData, loading } = useSelector(
        (state) => state.cro
    );
    useEffect(() => {
        dispatch(getExpenseTypes());
        dispatch(getStudentsforAddIncome());
    }, []);

    const handleChange = (event) => {
        setExpenseType(event.target.value);
    };
    useEffect(() => {
        if (expenseType && value) {
            const formatedDate = dayjs(value).format("YYYY-MM");
            const year = dayjs(formatedDate).year();
            const month = dayjs(formatedDate).month() + 1;
            const data = {
                expenseTypeId: expenseType,
                year: year,
                month: month,
            };
            dispatch(getExpenses(data));
        }
    }, [expenseType, value]);

    return (
        <>
            <Wrapper
                title="Expense"
                actions={<AddExpensePopup />}
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingBottom: 2,
                            }}
                        >
                            <Box>
                                <Box sx={{ minWidth: 220 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Expense Type
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={expenseType}
                                            label="Expense Type"
                                            onChange={handleChange}
                                        >
                                            {expenseTypes.map((item) => {
                                                return (
                                                    <MenuItem
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["year", "month"]}
                                        label="Year and Month"
                                        value={value}
                                        onChange={(newValue) =>
                                            setValue(newValue)
                                        }
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Paper elevation={3}>
                            <Box>
                                <DataGrid
                                    loading={loading}
                                    rows={expenseData}
                                    autoHeight
                                    columns={
                                        expenseType === 1
                                            ? TP_TABLE_HEAD
                                            : EXPENSE_TABLE_HEAD
                                    }
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    components={{
                                        Toolbar: CustomGridToolbar,
                                    }}
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </>
    );
};

export default ExpensePage;
