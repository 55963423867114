import { Chip } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";

const InitialScreeningStatusChip = ({ singleData }) => {
  if (singleData.initScreeningStatus === "Qualified") {
    return (
      <div>
        <Chip
          icon={<Icon icon="octicon:verified-24" />}
          label={singleData.initScreeningStatus}
          color="success"
        />
      </div>
    );
  } else if (singleData.initScreeningStatus === "Rejected") {
    return (
      <div>
        <Chip
          icon={<Icon icon="iconoir:cancel" />}
          label={singleData.initScreeningStatus}
          color="error"
        />
      </div>
    );
  } else if (singleData.initScreeningStatus === "Future Reference") {
    return (
      <div>
        <Chip
          icon={
            <Icon
              icon="material-symbols:quick-reference-all-outline-sharp"
              width="20"
              height="20"
              color="white"
            />
          }
          label={singleData.initScreeningStatus}
          sx={{ backgroundColor: "#7e4d8b", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.initScreeningStatus === "Notified") {
    return (
      <div>
        <Chip
          icon={
            <Icon icon="fe:notice-push" color="white" width="20" height="20" />
          }
          label={singleData.initScreeningStatus}
          sx={{ backgroundColor: "#3173b4", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.initScreeningStatus === "Pending") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:outline-pending-actions" />}
          label="Pending"
          color="warning"
        />
      </div>
    );
  }
};

const TestStatusChip = ({ singleData }) => {
  if (singleData.testStatus === "Qualified") {
    return (
      <div>
        <Chip
          icon={<Icon icon="octicon:verified-24" />}
          label={singleData.testStatus}
          color="success"
        />
      </div>
    );
  } else if (singleData.testStatus === "Rejected") {
    return (
      <div>
        <Chip
          label={singleData.testStatus}
          icon={<Icon icon="iconoir:cancel" />}
          color="error"
        />
      </div>
    );
  } else if (singleData.testStatus === "Future Reference") {
    return (
      <div>
        <Chip
          label={singleData.testStatus}
          icon={
            <Icon
              icon="material-symbols:quick-reference-all-outline-sharp"
              color="white"
              width="20"
              height="20"
            />
          }
          sx={{ backgroundColor: "#7e4d8b", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.testStatus === "Not Attended") {
    return (
      <div>
        <Chip
          label={singleData.testStatus}
          icon={<Icon icon="mdi:account-cancel-outline" />}
          sx={{ backgroundColor: "#3173b4", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.testStatus === "Pending") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:outline-pending-actions" />}
          label={singleData.testStatus}
          color="warning"
        />
      </div>
    );
  } else if (singleData.testStatus === "Not Applicable") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:twotone-do-not-disturb" />}
          label={singleData.testStatus}
          sx={{ backgroundColor: "#f4cc15", color: "#222" }}
        />
      </div>
    );
  }
};

const FirstInterviewStatusChip = ({ singleData }) => {
  if (singleData.interview1Status === "Qualified") {
    return (
      <div>
        <Chip
          icon={<Icon icon="octicon:verified-24" />}
          label={singleData.interview1Status}
          color="success"
        />
      </div>
    );
  } else if (singleData.interview1Status === "Rejected") {
    return (
      <div>
        <Chip
          label={singleData.interview1Status}
          icon={<Icon icon="iconoir:cancel" />}
          color="error"
        />
      </div>
    );
  } else if (singleData.interview1Status === "Future Reference") {
    return (
      <div>
        <Chip
          label={singleData.interview1Status}
          icon={
            <Icon
              icon="material-symbols:quick-reference-all-outline-sharp"
              color="white"
              width="20"
              height="20"
            />
          }
          sx={{ backgroundColor: "#7e4d8b", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.interview1Status === "Not Attended") {
    return (
      <div>
        <Chip
          label={singleData.interview1Status}
          icon={<Icon icon="mdi:account-cancel-outline" />}
          sx={{ backgroundColor: "#3173b4", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.interview1Status === "Pending") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:outline-pending-actions" />}
          label={singleData.interview1Status}
          color="warning"
        />
      </div>
    );
  }
};

const SecondInterviewStatusChip = ({ singleData }) => {
  if (singleData.interview2Status === "Qualified") {
    return (
      <div>
        <Chip
          icon={<Icon icon="octicon:verified-24" />}
          label={singleData.interview2Status}
          color="success"
        />
      </div>
    );
  } else if (singleData.interview2Status === "Rejected") {
    return (
      <div>
        <Chip
          label={singleData.interview2Status}
          icon={<Icon icon="iconoir:cancel" />}
          color="error"
        />
      </div>
    );
  } else if (singleData.interview2Status === "Future Reference") {
    return (
      <div>
        <Chip
          label={singleData.interview2Status}
          icon={
            <Icon
              icon="material-symbols:quick-reference-all-outline-sharp"
              color="white"
              width="20"
              height="20"
            />
          }
          sx={{ backgroundColor: "#7e4d8b", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.interview2Status === "Not Attended") {
    return (
      <div>
        <Chip
          label={singleData.interview2Status}
          icon={<Icon icon="mdi:account-cancel-outline" />}
          sx={{ backgroundColor: "#3173b4", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.interview2Status === "Pending") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:outline-pending-actions" />}
          label={singleData.interview2Status}
          color="warning"
        />
      </div>
    );
  }
};

const ThirdInterviewStatusChip = ({ singleData }) => {
  if (singleData.interview3Status === "Qualified") {
    return (
      <div>
        <Chip
          icon={<Icon icon="octicon:verified-24" />}
          label={singleData.interview3Status}
          color="success"
        />
      </div>
    );
  } else if (singleData.interview3Status === "Rejected") {
    return (
      <div>
        <Chip
          label={singleData.interview3Status}
          icon={<Icon icon="iconoir:cancel" />}
          color="error"
        />
      </div>
    );
  } else if (singleData.interview3Status === "Future Reference") {
    return (
      <div>
        <Chip
          label={singleData.interview3Status}
          icon={
            <Icon
              icon="material-symbols:quick-reference-all-outline-sharp"
              color="white"
              width="20"
              height="20"
            />
          }
          sx={{ backgroundColor: "#7e4d8b", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.interview3Status === "Not Attended") {
    return (
      <div>
        <Chip
          label={singleData.interview3Status}
          icon={<Icon icon="mdi:account-cancel-outline" />}
          sx={{ backgroundColor: "#3173b4", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.interview3Status === "Pending") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:outline-pending-actions" />}
          label={singleData.interview3Status}
          color="warning"
        />
      </div>
    );
  } else if (singleData.interview3Status === "Not Applicable") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:twotone-do-not-disturb" />}
          label={singleData.interview3Status}
          sx={{ backgroundColor: "#f4cc15", color: "#222" }}
        />
      </div>
    );
  }
};

const ConfirmationStatusChip = ({ singleData }) => {
  if (singleData.oralCnfStatus === "Accepted") {
    return (
      <div>
        <Chip
          icon={<Icon icon="octicon:verified-24" />}
          label={singleData.oralCnfStatus}
          color="success"
        />
      </div>
    );
  } else if (singleData.oralCnfStatus === "Rejected") {
    return (
      <div>
        <Chip
          label={singleData.oralCnfStatus}
          icon={<Icon icon="iconoir:cancel" />}
          color="error"
        />
      </div>
    );
  } else if (singleData.oralCnfStatus === "Future Reference") {
    return (
      <div>
        <Chip
          label={singleData.oralCnfStatus}
          icon={
            <Icon
              icon="material-symbols:quick-reference-all-outline-sharp"
              color="white"
              width="20"
              height="20"
            />
          }
          sx={{ backgroundColor: "#7e4d8b", color: "#fff" }}
        />
      </div>
    );
  } else if (singleData.oralCnfStatus === "Pending") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:outline-pending-actions" />}
          label={singleData.oralCnfStatus}
          color="warning"
        />
      </div>
    );
  }
};

const OfferLetterStatusChip = ({ singleData }) => {
  if (singleData.offerLetterStatus === "Accepted") {
    return (
      <div>
        <Chip
          icon={<Icon icon="octicon:verified-24" />}
          label={singleData.offerLetterStatus}
          color="success"
        />
      </div>
    );
  } else if (singleData.offerLetterStatus === "Rejected") {
    return (
      <div>
        <Chip
          label={singleData.offerLetterStatus}
          icon={<Icon icon="iconoir:cancel" />}
          color="error"
        />
      </div>
    );
  } else if (singleData.offerLetterStatus === "Pending") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:outline-pending-actions" />}
          label={singleData.offerLetterStatus}
          color="warning"
        />
      </div>
    );
  }
};

const JoiningStatusChip = ({ singleData }) => {
  if (singleData.finalStatus === "Joined") {
    return (
      <div>
        <Chip
          icon={<Icon icon="octicon:verified-24" />}
          label={singleData.finalStatus}
          color="success"
        />
      </div>
    );
  } else if (singleData.finalStatus === "Not-Joined") {
    return (
      <div>
        <Chip
          label={singleData.finalStatus}
          icon={<Icon icon="iconoir:cancel" />}
          color="error"
        />
      </div>
    );
  } else if (singleData.finalStatus === "Immediate Resign") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:outline-pending-actions" />}
          label={singleData.finalStatus}
          color="warning"
        />
      </div>
    );
  } else if (singleData.finalStatus === "Offer Cancelled") {
    return (
      <div>
        <Chip
          icon={<Icon icon="ic:twotone-do-not-disturb" />}
          label={singleData.testStatus}
          sx={{ backgroundColor: "#ffc1074d", color: "#ffc107" }}
        />
      </div>
    );
  } else if (singleData.finalStatus === "Offer-Rejected") {
    return (
      <div>
        <Chip
          label={singleData.finalStatus}
          icon={<Icon icon="iconoir:cancel" />}
          color="error"
        />
      </div>
    );
  }
};

export {
  InitialScreeningStatusChip,
  TestStatusChip,
  FirstInterviewStatusChip,
  SecondInterviewStatusChip,
  ThirdInterviewStatusChip,
  ConfirmationStatusChip,
  OfferLetterStatusChip,
  JoiningStatusChip,
};
