import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProfileRoute = ({ children }) => {
    const { data } = useSelector((state) => state.login);
    const profile = JSON.parse(localStorage.getItem("cred"));

    if (profile === null) {
        return <Navigate to="/auth/login" />;
    }

    if (
        !data.profile?.profileCompletion ||
        !data.profile?.bankAccountCompletion
    ) {
        return children;
    } else {
        return <Navigate to="/dashboard" />;
    }
};

export default ProfileRoute;
