import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    privileges: [],
    deptPreData: [],
    filteredPreData: {},
    permissions: {
        message: "",
        loading: false,
        data: [],
    },
};

export const getPermissionsPreData = createAsyncThunk(
    "privileges/permissions",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "/permissions",
        });
        return res;
    }
);

export const getDepartmentPreData = createAsyncThunk(
    "privileges/department_predata",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "pre-data/departments",
        });
        return res;
    }
);

export const addPermission = createAsyncThunk(
    "privileges/add_permission",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: "permissions/relations",
            data,
        });
        return res;
    }
);

export const deletePermission = createAsyncThunk(
    "privileges/dlt_permission",
    async (id) => {
        const res = await apiRequest({
            method: "delete",
            url: `permissions/relations/${id}`,
        });
        return res;
    }
);

export const getPermissions = createAsyncThunk(
    "privileges/get_permissions",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "permissions/relations",
        });
        return res;
    }
);

const privilegeslice = createSlice({
    name: "privileges",
    initialState,
    reducers: {
        clearPrivilage: (state) => {
            return {
                ...state,
                privileges: [],
            };
        },
        clearDeptPreData: (state) => {
            return {
                ...state,
                deptPreData: [],
            };
        },
        clearAllPrivilage: (state) => {
            return {
                ...state,
                privileges: [],
            };
        },
        clearPermissions: (state) => {
            return {
                ...state,
                permissions: initialState.permissions,
            };
        },
        filterDeptPreData: (state, action) => {
            const deptData = state.deptPreData.filter(
                (item) => item.departmentId === action.payload
            );
            if (deptData.length > 0) {
                return {
                    ...state,
                    filteredPreData: deptData[0],
                };
            } else {
                return {
                    ...state,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPermissionsPreData.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getPermissionsPreData.fulfilled, (state, action) => {
            return {
                ...state,
                privileges: action.payload.data.permissions,
                loading: false,
            };
        });
        builder.addCase(getPermissionsPreData.rejected, (state) => {
            return {
                ...state,
                loading: false,
            };
        });

        //-------------department pre-data-------------

        builder.addCase(getDepartmentPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getDepartmentPreData.fulfilled, (state, action) => {
            return {
                ...state,
                deptPreData: action.payload.data.departments,
                loading: false,
            };
        });
        builder.addCase(getDepartmentPreData.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        //-----------------addPermission---------------
        // -----------------deletePermission------------------

        builder.addCase(deletePermission.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deletePermission.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        builder.addCase(deletePermission.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        //   ---------------------------------------------------------------------
        builder.addCase(addPermission.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addPermission.fulfilled, (state) => {
            return {
                ...state,
                loading: false,
            };
        });
        builder.addCase(addPermission.rejected, (state) => {
            return {
                ...state,
                loading: false,
            };
        });

        builder.addCase(getPermissions.pending, (state, action) => {
            return {
                ...state,
                permissions: { ...state.permissions, loading: true },
            };
        });
        builder.addCase(getPermissions.fulfilled, (state, action) => {
            const formatData = formatDataForDataTable(
                action.payload.data.relations
            );
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    data: formatData,
                    loading: false,
                },
            };
        });
        builder.addCase(getPermissions.rejected, (state, action) => {
            return {
                ...state,
                permissions: {
                    ...state.permissions,
                    loading: false,
                },
            };
        });
    },
});

export const {
    clearPrivilage,
    filterDeptPreData,
    clearAllPrivilage,
    clearPermissions,
    clearDeptPreData,
} = privilegeslice.actions;
export default privilegeslice.reducer;
