import React, { useEffect } from 'react'
import GlobalWrapper from '../../components/Wrapper/GlobalWrapper'
import AddOrUpdateCourse from '../../components/datagrid/course/AddOrUpdateCourse'
import { Paper } from '@mui/material'
import GlobalDatagrid from '../../components/mui/DataGrid/GlobalDatagrid'
import { courseTableHeader } from '../../components/datagrid/course/courseTableHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCourses } from '../../redux/features/courseSlice'
import ActionDetails from '../../components/mui/GlobalAnchor/ActionDetails'
import GlobalAnchorList from '../../components/mui/GlobalAnchor/GlobalAnchorList'
import DeleteCourse from '../../components/datagrid/course/DeleteCourse'
import ActiveOrInactiveButton from '../../components/datagrid/course/ActiveOrInactiveButton'


const Course = () => {
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, search, rowData, openAnchor } = paginationData
  const { resData, loading } = useSelector((store) => store.course)
  const { meta, courses } = resData
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(paginationData).length > 0) {
      dispatch(getAllCourses(paginationData))
    }
  }, [gridSize, pageNum, search])
  return (
    <GlobalWrapper title={'Course'} actions={<AddOrUpdateCourse />}>
      <Paper elevation={4}>
        <GlobalDatagrid
          tableHead={courseTableHeader}
          rows={courses || []}
          meta={meta?.totalRecords}
          rowUniqueId="id"
          loading={loading}
          rowClick={true}
          actionComponent={
            <>
              <ActionDetails val={rowData?.courseName} />
              <GlobalAnchorList label="Update" component={<AddOrUpdateCourse row={rowData} />} />
              <GlobalAnchorList label="Delete" component={<DeleteCourse row={rowData} />} />
              <GlobalAnchorList label="Status"  component={<ActiveOrInactiveButton status={rowData?.courseAvailability} id={rowData?.id} />} />      
            </>
          }
        />
      </Paper>
    </GlobalWrapper>
  )
}

export default Course
