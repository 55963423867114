import {
  Button,
  Paper,
  Typography,
  Stepper,
  StepLabel,
  Step,
  Box,
  Container,
  Stack,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import GetStepContent from "./Form";
import { makeStyles } from "@material-ui/core/styles";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPreprofile,
  updateEmployee,
  userBankDetails,
} from "../../../../redux/features/profileSlice";
import useResponsive from "../../../../Hooks/useResponsive";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
  bankAccntCompleate,
  profileUpdateSuccess,
} from "../../../../redux/features/authSlice";
import Form from "./Form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getSteps() {
  return [
    "Personal information",
    "Address Information",
    "Education",
    "official Information",
    "Bank Details",
  ];
}

export default function StepperForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useSelector((state) => state.login);

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  //Custom styling
  const useStyles = makeStyles((theme) => ({
    button: {
      marginRight: theme.spacing(1),
    },
  }));

  // ---------------------dialogue-------------------------------------------

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ----------------------------------------------------------------------

  const classes = useStyles();

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // ----------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------------
  useEffect(() => {
    if (data.profile.profileCompletion) setActiveStep(4);
  }, [data]);
  return (
    <div>
      <Container>
        <Typography variant="h4" sx={{ mt: 9 }}>
          Employee Profile
        </Typography>
        <Breadcrumb sx={{ paddingLeft: 0.3 }} />
        <Paper elevation={3} sx={{ borderRadius: 5 }}>
          <Box sx={{ margin: 3, padding: 1 }}>
            {smUp && (
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step, index) => {
                  const labelProps = {};
                  const stepProps = {};
                  return (
                    <Step {...stepProps} key={index}>
                      <StepLabel {...labelProps}>{step}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
            {!smUp && (
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step, index) => {
                  const labelProps = {};
                  const stepProps = {};
                  return (
                    <Step {...stepProps} key={index} sx={{ width: 30 }}>
                      <StepLabel {...labelProps}>
                        <Icon
                          icon="ic:round-star-outline"
                          width="20"
                          height="20"
                        />
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ backgroundColor: "#f9fafb" }}>
          <Box
            sx={{
              height: 48,
              backgroundColor: "grey.200",
              borderRadius: "8px 8px 0 0",
            }}
          ></Box>
          <Box sx={{ padding: 4 }}>
            <>
              <Form activeStep={activeStep} setActiveStep={setActiveStep} />
            </>
          </Box>
        </Paper>
      </Container>

      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          {/* <Stack direction={"row"}> */}
          <Box padding={1}>
            <Stack direction={"row"}>
              <Icon
                icon="ic:round-warning-amber"
                width="40"
                height="40"
                color="red"
              />

              <Typography variant="h5" marginTop={1}>
                Warning..!
              </Typography>
            </Stack>
          </Box>

          {/* </Stack> */}
          <Divider />

          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Something Went wrong....! Please Re-check Your Details.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
