import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import { Icon } from "@iconify/react";
import Wrapper from "../../../../Wrapper/Wrapper";
import SimpleLoader from "../../../../Loader/SimpleLoader";

export const Main = ({ title, content }) => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
        <Chip
          color="primary"
          variant="outlined"
          label={
            <>
              <Typography variant="h6">{title}</Typography>
            </>
          }
        />
      </Box>
      <Box
        sx={{
          width: "100%",

          backgroundColor: "grey.200",
          marginTop: 2,
          padding: 4,
          borderRadius: 5,
        }}
      >
        {content}
      </Box>
    </div>
  );
};

export const Items = ({ name, value, key }) => {
  return (
    <List>
      <ListItem key={key}>
        <ListItemIcon>
          <Icon
            icon="line-md:arrow-left"
            width="20"
            height="20"
            rotate={2}
            color="#2f72b3"
          />
        </ListItemIcon>
        <Typography variant="subtitle2">
          {name}:-{value}
        </Typography>
      </ListItem>
      <Divider variant="inset" />
    </List>
  );
};

export const AssessmentData = React.memo(() => {
  const data = useSelector((state) => state.crm.preEvaluationReport);
  const loading = useSelector((state) => state.crm.loading);

  return (
    <div>
      {loading ? (
        <SimpleLoader />
      ) : (
        <Wrapper
          Content={
            <>
              <Main
                title="Student Details"
                content={
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Items
                          name={"Name"}
                          value={
                            data.preEvalutionReport?.studentName
                              ? data.preEvalutionReport?.studentName
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Email"}
                          value={
                            data.preEvalutionReport?.email
                              ? data.preEvalutionReport?.email
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Contact Person "}
                          value={
                            data.preEvalutionReport?.contactPerson
                              ? data.preEvalutionReport?.contactPerson
                              : "No Data Found..!"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Items
                          name={"Contact Number "}
                          value={
                            data.preEvalutionReport?.contactNumber
                              ? data.preEvalutionReport?.contactNumber
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Place "}
                          value={
                            data.preEvalutionReport?.place
                              ? data.preEvalutionReport?.place
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Lead Came On "}
                          value={
                            data.preEvalutionReport?.leadCameOn
                              ? data.preEvalutionReport?.leadCameOn
                              : "No Data Found..!"
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                }
              />
              {/* --------------------------------------------Course Details------------------------------------------------------------ */}
              <Main
                title="Course Details"
                content={
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Items
                          name={"Class"}
                          value={
                            data.preEvalutionReport?.className
                              ? data.preEvalutionReport?.className
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Syllabus"}
                          value={
                            data.preEvalutionReport?.syllabusName
                              ? data.preEvalutionReport?.syllabusName
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Medium"}
                          value={
                            data.preEvalutionReport?.mediumName
                              ? data.preEvalutionReport?.mediumName
                              : "No Data Found..!"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Items
                          name={"Course "}
                          value={
                            data.preEvalutionReport?.courseName
                              ? data.preEvalutionReport?.courseName
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Fee Per Session "}
                          value={
                            data.preEvalutionReport?.feePerSession
                              ? data.preEvalutionReport?.feePerSession
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Sessions Per Week "}
                          value={
                            data.preEvalutionReport?.sessionsPerWeek
                              ? data.preEvalutionReport?.sessionsPerWeek
                              : "No Data Found..!"
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                }
              />
              {/* ----------------------------------------SRO & CRO Details---------------------------------- */}
              <Main
                title="SRO & CRO Details"
                content={
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Items
                          name={"OGA"}
                          value={
                            data.preEvalutionReport?.oga
                              ? data.preEvalutionReport?.oga
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"OGA Remarks"}
                          value={
                            data.preEvalutionReport?.ogaRemarks
                              ? data.preEvalutionReport?.ogaRemarks
                              : "No Data Found..!"
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Items
                          name={"CRO "}
                          value={
                            data.preEvalutionReport?.cro
                              ? data.preEvalutionReport?.cro
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"CRO Remarks"}
                          value={
                            data.preEvalutionReport?.croRemarks
                              ? data.preEvalutionReport?.croRemarks
                              : "No Data Found..!"
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                }
              />
              {/* -------------------------------------Assessment Details-------------------------- */}
              <Main
                title="Assessment Details"
                content={
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Items
                          name={"Assessment Report Link"}
                          value={
                            <>
                              {data.preEvalutionReport?.assessmentReportLink ? (
                                <a
                                  href={`${data.preEvalutionReport?.assessmentReportLink}`}
                                  target="_blank"
                                >
                                  <Chip
                                    color="primary"
                                    icon={
                                      <Icon
                                        icon="line-md:download-loop"
                                        width="20"
                                        height="20"
                                      />
                                    }
                                    label="DOWNLOAD"
                                  />
                                </a>
                              ) : (
                                "No Data Found..!"
                              )}
                            </>
                          }
                        />
                        <Items
                          name={"Study Materials / Module"}
                          value={
                            <>
                              {data.preEvalutionReport?.studyMaterials
                                .length !== null ? (
                                <>
                                  {data.preEvalutionReport?.studyMaterials.map(
                                    (item) => {
                                      return (
                                        <Stack direction={"column"} spacing={1}>
                                          <a
                                            href={`${item.link}`}
                                            target="_blank"
                                          >
                                            <Box padding={1}>
                                              <Chip
                                                key={item.tag}
                                                color="primary"
                                                icon={
                                                  <Icon
                                                    icon="line-md:download-loop"
                                                    width="20"
                                                    height="20"
                                                  />
                                                }
                                                label={item.tag}
                                              />
                                            </Box>
                                          </a>
                                        </Stack>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                "No Data Found..!"
                              )}
                            </>
                          }
                        />

                        <Items
                          name={"Terminal Question Papers"}
                          value={
                            <>
                              {data.preEvalutionReport?.terminalQuestionPapers
                                .length !== null ? (
                                <>
                                  {data.preEvalutionReport?.terminalQuestionPapers.map(
                                    (item) => {
                                      return (
                                        <Stack direction={"column"} spacing={1}>
                                          <a
                                            href={`${item.link}`}
                                            target="_blank"
                                          >
                                            <Box padding={1}>
                                              <Chip
                                                key={item.tag}
                                                color="primary"
                                                icon={
                                                  <Icon
                                                    icon="line-md:download-loop"
                                                    width="20"
                                                    height="20"
                                                  />
                                                }
                                                label={item.tag}
                                              />
                                            </Box>
                                          </a>
                                        </Stack>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                "No Data Found..!"
                              )}
                            </>
                          }
                        />

                        <Items
                          name={"Text Books"}
                          value={
                            <>
                              {data.preEvalutionReport?.textBooks.length !==
                              null ? (
                                <>
                                  {data.preEvalutionReport?.textBooks.map(
                                    (item) => {
                                      return (
                                        <Stack direction={"column"} spacing={1}>
                                          <a
                                            href={`${item.link}`}
                                            target="_blank"
                                          >
                                            <Box padding={1}>
                                              <Chip
                                                key={item.tag}
                                                color="primary"
                                                icon={
                                                  <Icon
                                                    icon="line-md:download-loop"
                                                    width="20"
                                                    height="20"
                                                  />
                                                }
                                                label={item.tag}
                                              />
                                            </Box>
                                          </a>
                                        </Stack>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                "No Data Found..!"
                              )}
                            </>
                          }
                        />

                        <Items
                          name={"SQA Remarks"}
                          value={
                            data.preEvalutionReport?.sqaRemarks
                              ? data.preEvalutionReport?.sqaRemarks
                              : "No Data Found..!"
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Items
                          name={"Evaluation Remarks "}
                          value={
                            data.preEvalutionReport?.evaluationRemarks
                              ? data.preEvalutionReport?.evaluationRemarks
                              : "No Data Found..!"
                          }
                        />
                        <Items
                          name={"Chapterwise Question Papers / Worksheets"}
                          value={
                            <>
                              {data.preEvalutionReport?.worksheets.length !==
                              null ? (
                                <>
                                  {data.preEvalutionReport?.worksheets.map(
                                    (item) => {
                                      return (
                                        <Stack direction={"column"} spacing={1}>
                                          <a
                                            href={`${item.link}`}
                                            target="_blank"
                                          >
                                            <Box padding={1}>
                                              <Chip
                                                key={item.tag}
                                                color="primary"
                                                icon={
                                                  <Icon
                                                    icon="line-md:download-loop"
                                                    width="20"
                                                    height="20"
                                                  />
                                                }
                                                label={item.tag}
                                              />
                                            </Box>
                                          </a>
                                        </Stack>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                "No Data Found..!"
                              )}
                            </>
                          }
                        />

                        <Items
                          name={"Previous Year Question Papers"}
                          value={
                            <>
                              {data.preEvalutionReport?.prevQuestionPapers
                                .length !== null ? (
                                <>
                                  {data.preEvalutionReport?.prevQuestionPapers.map(
                                    (item) => {
                                      return (
                                        <Stack direction={"column"} spacing={1}>
                                          <a
                                            href={`${item.link}`}
                                            target="_blank"
                                          >
                                            <Box padding={1}>
                                              <Chip
                                                key={item.tag}
                                                color="primary"
                                                icon={
                                                  <Icon
                                                    icon="line-md:download-loop"
                                                    width="20"
                                                    height="20"
                                                  />
                                                }
                                                label={item.tag}
                                              />
                                            </Box>
                                          </a>
                                        </Stack>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                "No Data Found..!"
                              )}
                            </>
                          }
                        />

                        <Items
                          name={"JQA Remarks "}
                          value={
                            data.preEvalutionReport?.jqaRemarks
                              ? data.preEvalutionReport?.jqaRemarks
                              : "No Data Found..!"
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                }
              />
            </>
          }
        />
      )}
    </div>
  );
});
