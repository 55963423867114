export const INCOME_TABLE_HEAD = [
    {
        field: "id",
        headerName: "id",
        width: 50,
    },
    {
        field: "feeType",
        headerName: "fee Type",
        width: 150,
    },
    {
        field: "studentRegion",
        headerName: "Student Region",
        width: 150,
    },
    {
        field: "admissionNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
    },
    {
        field: "course",
        headerName: "Course",
        width: 180,
    },
    {
        field: "transactionDate",
        headerName: "Transaction Date",
        width: 150,
    },
    {
        field: "amount",
        headerName: "Amount",
        width: 90,
    },

    {
        field: "creditedAc",
        headerName: "Credited Ac",
        width: 150,
    },

    {
        field: "paymentMode",
        headerName: "Payment Mode",
        width: 200,
    },
    {
        field: "screenshot",
        headerName: "Screenshot",
        width: 150,
    },
    {
        field: "remarks",
        headerName: "Remarks",
        width: 150,
    },

    {
        field: "timeStamp",
        headerName: "Time Stamp",
        width: 150,
    },
    {
        field: "addedBy",
        headerName: "Added By",
        width: 150,
    },
    {
        field: "cro",
        headerName: "CRO",
        width: 150,
    },
    {
        field: "reflectedAtBankOn",
        headerName: "Reflected At BankOn",
        width: 150,
    },
    {
        field: "verification",
        headerName: "Verification",
        width: 150,
    },
    {
        field: "duplicate",
        headerName: "duplicate",
        width: 150,
    },
];
