import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import { Box, CardHeader,Typography, Chip } from "@mui/material";
// utils
// import { fNumber } from "../../../../utils/formatNumber";
//
import BaseOptionChart from "../../DashBoardCharts/ReactApexCharts/BaseOptionChart";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(5),
    "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
    "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
        overflow: "visible",
    },
    "& .apexcharts-legend": {
        height: LEGEND_HEIGHT,
        alignContent: "center",
        position: "relative !important",
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

export default function GlobalPieChart({ data, title ,chipValue,customPercentage,maxY}) {
    const labels = data.map((data) => data.label);
    const values = data.map((data) => data.value);

    const theme = useTheme();

    const total = values.reduce((acc, curr) => acc + curr, 0);

    const chartOptions = merge(BaseOptionChart(), {
        colors: [
            theme.palette.primary.main,
            theme.palette.chart.blue[0],
            theme.palette.chart.violet[0],
            theme.palette.chart.yellow[0],
        ],
        labels: labels,
        stroke: { colors: [theme.palette.background.paper] },
        legend: { floating: true, horizontalAlign: "center" },
        dataLabels: {
            enabled: true,
            formatter: (val, opts) => {
                const count = opts.w.globals.series[opts.seriesIndex];
                let percentage;
                if(customPercentage){
                 percentage=Math.floor(((count/maxY)*100))
                }else{
                    percentage = ((count / total) * 100).toFixed(1);
                }         
                return `${count} (${percentage}%)`;
            },
            dropShadow: { enabled: false },
            style: {
                fontSize: "14px",
                fontWeight: "bold",
                colors: [theme.palette.text.primary],
            },
        },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName) => seriesName,
                title: {
                    formatter: (seriesName) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: { donut: { labels: { show: false } } },
        },
    });

    return (
        <Box sx={{ height: 550 }}>
            <CardHeader title={title} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                {chipValue ? (
                    <Chip
                        label={
                            <Typography variant="h6">{chipValue}</Typography>
                        }
                        variant="outlined"
                        color="primary"
                    />
                ) : null}
            </Box>
            <ChartWrapperStyle sx={{ paddingTop: 10 }} dir="ltr">
                <ReactApexChart
                    type="pie"
                    series={values}
                    options={chartOptions}
                    height={280}
                />
            </ChartWrapperStyle>
        </Box>
    );
}
