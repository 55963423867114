import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { FormikProvider, Form, useFormik } from "formik";
import { Divider, Paper, TextField } from "@mui/material";
import { Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useResponsive from "../../../../Hooks/useResponsive";
import {
    getSingleJobApplication,
    updateConfirmationStatus,
} from "../../../../redux/features/jobApplicationSlice";
import {
    CancelButton,
    SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationForm = ({
    setConfirmationForm,
    confirmationForm,
    params,
    singleData,
}) => {
    const preData = useSelector((state) => state.jobApplication);
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const handleClose = () => {
        setConfirmationForm(false);
    };

    const CreateSchema = Yup.object().shape({
        date: Yup.string().required("date Required"),

        status: Yup.string().required("status Required"),
    });

    const formik = useFormik({
        initialValues: {
            date: singleData.oralConfirmedDate
                ? singleData.oralConfirmedDate || ""
                : new Date(),
            status: singleData.oralCnfStatusId
                ? singleData.oralCnfStatusId || ""
                : "",
        },
        // validationSchema: CreateSchema,
        onSubmit: (values) => {
            const data = {
                date: values.date.toISOString().split("T")[0],
                status: values.status,
                id: params.id,
            };
            dispatch(updateConfirmationStatus(data)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(getSingleJobApplication(params.id));
                    setConfirmationForm(false);
                }
            });
        },
    });

    return (
        <div>
            <Dialog
                open={confirmationForm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Paper sx={{ backgroundColor: "grey.200", display: "flex" }}>
                    <DialogTitle>{"Update Confirmation Status.."}</DialogTitle>
                </Paper>
                <Divider />
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <DialogContent>
                            <Box
                                sx={{ width: !smUp ? "100%" : 400, padding: 1 }}
                            >
                                <Box margin={1}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DesktopDatePicker
                                            label="Date"
                                            inputFormat="YYYY/MM/DD"
                                            mask=""
                                            name="date"
                                            value={dayjs(formik.values.date)}
                                            onChange={(e) => {
                                                formik.setFieldValue("date", e);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    {...params}
                                                    error={Boolean(
                                                        formik.touched.date &&
                                                            formik.errors.date
                                                    )}
                                                />
                                            )}
                                        />
                                        <FormHelperText>
                                            {formik.errors.date}
                                        </FormHelperText>
                                    </LocalizationProvider>
                                </Box>
                                <Box margin={1}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Status
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            variant="outlined"
                                            required
                                            label="Status"
                                            name="status"
                                            value={formik.values.status}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "status",
                                                    e.target.value
                                                );
                                            }}
                                            error={
                                                formik.touched.status &&
                                                Boolean(formik.errors.status)
                                            }
                                        >
                                            {preData.preData.data?.map(
                                                (item) => {
                                                    return (
                                                        <MenuItem
                                                            value={item.id}
                                                            key={item.id}
                                                        >
                                                            {item.status}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                        </Select>
                                        <FormHelperText>
                                            {formik.errors.status}
                                        </FormHelperText>
                                    </FormControl>
                                </Box>
                                <Divider sx={{ borderBottomWidth: 3 }} />
                            </Box>
                        </DialogContent>

                        <DialogActions>
                            <CancelButton action={handleClose}>
                                Cancel
                            </CancelButton>
                            <SubmitButton type="submit">
                                {preData.loading ? "Updating..." : "Update"}
                            </SubmitButton>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </Dialog>
        </div>
    );
};

export { ConfirmationForm };
