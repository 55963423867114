import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react";

import { useSelector, useDispatch } from "react-redux";
import { sendNotification } from "../../redux/features/notificationSlice";
import { getNotificationByID } from "../../redux/features/notificationSlice";
import { useSnackbar } from "notistack";
import BasicButton from "../mui/Button";
import { SendButton, CancelButton } from "../mui/Buttons/Buttons";
import { Divider, Paper } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide(params) {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const Notification = useSelector((state) => state.notification);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (e) => {
    dispatch(sendNotification(params.id)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        handleClose();
        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
      }
    });
  };

  return (
    <div>
      <SendButton
        action={handleClickOpen}
        disabled={params.row.crm_approved ? false : true}
      >
        Send
      </SendButton>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Paper sx={{ backgroundColor: "grey.200" }}>
          <DialogTitle>{"Send Notification"}</DialogTitle>
        </Paper>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to send this notification to all ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton action={handleClose}>Cancel</CancelButton>
          <SendButton action={(e) => handleClick(e)}>
            {Notification.loading ? "Sending..." : "Send"}
          </SendButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
