import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@iconify/react";
import DeleteDepartment from "./DeleteDepartment";
import EditDepartment from "./EditDepartment";
import { useDispatch } from "react-redux";
import { getDepartmentById } from "../../../redux/features/departmentSlice";

export default function DepartmentActions(params) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenDelete = () => {
    setAnchorEl(null);
    setOpenDelete(true);
  };
  //   ------------------------------------------------
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleClickOpenEdit = () => {
    dispatch(getDepartmentById(params.row.departmentId));
    setAnchorEl(null);
    setOpenEdit(true);
  };

  return (
    <div>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Icon icon="carbon:overflow-menu-vertical" width="25" height="25" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClickOpenEdit}>
            <Icon
              icon="material-symbols:edit-square-outline-rounded"
              width="25"
              height="25"
              color="#3576b5"
            />
            Edit
          </MenuItem>
          <MenuItem onClick={handleClickOpenDelete}>
            <Icon
              icon="material-symbols:delete-outline-rounded"
              width="25"
              height="25"
              color="red"
            />
            Delete
          </MenuItem>
        </Menu>
      </div>
      {/* ---------------------delete----------------------------- */}
      <DeleteDepartment
        setOpenDelete={setOpenDelete}
        openDelete={openDelete}
        params={params}
      />
      <EditDepartment
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        params={params}
      />
    </div>
  );
}
