import { Button, Box, Stack, Divider, Chip } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import BasicTextField from "../../../mui/TextField";
import GlobalSelect from "../../../mui/GlobalSelect";
import { CancelButton, SubmitButton } from "../../../mui/Buttons/Buttons";
import { Form, FormikProvider, useFormik } from "formik";
import useResponsive from "../../../../Hooks/useResponsive";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getCrosPreData,
  assignCro,
  getNewAdmissions,
} from "../../../../redux/features/crmSlice";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignCro(params) {
  const predata = useSelector((state) => state.crm);
  const dispatch = useDispatch();
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    dispatch(getCrosPreData());
    setOpen(true);
  };
  console.log("preeee", predata);

  const handleClose = () => {
    setOpen(false);
  };
  const date = `${
    params.params.ogaAdmissionConfirmedOn.split("T")[0].split("-")[0]
  }/${params.params.ogaAdmissionConfirmedOn.split("T")[0].split("-")[1]}`;

  const CreateSchema = Yup.object().shape({
    croAssigned: Yup.string().required("CRO  Required"),
  });
  const formik = useFormik({
    initialValues: {
      name: params.params.studentName,
      course: params.params.courseName,
      croAssigned: "",
      crmRemarks: "",
    },
    validationSchema: CreateSchema,
    onSubmit: (values) => {
      const data = {
        croAssigned: values.croAssigned,
        crmRemarks: values.crmRemarks,
        croAssignmentId: params.params.croAssignmentId,
      };
      dispatch(assignCro(data)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === "success") {
          enqueueSnackbar(res.payload.message, {
            variant: "success",
          });

          setOpen(false);
          dispatch(getNewAdmissions(date));
        }
      });
      console.log("values------", data);
    },
  });

  return (
    <div>
      {params.params.crmLeadStatus === "Assigned" ? (
        <Chip
          sx={{ backgroundColor: "#2e7d32", color: "#fff" }}
          icon={
            <Icon icon="ic:round-person" width="15" height="15" color="#fff" />
          }
          label={params.params.cro}
        />
      ) : (
        <Button
          size="small"
          sx={{ color: "#551e64", backgroundColor: "#551e6452" }}
          onClick={handleClickOpen}
        >
          <Icon icon="icon-park-outline:people-left" width="20" height="20" />
          Assign
        </Button>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Assign CRO"}</DialogTitle>
        <Divider />
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <Box
                width={smUp ? 500 : 250}
                sx={{ backgroundColor: "grey.200" }}
              >
                <Box sx={{ padding: smUp ? 3 : 1 }}>
                  <Stack direction={"column"} spacing={2}>
                    <BasicTextField
                      name="name"
                      value={formik.values.name}
                      fullWidth
                      label="Name"
                      disabled
                      variant="filled"
                    />
                    <BasicTextField
                      name="course"
                      value={formik.values.course}
                      fullWidth
                      label="Course"
                      disabled
                      variant="filled"
                    />
                    <GlobalSelect
                      name="croAssigned"
                      label="CRO"
                      data={predata.preData.cros}
                      value={formik.values.croAssigned}
                      action={(e) => {
                        formik.setFieldValue("croAssigned", e.target.value);
                      }}
                      error={
                        formik.touched.croAssigned &&
                        Boolean(formik.errors.croAssigned)
                      }
                      helperText={formik.errors.croAssigned}
                    />
                    <BasicTextField
                      fullWidth
                      label="Remarks"
                      name="crmRemarks"
                      value={formik.values.crmRemarks}
                      action={formik.handleChange}
                      rows={3}
                      multiline
                    />
                  </Stack>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <CancelButton action={handleClose}>Cancel</CancelButton>
              <SubmitButton type="submit">
                {predata.loading ? "Submiting.." : "Submit"}
              </SubmitButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
