import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";
import { FeePerSessionData } from "./FeePerSessionData";

export const FeePerSession = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                closeButton={true}
                title="Fee Per Session"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                popupButton={
                    <Button
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        view
                    </Button>
                }
                content={<FeePerSessionData />}
            />
        </div>
    );
};
