import React from "react";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
import { textPublisherStatus } from "../../redux/features/mastersSlice";

export const TextPublisherStatus = ({ params }) => {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.is_active}
                api={textPublisherStatus(params.text_publisher_id)}
            />
        </div>
    );
};
