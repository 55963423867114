import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import {Button} from '@mui/material';
// component
import { useDispatch,useSelector } from 'react-redux';

import { useSnackbar } from "notistack";
import { Link as RouterLink } from 'react-router-dom';




// ----------------------------------------------------------------------

export default function LandingPage() {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();


   

  
  

  return (

          <Button fullWidth size="large" type="submit" variant="contained" component={RouterLink} to="/dashboard"  >
           Go to Admin Panel
          </Button>
      
  );
}
