import React from "react";
import {
  Box,
  TextField,
  Stack,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import useResponsive from "../../../../../Hooks/useResponsive";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";

export default function Personalnformation({ formik }) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  console.log("formik.values", formik.values);

  return (
    <div>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          {!smUp ? (
            <>
              <Typography padding={1} variant="h6">
                Personal Information
              </Typography>
            </>
          ) : null}
          <Box margin={!smUp ? 0.5 : 3}>
            <Stack
              direction={`${!smUp ? "column" : "row"}`}
              spacing={!smUp ? 1 : 2}
            >
              <TextField
                label="Full Name (As per AADHAAR)"
                variant="outlined"
                fullWidth
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.name && formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />

              <TextField
                label="Name of Parent / Guardian"
                variant="outlined"
                fullWidth
                name="parentName"
                value={formik.values.parentName}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.parentName && formik.errors.parentName
                )}
                helperText={
                  formik.touched.parentName && formik.errors.parentName
                }
              />
            </Stack>
          </Box>
          {/* --------------------------------------------------------------------------- */}
          <Box margin={!smUp ? 0.5 : 3}>
            <Stack
              direction={`${!smUp ? "column" : "row"}`}
              spacing={!smUp ? 1.5 : 2}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  variant="outlined"
                  name="sex"
                  value={formik.values.sex}
                  label="Gender"
                  onChange={(e) => formik.setFieldValue("sex", e.target.value)}
                  error={Boolean(formik.touched.sex && formik.errors.sex)}
                  // helperText={formik.touched.sex && formik.errors.sex}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.touched.sex && formik.errors.sex}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Blood Group</InputLabel>
                <Select
                  variant="outlined"
                  value={formik.values.bloodGroup}
                  label="Blood Group"
                  name="bloodGroup"
                  onChange={(e) => {
                    formik.setFieldValue("bloodGroup", e.target.value);
                  }}
                  error={Boolean(
                    formik.touched.bloodGroup && formik.errors.bloodGroup
                  )}
                  // helperText={formik.touched.bloodGroup && formik.errors.bloodGroup}
                >
                  <MenuItem value="A+ve">A+ve</MenuItem>
                  <MenuItem value="A-ve">A-ve</MenuItem>
                  <MenuItem value="B+ve">B+ve</MenuItem>
                  <MenuItem value="B-ve">B-ve</MenuItem>
                  <MenuItem value="AB+ve">AB+ve</MenuItem>
                  <MenuItem value="AB-ve">AB-ve</MenuItem>
                  <MenuItem value="O+ve">O+ve</MenuItem>
                  <MenuItem value="O-ve">O-ve</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.touched.bloodGroup && formik.errors.bloodGroup}
                </FormHelperText>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>Marital Status </InputLabel>
                <Select
                  variant="outlined"
                  value={formik.values.maritalStatus}
                  label="Marital Status"
                  name="maritalStatus"
                  onChange={(e) => {
                    formik.setFieldValue("maritalStatus", e.target.value);
                  }}
                  error={Boolean(
                    formik.touched.maritalStatus && formik.errors.maritalStatus
                  )}
                  // helperText={formik.touched.maritalStatus && formik.errors.maritalStatus}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                  <MenuItem value="Separated">Separated</MenuItem>
                  <MenuItem value="Divorced">Divorced</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.touched.maritalStatus && formik.errors.maritalStatus}
                </FormHelperText>
              </FormControl>
            </Stack>
          </Box>

          <Box margin={!smUp ? 0.5 : 3}>
            <Stack
              direction={`${!smUp ? "column" : "row"}`}
              spacing={!smUp ? 1.5 : 2}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date Of Birth"
                  inputFormat="YYYY/MM/DD"
                  mask=""
                  name="dob"
                  value={formik.values.dob}
                  onChange={(e) => {
                    formik.setFieldValue("dob", e.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" fullWidth {...params} />
                  )}
                  error={Boolean(formik.touched?.dob && formik.errors.dob)}
                  // helperText={formik.touched.dob && formik.errors.dob}
                />
                <FormHelperText>
                  {formik.touched.dob && formik.errors.dob}
                </FormHelperText>
              </LocalizationProvider>

              <TextField
                name="parentContactNo"
                variant="outlined"
                label="Mobile No. of Parent / Guardian "
                fullWidth
                type="number"
                value={formik.values.parentContactNo}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.parentContactNo &&
                    formik.errors.parentContactNo
                )}
                helperText={
                  formik.touched.parentContactNo &&
                  formik.errors.parentContactNo
                }
              />
            </Stack>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" type="submit">
              Next
              <Icon icon="tabler:player-track-next" width="25" height="25" />
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </div>
  );
}
