import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { Box, Typography } from "@mui/material";
import { Details } from "./Details";
import { Actions } from "./Actions";

const data = [
    {
        id: 1,
        admissionNo: 1112,
        name: "Athul",
        class: 6,
        course: "Classroom - Long Term",
        mobile: "8086455308",

        status: "Not Attended",
        remark: "no reamrks....",

        actions: "",
    },
];

const columns = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },
    {
        field: "admissionNo",
        headerName: "admissionNo",
        width: 150,
    },
    {
        field: "name",
        headerName: "name",
        width: 150,
    },
    {
        field: "class",
        headerName: "class",
        width: 100,
    },
    {
        field: "course",
        headerName: "course",
        width: 200,
    },
    {
        field: "mobile",
        headerName: "mobile",
        width: 150,
    },

    {
        field: "status",
        headerName: "status",
        width: 150,
    },
    {
        field: "remark",
        headerName: "remark",
        width: 250,
    },

    {
        field: "actions",
        headerName: "actions",
        width: 150,
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function InactiveStudents() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Inactive Students"
                Content={
                    <>
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.admissionNo}
                                label="AdNo"
                                name={selectedRows[0]?.name}
                                details={<Details data={selectedRows[0]} />}
                                actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
