import { DeleteProjectPackage } from "./DeleteProjectPackage";
import { EditProjectPackege } from "./EditProjectPackege";
import { ProjectPackageListingStatus } from "./ProjectPackageListingStatus";
import { ProjectPackageStatus } from "./ProjectPackageStatus";

export const PROJECT_CLASSES_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "projectName",
        headerName: "Project Name",
        width: 150,
    },
    {
        field: "packageName",
        headerName: "Package Name",
        width: 200,
    },
    {
        field: "packageDescription",
        headerName: "Package Description ",
        width: 250,
    },
    {
        field: "packageSessions",
        headerName: "Package Sessions",
        width: 250,
    },
    {
        field: "isActive",
        headerName: "isActive",
        width: 150,
        renderCell: (params) => <ProjectPackageStatus params={params.row} />,
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 150,
        renderCell: (params) => (
            <ProjectPackageListingStatus params={params.row} />
        ),
    },
    {
        field: "Edit",
        headerName: "Edit",
        width: 150,
        renderCell: (params) => <EditProjectPackege params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => (
            <DeleteProjectPackage params={params.row.packageId} />
        ),
    },
];
