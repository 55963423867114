import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Button, Box } from "@mui/material";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { StudentProfileData } from "./StudentProfileData";
import Wrapper from "../../../../Wrapper/Wrapper";
import { useDispatch } from "react-redux";
import { getStudentProfile } from "../../../../../redux/features/crmSlice";

export const Profile = React.memo(({ params,url,storeName }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    return (
        <div>
            <GlobalDialog
                title="Student Profile"
                fullWidth
                closeButton={true}
                open={open}
                setOpen={setOpen}
                maxWidth="lg"
                popupButton={
                    <>
                        <Button
                        variant="outlined"
                         size="small"
                            onClick={() => {
                                setOpen(true);
                                if(url){
                               dispatch(url(params))
                                }else{
                                    dispatch(getStudentProfile(params));
                                }
                               
                            }}
                        >
                            <Icon style={{marginRight:"3px"}} icon="carbon:view" width="20" height="20" />
                            View
                        </Button>
                    </>
                }
                content={
                    <>
                        <Box sx={{ height: 800 }}>
                            <StudentProfileData storeName={storeName} setOpen={setOpen} />
                        </Box>
                    </>
                }
            />
        </div>
    );
});
