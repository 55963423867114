import React, { useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Box, Paper, Stack, TextField } from "@mui/material";
import useResponsive from "../../../../../Hooks/useResponsive";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import {
    getAllStudentSubjectData,
    assignTutor,
} from "../../../../../redux/features/croSlice";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CancelButton, SaveButton } from "../../../../mui/Buttons/Buttons";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import Wrapper from "../../../../Wrapper/Wrapper";

const classTypes = [
    {
        id: "ONE_TO_ONE",
        type: "ONE_TO_ONE",
    },
];
const type = [
    {
        id: "INSTALLMENT",
        type: "INSTALLMENT",
    },
];
const currency = [
    {
        id: "INR",
        name: "INR",
    },
];

const TutorAssignForm = ({ params, setOpen }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState(dayjs());
    const dispatch = useDispatch();
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const profile = JSON.parse(localStorage.getItem("cred")).profile;
    const { loading, wiseAppStudents, allStudentSubjectDatas } = useSelector(
        (state) => state.cro
    );

    const Schema = Yup.object().shape({
        subjectId: Yup.string().required("subject is required"),
        classType: Yup.string().required("classType is required"),
        sessionCredits: Yup.string().required("sessionCredits is required"),
        note: Yup.string().required("note is required"),
        studentId: Yup.string().required("studentName is required"),
        phoneNumber: Yup.string().required("phoneNumber is required"),
        currency: Yup.string().required("currency is required"),
        type: Yup.string().required("type is required"),
        amount: Yup.string().required("amount is required"),
        trainer_payment_per_session: Yup.string().required(
            "trainer_payment_per_session is required"
        ),
        trainer_session_duration: Yup.string().required(
            "trainer_session_duration is required"
        ),
        tp_remarks: Yup.string().required("tp_remarks is required"),
    });
    const formik = useFormik({
        initialValues: {
            instituteId: "",
            name: params.full_name,
            subjectId: "",
            subject: "",
            classType: "",
            adminId: profile.wiseUserId,
            sessionCredits: "",
            note: "",
            studentId: "",
            studentName: "",
            phoneNumber: "",
            currency: "",
            type: "",
            sessionDate: dayjs(value).format("YYYY/MM/DD"),
            amount: "",
            templateId: "",
            trainer_subject_enrollment_id: "",
            trainer_id: params.trainer_id,
            trainer_payment_per_session: "",
            trainer_session_duration: "",
            wiseUserId: params.wiseUserId,
            tp_remarks: "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            dispatch(assignTutor()).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    useEffect(() => {
        if (formik.values.studentId) {
            const studentName = wiseAppStudents.find(
                (item) => item.studentAdmin_no === formik.values.studentId
            );
            formik.setFieldValue("studentName", studentName.studentName);
            formik.setFieldValue("phoneNumber", studentName.phoneNumber);
            formik.setFieldValue("instituteId", studentName.instituteId);
        }
    }, [formik.values.studentId]);
    useEffect(() => {
        if (formik.values.subjectId) {
            const subjectname = allStudentSubjectDatas.find(
                (item) => item.serialNumber === formik.values.subjectId
            );
            formik.setFieldValue("subject", subjectname.subjectName);
            formik.setFieldValue(
                "trainer_subject_enrollment_id",
                subjectname.subject_enrollment_id
            );
        }
    }, [formik.values.subjectId]);
    useEffect(() => {
        if (formik.values.studentId) {
            const data = {
                adm_no: formik.values.studentId,
            };
            dispatch(getAllStudentSubjectData(data));
        }
    }, [formik.values.studentId]);
    useEffect(() => {
        if (formik.values.subjectId) {
            const subjectName = allStudentSubjectDatas.find(
                (item) => item.serialNumber === formik.values.subjectId
            );
            formik.setFieldValue("subject", subjectName.subject_name);
            formik.setFieldValue(
                "trainer_payment_per_session",
                subjectName.sro_fee_per_session
            );
        }
    }, [formik.values.subjectId]);

    return (
        <div>
            <Wrapper
                Content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Paper elevation={3} sx={{ padding: 2 }}>
                                        <Stack
                                            direction={smUp ? "row" : "column"}
                                            spacing={2}
                                        >
                                            <TextField
                                                name="name"
                                                fullWidth
                                                label="Trainer Name"
                                                value={formik.values.name}
                                                disabled
                                            />
                                            <Box width={"100%"}>
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Student",
                                                        formik: formik,
                                                        name: "studentId",
                                                        data: wiseAppStudents,
                                                        keys: {
                                                            id: "studentAdmin_no",
                                                            value: "studentName",
                                                        },
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Stack>
                                        <Box mt={2}>
                                            <TextField
                                                name="phoneNumber"
                                                fullWidth
                                                label="Phone Number"
                                                value={
                                                    formik.values.phoneNumber
                                                }
                                                disabled
                                                error={
                                                    formik.touched
                                                        .phoneNumber &&
                                                    Boolean(
                                                        formik.errors
                                                            .phoneNumber
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.phoneNumber
                                                }
                                            />
                                        </Box>

                                        <Stack
                                            direction={smUp ? "row" : "column"}
                                            spacing={2}
                                            pt={2}
                                        >
                                            <Box width={"100%"}>
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Subject",
                                                        formik: formik,
                                                        name: "subjectId",
                                                        data: allStudentSubjectDatas,
                                                        keys: {
                                                            id: "serialNumber",
                                                            value: "subject_name",
                                                        },
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box width={"100%"}>
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Class Type",
                                                        formik: formik,
                                                        name: "classType",
                                                        data: classTypes,
                                                        keys: {
                                                            id: "id",
                                                            value: "type",
                                                        },
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Stack>
                                        <Stack
                                            direction={smUp ? "row" : "column"}
                                            spacing={2}
                                            pt={2}
                                        >
                                            <Box width={"100%"}>
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Type",
                                                        formik: formik,
                                                        name: "type",
                                                        data: type,
                                                        keys: {
                                                            id: "id",
                                                            value: "type",
                                                        },
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <Box width={"100%"}>
                                                <GlobalSelectField
                                                    options={{
                                                        label: "Currency",
                                                        formik: formik,
                                                        name: "currency",
                                                        data: currency,
                                                        keys: {
                                                            id: "id",
                                                            value: "name",
                                                        },
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Stack>
                                        <Stack
                                            direction={smUp ? "row" : "column"}
                                            spacing={2}
                                            pt={2}
                                        >
                                            <Box sx={{ width: "100%" }}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        label="sessionDate"
                                                        value={value}
                                                        onChange={(newValue) =>
                                                            setValue(newValue)
                                                        }
                                                        sx={{ width: "100%" }}
                                                    />
                                                </LocalizationProvider>
                                            </Box>

                                            <TextField
                                                name="trainer_payment_per_session"
                                                fullWidth
                                                label="Trainer Payment Per Session"
                                                value={
                                                    formik.values
                                                        .trainer_payment_per_session
                                                }
                                                disabled
                                                error={
                                                    formik.touched
                                                        .trainer_payment_per_session &&
                                                    Boolean(
                                                        formik.errors
                                                            .trainer_payment_per_session
                                                    )
                                                }
                                                helperText={
                                                    formik.errors
                                                        .trainer_payment_per_session
                                                }
                                            />
                                        </Stack>
                                        <Stack
                                            direction={smUp ? "row" : "column"}
                                            spacing={2}
                                            pt={2}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Amount"
                                                name="amount"
                                                value={formik.values.amount}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.amount &&
                                                    Boolean(
                                                        formik.errors.amount
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.amount
                                                }
                                            />
                                            <TextField
                                                fullWidth
                                                label="Session Credits"
                                                name="sessionCredits"
                                                value={
                                                    formik.values.sessionCredits
                                                }
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched
                                                        .sessionCredits &&
                                                    Boolean(
                                                        formik.errors
                                                            .sessionCredits
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.sessionCredits
                                                }
                                            />
                                        </Stack>
                                        <Stack
                                            direction={"column"}
                                            spacing={2}
                                            pt={2}
                                        >
                                            <TextField
                                                name="trainer_session_duration"
                                                fullWidth
                                                label="Trainer Session Duration"
                                                value={
                                                    formik.values
                                                        .trainer_session_duration
                                                }
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched
                                                        .trainer_session_duration &&
                                                    Boolean(
                                                        formik.errors
                                                            .trainer_session_duration
                                                    )
                                                }
                                                helperText={
                                                    formik.errors
                                                        .trainer_session_duration
                                                }
                                            />
                                            <TextField
                                                name="note"
                                                fullWidth
                                                label="Note"
                                                multiline
                                                rows={3}
                                                value={formik.values.note}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.note &&
                                                    Boolean(formik.errors.note)
                                                }
                                                helperText={formik.errors.note}
                                            />
                                            <TextField
                                                name="tp_remarks"
                                                fullWidth
                                                label="T P Remarks"
                                                multiline
                                                rows={3}
                                                value={formik.values.tp_remarks}
                                                onChange={formik.handleChange}
                                                error={
                                                    formik.touched.tp_remarks &&
                                                    Boolean(
                                                        formik.errors.tp_remarks
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.tp_remarks
                                                }
                                            />
                                        </Stack>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                mt: 2,
                                            }}
                                        >
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <CancelButton
                                                    action={() =>
                                                        setOpen(false)
                                                    }
                                                >
                                                    Cancel
                                                </CancelButton>
                                                <SaveButton type="submit">
                                                    {loading
                                                        ? "Saving.."
                                                        : "Save"}
                                                </SaveButton>
                                            </Stack>
                                        </Box>
                                    </Paper>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};

export default TutorAssignForm;
