import { useEffect } from "react";
import {
  Typography,
  Box,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Paper,
} from "@mui/material";
import useResponsive from "../../../Hooks/useResponsive";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Page from "../../../components/Page";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Iconify from "../../../components/Iconify";
import { useSelector, useDispatch } from "react-redux";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import {
  clearAll,
  getNotificationLogs,
} from "../../../redux/features/notificationSlice";
import { NOTIFICATIONS_LOG_HEAD as columns } from "../../../components/datagrid/NotificationLogColumns";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { clearRes } from "../../../redux/features/notificationSlice";
import BasicButton from "../../../components/mui/Button";
import BasicDatePicker from "../../../components/mui/DatePicker";
import { useSnackbar } from "notistack";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { Divider } from "@mui/material";

import Chip from "@mui/material/Chip";

export default function ViewLog() {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const viewLog = useSelector((state) => state.notification);
  const loading = useSelector((state) => state.notification.loading);
  const [gridSize, setGridSize] = useState(5);
  const Schema = Yup.object().shape({
    type: Yup.string()
      .required("Type Required")
      .oneOf(["BROADCAST", "CUSTOM_GROUP", "CUSTOM_SINGLE"]),
    from: Yup.string().required("from Required"),
    to: Yup.string().required("to Required"),
  });
  const formik = useFormik({
    initialValues: {
      type: "",
      from: null,
      to: null,
    },
    validationSchema: Schema,

    onSubmit: (values) => {
      const data = {
        type: values.type,
        from: values.from.toISOString().split("T")[0],
        to: values.to.toISOString().split("T")[0],
      };

      dispatch(getNotificationLogs(data)).then((res) => {});
    },
  });
  useEffect(() => {
    return () => {
      dispatch(clearAll());
    };
  }, []);

  return (
    <div>
      {smUp && (
        <Page title="Notifications log">
          <Box>
            <Typography variant="h4">View Notification Log</Typography>
            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
          </Box>

          <Box>
            <Paper
              elevation={3}
              sx={{ backgroundColor: "grey.200", padding: 1.5, marginTop: 3 }}
            >
              <Typography variant="h5" sx={{ opacity: "80%" }}>
                Date and Type
              </Typography>
              <Divider sx={{ borderBottomWidth: 4 }} variant="middle" />
              <Box sx={{ margin: 3 }}>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Stack direction="row" spacing={3}>
                      <Box>
                        <BasicDatePicker
                          value={formik.values.from}
                          views={["year", "month", "day"]}
                          format="YYYY-MM-DD"
                          label="From-Date"
                          sx={{ width: "auto" }}
                          renderInput={(params) => <TextField {...params} />}
                          action={(e) => {
                            formik.setFieldValue("from", e);
                          }}
                        />
                      </Box>
                      <Box>
                        <BasicDatePicker
                          value={formik.values.to}
                          views={["year", "month", "day"]}
                          format="YYYY-MM-DD"
                          label="to-Date"
                          sx={{ width: "auto" }}
                          renderInput={(params) => <TextField {...params} />}
                          action={(e) => {
                            formik.setFieldValue("to", e);
                          }}
                        />
                      </Box>
                      <Box>
                        <FormControl
                          sx={{
                            flex: 1,
                            display: "flex",
                          }}
                          style={{ minWidth: 160 }}
                        >
                          <InputLabel id="notification-type-label">
                            Select Type
                          </InputLabel>
                          <Select
                            //  sx={{width:250 }}

                            name="type"
                            labelId="notification-type-label"
                            label="Select Type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            IconComponent={() => (
                              <Iconify
                                icon={"eva:chevron-down-outline"}
                                width={29}
                                height={22}
                                color="grey.400"
                                sx={{
                                  mr: 0.5,
                                }}
                              />
                            )}
                          >
                            <MenuItem value="BROADCAST">Broadcast</MenuItem>
                            <MenuItem value="CUSTOM_GROUP">Group</MenuItem>
                            <MenuItem value="CUSTOM_SINGLE">Single</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <LoadingButton
                          variant="contained"
                          type="submit"
                          sx={{ borderRadius: 25 }}
                        >
                          {loading ? (
                            <Icon
                              icon="eos-icons:bubble-loading"
                              width="25"
                              height="25"
                            />
                          ) : (
                            "View"
                          )}
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Box>
            </Paper>

            {/* {!schedule.loading && sessions.length ? ( */}
            <Paper
              elevation={3}
              sx={{ borderRadius: 1, width: "100%", marginTop: 4 }}
            >
              <Box
                sx={{
                  backgroundColor: "grey.200",
                  borderRadius: "8px 8px 0 0",
                  display: "flex",
                  height: 44,
                }}
              ></Box>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  loading={viewLog.loading}
                  rows={viewLog.logs}
                  columns={columns}
                  getRowId={(row) => row.logsId}
                  autoHeight
                  pageSize={gridSize}
                  getRowHeight={() => "auto"}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  components={{ Toolbar: CustomGridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 500,
                      },
                    },
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </Page>
      )}
      {!smUp && (
        <Page title="Notifications log">
          <Box>
            <Typography variant="h4">View Notification Log</Typography>
            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
          </Box>
          <Box>
            <Paper
              elevation={3}
              sx={{ backgroundColor: "grey.200", padding: 1.5, marginTop: 3 }}
            >
              <Typography variant="h5" sx={{ opacity: "80%" }}>
                Date and Type
              </Typography>
              <Divider sx={{ borderBottomWidth: 4 }} variant="middle" />
              <Box sx={{ margin: 3 }}>
                <FormikProvider value={formik}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Stack direction="column" spacing={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="From-Date"
                          format="YYYY-MM-DD"
                          views={["year", "month", "day"]}
                          value={formik.values.from}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "from",
                              e.toISOString().split("T")[0]
                            );
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          sx={{ width: "auto" }}
                          label="to-Date"
                          value={formik.values.to}
                          format="YYYY-MM-DD"
                          views={["year", "month", "day"]}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "to",
                              e.toISOString().split("T")[0]
                            );
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                      <FormControl sx={{ flex: 1 }}>
                        <InputLabel id="notification-type-label">
                          Select Type
                        </InputLabel>
                        <Select
                          sx={{ width: "100%" }}
                          name="type"
                          labelId="notification-type-label"
                          label="Select Type"
                          value={formik.values.type}
                          onChange={formik.handleChange}
                          IconComponent={() => (
                            <Iconify
                              icon={"eva:chevron-down-outline"}
                              width={29}
                              height={22}
                              color="grey.400"
                              sx={{
                                mr: 0.5,
                              }}
                            />
                          )}
                        >
                          <MenuItem value="BROADCAST">Broadcast</MenuItem>
                          <MenuItem value="CUSTOM_GROUP">Group</MenuItem>
                          <MenuItem value="CUSTOM_SINGLE">Single</MenuItem>
                        </Select>
                      </FormControl>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <BasicButton
                          variant="contained"
                          type="submit"
                          sx={{ borderRadius: 25 }}
                        >
                          View
                        </BasicButton>
                      </Box>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Box>
            </Paper>

            {/* {!schedule.loading && sessions.length ? ( */}
            <Paper
              elevation={3}
              sx={{ borderRadius: 1, width: "100%", marginTop: 4 }}
            >
              <Box
                sx={{
                  backgroundColor: "grey.200",
                  borderRadius: "8px 8px 0 0",
                  display: "flex",
                  height: 44,
                }}
              ></Box>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  loading={viewLog.loading}
                  rows={viewLog.logs}
                  columns={columns}
                  getRowId={(row) => row.logsId}
                  autoHeight
                  pageSize={gridSize}
                  getRowHeight={() => "auto"}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  components={{ Toolbar: CustomGridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 500,
                      },
                    },
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </Page>
      )}
    </div>
  );
}
