import React from "react";
import CustomGridToolbar from "../../GridToolBar";
import { DataGrid } from "@mui/x-data-grid";
import { SOURCE_TABLE_HEAD } from "./SourceTableHead";
import { Box } from "@mui/material";

const SourceTable = ({ data, loading }) => {
    return (
        <Box
            sx={{
                marginRight: "30px",
            }}
        >
            <h2
                style={{
                    marginBottom: "10px",
                }}
            >
                Source
            </h2>
            <DataGrid
                loading={loading}
                rows={data}
                columns={SOURCE_TABLE_HEAD}
                sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
                pageSize={5}
                autoHeight
                rowsPerPageOptions={[5, 10, 25, 50]}
                getRowId={(row) => row.serialNumber}
                components={{ Toolbar: CustomGridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </Box>
    );
};

export default SourceTable;
