import React from "react";
import GlobalDeleteDialog from "../../../components/mui/Dialogue/GlobalDeleteDialog";
import { useSelector } from "react-redux";
import {
    deleteTimeSlot,
    getSlotesById,
} from "../../../redux/features/cabinBookingSlice";

export const DeleteTimeSlot = ({ data }) => {
    const { loading } = useSelector((state) => state.cabin);
    return (
        <div>
            <GlobalDeleteDialog
                btnName={false}
                loading={loading}
                title="Delete"
                deleteNote="Are you sure.You want to delete this slot...?"
                api={deleteTimeSlot(data.slot_id)}
                upDateCall={getSlotesById(data.cabin_id)}
            />
        </div>
    );
};
