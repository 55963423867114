import React from "react";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";
import { Box, Grid } from "@mui/material";

export default function Details({ data }) {
    const dataa = {
        name: "Athul",
        age: 24,
        place: "Areekode",
    };

    const fil = Object.entries(data);

    let mydata = fil.map((item) => {
        const dataa = { label: item[0], value: item[1] };
        console.log("new", dataa);
        return dataa;
    });

    const dataaaa = [
        { id: 1, label: "name", value: "Athul" },
        { id: 2, label: "age", value: 24 },
        { id: 3, label: "place", value: "Areekode" },
    ];
    return (
        <div>
            <Box>
                <Grid container spacing={1}>
                    {/* <Mytable
                        tableRow={
                            <>
                                {mydata.map((item) => {
                                    return (
                                        <Rows
                                            label={`${item.label}`}
                                            value={item.value}
                                        />
                                    );
                                })}
                            </>
                        }
                    /> */}
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Class" value={data?.class} />
                                <Rows label="mobile" value={data?.mobile} />
                                <Rows label="course" value={data?.course} />
                                <Rows
                                    label="Total Sessions"
                                    value={data?.totalSessions}
                                />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows
                                    label="Session Completed"
                                    value={data?.sessionsCompleted}
                                />
                                <Rows
                                    label="Completed %"
                                    value={data?.completionPercentage}
                                />
                                <Rows
                                    label="Last Session Date "
                                    value={data?.lastSessionDate}
                                />
                                <Rows
                                    label="Next Enquiry "
                                    value={data?.nextEnquiry}
                                />
                            </>
                        }
                    />
                </Grid>
            </Box>
        </div>
    );
}
