import { Icon } from "@iconify/react";
import { Button, Stack } from "@mui/material";
import React, { useState } from "react";
import PopUps from "./PopUps";
import CroStudentUpdateForm from "./CroStudentUpdateForm";
import { Profile } from "../../CRM/AdmissionRegister/StudentProfile/Profile";

const ShareAndEditAction = ({ row }) => {
    const [open, setOpen] = useState(false);
    const componentProps = {
        setOpen: setOpen,
        row: row,
    };
    return (
        <Stack direction="row" gap={2}>
            {row.studentProfileStatus === "Not Completed" ? (
                <Button
                    variant="contained"
                    onClick={() => {
                        return row.studentProfileFormLink
                            ? window.open(row.studentProfileFormLink, "_blank")
                            : null;
                    }}
                >
                    <Icon icon="majesticons:share" width="25" height="25" />
                </Button>
            ) : (
                // the popup to be displayed on Clicking this button was already done by athul
                // shold be re-created here after merging

                // <Button variant="contained" color="success">
                //     <Icon icon="carbon:view" width="25" height="25" />
                // </Button>
                <Profile params={row.courseEnrollmentId} />
            )}
            <Button variant="contained" onClick={() => setOpen(true)}>
                <Icon icon="bx:edit" width="25" height="25" />
            </Button>
            <PopUps
                setOpen={setOpen}
                open={open}
                component={CroStudentUpdateForm}
                componentProps={componentProps}
            />
        </Stack>
    );
};

export default ShareAndEditAction;
