export const CRO_ANALYTICS_TBLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "CourseEnrollmentNumber",
        headerName: "Course Enrollment No",
        width: 150,
    },
    {
        field: "admnNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "CourseErollmentDate",
        headerName: "Course Enrollment Date",
        width: 150,
    },

    {
        field: "StudentName",
        headerName: "Student Name",
        width: 150,
    },

    {
        field: "class",
        headerName: "Class",
        width: 150,
    },
    {
        field: "courseEnrolled",
        headerName: "Course Enrolled",
        width: 150,
    },

    {
        field: "sroRemarks",
        headerName: "Sro Remarks",
        width: 150,
    },
    {
        field: "croRemarks",
        headerName: "Cro Remarks",
        width: 150,
    },

    {
        field: "classPerWeek",
        headerName: "Class Per Week",
        width: 150,
    },
    {
        field: "feePerSession",
        headerName: "Fee per Session",
        width: 150,
    },
    {
        field: "lastSessionDate",
        headerName: "Last Session Date",
        width: 150,
    },
    {
        field: "expectedSessions",
        headerName: "ExpectedSessions",
        width: 150,
    },
    {
        field: "firstWeeksessions",
        headerName: "1st Week Sessions",
        width: 150,
    },

    {
        field: "secondWeekSessions",
        headerName: "2nd Week Sessions",
        width: 150,
    },
    {
        field: "thirdWeekSessions",
        headerName: "3rd Week Sessions",
        width: 150,
    },
    {
        field: "fourthWeekSessions",
        headerName: "4th Week Sessions",
        width: 150,
    },
    {
        field: "totalSessions",
        headerName: "Total Sessions",
        width: 150,
    },
    {
        field: "SroSalesAmount",
        headerName: "Sro Sales Amount",
        width: 150,
    },
    {
        field: "totalFeePaid",
        headerName: "Toatal Fee Paid",
        width: 150,
    },
    {
        field: "balance",
        headerName: "Balance",
        width: 150,
    },
];
