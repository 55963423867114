import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Divider } from "@mui/material";
import {
  deleteRole,
  getAllRoles,
  getRoleById,
} from "../../../redux/features/rolesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import EditRole from "./EditRole";
import useResponsive from "../../../../src/Hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RoleActions(params) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.roles.loading);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDelete, setOpenDelete] = React.useState(false);

  const handleClickOpen = () => {
    setAnchorEl(null);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  // -----------------------------------------------------------------------------

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpenEdit = () => {
    dispatch(getRoleById(params.row.userTypeId));
    setAnchorEl(null);
    setOpenEdit(true);
  };
  const handleDelete = () => {
    dispatch(deleteRole(params.row.userTypeId)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === 200) {
        enqueueSnackbar(res.payload.message, { variant: "success" });
        dispatch(getAllRoles());
        setOpenDelete(false);
      }
    });
  };
  return (
    <div>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Icon icon="carbon:overflow-menu-vertical" width="30" height="30" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClickOpenEdit}>
            <Icon
              icon="material-symbols:edit-document-outline"
              width="30"
              height="30"
              color="#3576b5"
            />
            Edit
          </MenuItem>
          <MenuItem onClick={handleClickOpen}>
            <Icon
              icon="material-symbols:delete-outline-rounded"
              width="30"
              height="30"
              color="red"
            />
            Delete
          </MenuItem>
        </Menu>
      </div>
      {/* ----------------------Delete-Confirmation------------------------ */}
      <div>
       
        {smUp && (
          <Dialog
            open={openDelete}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDelete}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Delete Role.."}</DialogTitle>
            <Divider />
            <DialogContent>
              {/* <DialogContentText id="alert-dialog-slide-description"> */}
                <Box sx={{ width: 380 }}>
                  Are you sure you want to delete this role..?
                </Box>
              {/* </DialogContentText> */}
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ backgroundColor: "#eff4f9", color: "#2f72b3" }}
                onClick={handleCloseDelete}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ backgroundColor: "#ffe4de", color: "#c03530" }}
                onClick={handleDelete}
              >
                <Icon
                  icon="material-symbols:delete-outline-rounded"
                  width="25"
                  height="25"
                />
                {loading ? "Deleting.." : "Delete"}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
        {!smUp && (
          <Dialog
            open={openDelete}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDelete}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Delete Role.."}</DialogTitle>
            <Divider />
            <DialogContent>
              {/* <DialogContentText id="alert-dialog-slide-description"> */}
                <Box>Are you sure you want to delete this role..?</Box>
              {/* </DialogContentText> */}
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ backgroundColor: "#eff4f9", color: "#2f72b3" }}
                onClick={handleCloseDelete}
              >
                Cancel
              </Button>
              <LoadingButton
                sx={{ backgroundColor: "#ffe4de", color: "#c03530" }}
                onClick={handleDelete}
              >
                <Icon
                  icon="material-symbols:delete-outline-rounded"
                  width="25"
                  height="25"
                />
                {loading ? "Deleting.." : "Delete"}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        )}
      </div>
      {/* --------------------------------end-delete------------------------------------- */}
        <EditRole setOpenEdit={setOpenEdit} openEdit={openEdit} />
     
    </div>
  );
}
