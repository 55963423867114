import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { CancelButton } from "../../../../mui/Buttons/Buttons";
import { LogData } from "./LogData";
import { getCroEnquiryLog } from "../../../../../redux/features/crmSlice";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";

export const CroEnquiry = React.memo(({ params }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                closeButton={true}
                title="CRO Equiry Log"
                maxWidth={"xl"}
                setOpen={setOpen}
                open={open}
                popupButton={
                    <>
                        <CancelButton
                            action={() => {
                                dispatch(
                                    getCroEnquiryLog(params.courseEnrollmentId)
                                );
                                setOpen(true);
                            }}
                            size="small"
                        >
                            Log
                        </CancelButton>
                    </>
                }
                content={<LogData params={params} />}
            />
        </div>
    );
});
