import React from "react";
import Wrapper from "../../../../src/components/Wrapper/Wrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { CRO_ANALYTICS_TABLE_HEAD as columns } from "../../../components/datagrid/CRM/Analytics/CroAnalytics/CroAnalyticsTableHead";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const data = [
    {
        serialNumber: 1,
        croName: "AJVAD SABAH K C",
        totalLeads: 60,
        notAttended: 10,
        admissionConfirmed: 10,
        waitingForResponse: 2,
        demoClassScheduled: 3,
        postponed: 2,
        responseIssue: 2,
        notReachable: 3,
        cancelled: 1,
        expelled: 0,
        percentageOfConversion: "10%",
        percentageOfPendingresponse: "10%",
    },
];

export default function CroAnalytics() {
    const [gridSize, setGridSize] = React.useState(5);
    const [value, setValue] = React.useState(dayjs("2022-04-07"));
    return (
        <div>
            <Wrapper
                title="CRO Analytics"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        // formik: formik,
                                        label: "Course",
                                        name: "courseId",
                                        sx: { width: "100%" },
                                        // data: data.coursesPreData,

                                        keys: {
                                            id: "courseId",
                                            value: "courseName",
                                        },
                                    }}
                                    variant="filled"
                                />
                            </Box>
                            <Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["year", "month"]}
                                        label="Year and Month"
                                        minDate={dayjs("2012-03-01")}
                                        maxDate={dayjs("2023-06-01")}
                                        value={value}
                                        onChange={(newValue) => {
                                            setValue(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                helperText={null}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                        <Paper elevation={3}>
                            <Box width="100%">
                                <Box
                                    sx={{
                                        backgroundColor: "grey.200",
                                        borderRadius: "8px 8px 0 0",
                                        display: "flex",
                                        height: 44,
                                    }}
                                ></Box>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    // loading={loading}
                                    rows={data}
                                    columns={columns}
                                    autoHeight
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
