import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";
import { Form } from "./Form";

export const AddExpectedSession = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                closeButton={true}
                title="Add Expected Sessions"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Add
                    </Button>
                }
                content={<Form />}
            />
        </div>
    );
};
