import { DeleteTextPublisher } from "./DeleteTextPublisher";
import { EditTextPublisher } from "./EditTextPublisher";
import { TextPublisherListingStatus } from "./TextPublisherListingStatus";
import { TextPublisherStatus } from "./TextPublisherStatus";

export const TEXT_PUBLISHER_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
    },
    {
        field: "text_publisher_name",
        headerName: "Text Publisher Pame",
        width: 150,
    },
    {
        field: "address_1",
        headerName: "Address 1",
        width: 90,
    },
    {
        field: "address_2",
        headerName: "Address 2",
        width: 250,
    },
    {
        field: "address_3",
        headerName: "Address 3",
        width: 90,
    },
    {
        field: "text_publisher_descr",
        headerName: "Text Publisher description",
        width: 250,
    },
    {
        field: "create_timestamp",
        headerName: "Create Time ",
        width: 250,
    },
    {
        field: "isActive",
        headerName: "isActive",
        width: 150,
        renderCell: (params) => <TextPublisherStatus params={params.row} />,
    },
    {
        field: "isListed",
        headerName: "isListed",
        width: 150,
        renderCell: (params) => (
            <TextPublisherListingStatus params={params.row} />
        ),
    },
    {
        field: "Edit",
        headerName: "Edit",
        width: 150,
        renderCell: (params) => <EditTextPublisher params={params.row} />,
    },
    {
        field: "Delete",
        headerName: "Delete",
        width: 150,
        renderCell: (params) => (
            <DeleteTextPublisher params={params.row.text_publisher_id} />
        ),
    },
];
