import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Icon } from "@iconify/react";
import { Box, Button, Chip, Divider, IconButton } from "@mui/material";

const RotatingIcon = () => {
  return (
    <IconButton
      sx={{
        color: "text.primary",
        animation: "spin 5s linear infinite",
        "@keyframes spin": {
          "0%": {
            transform: "rotate(360deg)",
          },
          "100%": {
            transform: "rotate(0deg)",
          },
        },
      }}
    >
      <Icon icon="solar:settings-outline" />
    </IconButton>
  );
};
export default function GlobalAnchor({
  openAnchor,
  setOpenAnchor,
  children,
  handleClick,
}) {
  const toggleDrawer = (newOpen) => () => {
    setOpenAnchor(newOpen);
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            borderRadius: "16px", // Set border radius
            padding: "25px",
            margin: 1,
            maxWidth: 300,
          },
        }}
        anchor="right"
        open={openAnchor}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            // variant="outlined"
            size="small"
            sx={{ borderRadius: 5 }}
            onClick={handleClick}
          >
            <Icon icon="line-md:close-small" height={22} width={22} />
          </Button>
        </Box>
        <Box pt={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Chip
            icon={<RotatingIcon />}
            label="ACTIONS"
            variant="outlined"
            color="primary"
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Divider sx={{ borderStyle: "dashed", borderBottomWidth: 3 }} />
        </Box>
        {children}
      </Drawer>
    </div>
  );
}
