import { Box, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { DeleteButton } from "../../../../components/mui/Buttons/Buttons";
import { motion, AnimatePresence } from "framer-motion";
import { useSnackbar } from "notistack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CartCourseComponent = ({ totalCourses, setTotalCourses }) => {
  const { enqueueSnackbar } = useSnackbar();
  console.log("totalCourses=====", totalCourses);

  // Handle deleting a course
  const handleDelete = (courseId) => {
    const updatedCourses = totalCourses.filter((item) => item.id !== courseId);
    enqueueSnackbar("Deleted Successfully...!", {
      variant: "success",
    });
    setTotalCourses(updatedCourses);
  };

  // Handle changes to the form fields
  const handleInputChange = (e, courseId, field) => {
    const { value, type, checked } = e.target;
    const updatedCourses = totalCourses.map((item) =>
      item.id === courseId
        ? { ...item, [field]: type === "checkbox" ? checked : value }
        : item
    );
    setTotalCourses(updatedCourses);
  };

  return (
    <Box>
      <AnimatePresence>
        {totalCourses.map((item) => {
          return (
            <motion.div
              key={item.id}
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.5 }}
            >
              <Paper
                sx={{
                  backgroundColor: "grey.200",
                  mt: 3,
                  padding: 2,
                  borderRadius: 3,
                }}
              >
                <Stack direction={"row"} spacing={2}>
                  <Box>
                    <img
                      style={{ borderRadius: 10 }}
                      src={item.courseImg}
                      height={80}
                      width={80}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: 13, fontWeight: "bolder" }}>
                        {item.courseName}
                      </Typography>
                    </Box>
                  </Box>

                  {/* Text fields for session count, amount, discount, and discounted amount */}
                  <TextField
                    label="no: of sessions"
                    value={item.sessions || ""}
                    onChange={(e) => handleInputChange(e, item.id, "sessions")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                      },
                    }}
                  />
                  <TextField
                    label="Amount"
                    value={item.amount || ""}
                    onChange={(e) => handleInputChange(e, item.id, "amount")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                      },
                    }}
                  />
                  <TextField
                    label="Discount"
                    value={item.discount || ""}
                    onChange={(e) => handleInputChange(e, item.id, "discount")}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                      },
                    }}
                  />
                  <TextField
                    label="Discounted Amount"
                    value={item.discountedAmount || ""}
                    onChange={(e) =>
                      handleInputChange(e, item.id, "discountedAmount")
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "30px",
                      },
                    }}
                  />

                  <Box sx={{ pt: 1 }}>
                    {/* Delete button and Checkbox for wish list */}
                    <DeleteButton
                      size="small"
                      action={() => handleDelete(item.id)}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.wishList || false}
                          onChange={(e) =>
                            handleInputChange(e, item.id, "wishList")
                          }
                        />
                      }
                      label="wish list"
                    />
                  </Box>
                </Stack>
              </Paper>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </Box>
  );
};

export default CartCourseComponent;
