import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import Wrapper from "../../../components/Wrapper/Wrapper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  getExpenseTypes,
  getExpenses,
  getStudentsforAddIncome,
} from "../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  EXPENSE_TABLE_HEAD,
  TP_TABLE_HEAD,
} from "../../../components/datagrid/myworkspace/ogd/expense/OgaExpenseTableHead";
import { OgaAddExpense } from "../../../components/datagrid/myworkspace/ogd/expense/AddExpense";
import GlobalDatagrid from "../../../components/mui/DataGrid/GlobalDatagrid";
import { UpdateExpenseView } from "../../../components/datagrid/myworkspace/ogd/ogmLeads/RenderCellMain";
import GlobalAnchorList from "../../../components/mui/GlobalAnchor/GlobalAnchorList";
import ActionDetails from "../../../components/mui/GlobalAnchor/ActionDetails";
import DeleteExpense from "../../../components/datagrid/myworkspace/ogd/expense/DeleteExpense";

const OgaExpense = () => {
  const dispatch = useDispatch();
  const [expenseType, setExpenseType] = React.useState(2);
  const [value, setValue] = React.useState(dayjs());
  const [paramsData, setParamsData] = useState("");
  const { expenseTypes, expenseData, loading } = useSelector(
    (state) => state.cro
  );
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData;

  useEffect(() => {
    dispatch(getExpenseTypes());
    dispatch(getStudentsforAddIncome());
  }, []);

  const handleChange = (event) => {
    setExpenseType(event.target.value);
  };
  useEffect(() => {
    if (expenseType && value && pageNum && gridSize) {
      const formatedDate = dayjs(value).format("YYYY-MM");
      const year = dayjs(formatedDate).year();
      const month = dayjs(formatedDate).month() + 1;
      const data = {
        page: pageNum,
        limit: gridSize,
        search: search,
        expenseTypeId: expenseType,
        year: year,
        month: month,
      };
      setParamsData(data);
      dispatch(getExpenses(data));
    }
  }, [expenseType, value, pageNum, gridSize, search]);

  return (
    <>
      <Wrapper
        title="Expense"
        actions={<OgaAddExpense data={paramsData} />}
        Content={
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: 2,
              }}
            >
              <Box>
                <Box sx={{ minWidth: 220 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Expense Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={expenseType}
                      label="Expense Type"
                      onChange={handleChange}
                    >
                      {expenseTypes.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {/* <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year", "month"]}
                    label="Year and Month"
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                  />
                </LocalizationProvider>
              </Box> */}
            </Box>
            <Paper elevation={3}>
              <Box>
                <GlobalDatagrid
                  tableHead={
                    expenseType === 1 ? TP_TABLE_HEAD : EXPENSE_TABLE_HEAD
                  }
                  rows={expenseData?.data?.expenses}
                  meta={expenseData?.meta?.totalRecords || 0}
                  rowUniqueId="sl_no"
                  rowClick={true}
                  loading={loading}
                  actionComponent={
                    <>
                      <ActionDetails val={rowData?.studentName} />
                      <GlobalAnchorList
                        label={"Update"}
                        component={
                          <UpdateExpenseView
                            paramsData={paramsData}
                            params={rowData}
                            paginationData={paginationData}
                          />
                        }
                      />
                      <GlobalAnchorList
                        label={"Delete Expense"}
                        component={
                          <DeleteExpense
                            params={rowData}
                            paginationData={paginationData}
                          />
                        }
                      />
                    </>
                  }
                />
              </Box>
            </Paper>
          </>
        }
      />
    </>
  );
};

export default OgaExpense;
