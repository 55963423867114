import React from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { CRO_ACCOUNTS_TABLE_HEAD as columns } from "../../../components/datagrid/CRM/Analytics/CroAccounts/CroAccountsTableHead";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const data = [
    {
        serialNumber: 1,
        croName: "AJVAD SABAH K C",
        totalStudents: 13360,
        totalActiveStudents: 1240,
        totalTemporaryInactiveStudents: 310,
        totalInactiveStudents: 22,
        assignedStudents: 46,
        classStopped: 2,
        tcUploaded: 2,
        expectedSessions: 3,
        completedSessions: 1,
        completionPercentage: 0,
        croCollectedFee: 656768,
        trainersPayment: 779,
        feePerSession: 290,
        tpPerSession: 220,
        totalSalesAmount: 23237,
        totalAdvancePaid: 2300,
        balanceFee: 7798,
        percentageFeePaid: "50%",
    },
];

export default function CroAccounts() {
    const [gridSize, setGridSize] = React.useState(5);
    const [value, setValue] = React.useState(null);
    return (
        <div>
            <Wrapper
                title="Cro Accounts"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From"
                                    value={value}
                                    openTo="year"
                                    views={["year", "month", "day"]}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To"
                                    value={value}
                                    openTo="year"
                                    views={["year", "month", "day"]}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        // formik: formik,
                                        label: "Course",
                                        name: "courseId",
                                        sx: { width: "100%" },
                                        // data: data.coursesPreData,

                                        keys: {
                                            id: "courseId",
                                            value: "courseName",
                                        },
                                    }}
                                    variant="filled"
                                />
                            </Box>
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        // formik: formik,
                                        label: "CRO Type",
                                        name: "courseId",
                                        sx: { width: "100%" },
                                        // data: data.coursesPreData,

                                        keys: {
                                            id: "courseId",
                                            value: "courseName",
                                        },
                                    }}
                                    variant="filled"
                                />
                            </Box>
                        </Box>
                        <Paper elevation={3}>
                            <Box width="100%">
                                <Box
                                    sx={{
                                        backgroundColor: "grey.200",
                                        borderRadius: "8px 8px 0 0",
                                        display: "flex",
                                        height: 44,
                                    }}
                                ></Box>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    // loading={loading}
                                    rows={data}
                                    columns={columns}
                                    autoHeight
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
