import React, { useState } from "react";
import GlobalDialog from "../../../components/mui/Dialogue/GlobalDialog";
import { AddButton } from "../../../components/mui/Buttons/Buttons";
import AddIncome from "./AddIncome";

export const AddIncomePopup = ({ data }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <GlobalDialog
                title="Add Income"
                open={open}
                setOpen={setOpen}
                fullWidth
                closeButton={true}
                maxWidth={"md"}
                popupButton={
                    <AddButton action={() => setOpen(true)}>
                        Add Income
                    </AddButton>
                }
                content={
                    <>
                        <AddIncome data={data} open={open} setOpen={setOpen} />
                    </>
                }
            />
        </div>
    );
};
