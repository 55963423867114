import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import { AddButton, CancelButton, SaveButton } from "../mui/Buttons/Buttons";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
    addProjectPackage,
    getProjectPackages,
    getProjectsPreData,
} from "../../redux/features/mastersSlice";
import { useSnackbar } from "notistack";

export const AddProjectPackages = () => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const data = useSelector((state) => state.masters);

    const Schema = Yup.object().shape({
        projectId: Yup.string().required("projectId is required"),
        packageName: Yup.string().required("packageName is required"),
        packageDescription: Yup.string().required(
            "packageDescription is required"
        ),
        packageSessions: Yup.string().required("packageSessions is required"),
    });

    const formik = useFormik({
        initialValues: {
            projectId: "",
            packageName: "",
            packageDescription: "",
            packageSessions: "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            dispatch(addProjectPackage(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getProjectPackages());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                title="Add Project Package"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <AddButton
                        action={() => {
                            dispatch(getProjectsPreData());
                            setOpen(true);
                        }}
                    >
                        Add Project Package
                    </AddButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <Autocomplete
                                            disablePortal
                                            name="projectId"
                                            id="combo-box-demo"
                                            options={data.projectsPreData}
                                            getOptionLabel={(option) =>
                                                option.name
                                            }
                                            onChange={(e, option) => {
                                                formik.setFieldValue(
                                                    "projectId",
                                                    option.id
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    {...params}
                                                    label="Project"
                                                    error={
                                                        formik.touched
                                                            .projectId &&
                                                        Boolean(
                                                            formik.errors
                                                                .projectId
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.projectId
                                                    }
                                                />
                                            )}
                                        />
                                        <TextField
                                            label="Package Name"
                                            fullWidth
                                            name="packageName"
                                            value={formik.values.packageName}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.packageName &&
                                                Boolean(
                                                    formik.errors.packageName
                                                )
                                            }
                                            helperText={
                                                formik.errors.packageName
                                            }
                                        />
                                        <TextField
                                            label="packageSessions"
                                            fullWidth
                                            name="packageSessions"
                                            value={
                                                formik.values.packageSessions
                                            }
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched
                                                    .packageSessions &&
                                                Boolean(
                                                    formik.errors
                                                        .packageSessions
                                                )
                                            }
                                            helperText={
                                                formik.errors.packageSessions
                                            }
                                        />
                                        <TextField
                                            label="Package Description"
                                            fullWidth
                                            name="packageDescription"
                                            multiline
                                            rows={3}
                                            value={
                                                formik.values.packageDescription
                                            }
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched
                                                    .packageDescription &&
                                                Boolean(
                                                    formik.errors
                                                        .packageDescription
                                                )
                                            }
                                            helperText={
                                                formik.errors.packageDescription
                                            }
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack spacing={2} direction={"row"}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                {data.loading
                                                    ? "Saving..."
                                                    : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
