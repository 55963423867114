import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
  AddButton,
  CancelButton,
  SubmitButton,
} from "../../../../mui/Buttons/Buttons";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  assignOga,
  getOgaList,
  getOgmLeads,
} from "../../../../../redux/features/ogdSlice";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { savePaginationData } from "../../../../../redux/features/globalDatagridSlice";

const AssignOga = ({ params }) => {
  console.log("params", params);

  const { enqueueSnackbar } = useSnackbar();
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { ogaList, loading } = useSelector((state) => state.ogd);
  const [data, setData] = useState({
    leadId: params?.leadId,
    ogaUserId: "",
    srmRemarks: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(data);

    dispatch(assignOga(data)).then((res) => {
      if (res.payload.status === "failed") {
        enqueueSnackbar(res.payload.message, { variant: "error" });
      } else if (res.payload.status === "success") {
        setOpen(false);
        dispatch(getOgmLeads(params));
        dispatch(savePaginationData({ ...paginationData, openAnchor: false }));
        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
      }
    });
  };
  return (
    <GlobalDialog
      title="Assign OGA"
      open={open}
      setOpen={setOpen}
      closeButton={true}
      fullWidth
      maxWidth={"sm"}
      popupButton={
        <AddButton
          size="small"
          action={() => {
            setOpen(true);
            dispatch(getOgaList());
          }}
        >
          Assign
        </AddButton>
      }
      content={
        <>
          <form>
            <Box padding={2}>
              <Box pb={2}>
                <Autocomplete
                  disablePortal
                  options={ogaList}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, option, value) => {
                    setData({
                      ...data,
                      ogaUserId: option.userID,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="OGA" />
                  )}
                />
              </Box>
              <Box>
                <TextField
                  label="Remarks"
                  fullWidth
                  value={data.Remarks}
                  multiline
                  rows={3}
                  onChange={(e) =>
                    setData({
                      ...data,
                      srmRemarks: e.target.value,
                    })
                  }
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
                <Stack direction={"row"} spacing={1}>
                  <CancelButton size="small" action={() => setOpen(false)}>
                    Cancel
                  </CancelButton>
                  <SubmitButton
                    disabled={data.ogaUserId ? false : true}
                    size="small"
                    type="submit"
                    action={handleSubmit}
                  >
                    {loading ? "Please wait..." : "Assign"}
                  </SubmitButton>
                </Stack>
              </Box>
            </Box>
          </form>
        </>
      }
    />
  );
};

export default AssignOga;
