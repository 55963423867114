import React from "react";

const ColorStatusViewer = ({ value, color }) => {
    return (
        <h5
            style={{
                fontSize: "16px",
                color: color,
            }}
        >
            {value}
        </h5>
    );
};

export default React.memo(ColorStatusViewer);
