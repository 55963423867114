import React, { useEffect } from "react";
import Page from "../../../components/Page";
import { Box, Paper, Stack, TextField, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { Form, FormikProvider, useFormik } from "formik";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import BasicTextField from "../../../components/mui/TextField";
import BasicButton from "../../../components/mui/Button";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import {
    UpdateCroLeads,
    getCroLeadStatuses,
    getCroLeads,
} from "../../../redux/features/croSlice";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader/Loader";

const UpdateLeadForm = ({ setOpen, rowDetails }) => {
    const dispatch = useDispatch();
    const { leadStatuses, loading } = useSelector((state) => state.cro);
    const { enqueueSnackbar } = useSnackbar();

    const schema = Yup.object().shape({
        croLeadStatusId: Yup.number().required("this field is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            croLeadStatusId:
                rowDetails.croLeadStatusId >= 0
                    ? rowDetails.croLeadStatusId
                    : "",
            croRemarks: "",
        },
        validationSchema: schema,
        onSubmit: (values) => {
            dispatch(
                UpdateCroLeads({
                    id: rowDetails.croAssignmentId,
                    data: { ...values },
                })
            ).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(getCroLeads());
                    setOpen(false);
                } else {
                    enqueueSnackbar(res.payload.message, {
                        variant: "error",
                    });
                }
            });
        },
    });

    useEffect(() => {
        dispatch(getCroLeadStatuses());
    }, []);
    return loading ? (
        <Loader />
    ) : (
        <Page
            sx={{
                minWidth: "700px",
            }}
        >
            <Typography variant="h4" sx={{ mb: 1 }}>
                Update Lead
            </Typography>
            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
            <Paper elevation={3} sx={{ borderRadius: 1, mt: 4 }}>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Stack
                            sx={{
                                padding: 5,
                                gap: 2,
                            }}
                        >
                            <div
                                style={{
                                    border: "1px solid #000",
                                    width: "100%",
                                }}
                            />

                            <BasicTextField
                                fullWidth
                                label="Name"
                                value={rowDetails.studentName}
                                disabled
                            />

                            <BasicTextField
                                fullWidth
                                label="Course"
                                value={rowDetails.courseName}
                                disabled
                            />

                            <GlobalSelectField
                                options={{
                                    name: "croLeadStatusId",
                                    formik: formik,
                                    label: "Status",
                                    data: leadStatuses,
                                    keys: {
                                        id: "id",
                                        value: "name",
                                    },
                                    sx: {
                                        width: "100%",
                                    },
                                }}
                            />
                            <BasicTextField
                                name="croRemarks"
                                fullWidth
                                label="Cro Remarks"
                                multiline
                                value={formik.values.croRemarks}
                                rows={3}
                                action={(e) =>
                                    formik.setFieldValue(
                                        "croRemarks",
                                        e.target.value
                                    )
                                }
                            />
                            <BasicButton
                                variant="contained"
                                sx={{
                                    display: "flex",
                                    margin: "0 auto",
                                    gap: 1,
                                }}
                                type="submit"
                            >
                                <Icon
                                    icon="ic:sharp-save"
                                    width="30"
                                    height="30"
                                />
                                Update
                            </BasicButton>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Paper>
        </Page>
    );
};

export default UpdateLeadForm;
