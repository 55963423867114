import {
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Container,
  Stack,
  Skeleton,
  TextField,
  Button,
  Paper,
} from "@mui/material";
import useResponsive from "../../../Hooks/useResponsive";
import { Link } from "react-router-dom";
import { NotificationEditModal } from "../../../components/modal/NotificationEditModal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { Link } from "react-router-dom";
import { viewNotification } from "../../../redux/features/notificationSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { NOTIFICATIONS_TABLE_HEAD as columns } from "../../../components/datagrid/NotificationsTableColumns";
// import studentsSlice from "../../../redux/features/studentsSlice";
import { clearRes, clearAll } from "../../../redux/features/notificationSlice";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import BasicButton from "../../../components/mui/Button";
import CustomSelectField from "../../../components/mui/CustomSelectField";
import { VALUES } from "../../../utils/constants/InputValues";
import { Icon } from "@iconify/react";
import Loader from "../../../components/Loader/Loader";

function View() {
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const notifications = useSelector((state) => state.notification);
  const [singleNotification, setSingleNotification] = useState({});
  const dispatch = useDispatch();

  // role based state

  useEffect(() => {
    dispatch(viewNotification("CUSTOM_SINGLE"));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSingleNotification(notifications.single);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications.single]);

  return (
    <div>
      {smUp && (
        <Page title="View Notification">
          <Container component="main">
            {/*..................................HEADING.............................*/}
            <Typography variant="h4" sx={{ mb: 1 }}>
              View Notification
            </Typography>
            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
            {notifications.loading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  marginTop: "3rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    my: "2rem",
                  }}
                >
                  <BasicButton
                    component={Link}
                    to={`/notifications/viewlog`}
                    sx={{
                      backgroundColor: "#dccfe0",
                      color: "#7e4d8b",
                    }}
                  >
                    <Icon
                      icon="radix-icons:activity-log"
                      width="20"
                      height="20"
                    />
                    View Log
                  </BasicButton>
                </Box>

                <Paper elevation={3} sx={{ borderRadius: 1, width: "100%" }}>
                  <Box
                    sx={{
                      backgroundColor: "grey.200",
                      borderRadius: "8px 8px 0 0",
                      display: "flex",
                      height: 75,
                    }}
                  >
                    <Box padding={1.5}>
                      <CustomSelectField
                        options={{
                          name: "view",
                          label: "Select Type",
                          data: VALUES,
                          variant: "filled",
                        }}
                      />
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <DataGrid
                      loading={notifications.loading}
                      rows={notifications.data}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      autoHeight
                      getRowHeight={() => "auto"}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: {
                            debounceMs: 500,
                          },
                        },
                      }}
                      // experimentalFeatures={{ newEditingApi: true }}
                      getRowId={(row) => row.notification_id}
                      // checkboxSelection
                      // disableSelectionOnClick
                    />
                  </Box>
                </Paper>
              </Box>
            )}
          </Container>
        </Page>
      )}
      {!smUp && (
        <Page title="View Notification">
          <>
            {/*..................................HEADING.............................*/}
            <Typography variant="h4" sx={{ mb: 1 }}>
              View Notification
            </Typography>
            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
            {notifications.loading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  marginTop: "3rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    my: "2rem",
                  }}
                >
                  <BasicButton
                    component={Link}
                    to={`/notifications/viewlog`}
                    sx={{
                      backgroundColor: "#dccfe0",
                      color: "#7e4d8b",
                    }}
                  >
                    <Icon
                      icon="radix-icons:activity-log"
                      width="20"
                      height="20"
                    />
                    View Log
                  </BasicButton>
                </Box>

                <Paper elevation={3} sx={{ borderRadius: 1, width: "100%" }}>
                  <Box
                    sx={{
                      backgroundColor: "grey.200",
                      borderRadius: "8px 8px 0 0",
                      display: "flex",
                      height: 75,
                    }}
                  >
                    <Box padding={1.5}>
                      <CustomSelectField
                        options={{
                          name: "view",
                          label: "Select Type",
                          data: VALUES,
                          variant: "filled",
                        }}
                      />
                    </Box>
                  </Box>

                  <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid
                      loading={notifications.loading}
                      rows={notifications.data}
                      columns={columns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      autoHeight
                      getRowHeight={() => "auto"}
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      componentsProps={{
                        toolbar: {
                          showQuickFilter: true,
                          quickFilterProps: {
                            debounceMs: 500,
                          },
                        },
                      }}
                      // experimentalFeatures={{ newEditingApi: true }}
                      getRowId={(row) => row.notification_id}
                      // checkboxSelection
                      // disableSelectionOnClick
                    />
                  </Box>
                </Paper>
              </Box>
            )}
          </>
        </Page>
      )}
    </div>
  );
}

export default View;
