import React, { useState } from "react";
import PopUps from "../cro/PopUps";
import VideoEvaluationForm from "./VideoEvaluationForm";
import { Button } from "@mui/material";

const TrainerEvaluationPopUp = () => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <Button variant="contained" onClick={() => setOpen(true)}>
                View
            </Button>
            <PopUps
                open={open}
                setOpen={setOpen}
                component={VideoEvaluationForm}
            />
        </div>
    );
};

export default TrainerEvaluationPopUp;
