export const DAYS = [
    {
        day: "Sunday",
        id: 0,
    },
    {
        day: "Monday",
        id: 1,
    },
    {
        day: "Tuesday",
        id: 2,
    },
    {
        day: "Wednesday",
        id: 3,
    },
    {
        day: "Thursday",
        id: 4,
    },
    {
        day: "Friday",
        id: 5,
    },
    {
        day: "Saturday",
        id: 6,
    },
];

export const MONTHS = [
    {
        id: 0,
        name: "all",
    },
    {
        id: 1,
        name: "january",
    },
    {
        id: 2,
        name: "february",
    },
    {
        id: 3,
        name: "march",
    },
    {
        id: 4,
        name: "april",
    },
    {
        id: 5,
        name: "may",
    },
    {
        id: 6,
        name: "june",
    },
    {
        id: 7,
        name: "july",
    },
    {
        id: 8,
        name: "august",
    },
    {
        id: 9,
        name: "september",
    },
    {
        id: 10,
        name: "October",
    },
    {
        id: 11,
        name: "November",
    },
    {
        id: 12,
        name: "December",
    },
];
