const errorHandler = (schedules, createSchedule) => {
    let flag = false;
    for (let items of schedules) {
        if (items.weekDayId.toString() && items.fromTime) {
            flag = false;
            continue;
        } else {
            flag = true;
            break;
        }
    }
    createSchedule(flag);
};

export default errorHandler;
