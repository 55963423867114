import { Icon } from "@iconify/react";
import {
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";

export const DetailsView = ({ data }) => {
    const Items = ({ label, value, table }) => {
        return (
            <>
                <List>
                    <ListItem disablePadding>
                        <ListItemIcon>
                            <Icon
                                icon="uil:arrow-up"
                                rotate={1}
                                color="#2f72b3"
                                width="25"
                                height="25"
                            />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography
                                // variant="body2"
                                sx={{ fontWeight: "bolder", fontSize: 13 }}
                            >
                                {label} :-{value}
                            </Typography>
                        </ListItemText>

                        <Box>{table}</Box>
                    </ListItem>
                    <Divider
                        variant="inset"
                        component="li"
                        sx={{ overflow: "inherit" }}
                    />
                </List>
            </>
        );
    };

    const Mytable = ({ tableRow }) => {
        return (
            <Grid item xs={12} lg={6} md={6}>
                <Paper
                    sx={{
                        margin: 1,
                        borderRadius: 4,
                        padding: 2,
                    }}
                >
                    <Table>{tableRow}</Table>
                </Paper>
            </Grid>
        );
    };

    const Rows = ({ label, value }) => {
        return (
            <TableRow>
                <TableCell variant="head">
                    <Stack direction={"row"} spacing={1}>
                        <Box>
                            <Icon
                                icon="uil:arrow-up"
                                rotate={1}
                                color="#2f72b3"
                                width="25"
                                height="25"
                            />
                        </Box>

                        <Box>{label}</Box>
                    </Stack>
                </TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>
        );
    };

    return (
        <div>
            <Box marginTop={2}>
                {/* ------------------------------------------------------------------------------ */}

                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <TableBody>
                                    <Rows label="CRO" value={data?.cro} />
                                    <Rows label="OGA" value={data?.oga} />
                                    <Rows
                                        label="Student Region"
                                        value={data?.studentRegion}
                                    />
                                    <Rows
                                        label="Course Name"
                                        value={data?.courseName}
                                    />
                                    <Rows
                                        label="Course Status"
                                        value={data?.courseStatus}
                                    />
                                    <Rows label="Dob" value={data?.dob} />
                                    <Rows
                                        label="Contact Number"
                                        value={data?.contactNumber}
                                    />
                                    <Rows
                                        label="Admn Fee Status"
                                        value={data?.admnFeeStatus}
                                    />
                                </TableBody>
                            </>
                        }
                    />
                    <Mytable
                        tableRow={
                            <>
                                <TableBody>
                                    <Rows
                                        label="Class"
                                        value={data?.className}
                                    />

                                    <Rows
                                        label="course Enrolled Date"
                                        value={data?.courseEnrolledDate}
                                    />

                                    <Rows
                                        label="Expected Sessions"
                                        value={data?.expectedSessions}
                                    />
                                    <Rows
                                        label="Certificate Status"
                                        value={data?.courseCertificateStatus}
                                    />
                                    <Rows
                                        label="Sessions Completed"
                                        value={data?.sessionsCompleted}
                                    />

                                    <Rows
                                        label="last Session Date"
                                        value={data?.lastSessionDate}
                                    />
                                    <Rows
                                        label="total Sales Amount"
                                        value={data?.totalSalesAmount}
                                    />
                                    <Rows
                                        label="total FeeCollected"
                                        value={data?.totalFeeCollected}
                                    />
                                </TableBody>
                            </>
                        }
                    />
                </Grid>

                {/* ------------------------------------------------------------------------------------------ */}
            </Box>
        </div>
    );
};
