import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";

export default function CustomAccordion({
    children,
    title,
    variant,
    bgclr,
    icon,
}) {
    return (
        <div>
            <Box sx={{ borderRadius: 5 }}>
                <Accordion sx={{ backgroundColor: "grey.200" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box display="flex" alignItems="center">
                            {icon && icon}
                            <Typography variant={`${variant}`}>
                                {title}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>{children}</AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
}
