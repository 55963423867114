import { Icon } from "@iconify/react/dist/iconify.js";
import { Button } from "@mui/material";
import React from "react";

const PdfView = (params) => {  
    const onPdfClick=()=>{
        window.open(params?.assessmentReport);
    }
  return (
    <div>
      <a onClick={onPdfClick}>
        <Button>
        <Icon icon="pepicons-pop:cv" style={{ width: '30px', height: '30px' }} />
        </Button>
      </a>
    </div>
  );
};

export default PdfView;
