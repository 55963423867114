import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { Box, Card, Avatar, Divider, Typography, Stack } from "@mui/material";
// utils
import cssStyles from "../css/cardCssStyle";
import { fShortenNumber } from "../../utils/formatNumber";
// components

// import SocialsButton from "../../../../components/SocialsButton";

import Image from "../Image/Image";
import SvgIconStyle from "../css/SvgStyle";
import cover from "../../img/cover.jpeg";

// ----------------------------------------------------------------------

const OverlayStyle = styled("div")(({ theme }) => ({
    ...cssStyles().bgBlur({ blur: 2, color: theme.palette.primary.darker }),
    top: 0,
    zIndex: 8,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
}));

// ----------------------------------------------------------------------

UserCard.propTypes = {
    user: PropTypes.object.isRequired,
};

export default function UserCard({ user }) {
    const {
        userID,
        photo,
        name,
        employment_status_name,
        reporting_head,
        dept_name,
    } = user;

    return (
        <Card sx={{ textAlign: "center" }}>
            <Box sx={{ position: "relative" }}>
                <SvgIconStyle
                    src={`https://minimal-assets-api.vercel.app/assets/icons/shape-avatar.svg`}
                    sx={{
                        width: 144,
                        height: 62,
                        zIndex: 10,
                        left: 0,
                        right: 0,
                        bottom: -26,
                        mx: "auto",
                        position: "absolute",
                        color: "background.paper",
                    }}
                />
                <Avatar
                    alt={name}
                    src={`https://workspace.teaminterval.net/assets/employee/photo/passport_size/${photo}.jpg`}
                    sx={{
                        width: 64,
                        height: 64,
                        zIndex: 11,
                        left: 0,
                        right: 0,
                        bottom: -32,
                        mx: "auto",
                        position: "absolute",
                    }}
                />
                <OverlayStyle />
                <Image src={cover} alt={cover} ratio="16/9" />
            </Box>

            <Typography variant="subtitle1" sx={{ mt: 6 }}>
                {name}
            </Typography>

            <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {dept_name}
            </Typography>

            <Stack alignItems="center">
                {/* <SocialsButton initialColor sx={{ my: 2.5 }} /> */}
            </Stack>

            <Divider sx={{ borderStyle: "dashed" }} />

            <Box
                sx={{
                    py: 3,
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                }}
            >
                <div>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ mb: 0.75, color: "text.disabled" }}
                    >
                        userID
                    </Typography>
                    <Typography variant="subtitle1">{userID}</Typography>
                </div>

                <div>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ mb: 0.75, color: "text.disabled" }}
                    >
                        Reporting Head
                    </Typography>
                    <Typography variant="subtitle1">
                        {reporting_head}
                    </Typography>
                </div>

                <div>
                    <Typography
                        variant="caption"
                        component="div"
                        sx={{ mb: 0.75, color: "text.disabled" }}
                    >
                        Employment Status
                    </Typography>
                    <Typography variant="subtitle1">
                        {employment_status_name}
                    </Typography>
                </div>
            </Box>
        </Card>
    );
}
