import { Typography } from "@mui/material";
import ToolTip from "../../../ToolTip/ToolTip";
export const OGA_REMARKS_TABLE_HEAD = [
  // { field: "id", headerName: "ID" },
  {
    field: "studentDetails",
    headerName: "Student Details (In Brief)",
    width: 200,
    // renderCell: (params) => {
    //   return (
    //     <ToolTip
    //       expand={params.row.studentDetails}
    //       content={params.row.studentDetails}
    //     />
    //   );
    // },
    renderCell: (params) => (
      <Typography style={{ whiteSpace: "normal",}}>
        {params?.row?.studentDetails}
      </Typography>
    ),
  },
  {
    field: "subjectSessions",
    headerName: "Subjects & Session Count",
    width: 500,
    // renderCell: (params) => {
    //   return (
    //     <ToolTip
    //       expand={params.row.subjectSessions}
    //       content={params.row.subjectSessions}
    //     />
    //   );
    // },
    renderCell: (params) => (
      <Typography style={{ whiteSpace: "normal", wordBreak: "break-word"}}>
        {params?.row?.subjectSessions}
      </Typography>
    ),
  },
  {
    field: "feePerSession",
    headerName: "Fee Per Session",
    width: 118,
    renderHeader: () => (
      <Typography
        variant="subtitle2"
        style={{
          whiteSpace: "normal", // Allows wrapping
          wordWrap: "break-word", // Breaks long words if necessary
          textAlign: "center", // Optional: centers the text
        }}
      >
      Fee Per Session
      </Typography>
    ),
  },
  {
    field: "sessionsPerWeek",
    headerName: "Sessions Per Week",
    width: 80,
    renderHeader: () => (
      <Typography
        variant="subtitle2"
        style={{
          whiteSpace: "normal", // Allows wrapping
          wordWrap: "break-word", // Breaks long words if necessary
          textAlign: "center", // Optional: centers the text
        }}
      >
        Sessions Per Week
      </Typography>
    ),
  },
  {
    field: "timePrefered",
    headerName: "Time Prefered",
    width: 80,
    // renderCell: (params) => {
    //   return (
    //     <ToolTip
    //       expand={params.row.timePrefered}
    //       content={params.row.timePrefered}
    //     />
    //   );
    // },
    renderHeader: () => (
      <Typography
        variant="subtitle2"
        style={{
          whiteSpace: "normal", // Allows wrapping
          wordWrap: "break-word", // Breaks long words if necessary
          textAlign: "center", // Optional: centers the text
        }}
      >
        Time Prefered
      </Typography>
    ),
  },
  {
    field: "trainerPrefered",
    headerName: "Trainer Prefered",
    width: 140,
  },
  {
    field: "totalFee",
    headerName: "Total Fee",
    width: 100,
  },
  {
    field: "feeStatus",
    headerName: "Fee Status",
    width: 80,
    renderHeader: () => (
      <Typography
        variant="subtitle2"
        style={{
          whiteSpace: "normal", // Allows wrapping
          wordWrap: "break-word", // Breaks long words if necessary
          textAlign: "center", // Optional: centers the text
        }}
      >
      Fee Status
      </Typography>
    ),
  },
];
