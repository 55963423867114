import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { REFERRAL_ADMSN_RGSTR_TBL_HEAD } from "../../../components/datagrid/myworkspace/cro/ReferralAdmissionRegTableHead";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getReferralAdmissionRegister } from "../../../redux/features/croSlice";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";

const ReferralAdmissionRegister = () => {
    const [gridSize, setGridSize] = useState(5);
    const dispatch = useDispatch();
    const { data: userDetails } = useSelector((state) => state.login);
    const { refAdmissionRegister, loading } = useSelector((state) => state.cro);
    const userId = userDetails.profile.userId;

    useEffect(() => {
        dispatch(getReferralAdmissionRegister(userId));
    }, []);
    return (
        <div>
            <Box
                sx={{
                    mb: 2,
                }}
            >
                <Typography variant="h4">
                    Referral Admission Register
                </Typography>
                <Breadcrumb />
            </Box>
            <Box>
                <DataGrid
                    loading={loading}
                    rows={refAdmissionRegister}
                    columns={REFERRAL_ADMSN_RGSTR_TBL_HEAD}
                    autoHeight
                    getRowId={(row) => row.serialNumber}
                    onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
                    pageSize={gridSize}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    components={{ Toolbar: CustomGridToolbar }}
                />
            </Box>
        </div>
    );
};

export default ReferralAdmissionRegister;
