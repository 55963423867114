import React, { useCallback } from "react";
import Page from "../Page";
import { Typography, Box, Grow, Zoom, Stack } from "@mui/material";
import Breadcrumb from "../breadcrumb/BreadCrumb";
import Fade from "@mui/material/Fade";
import { useEffect } from "react";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";
import Slide from "@mui/material/Slide";
import { motion } from "framer-motion";
import { useState } from "react";
import Loader3D from "../Loader/Loader3D";

export default function Wrapper({
    title,
    Content,
    actions,
    transitionData,
    skelton,
}) {
    const [checked, setChecked] = React.useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loaderCheck, setLoaderCheck] = useState(true);
    const keyframesExample = {
        hidden: { opacity: 0, x: -100 },
        halfway: { opacity: 0.5, x: 50 },
        visible: { opacity: 1, x: 0 },
    };
    const transiations = useSelector(
        (state) => state.settings.transitionStatus
    );
    useEffect(() => {
        const timer = setTimeout(() => {
            setChecked((prev) => !prev);
        }, 1100);

        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        if (skelton === false && loaderCheck === false) {
            setIsLoading(false);
        } else {
            const timer = setTimeout(() => {
                setIsLoading(false);
                setLoaderCheck(false);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, []);

    const SkeletonLoader = useCallback(() => {
        return (
            <>
                <Loader3D />
            </>
        );
    }, []);
    return (
        <div>
            <Page>
                <Box maxWidth={2000}>
                    <Box
                        sx={{
                            display: "inline-flex",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            variants={keyframesExample}
                        >
                            <Box>
                                <Typography variant="h4">{title}</Typography>
                                <Breadcrumb sx={{ paddingLeft: 0.5 }} />
                            </Box>
                        </motion.div>

                        <Box>{actions}</Box>
                    </Box>
                    <Box>
                        {transitionData ? (
                            <>
                                <>
                                    {isLoading ? (
                                        <SkeletonLoader />
                                    ) : (
                                        <Slide
                                            direction={
                                                transitionData.transition
                                            }
                                            in={checked}
                                            mountOnEnter
                                            unmountOnExit
                                            timeout={{ enter: 500 }}
                                        >
                                            <Box marginTop={3}>{Content}</Box>
                                        </Slide>
                                    )}
                                </>
                            </>
                        ) : (
                            <>
                                {" "}
                                {transiations.transition === "Collapse" ? (
                                    <>
                                        {isLoading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Collapse
                                                collapsedSize={40}
                                                timeout={{ enter: 500 }}
                                                in={checked}
                                            >
                                                <Box marginTop={3}>
                                                    {Content}
                                                </Box>
                                            </Collapse>
                                        )}
                                    </>
                                ) : null}
                                {transiations.transition === "Fade" ? (
                                    <>
                                        {isLoading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Fade
                                                timeout={{ enter: 500 }}
                                                in={checked}
                                            >
                                                <Box marginTop={3}>
                                                    {Content}
                                                </Box>
                                            </Fade>
                                        )}
                                    </>
                                ) : null}
                                {transiations.transition === "Grow" ? (
                                    <>
                                        {isLoading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Grow
                                                timeout={{ enter: 500 }}
                                                in={checked}
                                            >
                                                <Box marginTop={3}>
                                                    {Content}
                                                </Box>
                                            </Grow>
                                        )}
                                    </>
                                ) : null}
                                {transiations.transition === "Zoom" ? (
                                    <>
                                        {isLoading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Zoom
                                                timeout={{ enter: 500 }}
                                                in={checked}
                                            >
                                                <Box marginTop={3}>
                                                    {Content}
                                                </Box>
                                            </Zoom>
                                        )}
                                    </>
                                ) : null}
                                {transiations.transition === "Slide" &&
                                transiations.otherProps === "up" ? (
                                    <>
                                        {isLoading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Slide
                                                direction="up"
                                                in={checked}
                                                mountOnEnter
                                                unmountOnExit
                                                timeout={{ enter: 500 }}
                                            >
                                                <Box marginTop={3}>
                                                    {Content}
                                                </Box>
                                            </Slide>
                                        )}
                                    </>
                                ) : null}
                                {transiations.transition === "Slide" &&
                                transiations.otherProps === "down" ? (
                                    <>
                                        {isLoading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Slide
                                                direction="down"
                                                in={checked}
                                                mountOnEnter
                                                unmountOnExit
                                                timeout={{ enter: 500 }}
                                            >
                                                <Box marginTop={3}>
                                                    {Content}
                                                </Box>
                                            </Slide>
                                        )}
                                    </>
                                ) : null}
                                {transiations.transition === "Slide" &&
                                transiations.otherProps === "right" ? (
                                    <>
                                        {isLoading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Slide
                                                direction="right"
                                                in={checked}
                                                mountOnEnter
                                                unmountOnExit
                                                timeout={{ enter: 500 }}
                                            >
                                                <Box marginTop={3}>
                                                    {Content}
                                                </Box>
                                            </Slide>
                                        )}
                                    </>
                                ) : null}
                                {transiations.transition === "Slide" &&
                                transiations.otherProps === "left" ? (
                                    <>
                                        {isLoading ? (
                                            <SkeletonLoader />
                                        ) : (
                                            <Slide
                                                direction="left"
                                                in={checked}
                                                mountOnEnter
                                                unmountOnExit
                                                timeout={{ enter: 500 }}
                                            >
                                                <Box marginTop={3}>
                                                    {Content}
                                                </Box>
                                            </Slide>
                                        )}
                                    </>
                                ) : null}
                            </>
                        )}
                    </Box>
                </Box>
            </Page>
        </div>
    );
}
