import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { INCOME_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/Income/IncomeTableHead";
import { Details } from "./Details";
import { Actions } from "./Actions";

const data = [
    {
        id: 1,
        feeType: "Tuition Fee",
        studentRegion: "GCC",
        admissionNo: 6049,
        name: "AZIM JANSHID",
        course: "Classroom - Short Term",
        transactionDate: "2023-04-28	",
        amount: 25000,
        creditedAc: "Indian A/c	",
        paymentMode: "UPI",
        screenshot: "",
        remarks: "Total fee 46396 Paid 10000 Balance 36396",
        timeStamp: "2023-04-27 14:28:46	",
        addedBy: "PESSY THOMAS",
        cro: "PESSY THOMAS",
        reflectedAtBankOn: "2023-05-02",
        verification: "verified",
        duplicate: "",
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function CrmExpense() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [value, setValue] = React.useState(null);
    return (
        <div>
            <Wrapper
                title="Expense"
                Content={
                    <>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Basic example"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.admissionNo}
                                label="AdNo"
                                name={selectedRows[0]?.name}
                                details={<Details data={selectedRows[0]} />}
                                actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
