import React from "react";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import useResponsive from "../../../Hooks/useResponsive";
export default function AddressDetails() {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const user = useSelector((state) => state.profile);
    return (
        <div>
            <Paper elevation={3}>
                <Box sx={{ padding: 2 }}>
                    <Typography variant="h5">Address Details</Typography>
                </Box>

                <Box>
                    <Box sx={{ padding: 1 }}>
                        <Paper
                            sx={{
                                borderRadius: 3,
                            }}
                        >
                            <Stack direction={"row"} marginLeft={5}>
                                <Icon
                                    icon="akar-icons:location"
                                    width="35"
                                    height="35"
                                    color="#2f72b3"
                                />
                                <Typography variant="h6">Address</Typography>
                            </Stack>
                            <Divider variant="middle" />
                            <Box sx={{ marginLeft: 1 }} paddingLeft={5}>
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        Name of Parent :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.parentName}
                                    </Typography>
                                </Stack>
                                {/* <Divider variant="middle" /> */}

                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        House Name :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.houseName}
                                    </Typography>
                                </Stack>

                                {/* <Divider variant="middle" /> */}

                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        Street / Area :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.PSTstreetOrArea}
                                    </Typography>
                                </Stack>

                                {/* <Divider variant="middle" /> */}

                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        City / Town :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.city}
                                    </Typography>
                                </Stack>

                                {/* <Divider variant="middle" /> */}

                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        Post Office :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.PSTpostOffice}
                                    </Typography>
                                </Stack>

                                {/* <Divider variant="middle" /> */}

                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        District :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.district}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        State :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.state}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        Country :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.country}
                                    </Typography>
                                </Stack>

                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    sx={{ margin: 1 }}
                                >
                                    <Icon
                                        icon="fa6-solid:arrows-to-dot"
                                        width="20"
                                        height="20"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="subtitle2">
                                        Pincode :
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        paddingTop={0}
                                    >
                                        {user.pinCode}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Paper>
        </div>
    );
}
