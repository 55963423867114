import Router from "./routes";
import "./App.css";
import ReactFullscreen from "react-easyfullscreen";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { screenStatus } from "./redux/features/settingsSlice";
import {
    transitionStatus,
    sideBarSettings,
} from "./redux/features/settingsSlice";

function App() {
    const dispatch = useDispatch();
    const status = useSelector((state) => state.settings);

    useEffect(() => {
        dispatch(
            sideBarSettings({
                bgColor: "default",
                color: "",
            })
        );
        dispatch(screenStatus("NotActive"));
        dispatch(
            transitionStatus({
                transition: "Fade",
                otherProps: "",
            })
        );
    }, []);

    return (
        // <ReactFullscreen>
        //     {({ ref, onRequest, onExit }) => (
        <>
            {/* {status.screenStatus === "Active" ? onRequest() : onExit()} */}

            <Router />
        </>
        //     )}
        // </ReactFullscreen>
    );
}

export default App;
