import React from 'react'
import GlobalDeleteDialog from '../../../../mui/Dialogue/GlobalDeleteDialog'
import { monthlyFoodStatus, removeMealStatus } from '../../../../../redux/features/foodSlice'
import { useSelector } from 'react-redux'

const DeleteFoodStatus = ({id,date}) => {
    const {loading}=useSelector((store)=>store.food)
  return (
    <GlobalDeleteDialog
    title={"Delete"}
    deleteNote={"Are you sure? you want to remove this meal status ..?"}
    api={removeMealStatus(id)}
    upDateCall={monthlyFoodStatus(date)}
    loading={loading}
    />
  )
}

export default DeleteFoodStatus