import React from "react";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";
import { Box, Grid } from "@mui/material";
import { ListOfDetails } from "../../../../components/mui/DataGrid/ListOfDetails";

export const Details = ({ data }) => {
    return (
        <div>
            <Box>
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Mobile" value={data?.mobile} />
                                <Rows label="Class" value={data?.class} />
                                <Rows label="Course" value={data?.course} />

                                <Rows
                                    label="Course Enrollment Date"
                                    value={data?.courseEnrollmentDate}
                                />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows
                                    label="Total Sessions"
                                    value={data?.totalSessions}
                                />
                                <Rows
                                    label="Last Session Date"
                                    value={data?.lastSessionDate}
                                />
                                <Rows
                                    label="Inactive Date"
                                    value={data?.inactiveDate}
                                />

                                <Rows
                                    label="course Restart Date"
                                    value={data?.courseRestartDate}
                                />
                            </>
                        }
                    />
                </Grid>
            </Box>
            <ListOfDetails />
        </div>
    );
};
