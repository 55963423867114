import React from "react";
import GlobalDeleteDialog from "../../../../mui/Dialogue/GlobalDeleteDialog";
import { deleteStudentAcademicSessions } from "../../../../../redux/features/researchSlice";
import { useSelector } from "react-redux";

export const DeleteStudentAcademicSessions = () => {
    const { subjectEnrollmentDetailedId, loading } = useSelector(
        (state) => state.research
    );
    return (
        <div>
            <GlobalDeleteDialog
                title="Delete Student Academic Sessions"
                api={deleteStudentAcademicSessions(subjectEnrollmentDetailedId)}
                deleteNote="Are you sure you want to delete...?"
                loading={loading}
            />
        </div>
    );
};
