import React from "react";
import GlobalDeleteDialog from "../../../../mui/Dialogue/GlobalDeleteDialog";
import {
    deleteMatrix,
    getAllMatrices,
} from "../../../../../redux/features/assessmentToolSlice";
import { useSelector } from "react-redux";

const DeleteMatrix = ({ id, childFlag }) => {
    const { loading } = useSelector((state) => state.assessmentTool);
    return (
        <GlobalDeleteDialog
            title={"Delete Matrix"}
            deleteNote={"Are you sure? you want to delete this matrix..?"}
            api={deleteMatrix(id)}
            upDateCall={getAllMatrices()}
            loading={loading}
            disabled={childFlag === 1 ? true : false}
        />
    );
};

export default DeleteMatrix;
