import React from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { UpdateForm } from "./Form";
import { UpdateButton } from "../../../../components/mui/Buttons/Buttons";

export const InactiveStudentUpdate = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                open={open}
                fullWidth
                maxWidth={"md"}
                setOpen={setOpen}
                closeButton={true}
                title="Inactive Student Update"
                popupButton={
                    <UpdateButton
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Update
                    </UpdateButton>
                }
                content={<UpdateForm />}
            />
        </div>
    );
};
