//to format the schedules based on completed or incomplete
export const formatSchedules = (state) => {
    const ArrayToFormat = [...state.schedule.previousSession];
    if (state.schedule.filter === "all") {
        return ArrayToFormat.sort((a, b) => a.schedule_id > b.schedule_id);
    } else if (state.schedule.filter === "complete")
        return ArrayToFormat.filter((item) => item.status === "Completed");
    else if (state.schedule.filter === "incomplete")
        return ArrayToFormat.filter((item) => item.status === "Incomplete");
    else if (state.schedule.filter === "Trainer Cancelled")
        return ArrayToFormat.filter(
            (item) => item.status === "Trainer Cancelled"
        );
    else if (state.schedule.filter === "CRO Cancelled")
        return ArrayToFormat.filter((item) => item.status === "CRO Cancelled");
    else if (state.schedule.filter === "Expired")
        return ArrayToFormat.filter((item) => item.status === "Expired");
};
