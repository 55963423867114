import React from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { useState } from "react";
import { Button } from "@mui/material";
import { SlotBookingForm } from "./SlotBookingForm";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";

export const PopupForm = ({ params, popupData }) => {
    const [open, setOpen] = useState(false);

    const fromTime = params.fromTime;
    const toTime = params.toTime;
    const fromTimeString = `1970-01-01 ${fromTime}`;
    const fromTimeObj = dayjs(fromTimeString, {
        format: "YYYY-MM-DD HH:mm:ss",
    });
    const toTimeString = `1970-01-01 ${toTime}`;
    const toTimeObj = dayjs(toTimeString, { format: "YYYY-MM-DD HH:mm:ss" });

    const dateString = params.date;
    const dateFormat = "DD/MM/YYYY";

    const dateObject = dayjs(dateString, dateFormat).toDate();
    const timeRange = {
        date: dateObject,
        fromTime: fromTimeObj,
        toTime: toTimeObj,
    };

    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                title="Book Cabin"
                popupButton={
                    <Button
                        onClick={() => setOpen(true)}
                        sx={{ backgroundColor: "#eff4f9" }}
                    >
                        <Icon
                            icon="fluent:form-28-regular"
                            width="22"
                            height="22"
                        />
                        Book Now
                    </Button>
                }
                closeButton={true}
                content={
                    <>
                        <SlotBookingForm
                            popupData={popupData}
                            timeRange={timeRange}
                            setOpen={setOpen}
                            cabinId={params.asset_id}
                            assetName={params.asset_name}
                        />
                    </>
                }
            />
        </div>
    );
};
