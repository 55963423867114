import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";
import { DataGrid } from "@mui/x-data-grid";
import {
    Autocomplete,
    Box,
    Button,
    Paper,
    Stack,
    TextField,
} from "@mui/material";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { TRAINER_ATTENDANCE_TABLE_HEAD } from "../../../components/datagrid/myworkspace/cro/TrainerAttendaceTableHead";
import { getTrainerAttendance } from "../../../redux/features/croSlice";
import { getCourseEnrollments } from "../../../redux/features/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import { fetchStudents } from "../../../redux/features/studentsSlice";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
export default function TrainerAttendance() {
    const { trainerAttandace, loading } = useSelector((state) => state.cro);
    const dispatch = useDispatch();
    const [gridSize, setGridSize] = useState(5);
    const { data } = useSelector((state) => state.students);
    const { courseEnrollments } = useSelector((state) => state.sessions);

    useEffect(() => {
        console.log("courseEnrollments", courseEnrollments);
    }, [courseEnrollments]);

    useEffect(() => {
        dispatch(fetchStudents());
        // dispatch(getTrainerAttendance());
    }, []);

    const formik = useFormik({
        initialValues: {
            courseEnrollmentId: "",
        },
        onSubmit: (values) => {
            dispatch(getTrainerAttendance(values.courseEnrollmentId));
        },
    });
    console.log(formik.values);
    return (
        <div>
            <Wrapper
                title="Trainer Attendance"
                Content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box sx={{ mb: 2 }}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Box width={"50%"}>
                                            <Autocomplete
                                                disablePortal
                                                name="addNo"
                                                id="combo-box-demo"
                                                options={data}
                                                getOptionLabel={(option) =>
                                                    option.student
                                                }
                                                onChange={(
                                                    e,
                                                    option,
                                                    value
                                                ) => {
                                                    dispatch(
                                                        getCourseEnrollments(
                                                            option.admissionNumber
                                                        )
                                                    );
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        disabled
                                                        sx={{ width: "100%" }}
                                                        fullWidth
                                                        {...params}
                                                        label="Student"
                                                        error={
                                                            formik.touched
                                                                .projectId &&
                                                            Boolean(
                                                                formik.errors
                                                                    .projectId
                                                            )
                                                        }
                                                        helperText={
                                                            formik.errors
                                                                .projectId
                                                        }
                                                    />
                                                )}
                                                renderOption={(
                                                    props,
                                                    option
                                                ) => {
                                                    return (
                                                        <li
                                                            {...props}
                                                            value={
                                                                option.admissionNumber
                                                            }
                                                            key={
                                                                option.admissionNumber
                                                            }
                                                            data-subenid={
                                                                option.subjectEnrollmentId
                                                            }
                                                        >
                                                            {option.student} : (
                                                            {
                                                                option.admissionNumber
                                                            }
                                                            )
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </Box>
                                        <Box width={"50%"}>
                                            <GlobalSelectField
                                                options={{
                                                    name: "courseEnrollmentId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                    },
                                                    label: "Course Enrollment",
                                                    data: courseEnrollments,
                                                    keys: {
                                                        id: "courseEnrollmentId",
                                                        value: "courseName",
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box pt={1}>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                            >
                                                Filter
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Form>
                        </FormikProvider>
                        <Paper elevation={3}>
                            <Box sx={{ width: "100%", padding: 0 }}>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    loading={loading}
                                    autoHeight
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    rows={trainerAttandace}
                                    columns={TRAINER_ATTENDANCE_TABLE_HEAD}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
