import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

export const fetchStudents = createAsyncThunk(
    "students/fetchStudents",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "students/cro-students",
        });
        return res;
    }
);

const studentsSlice = createSlice({
    name: "students",
    initialState: {
        loading: false,
        data: [],
        error: "",
    },
    extraReducers: (builder) => {
        builder.addCase(fetchStudents.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchStudents.fulfilled, (state, action) => {
            state.loading = false;
            state.data = formatDataForDataTable(action.payload.data.students);
        });
        builder.addCase(fetchStudents.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});

// Action creators are generated for each case reducer function
// export const { fetchStudents } = studentsSlice.actions;

export default studentsSlice.reducer;
