import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box } from "@mui/material";

const TImeLineComponet = ({ data, sx }) => {
    const colors = [
        "#8884d8",
        "#83a6ed",
        "#8dd1e1",
        "#82ca9d",
        "#a4de6c",
        "#80dfff",
        "#007399",
    ];
    return (
        <Box sx={{ ...sx }}>
            <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}
            >
                {data?.map((item, index) => (
                    <TimelineItem key={index}>
                        <TimelineSeparator>
                            <TimelineDot
                                sx={{
                                    background: `${
                                        colors[index % colors.length]
                                    }`,
                                }}
                            />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            {item.name}
                            <br />
                            {`${item.value}%`}
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </Box>
    );
};

export default TImeLineComponet;
