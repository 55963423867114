import React from "react";
import AssignCro from "./AssignCro";

export const Assign = React.memo((params) => {
  return (
    <div>
      <AssignCro params={params} />
    </div>
  );
});
