import * as React from "react";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

export default function BasicBadge({ children, content, color }) {
    return (
        <Badge badgeContent={content} color={color}>
            {children}
        </Badge>
    );
}
