import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

export const getAllDepartment = createAsyncThunk(
    "departments/getAllDepartment",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `departments`,
        });
        return res;
    }
);
export const deleteDepartment = createAsyncThunk(
    "departments/deleteDepartment",
    async (id) => {
        const res = await apiRequest({
            method: "delete",
            url: `departments/${id}`,
        });
        return res;
    }
);

export const createDepartment = createAsyncThunk(
    "departments/createDepartment",
    async (data) => {
        const res = await apiRequest({
            method: "post",
            url: `departments`,
            data: data,
        });
        return res;
    }
);

export const getDepartmentById = createAsyncThunk(
    "departments/getDepartmentById",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `departments/${id}`,
        });
        return res;
    }
);

export const editDepartment = createAsyncThunk(
    "departments/editDepartment",
    async (data) => {
        const res = await apiRequest({
            method: "put",
            url: `departments/${data.id}`,
            data: data,
        });
        return res;
    }
);
export const getDepartmentEmployees = createAsyncThunk(
    "departments/getDepartmentEmployees",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: `departments/getRHEmployees`,
        });
        return res;
    }
);

const departmentSlice = createSlice({
    name: "departments",
    initialState: {
        emp: [],
        singleDepartment: {},
        data: [],
        loading: false,
        error: "",
    },

    extraReducers: (builder) => {
        builder.addCase(getAllDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllDepartment.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data.departments;
        });
        builder.addCase(getAllDepartment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // --------------------delete-department-------------------------
        builder.addCase(deleteDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteDepartment.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(deleteDepartment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------create-department---------------------------------
        builder.addCase(createDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createDepartment.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(createDepartment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -------------------getDepartmentById-----------------------------------------
        builder.addCase(getDepartmentById.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDepartmentById.fulfilled, (state, action) => {
            state.loading = false;
            state.singleDepartment = action.payload.data.departments[0];
        });
        builder.addCase(getDepartmentById.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // -----------------------editDepartment--------------------------------------
        builder.addCase(editDepartment.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editDepartment.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(editDepartment.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ----------------------------getDepartmentEmployees-------------------------------
        builder.addCase(getDepartmentEmployees.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getDepartmentEmployees.fulfilled, (state, action) => {
            state.loading = false;
            state.emp = action.payload.data;
        });
        builder.addCase(getDepartmentEmployees.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});

export default departmentSlice.reducer;
