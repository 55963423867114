import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import Page from "../../../../components/Page";
import { Button, Stack, TextField, Typography, Card, Box } from "@mui/material";
import useResponsive from "../../../../Hooks/useResponsive";
import Logo from "../../../../components/color-utils/Logo";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../../../redux/features/authSlice";
import { useSnackbar } from "notistack";
const ResetPswrd = () => {
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const loading = useSelector((state) => state.login.isLoading);
    const RootStyle = {
        display: "flex",
    };
    const ContentStyle = {
        maxWidth: "480px",
        margin: "auto",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "12px 0px",
    };
    const SectionStyle = styled(Card)(({ theme }) => ({
        width: "100%",
        maxWidth: 464,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: theme.spacing(2, 0, 2, 2),
    }));
    const HeaderStyle = styled("header")(({ theme }) => ({
        top: 0,
        zIndex: 9,
        lineHeight: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        position: "absolute",
        padding: theme.spacing(3),
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
            alignItems: "flex-start",
            padding: theme.spacing(7, 5, 0, 7),
        },
    }));

    const navigate = useNavigate();

    const passSchema = yup.object().shape({
        password: yup
            .string()
            .min(8, "Password must be 8 characters long")
            .matches(/[0-9]/, "Password requires a number")
            .matches(/[a-z]/, "Password requires a lowercase letter")
            .matches(/[A-Z]/, "Password requires an uppercase letter")
            .matches(/[^\w]/, "Password requires a symbol"),
        confirm: yup
            .string()
            .oneOf(
                [yup.ref("password"), null],
                'Must match "password" field value'
            ),
    });
    const formik = useFormik({
        initialValues: {
            password: password,
        },
        validationSchema: passSchema,
        onSubmit: (e) => {
            dispatch(
                resetPassword({
                    password: e.confirm,
                })
            ).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message);
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message);
                    navigate("/auth/login");
                }
            });
        },
    });
    const { errors, touched, handleSubmit, getFieldProps } = formik;
    const mdUp = useResponsive("up", "md");
    return (
        <Page title="login">
            <Box sx={RootStyle}>
                <HeaderStyle>
                    <Logo />
                </HeaderStyle>
                {mdUp && (
                    <SectionStyle>
                        <img
                            src="/static/illustrations/illustration_login.png"
                            alt="login"
                        />
                    </SectionStyle>
                )}
                <Box sx={ContentStyle}>
                    <FormikProvider value={formik}>
                        <Form onSubmit={handleSubmit}>
                            <Stack
                                sx={{ width: "500px" }}
                                direction="column"
                                gap={2}
                            >
                                <TextField
                                    name="password"
                                    type="password"
                                    label="Enter password"
                                    size="medium"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    {...getFieldProps("password")}
                                    helperText={
                                        touched.password && errors.password
                                    }
                                    error={Boolean(
                                        touched.password && errors.password
                                    )}
                                />
                                <TextField
                                    name="confirm"
                                    type="password"
                                    label="Confirm password"
                                    size="medium"
                                    {...getFieldProps("confirm")}
                                    helperText={
                                        touched.confirm && errors.confirm
                                    }
                                    error={Boolean(
                                        touched.confirm && errors.confirm
                                    )}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    type="submit"
                                >
                                    {loading
                                        ? "Reseting...."
                                        : "Reset Password"}
                                </Button>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </Box>
            </Box>
        </Page>
    );
};

export default ResetPswrd;
