import React from "react";
import ApproveActionDialogue from "./ApproveActionDialogue";

export default function ApproveAction(params) {
    //
    return (
        <div>
            <ApproveActionDialogue params={params} />
        </div>
    );
}
