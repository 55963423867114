import { AddCourseEnrollmentDetailed } from "./AddCourseEnrollmentDetailed";
import { AddEnrollmentDetails } from "./RenderCellMain";

export const ENROLLMENT_DETAILS_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "admnNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "noOfCoursesEnrolled",
        headerName: "No OfCourses Enrolled",
        width: 120,
    },
    {
        field: "noOfSubjectsEnrolled",
        headerName: "No Of Subjects Enrolled",
        width: 120,
    },
    {
        field: "totalNoOfSessions",
        headerName: "Total No OfSessions",
        width: 120,
    },
    {
        field: "actions",
        headerName: "Actions",
        width: 120,
        renderCell: (params) => <AddEnrollmentDetails {...params.row} />,
    },
];
