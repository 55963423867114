import React from 'react'
import GlobalDeleteDialog from '../../mui/Dialogue/GlobalDeleteDialog'
import { savePaginationData } from '../../../redux/features/globalDatagridSlice'
import { deleteRequest, getAllRequests } from '../../../redux/features/helpAndSupportSlice'
import { useSelector } from 'react-redux'

const DeleteRaiseRequest = ({requestId,isDisabled}) => {
    const {loading}=useSelector((store)=>store.helpAndSupport)
    const { paginationData } = useSelector((state) => state.globalDataGrid)
  return (
    <GlobalDeleteDialog
    disabled={isDisabled}
    title={"Delete"}
    deleteNote={"Are you sure? you want to delete this raised request..?"}
    api={deleteRequest(requestId)}
    upDateCall={getAllRequests(paginationData)}
    updatePaginationCall={savePaginationData({ ...paginationData, openAnchor: false })}
    loading={loading}
    />
  )
}

export default DeleteRaiseRequest