import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import Page from "../../../components/Page";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BasicTextField from "../../../components/mui/TextField";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createAdditionalSchedule } from "../../../redux/features/sessionSlice";
import { useSnackbar } from "notistack";
import errorHandler from "../../../utils/errorHandler.js";
import moment from "moment";
import { DAYS } from "../../../utils/constants/daysConst";
import Loader from "../../../components/Loader/Loader";

const AditionalSchedules = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.sessions);
    const { state } = useLocation();
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const [errStyle, setErrStyle] = useState({
        border: "0",
    });

    const [time, setTime] = useState([{ time: new Date() }]);
    const [schedules, setSchedules] = useState([
        {
            weekDayId: "",
            fromTime: moment(new Date()).format("HH:mm:00"),
        },
    ]);

    const handleDayChange = (i, e) => {
        let temp = [...schedules];
        temp[i] = { ...temp[i], weekDayId: e.target.value };
        setSchedules(temp);
        errorHandler(temp, (err) => {
            if (err) {
                setErrStyle({
                    border: "1px solid red",
                    padding: "10px",
                    borderRadius: "5px",
                });
                return;
            }
            setErrStyle({
                border: "0",
            });
        });
    };

    const handleTimeChange = (i, e) => {
        const Ftime = moment(new Date(e)).format("HH:mm:00");
        let timeVal = [...time];
        timeVal[i].time = e;
        setTime(timeVal);

        let values = [...schedules];
        values[i] = { ...values[i], fromTime: Ftime };
        setSchedules(values);
    };

    const handleAdd = () => {
        setSchedules([
            ...schedules,
            {
                weekDayId: "",
                fromTime: moment(new Date()).format("HH:mm:00"),
            },
        ]);
        setTime([...time, { time: new Date() }]);
    };
    const handleRemove = (i) => {
        if (schedules.length > 1) {
            let nwSch = [...schedules];
            let nwTime = [...time];
            nwSch.splice(i, 1);
            nwTime.splice(i, 1);
            setSchedules(nwSch);
            setTime(nwTime);
            errorHandler(nwSch, (err) => {
                if (err) {
                    setErrStyle({
                        border: "1px solid red",
                        padding: "10px",
                        borderRadius: "5px",
                    });
                    return;
                }
                setErrStyle({
                    border: "0",
                });
            });
        } else {
            setSchedules([
                {
                    weekDayId: "",
                    fromTime: moment(new Date()).format("HH:mm:00"),
                },
            ]);
            setTime([{ time: new Date() }]);
        }
    };

    const handleSubmit = () => {
        // manual error handling method using a callback function
        errorHandler(schedules, (err) => {
            if (err) {
                setErrStyle({
                    border: "1px solid red",
                    padding: "10px",
                    borderRadius: "5px",
                });
                return;
            }
            setErrStyle({
                border: "0",
            });
            dispatch(
                createAdditionalSchedule({
                    subjectEnrollmentId: state.subjectEnrollmentId,
                    prefererdSchedules: schedules,
                })
            ).then((res) => {
                if (
                    res.payload.status === "failed" ||
                    res.payload.status === "error"
                ) {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    navigate(
                        `/schedules/student-preffered-shedules/${state.subjectEnrollmentId}`,
                        {
                            state: state,
                        }
                    );
                }
            });
        });
    };

    return loading ? (
        <Loader />
    ) : (
        <Page>
            <Container>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Add Additional Schedule
                </Typography>
                <Breadcrumb sx={{ marginBottom: 1 }} />

                <Box>
                    <Stack gap={2}>
                        <BasicTextField value={state.studentName} disabled />
                        <BasicTextField
                            value={`${state.course}(${state.courseDetailed})${state.subject}-class ${state.class} ${state.syllabus} ${state.medium}`}
                            disabled
                        />
                    </Stack>
                    <Stack alignItems="center">
                        {state.studentPreferredSchedules.map((item, i) => (
                            <Box
                                key={i}
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    gap: 2,
                                    margin: "10px 0",
                                }}
                            >
                                <BasicTextField
                                    value={item.weekDay}
                                    sx={{
                                        flex: 1,
                                    }}
                                    disabled
                                />
                                <BasicTextField
                                    value={item.fromTime}
                                    sx={{
                                        flex: 1,
                                    }}
                                    disabled
                                />
                            </Box>
                        ))}

                        <div style={{ ...errStyle }}>
                            {schedules.map((item, i) => {
                                return (
                                    <Box key={i}>
                                        <Stack
                                            width="100%"
                                            direction={"row"}
                                            gap={2}
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{
                                                margin: "10px 0",
                                            }}
                                        >
                                            <FormControl
                                                variant="outlined"
                                                sx={{
                                                    minWidth: 160,
                                                    flex: 1,
                                                }}
                                            >
                                                <InputLabel>
                                                    Week days
                                                </InputLabel>
                                                <Select
                                                    label="Week days"
                                                    onChange={(e) =>
                                                        handleDayChange(i, e)
                                                    }
                                                    value={
                                                        schedules[i].weekDayId
                                                    }
                                                >
                                                    {DAYS.map(
                                                        (option, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    option.id
                                                                }
                                                            >
                                                                {option.day}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <TimePicker
                                                    label="Time"
                                                    minutesStep={15}
                                                    value={time[i].time}
                                                    onChange={(e) =>
                                                        handleTimeChange(i, e)
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            sx={{
                                                                flex: 1,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>

                                            <Box>
                                                <Typography variant="subtitle2">
                                                    Action
                                                </Typography>
                                                <Stack
                                                    direction={"row"}
                                                    gap={2}
                                                >
                                                    <Button onClick={handleAdd}>
                                                        <Icon
                                                            icon="material-symbols:add"
                                                            height="28"
                                                        />{" "}
                                                    </Button>
                                                    <Button
                                                        onClick={() =>
                                                            handleRemove(i)
                                                        }
                                                    >
                                                        <Icon
                                                            icon="material-symbols:remove"
                                                            height="28"
                                                        />
                                                    </Button>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Box>
                                );
                            })}
                        </div>
                        {errStyle?.border !== "0" && (
                            <p
                                style={{
                                    color: "red",
                                    fontSize: "small",
                                }}
                            >
                                Please Enter all values
                            </p>
                        )}
                    </Stack>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            gap: 2,
                            justifyContent: "center",
                            marginTop: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                width: "100px",
                            }}
                            onClick={() =>
                                setSchedules([
                                    {
                                        weekDayId: "",
                                        fromTime: moment(new Date()).format(
                                            "HH:mm:00"
                                        ),
                                    },
                                ])
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "100px",
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Page>
    );
};

export default AditionalSchedules;
