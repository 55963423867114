import ToolTip from "../../../../ToolTip/ToolTip";

export const CRO_ENQUIRY_TABLE_HEAD = [
  {
    field: "enquiryDate",
    headerName: "enquiryDate",
    width: 120,
  },
  {
    field: "enquiryName",
    headerName: "enquiryName",
    width: 180,
    renderCell: (params) => {
      return (
        <ToolTip
          expand={params.row.enquiryName}
          content={params.row.enquiryName}
        />
      );
    },
  },
  {
    field: "enquiryRemarks",
    headerName: "enquiryRemarks",
    width: 500,
    renderCell: (params) => {
      return (
        <ToolTip
          expand={params.row.enquiryRemarks}
          content={params.row.enquiryRemarks}
        />
      );
    },
  },
];
