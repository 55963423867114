import { Box, Grid } from "@mui/material";
import React from "react";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";

export const Details = ({ data }) => {
    return (
        <div>
            {" "}
            <Box>
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Fee Type" value={data?.feeType} />
                                <Rows
                                    label="Student Region"
                                    value={data?.studentRegion}
                                />
                                <Rows label="Course" value={data?.course} />
                                <Rows
                                    label="Transaction Date"
                                    value={data?.transactionDate}
                                />
                                <Rows label="Amount" value={data?.amount} />
                                <Rows
                                    label="Credited Ac"
                                    value={data?.creditedAc}
                                />
                                <Rows
                                    label="Payment Mode"
                                    value={data?.paymentMode}
                                />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Remarks" value={data?.remarks} />
                                <Rows
                                    label="Time Stamp"
                                    value={data?.timeStamp}
                                />
                                <Rows label="Added By" value={data?.addedBy} />
                                <Rows label="CRO" value={data?.cro} />
                                <Rows
                                    label="Reflected At BankOn"
                                    value={data?.reflectedAtBankOn}
                                />
                                <Rows
                                    label="Verification"
                                    value={data?.verification}
                                />
                            </>
                        }
                    />
                </Grid>
            </Box>
        </div>
    );
};
