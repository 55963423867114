import { Stack, Typography } from "@mui/material";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon } from "@iconify/react";
import { useSnackbar } from "notistack";

export default function CopyEmail(params) {
  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = () => {
    enqueueSnackbar("CopiedSuccessfuly..", { variant: "success" });
  };

  return (
    <div>
      <Stack direction={"row"}>
        <Typography>{params.row.email}</Typography>
        <CopyToClipboard text={params.row.email} onCopy={handleCopy}>
          <Icon icon="bx:copy-alt" width="20" height="20" color="#2f72b3" />
        </CopyToClipboard>
      </Stack>
    </div>
  );
}
