import React, { useState, useEffect } from "react";

//MUI IMPORTS
import {
  Autocomplete,
  Box,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
//CUSTOM COMPONENTS
import {
  CancelButton,
  SubmitButton,
} from "../../../components/mui/Buttons/Buttons";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import Wrapper from "../../../components/Wrapper/Wrapper";

//CUSTOM HOOKS
import useResponsive from "../../../Hooks/useResponsive";

import commonArtboard from "../../../../src/img/Assessment.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  createAssessmentData,
  getClassPreData,
  getCourseData,
  getSroAssignmetStudents,
  getSyllabusPreData,
} from "../../../redux/features/assessmentToolSlice";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

const CourseRegistration = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    sroAssignmentStudents,
    assessmentFormData,
    syllabusPredata,
    classPreData,
    courseData,
  } = useSelector((state) => state.assessmentTool);
  const [isSameNumber, setIsSameNumber] = useState(false);
  const [direction, setDirection] = useState({});
  const mdUp = useResponsive("up", "md");
  const storedStudentData = localStorage.getItem("studentData");
  useEffect(() => {
    if (storedStudentData) {
      navigate("/assessment-tool/report-generation");
    }
  }, [storedStudentData]);

  useEffect(() => {
    if (mdUp) {
      setDirection("row");
    } else {
      setDirection("column");
    }
  }, [mdUp]);

  const profile = JSON.parse(localStorage.getItem("cred")).profile;

  useEffect(() => {
    dispatch(getSroAssignmetStudents(profile?.roleID));
    dispatch(getSyllabusPreData());
    dispatch(getClassPreData());
    dispatch(getCourseData());
  }, []);

  let gender = [
    { id: 1, value: "Male" },
    { id: 2, value: "Female" },
  ];
  let course = [
    { id: 1, value: "Speak Pro" },
    { id: 2, value: "Foundation" },
    { id: 3, value: "Classroom" },
  ];
  let userType = [
    { id: 1, value: "Student" },
    { id: 2, value: "Proffessional" },
    { id: 3, value: "Other" },
  ];

  //FORMIK INITIAL VALUES
  const initialValues = {
    nameId: "",
    name: "",
    age: "",
    place: "",
    gender: "",
    genderName: "",
    email: "",
    courseName: "",
    courseFullName: "",
    userType: "3",
    userTypeName: "", //BY DEFAULT SET TO USER TYPE = OTHER
    contactNumber: "",
    whatsappNumber: "",
    combination: "",
    courseDuration: "",
    dateOfAssessment: "",
    remarks: "",
    // IF USERT TYPE IS STUDNET BELOW FIELDS REQUIRED
    syllabus: "",
    syllabusName: "",
    standard: "",
    favouriteSubject: "",
    schoolName: "",
    parentName: "",
    // IF USERT TYPE IS PROFFESSIONAL BELOW FIELDS REQUIRED
    designation: "",
    companyName: "",
    countryCode:""
  };

  //FORMIK SCHEMA
  const schema = Yup.object({
    nameId: Yup.string().required("Name is required"),
    age: Yup.number()
      .required("Age is required")
      .min(1, "Age must be at least 1")
      .max(100, "Age must be less than 100")
      .integer("Age must be an integer"),
    gender: Yup.string().required("Gender is required"),
    place: Yup.string()
      .min(2, "Place must be at least 2 characters")
      .max(100, "Place must be less than 100 characters"),
    email: Yup.string().email("Invalid email format"),
    courseName: Yup.string().required("Course Name is required"),
    userType: Yup.string().required("User Type is required"),
    combination: Yup.string().required("combination Name is required"),
    courseDuration: Yup.string().required("courseDuration Name is required"),
    // contactNumber: Yup.string()
    //   .required("Contact Number is required")
    //   .matches(/^[0-9]+$/, "Contact Number must be only digits")
    //   .length(10, "Contact Number must be exactly 10 digits"),
    // whatsappNumber: Yup.string()
    //   .required("WhatsApp Number is required")
    //   .matches(/^[0-9]+$/, "WhatsApp Number must be only digits")
    //   .length(10, "WhatsApp Number must be exactly 10 digits"),
    standard: Yup.string().when("userType", {
      is: "1",
      then: Yup.string(),
      otherwise: Yup.string().notRequired(),
    }),
    favouriteSubject: Yup.string().when("userType", {
      is: "1",
      then: Yup.string(),
      otherwise: Yup.string().notRequired(),
    }),
    syllabus: Yup.string().when("userType", {
      is: "1",
      then: Yup.string(),
      otherwise: Yup.string().notRequired(),
    }),
    // schoolName: Yup.string().when("userType", {
    //     is: "1",
    //     then: Yup.string(),
    //     otherwise: Yup.string().notRequired(),
    // }),
    parentName: Yup.string().when("userType", {
      is: "1",
      then: Yup.string(),
      otherwise: Yup.string().notRequired(),
    }),
    designation: Yup.string().when("userType", {
      is: "2",
      then: Yup.string(),
      otherwise: Yup.string().notRequired(),
    }),
    companyName: Yup.string().when("userType", {
      is: "2",
      then: Yup.string(),
      otherwise: Yup.string().notRequired(),
    }),
    remarks: Yup.string().required("Remarks is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(createAssessmentData(values));
      localStorage.setItem("studentData", JSON.stringify(values));
      if (assessmentFormData) {
        enqueueSnackbar("Created Successfully", {
          variant: "success",
        });
        navigate("/assessment-tool/report-generation");
      } else {
        enqueueSnackbar("Data Not Found", {
          variant: "error",
        });
      }
    },
  });
  useEffect(() => {
    if (formik.values.userType) {
      const userTypeData = userType.filter(
        (item) => item.id == formik.values.userType
      );
      if (userTypeData) {
        formik.setFieldValue("userTypeName", userTypeData[0].value);
      }
    }
  }, [formik.values.userType]);
  useEffect(() => {
    if (formik.values.courseName) {
      const courseArry = courseData.filter(
        (item) => item.course_id == formik.values.courseName
      );
      if (courseArry) {
        formik.setFieldValue("courseFullName", courseArry[0].course_name);
      }
    }
  }, [formik.values.courseName]);
  useEffect(() => {
    if (formik.values.syllabus) {
      const syllabusData = syllabusPredata.filter(
        (item) => item.id == formik.values.syllabus
      );
      if (syllabusData) {
        formik.setFieldValue("syllabusName", syllabusData[0]?.name);
      }
    }
  }, [formik.values.syllabus]);
  useEffect(() => {
    if (formik.values.gender) {
      const genderData = gender.filter(
        (item) => item.id == formik.values.gender
      );
      if (genderData) {
        formik.setFieldValue("genderName", genderData[0].value);
      }
    }
  }, [formik.values.gender]);

  //CLEARING ALL FIELDS WHEN USER TYPE CHANGED
  useEffect(() => {
    formik.setValues({
        ...initialValues,
        userType: formik.values.userType, 
        userTypeName: formik.values.userTypeName, 
      });
     
  }, [formik.values.userType]);

  //IF BOTH WHATSAPP NUMBER AND CONTACT NUMBER ARE SAME
  useEffect(() => {
    if (isSameNumber) {
      formik.setFieldValue("whatsappNumber", formik.values.contactNumber);
    } else {
      formik.setFieldValue("whatsappNumber", "");
    }
  }, [isSameNumber, formik.values.contactNumber]);

  //CONTACT NUMBER IS SAME CHECK BOX ACTION
  const handleCheckboxChange = () => {
    setIsSameNumber((prev) => !prev);
  };

  const handleStudentChange = (e, option, value) => {
    formik.setFieldValue("nameId", option.sro_lead_id);
    formik.setFieldValue("name", option.lead_name);
    formik.setFieldValue(
      "dateOfAssessment",
      dayjs(option.lead_timestamp).format("DD-MM-YYYY")
    );
    formik.setFieldValue("contactNumber", option.lead_mob_no);
    formik.setFieldValue("place", option.lead_place);
    formik.setFieldValue("schoolName", option.lead_school_name);
    formik.setFieldValue("syllabus", option.lead_syllabus_id);
    formik.setFieldValue("standard", option.lead_class);
    formik.setFieldValue("email", option.email);
    formik.setFieldValue("countryCode", option.lead_country_code);

  };

  return (
    <div>
      <Wrapper
        title="Student Registration"
        Content={
          <>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit} noValidate>
                <Paper elevation={3}>
                  <Stack direction={"row"}>
                    {mdUp && (
                      <Box sx={{ flex: 1 }}>
                        <img
                          src={commonArtboard}
                          alt="artboard"
                          style={{
                            width: "100%",
                            height: "auto",
                          }}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        flex: 1,
                        px: 2,
                        py: 2,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Stack
                        direction={"row"}
                        sx={{
                          justifyContent: "flex-end",
                        }}
                      >
                        <Box>
                          <GlobalSelectField
                            options={{
                              formik: formik,
                              label: "User Type",
                              name: "userType",
                              data: userType,
                              keys: {
                                id: "id",
                                value: "value",
                              },
                              sx: {
                                width: "100%",
                              },
                            }}
                          />
                        </Box>
                      </Stack>

                      <Stack direction={direction} spacing={2}>
                        <Box sx={{ flex: 1 }}>
                          <Autocomplete
                            disablePortal
                            name="name"
                            id="combo-box-demo"                       
                            options={sroAssignmentStudents}
                            getOptionLabel={( option ) => option.lead_name || ""}
                            value={
                                sroAssignmentStudents.find(
                                    (student) =>
                                        student.sro_lead_id ===
                                        formik
                                            .values
                                            .nameId
                                ) || null
                            }    
                            onChange={handleStudentChange}
                            renderInput={(params) => (
                                
                              <TextField
                                disabled
                                fullWidth
                                autoComplete="off"
                                {...params}
                                label="Name"
                                error={
                                  formik.touched.nameId &&
                                  Boolean(formik.errors.nameId)
                                }
                                helperText={formik.errors.nameId}
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <TextField
                            fullWidth
                            id="age"
                            label="Age"
                            name="age"
                            type="number"
                            value={formik.values.age}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.age && Boolean(formik.errors.age)
                            }
                            helperText={formik.touched.age && formik.errors.age}
                          />
                        </Box>
                      </Stack>

                      <Stack direction={direction} spacing={2}>
                        <Box sx={{ flex: 1 }}>
                          <TextField
                            fullWidth
                            id="place"
                            label="Place"
                            disabled
                            name="place"
                            value={formik.values.place}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.place &&
                              Boolean(formik.errors.place)
                            }
                            helperText={
                              formik.touched.place && formik.errors.place
                            }
                          />
                        </Box>

                        <Box sx={{ flex: 1 }}>
                          <TextField
                            fullWidth
                            id="email"
                            label="Email"
                            disabled
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                          />
                        </Box>
                      </Stack>

                      {formik.values.userType == 1 && ( //IF THE USER TYPE IS STUDENT
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <Stack direction={direction} spacing={2}>
                            <Box sx={{ flex: 1 }}>
                              <GlobalSelectField
                                disabled
                                options={{
                                  formik: formik,
                                  label: "Standard",
                                  name: "standard",
                                  data: classPreData,
                                  keys: {
                                    id: "id",
                                    value: "className",
                                  },
                                  sx: {
                                    width: "100%",
                                  },
                                }}
                              />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                id="favouriteSubject"
                                label="Favourite Subject"
                                name="favouriteSubject"
                                value={formik.values.favouriteSubject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.favouriteSubject &&
                                  Boolean(formik.errors.favouriteSubject)
                                }
                                helperText={
                                  formik.touched.favouriteSubject &&
                                  formik.errors.favouriteSubject
                                }
                              />
                            </Box>
                          </Stack>

                          <Stack direction={direction} spacing={2}>
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                disabled
                                id="schoolName"
                                label="School Name"
                                name="schoolName"
                                value={formik.values.schoolName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.schoolName &&
                                  Boolean(formik.errors.schoolName)
                                }
                                helperText={
                                  formik.touched.schoolName &&
                                  formik.errors.schoolName
                                }
                              />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                required
                                disabled
                                id="parentName"
                                label="Parent Name"
                                name="parentName"
                                value={formik.values.parentName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.parentName &&
                                  Boolean(formik.errors.parentName)
                                }
                                helperText={
                                  formik.touched.parentName &&
                                  formik.errors.parentName
                                }
                              />
                            </Box>
                          </Stack>

                          <GlobalSelectField
                            disabled
                            options={{
                              formik: formik,
                              label: "Syllabus",

                              name: "syllabus",
                              data: syllabusPredata,
                              keys: {
                                id: "id",
                                value: "name",
                              },
                              sx: {
                                width: "100%",
                              },
                            }}
                          />
                        </Box>
                      )}

                      {formik.values.userType == 2 && ( //IF THE USER TYPE IS PROFFESSIONAL
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <Stack direction={direction} spacing={2}>
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                id="designation"
                                label="Designation"
                                name="designation"
                                value={formik.values.designation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.designation &&
                                  Boolean(formik.errors.designation)
                                }
                                helperText={
                                  formik.touched.designation &&
                                  formik.errors.designation
                                }
                              />
                            </Box>
                            <Box sx={{ flex: 1 }}>
                              <TextField
                                fullWidth
                                id="companyName"
                                label="Company Name"
                                name="companyName"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={
                                  formik.touched.companyName &&
                                  Boolean(formik.errors.companyName)
                                }
                                helperText={
                                  formik.touched.companyName &&
                                  formik.errors.companyName
                                }
                              />
                            </Box>
                          </Stack>
                        </Box>
                      )}

                      <Stack direction={direction} spacing={2}>
                        <Box sx={{ flex: 1 }}>
                          <GlobalSelectField
                            options={{
                              formik: formik,
                              label: "Gender",
                              name: "gender",
                              data: gender,
                              keys: {
                                id: "id",
                                value: "value",
                              },
                              sx: {
                                width: "100%",
                              },
                            }}
                          />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <GlobalSelectField
                            options={{
                              formik: formik,
                              label: "Course Name",
                              name: "courseName",
                              data: courseData,
                              keys: {
                                id: "course_id",
                                value: "course_name",
                              },
                              sx: {
                                width: "100%",
                              },
                            }}
                          />
                        </Box>
                      </Stack>
                      <Stack direction={"row"} spacing={2}>
                        <Box sx={{ flex: 1 }}>
                          <TextField
                            fullWidth
                            required
                            id="combination"
                            label="Combination"
                            name="combination"
                            value={formik.values.combination}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.combination &&
                              Boolean(formik.errors.combination)
                            }
                            helperText={
                              formik.touched.combination &&
                              formik.errors.combination
                            }
                          />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <TextField
                            fullWidth
                            required
                            id="courseDuration"
                            label="Course Duration"
                            name="courseDuration"
                            value={formik.values.courseDuration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.courseDuration &&
                              Boolean(formik.errors.courseDuration)
                            }
                            helperText={
                              formik.touched.courseDuration &&
                              formik.errors.courseDuration
                            }
                          />
                        </Box>
                      </Stack>

                      <Stack direction={direction} spacing={2}>
                        <Box>
                            <TextField
                            id="countryCode"
                            label={mdUp?"Code":"Country Code"}
                            type="number"
                            name="countryCode"
                            value={formik.values.countryCode}
                            sx={{width:mdUp?"60px":"50%"}}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <TextField
                            fullWidth
                            required
                            id="contactNumber"
                            label="Contact Number"
                            type="number"
                            name="contactNumber"
                            value={formik.values.contactNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.contactNumber &&
                              Boolean(formik.errors.contactNumber)
                            }
                            helperText={
                              formik.touched.contactNumber &&
                              formik.errors.contactNumber
                            }
                          />
                        </Box>

                        <Box sx={{ flex: 1 }}>
                          <TextField
                            fullWidth
                            required
                            id="whatsappNumber"
                            label="WhatsApp Number"
                            name="whatsappNumber"
                            type="number"
                            value={formik.values.whatsappNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.whatsappNumber &&
                              Boolean(formik.errors.whatsappNumber)
                            }
                            helperText={
                              formik.touched.whatsappNumber &&
                              formik.errors.whatsappNumber
                            }
                          />
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            spacing={1}
                          >
                            <Typography>Same as contact Number</Typography>
                            <Checkbox
                              checked={isSameNumber}
                              onChange={handleCheckboxChange}
                            />
                          </Stack>
                        </Box>
                      </Stack>

                      <Stack spacing={2}>
                        <Box>
                          <TextField
                            fullWidth
                            id="remarks"
                            label="Remarks"
                            name="remarks"
                            multiline
                            rows={4}
                            value={formik.values.remarks}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.remarks &&
                              Boolean(formik.errors.remarks)
                            }
                            helperText={
                              formik.touched.remarks && formik.errors.remarks
                            }
                          />
                        </Box>
                      </Stack>

                      <Box display="flex" justifyContent="flex-end">
                        <Stack
                          direction={"row"}
                          spacing={2}
                          sx={{ mt: 2, p: 2 }}
                        >
                          <CancelButton size="small">Cancel</CancelButton>
                          <SubmitButton size="small" type="submit">
                            Submit
                          </SubmitButton>
                        </Stack>
                      </Box>
                    </Box>
                  </Stack>
                </Paper>
              </Form>
            </FormikProvider>
          </>
        }
      />
    </div>
  );
};

export default CourseRegistration;
