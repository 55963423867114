import { AvailabilityStatus } from "./AvailabilityStatus";
import { ScheduleAvailabilityCheck } from "./ScheduleAvailabilityCheck";
import { Scheduledate } from "./Scheduledate";

export const SCHEDULE_PREVIEW_TABLE_HEAD = [
    {
        field: "sessionNo",
        headerName: "sessionNo",
        width: 90,
    },
    {
        field: "date",
        headerName: "Fields",
        width: 700,
        renderCell: (params) => <ScheduleAvailabilityCheck params={params} />,
    },

    {
        field: "isAvailable",
        headerName: "Time Slot Status",
        width: 200,
        renderCell: (params) => <AvailabilityStatus params={params} />,
    },
];
