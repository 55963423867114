import { Button } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PrefferedSchedulesAction = ({ params: { row } }) => {
    const { singleStudentDetails } = useSelector((state) => state.sessions);
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(
            `/schedules/student-preffered-shedules/${row.subjectEnrollmentId}`,
            {
                state: { ...row, ...singleStudentDetails },
            }
        );
    };
    return (
        <Button variant="contained" onClick={handleClick}>
            Details
        </Button>
    );
};

export default PrefferedSchedulesAction;
