import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import {
    Box,
    Stack,
    Paper,
    FormControl,
    Select,
    InputLabel,
    TextField,
} from "@mui/material";
import {
    getRoleById,
    editRole,
    getAllRoles,
} from "../../../redux/features/rolesSlice";
import { Icon } from "@iconify/react";
import { Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { useEffect } from "react";
import { getPreData } from "../../../redux/features/userSlice";
import { useSnackbar } from "notistack";
import { Skeleton } from "@mui/material";
import useResponsive from "../../../../src/Hooks/useResponsive";
import SimpleLoader from "../../Loader/SimpleLoader";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditRole({ openEdit, setOpenEdit, params }) {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const loading = useSelector((state) => state.roles.loading);
    const role = useSelector((state) => state.roles);
    const preData = useSelector((state) => state.users.preData);
    const preRole = useSelector((state) => state.roles.singleRole);

    useEffect(() => {
        dispatch(getPreData());
    }, []);

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            deptId: preRole.deptId || "",
            userType: preRole.usertype || "",
            id: preRole.userTypeId,
        },
        // validationSchema: CreateSchema,
        onSubmit: (values) => {
            dispatch(editRole(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === 200) {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    dispatch(getAllRoles());
                    setOpenEdit(false);
                }
            });
        },
    });

    return (
        <div>
            {smUp && (
                <div>
                    <Dialog
                        open={openEdit}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseEdit}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <DialogTitle>
                                    <Stack direction={"row"} marginTop={1}>
                                        <Box marginRight={1}>
                                            <Icon
                                                icon="material-symbols:edit-document-outline"
                                                width="30"
                                                height="30"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                marginTop: 0.5,
                                                color: "#7e4d8b",
                                            }}
                                        >
                                            {"Edit Role.."}
                                        </Box>
                                    </Stack>
                                </DialogTitle>
                                <Divider />
                                {loading ? (
                                    <SimpleLoader
                                        sx={{ width: 500, height: 300 }}
                                    />
                                ) : (
                                    <>
                                        <DialogContent>
                                            {/* <DialogContentText id="alert-dialog-slide-description"></DialogContentText> */}
                                            <Paper
                                                elevation={0}
                                                sx={{
                                                    width: 500,
                                                    backgroundColor: "grey.200",
                                                }}
                                            >
                                                <Box padding={4}>
                                                    <Box margin={2}>
                                                        {loading ? (
                                                            <FormControl
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    sx={{
                                                                        width: "100%",
                                                                        height: 56,
                                                                        borderRadius: 1,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        ) : (
                                                            <FormControl
                                                                fullWidth
                                                            >
                                                                <InputLabel id="demo-simple-select-label">
                                                                    Select
                                                                    Department
                                                                </InputLabel>
                                                                <Select
                                                                    name="deptId"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .deptId ||
                                                                        ""
                                                                    }
                                                                    label="Department"
                                                                    fullWidth
                                                                    variant="filled"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        formik.setFieldValue(
                                                                            "deptId",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    {preData.departments?.map(
                                                                        (
                                                                            item
                                                                        ) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={
                                                                                        item.departmentName
                                                                                    }
                                                                                    value={
                                                                                        item.departmentId
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.departmentName
                                                                                    }
                                                                                </MenuItem>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Select>
                                                                <FormHelperText>
                                                                    {
                                                                        formik
                                                                            .errors
                                                                            .deptId
                                                                    }
                                                                </FormHelperText>
                                                            </FormControl>
                                                        )}
                                                    </Box>
                                                    <Box margin={2}>
                                                        {loading ? (
                                                            <FormControl
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    sx={{
                                                                        width: "100%",
                                                                        height: 56,
                                                                        borderRadius: 1,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        ) : (
                                                            <TextField
                                                                name="userType"
                                                                label="Enter New Role"
                                                                variant="filled"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .userType
                                                                }
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                fullWidth
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        ?.userType &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .userType
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#eff4f9",
                                                    color: "#2f72b3",
                                                }}
                                                onClick={handleCloseEdit}
                                            >
                                                Cancel
                                            </Button>
                                            <LoadingButton
                                                sx={{
                                                    backgroundColor:
                                                        "#7e4d8b40",
                                                    color: "#7e4d8b",
                                                }}
                                                type="submit"
                                            >
                                                <Icon
                                                    icon="radix-icons:update"
                                                    width="25"
                                                    height="25"
                                                />
                                                {loading
                                                    ? "Updating..."
                                                    : "Update"}
                                            </LoadingButton>
                                        </DialogActions>
                                    </>
                                )}
                            </Form>
                        </FormikProvider>
                    </Dialog>
                </div>
            )}
            {!smUp && (
                <div>
                    <Dialog
                        open={openEdit}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseEdit}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <DialogTitle>
                                    <Stack direction={"row"} marginTop={1}>
                                        <Box marginRight={1}>
                                            <Icon
                                                icon="material-symbols:edit-document-outline"
                                                width="30"
                                                height="30"
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                marginTop: 0.5,
                                                color: "#7e4d8b",
                                            }}
                                        >
                                            {"Edit Role.."}
                                        </Box>
                                    </Stack>
                                </DialogTitle>
                                <Divider />
                                {loading ? (
                                    <SimpleLoader
                                        sx={{ width: 300, height: 200 }}
                                    />
                                ) : (
                                    <>
                                        <DialogContent>
                                            {/* <DialogContentText id="alert-dialog-slide-description"></DialogContentText> */}
                                            <Paper
                                                elevation={0}
                                                sx={{
                                                    width: 280,
                                                    backgroundColor: "grey.200",
                                                }}
                                            >
                                                <Box padding={1}>
                                                    <Box margin={1}>
                                                        {loading ? (
                                                            <FormControl
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    sx={{
                                                                        width: "100%",
                                                                        height: 56,
                                                                        borderRadius: 1,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        ) : (
                                                            <FormControl
                                                                fullWidth
                                                            >
                                                                <InputLabel id="demo-simple-select-label">
                                                                    Select
                                                                    Department
                                                                </InputLabel>
                                                                <Select
                                                                    name="deptId"
                                                                    value={
                                                                        formik
                                                                            .values
                                                                            .deptId ||
                                                                        ""
                                                                    }
                                                                    label="Department"
                                                                    fullWidth
                                                                    variant="filled"
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        formik.setFieldValue(
                                                                            "deptId",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    {preData.departments?.map(
                                                                        (
                                                                            item
                                                                        ) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={
                                                                                        item.departmentName
                                                                                    }
                                                                                    value={
                                                                                        item.departmentId
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        item.departmentName
                                                                                    }
                                                                                </MenuItem>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Select>
                                                                <FormHelperText>
                                                                    {
                                                                        formik
                                                                            .errors
                                                                            .deptId
                                                                    }
                                                                </FormHelperText>
                                                            </FormControl>
                                                        )}
                                                    </Box>
                                                    <Box margin={1}>
                                                        {loading ? (
                                                            <FormControl
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Skeleton
                                                                    variant="rectangular"
                                                                    sx={{
                                                                        width: "100%",
                                                                        height: 56,
                                                                        borderRadius: 1,
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        ) : (
                                                            <TextField
                                                                name="userType"
                                                                label="Enter New Role"
                                                                variant="filled"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .userType
                                                                }
                                                                onChange={
                                                                    formik.handleChange
                                                                }
                                                                fullWidth
                                                                error={
                                                                    formik
                                                                        .touched
                                                                        ?.userType &&
                                                                    Boolean(
                                                                        formik
                                                                            .errors
                                                                            .userType
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#eff4f9",
                                                    color: "#2f72b3",
                                                }}
                                                onClick={handleCloseEdit}
                                            >
                                                Cancel
                                            </Button>
                                            <LoadingButton
                                                sx={{
                                                    backgroundColor:
                                                        "#7e4d8b40",
                                                    color: "#7e4d8b",
                                                }}
                                                type="submit"
                                            >
                                                <Icon
                                                    icon="radix-icons:update"
                                                    width="25"
                                                    height="25"
                                                />
                                                {loading
                                                    ? "Updating..."
                                                    : "Update"}
                                            </LoadingButton>
                                        </DialogActions>
                                    </>
                                )}
                            </Form>
                        </FormikProvider>
                    </Dialog>
                </div>
            )}
        </div>
    );
}

export default EditRole;
