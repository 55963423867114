import { memo } from "react";
import DeleteDescription from "./description/DeleteDescription";
import CreateUpdateDescription from "./description/CreateUpdateDescription";
import DeleteGrade from "./grade/DeleteGrade";
import CreateUpdateGrade from "./grade/CreateUpdateGrade";
import DeleteObjective from "./objectives/DeleteObjective";
import CreateUpdateObjective from "./objectives/CreateUpdateObjective";

const DeleteDescriptionView = memo(({ id }) => {
  return <DeleteDescription id={id} />;
});

const CreateUpdateDescriptionView = memo(({ params }) => {
  return <CreateUpdateDescription params={params} />;
});
const DeleteGradeView = memo(({ childFlag, id }) => {
  return <DeleteGrade id={id} childFlag={childFlag} />;
});
const CreateUpdateGradeView = memo(({ params }) => {
  return <CreateUpdateGrade params={params} />;
});
const DeleteObjectiveView = memo(({ id, childFlag }) => {
  return <DeleteObjective id={id} childFlag={childFlag} />;
});
const CreateUpdateObjectiveView = memo(({ params }) => {
  return <CreateUpdateObjective params={params} />;
});

export {
  DeleteDescriptionView,
  CreateUpdateDescriptionView,
  DeleteGradeView,
  CreateUpdateGradeView,
  DeleteObjectiveView,
  CreateUpdateObjectiveView,
};
