import { Box } from "@mui/material";
import React from "react";
import Page from "../../../../components/Page";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { useState } from "react";
import {
  getReportingHeadAttendance,
  createTableHead,
} from "../../../../redux/features/attendanceSlice";
import { Paper, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MarkAttendanceStyle from "../../../../components/datagrid/MarkAttendanceStyle";
import {
  END_PORTION_TABLE_HEAD,
  START_PORTION_TABLE_HEAD,
} from "../../../../components/datagrid/Attendance/ReportingHeadAttendanceTableHead";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MarkAbsentees from "./MarkAbsentees";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import dayjs from "dayjs";
import EditEmployeeAttendance from "../../../../components/datagrid/Attendance/EditEmployeeAttendance";

export default function ReportingHeadAttendance() {
  const [value, setValue] = useState(dayjs());
  const [date, setDate] = useState();
  const attendances = useSelector((state) => state.attendance);
  const [tableHead, setTableHead] = useState([]);
  const dispatch = useDispatch();
  const [gridSize, setGridSize] = useState(5);

  useEffect(() => {
    const table = attendances.tableHead.map((item, i) => {
      if (item.day) {
        item = {
          ...item,
          renderCell: (params) => MarkAttendanceStyle(params),
        };
      }
      return item;
    });

    setTableHead([
      ...START_PORTION_TABLE_HEAD,
      ...table,
      ...END_PORTION_TABLE_HEAD,
      {
        field: "Edit",
        minWidth: 150,
        headerName: "Edit",
        renderCell: (params) => {
          const formattedMonth = dayjs(value).format("MM");
          const formattedYear = dayjs(value).format("YYYY");
          const yearMonth = {
            month: formattedMonth,
            year: formattedYear,
          };
          console.log(yearMonth);

          const param = { ...params.row, ...yearMonth };
          return EditEmployeeAttendance(param);
        },
      },
    ]);
  }, [attendances.tableHead]);

  useEffect(() => {
    dispatch(createTableHead(dayjs(value).format("YYYY/MM")));
    dispatch(getReportingHeadAttendance(dayjs(value).format("YYYY/MM")));
  }, [value]);

  const handleChange = (newValue) => {
    setValue(newValue);
    // setDate(yer);
    dispatch(createTableHead(dayjs(newValue).format("YYYY/MM")));
    dispatch(getReportingHeadAttendance(dayjs(newValue).format("YYYY/MM")));
  };

  return (
    <div>
      <Wrapper
        title="Reporting Head Attendance"
        Content={
          <Page>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <MarkAbsentees attendances={attendances} /> */}
            </Box>
            <Paper
              elevation={3}
              sx={{
                borderRadius: 1,
                width: "100%",
                marginTop: 3,
              }}
            >
              <Box
                sx={{
                  backgroundColor: "grey.200",
                  borderRadius: "8px 8px 0 0",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box padding={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month"]}
                      label="Year and Month"
                      inputFormat="YYYY/MM"
                      disableFuture
                      value={value}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          helperText={null}
                          variant="filled"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  disableVirtualization
                  loading={attendances.loading}
                  rows={attendances.data}
                  columns={tableHead}
                  sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                  pageSize={gridSize}
                  onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
                  autoHeight
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  getRowId={(row) => row.userId}
                  components={{ Toolbar: CustomGridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        debounceMs: 500,
                      },
                    },
                  }}
                />
              </Box>
            </Paper>
          </Page>
        }
      />
    </div>
  );
}
