import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    EditButton,
    SaveButton,
} from "../mui/Buttons/Buttons";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import GlobalSelectField from "../mui/GlobalSelectField";
import { useFormik, Form, FormikProvider } from "formik";
import { getUsers } from "../../redux/features/jobApplicationSlice";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { editProject, getProjects } from "../../redux/features/mastersSlice";
import { useSnackbar } from "notistack";

export const EditProjectPopup = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const data = useSelector((state) => state.jobApplication.users);
    const loading = useSelector((state) => state.masters.loading);
    const [value, setValue] = React.useState({
        userId: params?.projectHeadId || "",
        name: params?.projectHead || "",
    });

    const Schema = Yup.object().shape({
        projectName: Yup.string().required("projectName is required"),
        projectHeadId: Yup.string().required("projectHead is required"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            projectId: params.projectId,
            projectName: params.projectName || "",
            projectHeadId: params.projectHeadId || "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            dispatch(editProject(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getProjects());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    return (
        <div>
            <GlobalDialog
                title="Edit Project"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <EditButton
                        size="small"
                        action={() => {
                            dispatch(getUsers());
                            setOpen(true);
                        }}
                    >
                        Edit
                    </EditButton>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <TextField
                                            label="Project Name"
                                            fullWidth
                                            name="projectName"
                                            value={formik.values.projectName}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.projectName &&
                                                Boolean(
                                                    formik.errors.projectName
                                                )
                                            }
                                            helperText={
                                                formik.errors.projectName
                                            }
                                        />

                                        <Autocomplete
                                            disablePortal
                                            name="projectHeadId"
                                            id="combo-box-demo"
                                            options={data}
                                            value={value}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) =>
                                                option.userId ||
                                                "" === value.userId ||
                                                ""
                                            }
                                            getOptionLabel={(option) =>
                                                `${option.name} (${option.userId})` ||
                                                ""
                                            }
                                            onChange={(e, option, newValue) => {
                                                setValue({
                                                    userId: option.userId || "",
                                                    name: option.name || "",
                                                });
                                                formik.setFieldValue(
                                                    "projectHeadId",
                                                    option.userId
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    {...params}
                                                    label="project Head"
                                                    error={
                                                        formik.touched
                                                            .projectHeadId &&
                                                        Boolean(
                                                            formik.errors
                                                                .projectHeadId
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors
                                                            .projectHeadId
                                                    }
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack spacing={2} direction={"row"}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <EditButton type="submit">
                                                {loading
                                                    ? "Editing..."
                                                    : "Edit"}
                                            </EditButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
