import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import {
    CancelButton,
    SubmitButton,
} from "../../../../components/mui/Buttons/Buttons";

export const Form = () => {
    return (
        <div>
            <Box padding={3}>
                <TextField
                    label="Add Note"
                    variant="filled"
                    fullWidth
                    multiline
                    rows={3}
                />
                <Box
                    marginTop={2}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Stack direction={"row"} spacing={2}>
                        <CancelButton>Cancel</CancelButton>
                        <SubmitButton>Submit</SubmitButton>
                    </Stack>
                </Box>
            </Box>
        </div>
    );
};
