import TooltipRemarkViewer from "../../myworkspace/cro/TooltipRemarkViewer";
import { EnquiryLog } from "./EnquiryLog";

export const CRO_ENQUIRY_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        width: 50,
    },
    {
        field: "admnNo",
        headerName: "AdmissionNo",
        width: 150,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "studentName",
        headerName: "Name",
        width: 150,
        headerAlign: "center",

        renderCell: (params) => (
            <TooltipRemarkViewer value={params.row.studentName} />
        ),
    },
    {
        field: "contactNumber",
        headerName: "Mobile",
        width: 150,
    },
    {
        field: "className",
        headerName: "Class",
        width: 100,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "courseName",
        headerName: "Course",
        width: 150,
    },
    {
        field: "totalSessions",
        headerName: "TotalSessions",
        width: 80,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "sessionsCompleted",
        headerName: "Sessions Completed",
        width: 80,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "completionPercentage",
        headerName: "Completion %",
        width: 80,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "lastSessionDate",
        headerName: "Last Session Date",
        width: 150,
    },
    {
        field: "nextEnquiryStatusName",
        headerName: "Next Enquiry",
        width: 150,
    },
    {
        field: "enquiryLog",
        headerName: "enquiryLog",
        width: 150,
        renderCell: (params) => <EnquiryLog data={params.row} />,
    },
    {
        field: "actions",
        headerName: "actions",
        width: 150,
    },
];
