import {
    Box,
    Chip,
    Divider,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";
import Avatar from "@mui/material/Avatar";
import { Icon } from "@iconify/react";

export const TrainerDetails = () => {
    const Mytable = ({ tableRow }) => {
        return (
            <Grid item xs={12} lg={6} md={6}>
                <Table>{tableRow}</Table>
            </Grid>
        );
    };
    const Rows = ({ label, value, icon }) => {
        return (
            <TableBody>
                <TableRow>
                    <TableCell variant="head">
                        <Stack direction={"row"} spacing={1}>
                            <Box>
                                <Icon icon="line-md:star" />
                            </Box>

                            <Box>
                                <Typography fontWeight={"bolder"} fontSize={12}>
                                    {label}
                                </Typography>
                            </Box>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <Typography fontWeight={"bolder"} fontSize={12}>
                            {value}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    };
    return (
        <div>
            <Box>
                {/* <Stack direction={"row"} spacing={2}> */}

                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={4} md={6}>
                            <Paper
                                elevation={3}
                                sx={{
                                    width: "100%",

                                    backgroundColor: "#feefe8",
                                    paddingBottom: 5,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Box sx={{ marginTop: 4 }}>
                                        <Avatar
                                            style={{
                                                border: "3px solid white",
                                            }}
                                            alt="Remy Sharp"
                                            src="https://d19d5sz0wkl0lu.cloudfront.net/dims4/default/0c8a0a3/2147483647/thumbnail/1000x1000%3E/quality/90/?url=https%3A%2F%2Fatd-brightspot.s3.amazonaws.com%2F7f%2F38%2Fb37d0d6148e48fea8f76209eb3bb%2Fbigstock-pretty-teacher-smiling-at-came-69887626-1.jpg"
                                            sx={{ width: 120, height: 120 }}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography variant="h6">
                                                Trainer
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Chip
                                                size="small"
                                                variant="contained"
                                                sx={{
                                                    backgroundColor:
                                                        "#e30202b3",
                                                    color: "white",
                                                }}
                                                label="INT2306TR00238"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Divider
                                    variant="middle"
                                    sx={{
                                        borderStyle: "dashed",
                                        borderBottomWidth: 3,
                                        padding: 2,
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#ffd7ca",
                                            width: "90%",

                                            borderRadius: 3,
                                            marginTop: 3,
                                            paddingTop: 3,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Chip
                                                variant="outlined"
                                                color="error"
                                                label={
                                                    <Typography
                                                        color="black"
                                                        fontFamily="popins"
                                                        variant="h6"
                                                    >
                                                        Academic Details
                                                    </Typography>
                                                }
                                            />
                                        </Box>

                                        <Mytable
                                            tableRow={
                                                <>
                                                    <Rows
                                                        label="Course"
                                                        value="B.Sc Microbiology"
                                                    />
                                                    <Rows
                                                        label="Course Status"
                                                        value="Passed"
                                                    />
                                                    <Rows
                                                        label="Year of Passing"
                                                        value="2020"
                                                    />
                                                    <Rows
                                                        label="Academic Remarks"
                                                        value="  fonts; however, the most robust families will include weights that vary from the very lig"
                                                    />
                                                </>
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} lg={4} md={6}>
                            <Paper
                                elevation={3}
                                sx={{
                                    backgroundColor: "#feefe8",
                                    paddingBottom: 15,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: 4,
                                    }}
                                >
                                    <Chip
                                        variant="outlined"
                                        color="error"
                                        label={
                                            <Typography
                                                color="black"
                                                fontFamily="popins"
                                                variant="h6"
                                            >
                                                Personal Details
                                            </Typography>
                                        }
                                    />
                                </Box>
                                <Box>
                                    <Grid container>
                                        <Mytable
                                            tableRow={
                                                <>
                                                    <Rows
                                                        label="Name of Parent"
                                                        value="ABBAS"
                                                    />
                                                    <Rows
                                                        label="Date of Birth"
                                                        value="1998-01-26"
                                                    />
                                                    <Rows
                                                        label="Gender"
                                                        value="Female"
                                                    />
                                                    <Rows
                                                        label="Email ID"
                                                        value="mehanazmamma@gmail.com"
                                                    />
                                                    <Rows
                                                        label="Marital Status"
                                                        value="Married"
                                                    />
                                                    <Rows
                                                        label="Mobile No."
                                                        value="+917909209930"
                                                    />
                                                    <Rows
                                                        label="Mobile No. of Parent"
                                                        value="9995374499"
                                                    />
                                                    <Rows
                                                        label="Permanent Address"
                                                        value="MEHANAZ MANZIL , POYYA HOUSE ,UPPALA GATE , UPPALA, UPPALA PO, KASARAGOD , KERALA , 671322"
                                                    />
                                                </>
                                            }
                                        />
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        {/* -------------------------------------------------------------------- */}
                        <Grid item xs={12} lg={4} md={6}>
                            <Paper
                                elevation={3}
                                sx={{
                                    backgroundColor: "#feefe8",
                                    paddingBottom: 15,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: 4,
                                    }}
                                >
                                    <Chip
                                        variant="outlined"
                                        color="error"
                                        label={
                                            <Typography
                                                color="black"
                                                fontFamily="popins"
                                                variant="h6"
                                            >
                                                Professional Details
                                            </Typography>
                                        }
                                    />
                                </Box>
                                <Box>
                                    <Grid container>
                                        <Mytable
                                            tableRow={
                                                <>
                                                    <Rows
                                                        label="Prefered Syllabus"
                                                        value="State"
                                                    />
                                                    <Rows
                                                        label="English Fluency"
                                                        value="Excellent"
                                                    />
                                                    <Rows
                                                        label="Years of Experience"
                                                        value="5"
                                                    />
                                                    <Rows
                                                        label="Work Experience"
                                                        value="2 years teaching experience at Peace public school ,Hosangadi from class LKG to 4 th std Subjects handles (Islamic,language and social science ) And 3 years teaching experience in al fithra islamic center Kasaragod Subjects handled ( islamic studies)"
                                                    />
                                                </>
                                            }
                                        />
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                {/* </Stack> */}
            </Box>
        </div>
    );
};
