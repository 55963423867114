import ColorStatusViewer from "./ColorStatusViewer";
import TooltipRemarkViewer from "./TooltipRemarkViewer";
import OgaRemarksDetailedView from "./OgaRemarksDetailedView";
import CroLeadViewAction from "./CroLeadViewAction";
import { AddEnrollmentDetails } from "./RenderCellMain";

export const CRO_LEADS_TABLE_HEAD = [
    { field: "serialNumber", minWidth: 10, headerName: "slNo" },
    {
        field: "studentName",
        minWidth: 200,
        align: "left",
        headerName: "Student Name",
    },
    { field: "className", headerName: "class Name", minWidth: 90, flex: 1 },
    {
        field: "syllabusName",
        minWidth: 150,
        align: "left",
        headerName: "Syllabus",
        flex: 1,
    },
    {
        field: "mediumName",
        headerName: "Medium",
        minWidth: 100,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "courseName",
        headerName: "course name",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "contactPerson",
        headerName: "contact Pearson",
        minWidth: 150,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "contactNumber",
        headerName: "contact Number",
        minWidth: 150,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "place",
        headerName: "Place",
        minWidth: 150,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "leadRemarks",
        headerName: "Lead Remarks",
        minWidth: 150,
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
            <TooltipRemarkViewer value={params.row.leadRemarks} />
        ),
    },
    {
        field: "leadCameOn",
        headerName: "Lead came on",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "oga",
        headerName: "OGA",
        minWidth: 150,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "ogaRemarks",
        headerName: "OGA Remarks",
        type: "string",
        headerAlign: "left",
        align: "left",
        minWidth: 300,
        renderCell: (params) => (
            <TooltipRemarkViewer value={params.row.ogaRemarks} />
        ),
    },
    {
        field: "ogaAssessmentRemarks",
        headerName: "OGA Assesment Remarks",
        minWidth: 150,
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
            <TooltipRemarkViewer value={params.row.ogaAssessmentRemarks} />
        ),
    },
    {
        field: "ogaAdmissionConfirmedOn",
        headerName: "OGA admission confirmed on",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    // {
    //     field: "ogaRemarksDetail",
    //     headerName: "OGA Remark(Details)",
    //     minWidth: 100,
    //     headerAlign: "left",
    //     align: "left",
    //     renderCell: (params) => <OgaRemarksDetailedView row={params.row} />,
    // },
    {
        field: "jqa",
        headerName: "JQA",
        minWidth: 150,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "jqaLeadStatusName",
        headerName: "JQA Status",
        minWidth: 150,
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
            return (
                <ColorStatusViewer
                    value={params.row.jqaLeadStatusName}
                    color={params.row.jqaLeadStatusColor}
                />
            );
        },
    },
    {
        field: "jqaLastUpdatedOn",
        headerName: "JQA last updated On",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "croLedStatusName",
        headerName: "CRO lead Status",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
            return (
                <ColorStatusViewer
                    value={params.row.croLedStatusName}
                    color={params.row.croLeadStatusColor}
                />
            );
        },
    },
    {
        field: "croRemarks",
        headerName: "Cro Remarks",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
        renderCell: (params) => (
            <TooltipRemarkViewer value={params.row.croRemarks} />
        ),
    },
    {
        field: "croLastUpdatedOn",
        headerName: "Cro last updated On",
        minWidth: 200,
        type: "string",
        headerAlign: "left",
        align: "left",
    },
    // {
    //     field: "action",
    //     headerName: "Action",
    //     minWidth: 100,
    //     headerAlign: "left",
    //     align: "left",
    //     renderCell: (params) => <AddEnrollmentDetails {...params.row} />,
    // },
];
