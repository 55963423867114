import React, { useEffect } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { Box, Paper, Typography } from "@mui/material";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { Details } from "./Details";
import { Actions } from "./Actions";
import { useSelector, useDispatch } from "react-redux";
import { getAllStudentClassRestartedDates } from "../../../../redux/features/croSlice";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../../../components/css/calender.css";
import { CLASS_RESTART_DATES } from "../../../../components/datagrid/myworkspace/cro/StudentClassRestartDatesTableHead";
import { ListOfDetails } from "../../../../components/mui/DataGrid/ListOfDetails";

const Head = [
    {
        field: "studentName",
        headerName: "Student Name",
        width: 250,
    },
];

const status = [
    { id: 0, status: "All" },
    { id: 1, status: "This Week" },
    { id: 2, status: "Next Week" },
    { id: 3, status: "Expired" },
];

export default function StudentClassRestartDates() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllStudentClassRestartedDates());
    }, []);
    const { classRestartDates } = useSelector((state) => state.cro);

    const [selectedRows, setSelectedRows] = React.useState([]);
    console.log("selectedRows", selectedRows);

    return (
        <div>
            <Wrapper
                title="Student Class Restart Dates"
                Content={
                    <>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <Box width={"20%"}>
                                <GlobalSelectField
                                    options={{
                                        // formik: formik,
                                        sx: { width: "100%" },
                                        label: "Status",
                                        name: "status",
                                        data: status,

                                        keys: {
                                            id: "id",
                                            value: "status",
                                        },
                                    }}
                                    variant="filled"
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Paper elevation={3}>
                                <CustomDataGrid
                                    data={classRestartDates}
                                    rowId={"serialNumber"}
                                    columns={CLASS_RESTART_DATES}
                                    Head={Head}
                                    setSelectedRows={setSelectedRows}
                                    selectedRows={selectedRows}
                                    chipValue={selectedRows[0]?.adminNo}
                                    label={"Admission No"}
                                    name={selectedRows[0]?.studentName}
                                    // details={<Details data={selectedRows[0]} />}
                                    details={
                                        <ListOfDetails data={selectedRows[0]} />
                                    }
                                    actions={<Actions />}
                                />
                            </Paper>
                        </Box>
                    </>
                }
            />
        </div>
    );
}
