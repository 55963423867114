
import React, { useState, useEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { Box, CardHeader,Typography, Chip } from "@mui/material";
import useResponsive from "../../../../Hooks/useResponsive";

const SpeedoMeter = ({data,maxY}) => {
  const [percentage, setPercentage] = useState("");
  const [selections,setSelections]=useState([])
  const mdUp = useResponsive("up", "md");
  const [chartWidth,setChartWidth]=useState(350)
  const[fontsize,setFontSize]=useState("12px")

  useEffect(() => {
    if (mdUp) {
      setChartWidth(500)
      setFontSize("14px")
    } else {
      setChartWidth(350)
      setFontSize("12px")
    }
  }, [mdUp]);

useEffect(()=>{
setSelections(data || [])
},[data])

  useEffect(() => {
    let maximumValue = selections.length * maxY;
    const scoredMark = selections.reduce((acc, matrix) => acc + matrix.value, 0);
    let Percentage = Math.round((scoredMark / maximumValue) * 100);
    if(isNaN(Percentage)){
        Percentage=0
    }
    setPercentage(Percentage);
  }, [selections]);

  return (
    <Box sx={{display:'flex',justifyContent:'center',paddingTop:10}} height={364}>
      <ReactSpeedometer 
      value={percentage}
      minValue={0}
      maxValue={100}
      needleColor="#333"
      startColor="#FF471A"
      segments={5}
      endColor="#33CC33"
      currentValueText={`Overall Performance: ${percentage}%`}
      textColor="#000"
      customSegmentLabels={[
        {
          text: "Beginner",
          position: "INSIDE",
          color: "white",
          fontSize: fontsize,
          fontWeight: "bold",
        },
        {
          text: "Elementary",
          position: "INSIDE",
          color: "white",
          fontSize: fontsize,
          fontWeight: "bold",
        },
        {
          text: "Intermediate",
          position: "INSIDE",
          color: "white",
          fontSize: fontsize,
          fontWeight: "bold",
        },
        {
          text: "Advanced",
          position: "INSIDE",
          color: "white",
          fontSize: fontsize,
          fontWeight: "bold",
        },
        {
          text: "Expert",
          position: "INSIDE",
          color: "white",
          fontSize: fontsize,
          fontWeight: "bold",
        },
      ]}
      ringWidth={60}
      needleTransitionDuration={2000}
      needleTransition="easeQuadIn"
      needleHeightRatio={0.8}
      width={chartWidth}
      height={500}
    />
    </Box>
   
  );
};
export default SpeedoMeter;