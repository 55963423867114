import React from "react";
import GlobalDeleteDialog from "../../../../mui/Dialogue/GlobalDeleteDialog";
import {
  deleteExpense,
  getExpenses,
} from "../../../../../redux/features/croSlice";
import { useSelector } from "react-redux";
import { savePaginationData } from "../../../../../redux/features/globalDatagridSlice";

const DeleteExpense = ({ params, paginationData }) => {
  const { loading } = useSelector((state) => state.cro);

  const data = {
    page: paginationData?.pageNum,
    limit: paginationData?.gridSize,
    search: paginationData?.search,
    expenseTypeId: 2,
    year: params.expenseYear,
    month: params.expenseMonth,
  };

  return (
    <GlobalDeleteDialog
      btnName={false}
      title="Delete Expense"
      deleteNote={"Are you sure..? you wnat to delete this data...!"}
      api={deleteExpense(params.expenseId)}
      upDateCall={getExpenses(data)}
      updatePaginationCall={savePaginationData({
        ...paginationData,
        openAnchor: false,
      })}
      loading={loading}
    />
  );
};

export default DeleteExpense;
