import React from "react";
import { Route, Routes } from "react-router-dom";
import {
    ApproveTask,
    CreatedTasks,
    CreateTask,
    DepartmentTasks,
    EditTask,
    InwardTask,
    OutWardTask,
    UpdateMyTask,
} from "../../views/user/Todo";
import MyTasks from "../../views/user/Todo/MyTasks";
import HodRoute from "../route/HodRoute";
import Page404 from "../../views/user/Auth/Page404";

const TodoRouter = () => {
    return (
        <Routes>
            <Route path="update-task/:id" element={<UpdateMyTask />} />
            <Route element={<HodRoute />}>
                <Route path="approve-task/:id" element={<ApproveTask />} />
                <Route path="inward-tasks" element={<InwardTask />} />
                <Route path="outward-tasks" element={<OutWardTask />} />
                <Route path="department-tasks" element={<DepartmentTasks />} />
            </Route>
            <Route path="my-tasks" element={<MyTasks />} />
            <Route path="created-tasks" element={<CreatedTasks />} />
            <Route path="createtask" element={<CreateTask />} />
            <Route path="edit-task/:id" element={<EditTask />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default TodoRouter;
