import React from "react";
import GlobalDeleteDialog from "../../../../mui/Dialogue/GlobalDeleteDialog";
import {
  deleteDescription,
  getAllDescriptions,
} from "../../../../../redux/features/assessmentToolSlice";
import { useSelector } from "react-redux";

const DeleteDescription = ({ id }) => {
  const { loading } = useSelector((state) => state.assessmentTool);
  return (
    <GlobalDeleteDialog
      title={"Delete Description"}
      deleteNote={"Are you sure? you wnat to delete this description..?"}
      api={deleteDescription(id)}
      upDateCall={getAllDescriptions()}
      loading={loading}
    />
  );
};

export default DeleteDescription;
