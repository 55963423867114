import React from "react";
import {
  Paper,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Button,
} from "@mui/material";
import { Icon } from "@iconify/react";

export default function InterviewDetailsCard({
  title,
  Interviewer,
  date,
  status,
  form,
  action,
  rating,
  disabled,
}) {
  return (
    <div>
      <Paper
        elevation={3}
        sx={{
          backgroundImage: `url(${"https://t3.ftcdn.net/jpg/03/57/96/98/360_F_357969833_OJe2ircqLJDBsBfE6rva63hpIBzK2vRG.jpg"})`,
          "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
          transition: "transform 0.15s ease-in-out",
          height: 310,
        }}
      >
        <Box>
          <Box padding={1} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="title" sx={{ fontWeight: "bolder" }}>
              {title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <List>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    icon="material-symbols:communication"
                    width="40"
                    height="40"
                    color="#2f72b3"
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography fontWeight={"bolder"} variant="subtitle2">
                    Interviewer :- {Interviewer}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    icon="material-symbols:date-range-rounded"
                    width="26"
                    height="26"
                    color="#2f72b3"
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography fontWeight={"bolder"} variant="subtitle2">
                    Date :-{date}
                  </Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    icon="mdi:list-status"
                    width="30"
                    height="30"
                    color="#2f72b3"
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography fontWeight={"bolder"} variant="subtitle2">
                    <Stack direction={"row"}>
                      <Box paddingTop={0.5}>STATUS :-</Box>
                      <Box>{status}</Box>
                    </Stack>
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper>{rating}</Paper>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: 2,
            }}
          >
            <Button
              onClick={action}
              sx={{ backgroundColor: "#81aed8a3" }}
              disabled={disabled}
            >
              <Icon icon="radix-icons:update" width="20" height="20" />
              Update
            </Button>
            {form}
          </Box>
        </Box>
      </Paper>
    </div>
  );
}
