import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { Box, Paper, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { DataGrid } from "@mui/x-data-grid";
import { CRO_STUDENTS_TABLE_HEAD } from "../../../components/datagrid/myworkspace/cro/CroStudentsTableHead";
import BasicButton from "../../../components/mui/Button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourseEnrollments } from "../../../redux/features/croSlice";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";

const CroStudentsView = () => {
    const dispatch = useDispatch();
    const { courseEnrollments, loading } = useSelector((state) => state.cro);
    const [gridSize, setGridSize] = useState(5);

    useEffect(() => {
        dispatch(getCourseEnrollments());
    }, []);
    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Student Enrollment
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                        }}
                    >
                        <BasicButton
                            variant="contained"
                            sx={{
                                display: "flex",
                                gap: 1,
                            }}
                            component={Link}
                            to={"/my-tasks/cro-course-enroll"}
                        >
                            Course Enroll
                        </BasicButton>
                    </Box>
                    <Paper elevation={3} sx={{ borderRadius: 1, mt: 4 }}>
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                            }}
                        ></Box>

                        <Box>
                            <DataGrid
                                loading={loading}
                                rows={courseEnrollments}
                                columns={CRO_STUDENTS_TABLE_HEAD}
                                autoHeight
                                getRowId={(row) => row.serialNumber}
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                                components={{ Toolbar: CustomGridToolbar }}
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default CroStudentsView;
