import React from "react";
import loader from "./loader.gif";
import { Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import { useEffect } from "react";

export default function Loader() {
  const [checked, setChecked] = React.useState(false);
  useEffect(() => {
    setChecked((prev) => !prev);
  }, []);
  return (
    <div>
      <Fade timeout={{ enter: 1000 }} in={checked}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20%",
            }}
          >
            <img src={loader} alt="loading..." />
          </Box>
        </Box>
      </Fade>
    </div>
  );
}
