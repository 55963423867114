import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const GlobalFullDialog = ({ popupButton, title, content, open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box>{popupButton}</Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ pl: 1 }}> <Typography variant="h6">{title}</Typography> </Box>
            <Box>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Typography>Close</Typography>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </AppBar>
        <Divider />
        <Box >
        {content}
        </Box>
      
      </Dialog>
    </>
  );
};

export default GlobalFullDialog;
