import React from "react";
import GlobalDeleteDialog from "../../../../mui/Dialogue/GlobalDeleteDialog";
import {
  deleteGrade,
  getAllGrades,
} from "../../../../../redux/features/assessmentToolSlice";
import { useSelector } from "react-redux";

const DeleteGrade = ({ id, childFlag }) => {
  const { loading } = useSelector((state) => state.assessmentTool);
  return (
    <GlobalDeleteDialog
      title={"Delete Grade"}
      disabled={childFlag === 1 ? true : false}
      deleteNote={"Are you sure? you wnat to delete this Grade..?"}
      api={deleteGrade(id)}
      upDateCall={getAllGrades()}
      loading={loading}
    />
  );
};

export default DeleteGrade;
