import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Paper, Stack, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Icon } from "@iconify/react";
import useResponsive from "../../../../Hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewMoreDetails({ singleData }) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>View More Details</Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {smUp && (
          <>
            <Stack direction={"row"} spacing={45}>
              <DialogTitle>{"Details..."}</DialogTitle>
              <Button onClick={handleClose}>
                <Icon icon="iconoir:cancel" width="25" height="25" />
              </Button>
            </Stack>
            <Divider />
            <DialogContent>
              <Box sx={{ width: 500 }}>
                <Paper sx={{ backgroundColor: "grey.200", padding: 3 }}>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Icon
                          icon="ic:baseline-person"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Name :- {singleData.fullName}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon icon="ic:outline-place" width="25" height="25" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Place :- {singleData.place}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon
                          icon="material-symbols:phone-android-outline"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Phone :- {singleData.phone}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon icon="cil:education" width="25" height="25" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Qualification :- {singleData.qualification}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon
                          icon="ic:outline-attach-email"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Email :- {singleData.email}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon
                          icon="material-symbols:date-range-outline-rounded"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Applied On :- {singleData.appliedOn}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Paper>
              </Box>
            </DialogContent>
          </>
        )}

        {!smUp && (
          <>
            <Stack direction={"row"} spacing={15}>
              <DialogTitle>{"Details..."}</DialogTitle>
              <Button onClick={handleClose}>
                <Icon icon="iconoir:cancel" width="25" height="25" />
              </Button>
            </Stack>
            <Divider />
            <DialogContent>
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ backgroundColor: "grey.200", padding: 1 }}>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Icon
                          icon="ic:baseline-person"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Name :- {singleData.fullName}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon icon="ic:outline-place" width="25" height="25" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Place :- {singleData.place}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon
                          icon="material-symbols:phone-android-outline"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Phone :- {singleData.phone}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon icon="cil:education" width="25" height="25" />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Qualification :- {singleData.qualification}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon
                          icon="ic:outline-attach-email"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Email :- {singleData.email}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem>
                      <ListItemIcon>
                        <Icon
                          icon="material-symbols:date-range-outline-rounded"
                          width="25"
                          height="25"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="subtitle2">
                          Applied On :- {singleData.appliedOn}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Paper>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
}
