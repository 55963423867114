import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import BasicTextField from "../../../mui/TextField";
import GlobalSelectField from "../../../mui/GlobalSelectField";

const AddMouForm = () => {
    return (
        <Box
            sx={{
                minWidth: "600px",
            }}
        >
            <Typography
                variant="h3"
                sx={{
                    mb: 2,
                }}
            >
                Add Mou
            </Typography>
            <Stack gap={2} mb={2}>
                <GlobalSelectField
                    options={{
                        label: "Select Trainer",
                    }}
                />
                <GlobalSelectField
                    options={{
                        label: "Video Evaluation Status",
                    }}
                />
                <GlobalSelectField
                    options={{
                        label: "Interview Status",
                    }}
                />
                <BasicTextField label="Remarks" multiline rows={2} />
            </Stack>
            <Stack gap={2} direction="row" justifyContent="center">
                <Button variant="contained">Reset</Button>
                <Button variant="contained">Save</Button>
            </Stack>
        </Box>
    );
};

export default AddMouForm;
