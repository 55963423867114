import React, { useEffect, useState } from "react";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { DataGrid } from "@mui/x-data-grid";
import Page from "../../../components/Page";
import { Box, Paper, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { CRO_TERM_FEE_COLLECTION_TBL_HEAD } from "../../../components/datagrid/myworkspace/cro/CroTermFeeCollectionsTableHead";
import CustomDataGrid from "../../../components/mui/DataGrid/CustomDataGrid";
import TermFeeCollectionDetails from "../../../components/datagrid/myworkspace/cro/TermFeeCollectionDetails";
import TermFeeCollectionActions from "../../../components/datagrid/myworkspace/cro/TermFeeCollectionActions";
const CroTermfeeCollection = () => {
    const [gridSize, setGridSize] = useState(5);
    const [selectedRows, setSelectedRows] = useState([]);

    const studentData = [
        {
            serialNumber: 1,
            admnNo: 12345,
            studentName: "John Doe",
            studentMobNo: "9876543210",
            className: "Grade 10",
            courseName: "Mathematics",
            feeStructure: "Regular",
            term: "First Term",
            amount: 5000,
        },
        {
            serialNumber: 2,
            admnNo: 67890,
            studentName: "Jane Smith",
            studentMobNo: "8765432109",
            className: "Grade 12",
            courseName: "Physics",
            feeStructure: "Advanced",
            term: "Second Term",
            amount: 6000,
        },
        {
            serialNumber: 3,
            admnNo: 24680,
            studentName: "Bob Johnson",
            studentMobNo: "7654321098",
            className: "Grade 8",
            courseName: "English",
            feeStructure: "Regular",
            term: "Third Term",
            amount: 4500,
        },
        {
            serialNumber: 4,
            admnNo: 13579,
            studentName: "Alice Brown",
            studentMobNo: "6543210987",
            className: "Grade 11",
            courseName: "Chemistry",
            feeStructure: "Regular",
            term: "First Term",
            amount: 5500,
        },
        {
            serialNumber: 5,
            admnNo: 98765,
            studentName: "Michael Lee",
            studentMobNo: "5432109876",
            className: "Grade 9",
            courseName: "Biology",
            feeStructure: "Advanced",
            term: "Second Term",
            amount: 4800,
        },
    ];

    return (
        <Page title="My Tasks">
            <Box
                sx={{
                    width: "90%",
                    margin: "0 auto",
                }}
            >
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Term Fee Collection
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Box>
                    <Paper
                        elevation={3}
                        sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                    >
                        <Box
                            sx={{
                                backgroundColor: "grey.200",
                                borderRadius: "8px 8px 0 0",
                                display: "flex",
                                justifyContent: "right",
                                padding: "10px 10px",
                            }}
                        ></Box>

                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                rows={studentData}
                                columns={CRO_TERM_FEE_COLLECTION_TBL_HEAD}
                                getRowId={(row) => row.serialNumber}
                                autoHeight
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                                components={{ Toolbar: CustomGridToolbar }}
                                sortModel={[
                                    {
                                        field: "serialNumber",
                                        sort: "asc",
                                    },
                                ]}
                            />

                            <CustomDataGrid
                                data={studentData}
                                rowId={"serialNumber"}
                                columns={CRO_TERM_FEE_COLLECTION_TBL_HEAD}
                                Head={[
                                    {
                                        field: "serialNumber",
                                        headerName: "SlNo",
                                        width: 50,
                                    },
                                    {
                                        field: "studentName",
                                        headerName: "Student Name",
                                        width: 250,
                                    },
                                ]}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                admissionNo={selectedRows[0]?.admnNo}
                                name={selectedRows[0]?.studentName}
                                label={"Admission Number"}
                                chipValue={selectedRows[0]?.admnNo}
                                details={
                                    <TermFeeCollectionDetails
                                        data={selectedRows[0]}
                                    />
                                }
                                actions={
                                    <TermFeeCollectionActions
                                        data={selectedRows[0]}
                                    />
                                }
                            />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </Page>
    );
};

export default CroTermfeeCollection;
