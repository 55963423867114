import React, { useState } from "react";
import PopUps from "./PopUps";
import StudentTerm1updateForm from "./StudentTerm1updateForm";
import { Button } from "@mui/material";

const StudentTerm1EditPopUp = ({ row }) => {
    const [open, setOpen] = useState(false);
    const componentProps = {
        row: row,
        setOpen: setOpen,
    };
    return (
        <div>
            <Button variant="contained" onClick={() => setOpen(true)}>
                Edit
            </Button>
            <PopUps
                setOpen={setOpen}
                open={open}
                componentProps={componentProps}
                component={StudentTerm1updateForm}
            />
        </div>
    );
};

export default StudentTerm1EditPopUp;
