import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import { CancelButton, SubmitButton } from "../../../../mui/Buttons/Buttons";

export const Form = () => {
    return (
        <div>
            <Box padding={3}>
                <TextField
                    fullWidth
                    label="Add Expected Sessions"
                    type="number"
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Stack direction={"row"} spacing={2} marginTop={2}>
                        <CancelButton>Cancel</CancelButton>
                        <SubmitButton>Submit</SubmitButton>
                    </Stack>
                </Box>
            </Box>
        </div>
    );
};
