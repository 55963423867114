import { Icon } from "@iconify/react";
import {
    Box,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import React from "react";

const Mytable = ({ tableRow }) => {
    return (
        <Grid item xs={12} lg={6} md={6}>
            <Paper
                sx={{
                    margin: 1,
                    borderRadius: 4,
                    padding: 2,
                }}
            >
                <Table>{tableRow}</Table>
            </Paper>
        </Grid>
    );
};

const Rows = ({ label, value }) => {
    return (
        <TableBody>
            <TableRow>
                <TableCell variant="head">
                    <Stack direction={"row"} spacing={1}>
                        <Box>
                            <Icon
                                icon="uil:arrow-up"
                                rotate={1}
                                color="#2f72b3"
                                width="25"
                                height="25"
                            />
                        </Box>

                        <Box>{label}</Box>
                    </Stack>
                </TableCell>
                <TableCell>{value}</TableCell>
            </TableRow>
        </TableBody>
    );
};

// <------------------ this is for customising the component inside table cell------------------>//

const CustomRow = ({ label, value, component }) => {
    return (
        <TableBody>
            <TableRow>
                <TableCell variant="head">
                    <Stack direction={"row"} spacing={1}>
                        <Box>
                            <Icon
                                icon="uil:arrow-up"
                                rotate={1}
                                color="#2f72b3"
                                width="25"
                                height="25"
                            />
                        </Box>

                        <Box>{label}</Box>
                    </Stack>
                </TableCell>
                <TableCell align="center">{component}</TableCell>
            </TableRow>
        </TableBody>
    );
};

export { Mytable, Rows, CustomRow };
