import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Page404 from "../../views/user/Auth/Page404";
import {
    CreateNotification,
    EditNotification,
    SendNotification,
    ViewLog,
    ViewNotification,
} from "../../views/cro/Notifications";

import useRoles from "../../Hooks/useRoles";

const NotificationRouter = () => {
    const { role } = useSelector((state) => state);

    useRoles("notifications");

    return (
        !role.loading && (
            <Routes>
                {role.navChild.includes("create") && (
                    <Route path="create" element={<CreateNotification />} />
                )}

                {role.navChild.includes("view") && (
                    <Route path="view" element={<ViewNotification />} />
                )}

                {role.navChild.includes("send") && (
                    <Route path="send" element={<SendNotification />} />
                )}

                {role.permissions.includes("edit") && (
                    <Route path="edit/:id" element={<EditNotification />} />
                )}

                {role.permissions.includes("log") && (
                    <Route path="viewlog" element={<ViewLog />} />
                )}

                <Route
                    path="*"
                    element={
                        <Page404
                            message={
                                "you don't have the permission to perform such an action"
                            }
                        />
                    }
                />
            </Routes>
        )
    );
};

export default NotificationRouter;
