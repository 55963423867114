import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { STUDY_MATERIALS_TABLE_HEAD as columns } from "../../../../components/datagrid/myworkspace/sqa/StudyMaterials/StudyMaterialsTableHead";
import { Autocomplete, Box, Paper, Stack, TextField } from "@mui/material";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { AddMaterilas } from "./AddMaterilas";
import SelectionHeader from "../../../../components/mui/SelectionHeader/SelectionHeader";
import { SearchButton } from "../../../../components/mui/Buttons/Buttons";
import useResponsive from "../../../../Hooks/useResponsive";
const data = [
    {
        serialNumber: 1,
        courseId: "#INT-CRS0112",
        materialType: "Text Book",
        view: "",
        Delete: "",
    },
    {
        serialNumber: 2,
        courseId: "#INT-CRS0113",
        materialType: "Text Book",
        view: "",
        Delete: "",
    },
    {
        serialNumber: 3,
        courseId: "#INT-CRS0114",
        materialType: "Text Book",
        view: "",
        Delete: "",
    },
    {
        serialNumber: 4,
        courseId: "#INT-CRS0115",
        materialType: "Text Book",
        view: "",
        Delete: "",
    },
    {
        serialNumber: 5,
        courseId: "#INT-CRS0116",
        materialType: "Text Book",
        view: "",
        Delete: "",
    },
];

export default function StudyMaterials() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const [gridSize, setGridSize] = React.useState(5);
    return (
        <div>
            <Wrapper
                title="Study Materials"
                Content={
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                padding: 3,
                            }}
                        >
                            <AddMaterilas />
                        </Box>
                        <SelectionHeader
                            content={
                                <Box>
                                    <Stack
                                        direction={mdUp ? "row" : "column"}
                                        spacing={2}
                                    >
                                        <Box>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={top100Films}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Course"
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box>
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={top100Films}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Study Material Type"
                                                    />
                                                )}
                                            />
                                        </Box>
                                        <Box
                                            paddingTop={1}
                                            sx={{
                                                display: mdUp ? "" : "flex",
                                                justifyContent: mdUp
                                                    ? "flex-end"
                                                    : "center",
                                            }}
                                        >
                                            <SearchButton>Search</SearchButton>
                                        </Box>
                                    </Stack>
                                </Box>
                            }
                        />
                        <Box marginTop={2}>
                            <Paper elevation={3}>
                                <Box
                                    sx={{
                                        backgroundColor: "grey.200",
                                        borderRadius: "8px 8px 0 0",
                                        display: "flex",
                                        height: 44,
                                    }}
                                ></Box>
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    // loading={loading}
                                    rows={data}
                                    columns={columns}
                                    autoHeight={true}
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Paper>
                        </Box>
                    </>
                }
            />
            {/* ---------------------------------------------------------- */}
        </div>
    );
}

const top100Films = [
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
    {
        label: "The Lord of the Rings: The Return of the King",
        year: 2003,
    },
];
