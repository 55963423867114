import React, { useState } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { ApproveButton, CancelButton } from "../../../mui/Buttons/Buttons";
import { useSelector, useDispatch } from "react-redux";
import {
    createWiseAdmin,
    wiseAdminget,
} from "../../../../redux/features/crmSlice";
import { useSnackbar } from "notistack";

export const AssignCroToWiseAdmin = ({ params }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { loading } = useSelector((state) => state.crm);
    const { enqueueSnackbar } = useSnackbar();
    const profile = JSON.parse(localStorage.getItem("cred")).profile;

    const handleSubmit = () => {
        const data = {
            vendorUserId: `${params.userID}`,
            name: params.name,
            profile: "teacher",
            email: params.email,
            phoneNumber: `+91${params.phone}`,
            rollId: profile.roleID,
        };
        dispatch(createWiseAdmin(data)).then((res) => {
            if (res.payload.status === "error") {
                setOpen(false);
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                setOpen(false);
                dispatch(wiseAdminget());
                enqueueSnackbar("Assigned successfully", {
                    variant: "success",
                });
            }
        });
    };
    return (
        <div>
            <GlobalDialog
                title="Assign Cro"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                popupButton={
                    <>
                        <Button size="small" onClick={() => setOpen(true)}>
                            <Icon
                                icon="material-symbols-light:assignment-add-outline"
                                height={22}
                                width={22}
                            />
                            Assign
                        </Button>
                    </>
                }
                content={
                    <>
                        <Box>
                            <Box>
                                <Typography variant="sub-title">
                                    Are you sure. You want to Assign{" "}
                                    {params.name}..?
                                </Typography>
                            </Box>
                        </Box>
                    </>
                }
                actionButton={
                    <>
                        <CancelButton
                            size="small"
                            action={() => setOpen(false)}
                        >
                            Cancel
                        </CancelButton>
                        <ApproveButton size="small" action={handleSubmit}>
                            {loading ? "Assigning..." : "Assign"}
                        </ApproveButton>
                    </>
                }
            />
        </div>
    );
};
