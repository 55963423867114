import React from "react";
import { projectListingStatus } from "../../redux/features/mastersSlice";
import { StatusToggleSwitch } from "../StatusToggleSwitch";
export const ProjectListingStatus = ({ params }) => {
    return (
        <div>
            <StatusToggleSwitch
                initialValue={params.isListed}
                api={projectListingStatus(params.projectId)}
            />
        </div>
    );
};
