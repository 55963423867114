import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper/Wrapper";

import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getStudentsForEnrollmentDetailed } from "../../../redux/features/croSlice";
import { Paper } from "@mui/material";
import { ENROLLMENT_DETAILS_TABLE_HEAD } from "../../../components/datagrid/myworkspace/cro/EnrollmentDetailsTableHead";

export default function EnrollmentDetails() {
    const dispatch = useDispatch();
    const { studentsForEnrollmentDetailed, loading } = useSelector(
        (state) => state.cro
    );
    const [gridSize, setGridSize] = useState(5);
    const profile = JSON.parse(localStorage.getItem("cred")).profile;

    useEffect(() => {
        dispatch(getStudentsForEnrollmentDetailed(profile.userId));
    }, []);

    return (
        <div>
            <Wrapper
                title="Enrollment Details"
                Content={
                    <>
                        <Paper elevation={3}>
                            <Box width="100%">
                                <DataGrid
                                    components={{ Toolbar: CustomGridToolbar }}
                                    loading={loading}
                                    rows={studentsForEnrollmentDetailed}
                                    columns={ENROLLMENT_DETAILS_TABLE_HEAD}
                                    autoHeight
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            textOverflow: "clip",
                                            whiteSpace: "break-spaces",
                                            lineHeight: 1,
                                        },
                                    }}
                                    getRowId={(row) => row.serialNumber}
                                    pageSize={gridSize}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                />
                            </Box>
                        </Paper>
                    </>
                }
            />
        </div>
    );
}
