import { Button, Chip } from "@mui/material";
import React from "react";
import { AddButton, UpdateButton } from "../../../mui/Buttons/Buttons";
import { EditButton } from "../../../mui/Buttons/Buttons";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { UpdateForm } from "./UpdateForm";

export const Update = React.memo(({ params }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <GlobalDialog
        title="Update Expected Session"
        popupButton={
          <>
            {params.expectedSessions ? (
              <EditButton action={() => setOpen(true)}>
                <Chip
                  variant="outlined"
                  size="small"
                  label={`${params.expectedSessions} Sessions`}
                  color="primary"
                />
              </EditButton>
            ) : (
              <AddButton action={() => setOpen(true)}>
                <Chip
                  variant="outlined"
                  size="small"
                  label="Add Sessions"
                  color="primary"
                />
              </AddButton>
            )}
          </>
        }
        variant="contained"
        open={open}
        setOpen={setOpen}
        content={<UpdateForm open={open} setOpen={setOpen} params={params} />}
      />
    </div>
  );
});
