import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    EditButton,
    SaveButton,
} from "../mui/Buttons/Buttons";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
    addCourseAvailable,
    editCourseAvailable,
    getCourseAvailable,
    getProjectsPreData,
} from "../../redux/features/mastersSlice";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const EditCourse = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const { loading, projectsPreData } = useSelector((state) => state.masters);
    const [value, setValue] = React.useState({});

    useEffect(() => {
        const projectName = projectsPreData.find(
            (obj) => obj.id === params?.project_id
        );
        const data = projectName?.name;
        console.log("projectName", data);

        const datas = {
            id: params?.project_id || "",
            name: data || "",
        };
        setValue(datas);
    }, []);

    const Schema = Yup.object().shape({
        projectId: Yup.string().required("projectId is required"),
        courseAvailableName: Yup.string().required(
            "courseAvailableName is required"
        ),
        batchAvailability: Yup.string().required(
            "batchAvailability is required"
        ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            courseAvailableId: params.course_available_id,
            projectId: params.project_id || "",
            courseAvailableName: params.course_name || "",
            batchAvailability: 0,
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            dispatch(editCourseAvailable(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getCourseAvailable());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                title="Add Course"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <Box padding={1}>
                        <EditButton
                            size="small"
                            action={() => {
                                dispatch(getProjectsPreData());
                                setOpen(true);
                            }}
                        >
                            Edit
                        </EditButton>
                    </Box>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <Autocomplete
                                            disablePortal
                                            name="projectId"
                                            value={value}
                                            id="combo-box-demo"
                                            options={projectsPreData}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) =>
                                                option.id ||
                                                "" === value.id ||
                                                ""
                                            }
                                            getOptionLabel={(option) =>
                                                option.name || ""
                                            }
                                            onChange={(e, option, newValue) => {
                                                setValue({
                                                    id: option.id || "",
                                                    name: option.name || "",
                                                });
                                                formik.setFieldValue(
                                                    "projectId",
                                                    option.id
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    {...params}
                                                    label="project"
                                                    error={
                                                        formik.touched
                                                            .projectId &&
                                                        Boolean(
                                                            formik.errors
                                                                .projectId
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.projectId
                                                    }
                                                />
                                            )}
                                        />
                                        <TextField
                                            label="Course AvailableName"
                                            fullWidth
                                            name="courseAvailableName"
                                            value={
                                                formik.values
                                                    .courseAvailableName
                                            }
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched
                                                    .courseAvailableName &&
                                                Boolean(
                                                    formik.errors
                                                        .courseAvailableName
                                                )
                                            }
                                            helperText={
                                                formik.errors
                                                    .courseAvailableName
                                            }
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack spacing={2} direction={"row"}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
