import React from "react";
import GlobalDialog from "../../../../components/mui/Dialogue/GlobalDialog";
import { AddButton } from "../../../../components/mui/Buttons/Buttons";
import { Form } from "./Form";

export const AddMaterilas = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Add Materilas"
                open={open}
                setOpen={setOpen}
                fullWidth
                closeButton={true}
                maxWidth={"sm"}
                popupButton={
                    <AddButton
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Add Materilas
                    </AddButton>
                }
                content={<Form />}
            />
        </div>
    );
};
