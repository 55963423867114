import React from "react";
import { Paper, Box, Stack, Typography, Chip, Button } from "@mui/material";
import { Icon } from "@iconify/react";

export default function InterviewInitialCard({
  title,
  message,
  checkAssigned,
  assignedby,
  assigneddate,
  disableUpdateButton,
  interviewform,
  assignform,
  assignformaction,
  disableAssignButton,
  interviewformaction,
}) {
  return (
    <div>
      {" "}
      <Paper
        elevation={3}
        sx={{
          backgroundImage: `url(${"https://media.istockphoto.com/id/1139397586/vector/abstract-background.jpg?s=612x612&w=0&k=20&c=_5e5aIftqcC1toShclOkhV0S5RoXE42ZEa1BQ9Ri024="})`,
          "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
          transition: "transform 0.15s ease-in-out",
          height: 310,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stack direction={"column"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 0.6,
              }}
            >
              <Typography variant="title" sx={{ fontWeight: "bolder" }}>
                {title}
              </Typography>
            </Box>
            <Box>
              <Paper elevation={3}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Icon icon="ic:round-warning-amber" width="60" height="60" />
                </Box>

                <Typography variant="h6" padding={3}>
                  {message}
                </Typography>
                {checkAssigned == null ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 1,
                    }}
                  >
                    <Chip
                      variant="outlined"
                      label="Not Assigned"
                      color="error"
                      icon={<Icon icon="iconoir:cancel" />}
                      size="small"
                    />
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: 1,
                      }}
                    >
                      <Chip
                        variant="outlined"
                        label={assignedby}
                        color="success"
                        icon={<Icon icon="mdi:account-tick-outline" />}
                        size="small"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: 1,
                      }}
                    >
                      <Chip
                        variant="filled"
                        label={assigneddate}
                        color="primary"
                        icon={<Icon icon="ic:outline-date-range" />}
                        size="small"
                      />
                    </Box>
                  </>
                )}
              </Paper>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 0,
              }}
            >
              <Button
                disabled={disableUpdateButton}
                sx={{
                  backgroundColor: "#f0dfa4",
                  color: "#b78103",
                  margin: 1,
                }}
                onClick={interviewformaction}
              >
                <Icon icon="radix-icons:update" width="20" height="20" />
                Update
              </Button>
              {interviewform}

              <Button
                sx={{
                  backgroundColor: "#f0dfa4",
                  color: "#b78103",
                  margin: 1,
                }}
                onClick={assignformaction}
                disabled={disableAssignButton}
              >
                <Icon
                  icon="material-symbols:assignment-ind-outline"
                  width="20"
                  height="20"
                />
                Assign
              </Button>

              {assignform}
            </Box>
          </Stack>
        </Box>
      </Paper>
    </div>
  );
}
