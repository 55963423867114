import * as React from "react";
import {
    Box,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Container,
    Stack,
    Paper,
    Button,
    TextField,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { viewNotification } from "../../../redux/features/notificationSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, forwardRef, useState } from "react";
import Page from "../../../components/Page";
import { NOTIFICATIONS_TABLE_HEAD as columns } from "../../../components/datagrid/SendNotificationTableHead";
import useResponsive from "../../../../src/Hooks/useResponsive";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";

import { clearRes } from "../../../redux/features/notificationSlice";
import { Routes, Route, useNavigate } from "react-router-dom";
import CustomSelectField from "../../../components/mui/CustomSelectField";
import { VALUES } from "../../../utils/constants/InputValues";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import Loader from "../../../components/Loader/Loader";

function SendNotification() {
    const navigate = useNavigate();
    const logs = () => {
        dispatch(clearRes());
        navigate("/notifications/viewlog");
    };
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const [gridSize, setGridSize] = useState(5);
    const dispatch = useDispatch();
    const notifications = useSelector((state) => state.notification);
    useEffect(() => {
        dispatch(viewNotification("CUSTOM_SINGLE"));
        return () => {
            dispatch(clearRes());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page title="Notifications">
            {smUp && (
                <Container component="main">
                    <Stack direction="row" mb={2}>
                        <Box>
                            <Typography variant="h4" gutterBottom>
                                Send Notification
                            </Typography>
                            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                        </Box>
                    </Stack>
                    {/* {Can'nt be changed into global} */}

                    <Box>
                        <Box sx={{ padding: 1.5 }}>
                            <CustomSelectField
                                options={{
                                    name: "view",
                                    label: "Select Type",
                                    data: VALUES,
                                    variant: "filled",
                                }}
                            />
                        </Box>
                        <Paper
                            elevation={3}
                            sx={{ borderRadius: 1, width: "100%" }}
                        >
                            <Box sx={{ width: "100%" }}>
                                <DataGrid
                                    loading={notifications.loading}
                                    rows={notifications.data}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    autoHeight
                                    getRowHeight={() => "auto"}
                                    components={{ Toolbar: CustomGridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                    // experimentalFeatures={{ newEditingApi: true }}
                                    getRowId={(row) => row.notification_id}

                                    // checkboxSelection
                                    // disableSelectionOnClick
                                />
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            )}
            {!smUp && (
                <>
                    <Stack direction="row" mb={2}>
                        <Box>
                            <Typography variant="h4" gutterBottom>
                                Send Notification
                            </Typography>
                            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                        </Box>
                    </Stack>
                    <Box>
                        {/* {Can'nt be changed into global} */}

                        <Paper
                            elevation={3}
                            sx={{ borderRadius: 1, width: "100%" }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                    display: "flex",
                                    height: 75,
                                }}
                            >
                                <Box sx={{ padding: 1.5 }}>
                                    <CustomSelectField
                                        options={{
                                            name: "view",
                                            label: "Select Type",
                                            data: VALUES,
                                            variant: "filled",
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <DataGrid
                                    loading={notifications.loading}
                                    rows={notifications.data}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                    autoHeight
                                    getRowHeight={() => "auto"}
                                    components={{ Toolbar: GridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                    // experimentalFeatures={{ newEditingApi: true }}
                                    getRowId={(row) => row.notification_id}

                                    // checkboxSelection
                                    // disableSelectionOnClick
                                />
                            </Box>
                        </Paper>
                    </Box>
                </>
            )}
        </Page>
    );
}

export default SendNotification;
