import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { REFERRAL_STATUS_TABLE_HEAD as column } from "../../../components/datagrid/reffer/referralStatusTableHead";
import { clearState, getLeads } from "../../../redux/features/referrelSlice";

const ReferelStatus = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.referrel);

    const userProfile = JSON.parse(localStorage.getItem("cred")).profile;

    useEffect(() => {
        dispatch(getLeads(userProfile.userId));

        return () => dispatch(clearState());
    }, []);
    useEffect(() => {}, [data]);
    return (
        <div>
            <Typography variant="h4" sx={{ mb: 1 }}>
                Referral Status
            </Typography>
            <Breadcrumb sx={{ paddingLeft: 0.3 }} />
            <Box
                sx={{
                    marginTop: 4,
                    height: 48,
                    backgroundColor: "grey.200",
                    borderRadius: "8px 8px 0 0",
                }}
            ></Box>
            <DataGrid
                loading={data.loading}
                columns={column}
                rows={data.tableData}
                getRowId={(row) => row.serialNumber}
                pageSize={25}
                autoHeight
                autoPageSize
                rowsPerPageOptions={[5, 10, 25, 50]}
            />
        </div>
    );
};

export default ReferelStatus;
