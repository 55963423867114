import {
    Container,
    Paper,
    Typography,
    Box,
    TextField,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
    Divider,
} from "@mui/material";
import React, { useState } from "react";
import Page from "../../../components/Page";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Icon } from "@iconify/react";
import {
    getAttendanceTypes,
    addAttendance,
} from "../../../redux/features/attendanceSlice";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormikProvider, useFormik } from "formik";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { endOfYesterday } from "date-fns";
import useResponsive from "../../../../src/Hooks/useResponsive";
import { getAttendance } from "../../../redux/features/attendanceSlice";
import FormHelperText from "@mui/material/FormHelperText";
import { checkAttendanceMarked } from "../../../redux/features/AttendanceCheckSlice";

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {children: React.ReactElement<any, any>,},ref: React.Ref<unknown>
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function MarkAttendance() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const leaveType = useSelector((state) => state.attendance);
    const [value, setValue] = useState(dayjs(new Date()));
    const [type, setType] = React.useState("");
    const { enqueueSnackbar } = useSnackbar();
    const [date, setDate] = React.useState(new Date());
    const [val, setVal] = useState();
    const handleChange = (event) => {
        setType(event.target.value);
    };
    // --------------------------------------------------------------------------
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    // ----------------------------------------------------------------------------

    useEffect(() => {
        dispatch(getAttendanceTypes());
    }, []);

    const formik = useFormik({
        initialValues: {
            attendanceDate: dayjs(),
            attendanceStatus: 1,
        },
        onSubmit: (values, { resetForm }) => {
            const atsDate = values.attendanceDate;
            const formatedDate = atsDate.format("YYYY-MM-DD");
            const datas = {
                attendanceDate: formatedDate,
                attendanceStatus: values.attendanceStatus,
            };

            dispatch(addAttendance(datas)).then((res) => {
                if (res.payload.status === "error") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                    setOpen(false);
                    resetForm();
                } else if (res.payload.status === "success") {
                    enqueueSnackbar("Attendance Marked Successfully...", {
                        variant: "success",
                    });

                    dispatch(
                        getAttendance(
                            `${
                                date.toISOString().split("T")[0].split("-")[0]
                            }/${date.toISOString().split("T")[0].split("-")[1]}`
                        )
                    );

                    setOpen(false);
                    dispatch(checkAttendanceMarked());
                    resetForm();
                }
            });
        },
    });

    const handleattendance = (e) => {
        setVal(e);
        formik.setFieldValue("attendanceDate", e);
    };

    return (
        <div>
            {smUp && (
                <div>
                    <Button
                        sx={{ backgroundColor: "#dccfe0", color: "#7e4d8b" }}
                        onClick={handleClickOpen}
                    >
                        <Icon
                            icon="material-symbols:edit-calendar-outline"
                            width="30"
                            height="30"
                        />
                        Mark Attendance
                    </Button>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <DialogContent>
                                    <Page title="MarkAttendance">
                                        <Typography padding={2} variant="h4">
                                            Mark Attendance
                                        </Typography>
                                        {/* <Breadcrumb sx={{ paddingLeft: 0.3 }} /> */}

                                        <Box sx={{px:2}}>
                                            <Paper square
                                           
                                                elevation={1}
                                                sx={{
                                                    width: 500,
                                                    backgroundColor: "#eff4f9",
                                                }}
                                            >
                                                <Box sx={{ padding: 5 }}>
                                                    <Box sx={{ margin: 3 }}>
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label="Date"
                                                                name="attendanceDate"
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                                inputFormat="YYYY/MM/DD"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .attendanceDate
                                                                }
                                                                minDate={dayjs().add(
                                                                    -1,
                                                                    "day"
                                                                )}
                                                                maxDate={dayjs()}
                                                                mask=""
                                                                onChange={
                                                                    handleattendance
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        variant="filled"
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    <Box sx={{ margin: 3 }}>
                                                        <FormControl
                                                            fullWidth
                                                            variant="filled"
                                                        >
                                                            <InputLabel id="demo-simple-select-label">
                                                                Attendance
                                                            </InputLabel>
                                                            <Select
                                                                name="attendanceStatus"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .attendanceStatus
                                                                }
                                                                label="Type of Leave"
                                                                required
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "attendanceStatus",
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            >
                                                                {leaveType.types.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.fullStatus
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                            {/* <FormHelperText>{}</FormHelperText> */}
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        </Box>
                                    </Page>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor: "#eff4f9",
                                            color: "#3476b5",
                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        sx={{
                                            backgroundColor: "#7e4d8b45",
                                            color: "#7e4d8b",
                                        }}
                                        type="submit"
                                    >
                                        <Icon
                                            icon="healthicons:yes-outline"
                                            width="25"
                                            height="25"
                                        />

                                        {leaveType.loading
                                            ? "Marking..."
                                            : "Mark"}
                                    </LoadingButton>
                                </DialogActions>
                            </Form>
                        </FormikProvider>
                    </Dialog>
                </div>
            )}
            {!smUp && (
                <div>
                    <Button
                        size="small"
                        sx={{ backgroundColor: "#dccfe0", color: "#7e4d8b" }}
                        onClick={handleClickOpen}
                    >
                        <Icon
                            icon="material-symbols:edit-calendar-outline"
                            width="30"
                            height="30"
                        />
                        Mark Attendance
                    </Button>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <DialogContent>
                                    <Page title="MarkAttendance">
                                        <Typography padding={1} variant="h4">
                                            Mark Attendance
                                        </Typography>
                                        {/* <Breadcrumb sx={{ paddingLeft: 0.3 }} /> */}

                                        <Box>
                                            <Paper
                                                elevation={0}
                                                sx={{
                                                    width: "100%",
                                                    backgroundColor: "#eff4f9",
                                                }}
                                            >
                                                <Box sx={{ padding: 2 }}>
                                                    <Box sx={{ margin: 1 }}>
                                                        <LocalizationProvider
                                                            dateAdapter={
                                                                AdapterDayjs
                                                            }
                                                        >
                                                            <DatePicker
                                                                label="Date"
                                                                name="attendanceDate"
                                                                inputFormat="YYYY/MM/DD"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .attendanceDate
                                                                }
                                                                minDate={dayjs().add(
                                                                    -1,
                                                                    "day"
                                                                )}
                                                                maxDate={dayjs()}
                                                                mask=""
                                                                onChange={
                                                                    handleattendance
                                                                }
                                                                renderInput={(
                                                                    params
                                                                ) => (
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        variant="filled"
                                                                        {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            readOnly: true,
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    <Box sx={{ margin: 1 }}>
                                                        <FormControl
                                                            fullWidth
                                                            variant="filled"
                                                        >
                                                            <InputLabel id="demo-simple-select-label">
                                                                Attendance
                                                            </InputLabel>
                                                            <Select
                                                                name="attendanceStatus"
                                                                value={
                                                                    formik
                                                                        .values
                                                                        .attendanceStatus
                                                                }
                                                                label="Type of Leave"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    formik.setFieldValue(
                                                                        "attendanceStatus",
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            >
                                                                {leaveType.types.map(
                                                                    (item) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.id
                                                                                }
                                                                                key={
                                                                                    item.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.fullStatus
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        </Box>
                                    </Page>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        sx={{
                                            backgroundColor: "#eff4f9",
                                            color: "#3476b5",
                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <LoadingButton
                                        sx={{
                                            backgroundColor: "#7e4d8b45",
                                            color: "#7e4d8b",
                                        }}
                                        type="submit"
                                    >
                                        <Icon
                                            icon="healthicons:yes-outline"
                                            width="25"
                                            height="25"
                                        />

                                        {leaveType.loading
                                            ? "Marking..."
                                            : "Mark"}
                                    </LoadingButton>
                                </DialogActions>
                            </Form>
                        </FormikProvider>
                    </Dialog>
                </div>
            )}
        </div>
    );
}
