import { SessionsStatus } from "./SessionsStatus";
import UtilsMenu from "./UtilityMenu";
export const SessionTableColumns = [
    {
        field: "schedule_id",
        headerName: "ID",
        width: 90,
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "schedule_date",
        headerName: "Date",
        width: 200,
        type: "date",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "schedule_from_time",
        headerName: "From",
        width: 180,
        type: "dateTime",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "schedule_to_time",
        headerName: "To",
        width: 180,
        type: "dateTime",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Status",
        headerName: "Status",
        width: 250,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => <SessionsStatus status={params.row.status} />,
    },
    // {
    //     field: "session_content",
    //     headerName: "Content",
    //     width: 400,
    //     type: "string",
    //     headerAlign: "left",
    //     align: "left",
    // },

    {
        field: "Actions ",
        width: 100,
        align: "center",
        renderCell: (params) =>
            params.row.status !== "Completed" ? UtilsMenu(params) : null,
    },
];
