import { Chip } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";

export const AssignCroSatus = React.memo((params) => {
  return (
    <Chip
      icon={
        params.crmLeadStatus === "Assigned" ? (
          <Icon icon="charm:tick" />
        ) : (
          <Icon icon="radix-icons:cross-2" />
        )
      }
      label={params.crmLeadStatus}
      variant="outlined"
      color={params.crmLeadStatus === "Assigned" ? "success" : "warning"}
      size="small"
    />
  );
});
