import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../components/Wrapper/GlobalWrapper";
import { Box, Chip, Paper, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getLeadTypes, getOgmLeads } from "../../../redux/features/ogdSlice";
import { OGM_LEADS_TABLE_HEAD } from "../../../components/datagrid/myworkspace/ogd/ogmLeads/OgmLeadsTableHead";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import GlobalDatagrid from "../../../components/mui/DataGrid/GlobalDatagrid";
import ActionDetails from "../../../components/mui/GlobalAnchor/ActionDetails";
import {
  AssignOgaView,
  OgaSubjectWiseAssessmentDataView,
} from "../../../components/datagrid/myworkspace/ogd/ogmLeads/RenderCellMain";
import GlobalAnchorList from "../../../components/mui/GlobalAnchor/GlobalAnchorList";
import { savePaginationData } from "../../../redux/features/globalDatagridSlice";

const OgmLeads = () => {
  const dispatch = useDispatch();
  const { leadType, ogmLeads, loading } = useSelector((state) => state.ogd);
  const [value, setValue] = useState(dayjs());
  const [leadTypes, seLeasTypes] = useState(1);
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData;

  const handleChange = (event) => {
    seLeasTypes(event.target.value);
  };
  const data = {
    page: pageNum,
    limit: gridSize,
  };
  useEffect(() => {
    const datas = {
      ...data,
      date: dayjs(value).format("YYYY-MM"),
      leadType: leadTypes,
      search: search,
    };
    dispatch(getOgmLeads(datas));
  }, [leadTypes, value, pageNum, gridSize, search]);

  useEffect(() => {
    dispatch(savePaginationData({ ...paginationData, pageNum: 1 }));
  }, [value, leadTypes, search]);

  useEffect(() => {
    dispatch(getLeadTypes());
  }, []);

  const updateData = {
    ...data,
    leadId: rowData?.lead_id,
    sroAssignmentId: rowData?.sro_assignment_id,
    sroAssigned: rowData?.sro_assigned,
    date: dayjs(value).format("YYYY-MM"),
    leadType: leadTypes,
    openAnchor,
    search: search,
  };

  return (
    <GlobalWrapper title={"OGM Leads"}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 2 }}>
        <Stack direction={"row"} spacing={2}>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Year/Month"
                value={value}
                views={["year", "month"]}
                format="YYYY-MM" //
                onChange={(newValue) => setValue(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ minWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Lead Types</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={leadTypes}
                label="Lead Types"
                onChange={handleChange}
              >
                {leadType.map((item) => {
                  return (
                    <MenuItem key={item.lead_type_id} value={item.lead_type_id}>
                      {item.lead_type_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Box>
      <Paper elevation={3}>
        <Box sx={{ width: "100%", padding: 0 }}>
          <GlobalDatagrid
            tableHead={OGM_LEADS_TABLE_HEAD}
            rows={ogmLeads.data?.ogmLeads}
            rowUniqueId="sl_no"
            meta={ogmLeads?.meta?.totalRecords || 0}
            rowClick={true}
            loading={loading}
            actionComponent={
              <>
                <ActionDetails val={rowData?.lead_name} />
                {!updateData?.sroAssigned ? null : (
                  <GlobalAnchorList
                    label="Assign OGA"
                    component={<AssignOgaView {...updateData} />}
                  />
                )}
                <GlobalAnchorList
                  label="Subject Wise Assessment"
                  component={
                    <OgaSubjectWiseAssessmentDataView {...updateData} />
                  }
                />
              </>
            }
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  );
};

export default OgmLeads;
