import { Box, Grid } from "@mui/material";
import React from "react";
import { Mytable, Rows } from "../../../../components/mui/DataGrid/Details";

export const Details = ({ data }) => {
    return (
        <div>
            <Box>
                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <Rows label="Class" value={data?.class} />
                                <Rows label="Course" value={data?.course} />
                                <Rows label="Course" value={data?.course} />
                                <Rows
                                    label="Publication"
                                    value={data?.publication}
                                />
                                <Rows
                                    label="Contact No"
                                    value={data?.contactNo}
                                />
                                <Rows label="Place" value={data?.place} />
                                <Rows label="Came On" value={data?.cameOn} />
                                <Rows label="OGA" value={data?.oga} />
                                <Rows
                                    label="OGA Remarks"
                                    value={data?.ogaRemarks}
                                />
                            </>
                        }
                    />

                    <Mytable
                        tableRow={
                            <>
                                <Rows label="CRO" value={data?.cro} />
                                <Rows
                                    label="CRO Remarks"
                                    value={data?.croRemarks}
                                />
                                <Rows
                                    label="SQA Status"
                                    value={data?.sqaStatus}
                                />
                                <Rows
                                    label="Assigned On"
                                    value={data?.assignedOn}
                                />
                                <Rows
                                    label="Assigned JQA"
                                    value={data?.assignedJqa}
                                />
                                <Rows
                                    label="JQA Status"
                                    value={data?.jqaStatus}
                                />
                                <Rows
                                    label="JQA Last Updated On"
                                    value={data?.jqaLastUpdatedOn}
                                />
                                <Rows
                                    label="CRO Aknowledged On"
                                    value={data?.croAknowledgedOn}
                                />
                            </>
                        }
                    />
                </Grid>
            </Box>
        </div>
    );
};
