import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import CustomGridToolbar from "../../datagrid/GridToolBar";
import GlobalAnchor from "../GlobalAnchor/GlobalAnchor";
import { Box, Button } from "@mui/material";
import useResponsive from "../../../Hooks/useResponsive";
import { useDispatch } from "react-redux";
import {
  clearPaginationData,
  savePaginationData,
} from "../../../redux/features/globalDatagridSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const GlobalDatagrid = ({
  loading,
  tableHead,
  rows,
  rowUniqueId,
  getRowHeight,
  meta,
  actionComponent,
  rowClick,
}) => {
  const location = useLocation();
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  // ------------------------------------------------------------------------------
  const { paginationData } = useSelector((state) => state.globalDataGrid);
  const [gridSize, setGridSize] = useState(paginationData?.gridSize || 10);
  const [pageNum, setPageNum] = useState(paginationData?.pageNum || 1);
  const [rowCount, setRowCount] = useState(meta || 0);
  const [rowData, setRowData] = useState("");
  const [search, setSearch] = useState(paginationData?.search || "");
  const [openAnchor, setOpenAnchor] = useState(
    paginationData?.openAnchor || false
  );
  const [pathName, setPathName] = useState("");

  useEffect(() => {
    if (location.pathname) {
      setPathName(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(clearPaginationData());
  }, [pathName]);

  useEffect(() => {
    // Only update if paginationData exists
    if (paginationData) {
      setPageNum(paginationData.pageNum);
      setGridSize(paginationData.gridSize);
      setOpenAnchor(paginationData.openAnchor);
      setSearch(paginationData?.search);
    }
  }, [paginationData]);

  useEffect(() => {
    setRowCount(meta || 0);
  }, [rows]);
  // ----------------------------------------------------------------------------------

  useEffect(() => {
    dispatch(
      savePaginationData({
        gridSize,
        pageNum,
        rowCount,
        rowData,
        openAnchor,
        search,
      })
    );
  }, [gridSize, pageNum, rowCount, rowData, openAnchor, search]);

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length > 0) {
      const { field, sort } = sortModel[0]; // Field and sort direction (asc/desc)
      console.log("Sort field:", field);
      console.log("Sort direction:", sort);
    } else {
      console.log("sort cleared");
    }
  };

  const previousSearchValue = useRef("");
  const handleFilterModelChange = (filterModel) => {
    const quickFilter = filterModel.quickFilterValues?.[0] || "";

    if (quickFilter !== previousSearchValue.current) {
      searchFunction(quickFilter);
      previousSearchValue.current = quickFilter;
    }
    if (filterModel.items.length > 0) {
      filterFunction(filterModel.items);
    }
  };

  const searchFunction = (searchValue) => {
    // console.log("search value", searchValue);
    setSearch(searchValue);
  };

  const filterFunction = (filters) => {
    filters.forEach((filter) => {
      const { columnField, operatorValue, value } = filter;
      console.log("Filter column:", columnField);
      console.log("Filter operator:", operatorValue);
      console.log("Filter value:", value);
    });
  };

  const handleRowClick = (params) => {
    if (rowClick) {
      setRowData(params.row);
    } else {
      setRowData("");
    }
  };
  useEffect(() => {
    if (!openAnchor) {
      setRowData("");
    }
  }, [openAnchor]);
  useEffect(() => {
    if (rowData) {
      setOpenAnchor(true);
      dispatch(
        savePaginationData({
          gridSize,
          pageNum,
          rowCount,
          rowData,
          search,
          openAnchor: true,
        })
      );
    } else {
      setOpenAnchor(false);
      savePaginationData({
        gridSize,
        pageNum,
        rowCount,
        rowData,
        search,
        openAnchor: false,
      });
    }
  }, [rowData]);
  const handleClick = () => {
    setOpenAnchor(false);
    savePaginationData({
      gridSize,
      pageNum,
      rowCount,
      rowData,
      search,
      openAnchor: false,
    });
  };

  const lastTapRef = useRef(null);

  const handleRowClicked = (params) => {
    const currentTime = new Date().getTime();
    const tapGap = 300;

    if (lastTapRef.current && currentTime - lastTapRef.current < tapGap) {
      if (!smUp && rowClick) {
        setRowData(params.row);
      }
    } else {
      lastTapRef.current = currentTime;
    }
  };

  return (
    <div>
      <DataGrid
        disableVirtualization
        components={{ Toolbar: CustomGridToolbar }}
        loading={loading}
        autoHeight
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          },
        }}
        getRowHeight={getRowHeight}
        onRowDoubleClick={handleRowClick}
        onRowClick={handleRowClicked}
        rows={rows ? rows : []}
        columns={tableHead}
        pagination
        paginationMode="server"
        getRowId={(row) => row[rowUniqueId]}
        pageSize={gridSize}
        columnBuffer={30}
        rowCount={rowCount}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
        page={pageNum - 1}
        onPageChange={(newPageNum) => {
          // console.log("newPageNum", newPageNum);
          setPageNum(newPageNum + 1);
        }}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
      />
      <GlobalAnchor
        openAnchor={openAnchor}
        setOpenAnchor={setOpenAnchor}
        handleClick={handleClick}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}></Box>
        <Box>{actionComponent}</Box>
      </GlobalAnchor>
    </div>
  );
};

export default GlobalDatagrid;
