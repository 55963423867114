import React, { useEffect, useState } from "react";
//MUI IMPORTS
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Checkbox from "@mui/material/Checkbox";


const SpeakProRatingComponent = ({
  rating,
  matrix,
  handleRatingChange,
  handleCheckboxChange,
  selections
}) => {
 const [isChecked,setIsChecked]=useState("") //TO SHOW THE CURRENT CHECKED ELEMENT
 
 useEffect(() => {
  const selectedDescription = selections?.find(
    (selected) => selected.matrixId === matrix.matrix_id
  );
  if (selectedDescription) {
    setIsChecked(selectedDescription.checkedDescriptionId);
  } else {
    setIsChecked(""); 
  }
}, [selections, matrix.matrix_id]);

  return (
    <Box
      sx={{
        marginLeft: "15px",
        padding: "8px",
      }}
    >
      <Rating
        size="large"
        name="simple-controlled"
        max={5}
        value={rating[matrix.matrix_id] / 10 || 0}
        onChange={(event, newValue) =>
          handleRatingChange(newValue, matrix.matrix_id)
        }
      />
      {true && (
        <Box sx={{ marginTop: "10px" }}>
          {matrix?.grades
            ?.filter((grade) => grade.grade_name == rating[matrix.matrix_id])[0]
            ?.descriptions.map((description) => (
              <Box
                key={description.description_id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                checked={description.description_id==isChecked}
                  onChange={() => {
                    handleCheckboxChange(
                      matrix.matrix_name,
                      matrix.matrix_id,
                      rating[matrix.matrix_id],
                      description.description_id,
                      description.description
                    );
                  }}
                />
                {description.description}
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default SpeakProRatingComponent;
