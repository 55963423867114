import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TextField, Paper, Box, Divider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllUsers } from "../../../../redux/features/userSlice";
import { useFormik, Form, FormikProvider } from "formik";
import {
  createDepartment,
  getAllDepartment,
} from "../../../../redux/features/departmentSlice";
import { useSnackbar } from "notistack";
import useResponsive from "../../../../Hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreateDepartment({ open, setOpen }) {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const loading = useSelector((state) => state.departments.loading);
  const users = useSelector((state) => state.users);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {}, [users]);

  const formik = useFormik({
    initialValues: {
      deptName: "",
      hodId: "",
    },
    // validationSchema: CreateSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(createDepartment(values)).then((res) => {
        if (res.payload.status === "failed") {
          enqueueSnackbar(res.payload.message, { variant: "error" });
        } else if (res.payload.status === 200) {
          enqueueSnackbar("Created Successfully..", {
            variant: "success",
          });
          dispatch(getAllDepartment());
          setOpen(false);
          resetForm();
        }
      });
    },
  });

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <DialogTitle>{"Create New Department"}</DialogTitle>
            <Divider />
            <DialogContent>
              <Paper sx={{ backgroundColor: "#f2f3f5" }}>
                <Box sx={{ width: smUp ? 500 : 280, padding: smUp ? 4 : 2 }}>
                  <Box margin={2}>
                    <TextField
                      label="Department"
                      name="deptName"
                      placeholder="Enter new Department Name"
                      variant="filled"
                      fullWidth
                      value={formik.values.deptName}
                      onChange={formik.handleChange}
                    />
                  </Box>
                  <Box margin={2}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      name="hodId"
                      options={users.data}
                      getOptionLabel={(option) => option.name || ""}
                      onChange={(e, value) => {
                        formik.setFieldValue("hodId", value.id);
                      }}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select HOD"
                          fullWidth
                          variant="filled"
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Paper>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{
                  marginLeft: 2,
                  backgroundColor: "#eff4f9",
                  color: "#008fc3",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  marginLeft: 2,
                  backgroundColor: "#c8facd",
                  color: "#0db05d",
                }}
                // onClick={handleClose}
                type="submit"
              >
                <Icon
                  icon="mdi:content-save-check-outline"
                  width="25"
                  height="25"
                />
                {loading ? "Saving.." : "Save Department"}
              </Button>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </div>
  );
}
