import React, { useState } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Box, Button, Stack, TextField } from "@mui/material";
import {
    CancelButton,
    SaveButton,
    UpdateButton,
} from "../../../mui/Buttons/Buttons";
import GlobalSelectField from "../../../mui/GlobalSelectField";
import { useFormik, Form, FormikProvider } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { updateTpVerificationData } from "../../../../redux/features/croSlice";

export const UpdateTpVerification = ({ tpInvoiceId, poupStatus }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const Schema = Yup.object().shape({
        statusId: Yup.string().required("statusId is required"),
        remarks: Yup.string().required("remarks is required"),
    });

    const formik = useFormik({
        initialValues: {
            tpInvoiceId: tpInvoiceId,
            statusId: "",
            remarks: "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            dispatch(updateTpVerificationData(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    poupStatus(false);
                    resetForm();
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                open={open}
                setOpen={setOpen}
                fullWidth
                title="Update"
                maxWidth={"sm"}
                popupButton={
                    <Box padding={1}>
                        <UpdateButton
                            size="small"
                            action={() => {
                                setOpen(true);
                            }}
                        >
                            Update
                        </UpdateButton>
                    </Box>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <GlobalSelectField
                                            options={{
                                                name: "statusId",
                                                formik,
                                                sx: {
                                                    width: "100%",
                                                },
                                                label: "Status",
                                                data: [],
                                                keys: {
                                                    id: "courseId",
                                                    value: "courseName",
                                                },
                                            }}
                                        />
                                        <TextField
                                            label="Remark"
                                            name="remarks"
                                            fullWidth
                                            multiline
                                            rows={3}
                                            onChange={formik.handleChange}
                                            value={formik.values.remarks}
                                            error={
                                                formik.touched.remarks &&
                                                Boolean(formik.errors.remarks)
                                            }
                                            helperText={formik.errors.remarks}
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            mt: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                Save
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
