import { Box, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import useResponsive from "../../../../Hooks/useResponsive";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { Icon } from "@iconify/react";
import cvimage from "./image.png";
import { useSelector } from "react-redux";

const ProfileHeader = () => {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    // const { assessmentFormData } = useSelector((state) => state.assessmentTool);
    const assessmentFormData = JSON.parse(localStorage.getItem("studentData"));
    const [checked, setChecked] = React.useState(true);
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }));
    return (
        <Box>
            {smUp && (
                <>
                    <Grow in={checked} timeout={{ enter: 1000 }}>
                        <Box sx={{ marginTop: 2 }}>
                            <div>
                                <Paper
                                    elevation={2}
                                    sx={{
                                        width: "100%",
                                        height: 200,
                                        backgroundSize: "contain",
                                        backgroundSize: "cover",
                                        backgroundImage: `url(${"https://wallpapers.com/images/featured/dark-blue-1u6lldkvi9buf4rl.webp"})`,
                                        borderTopRightRadius: 20,
                                        borderTopLeftRadius: 20,
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 0,
                                    }}
                                >
                                    <Stack direction={"row"}>
                                        <Box paddingTop={14} paddingLeft={5}>
                                            <Stack direction={"row"}>
                                                <Box
                                                    sx={{
                                                        marginTop: 1,
                                                    }}
                                                >
                                                    <HtmlTooltip
                                                        title={
                                                            <React.Fragment>
                                                                <Stack
                                                                    direction={
                                                                        "row"
                                                                    }
                                                                >
                                                                    <Box>
                                                                        <Icon
                                                                            icon="svg-spinners:pulse-2"
                                                                            color="#1769aa"
                                                                            width="30"
                                                                            height="30"
                                                                        />
                                                                    </Box>
                                                                </Stack>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <Paper
                                                            sx={{
                                                                borderRadius: 4,
                                                                height: 100,
                                                                width: 100,
                                                                backgroundColor:
                                                                    "#fff",
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                ":hover": {
                                                                    boxShadow: 20, // theme.shadows[20]
                                                                    backgroundColor:
                                                                        "grey.300",
                                                                },
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    marginTop: 0,
                                                                    overflow:
                                                                        "hidden",
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        cvimage
                                                                    }
                                                                />
                                                            </Box>
                                                        </Paper>
                                                    </HtmlTooltip>
                                                </Box>
                                                <Box margin={2}>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            mb: 1,
                                                            color: "#fff",
                                                        }}
                                                    >
                                                        {
                                                            assessmentFormData?.name
                                                        }
                                                    </Typography>
                                                    <Stack
                                                        direction={"row"}
                                                        spacing={1}
                                                    >
                                                        <Typography
                                                            // variant="h6"
                                                            color="#fff"
                                                            textTransform="capitalize"
                                                        >
                                                            {
                                                                assessmentFormData?.courseFullName
                                                            }
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Paper>

                                <Box
                                    sx={{
                                        justifyContent: "flex-end",
                                        backgroundColor: "grey.200",
                                        borderRadius: "0 0 15px 15px",
                                        display: "flex",
                                        height: 45,
                                    }}
                                ></Box>
                            </div>
                        </Box>
                    </Grow>
                </>
            )}
            {!smUp && (
                <>
                    <Box sx={{ marginTop: 2 }}>
                        <div>
                            <Paper
                                elevation={2}
                                sx={{
                                    width: "100%",
                                    height: 200,
                                    backgroundSize: "contain",
                                    backgroundSize: "cover",
                                    backgroundImage: `url(${"https://wallpapers.com/images/featured/dark-blue-1u6lldkvi9buf4rl.webp"})`,
                                    borderTopRightRadius: 20,
                                    borderTopLeftRadius: 20,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Stack direction={"column"}>
                                        <Box paddingTop={2}>
                                            <Stack direction={"column"}>
                                                <Box
                                                    sx={{
                                                        marginTop: 1,
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <Paper
                                                        sx={{
                                                            borderRadius: 4,
                                                            height: 70,
                                                            width: 70,
                                                            backgroundColor:
                                                                "#fff",
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            ":hover": {
                                                                boxShadow: 20, // theme.shadows[20]
                                                                backgroundColor:
                                                                    "grey.300",
                                                            },
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                overflow:
                                                                    "hidden",
                                                            }}
                                                        >
                                                            <img
                                                                src={cvimage}
                                                            />
                                                        </Box>
                                                    </Paper>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        variant="h4"
                                                        sx={{
                                                            mb: 1,
                                                            color: "#fff",
                                                            fontSize: 17,
                                                        }}
                                                    >
                                                        {
                                                            assessmentFormData?.name
                                                        }
                                                    </Typography>
                                                    <Stack
                                                        direction={"column"}
                                                        spacing={1}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Typography
                                                                // variant="h6"
                                                                color="#fff"
                                                                textTransform="capitalize"
                                                            >
                                                                {
                                                                    assessmentFormData?.courseFullName
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Paper>

                            <Box
                                sx={{
                                    justifyContent: "flex-end",
                                    backgroundColor: "grey.200",
                                    borderRadius: "0 0 15px 15px",
                                    display: "flex",
                                    height: 45,
                                }}
                            ></Box>
                        </div>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ProfileHeader;
