import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";
import TutorAssignForm from "./TutorAssignForm";
import { useDispatch } from "react-redux";
import {
    getSubjects,
    getWiseAppStudents,
} from "../../../../../redux/features/croSlice";
import { Icon } from "@iconify/react";

export const AssignTutorPopup = ({ params }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
        setTimeout(() => {
            dispatch(getSubjects());
            dispatch(getWiseAppStudents());
        }, 500);
    };
    return (
        <div>
            <GlobalDialog
                title="Assign Tutor"
                open={open}
                setOpen={setOpen}
                maxWidth={"md"}
                fullWidth
                closeButton={true}
                popupButton={
                    <Button
                        size="small"
                        sx={{ backgroundColor: "#2f72b34f" }}
                        onClick={handleClick}
                    >
                        <Icon icon="iwwa:assign" height={22} width={22} />
                        Assign Tutor
                    </Button>
                }
                content={<TutorAssignForm params={params} setOpen={setOpen} />}
            />
        </div>
    );
};
