export const LOGS_TABLE_HEAD = [
    // {
    //     field: "serialNumber",
    //     flex: 0,
    //     Width: 90,
    //     headerName: "slno",
    //     type: "number",
    //     headerAlign: "left",
    //     align: "left",
    // },
    // {
    //     field: "taskLevel",
    //     flex: 0,
    //     Width:90,
    //     headerName: "Task Level",
    //     type: "number",
    //     headerAlign: "left",
    //     align: "left",
    //   },
    {
        field: "cameOn",
        flex: 1,
        Width: 90,
        headerName: "Came on",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "taskInHand",
        flex: 0,
        Width: 90,
        headerName: "Task in Hand",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "approverName",
        flex: 0,
        Width: 90,
        headerName: "Approver Name",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "approvalStatus",
        flex: 0,
        Width: 90,
        headerName: "Approval Status",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "remarks",
        flex: 0,
        Width: 90,
        headerName: "Remarks",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "lastUpdatedOn",
        flex: 1,
        Width: 90,
        headerName: "Last Updated On",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
];
