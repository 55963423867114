import React from "react";

const MarkAttendanceStyle = (params) => {
    if (params.formattedValue === "A") {
        return (
            <div style={{ color: "red", fontWeight: "bold" }}>
                {params.formattedValue}
            </div>
        );
    }
    if (params.formattedValue === "P") {
        return (
            <div style={{ color: "green", fontWeight: "bold" }}>
                {params.formattedValue}
            </div>
        );
    }
    if (params.formattedValue === "WFH" || params.formattedValue === "WO") {
        return (
            <div style={{ color: "blue", fontWeight: "bold" }}>
                {params.formattedValue}
            </div>
        );
    }
};

export default MarkAttendanceStyle;
