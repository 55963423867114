import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    userGenderData: [],
    departmentEmployeesCountByReportingHead: [],
    departmentEmployeesCountByDepartment: [],
    DepartmentEployeesDetails: [],
    allAttendanceByDay: [],
    leaveCountwithDateDurationData: [],
};

export const getUserGenterData = createAsyncThunk(
    "dashboardAnalytics/getUserGenterData",
    async () => {
        const res = await apiRequest({
            url: "userData/userGenderDataCount",
            method: "get",
        });

        return res;
    }
);
export const getDepartmentEmployeesCountByReportingHead = createAsyncThunk(
    "dashboardAnalytics/getDepartmentEmployeesCountByReportingHead",
    async () => {
        const res = await apiRequest({
            url: "userData/getDepartmentEmployeesCountByReportingHead",
            method: "get",
        });

        return res;
    }
);
export const getDepartmentEmployeesCountByDepartment = createAsyncThunk(
    "dashboardAnalytics/getDepartmentEmployeesCountByDepartment",
    async () => {
        const res = await apiRequest({
            url: "userData/getDepartmentEmployeesCountByDepartment",
            method: "get",
        });

        return res;
    }
);

export const getDepartmentEmployeesDetails = createAsyncThunk(
    "dashboardAnalytics/getDepartmentEmployeesDetails",
    async (data) => {
        const res = await apiRequest({
            url: "userData/getDepartmentEployeesDetails",
            method: "post",
            data,
        });

        return res;
    }
);

export const getAllAttendanceByDay = createAsyncThunk(
    "dashboardAnalytics/getAllAttendanceByDay",
    async (data) => {
        const res = await apiRequest({
            url: "userData/totalAttendance",
            method: "post",
            data,
        });

        return res;
    }
);
export const leaveCountwithDateDuration = createAsyncThunk(
    "dashboardAnalytics/leaveCountwithDateDuration",
    async (data) => {
        const res = await apiRequest({
            url: "userData/leaveCountwithDateDuration",
            method: "post",
            data,
        });

        return res;
    }
);

const dashboardSlice = createSlice({
    name: "dashboardAnalytics",
    initialState,
    reducers: {
        clearEmplyeeData: (state, action) => {
            return {
                ...state,
                DepartmentEployeesDetails: [],
            };
        },
    },

    extraReducers: (builder) => {
        builder.addCase(getUserGenterData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getUserGenterData.fulfilled, (state, action) => {
            const data = action.payload.data?.users[0];
            return {
                ...state,
                userGenderData: data,
                loading: false,
            };
        });
        // -------------------------------getDepartmentEmployeesCount-----------------------
        builder.addCase(
            getDepartmentEmployeesCountByReportingHead.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getDepartmentEmployeesCountByReportingHead.fulfilled,
            (state, action) => {
                const data = action.payload.data.count;
                return {
                    ...state,
                    departmentEmployeesCountByReportingHead: data,
                    loading: false,
                };
            }
        );
        // -----------------------------getDepartmentEmployeesCountByDepartment---------------
        builder.addCase(
            getDepartmentEmployeesCountByDepartment.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getDepartmentEmployeesCountByDepartment.fulfilled,
            (state, action) => {
                const data = action.payload.data.count;
                return {
                    ...state,
                    departmentEmployeesCountByDepartment: data,
                    loading: false,
                };
            }
        );
        // --------------------getDepartmentEmployeesDetails------------------------------

        builder.addCase(
            getDepartmentEmployeesDetails.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getDepartmentEmployeesDetails.fulfilled,
            (state, action) => {
                const data = action.payload.data.departmentEmp;
                return {
                    ...state,
                    DepartmentEployeesDetails: data,
                    loading: false,
                };
            }
        );
        // ---------------getAllAttendanceByDay----------------------------
        builder.addCase(getAllAttendanceByDay.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAllAttendanceByDay.fulfilled, (state, action) => {
            const data = action.payload.data.attendance;
            return {
                ...state,
                allAttendanceByDay: data,
                loading: false,
            };
        });
        // ---------------------------leaveCountwithDateDuration----------------------
        builder.addCase(leaveCountwithDateDuration.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            leaveCountwithDateDuration.fulfilled,
            (state, action) => {
                const data = action.payload.data.atsData;
                return {
                    ...state,
                    leaveCountwithDateDurationData: data,
                    loading: false,
                };
            }
        );
    },
});
export const { clearEmplyeeData } = dashboardSlice.actions;
export default dashboardSlice.reducer;
