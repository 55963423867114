import { Avatar, Box, Button, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import profileImg from "./profileimg.jpg";
import { Icon } from "@iconify/react";
import IconPaper from "./IconPaper";
import dayjs from "dayjs";
import { StudentsDetailstable } from "./StudentDetailsList";

const studentDetails = {
  studentImag: profileImg,
  name: "Test Student",
  place: "Malappuram",
  email: "test@gmail.com",
  phone: "9876543189",
  ogaName: "Test OGA sdd he h hhhfheh hi",
  leadDate: dayjs().format("YYYY-MM-DD"),
};

const StudentProfile = () => {
  return (
    <Box>
      <Paper elevation={2} sx={{ borderRadius: 3 }}>
        <Box padding={2}>
          <Typography
            variant="body2"
            sx={{ fontWeight: "bolder", fontSize: 12 }}
          >
            Student Profile
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: 64,
              height: 64,
              borderRadius: "50%",
              background: `linear-gradient(to bottom, #702dff 0%, #702dff 10%, white 100%)`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="studentimg"
              src={profileImg}
              sx={{ width: 56, height: 56, border: "2px solid white" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mt={1}>
            <Stack direction={"column"} spacing={0}>
              <Typography sx={{ fontSize: 15, fontWeight: "bolder" }}>
                Test Student
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ opacity: 0.5 }} variant="caption">
                  Malappuram
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        {/* ----------------------------------------------------------------------- */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <IconPaper>
              <Icon icon="ic:baseline-whatsapp" height={23} width={23} />
            </IconPaper>
            <IconPaper>
              <Icon icon="ic:outline-alternate-email" height={23} width={23} />
            </IconPaper>
            <IconPaper>
              <Icon
                icon="material-symbols:school-outline"
                height={23}
                width={23}
              />
            </IconPaper>
          </Stack>
        </Box>
        {/* -----------------------------details------------------------------------------ */}
        <Box>
          <Box padding={2}>
            <Typography sx={{ fontSize: 11, fontWeight: "bolder" }}>
              More Details
            </Typography>
          </Box>
          <Box>
            <StudentsDetailstable data={studentDetails} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Button
            sx={{
              color: "white",
              background: `linear-gradient(to bottom, #0089d0 0%, #0089d0 10%, #a125c2 100%)`,
              borderRadius: 3,
            }}
          >
            <Icon icon="mdi:file-report-outline" height={22} width={22} />
            View Assessment Report
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default StudentProfile;
