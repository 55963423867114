import React, { useEffect } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Autocomplete, Box, Button, Stack, TextField } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { fetchStudents } from "../../../../redux/features/studentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCourseEnrollments } from "../../../../redux/features/sessionSlice";
import GlobalSelectField from "../../../mui/GlobalSelectField";
import {
    getClasses,
    getMediums,
    getSyllabus,
    getInvoicesByCourseEnrollment,
    addCourseEnrollmentDetailed,
    getDetailedCourseEnrollementsByStudent,
    getDetailedCourseEnrollementsByCourse,
} from "../../../../redux/features/croSlice";
import { getClassPreData } from "../../../../redux/features/mastersSlice";
import { getProjectPackagesPreData } from "../../../../redux/features/mastersSlice";
import {
    AddButton,
    CancelButton,
    SaveButton,
    UpdateButton,
} from "../../../mui/Buttons/Buttons";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import CollapseList from "../../../CollapseList";
import { EditCourseEnrollmentDetailed } from "./CroLeads/EditCourseEnrollmentDetailed";
import OgaRemarksDetailedView from "./OgaRemarksDetailedView";

export const AddCourseEnrollmentDetailed = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [filteredClass, setFilteredClass] = React.useState([]);
    const [filteredPackeges, setFilteredPackages] = React.useState([]);
    const { data } = useSelector((state) => state.students);
    const { courseEnrollments } = useSelector((state) => state.sessions);
    const { classPreData } = useSelector((state) => state.masters);

    const {
        classes,
        mediums,
        syllabuses,
        invoicesByCourseEnrollmentPreData,
        loading,
        detailedCourseEnrollementsByStudent,
        detailedCourseEnrollementsByCourse,
    } = useSelector((state) => state.cro);
    const { projectPackagesPreData } = useSelector((state) => state.masters);

    useEffect(() => {
        dispatch(
            getDetailedCourseEnrollementsByCourse(params.courseEnrollmentId)
        );
        console.log(
            "detailedCourseEnrollementsByCourse",
            detailedCourseEnrollementsByCourse
        );
    }, [params]);

    const Schema = Yup.object().shape({
        // addNo: Yup.string().required("addNo is required"),
        courseEnrollmentId: Yup.string().required(
            "courseEnrollmentId is required"
        ),
        classId: Yup.string().required("classId is required"),
        // levelTypeId: Yup.string().required("levelTypeId is required"),
        mediumId: Yup.string().required("mediumId is required"),
        syllabusId: Yup.string().required("syllabusId is required"),
        packageId: Yup.string().required("packageId is required"),
        sessionPurchaseType: Yup.string().required(
            "sessionPurchaseType is required"
        ),
        noOfSessions: Yup.string().required("noOfSessions is required"),
        // invoiceId: Yup.string().required("invoiceId is required"),
        // salesAmount: Yup.string().required("salesAmount is required"),
        // admissionFeeAmount: Yup.string().required(
        //     "admissionFeeAmount is required"
        // ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            addNo: params.admissionNo,
            courseEnrollmentId: params.courseEnrollmentId || "",
            classId: "",
            levelTypeId: "",
            mediumId: "",
            syllabusId: "",
            packageId: "",
            sessionPurchaseType: "",
            noOfSessions: "",
            invoiceId: null,
            salesAmount: "",
            admissionFeeAmount: "",
        },
        validationSchema: Schema,
        onSubmit: (values) => {
            dispatch(addCourseEnrollmentDetailed(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });

    useEffect(() => {
        dispatch(getInvoicesByCourseEnrollment());
        dispatch(getClasses());
    }, [params.courseEnrollmentId]);

    useEffect(() => {
        const findObjectById = (array, courseEnrollmentId) => {
            for (let i = 0; i < array.length; i++) {
                if (array[i].courseEnrollmentId === courseEnrollmentId) {
                    return array[i];
                }
            }
            return null;
        };

        const objectIdToFind = params.courseEnrollmentId;
        const foundObject = findObjectById(courseEnrollments, objectIdToFind);

        // if (foundObject) {
        const courseId = foundObject?.courseId;

        const filteredArray = classPreData.filter(
            (obj) => obj.courseAvailableId === courseId
        );
        setFilteredClass([...filteredArray]);
        // console.log("filteredArray======", filteredArray);
        // }
        //  else {
        //     setFilteredClass([]);
        // }

        // -------------------------------------------------------------
        const ProId = params.projectId;

        const filteredPackage = projectPackagesPreData.filter(
            (obj) => obj.projectId === ProId
        );
        setFilteredPackages(filteredPackage);
    }, [classPreData && courseEnrollments]);

    const levelData = [
        { id: 1, level: "Multi Level" },
        { id: 4, level: "Single Level" },
    ];
    const purchaseType = [
        { id: 5, type: "Primary Sessions" },
        { id: 6, type: "Additional Subject" },
        { id: 7, type: "Additional Sessions" },
    ];

    return (
        <div>
            <GlobalDialog
                title="Add Course Enrollment Detailed"
                fullWidth
                maxWidth={"sm"}
                open={open}
                setOpen={setOpen}
                closeButton={true}
                popupButton={
                    <Box>
                        {detailedCourseEnrollementsByCourse ? (
                            <UpdateButton
                                size="small"
                                action={() => {
                                    dispatch(getClasses());
                                    dispatch(getMediums());
                                    dispatch(getSyllabus());
                                    dispatch(getProjectPackagesPreData());

                                    dispatch(getClassPreData());

                                    setOpen(true);
                                }}
                            >
                                Update
                            </UpdateButton>
                        ) : (
                            <AddButton
                                action={() => {
                                    dispatch(fetchStudents());
                                    dispatch(getClasses());
                                    dispatch(getMediums());
                                    dispatch(getSyllabus());
                                    dispatch(getProjectPackagesPreData());
                                    dispatch(
                                        getCourseEnrollments(params.admissionNo)
                                    );
                                    dispatch(getClassPreData());

                                    setOpen(true);
                                }}
                                size="small"
                            >
                                Add
                            </AddButton>
                        )}
                    </Box>
                }
                content={
                    <>
                        {params.syllabusName ? (
                            <CollapseList
                                label="OGA Details"
                                content={
                                    <>
                                        <TextField
                                            label="OGA"
                                            value={params.oga}
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="OGA Remarks"
                                            value={params.ogaRemarks}
                                            fullWidth
                                            disabled
                                            multiline
                                            rows={3}
                                        />
                                        <TextField
                                            label="OGA Assessment Remarks"
                                            value={params.ogaAssessmentRemarks}
                                            fullWidth
                                            disabled
                                            multiline
                                            rows={3}
                                        />
                                        <TextField
                                            label="Fee Per Session"
                                            value={
                                                params.ogaRemarksFeePerSession
                                            }
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="Class Per Week"
                                            value={
                                                params.ogaRemarksClassPerWeek
                                            }
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="Time Preferred"
                                            value={
                                                params.ogaRemarksTimePreferred
                                            }
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="Trainer Preferred"
                                            value={
                                                params.ogaRemarksTrainerPreferred
                                            }
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="Total Sessions"
                                            value={
                                                params.ogaRemarksTotalSessions
                                            }
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="Total Fee"
                                            value={params.ogaRemarksTotalFee}
                                            fullWidth
                                            disabled
                                        />
                                    </>
                                }
                            />
                        ) : null}
                        {detailedCourseEnrollementsByCourse ? (
                            <EditCourseEnrollmentDetailed
                                courseEnrollmentId={params.courseEnrollmentId}
                                setOpen={setOpen}
                                data={detailedCourseEnrollementsByCourse}
                            />
                        ) : (
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box
                                        sx={{
                                            padding: 2,
                                            backgroundColor: "grey.200",
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Stack direction={"column"} spacing={2}>
                                            <TextField
                                                disabled
                                                fullWidth
                                                label="Student Name"
                                                value={params.studentName}
                                            />
                                            <GlobalSelectField
                                                options={{
                                                    name: "courseEnrollmentId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                    },
                                                    label: "Course Enrollment",
                                                    data: courseEnrollments,
                                                    keys: {
                                                        id: "courseEnrollmentId",
                                                        value: "courseName",
                                                    },
                                                }}
                                                disabled
                                            />
                                            <GlobalSelectField
                                                options={{
                                                    name: "classId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                    },
                                                    label: "Class",
                                                    data: filteredClass,
                                                    keys: {
                                                        id: "id",
                                                        value: "className",
                                                    },
                                                }}
                                            />
                                            {params.projectId === 2 ? (
                                                <GlobalSelectField
                                                    options={{
                                                        name: "levelTypeId",
                                                        formik,
                                                        sx: {
                                                            width: "100%",
                                                        },
                                                        label: "Level Type",
                                                        data: levelData,
                                                        keys: {
                                                            id: "id",
                                                            value: "level",
                                                        },
                                                    }}
                                                />
                                            ) : null}

                                            <GlobalSelectField
                                                options={{
                                                    name: "mediumId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                    },
                                                    label: "Medium",
                                                    data: mediums,
                                                    keys: {
                                                        id: "id",
                                                        value: "name",
                                                    },
                                                }}
                                            />
                                            <GlobalSelectField
                                                options={{
                                                    name: "syllabusId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                    },
                                                    label: "Syllabus",
                                                    data: syllabuses,
                                                    keys: {
                                                        id: "id",
                                                        value: "name",
                                                    },
                                                }}
                                            />
                                            <GlobalSelectField
                                                options={{
                                                    name: "packageId",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                    },
                                                    label: "Package",
                                                    data: filteredPackeges,
                                                    keys: {
                                                        id: "id",
                                                        value: "name",
                                                    },
                                                }}
                                            />
                                            <GlobalSelectField
                                                options={{
                                                    name: "sessionPurchaseType",
                                                    formik,
                                                    sx: {
                                                        width: "100%",
                                                    },
                                                    label: "Session Purchase Type",
                                                    data: purchaseType,
                                                    keys: {
                                                        id: "id",
                                                        value: "type",
                                                    },
                                                }}
                                            />

                                            <TextField
                                                label="No Of Sessions"
                                                name="noOfSessions"
                                                type="number"
                                                fullWidth
                                                onChange={formik.handleChange}
                                                value={
                                                    formik.values.noOfSessions
                                                }
                                                error={
                                                    formik.touched
                                                        .noOfSessions &&
                                                    Boolean(
                                                        formik.errors
                                                            .noOfSessions
                                                    )
                                                }
                                                helperText={
                                                    formik.errors.noOfSessions
                                                }
                                            />

                                            {/* <GlobalSelectField
                                            options={{
                                                name: "invoiceId",
                                                formik,
                                                sx: {
                                                    width: "100%",
                                                },
                                                label: "Invoice",
                                                data: invoicesByCourseEnrollmentPreData,
                                                keys: {
                                                    id: "id",
                                                    value: "name",
                                                },
                                            }}
                                        /> */}
                                            {/* <TextField
                                            label="Sales Amount"
                                            name="salesAmount"
                                            fullWidth
                                            type="number"
                                            onChange={formik.handleChange}
                                            value={formik.values.salesAmount}
                                            error={
                                                formik.touched.salesAmount &&
                                                Boolean(
                                                    formik.errors.salesAmount
                                                )
                                            }
                                            helperText={
                                                formik.errors.salesAmount
                                            }
                                        /> */}
                                            {/* <TextField
                                            label="Admission Fee Amount"
                                            name="admissionFeeAmount"
                                            type="number"
                                            fullWidth
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values.admissionFeeAmount
                                            }
                                            error={
                                                formik.touched
                                                    .admissionFeeAmount &&
                                                Boolean(
                                                    formik.errors
                                                        .admissionFeeAmount
                                                )
                                            }
                                            helperText={
                                                formik.errors.admissionFeeAmount
                                            }
                                        /> */}
                                        </Stack>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                mt: 2,
                                            }}
                                        >
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <CancelButton
                                                    action={() =>
                                                        setOpen(false)
                                                    }
                                                >
                                                    Cancel
                                                </CancelButton>
                                                <SaveButton type="submit">
                                                    {loading
                                                        ? "Saving..."
                                                        : "Save"}
                                                </SaveButton>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        )}

                        {/* ------------------------------------------------------------------------- */}
                    </>
                }
            />
        </div>
    );
};
