export const MY_STUDENTS_TABLE_HEAD = [
  {
    field: "serialNumber",
    headerName: "No.",
    width: 120,
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "admissionNumber",
    headerName: "Admn.No",
    width: 120,
    type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "student",
    headerName: "Name",
    width: 250,
    type: "string",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "subjectEnrollmentId",
    headerName: "SubEnId",
    width: 150,
    type: "number",
    headerAlign: "left",
    align: "left",
  },
];
