import { Stack } from "@mui/material";
import React from "react";
import Page from "../../../../components/Page";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import {
    Box,
    Paper,
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    clearStates,
    createTableHead,
    getAllAttendance,
} from "../../../../redux/features/attendanceSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getPreData } from "../../../../redux/features/userSlice";

import MarkAttendanceStyle from "../../../../components/datagrid/MarkAttendanceStyle";
import {
    END_PORTION_TABLE_HEAD,
    START_PORTION_TABLE_HEAD,
} from "../../../../components/datagrid/Attendance/AllAttendanceTableHead";
import Loader from "../../../../components/Loader/Loader";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import useResponsive from "../../../../Hooks/useResponsive";
import dayjs from "dayjs";

export default function AllAttendance() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const attendances = useSelector((state) => state.attendance);
    const [gridSize, setGridSize] = useState(5);
    const [tableHead, setTableHead] = useState([]);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState("");
    const [department, setDepartment] = React.useState("");
    let { preData } = useSelector((state) => state.users);
    const [date, setDate] = React.useState();

    // ----------------------useEffect-------------------------------

    useEffect(() => {
        let newValue = new Date();
        setValue(newValue);
        setDepartment(0);
        const yer = `${newValue.toISOString().split("T")[0].split("-")[0]}/${
            newValue.toISOString().split("T")[0].split("-")[1]
        }`;
        setDate(yer);

        dispatch(getPreData());
        dispatch(createTableHead(yer));
        const data = { date: yer, dep: 0 };
        dispatch(getAllAttendance(data));
        return () => dispatch(clearStates());
    }, []);

    useEffect(() => {
        const table = attendances.tableHead.map((item, i) => {
            if (item.day) {
                item = {
                    ...item,
                    renderCell: (params) => MarkAttendanceStyle(params),
                };
            }
            return item;
        });

        setTableHead([
            ...START_PORTION_TABLE_HEAD,
            ...table,
            ...END_PORTION_TABLE_HEAD,
        ]);
    }, [attendances.tableHead]);

    // ------------------------onChange---------------------------------------

    const handleChange = (newValue) => {
        setValue(newValue);
        let yer = `${newValue.toISOString().split("T")[0].split("-")[0]}/${
            newValue.toISOString().split("T")[0].split("-")[1]
        }`;
        if (newValue !== null && JSON.stringify(newValue.$d) !== "null") {
            const formated = newValue.toISOString().split("T")[0];
        }
        setDepartment("");
        setDate(yer);
    };

    const handleDepChange = (e) => {
        setDepartment(e.target.value);
        const data = { date: date, dep: e.target.value };
        dispatch(getAllAttendance(data));
        dispatch(createTableHead(date));
    };

    // -------------------------------------------------------------------------
    return (
        <div>
            <Wrapper
                title="All Attendance"
                Content={
                    <Page>
                        {attendances.loading ? (
                            <Loader />
                        ) : (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: smUp
                                            ? "flex-end"
                                            : "center",
                                    }}
                                >
                                    <Box sx={{ padding: 2 }}>
                                        <Stack
                                            direction={smUp ? "row" : "column"}
                                            spacing={2}
                                        >
                                            <Box>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        views={[
                                                            "year",
                                                            "month",
                                                        ]}
                                                        label="Year and Month"
                                                        inputFormat="YYYY/MM"
                                                        disableFuture
                                                        value={dayjs(value)}
                                                        onChange={handleChange}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                fullWidth
                                                                {...params}
                                                                helperText={
                                                                    null
                                                                }
                                                                variant="filled"
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: 220,
                                                    marginLeft: 2,
                                                }}
                                            >
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Select Department
                                                    </InputLabel>
                                                    <Select
                                                        name="deptId"
                                                        value={department}
                                                        label="Department"
                                                        variant="filled"
                                                        fullWidth
                                                        onChange={
                                                            handleDepChange
                                                        }
                                                    >
                                                        <MenuItem value={0}>
                                                            All
                                                        </MenuItem>
                                                        ,
                                                        {preData.departments?.map(
                                                            (item) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={
                                                                            item.departmentName
                                                                        }
                                                                        value={
                                                                            item.departmentId
                                                                        }
                                                                    >
                                                                        {
                                                                            item.departmentName
                                                                        }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Box>
                                <Paper
                                    elevation={3}
                                    sx={{
                                        borderRadius: 1,
                                        width: "100%",
                                        marginTop: 1,
                                    }}
                                >
                                    <Box sx={{ width: "100%" }}>
                                        <DataGrid
                                            loading={attendances.loading}
                                            rows={attendances.data}
                                            columns={tableHead}
                                            sx={{
                                                borderTopLeftRadius: 0,
                                                borderTopRightRadius: 0,
                                            }}
                                            pageSize={gridSize}
                                            onPageSizeChange={(newGridSize) =>
                                                setGridSize(newGridSize)
                                            }
                                            autoHeight
                                            rowsPerPageOptions={[5, 10, 25, 50]}
                                            getRowId={(row) => row.userId}
                                            components={{
                                                Toolbar: CustomGridToolbar,
                                            }}
                                            componentsProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                    quickFilterProps: {
                                                        debounceMs: 500,
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>
                                </Paper>
                            </>
                        )}
                    </Page>
                }
            />
        </div>
    );
}
