import { Chip } from "@mui/material";
import { CreateStudent } from "./CreateStudent";
import { EnrollemtFormView } from "../../../../myworkspace/cro/RenderCellMain";
import { Icon } from "@iconify/react";

export const STUDENT_ENROLLMENT_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "SlNo",
        width: 50,
    },

    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
    {
        field: "email",
        headerName: "Email",
        width: 200,
    },

    {
        field: "student_mob_no",
        headerName: "student_mob_no",
        width: 150,
    },
    {
        field: "Status",
        headerName: "Status",
        width: 150,
        renderCell: (params) => {
            return (
                <Chip
                    size="small"
                    variant="outlined"
                    icon={
                        params.row.wiseUserId ? (
                            <Icon icon="charm:tick" />
                        ) : (
                            <Icon icon="bitcoin-icons:cross-filled" />
                        )
                    }
                    color={params.row.wiseUserId ? "success" : "error"}
                    label={params.row.wiseUserId ? "Created" : "Not Created"}
                />
            );
        },
    },

    {
        field: "Create ",
        headerName: "Create",
        width: 150,
        renderCell: (params) => {
            if (params.row.wiseUserId) {
                return null;
            } else {
                return <CreateStudent params={params.row} />;
            }
        },
    },
    {
        field: "SubjectEnrollment ",
        headerName: "SubjectEnrollment",
        width: 150,
        renderCell: (params) => {
            if (params.row.wiseUserId) {
                return <EnrollemtFormView {...params.row} />;
            } else {
                return null;
            }
        },
    },
];
