import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
    const { login } = useSelector((state) => state);

    return login.data.isLogin ? children : <Navigate to="/auth/login" />;
};

export default ProtectedRoute;
