import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box } from "@mui/material";
import { PAYMENT_INVOICES_TABLE_HEAD as columns } from "../../../../components/datagrid/myworkspace/ogd/invoice/PaymentInvoicesTableHead";
import { Actions } from "./Actions";
import dayjs from "dayjs";
import DatePickerCustom from "../../../../components/DatePicker/DatePickerCustom";
import { getInvoice, getScreenshot } from "../../../../redux/features/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import AddOrUpdateInvoice from "../../../../components/datagrid/myworkspace/ogd/invoice/AddOrUpdateInvoice";
import GlobalDatagrid from "../../../../components/mui/DataGrid/GlobalDatagrid";
import { savePaginationData } from "../../../../redux/features/globalDatagridSlice";
import ActionDetails from "../../../../components/mui/GlobalAnchor/ActionDetails";
import CourseDetails from "./CourseDetails";
import GlobalAnchorList from "../../../../components/mui/GlobalAnchor/GlobalAnchorList";
import ViewScreenShot from "./ViewScreenShot";
import DeleteInvoice from "../../../../components/datagrid/myworkspace/ogd/invoice/DeleteInvoice";
import InvoicePrint from "./InvoicePrint";


export default function InvoiceUpdatedPagination() {
  const [selectedDate, setSelectedDate] = useState(dayjs().format("YYYY-MM"));
  const dispatch = useDispatch();
  const { loading, invoices, rowCount } = useSelector((store) => store.invoice);
  const { paginationData } = useSelector((state) => state.globalDataGrid)
  const { gridSize, pageNum, rowData, openAnchor, search } = paginationData

  useEffect(() => {
    dispatch(savePaginationData({ ...paginationData, pageNum: 1 }))
  }, [selectedDate,search])

  useEffect(() => {
    dispatch(getInvoice({ selectedDate,pageNumber: pageNum, pageLimit:gridSize,searchValue:search }));
  }, [gridSize, pageNum,selectedDate,search]);



  const paginationDetails = {
    pageNumber: pageNum,
    pageLimit: gridSize,
    selectedDate: selectedDate,
    searchValue: search,
  };

  return (
    <div>
      <Wrapper
        title="Payment Invoices"
        Content={
          <>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <AddOrUpdateInvoice />
            </Box>
            <Box>
              <DatePickerCustom
                value={dayjs(selectedDate)}
                setValue={(date) => setSelectedDate(date.format("YYYY-MM"))}
                viewDetails={["year", "month"]}
              />
            </Box>

            <Box sx={{mt:2}}>
            <GlobalDatagrid
             tableHead={columns}
             rows={invoices}
             meta={rowCount}
             rowUniqueId="sl_no"
             loading={loading}
             rowClick={true}
             actionComponent={
              <>
                 <ActionDetails val={rowData?.student_name} />
                 <GlobalAnchorList label="Selected Courses" component={ <CourseDetails invoiceId={rowData?.invoice_id} />} />
                 <GlobalAnchorList label="Payment" component={  <ViewScreenShot pageName="invoice" api={getScreenshot(rowData?.invoice_screenshot)} />} />
                 <GlobalAnchorList label="Edit" component={   <AddOrUpdateInvoice  paginationDetails={paginationDetails} row={rowData} />} />
                 <GlobalAnchorList label="Delete" component={ <DeleteInvoice isVerified={rowData?.invoice_accountant_verification} paginationDetails={paginationDetails} invoiceId={rowData?.invoice_id} />} />
                 <GlobalAnchorList label="Invoice" component={<InvoicePrint isVerified={rowData?.invoice_accountant_verification} invoiceId={rowData?.invoice_id}/>} />
              </>
            }
            />
            </Box>
          </>
        }
      />
    </div>
  );
}
