import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Link, Drawer, Typography, Avatar, Skeleton } from "@mui/material";
// mock
// hooks
import useResponsive from "../../../src/Hooks/useResponsive";
// components

import Logo from "../../components/color-utils/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../NavSection";

//
import { useSelector, useDispatch } from "react-redux";

import { clearRoles, getNavConfig } from "../../redux/features/roleSlice";

import { shouldForwardProp } from "@chakra-ui/react";
import SmallNavSection from "./SmallNavSection";
import { motion } from "framer-motion";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const DRAWER_SM_WIDTH = 90;

const RootStyle = styled(
  "div",
  shouldForwardProp((props) => props !== "isopensidebar")
)(({ theme, isopensidebar }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: isopensidebar === "true" ? DRAWER_WIDTH : DRAWER_SM_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  user,
}) {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state);
  const color = useSelector((state) => state.settings.sideBarSettings);
  const { pathname } = useLocation();
  // const dispatch = useDispatch()

  const isDesktop = useResponsive("up", "lg");

  // useEffect(() => {
  //     if (isOpenSidebar) {
  //         onCloseSidebar();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  useEffect(() => {
    dispatch(getNavConfig());
    return () => dispatch(clearRoles());
  }, []);

  const keyframesExample = {
    hidden: { opacity: 0, x: -100 },
    halfway: { opacity: 0.5, x: 50 },
    visible: { opacity: 1, x: 0 },
  };
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: "auto",
          display: "flex",
          flexDirection: "column",
          // background:
          //     color.bgColor === "default" ? "" : color.bgColor,
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          pb: 3,
          pt: 4,
          display: "inline-flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 7, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar
              src={`https://workspace.teaminterval.net/assets/employee/photo/passport_size/${user.image}.jpg`}
              alt="photoURL"
            />
            <Box sx={{ marginLeft: 1 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  color: color.bgColor === "default" ? "text.primary" : "white",
                }}
              >
                {user.name}
              </Typography>
              <Typography
                variant="subtitle3"
                sx={{
                  color:
                    color.bgColor === "default" ? "text.secondary" : "white",
                  textTransform: "capitalize",
                  width: "100%",
                  fontSize: "14px",
                }}
              >
                {user.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      {!role.loading ? (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={keyframesExample}
        >
          <NavSection navConfig={role.navConfig} />
        </motion.div>
      ) : (
        <Skeleton variant="rectangular" width={"100%"} height={500} />
      )}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  const smallNavRenderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ marginTop: 3, padding: 1 }}>
        <img
          src="https://intervaledu.com/static/web/images/logo/logo-dark.png"
          alt="logo"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 3,
        }}
      >
        <Avatar
          src={`https://workspace.teaminterval.net/assets/employee/photo/passport_size/${user.image}.jpg`}
          alt="photoURL"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: 1,
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: 9,
            color: color.bgColor === "default" ? "text.primary" : "white",
          }}
        >
          {user.name}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingTop: "100px",
        }}
      >
        {!role.loading ? (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={keyframesExample}
          >
            <SmallNavSection navConfig={role.navConfig} />
          </motion.div>
        ) : (
          <Skeleton variant="rectangular" width={"100%"} height={500} />
        )}
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle isopensidebar={`${isOpenSidebar}`}>
      {!isDesktop ? (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      ) : isOpenSidebar ? (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              background:
                color.bgColor === "default"
                  ? "background.paper"
                  : color.bgColor,
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_SM_WIDTH,
              bgcolor: "background.paper",
              borderRightStyle: "dashed",
            },
          }}
        >
          {smallNavRenderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
