import React, { useEffect, useMemo, useRef } from "react";
import GlobalDialog from "../../../components/mui/Dialogue/GlobalDialog";
import { Alert, Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { SCHEDULE_PREVIEW_TABLE_HEAD } from "../../../components/datagrid/schedules/SchedulePreviewTableHead";
import {
    checkPreferenceData,
    createSchedulePreference,
    getSchedulePreview,
} from "../../../redux/features/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { updatedPreview } from "../../../redux/features/sessionSlice";
import { useSnackbar } from "notistack";

export const SchedulePreview = ({ setOpen, open }) => {
    const { enqueueSnackbar } = useSnackbar();
    const schedules = useSelector((state) => state.sessions.schedulePreview);
    const updatedPreView = useSelector(
        (state) => state.sessions.updatedPreview
    );
    const preferenceAvailabilityData = useSelector(
        (state) => state.sessions.preferenceAvailabilityData
    );
    const subjectEnrollmentId = useSelector(
        (state) => state.sessions.subjectEnrollmentId
    );
    const checkPreferenceDatas = useSelector(
        (state) => state.sessions.checkPreferenceData
    );
    const [gridSize, setGridSize] = React.useState(100);
    const dispatch = useDispatch();

    const [tableRow, setTableRow] = React.useState([]);
    useEffect(() => {
        if (schedules) {
            dispatch(updatedPreview(schedules));
            setTableRow([...schedules]);
        }
    }, [schedules]);
    const loading = useSelector((state) => state.sessions.loading);

    useEffect(() => {
        if (preferenceAvailabilityData) {
            const id = preferenceAvailabilityData.sessionNo;
            const newData = [...tableRow];

            const i = newData.findIndex((x) => x.sessionNo === id);
            newData[i] = preferenceAvailabilityData;
            setTableRow(newData);
        }
    }, [preferenceAvailabilityData]);

    useEffect(() => {
        dispatch(checkPreferenceData(tableRow));
    }, [tableRow]);

    const data = {
        subjectEnrollmentId: subjectEnrollmentId,
        schedules: checkPreferenceDatas,
    };

    const filter = () => {
        let data = data?.schedules.filter((item) => {
            return item.isAvailable === 0;
        });
        console.log("filter data", data);
    };

    return (
        <div>
            <GlobalDialog
                title="Schedule Preview"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"lg"}
                closeButton={true}
                content={
                    <>
                        <Box sx={{ width: "100%", padding: 0 }}>
                            {updatedPreView ? (
                                <>
                                    <DataGrid
                                        // loading={datas.loading}
                                        autoHeight
                                        sx={{
                                            "& .MuiDataGrid-columnHeaderTitle":
                                                {
                                                    textOverflow: "clip",
                                                    whiteSpace: "break-spaces",
                                                    lineHeight: 1,
                                                },
                                        }}
                                        getRowHeight={() => "auto"}
                                        rows={tableRow}
                                        columns={SCHEDULE_PREVIEW_TABLE_HEAD}
                                        getRowId={(row) => row.sessionNo}
                                        pageSize={gridSize}
                                        rowsPerPageOptions={[
                                            5, 10, 25, 50, 100,
                                        ]}
                                        onPageSizeChange={(newGridSize) =>
                                            setGridSize(newGridSize)
                                        }
                                    />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button
                                            size="large"
                                            variant="contained"
                                            onClick={() => {
                                                dispatch(
                                                    createSchedulePreference(
                                                        data
                                                    )
                                                ).then((res) => {
                                                    if (
                                                        res.payload.status ===
                                                        "failed"
                                                    ) {
                                                        enqueueSnackbar(
                                                            res.payload.message,
                                                            { variant: "error" }
                                                        );
                                                    } else if (
                                                        res.payload.status ===
                                                        "success"
                                                    ) {
                                                        enqueueSnackbar(
                                                            res.payload.message,
                                                            {
                                                                variant:
                                                                    "success",
                                                            }
                                                        );
                                                        setOpen(false);
                                                    }
                                                });
                                            }}
                                        >
                                            {loading
                                                ? "Submitting..."
                                                : "submit preference"}
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <Box>
                                    <Alert severity="success">
                                        Schedules added successfully!!
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </>
                }
            />
        </div>
    );
};
