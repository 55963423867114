import React from "react";
import simpleLoader from "./SimpleLoader.gif";
import { Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import { useEffect } from "react";

export default function SimpleLoader({ sx }) {
  const [checked, setChecked] = React.useState(false);
  useEffect(() => {
    setChecked((prev) => !prev);
  }, []);
  return (
    <div>
      <Fade timeout={{ enter: 1000 }} in={checked}>
        <Box sx={sx}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20%",
            }}
          >
            <img height={50} width={50} src={simpleLoader} alt="loading..." />
          </Box>
        </Box>
      </Fade>
    </div>
  );
}
