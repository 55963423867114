import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

export const fetchCoursesOfStudent = createAsyncThunk(
    "courses/fetchCoursesOfStudent",
    async (subEnID) => {
        const res = await apiRequest({
            method: "GET",
            url: `courses/course-base/${subEnID}`,
        });
        //
        return formatDataForDataTable(res.data.courses);
    }
);

const coursesSlice = createSlice({
    name: "courses",
    initialState: {
        loading: false,
        data: [],
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCoursesOfStudent.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchCoursesOfStudent.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchCoursesOfStudent.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});
export default coursesSlice.reducer;
