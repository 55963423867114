import TooltipRemarkViewer from "./TooltipRemarkViewer";

export const SESSIONS_LOG_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
        width: 90,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "attendanceDate",
        headerName: "Session Date",
        width: 150,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "fromTime",
        headerName: "From",
        width: 100,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "toTime",
        headerName: "To",
        width: 100,
        headerAlign: "center",
        align: "center",
    },
    {
        field: "lessons",
        headerName: "Lessons",
        width: 300,
        headerAlign: "center",
        align: "center",
        headerAlign: "center",
    },
    {
        field: "trainerRemarks",
        headerName: "Remarks",
        width: 300,
        headerAlign: "center",
        renderCell: (params) => (
            <TooltipRemarkViewer value={params.row.trainerRemarks} />
        ),
    },
    {
        field: "trainerName",
        headerName: "Trainer Name",
        width: 150,
        headerAlign: "center",
        align: "center",
    },
];
