import {
    Container,
    Paper,
    Typography,
    Box,
    Stack,
    Chip,
    Button,
} from "@mui/material";
import React from "react";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import Page from "../../../../components/color-utils/Page";
import InterviewTimeLine from "./InterviewTimeLine";
import InterviewProcedureCards from "./InterviewProcedureCards";
import cvimage from "./cvimage.png";
import {
    getSingleJobApplication,
    getCvStatusPreData,
} from "../../../../redux/features/jobApplicationSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import ViewMoreDetails from "./ViewMoreDetails";
import useResponsive from "../../../../Hooks/useResponsive";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import { timeLine } from "../../../../redux/features/jobApplicationSlice";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Icon } from "@iconify/react";

export default function InterviewProcedure() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const singleData = useSelector((state) => state.jobApplication.single);
    const loading = useSelector((state) => state.jobApplication.loading);
    const timeline = useSelector((state) => state.jobApplication.timeline);
    const params = useParams();
    const dispatch = useDispatch();
    const [checked, setChecked] = React.useState(false);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }));

    useEffect(() => {
        setChecked((prev) => !prev);
    }, []);

    const onResumeClick = () => {
        window.open(singleData.resume);
    };
    useEffect(() => {
        dispatch(getSingleJobApplication(params.id));
        dispatch(timeLine(params.id));
    }, []);

    useEffect(() => {
        console.log(singleData);
    }, [singleData]);

    return (
        <div>
            <Page>
                <Container>
                    <Typography variant="h4">Interview Procedure</Typography>
                    <Breadcrumb sx={{ ml: 2 }} />
                    {smUp && (
                        <>
                            <Grow in={checked} timeout={{ enter: 1000 }}>
                                <Box sx={{ marginTop: 2 }}>
                                    <div>
                                        <Paper
                                            elevation={2}
                                            sx={{
                                                width: "100%",
                                                height: 200,
                                                backgroundSize: "contain",
                                                backgroundSize: "cover",
                                                backgroundImage: `url(${"https://wallpapers.com/images/featured/dark-blue-1u6lldkvi9buf4rl.webp"})`,
                                                borderTopRightRadius: 20,
                                                borderTopLeftRadius: 20,
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                        >
                                            <Stack direction={"row"}>
                                                <Box
                                                    paddingTop={14}
                                                    paddingLeft={5}
                                                >
                                                    <Stack direction={"row"}>
                                                        <Box
                                                            sx={{
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            <HtmlTooltip
                                                                title={
                                                                    <React.Fragment>
                                                                        <Stack
                                                                            direction={
                                                                                "row"
                                                                            }
                                                                        >
                                                                            <Box>
                                                                                <Icon
                                                                                    icon="svg-spinners:pulse-2"
                                                                                    color="#1769aa"
                                                                                    width="30"
                                                                                    height="30"
                                                                                />
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography color="inherit">
                                                                                    Click
                                                                                    here
                                                                                    to
                                                                                    view
                                                                                    CV
                                                                                </Typography>
                                                                            </Box>
                                                                        </Stack>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <Paper
                                                                    sx={{
                                                                        borderRadius: 4,
                                                                        height: 100,
                                                                        width: 100,
                                                                        backgroundColor:
                                                                            "#fff",
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "center",
                                                                        ":hover":
                                                                            {
                                                                                boxShadow: 20, // theme.shadows[20]
                                                                                backgroundColor:
                                                                                    "grey.300",
                                                                            },
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            marginTop: 0,
                                                                            overflow:
                                                                                "hidden",
                                                                        }}
                                                                    >
                                                                        <a
                                                                            onClick={
                                                                                onResumeClick
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    cvimage
                                                                                }
                                                                            />
                                                                        </a>
                                                                    </Box>
                                                                </Paper>
                                                            </HtmlTooltip>
                                                        </Box>
                                                        <Box margin={2}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    mb: 1,
                                                                    color: "#fff",
                                                                }}
                                                            >
                                                                {
                                                                    singleData.fullName
                                                                }
                                                            </Typography>
                                                            <Stack
                                                                direction={
                                                                    "row"
                                                                }
                                                                spacing={1}
                                                            >
                                                                <Typography
                                                                    // variant="h6"
                                                                    color="#fff"
                                                                    textTransform="capitalize"
                                                                >
                                                                    {
                                                                        singleData.jobPositionName
                                                                    }
                                                                </Typography>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Paper>

                                        <Box
                                            sx={{
                                                justifyContent: "flex-end",
                                                backgroundColor: "grey.200",
                                                borderRadius: "0 0 15px 15px",
                                                display: "flex",
                                                height: 45,
                                            }}
                                        >
                                            <Box padding={0.5}>
                                                <ViewMoreDetails
                                                    singleData={singleData}
                                                />
                                            </Box>
                                        </Box>
                                    </div>
                                </Box>
                            </Grow>

                            <Stack direction={"row"} spacing={2}>
                                <Box sx={{ width: 410, marginTop: 5 }}>
                                    <InterviewTimeLine
                                        title="Interview Status Timeline"
                                        list={timeline}
                                    />
                                </Box>
                                <Slide
                                    direction="left"
                                    in={checked}
                                    mountOnEnter
                                    unmountOnExit
                                    timeout={{ enter: 1000 }}
                                >
                                    <Box sx={{ maxWidth: 850 }}>
                                        <InterviewProcedureCards
                                            singleData={singleData}
                                            params={params}
                                        />
                                    </Box>
                                </Slide>
                            </Stack>
                        </>
                    )}
                    {!smUp && (
                        <>
                            <Box sx={{ marginTop: 2 }}>
                                <div>
                                    <Paper
                                        elevation={2}
                                        sx={{
                                            width: "100%",
                                            height: 200,
                                            backgroundSize: "contain",
                                            backgroundSize: "cover",
                                            backgroundImage: `url(${"https://wallpapers.com/images/featured/dark-blue-1u6lldkvi9buf4rl.webp"})`,
                                            borderTopRightRadius: 20,
                                            borderTopLeftRadius: 20,
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Stack direction={"column"}>
                                                <Box paddingTop={2}>
                                                    <Stack direction={"column"}>
                                                        <Box
                                                            sx={{
                                                                marginTop: 1,
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Paper
                                                                sx={{
                                                                    borderRadius: 4,
                                                                    height: 70,
                                                                    width: 70,
                                                                    backgroundColor:
                                                                        "#fff",
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "center",
                                                                    ":hover": {
                                                                        boxShadow: 20, // theme.shadows[20]
                                                                        backgroundColor:
                                                                            "grey.300",
                                                                    },
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        overflow:
                                                                            "hidden",
                                                                    }}
                                                                >
                                                                    <a
                                                                        onClick={
                                                                            onResumeClick
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                cvimage
                                                                            }
                                                                        />
                                                                    </a>
                                                                </Box>
                                                            </Paper>
                                                        </Box>
                                                        <Box>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    mb: 1,
                                                                    color: "#fff",
                                                                    fontSize: 17,
                                                                }}
                                                            >
                                                                {
                                                                    singleData.fullName
                                                                }
                                                            </Typography>
                                                            <Stack
                                                                direction={
                                                                    "column"
                                                                }
                                                                spacing={1}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "center",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        // variant="h6"
                                                                        color="#fff"
                                                                        textTransform="capitalize"
                                                                    >
                                                                        {
                                                                            singleData.jobPosition
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Paper>

                                    <Box
                                        sx={{
                                            justifyContent: "flex-end",
                                            backgroundColor: "grey.200",
                                            borderRadius: "0 0 15px 15px",
                                            display: "flex",
                                            height: 45,
                                        }}
                                    >
                                        <Box padding={0.5}>
                                            <ViewMoreDetails
                                                singleData={singleData}
                                            />
                                        </Box>
                                    </Box>
                                </div>
                            </Box>

                            <Stack direction={"column"} spacing={2}>
                                <Box sx={{ width: "100%", marginTop: 5 }}>
                                    <InterviewTimeLine
                                        title="Interview Status Timeline"
                                        list={timeline}
                                    />
                                </Box>
                                <Box sx={{ maxWidth: 850 }}>
                                    <InterviewProcedureCards
                                        singleData={singleData}
                                        params={params}
                                    />
                                </Box>
                            </Stack>
                        </>
                    )}
                </Container>
            </Page>
        </div>
    );
}
