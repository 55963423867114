export const NOT_MARKED_ATTENDANCE_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
    },
    {
        field: "CourseEnrollmentNumber",
        headerName: "Course Enrollment No",
        width: 150,
    },
    {
        field: "admnNo",
        headerName: "Admission No",
        width: 150,
    },
    {
        field: "CourseErollmentDate",
        headerName: "Course Enrollment Date",
        width: 150,
    },

    {
        field: "StudentName",
        headerName: "Student Name",
        width: 150,
    },

    {
        field: "class",
        headerName: "Class",
        width: 150,
    },
    {
        field: "courseEnrolled",
        headerName: "Course Enrolled",
        width: 150,
    },

    {
        field: "contact",
        headerName: "Contact Number",
        width: 150,
    },
    {
        field: "sro",
        headerName: "Sro",
        width: 150,
    },
    {
        field: "sroRemarks",
        headerName: "Sro Remarks",
        width: 150,
    },
    {
        field: "SroSalesAmount",
        headerName: "Sro Sales Amount",
        width: 150,
    },
    {
        field: "CompleatedSessions",
        headerName: "Compleated Sessions",
        width: 150,
    },
    {
        field: "lastSessions",
        headerName: "Last Sessions",
        width: 150,
    },
];
