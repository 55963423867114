import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, Box, Typography, Chip } from "@mui/material";
// utils
import { fNumber } from "../../../../utils/formatNumber";
//
import BaseOptionChart from "../../../../components/Dashboard/DashboardWidgetSummaryCard/BaseOptionChart";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllAttendanceByDay } from "../../../../redux/features/dashboardAnalyticsSlice";

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled("div")(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(5),
    "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
    "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
        overflow: "visible",
    },
    "& .apexcharts-legend": {
        height: LEGEND_HEIGHT,
        alignContent: "center",
        position: "relative !important",
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));

// ----------------------------------------------------------------------

export default function TotalAttandanceByDay() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [value, setValue] = useState(dayjs());
    const [dayName, setDayName] = useState("");
    const { allAttendanceByDay } = useSelector(
        (state) => state.dashboardAnalytics
    );

    const newData = allAttendanceByDay.reduce(
        (acc, user) => {
            if (user.leaveType !== null && user.leaveType !== undefined) {
                if (acc.labels.indexOf(user.leaveType) === -1) {
                    acc.labels.push(user.leaveType);
                    acc.values.push(user.count);
                } else {
                    const index = acc.labels.indexOf(user.leaveType);
                    acc.values[index] += user.count;
                }
            }
            return acc;
        },
        { labels: [], values: [] }
    );

    useEffect(() => {
        const selectedDate = value ? value.toDate() : null;
        const day = selectedDate ? selectedDate.getDate() : null;
        const month = selectedDate ? selectedDate.getMonth() + 1 : null; // Month is zero-based
        const year = selectedDate ? selectedDate.getFullYear() : null;
        const data = {
            day: day,
            month: month,
            year: year,
        };
        dispatch(getAllAttendanceByDay(data));
        const dayName = value.format("dddd");
        setDayName(dayName);
    }, [value]);

    const chartOptions = merge(BaseOptionChart(), {
        colors: [
            theme.palette.chart.green[0],
            theme.palette.primary.light,
            theme.palette.chart.yellow[0],
            theme.palette.primary.main,
            theme.palette.primary.dark,
            theme.palette.chart.violet[0],
        ],
        labels: newData.labels,
        stroke: { colors: [theme.palette.background.paper] },
        legend: { floating: true, horizontalAlign: "center" },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName) => fNumber(seriesName),
                title: {
                    formatter: (seriesName) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: "90%",
                    labels: {
                        value: {
                            formatter: (val) => fNumber(val),
                        },
                        total: {
                            formatter: (w) => {
                                const sum = w.globals.seriesTotals.reduce(
                                    (a, b) => a + b,
                                    0
                                );
                                return fNumber(sum);
                            },
                        },
                    },
                },
            },
        },
    });

    return (
        <Card>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <CardHeader title="All Attandance" />

                <Box padding={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            variant="filled"
                            label="Date"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                        />
                    </LocalizationProvider>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Chip
                    label={<Typography variant="h6">{dayName}</Typography>}
                    variant="outlined"
                    color="primary"
                />
            </Box>

            <ChartWrapperStyle dir="ltr">
                <ReactApexChart
                    type="donut"
                    series={newData.values}
                    options={chartOptions}
                    height={320}
                />
            </ChartWrapperStyle>
        </Card>
    );
}
