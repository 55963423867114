import React, { useState } from "react";
import { Box, Paper, Stack } from "@mui/material";
import { getStudentAcademicSessions } from "../../../../../redux/features/researchSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import SimpleLoader from "../../../../Loader/SimpleLoader";
import { EditAcademicSessions } from "./EditAcademicSessions";
import NoDataFound from "../../../../NodataIndication/NoDataFound";
import { DeleteIndividualAcademicSessions } from "./DeleteIndividualAcademicSession";
import { AddStudentAcademicSchedules } from "./AddStudentAcademicSchedules";

export default function StudentAcademicSessions() {
    const dispatch = useDispatch();

    const { loading, subjectEnrollmentDetailedId, studentAcademicSessions } =
        useSelector((state) => state.research);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (studentAcademicSessions) {
            const updatedData = studentAcademicSessions?.map((item) => ({
                ...item,
                checked: false,
            }));
            setData(updatedData);
        }
    }, [studentAcademicSessions]);

    useEffect(() => {
        if (open === true) {
            dispatch(getStudentAcademicSessions(subjectEnrollmentDetailedId));
        }
    }, [open]);
    return (
        <div>
            <>
                {loading ? (
                    <SimpleLoader />
                ) : (
                    <>
                        {data.length === 0 ? (
                            <NoDataFound />
                        ) : (
                            <Box
                                sx={{
                                    backgroundColor: "grey.200",
                                    padding: 3,
                                    borderRadius: 1,
                                }}
                            >
                                {data.map((item, i) => {
                                    return (
                                        <>
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                                mt={1}
                                                key={item.sessionSrlNo}
                                            >
                                                <Paper
                                                    sx={{
                                                        height: 30,
                                                        width: 30,
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                        padding: 3,
                                                    }}
                                                >
                                                    {item.sessionSrlNo}
                                                </Paper>
                                                <Paper
                                                    sx={{
                                                        minHeight: 30,
                                                        width: 400,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 3,
                                                    }}
                                                >
                                                    {item.sessionContent}
                                                </Paper>
                                                <Paper
                                                    sx={{
                                                        height: 30,
                                                        width: 80,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 3,
                                                    }}
                                                >
                                                    {item.sessionModuleNo}
                                                </Paper>
                                                <Box pt={1}>
                                                    <EditAcademicSessions
                                                        data={item}
                                                    />
                                                </Box>
                                                <Box pt={1}>
                                                    <DeleteIndividualAcademicSessions
                                                        data={
                                                            item.studentAcademicSchedulesId
                                                        }
                                                    />
                                                </Box>
                                            </Stack>
                                        </>
                                    );
                                })}
                            </Box>
                        )}
                    </>
                )}
            </>
        </div>
    );
}
