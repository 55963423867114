import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import { viewNotification } from "../../redux/features/notificationSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchModulesOfCourse } from "../../redux/features/modulesSlice";
import { useRef } from "react";
import {
    previousSession,
    getLastSessionDate,
} from "../../redux/features/scheduleSlice";

const CustomSelectField = ({ options }) => {
    const [itemData, setItemData] = useState([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState("");
    const [fieldLabel, setLabel] = useState("");

    const {
        formik,
        name,
        data,
        label,
        setLastSessionDate,
        setLastDate,
        variant,
    } = options;
    const dispatch = useDispatch();
    useEffect(() => {}, []);

    useEffect(() => {
        setLabel(label);
        if (formik) {
            setError(formik.touched[name] && Boolean(formik.errors[name]));
            setHelperText(formik.errors[name]);
        }
        if (data?.length > 0) {
            setItemData(data);
        }
    }, [options]);

    const handleChange = (e) => {
        setValue(e.target.value);

        if (formik) {
            formik.handleChange(e);
            formik.setFieldValue(name, e.target.value);
        }
        if (name === "course") {
            const [courseId, EndrolId] = e.target.value.split("&");
            dispatch(fetchModulesOfCourse(courseId));
            dispatch(getLastSessionDate(EndrolId)).then((res) => {
                let dateToBeChanged =
                    res.payload.data.lastSessionDate &&
                    new Date(res.payload.data.lastSessionDate + " 00:00:00");
                if (dateToBeChanged < new Date(new Date().toDateString())) {
                    dateToBeChanged = new Date(new Date().toDateString());
                    dateToBeChanged.setDate(
                        dateToBeChanged.getDate() - parseInt(1)
                    );
                } else {
                    dateToBeChanged = new Date(
                        res.payload.data.lastSessionDate
                    );
                }
                let dayAfterLastSession = dateToBeChanged.setDate(
                    dateToBeChanged.getDate() + parseInt(1)
                );
                let dayBeforeLastSession = dateToBeChanged.setDate(
                    dateToBeChanged.getDate() - parseInt(1)
                );
                formik.setFieldValue("startDate", dayAfterLastSession);
                setLastSessionDate(
                    new Date(res.payload.data.lastSessionDate + " 00:00:00")
                );
                setLastDate(dayBeforeLastSession);
            });
            formik.setFieldValue("subjectEnrollmentId", EndrolId);
        } else if (name === "course/session") {
            const [course_base_id, subjectEnrollmentId] =
                e.target.value.split("&");
            formik.setFieldValue("subjectEnrollmentId", subjectEnrollmentId);
            dispatch(previousSession(subjectEnrollmentId));
        } else if (name === "view") {
            dispatch(viewNotification(e.target.value));
        }
    };

    return (
        <FormControl
            sx={{ ...options.sx, minWidth: 160, flex: 1 }}
            size="medium"
            error={error}
        >
            <InputLabel id="demo-simple-select-label">{fieldLabel}</InputLabel>
            <Select
                id="demo-simple-select-label"
                label={fieldLabel}
                name={name}
                onChange={handleChange}
                variant={variant}
                value={formik ? formik.values[name] : value}
            >
                {itemData.map((item) => {
                    if (name === "course") {
                        return (
                            <MenuItem
                                key={item.courses_base_table_id}
                                value={`${item.courses_base_table_id}&${item.subjectEnrollmentId}`}
                                data-subenid={item.subjectEnrollmentId}
                                label={`${item.course_name} ${item.course_available_detailed_name} ${item.subject_name}`}
                            >
                                {`${item.course_name} ${item.course_available_detailed_name} ${item.subject_name}`}
                            </MenuItem>
                        );
                    } else if (name === "moduleId") {
                        return (
                            <MenuItem
                                value={item.module_num}
                                key={item.courses_master_modules_id}
                            >
                                {item.module_num}{" "}
                            </MenuItem>
                        );
                    } else if (name === "course/sessions") {
                        return (
                            <MenuItem
                                key={item.courses_base_table_id}
                                value={`${item.courses_base_table_id}&${item.subjectEnrollmentId}`}
                                data-subenid={item.subjectEnrollmentId}
                                label={`${item.course_name} ${item.course_available_detailed_name} ${item.subject_name}`}
                            >
                                {`${item.course_name} ${item.course_available_detailed_name} ${item.subject_name}`}
                            </MenuItem>
                        );
                    } else if (name === "status") {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                {item.content}
                            </MenuItem>
                        );
                    } else if (name === "type" || "view") {
                        return (
                            <MenuItem key={item.id} value={item.id}>
                                {item.value}{" "}
                            </MenuItem>
                        );
                    }
                })}
            </Select>
            <FormHelperText id="course-helper">{helperText}</FormHelperText>
        </FormControl>
    );
};

export default CustomSelectField;
