import React from "react";
import GlobalDialog from "../mui/Dialogue/GlobalDialog";
import {
    AddButton,
    CancelButton,
    EditButton,
    SaveButton,
} from "../mui/Buttons/Buttons";
import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
    getProjectPackagesPreData,
    getClassPreData,
    editPackageClasses,
    getPackageClasses,
} from "../../redux/features/mastersSlice";
import { useSnackbar } from "notistack";

export const EditPackageClass = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState({
        id: params?.classId || "",
        name: params?.className || "",
    });
    const [packageId, setPackageId] = React.useState({
        id: params?.packageId || "",
        name: params?.packageName || "",
    });

    const { projectPackagesPreData, classPreData, loading } = useSelector(
        (state) => state.masters
    );

    const Schema = Yup.object().shape({
        classId: Yup.string().required("classId is required"),
        packageId: Yup.string().required("packageId is required"),
    });

    const formik = useFormik({
        initialValues: {
            packageClassId: params.packageClassId,
            classId: params.classId || "",
            packageId: params.packageId || "",
        },
        validationSchema: Schema,
        onSubmit: (values, { resetForm }) => {
            dispatch(editPackageClasses(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    resetForm();
                    dispatch(getPackageClasses());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                title="Add Package Class"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <Box sx={{ padding: 1 }}>
                        {" "}
                        <EditButton
                            size="small"
                            action={() => {
                                dispatch(getClassPreData());
                                dispatch(getProjectPackagesPreData());
                                setOpen(true);
                            }}
                        >
                            Edit
                        </EditButton>
                    </Box>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Stack direction={"column"} spacing={2}>
                                        <Autocomplete
                                            disablePortal
                                            name="classId"
                                            value={value}
                                            id="combo-box-demo"
                                            options={classPreData}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) =>
                                                option.id ||
                                                "" === value.id ||
                                                ""
                                            }
                                            getOptionLabel={(option) =>
                                                option.name || ""
                                            }
                                            onChange={(e, option, newValue) => {
                                                setValue({
                                                    id: option.id || "",
                                                    name: option.name || "",
                                                });
                                                formik.setFieldValue(
                                                    "classId",
                                                    option.id
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    {...params}
                                                    label="Class"
                                                    error={
                                                        formik.touched
                                                            .classId &&
                                                        Boolean(
                                                            formik.errors
                                                                .classId
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.classId
                                                    }
                                                />
                                            )}
                                        />
                                        <Autocomplete
                                            disablePortal
                                            name="packageId"
                                            value={packageId}
                                            id="combo-box-demo"
                                            options={projectPackagesPreData}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) =>
                                                option.id ||
                                                "" === value.id ||
                                                ""
                                            }
                                            getOptionLabel={(option) =>
                                                option.name || ""
                                            }
                                            onChange={(e, option, newValue) => {
                                                setValue({
                                                    id: option.id || "",
                                                    name: option.name || "",
                                                });
                                                formik.setFieldValue(
                                                    "packageId",
                                                    option.id
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    disabled
                                                    fullWidth
                                                    {...params}
                                                    label="Package"
                                                    error={
                                                        formik.touched
                                                            .packageId &&
                                                        Boolean(
                                                            formik.errors
                                                                .packageId
                                                        )
                                                    }
                                                    helperText={
                                                        formik.errors.packageId
                                                    }
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: 2,
                                        }}
                                    >
                                        <Stack spacing={2} direction={"row"}>
                                            <CancelButton
                                                action={() => setOpen(false)}
                                            >
                                                Cancel
                                            </CancelButton>
                                            <SaveButton type="submit">
                                                {loading ? "Saving..." : "Save"}
                                            </SaveButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
