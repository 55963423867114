import React from "react";
import ViewInterviewStatus from "./ViewInterviewStatus";

export default function InterviewStatus(params) {
  return (
    <div>
      <ViewInterviewStatus params={params} />
    </div>
  );
}
