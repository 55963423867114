import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useSelector } from "react-redux";
import TooltipRemarkViewer from "./TooltipRemarkViewer";

const PopUpDataGrid = (componentProps) => {
    const { ogaRemarks, loading } = useSelector((state) => state.cro);

    const gridProps = {
        loading: loading,
        rows: ogaRemarks,
        columns: [
            {
                field: "studentDetails",
                headerName: "Student Details (in brief)",
                minWidth: 200,
                renderCell: (params) => (
                    <TooltipRemarkViewer value={params.row.studentDetails} />
                ),
            },
            {
                field: "subjectSessions",
                headerName: "Subjects & Session Count per Subject",
                minWidth: 200,
                headerAlign: "left",
                align: "left",
                renderCell: (params) => (
                    <TooltipRemarkViewer value={params.row.subjectSessions} />
                ),
            },
            {
                field: "feePerSession",
                headerName: "Fee per session",
                minWidth: 100,
                type: "number",
                headerAlign: "left",
                align: "left",
            },
            {
                field: "sessionsPerWeek",
                headerName: "Session per Week",
                minWidth: 80,
                type: "number",
                headerAlign: "left",
                align: "left",
            },
            {
                field: "timePrefered",
                headerName: "Time Prefered",
                minWidth: 150,
                type: "string",
                headerAlign: "left",
                align: "left",
            },
            {
                field: "totalFee",
                headerName: "Total Fees",
                minWidth: 100,
                type: "number",
                headerAlign: "left",
                align: "left",
            },
            {
                field: "feeStatus",
                headerName: "Fee Status",
                minWidth: 100,
                type: "string",
                headerAlign: "left",
                align: "left",
            },
        ],
        autoHeight: true,
        getRowId: (row) => row.id,
    };

    return (
        <>
            <Box
                sx={{
                    height: 48,
                    backgroundColor: "grey.200",
                    borderRadius: "8px 8px 0 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "black",
                }}
            >
                <p>{`Name : ${componentProps.name} | Contact Person : ${componentProps.contactPerson} | Phone : ${componentProps.phone} | Place : ${componentProps.place} | Oga : ${componentProps.sro}`}</p>
            </Box>
            <Box minWidth={1000}>
                <DataGrid {...gridProps} />
            </Box>
        </>
    );
};

export default PopUpDataGrid;
