import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import BasicTextField from "../../../components/mui/TextField";
import Page from "../../../components/Page";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { DAYS } from "../../../utils/constants/daysConst";
import { editPreferrence } from "../../../redux/features/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Loader from "../../../components/Loader/Loader";

const EditSchedulePreferrence = () => {
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.sessions);
    const { state } = useLocation();
    const navigate = useNavigate();

    const [time, setTime] = useState(
        new Date("2000-01-01 " + state.row.fromTime)
    );

    const { prefId } = useParams();

    const { enqueueSnackbar } = useSnackbar();

    const [preferrence, setPreferrence] = useState({
        weekDayId: state.row.weekDayId,
        fromTime: moment(new Date("2000-01-01 " + state.row.fromTime)).format(
            "HH:mm:00"
        ),
    });

    const handleDay = (e) => {
        setPreferrence({ ...preferrence, weekDayId: e.target.value });
    };

    const handleTimeChange = (e) => {
        setTime(e);
        setPreferrence({
            ...preferrence,
            fromTime: moment(new Date(e)).format("HH:mm:00"),
        });
    };

    const handleSubmit = () => {
        dispatch(editPreferrence({ prefId, values: preferrence })).then(
            (res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    navigate(
                        `/schedules/student-preffered-shedules/${state.subjectEnrollmentId}`,
                        {
                            state: state,
                        }
                    );
                } else {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                }
            }
        );
    };

    return loading ? (
        <Loader />
    ) : (
        <Page>
            <Container>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Edit Preference Schedule
                </Typography>
                <Breadcrumb sx={{ marginBottom: 1 }} />
                <Box>
                    <Stack gap={2}>
                        <BasicTextField value={state.studentName} disabled />
                        <BasicTextField
                            value={`${state.course}(${state.courseDetailed})${state.subject}-class ${state.class} ${state.syllabus} ${state.medium}`}
                            disabled
                        />

                        <Stack
                            width="100%"
                            direction={"row"}
                            gap={2}
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                margin: "10px 0",
                            }}
                        >
                            <FormControl
                                variant="outlined"
                                sx={{
                                    minWidth: 160,
                                    flex: 1,
                                }}
                            >
                                <InputLabel>Week days</InputLabel>
                                <Select
                                    label="Week days"
                                    value={preferrence.weekDayId}
                                    onChange={handleDay}
                                >
                                    {DAYS.map((option, index) => (
                                        <MenuItem key={index} value={option.id}>
                                            {option.day}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                    label="Time"
                                    minutesStep={15}
                                    value={time}
                                    onChange={handleTimeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                flex: 1,
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            gap: 2,
                            justifyContent: "center",
                            marginTop: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                width: "100px",
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                width: "100px",
                            }}
                            onClick={handleSubmit}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Page>
    );
};

export default EditSchedulePreferrence;
