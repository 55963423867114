const saveduserinfo = (data) => {
    localStorage.setItem("cred", JSON.stringify(data));
};

const clearUserInfo = () => {
    localStorage.removeItem("cred");
    localStorage.removeItem("att");
};

const SwitchRole = (data) => {
    try {
        const val = JSON.parse(localStorage.getItem("cred"));
        val.token = data.token;
        val.profile.role = data.currentRole;
        clearUserInfo();
        saveduserinfo(val);
    } catch {
        return false;
    }
};

const isLogedIn = () => {
    try {
        const val = JSON.parse(localStorage.getItem("cred"));
        return val.isLogin;
    } catch {
        return false;
    }
};

const logOut = () => {
    clearUserInfo();
};

export { saveduserinfo, clearUserInfo, isLogedIn, SwitchRole, logOut };
