import React, { useState } from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import {
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";

import { Icon } from "@iconify/react";
import CollapseList from "../../../../CollapseList";
import { DataGrid } from "@mui/x-data-grid";
import NoDataFound from "../../../../NodataIndication/NoDataFound";
import { SubjectEnrollmentStudentOgaDetails } from "./SubjectEnrollmentStudentOgaDetails";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
    getDetailedSubjectEnrollementsByCourse,
    getDetailedSubjectEnrollementsByStudent,
    getLevelType,
    getSubjectsByClass,
    getDetailedCourseEnrollementsByCourse,
} from "../../../../../redux/features/croSlice";
import {
    getStudentAcademicSessions,
    subjectEnrollmentDetailedId,
} from "../../../../../redux/features/researchSlice";
import { AddSubjectPopup } from "./AddSubjectPopup";
import { AddDetailsPopup } from "./AddDetailsPopup";
import useResponsive from "../../../../../Hooks/useResponsive";
import { UpdateBaseCoursePopup } from "./UpdateBaseCoursePopup";
import { DeleteStudentAcademicSessions } from "./DeleteStudentAcademicSessions";
import { AcademicSessionsPopupCheck } from "../RenderCellMain";

const Head = [
    {
        field: "subjectName",
        headerName: "Enrolled Subjects",
        width: 200,
        renderCell: (params) => {
            const data = params.row.subjectName || "N/A";
            return (
                <Paper
                    elevation={3}
                    sx={{
                        margin: 1,
                        padding: 1,
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    {data}
                </Paper>
            );
        },
    },
];

export const SubjectEnrollmentPopup = ({ params }) => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");

    const foundationId = params?.projectId;
    const dispatch = useDispatch();
    const {
        detailedSubjectEnrollementsByCourse,
        loading,
        courseEnrollmentDtldId,
    } = useSelector((state) => state.cro);

    const [selectedRows, setSelectedRows] = useState([]);
    const [subEnroll, setSubEnroll] = useState([]);
    const [singleData, setSingleData] = useState("");
    const [open, setOpen] = useState(false);
    const [dataFiltered, setFiltered] = useState("");

    useEffect(() => {
        if (selectedRows) {
            const filterData = {
                subjectId: selectedRows[0]?.subjectId,
                categoryId:
                    selectedRows[0]?.detailedSubjectEnrollments[0]?.categoryId,
            };
            setFiltered(filterData);
        }
    }, [selectedRows]);

    useEffect(() => {
        if (open === true) {
            setTimeout(() => {
                dispatch(
                    getDetailedSubjectEnrollementsByCourse(
                        params.courseEnrollmentId
                    )
                );
                dispatch(
                    getDetailedCourseEnrollementsByCourse(
                        params.courseEnrollmentId
                    )
                );
                dispatch(getLevelType());
                dispatch(getSubjectsByClass(params.classId));
            }, 500);
        } else {
            setSelectedRows([]);
        }
    }, [open]);
    useEffect(() => {
        const data = selectedRows[0]?.detailedSubjectEnrollments;
        const singleData = selectedRows[0]?.detailedSubjectEnrollments[0];
        setSingleData(singleData);
        setSubEnroll(data);
    }, [selectedRows]);
    const subjectEnrollmentId = selectedRows[0]?.subjectEnrollmentId;

    const MyTextField = ({ label, value, multiline, rows }) => {
        return (
            <TextField
                label={label}
                inputProps={{ readOnly: true }}
                fullWidth
                variant="filled"
                defaultValue={value}
                multiline={multiline}
                rows={rows}
            />
        );
    };

    const Mytable = ({ tableRow }) => {
        return (
            <Grid item xs={12} lg={12} md={12}>
                <Paper
                    sx={{
                        margin: 1,
                        borderRadius: 4,
                    }}
                >
                    <Table>{tableRow}</Table>
                </Paper>
            </Grid>
        );
    };
    const Rows = ({ label, value, actions }) => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell variant="head" sx={{ padding: 1 }}>
                        <Stack direction={"row"} spacing={1}>
                            <Box>{label}</Box>
                        </Stack>
                    </TableCell>
                    <TableCell sx={{ padding: 0.5 }}>
                        <Stack direction={"row"} spacing={1}>
                            <Box>{value}</Box>
                            <Box>{actions}</Box>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    };

    return (
        <div>
            <GlobalDialog
                open={open}
                title="Subject
                Enrollment"
                closeButton={true}
                setOpen={setOpen}
                fullWidth
                maxWidth={"lg"}
                popupButton={
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        Enrollment
                    </Button>
                }
                content={
                    <>
                        <Box>
                            <Paper elevation={3}>
                                <Typography variant="h6" padding={2}>
                                    Student Details
                                </Typography>
                                <Box pb={2}>
                                    <Divider
                                        sx={{
                                            borderBottomWidth: 3,
                                            borderStyle: "dashed",
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "grey.200",
                                            borderRadius: 1,
                                            width: "97%",
                                        }}
                                    >
                                        <List>
                                            <ListItem disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        <Icon
                                                            icon="ic:round-person"
                                                            width="22"
                                                            height="22"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            params?.studentName
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                        <CollapseList label="Course Enrollment Details" />
                                        <CollapseList
                                            label="OGA Details"
                                            content={
                                                <SubjectEnrollmentStudentOgaDetails
                                                    params={params}
                                                />
                                            }
                                        />
                                        {/* ------------------------------------------------------------------------------ */}
                                        <Box>
                                            <Paper>
                                                <Stack
                                                    direction={
                                                        smUp ? "row" : "column"
                                                    }
                                                    spacing={2}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: smUp
                                                                ? 300
                                                                : "100%",
                                                        }}
                                                    >
                                                        <DataGrid
                                                            loading={loading}
                                                            autoHeight
                                                            rows={
                                                                detailedSubjectEnrollementsByCourse
                                                            }
                                                            columns={Head}
                                                            getRowId={(row) =>
                                                                row.serialNumber
                                                            }
                                                            onSelectionModelChange={(
                                                                ids
                                                            ) => {
                                                                const selectedIDs =
                                                                    new Set(
                                                                        ids
                                                                    );
                                                                const selectedRows =
                                                                    detailedSubjectEnrollementsByCourse?.filter(
                                                                        (row) =>
                                                                            selectedIDs.has(
                                                                                row.serialNumber
                                                                            )
                                                                    );
                                                                dispatch(
                                                                    subjectEnrollmentDetailedId(
                                                                        selectedRows[0]
                                                                            ?.detailedSubjectEnrollments[0]
                                                                            ?.subjectEnrollmentDetailedId
                                                                    )
                                                                );

                                                                setSelectedRows(
                                                                    selectedRows
                                                                );
                                                            }}
                                                            hideFooter={true}
                                                        />
                                                    </Box>

                                                    <Box
                                                        width={
                                                            smUp
                                                                ? "100%"
                                                                : "100%"
                                                        }
                                                        sx={{
                                                            backgroundColor:
                                                                "grey.200",
                                                            borderRadius: 1,
                                                        }}
                                                    >
                                                        <Box>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight:
                                                                        "bolder",
                                                                    m: 2,
                                                                }}
                                                            >
                                                                Subject Detailed
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "flex-end",
                                                                    m: 1,
                                                                }}
                                                            >
                                                                <AddSubjectPopup
                                                                    courseEnrollmentDtldId={
                                                                        courseEnrollmentDtldId
                                                                    }
                                                                    subEnroll={
                                                                        subEnroll
                                                                    }
                                                                />
                                                            </Box>
                                                            {selectedRows[0] ? (
                                                                <>
                                                                    <Box>
                                                                        <Stack
                                                                            direction={
                                                                                smUp
                                                                                    ? "row"
                                                                                    : "column"
                                                                            }
                                                                            spacing={
                                                                                1
                                                                            }
                                                                            margin={
                                                                                1
                                                                            }
                                                                        >
                                                                            <MyTextField
                                                                                value={
                                                                                    selectedRows[0]
                                                                                        ?.subjectName
                                                                                }
                                                                                label="Subejct"
                                                                            />
                                                                            <MyTextField
                                                                                value={
                                                                                    selectedRows[0]
                                                                                        ?.feePerSession
                                                                                }
                                                                                label="Fee Per Session"
                                                                            />
                                                                        </Stack>
                                                                        <Stack
                                                                            direction={
                                                                                smUp
                                                                                    ? "row"
                                                                                    : "column"
                                                                            }
                                                                            spacing={
                                                                                1
                                                                            }
                                                                            margin={
                                                                                1
                                                                            }
                                                                        >
                                                                            <MyTextField
                                                                                value={
                                                                                    selectedRows[0]
                                                                                        ?.preferedTime
                                                                                }
                                                                                label="Prefered Time"
                                                                            />
                                                                            <MyTextField
                                                                                value={
                                                                                    selectedRows[0]
                                                                                        ?.Status
                                                                                }
                                                                                label="Subject Enrollment Status"
                                                                            />
                                                                        </Stack>
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography
                                                                            sx={{
                                                                                fontWeight:
                                                                                    "bolder",
                                                                                m: 2,
                                                                            }}
                                                                        >
                                                                            Session
                                                                            Details
                                                                        </Typography>
                                                                        <Box
                                                                            sx={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "flex-end",
                                                                                m: 1,
                                                                            }}
                                                                        >
                                                                            <AddDetailsPopup
                                                                                courseEnrollmentDtldId={
                                                                                    courseEnrollmentDtldId
                                                                                }
                                                                                foundationId={
                                                                                    foundationId
                                                                                }
                                                                                subjectEnrollmentId={
                                                                                    subjectEnrollmentId
                                                                                }
                                                                                subEnroll={
                                                                                    subEnroll
                                                                                }
                                                                            />
                                                                        </Box>
                                                                        {/* --------------------------------------------------- */}
                                                                        {subEnroll?.length ===
                                                                        1 ? (
                                                                            <>
                                                                                {subEnroll?.length ===
                                                                                0 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <Box>
                                                                                        <Box
                                                                                            padding={
                                                                                                2
                                                                                            }
                                                                                        >
                                                                                            <Paper
                                                                                                elevation={
                                                                                                    3
                                                                                                }
                                                                                                sx={{
                                                                                                    backgroundClip:
                                                                                                        "grey.200",
                                                                                                    padding: 1,
                                                                                                }}
                                                                                            >
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        display:
                                                                                                            "flex",
                                                                                                        justifyContent:
                                                                                                            "flex-end",
                                                                                                    }}
                                                                                                >
                                                                                                    <DeleteStudentAcademicSessions />
                                                                                                </Box>
                                                                                                <Box
                                                                                                    sx={{
                                                                                                        display:
                                                                                                            "flex",
                                                                                                        justifyContent:
                                                                                                            "flex-end",
                                                                                                    }}
                                                                                                ></Box>
                                                                                                <List>
                                                                                                    <Stack
                                                                                                        direction={
                                                                                                            smUp
                                                                                                                ? "column"
                                                                                                                : "column"
                                                                                                        }
                                                                                                    >
                                                                                                        <Mytable
                                                                                                            tableRow={
                                                                                                                <>
                                                                                                                    <Rows
                                                                                                                        label="Category"
                                                                                                                        value={
                                                                                                                            singleData?.categoryName
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <Rows
                                                                                                                        label="No Of Sessions"
                                                                                                                        value={
                                                                                                                            singleData?.noOfSessions
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <Rows
                                                                                                                        label="Sessions Type"
                                                                                                                        value={
                                                                                                                            singleData?.sessionsTypeName
                                                                                                                        }
                                                                                                                    />
                                                                                                                    <Rows
                                                                                                                        label="Academic Sessions"
                                                                                                                        value={
                                                                                                                            singleData?.baseCourseString
                                                                                                                        }
                                                                                                                        actions={
                                                                                                                            <>
                                                                                                                                <UpdateBaseCoursePopup
                                                                                                                                    dataFiltered={
                                                                                                                                        dataFiltered
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                {/* <AcademicSessionsPopupCheck /> */}
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    />
                                                                                                                </>
                                                                                                            }
                                                                                                        />
                                                                                                    </Stack>
                                                                                                </List>
                                                                                            </Paper>
                                                                                        </Box>
                                                                                    </Box>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {subEnroll?.map(
                                                                                    (
                                                                                        item
                                                                                    ) => {
                                                                                        return (
                                                                                            <Box
                                                                                                key={
                                                                                                    item.subjectEnrollmentDetailedId
                                                                                                }
                                                                                            >
                                                                                                <CollapseList
                                                                                                    label={
                                                                                                        item.categoryName
                                                                                                    }
                                                                                                    content={
                                                                                                        <>
                                                                                                            <Box
                                                                                                                key={
                                                                                                                    item.subjectEnrollmentDetailedId
                                                                                                                }
                                                                                                            >
                                                                                                                <Box
                                                                                                                    padding={
                                                                                                                        2
                                                                                                                    }
                                                                                                                >
                                                                                                                    <Paper
                                                                                                                        elevation={
                                                                                                                            3
                                                                                                                        }
                                                                                                                        sx={{
                                                                                                                            backgroundClip:
                                                                                                                                "grey.200",
                                                                                                                            padding: 1,
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <Box
                                                                                                                            sx={{
                                                                                                                                display:
                                                                                                                                    "flex",
                                                                                                                                justifyContent:
                                                                                                                                    "flex-end",
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <DeleteStudentAcademicSessions />
                                                                                                                        </Box>
                                                                                                                        <Box
                                                                                                                            sx={{
                                                                                                                                display:
                                                                                                                                    "flex",
                                                                                                                                justifyContent:
                                                                                                                                    "flex-end",
                                                                                                                            }}
                                                                                                                        ></Box>
                                                                                                                        <List>
                                                                                                                            <Stack
                                                                                                                                direction={
                                                                                                                                    smUp
                                                                                                                                        ? "column"
                                                                                                                                        : "column"
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Mytable
                                                                                                                                    tableRow={
                                                                                                                                        <>
                                                                                                                                            <Rows
                                                                                                                                                label="Category"
                                                                                                                                                value={
                                                                                                                                                    item?.categoryName
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                            <Rows
                                                                                                                                                label="No Of Sessions"
                                                                                                                                                value={
                                                                                                                                                    item?.noOfSessions
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                            <Rows
                                                                                                                                                label="Sessions Type"
                                                                                                                                                value={
                                                                                                                                                    item?.sessionsTypeName
                                                                                                                                                }
                                                                                                                                            />

                                                                                                                                            <Rows
                                                                                                                                                label="Academic Sessions"
                                                                                                                                                value={
                                                                                                                                                    item?.baseCourseString
                                                                                                                                                }
                                                                                                                                                actions={
                                                                                                                                                    <>
                                                                                                                                                        <UpdateBaseCoursePopup
                                                                                                                                                            dataFiltered={
                                                                                                                                                                dataFiltered
                                                                                                                                                            }
                                                                                                                                                            category={
                                                                                                                                                                item.categoryId
                                                                                                                                                            }
                                                                                                                                                            subEnrllDldId={
                                                                                                                                                                item.subjectEnrollmentDetailedId
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                        {/* <AcademicSessionsPopupCheck /> */}
                                                                                                                                                    </>
                                                                                                                                                }
                                                                                                                                            />
                                                                                                                                        </>
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </Stack>
                                                                                                                        </List>
                                                                                                                    </Paper>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        </>
                                                                                                    }
                                                                                                />
                                                                                            </Box>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <NoDataFound />
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Stack>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                    }}
                                                ></Box>
                                            </Paper>
                                        </Box>
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </>
                }
            />
        </div>
    );
};
