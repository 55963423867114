import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  AddButton,
  CancelButton,
  SubmitButton,
  UpdateButton,
} from "../../../../mui/Buttons/Buttons";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import useResponsive from "../../../../../Hooks/useResponsive";
import { useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import DatePickerCustom from "../../../../DatePicker/DatePickerCustom";
import dayjs from "dayjs";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import {
  createInvoice,
  getInvoice,
  getInvoiceDetails,
  getScreenshot,
  getStudentsList,
  getSubjects,
  updateInvoice,
} from "../../../../../redux/features/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ViewScreenShot from "../../../../../views/ogd/myworkspace/Invoice/ViewScreenShot";
import { savePaginationData } from "../../../../../redux/features/globalDatagridSlice";

const AddOrUpdateInvoice = ({ row, paginationDetails }) => {
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [direction, setDirection] = useState("row");
  const mdDown = useResponsive("down", "md");
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const { loading, studentsList, subjectList, invoicesDetails } = useSelector(
    (store) => store.invoice
  );
  const { paginationData } = useSelector((state) => state.globalDataGrid)

  const preSubjectDetails = (invoicesDetails) => {
    const array = [];
    invoicesDetails.map((sub) => {
      const structure = {
        subject: sub.subject_id,
        feeInr: sub.invoice_detail_fee_inr,
        feeAed: sub.invoice_detail_fee_foreign_currency,
      };
      array.push(structure);
    });
    return array;
  };

  useEffect(() => {
    if (open && row) {
      setSelectedDate(row.invoice_date);
      dispatch(getInvoiceDetails(row.invoice_id));
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      dispatch(getStudentsList());
      dispatch(getSubjects());
    }
    if (!open) {
      handleCancel();
    }
  }, [open]);

  useEffect(() => {
    if (mdDown) {
      setDirection("column");
    } else {
      setDirection("row");
    }
  }, [mdDown]);

  const currencyType = [
    { id: 1, value: "INR" },
    { id: 2, value: "AED" },
  ];

  const AdmissionType = [
    { id: 0, value: "Not Included" },
    { id: 2, value: "Included" },
  ];

  const initialValues = {
    isUpdate:row?true:false,
    nameId: row ? row.invoice_course_enrollment_id : "",
    admissionFeeType: row ? row.invoice_admission_fee : 0,
    currency: row ? row.is_currency : 1,
    advanceInr: row ? row.invoice_advance_fee : "",
    advanceAed: row ? row.invoice_advance_fee_foreign_currency==null ? 0:row.invoice_advance_fee_foreign_currency : 0,
    discount: row ? row.invoice_discount : "",
    screenShot: "",
    remarks: row ? row.invoice_remarks : "",
    details: row
      ? preSubjectDetails(invoicesDetails)
      : [{ subject: "", feeInr: "", feeAed: "" }],
  };

  const schema = Yup.object({
    screenShot: Yup.mixed().when('isUpdate', {
      is:false,
      then: Yup.mixed().required("Screenshot is required"), // Make it required
      otherwise: Yup.mixed().nullable(), // Not required if `row` exists
    }),
    nameId: Yup.number().required("Name is required"),
    remarks: Yup.string().required("Remarks is required"),
    discount: Yup.number().required("Discount is required"),
    currency: Yup.number().required("Currency is required"),
    advanceInr: Yup.number().required("Advance INR is required"),
    advanceAed: Yup.number().when("currency", {
      is: 2,
      then: Yup.number().required("Advance INR is required"),
      otherwise: Yup.number().nullable(),
    }),
    details: Yup.array().when("currency", {
      is: 2,
      then: Yup.array().of(
        Yup.object({
          feeAed: Yup.string().required(
            "Fee (AED) is required when currency is AED"
          ),
          feeInr: Yup.string().required("Fee INR is required"),
          subject: Yup.string().required("Subject is required"),
        })
      ),
      otherwise: Yup.array().of(
        Yup.object().shape({
          feeInr: Yup.string().required("Fee INR is required"),
          subject: Yup.string().required("Subject is required"),
          feeAed: Yup.string().nullable(),
        })
      ),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    context: { row },
    onSubmit: (values) => {
      const data = {
        enrollmentId: values.nameId,
        invoiceDate: selectedDate,
        admission_fee: values.admissionFeeType,
        currency: values.currency,
        advance_inr: values.advanceInr,
        advance_aed: values.advanceAed,
        discount: values.discount,
        file: values.screenShot,
        details: JSON.stringify(values.details),
        remarks: values.remarks,
      };

      if (row) {
        const updateData = { ...data, id: row.invoice_id };
        dispatch(updateInvoice(updateData)).then((res) => {
          if (res.payload.status === "error") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);     
            dispatch(getInvoice(paginationDetails));
            dispatch(savePaginationData({ ...paginationData, openAnchor: false }));
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      } else {
        dispatch(createInvoice(data)).then((res) => {
          if (res.payload.status === "error") {
            enqueueSnackbar(res.payload.message, {
              variant: "error",
            });
          } else if (res.payload.status === "success") {
            setOpen(false);
            dispatch(
              getInvoice({
                selectedDate: dayjs().format("YYYY-MM"),
                pageNumber: 1,
              })
            );
            enqueueSnackbar(res.payload.message, {
              variant: "success",
            });
          }
        });
      }
    },
  });

  const handleStudentChange = (e, option) => {
    formik.setFieldValue("nameId", option.id);
    formik.setFieldValue("studentName", option.name);
  };

  const dynamicValueChange = (Rowindex, e, option) => {
    if (option) {
      const updatedDetails = [...formik.values.details];
      updatedDetails[Rowindex] = {
        ...updatedDetails[Rowindex],
        subject: option.subjId,
      };
      formik.setFieldValue("details", updatedDetails);
    } else {
      const { name, value } = e.target;
      const updatedDetails = [...formik.values.details];
      updatedDetails[Rowindex] = { ...updatedDetails[Rowindex], [name]: value };
      formik.setFieldValue("details", updatedDetails);
    }
  };

  const addNewRow = () => {
    formik.setFieldValue("details", [
      ...formik.values.details,
      { subject: "", feeInr: "", feeAed: "" },
    ]);
  };

  const removeRow = (Rowindex) => {
    const updatedDetails = formik.values.details.filter(
      (data, index) => index !== Rowindex
    );
    formik.setFieldValue("details", updatedDetails);
  };

  const handleFileChange = (e) => {
    formik.setFieldValue("screenShot", e.currentTarget.files[0]);
  };

  const handleCancel = () => {
    formik.resetForm(); // Resets form to initial values
    setOpen(false); // Closes the dialog or modal
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // This clears the file input
    }
  };
  return (
    <GlobalDialog
      title={row ? "Update Invoice" : "Add Invoice"}
      open={open}
      setOpen={setOpen}
      fullWidth
      maxWidth={"lg"}
      closeButton={true}
      popupButton={
        <>
          {row ? (
            <UpdateButton  disabled={row.invoice_accountant_verification==1} size="small" action={() => setOpen(true)}>
              Update
            </UpdateButton>
          ) : (
            <AddButton size="large" action={() => setOpen(true)}>
              Add Invoice
            </AddButton>
          )}
        </>
      }
      content={
        <>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "grid", gap: 2 }}>
                <Box sx={{ flex: 1 }}>
                  <Autocomplete
                    disablePortal
                    name="name"
                    // id="combo-box-demo"
                    options={studentsList}
                    getOptionLabel={(option) => option.name || ""}
                    value={
                      studentsList.find(
                        (student) => student.id == formik.values.nameId
                      ) || null
                    }
                    onChange={handleStudentChange}
                    renderInput={(params) => (
                      <TextField
                        disabled
                        fullWidth
                        autoComplete="off"
                        {...params}
                        label="Name"
                        error={
                          formik.touched.nameId && Boolean(formik.errors.nameId)
                        }
                        helperText={formik.errors.nameId}
                      />
                    )}
                  />
                </Box>

                <Box>
                  <DatePickerCustom
                    fullWidth={true}
                    value={dayjs(selectedDate)}
                    setValue={(date) =>
                      setSelectedDate(date.format("YYYY-MM-DD"))
                    }
                    viewDetails={["year", "month", "day"]}
                  />
                </Box>

                <Box>
                  <GlobalSelectField
                    options={{
                      formik: formik,
                      label: "Admission Fee",
                      name: "admissionFeeType",
                      data: AdmissionType,
                      keys: {
                        id: "id",
                        value: "value",
                      },
                      sx: {
                        width: "100%",
                      },
                    }}
                  />
                </Box>

                <Box>
                  <GlobalSelectField
                    options={{
                      formik: formik,
                      label: "Currency Type",
                      name: "currency",
                      data: currencyType,
                      keys: {
                        id: "id",
                        value: "value",
                      },
                      sx: {
                        width: "100%",
                      },
                    }}
                  />
                </Box>

                <Box sx={{ display: "grid", gap: 1 }}>
                  {formik.values.details.map((obj, index) => (
                    <Stack key={index} direction={direction} gap={1}>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Autocomplete
                          sx={{ width: 300 }}
                          name="name"
                          id="combo-box-demo"
                          options={subjectList}
                          getOptionLabel={(option) => option.subjectName || ""}
                          value={
                            subjectList.find(
                              (subject) => subject.subjId == obj.subject
                            ) || null
                          }
                          onChange={(e, option) =>
                            dynamicValueChange(index, e, option)
                          }
                          renderInput={(params) => (
                            <TextField
                              name="subject"
                              fullWidth
                              autoComplete="off"
                              {...params}
                              label="Subject Name"
                              error={
                                formik.touched.details?.[index]?.subject &&
                                Boolean(formik.errors.details?.[index]?.subject)
                              }
                              helperText={
                                formik.touched.details?.[index]?.subject &&
                                formik.errors.details?.[index]?.subject
                              }
                            />
                          )}
                        />

                        {formik.values.currency === 2 && (
                          <TextField
                            autoComplete="off"
                            value={obj.feeAed}
                            onChange={(e) => dynamicValueChange(index, e)}
                            id="feeAed"
                            label="Fee (AED)"
                            name="feeAed"
                            error={
                              formik.touched.details?.[index]?.feeAed &&
                              Boolean(formik.errors.details?.[index]?.feeAed)
                            }
                            helperText={
                              formik.touched.details?.[index]?.feeAed &&
                              formik.errors.details?.[index]?.feeAed
                            }
                          />
                        )}
                      </Box>

                      <Box sx={{ display: "flex", gap: 1 }}>
                        <Box>
                          <TextField
                            autoComplete="off"
                            value={obj.feeInr}
                            onChange={(e) => dynamicValueChange(index, e)}
                            id="feeInr"
                            label="Fee (INR)"
                            name="feeInr"
                            error={
                              formik.touched.details?.[index]?.feeInr &&
                              Boolean(formik.errors.details?.[index]?.feeInr)
                            }
                            helperText={
                              formik.touched.details?.[index]?.feeInr &&
                              formik.errors.details?.[index]?.feeInr
                            }
                          />
                        </Box>
                        <Box sx={{ display: "flex" }}>
                          {formik.values.details.length > 1 && (
                            <Button onClick={() => removeRow(index)}>
                              Remove
                            </Button>
                          )}
                          {formik.values.details.length - 1 == index && (
                            <Button onClick={addNewRow}>Add</Button>
                          )}
                        </Box>
                      </Box>
                    </Stack>
                  ))}
                </Box>

                <Box>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="advanceInr"
                    label="Advance (INR)"
                    name="advanceInr"
                    value={formik.values.advanceInr}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.advanceInr &&
                      Boolean(formik.errors.advanceInr)
                    }
                    helperText={
                      formik.touched.advanceInr &&
                      Boolean(formik.errors.advanceInr)
                    }
                  />
                </Box>

                {formik.values.currency === 2 && (
                  <Box>
                    <TextField
                      autoComplete="off"
                      fullWidth
                      id="advanceAed"
                      label="Advance (AED)"
                      name="advanceAed"
                      value={formik.values.advanceAed}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.advanceAed &&
                        Boolean(formik.errors.advanceAed)
                      }
                      helperText={
                        formik.touched.advanceAed &&
                        Boolean(formik.errors.advanceAed)
                      }
                    />
                  </Box>
                )}

                <Box>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="discount"
                    label="Discount ( % )"
                    name="discount"
                    value={formik.values.discount}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.discount && Boolean(formik.errors.discount)
                    }
                    helperText={
                      formik.touched.advanceInr &&
                      Boolean(formik.errors.discount)
                    }
                  />
                </Box>
                {row && (
                  <Box>
                    <ViewScreenShot  pageName="invoice" api={getScreenshot(row.invoice_screenshot)}/>
                  </Box>
                )}

                <Box >
                 <Typography sx={{pl:1,color:"#697077"}}>Screenshot</Typography>                 
                  <TextField
                    name="screenShot"
                    // label="Screenshot"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    type="file"
                    error={
                      formik.touched.screenShot &&
                      Boolean(formik.errors.screenShot)
                    }
                    helperText={
                      formik.touched.screenShot &&
                      formik.errors.screenShot
                    }
                  />
                </Box>

                <Box>
                  <TextField
                    autoComplete="off"
                    fullWidth
                    id="remarks"
                    label="Remarks"
                    name="remarks"
                    value={formik.values.remarks}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.remarks && Boolean(formik.errors.remarks)
                    }
                    helperText={
                      formik.touched.remarks && Boolean(formik.errors.remarks)
                    }
                  />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Stack direction={"row"} spacing={1}>
                    <CancelButton action={() => handleCancel()}>
                      Cancel
                    </CancelButton>
                    {row ? (
                      <UpdateButton type="submit">
                        {loading ? "Updating..." : "Update"}
                      </UpdateButton>
                    ) : (
                      <SubmitButton type="submit">
                        {loading ? "Saving..." : "Save"}
                      </SubmitButton>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Form>
          </FormikProvider>
        </>
      }
    />
  );
};

export default AddOrUpdateInvoice;
