import React, { useState, useEffect } from "react";
import { Box, Stack, Paper, Typography, Grid } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getHolidays } from "../../../redux/features/holidaysSlice";
import DatePickerCustom from "../../../components/DatePicker/DatePickerCustom";
import { DataGrid } from "@mui/x-data-grid";
import GlobalWrapper from "../../../components/Wrapper/GlobalWrapper";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import AddOrUpdateHoliday from "../../../components/datagrid/myworkspace/hr/Holidays-Calendar/AddOrUpdateHoliday";
import { HolidayCalendarTableHead } from "../../../components/datagrid/myworkspace/hr/Holidays-Calendar/HolidayCalendarTableHead";

const Holidays = () => {
  const [value, setValue] = useState(dayjs());
  const dispatch = useDispatch();
  const { holidays, loading } = useSelector((state) => state.holidays);
  const [gridSize, setGridSize] = useState(10);

  useEffect(() => {
    dispatch(getHolidays(value.year())); //GETTING ALL HOLIDAYS OF CURRENT YEAR
  }, [value]);

  return (
    <GlobalWrapper title={"Holidays"} actions={<AddOrUpdateHoliday/>}  >
      <>
        <Box sx={{marginBottom:2}}>
          <DatePickerCustom
            value={value}
            setValue={setValue}
            viewDetails={["year"]}
          />
        </Box>
        <Box>
          <DataGrid
            components={{ Toolbar: CustomGridToolbar }}
            rows={holidays}
            columns={HolidayCalendarTableHead}
            loading={loading} //WILL PROVIDE A INBUILD LOADER FOR DATAGRID
            getRowId={(row) => row.serialNumber}
            pageSize={gridSize}
            autoHeight
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageSizeChange={(newGridSize) =>
                setGridSize(newGridSize)
            }
          />
        </Box>
      </>
    </GlobalWrapper>
  );
};

export default Holidays;
