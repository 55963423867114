import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
  loading: false,
  foodList: [],
  eveningFoodList: [],
  alredayLunchMarkedDetails: {},
  alreadyEveningMarkedDetails: {},
  eveningSlots: [],
  lunchslots: [],
  foodStatus: [],
  monthlyStatus:[]
};

export const getFoodList = createAsyncThunk(
  "food/getFoodList",
  async ({ date, userId }) => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-food-list/${date}`,
    });
    let filter = null;
    if (userId) {
      filter = res.data.foodList.find((list) => list.food_user_id == userId);
    }
    return { res: res, filter };
  }
);

export const getEveningFoodList = createAsyncThunk(
  "food/getEveningFoodList",
  async ({ date, userId }) => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-evening-food-list/${date}`,
    });
    let filter = null;
    if (userId) {
      filter = res.data.eveningData.find((list) => list.food_user_id == userId);
    }
    return { res: res, filter };
  }
);

export const getFoodSlots = createAsyncThunk("food/getFoodSlots", async () => {
  const res = await apiRequest({
    method: "get",
    url: `food-list/get-food-slots`,
  });
  
  return res;
});

export const getFoodStatus = createAsyncThunk(
  "food/getFoodStatus",
  async (date) => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-day-food-status/${date}`,
    });
    return res;
  }
);


export const addMealStatus = createAsyncThunk("food/addMealStatus", async (data) => {
  const res = await apiRequest({
    method: "post",
    url: `food-list/add-day-meal`,
    data: data,
  });
  return res;
});


export const monthlyFoodStatus = createAsyncThunk(
  "food/monthlyFoodStatus",
  async (date) => {
    const res = await apiRequest({
      method: "get",
      url: `food-list/get-day-food-list-by-month/${date}`,
    });
    return res;
  }
);

export const removeFood = createAsyncThunk("food/removeFood", async (id) => {
  const res = await apiRequest({
    method: "delete",
    url: `food-list/delete-food-list/${id}`,
  });
  return res;
});

export const removeMealStatus = createAsyncThunk("food/removeMealStatus", async (id) => {
  const res = await apiRequest({
    method: "delete",
    url: `food-list/delete-day-food-status/${id}`,
  });
  return res;
});


export const addFood = createAsyncThunk("food/addFood", async (data) => {
  const res = await apiRequest({
    method: "post",
    url: `food-list/add-food-list`,
    data: data,
  });
  return res;
});

export const verifyStatus = createAsyncThunk(
  "food/verifyStatus",
  async (id) => {
    const res = await apiRequest({
      method: "patch",
      url: `food-list/food-list-verification/${id}`,
    });
    return res;
  }
);

const foodSlice = createSlice({
  name: "foodSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //.................GET FOODLIST..............................
    builder.addCase(getFoodList.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getFoodList.fulfilled, (state, action) => {
      return {
        ...state,
        foodList: formatDataForDataTable(action.payload.res.data.foodList),
        loading: false,
        alredayLunchMarkedDetails: action.payload.filter || {},
      };
    });

    //...................EVENIG FOOD LIST.............
    builder.addCase(getEveningFoodList.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getEveningFoodList.fulfilled, (state, action) => {
      return {
        ...state,
        foodList: formatDataForDataTable(action.payload.res.data.eveningData),
        loading: false,
        alreadyEveningMarkedDetails: action.payload.filter || {},
      };
    });

    //.................GET SLOTS..............................
    builder.addCase(getFoodSlots.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getFoodSlots.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        lunchslots: action.payload.data.foodSlots.filter(
          (list) => list.food_shift_id == 1
        ),
        eveningSlots: action.payload.data.foodSlots.filter(
          (list) => list.food_shift_id == 2
        ),
      };
    });

    //.................GET FOOD STATUS..............................
    builder.addCase(getFoodStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getFoodStatus.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        foodStatus: action.payload.data.status || [],
      };
    });

    
    //.................ADD MEAL STATUS..............................
    builder.addCase(addMealStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(addMealStatus.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });


    //.................GET FOOD STATUS BY MONTH..............................
    builder.addCase(monthlyFoodStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(monthlyFoodStatus.fulfilled, (state, action) => {
      return {
        ...state,
        loading: false,
        monthlyStatus: formatDataForDataTable(action.payload.data.list) || [],
      };
    });

    //.................DELETE FOOD..............................
    builder.addCase(removeFood.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(removeFood.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });

        //.................DELETE MEAL STATUS..............................
        builder.addCase(removeMealStatus.pending, (state, action) => {
          return {
            ...state,
            loading: true,
          };
        });
        builder.addCase(removeMealStatus.fulfilled, (state) => {
          return {
            ...state,
            loading: false,
          };
        });

    //.................ADD FOOD..............................
    builder.addCase(addFood.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(addFood.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });

    //.................VERIFY FOOD STATUS..............................
    builder.addCase(verifyStatus.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(verifyStatus.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export default foodSlice.reducer;
