import { Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import {
  AssignOgaView,
  OgaEnquiryLogView,
  OgaStatusLogView,
  OgaSubjectWiseAssessmentDataView,
} from "./RenderCellMain";
import ToolTip from "../../../../ToolTip/ToolTip";

const ogmStatus = (params) => {
  return (
    <Chip
      variant="outlined"
      size="small"
      icon={
        params.row.sro_assigned ? (
          <Icon icon="mdi:tick" />
        ) : (
          <Icon icon="gridicons:cross" />
        )
      }
      color={params.row.sro_assigned ? "success" : "error"}
      label={params.row.sro_assigned ? "Assigned" : "Not Assigned"}
    />
  );
};

// const assignOGA = (params) => {
//   return (
//     <>
//       {!params.row.sro_assigned ? (
//         params.row.oga_name
//       ) : (
//         <AssignOgaView {...params.row} />
//       )}
//     </>
//   );
// };

// const ogaLeadStatus = (params) => {
//   return (
//     <Chip
//       variant="outlined"
//       size="small"
//       icon={
//         params.row.sro_lead_status_name == "Admission Confirmed" ? (
//           <Icon icon="mdi:tick" />
//         ) : (
//           <Icon icon="gridicons:cross" />
//         )
//       }
//       color={
//         params.row.sro_lead_status_name == "Admission Confirmed"
//           ? "success"
//           : "warning"
//       }
//       label={
//         params.row.sro_lead_status_name
//           ? params.row.sro_lead_status_name
//           : "Not Updated"
//       }
//     />
//   );
// };

// const ogaStatusLog = (params) => {
//   return (
//     <>{params.row.sro_assigned ? <OgaStatusLogView {...params.row} /> : ""}</>
//   );
// };

// const ogaEnquiryLog = (params) => {
//   return (
//     <>{params.row.sro_assigned ? <OgaEnquiryLogView {...params.row} /> : ""}</>
//   );
// };

const OgaSubjectWiseAssessment = (params) => {
  return (
    <>
      {params.row.sro_assigned ? (
        <OgaSubjectWiseAssessmentDataView {...params.row} />
      ) : (
        ""
      )}
    </>
  );
};

const ogaRemarksToolTip = (params) => {
  return (
    <ToolTip content={params.row.sro_remarks} expand={params.row.sro_remarks} />
  );
};
const ogaAssessmentRemarksToolTip = (params) => {
  return (
    <ToolTip
      content={params.row.sro_assessment_remarks}
      expand={params.row.sro_assessment_remarks}
    />
  );
};
export const OGM_LEADS_TABLE_HEAD = [
  {
    field: "sl_no",
    headerName: "slNo",
    width: 20,
  },
  {
    field: "lead_type_name",
    headerName: "Lead Type",
    width: 150,
  },
  {
    field: "lead_name",
    headerName: "Lead Name",
    width: 200,
  },
  {
    field: "class_name",
    headerName: "class",
    width: 110,
  },
  {
    field: "course_name",
    headerName: "Course Name",
    width: 150,
  },
  {
    field: "lead_mob_no",
    headerName: "Contact",
    width: 120,
  },
  {
    field: "lead_place",
    headerName: "Place",
    width: 140,
  },
  {
    field: "source_name",
    headerName: "Source",
    width: 120,
  },
  {
    field: "user",
    headerName: "user",
    width: 200,
  },
  {
    field: "lead_timestamp",
    headerName: "Lead Came On",
    width: 180,
  },
  {
    field: "srm_status",
    headerName: "OGM Status",
    width: 140,
    renderCell: ogmStatus,
  },
  {
    field: "oga_name",
    headerName: "Assigned OGA",
    width: 160,
    // renderCell: assignOGA,
  },
  // {
  //   field: "sro_lead_status_name",
  //   headerName: "OGA Lead Status",
  //   width: 200,
  //   renderCell: ogaLeadStatus,
  // },
  // {
  //   field: "SROStatusLog",
  //   headerName: "OGA Status Log",
  //   width: 120,
  //   renderCell: ogaStatusLog,
  // },
  {
    field: "sro_remarks_total_fees",
    headerName: "OGA Sales Amount",
    width: 100,
  },
  // {
  //   field: "enquiry_log",
  //   headerName: "Enquiry_log",
  //   width: 120,
  //   renderCell: ogaEnquiryLog,
  // },
  {
    field: "sro_remarks",
    headerName: "OGA Remarks",
    width: 300,
    renderCell: ogaRemarksToolTip,
  },
  // {
  //   field: "sro_assessment_remarks",
  //   headerName: "OGA Assessment Remarks",
  //   width: 200,
  //   renderCell: ogaAssessmentRemarksToolTip,
  // },
  // {
  //   field: "subject_wise_assessment",
  //   headerName: "subject_wise_assessment",
  //   width: 120,
  //   renderCell: OgaSubjectWiseAssessment,
  // },
  {
    field: "sro_last_updated_on",
    headerName: "OGA Last Updated On",
    width: 200,
  },
  {
    field: "cro_name",
    headerName: "CRO",
    width: 200,
  },
  {
    field: "cro_lead_status_name",
    headerName: "CRO Lead Status",
    width: 200,
  },
];
