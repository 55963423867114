import { Card } from "@mui/material";
import React from "react";

const DashBoardCard = ({ text, value }) => {
    return (
        <Card sx={{ flex: 1, padding: "20px", background: "#cbe6cb" }}>
            <h4>{text}</h4>
            <h2>{value}</h2>
        </Card>
    );
};

export default React.memo(DashBoardCard);
