import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";
const initialState = {
    loading: false,
    data: [],
    logs: [],
    message: [],
    response: {},
    single: {},
    error: [],
    type: "",
};

export const createNotification = createAsyncThunk(
    "Notification/createNotification",
    async (finalData) => {
        const res = await apiRequest({
            method: "POST",
            url: "/notifications",
            data: {
                type: finalData.type,
                title: finalData.title,
                content: finalData.content,
            },
        });
        return res;
    }
);
export const sendSingleNotification = createAsyncThunk(
    "Notification/sendSingleNotification",
    async (datas) => {
        const res = await apiRequest({
            method: "POST",
            url: `/notifications/${datas.id}/send-specific`,
            data: {
                title: datas.title,
                content: datas.content,
                students: [datas.students],
            },
        });
        return res;
    }
);
export const viewNotification = createAsyncThunk(
    "notification/ViewNotification",
    async (e) => {
        const res = await apiRequest({
            method: "get",
            url: `notifications/${e}/by-type`,
        });
        const a = formatDataForDataTable(res.data.notifications);
        return a;
    }
);
export const getNotificationByID = createAsyncThunk(
    "notification/getNotificationByID",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `/notifications/${id}/by-id`,
        });
        //
        return res;
    }
);
export const deleteNotification = createAsyncThunk(
    "notification/deleteNotification",
    async (data) => {
        const res = await apiRequest({
            method: "delete",
            url: `/notifications/${data}`,
        });
        return res;
    }
);
export const editNotification = createAsyncThunk(
    "notification/editNotification",
    async (data) => {
        const res = await apiRequest({
            method: "put",
            url: `/notifications/${data.id}`,
            data: {
                type: data.type,
                title: data.title,
                content: data.content,
            },
        });
        return res;
    }
);
export const sendNotification = createAsyncThunk(
    "notification/sendNotification",
    async (id) => {
        const res = await apiRequest({
            method: "post",
            url: `/notifications/${id}/send-all`,
        });
        return res;
    }
);

export const getNotificationLogs = createAsyncThunk(
    "notification/getNotificationLogs",
    async (finalData) => {
        const res = await apiRequest({
            method: "get",
            url: `notifications/${finalData.type}/logs?from=${finalData.from}&to=${finalData.to}`,
        });
        return res;
    }
);

export const approveNotification = createAsyncThunk(
    "notification/approveNotification",
    async (id) => {
        const res = await apiRequest({
            method: "put",
            url: `notifications/${id}/approve`,
        });
        return res;
    }
);

const NotificationSlice = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {
        clearAll(state) {
            return { ...initialState };
        },
        clearNotifications(state) {
            state.data = [];
            state.filter = "all";
        },
        clearRes(state) {
            state.data = [];
        },
        clearMessage(state) {
            state.message = [];
        },
    },
    extraReducers: (builder) => {
        //---------------------Notification-Create-----------------------------------//
        builder.addCase(createNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createNotification.fulfilled, (state, action) => {
            if (action.payload.status === "failed") {
                state.error = action.payload.data.errors;
                state.loading = false;
            } else if (action.payload.status === "success") {
                state.message.push(action.payload.message);
            }
        });
        builder.addCase(createNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        //---------------------notification-view-----------------------------------//

        builder.addCase(viewNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(viewNotification.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
        });
        builder.addCase(viewNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //---------------------Notification-Send-----------------------------------//

        builder.addCase(sendSingleNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(sendSingleNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(sendSingleNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        //---------------------notification-edit-----------------------------------//

        builder.addCase(editNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editNotification.fulfilled, (state, action) => {
            state.response = action.payload;
            state.loading = false;
        });
        builder.addCase(editNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //---------------------notification-delete-----------------------------------//

        builder.addCase(deleteNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteNotification.fulfilled, (state, action) => {
            if (action.payload.status === "failed") {
                state.error = action.payload.data.errors;
                state.loading = false;
            } else if (action.payload.status === "success") {
                const rowToUpdateIndex = state.data.findIndex(function (item) {
                    return item.notification_id === action.meta.arg;
                });
                state.data.splice(rowToUpdateIndex, 1);
                state.message.push(action.payload.message);
            }
            state.response = action.payload;
            state.loading = false;
        });
        builder.addCase(deleteNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //-------------------get-notification-by-id--------------------------//
        builder.addCase(getNotificationByID.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getNotificationByID.fulfilled, (state, action) => {
            state.loading = false;
            state.single = action.payload.data.notification;
        });
        builder.addCase(getNotificationByID.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        //------------------------sendNotification--------------------------
        builder.addCase(sendNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(sendNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(sendNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        // ------------------------get-notification-logs---------------------------------------------------------
        builder.addCase(getNotificationLogs.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getNotificationLogs.fulfilled, (state, action) => {
            state.logs = action.payload.data.logs;
            state.loading = false;
        });
        builder.addCase(getNotificationLogs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // -------------------------------------approveNotification--------------------------------------------------

        builder.addCase(approveNotification.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(approveNotification.fulfilled, (state, action) => {
            state.loading = false;
            state.response = action.payload;
        });
        builder.addCase(approveNotification.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
    },
});
export const { clearMessage, clearRes, clearAll } = NotificationSlice.actions;
export default NotificationSlice.reducer;
