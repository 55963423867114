
export const FoodTableHead = [
  {
    field: "serialNumber",
    headerName: "slNo",
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  {
    field: "designation",
    headerName: "Designation / Role",
    width: 200,
  },
  {
    field: "department",
    headerName: "Department",
    width: 250,
  },
  {
    field: "office_name",
    headerName: "Office",
    width: 200,
  },
  {
    field: "tbl_food_slot",
    headerName: "Slot",
    width: 200,
  },
];
