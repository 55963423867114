import React, { useState } from "react";
import BasicTextField from "../../../mui/TextField";
import { Button, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateTerm1Fee } from "../../../../redux/features/croSlice";
import { useSnackbar } from "notistack";

const StudentTerm1updateForm = ({ row, setOpen }) => {
    const [value, setValue] = useState(row.term1Fee || "");
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (value) {
            dispatch(
                updateTerm1Fee({
                    courseEnrollmentId: row.courseEnrollmentId,
                    term1Fee: row.term1Fee,
                })
            ).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    setOpen(false);
                } else {
                    enqueueSnackbar(res.payload.message, {
                        variant: "error",
                    });
                }
            });
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <Stack gap={2}>
                <BasicTextField
                    label="Term 1 fee"
                    type="number"
                    value={value}
                    action={(e) => setValue(e.target.value)}
                    error={!Boolean(value)}
                    helperText={
                        !Boolean(value) ? "please enter term 1 fee" : ""
                    }
                />
                <Button variant="contained" type="submit" disabled={!value}>
                    Update
                </Button>
            </Stack>
        </form>
    );
};

export default StudentTerm1updateForm;
