import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";

export const TrainerUnavailability = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Trainer Unavailability"
                fullWidth
                maxWidth={"lg"}
                open={open}
                setOpen={setOpen}
                closeButton={true}
                popupButton={
                    <Button variant="outlined" onClick={() => setOpen(true)}>
                        View
                    </Button>
                }
                // content={<TrainerDetails />}
            />
        </div>
    );
};
