export const TP_VERIFICATION_DETAILED_TABLE_HEAD = [
    {
        field: "slNo",
        headerName: "slNo",
        width: 30,
    },
    {
        field: "attendanceType",
        headerName: "Attendance Type",
        width: 160,
    },
    {
        field: "course",
        headerName: "Course",
        width: 150,
    },
    {
        field: "date",
        headerName: "Date",
        width: 150,
    },
    {
        field: "feePerSession",
        headerName: "FeePer Session",
        width: 100,
    },
    {
        field: "subject",
        headerName: "Subject",
        width: 100,
    },
    {
        field: "tutorPayment",
        headerName: "Tutor Payment",
        width: 100,
    },
];
