import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { FEE_STRUCTURE_TBL_HEAD } from "./FeeStructureTableHead";
import {
    clearStudentFeeStructure,
    getStudentFeeStructure,
} from "../../../../redux/features/croSlice";
import { useDispatch, useSelector } from "react-redux";

const FeeStructureTable = ({ courseEnrollmentId }) => {
    const dispatch = useDispatch();
    const { studentFeeStructure, loading } = useSelector((state) => state.cro);
    useEffect(() => {
        dispatch(getStudentFeeStructure(courseEnrollmentId));
        return () => dispatch(clearStudentFeeStructure());
    }, []);

    return (
        <div
            style={{
                minWidth: "555px",
            }}
        >
            <DataGrid
                loading={loading}
                rows={studentFeeStructure}
                getRowId={(row) => row.termNo}
                columns={FEE_STRUCTURE_TBL_HEAD}
                autoHeight
            />
        </div>
    );
};

export default FeeStructureTable;
