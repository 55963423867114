export const CONVERSION_ANALYTICS_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
        width: 90,
    },
    {
        field: "ogaName",
        headerName: "OGA Name",
        flex: 1,
    },

    {
        field: "leadsAssigned",
        headerName: "Leads Assigned",
        flex: 1,
    },
    {
        field: "processedLeads",
        headerName: "Processed Leads",
        flex: 1,
    },
    {
        field: "admissionConfirmed",
        headerName: "AdmissionConfirmed",
        flex: 1,
    },
    {
        field: "ProcessedLeadsPercentage",
        headerName: "Processed Leads %",
        flex: 1,
    },
    {
        field: "totalLeadsConversionPercentage",
        headerName: "Total Leads Conversion %",
        flex: 1,
    },
    {
        field: "AdmissionConfirmedFromPreviousMonthLeads",
        headerName: "Admission Confirmed From Previous Month Leads",
        flex: 1,
    },
    {
        field: "lastMonthTotalLeadsConversionPercentage",
        headerName: "Last Month Total leads Conversion %",
        flex: 1,
    },
    {
        field: "totalSalesAmount",
        headerName: "Total Sales Amount",
        flex: 1,
    },
    {
        field: "feeCollected",
        headerName: "Fee Collected",
        flex: 1,
    },
];
