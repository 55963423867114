import React from "react";
import { Route, Routes } from "react-router-dom";
import Page404 from "../../views/user/Auth/Page404";

import {
  // Attendance,
  EditLeaveApplication,
  LeaveApplication,
  MarkAttendance,
  ViewApplications,
  CabinBooking,
} from "../../views/user/EmployeeServices";
import { MentoringBookings } from "../../views/user/EmployeeServices/mentoring/MentoringBookings";
import AttendanceView from "../../views/user/EmployeeServices/Attendance/AttendanceView";
import HolidaysCalendar from "../../views/user/EmployeeServices/Holidays/HolidaysCalendar";
import Food from "../../views/user/EmployeeServices/Food/Food";
import CompensatoryWorkRequest from "../../views/user/EmployeeServices/CompensatoryWork/CompensatoryWorkRequest";
const EmployeeServiceRouter = () => {
  return (
    <Routes>
      <Route path="attendance" element={<AttendanceView />} />
      <Route path="leave" element={<LeaveApplication />} />
      <Route path="applications" element={<ViewApplications />} />
      <Route
        path="edit-leave-application/:id"
        element={<EditLeaveApplication />}
      />
      <Route path="markattendance" element={<MarkAttendance />} />
      <Route path="cabin-booking" element={<CabinBooking />} />
      <Route
        path="compensatory-work-request"
        element={<CompensatoryWorkRequest />}
      />

      <Route path="mentoring-bookings" element={<MentoringBookings />} />
      <Route path="holidays-calendar" element={<HolidaysCalendar />} />
      <Route path="food" element={<Food />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default EmployeeServiceRouter;
