import { Icon } from "@iconify/react";
import {
    Box,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import React from "react";
import { TrainerProfile } from "../../../../components/datagrid/myworkspace/ta/TrainersPortal/TrainerProfile";
import { SubjectsList } from "../../../../components/datagrid/myworkspace/ta/TrainersPortal/SubjectsList";
import { Slots } from "../../../../components/datagrid/myworkspace/ta/TrainersPortal/Slots";
import { ActiveInactive } from "../../../../components/datagrid/myworkspace/ta/TrainersPortal/ActiveInactive";
import { TrainerUnavailability } from "../../../../components/datagrid/myworkspace/ta/TrainersPortal/TrainerUnavailability";

export const Actions = () => {
    return (
        <div>
            <Box padding={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Trainer Profile
                                        </Typography>
                                    </ListItemText>
                                    <TrainerProfile />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Subjects List
                                        </Typography>
                                    </ListItemText>
                                    <SubjectsList />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    {/* ---------------------------- */}
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Slots
                                        </Typography>
                                    </ListItemText>
                                    <Slots />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    {/* ------------------ */}
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Active/Inactive
                                        </Typography>
                                    </ListItemText>
                                    <ActiveInactive />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    {/* ----------------------- */}
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Trainer Unavailability
                                        </Typography>
                                    </ListItemText>
                                    <TrainerUnavailability />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};
