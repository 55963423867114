import React, { useEffect, useState } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import {
    CancelButton,
    SubmitButton,
    UpdateButton,
} from "../../../mui/Buttons/Buttons";
import { Box, Stack, TextField } from "@mui/material";
import dayjs from "dayjs";
import {
    getUserBookings,
    recheduleBooking,
} from "../../../../redux/features/mentoringSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

export const RecheduleBooking = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.mentoring);
    const [open, setOpen] = useState(false);
    const [updatedTime, setUpadtedTime] = useState("");
    const [data, setData] = useState({
        id: params.mentoring_bookings_id,
        from_time: params.mentor_suggested_from_time,
        to_time: params.mentor_suggested_to_time,
        bookingDate: params.mentor_suggested_date,
        remark: params.mentor_rejection_remarks,
    });

    const handleClick = () => {
        const bookingData = {
            id: params.mentoring_bookings_id,
            from_time: params.mentor_suggested_from_time,
            to_time: dayjs(`2000-01-01 ${updatedTime}`).format("HH:mm:00"),
            booking_date: params.mentor_suggested_date,
        };

        dispatch(recheduleBooking(bookingData)).then((res) => {
            if (
                res.payload.status === "failed" ||
                res.payload.status === "error"
            ) {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                setOpen(false);
                dispatch(getUserBookings());
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
            }
        });
    };
    useEffect(() => {
        if (data) {
            const currentTime = dayjs(`2000-01-01 ${data?.to_time}`);
            const updatedTime =
                currentTime.add(1, "second").format("hh:mm A") || "";

            setUpadtedTime(updatedTime);
        }
    }, [data]);

    return (
        <div>
            <GlobalDialog
                title="Reschedule"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <>
                        {params.status === 2 ? (
                            <UpdateButton
                                size="small"
                                action={() => setOpen(true)}
                            >
                                Rechedule
                            </UpdateButton>
                        ) : (
                            ""
                        )}
                    </>
                }
                content={
                    <>
                        <Box>
                            <Box>
                                <TextField
                                    label="Suggested Date"
                                    value={data.bookingDate || ""}
                                    fullWidth
                                    disabled
                                />
                            </Box>
                            <Box pt={2}>
                                <Stack direction={"row"} spacing={2}>
                                    <TextField
                                        label="Suggested from Time"
                                        value={
                                            dayjs(
                                                `2000-01-01 ${data?.from_time}`
                                            ).format("hh:mm A") || ""
                                        }
                                        fullWidth
                                        disabled
                                    />
                                    <TextField
                                        label="Suggested To Time"
                                        value={updatedTime}
                                        fullWidth
                                        disabled
                                    />
                                </Stack>
                            </Box>
                            <Box pt={2}>
                                <TextField
                                    label="Remarks"
                                    value={data?.remark || ""}
                                    fullWidth
                                    disabled
                                    multiline
                                    rows={2}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    pt: 2,
                                }}
                            >
                                <Stack direction={"row"} spacing={2}>
                                    <CancelButton
                                        size="small"
                                        action={() => setOpen(false)}
                                    >
                                        Cancel
                                    </CancelButton>
                                    <SubmitButton
                                        size="small"
                                        action={handleClick}
                                    >
                                        {loading ? "Submitting..." : "Submit"}
                                    </SubmitButton>
                                </Stack>
                            </Box>
                        </Box>
                    </>
                }
            />
        </div>
    );
};
