import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box } from "@mui/material";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";

const data = [
    {
        id: 1,
        date: "04-01-2023",
        fromTime: "11:23:00",
        toTime: "12:53:00",
        attendanceType: "Class",
        subject: "Hindi",
        lessons: "c	",
        remark: "no reamrks....",
        studentName: "NAUREEN AMEERUDEEN",
        feePerSession: "350",
        trainerpayment: "140",
        timeStamp: "2023-02-02 15:28:41",

        actions: "",
    },
];

const columns = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },
    {
        field: "date",
        headerName: "date",
        width: 150,
    },
    {
        field: "fromTime",
        headerName: "fromTime",
        width: 150,
    },
    {
        field: "toTime",
        headerName: "toTime",
        width: 100,
    },
    {
        field: "attendanceType",
        headerName: "attendanceType",
        width: 200,
    },
    {
        field: "subject",
        headerName: "subject",
        width: 150,
    },

    {
        field: "lessons",
        headerName: "lessons",
        width: 150,
    },

    {
        field: "remark",
        headerName: "remark",
        width: 250,
    },
    {
        field: "studentName",
        headerName: "studentName",
        width: 150,
    },
    {
        field: "feePerSession",
        headerName: "feePerSession",
        width: 150,
    },
    {
        field: "trainerpayment",
        headerName: "trainerpayment",
        width: 150,
    },
    {
        field: "timeStamp",
        headerName: "timeStamp",
        width: 150,
    },

    {
        field: "actions",
        headerName: "actions",
        width: 150,
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function TrainerAttendanceView() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Trainer Attendance View"
                Content={
                    <>
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.admissionNo}
                                label="AdNo"
                                name={selectedRows[0]?.name}
                                // details={<Details data={selectedRows[0]} />}
                                // actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
