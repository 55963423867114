import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    data: [],
    croStudents: [],
    studentsWithPreff: [],
    courseEnrollments: [],
    subjectEnrollments: [],
    filteredBaseCourses: [],
    studentBaseCourses: [],
    studentPreferredSchedules: [],
    sessionCounts: "",
    classes: [],
    error: "",
    singleStudentDetails: {},
    academicYears: [],
    schedulePreview: [],
    subjectEnrollmentId: [],
    preference: [],
    preview: [],
    updatedPreview: [],
    preferenceAvailabilityData: [],
    checkPreferenceData: [],
};

export const getSessionsByModuleId = createAsyncThunk(
    "sessions/getSessionsByModuleId",
    async (event) => {
        const res = await apiRequest({
            method: "GET",
            url: `courses/module-sessions/${event.target.value}`,
        });
        return res.data;
    }
);

export const getCroStudents = createAsyncThunk(
    "sessions/getCroStudents",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "pre-data/getCroStudents",
        });
        return res;
    }
);

export const getStudentsWithPrefference = createAsyncThunk(
    "sessions/getStudents",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "sessions/preference/getStudents",
        });
        return res;
    }
);

export const getCourseEnrollments = createAsyncThunk(
    "sessions/getCourses",
    async (addNo) => {
        const res = await apiRequest({
            url: `pre-data/getCourseEnrollments/${addNo}`,
            method: "get",
        });
        return res;
    }
);

export const getSubjectEnrollments = createAsyncThunk(
    "session/subject_enroll",
    async (courseEnrllId) => {
        const res = await apiRequest({
            url: `pre-data/getSubjectEnrollments/${courseEnrllId}`,
            method: "get",
        });
        return res;
    }
);

export const getClassess = createAsyncThunk("sessions/getClasses", async () => {
    const res = await apiRequest({
        url: `pre-data/getClasses`,
        method: "get",
    });
    return res;
});

export const getFilteredBasecourses = createAsyncThunk(
    "sessions/filter_base_coures",
    async (filter) => {
        const res = await apiRequest({
            url: `pre-data/getFilteredBaseCourses/${filter}`,
            method: "get",
        });

        const result = res.data.baseCourses?.map((item) => {
            return {
                ...item,
                displayValue: `${item.course} (${item.courseDetailed}) ${item.subject} ${item.class} ${item.syllabus}`,
            };
        });
        return { data: { baseCourses: result } };
    }
);

export const getSessionCounts = createAsyncThunk(
    "sessions/sessions_count",
    async (params) => {
        const res = await apiRequest({
            url: `pre-data/getModuleSessionsCount/${params.year}/${params.baseCId}`,
            method: "get",
        });

        return res;
    }
);

export const getAcademicYears = createAsyncThunk(
    "sessions/academic_year",
    async (baseCId) => {
        const res = await apiRequest({
            url: `pre-data/getFilteredAcademicYears/${baseCId}`,
            method: "get",
        });
        return res.data;
    }
);

export const createSchedulePreferences = createAsyncThunk(
    "sessions/create_pref",
    async (values) => {
        const res = await apiRequest({
            url: "sessions/preference/createSchedulePreferences",
            method: "post",
            data: values,
        });
        return res;
    }
);

export const getSchedulePreview = createAsyncThunk(
    "sessions/getSchedulePreview",
    async (data) => {
        const res = await apiRequest({
            url: "sessions/getSessionSchedulePreview",
            method: "post",
            data: data,
        });
        return res;
    }
);

export const getStudentBaseCourses = createAsyncThunk(
    "session/student_baseCourse",
    async (admNo) => {
        const res = await apiRequest({
            url: `sessions/preference/getStudentBaseCourses/${admNo}`,
            method: "get",
        });
        return res;
    }
);

export const getBaseCoursePreferedSchedule = createAsyncThunk(
    "sessions/get_prefered_schedule",
    async (subjectEId) => {
        const res = await apiRequest({
            url: `sessions/preference/getSchedulePreferences/${subjectEId}`,
            method: "get",
        });

        return res;
    }
);

export const createAdditionalSchedule = createAsyncThunk(
    "sessions/additional_sessions",
    async (values) => {
        const res = await apiRequest({
            url: "sessions/preference/createAdditionalSchedulePreferences",
            method: "post",
            data: values,
        });
        return res;
    }
);

export const editPreferrence = createAsyncThunk(
    "sessions/edit_preferrence",
    async ({ prefId, values }) => {
        const res = await apiRequest({
            url: `sessions/preference/editSchedulePreference/${prefId}`,
            method: "put",
            data: values,
        });
        return res;
    }
);

export const deletePreferrence = createAsyncThunk(
    "sessions/dlt_pref",
    async (prefId) => {
        const res = await apiRequest({
            url: `sessions/preference/deleteSchedulePreference/${prefId}`,
            method: "delete",
        });
        return res;
    }
);

export const editDate = createAsyncThunk(
    "sessions/edit_date",
    async (values) => {
        const res = await apiRequest({
            url: `sessions/preference/editScheduleStartDate/${values.subEId}`,
            method: "put",
            data: {
                classStartsFrom: values.newDate,
            },
        });

        return res;
    }
);

export const preferenceAvailability = createAsyncThunk(
    "sessions/preferenceAvailability",
    async (data) => {
        console.log("dataaaaaaaa", data);
        const res = await apiRequest({
            url: `sessions/validateSessionSlotAvailability/${data.sessionNo}`,
            method: "post",
            data: data,
        });
        console.log(res);
        return res;
    }
);

export const createSchedulePreference = createAsyncThunk(
    "sessions/createSchedulePreference",
    async (data) => {
        console.log("dataaaaaaaa", data);
        const res = await apiRequest({
            url: `sessions/createSessionSchedules`,
            method: "post",
            data: data,
        });
        console.log(res);
        return res;
    }
);

const sessionSlice = createSlice({
    name: "sessions",
    initialState: initialState,
    reducers: {
        updateSingleUser: (state, action) => {
            return {
                ...state,
                singleStudentDetails: action.payload,
            };
        },
        preferences: (state, action) => {
            return {
                ...state,
                preference: action.payload,
            };
        },
        previewData: (state, action) => {
            return {
                ...state,
                preview: action.payload,
            };
        },
        updatedPreview: (state, action) => {
            console.log("action.payload", action.payload);
            return {
                ...state,
                updatedPreview: action.payload,
            };
        },
        checkPreferenceData: (state, action) => {
            console.log("checkPreferenceData===", action.payload);
            return {
                ...state,
                checkPreferenceData: action.payload,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getSessionsByModuleId.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSessionsByModuleId.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.moduleSessions;
        });
        builder.addCase(getSessionsByModuleId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });
        builder.addCase(getStudentsWithPrefference.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getStudentsWithPrefference.fulfilled,
            (state, action) => {
                const students = formatDataForDataTable(
                    action.payload.data.students
                );
                return {
                    ...state,
                    studentsWithPreff: students,
                    loading: false,
                };
            }
        );

        builder.addCase(getCourseEnrollments.fulfilled, (state, action) => {
            return {
                ...state,
                courseEnrollments: action.payload.data.courseEnrollments,
                loading: false,
            };
        });

        builder.addCase(getCroStudents.fulfilled, (state, action) => {
            return {
                ...state,
                croStudents: action.payload.data.students,
            };
        });

        builder.addCase(getSubjectEnrollments.fulfilled, (state, action) => {
            return {
                ...state,
                subjectEnrollments: action.payload.data.subjectEnrollments,
            };
        });

        builder.addCase(getClassess.fulfilled, (state, action) => {
            const data = action.payload.data.classes;
            return {
                ...state,
                classes: data,
            };
        });

        builder.addCase(getFilteredBasecourses.fulfilled, (state, action) => {
            return {
                ...state,
                filteredBaseCourses: action.payload.data.baseCourses,
            };
        });

        builder.addCase(getSessionCounts.fulfilled, (state, action) => {
            return {
                ...state,
                sessionCounts: action.payload.data.sessionsCount,
            };
        });

        builder.addCase(getStudentBaseCourses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(getStudentBaseCourses.fulfilled, (state, action) => {
            const courses = formatDataForDataTable(
                action.payload.data.baseCourses
            );
            return {
                ...state,
                studentBaseCourses: courses,
                loading: false,
            };
        });

        builder.addCase(
            getBaseCoursePreferedSchedule.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getBaseCoursePreferedSchedule.fulfilled,
            (state, action) => {
                const schedules = formatDataForDataTable(
                    action.payload.data.schedulePreferences
                );
                return {
                    ...state,
                    loading: false,
                    studentPreferredSchedules: schedules,
                };
            }
        );

        builder.addCase(deletePreferrence.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deletePreferrence.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        //<------------edit schedule preference------------>

        builder.addCase(editPreferrence.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editPreferrence.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        //<------------add aditional Schedules------------>

        builder.addCase(createAdditionalSchedule.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(createAdditionalSchedule.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        //<------------create Schedule Preferences------------>

        builder.addCase(createSchedulePreferences.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(
            createSchedulePreferences.fulfilled,
            (state, action) => {
                return {
                    ...state,
                    loading: false,
                };
            }
        );

        //<------academic years predata-------->

        builder.addCase(getAcademicYears.fulfilled, (state, action) => {
            return {
                ...state,
                academicYears: action.payload.academicYears,
            };
        });
        // -------------------------getSchedulePreview----------------------------
        builder.addCase(getSchedulePreview.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(getSchedulePreview.fulfilled, (state, action) => {
            const data = action.payload.data?.schedules;
            const subjectEnrollmentId = action.payload.data.subjectEnrollmentId;
            console.log("data=====", action.payload.data);
            return {
                ...state,
                schedulePreview: data,
                subjectEnrollmentId: subjectEnrollmentId,
                loading: false,
            };
        });
        // ----------------------preferenceAvailability-------------------------------

        builder.addCase(preferenceAvailability.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(preferenceAvailability.fulfilled, (state, action) => {
            const data = action.payload.data?.selectedSession;
            console.log("data=====", action.payload);
            return {
                ...state,
                preferenceAvailabilityData: data,

                loading: false,
            };
        });
        // ---------------------------createSchedulePreference--------------------------------

        builder.addCase(createSchedulePreference.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(createSchedulePreference.fulfilled, (state, action) => {
            const data = action.payload.data.schedules;
            console.log("data=====", data);
            return {
                ...state,
                schedulePreview: data,

                loading: false,
            };
        });
    },
});

export const {
    updateSingleUser,
    preferences,
    previewData,
    updatedPreview,
    checkPreferenceData,
} = sessionSlice.actions;
export default sessionSlice.reducer;
