import React, { useRef, useState } from "react";
import GlobalFullDialog from "../../../../components/mui/Dialogue/GlobalFullDialog";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useReactToPrint } from "react-to-print";

const IncomeTemplatePrint = ({ isVerified, row }) => {
  let componentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const downloadPdf = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Fee Receipt`,
    onPrintError: () => alert("error occured"),
    // onAfterPrint: () => setconfirmedAlert(false),
  });
  if(!row){
    return
  }
  return (
    <GlobalFullDialog
      open={open}
      setOpen={setOpen}
      title={"Fee Reciept"}
      popupButton={
        <>
          <Button
            size="small"
            disabled={isVerified == "Not Verified"}
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            View
          </Button>
        </>
      }
      content={
        <Container sx={{ p: 1 }}>
          <GlobalWrapper
            actions={
              <Box>
                <Button variant="contained" onClick={downloadPdf}>
                  <Icon icon="ion:print-outline" height="25" width="25" />
                  Download Income Reciept
                </Button>
              </Box>
            }
          >
            <Box className="print-content">
              <Box
                border={1}
                sx={{
                  p: 5,
                  width: "1000px",
                  // height: "1450px",
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "space-between",
                }}
                ref={componentRef}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    <Typography variant="h3">FEE RECEIPT</Typography>
                  </Box>
                  <Box>
                    <img
                      height="90"
                      src="https://www.teaminterval.ae/wp-content/uploads/2022/03/interval-logo.png"
                      alt="header"
                    />
                  </Box>
                </Stack>

                <Stack
                  sx={{ mt: 10 }}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1">
                        Admission No :
                      </Typography>
                      <Typography sx={{ pl: "3px" }}> {row.admn_no}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                     
                      <Typography variant="subtitle1">Name :</Typography>
                      <Typography sx={{ pl: "3px" }}>{row.studentName.split(" ").slice(0,row.studentName.split(" ").length-1)}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1">Course :</Typography>
                      <Typography sx={{ pl: "3px" }}>{row.courseName.split(" ").slice(0,row.courseName.split(" ").length-1)}</Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1">Receipt : </Typography>
                      <Typography sx={{ pl: "3px" }}> {row.incomeId}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle1">
                        Payment Mode :
                      </Typography>
                      <Typography sx={{ pl: "3px" }}>{row.paymentMode}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                     
                      <Typography variant="subtitle1">Date :</Typography>
                      <Typography sx={{ pl: "3px" }}>{row.transactionDate}</Typography>
                    </Box>
                  </Box>
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  mt={8}
                >
                  <Typography variant="h6">Particulars</Typography>
                  <Typography variant="h6">Amount</Typography>
                </Stack>
                <Divider sx={{ borderBottomWidth: 2, my: 1 }} />

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle1">{row.feeType}</Typography>
                  <Typography variant="subtitle1">₹ {row.incomeAmount.split(" ")[0] -(row.incomeAmount.split(" ")[0] * 0.18).toFixed(2) }</Typography>
                </Stack>
                <Divider sx={{ borderBottomWidth: 2, my: 1 }} />

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle2">CGST (9%)</Typography>
                  <Typography variant="subtitle2">₹ {(row.incomeAmount.split(" ")[0] * 0.09).toFixed(2)}</Typography>
                </Stack>

                <Stack
                  sx={{ mt: "4px" }}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="subtitle2">SGST (9%)</Typography>
                  <Typography variant="subtitle2">₹ {(row.incomeAmount.split(" ")[0] * 0.09).toFixed(2)}</Typography>
                </Stack>
                <Divider  sx={{ borderBottomWidth: 2, my: 1 }} />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography variant="subtitle1">Total : </Typography>{" "}
                  <Typography sx={{ ml: "5px" }} variant="subtitle1">
                    ₹ {row.incomeAmount.split(" ")[0]}
                  </Typography>
                </Box>
                <Divider sx={{ borderBottomWidth: 2, my: 1 }} />

                <Stack
                  mt={3}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box>
                    {/* <img
                      src="https://cdn.britannica.com/17/155017-050-9AC96FC8/Example-QR-code.jpg"
                      alt="qrcode"
                      height={100}
                      width={100}
                    /> */}
                  </Box>
                  <Box display={"flex"}>
                    <Typography variant="subtitle1">Reciever :</Typography>
                    <Typography sx={{ml:"3px"}}>{row.incomeAddedUser}</Typography>
                  </Box>
                </Stack>

                <Box sx={{display:"flex",justifyContent:"center", mt:7}}>
                  <Typography padding={1} variant="caption">
                    SANCO INDIA EDU SOLUTIONS PRIVATE LIMITED
                  </Typography>
                </Box>
                <Box sx={{display:"flex",justifyContent:"center"}}>
                  <Typography variant="caption">
                    GSTIN : 32ABFCS2058H1ZI
                  </Typography>
                </Box>
              </Box>
            </Box>
          </GlobalWrapper>
        </Container>
      }
    />
  );
};

export default IncomeTemplatePrint;
