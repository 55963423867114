import React, { useEffect } from "react";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { INCOME_TABLE_HEAD } from "../../../components/datagrid/myworkspace/cro/IncomeTableHead";
import Wrapper from "../../../components/Wrapper/Wrapper";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { AddIncomePopup } from "./AddIncomePopup";
import { useSelector, useDispatch } from "react-redux";
import {
    getIncome,
    getStudentsforAddIncome,
} from "../../../redux/features/croSlice";

const IncomePage = () => {
    const dispatch = useDispatch();
    const { incomeData, loading, studentsforAddIncome } = useSelector(
        (state) => state.cro
    );
    const [gridSize, setGridSize] = React.useState(5);

    useEffect(() => {
        dispatch(getIncome());
        dispatch(getStudentsforAddIncome());
    }, []);
console.log(incomeData);

    return (
        <>
            <Wrapper
                title="Income"
                actions={<AddIncomePopup data={studentsforAddIncome} />}
                Content={
                    <>
                        <>
                            <Paper elevation={3}>
                                <Box>
                                    <DataGrid
                                        loading={loading}
                                        rows={incomeData}
                                        sx={{
                                            "& .MuiDataGrid-columnHeaderTitle":
                                                {
                                                    textOverflow: "clip",
                                                    whiteSpace: "break-spaces",
                                                    lineHeight: 1,
                                                },
                                        }}
                                        autoHeight
                                        getRowHeight={() => "auto"}
                                        columns={INCOME_TABLE_HEAD}
                                        getRowId={(row) => row.serialNumber}
                                        pageSize={gridSize}
                                        onPageSizeChange={(newGridSize) =>
                                            setGridSize(newGridSize)
                                        }
                                        rowsPerPageOptions={[5, 10, 25, 50]}
                                        components={{
                                            Toolbar: CustomGridToolbar,
                                        }}
                                    />
                                </Box>
                            </Paper>
                        </>
                    </>
                }
            />
        </>
        // <Page>
        //     <Box>
        //         <Typography variant="h4" sx={{ mb: 1 }}>
        //             Income
        //         </Typography>
        //         <Breadcrumb sx={{ paddingLeft: 0.3 }} />
        //         <Paper
        //             elevation={3}
        //             sx={{ borderRadius: 1, width: "100%", mt: 4 }}
        //         >
        //             <Box
        //                 sx={{
        //                     padding: 1,
        //                     backgroundColor: "grey.200",
        //                     borderRadius: "8px 8px 0 0",
        //                     display: "flex",
        //                     justifyContent: "right",
        //                 }}
        //             >
        //                 <Button
        //                     variant="contained"
        //                     component={Link}
        //                     to={"/my-tasks/add-income"}
        //                 >
        //                     Add Income
        //                 </Button>
        //             </Box>
        //             <DataGrid
        //                 columns={INCOME_TABLE_HEAD}
        //                 rows={[]}
        //                 autoHeight
        //             />
        //         </Paper>
        //     </Box>
        // </Page>
    );
};

export default IncomePage;
