import { Box, Button, Divider, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sideBarSettings } from "../redux/features/settingsSlice";
import { Icon } from "@iconify/react";

export default function SideBarOptions() {
    const dispatch = useDispatch();
    const status = useSelector((state) => state.settings.sideBarSettings);
    const CustomPaper = ({ backgroundColor, icon, action }) => {
        return (
            <Paper
                onClick={action}
                sx={{
                    height: 80,
                    width: 40,
                    background: backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box>
                    {icon == true ? (
                        <Icon
                            icon="line-md:confirm-circle"
                            width="22"
                            height="22"
                            color="white"
                        />
                    ) : (
                        ""
                    )}
                </Box>
            </Paper>
        );
    };
    return (
        <div>
            <Box>
                <Box padding={2}>
                    <Typography variant="subtitle2" sx={{ color: "#9aa6b2" }}>
                        Side Bar Options
                    </Typography>
                </Box>
                <Box padding={1}>
                    <Paper elevation={3}>
                        <Typography
                            variant="subtitle2"
                            padding={2}
                            // sx={{ color: "#9aa6b2" }}
                        >
                            Background Color
                        </Typography>
                        <Divider />
                        <Stack direction={"row"} spacing={1} padding={3}>
                            <CustomPaper
                                backgroundColor={"#4f46ba"}
                                action={() => {
                                    dispatch(
                                        sideBarSettings({
                                            bgColor: "#4f46ba",
                                            color: "white",
                                        })
                                    );
                                }}
                                icon={
                                    status.bgColor === "#4f46ba" ? true : false
                                }
                            />

                            <CustomPaper
                                backgroundColor={"#b94343"}
                                action={() => {
                                    dispatch(
                                        sideBarSettings({
                                            bgColor: "#b94343",
                                            color: "white",
                                        })
                                    );
                                }}
                                icon={
                                    status.bgColor === "#b94343" ? true : false
                                }
                            />

                            <CustomPaper
                                backgroundColor={"#212121"}
                                action={() => {
                                    dispatch(
                                        sideBarSettings({
                                            bgColor: "#212121",
                                            color: "white",
                                        })
                                    );
                                }}
                                icon={
                                    status.bgColor === "#212121" ? true : false
                                }
                            />
                            <CustomPaper
                                backgroundColor={"#348f6c"}
                                action={() => {
                                    dispatch(
                                        sideBarSettings({
                                            bgColor: "#348f6c",
                                            color: "white",
                                        })
                                    );
                                }}
                                icon={
                                    status.bgColor === "#348f6c" ? true : false
                                }
                            />

                            <CustomPaper
                                backgroundColor={"#26324d"}
                                action={() => {
                                    dispatch(
                                        sideBarSettings({
                                            bgColor: "#26324d",
                                            color: "white",
                                        })
                                    );
                                }}
                                icon={
                                    status.bgColor === "#26324d" ? true : false
                                }
                            />
                        </Stack>
                        <Box>
                            <Typography
                                variant="subtitle2"
                                padding={2}
                                // sx={{ color: "#9aa6b2" }}
                            >
                                Background Gradient
                            </Typography>
                            <Stack direction={"row"} spacing={1} padding={3}>
                                <CustomPaper
                                    backgroundColor="linear-gradient(rgb(0, 153, 153), rgb(54, 9, 109))"
                                    action={() => {
                                        dispatch(
                                            sideBarSettings({
                                                bgColor:
                                                    "linear-gradient(rgb(0, 153, 153), rgb(54, 9, 109))",
                                                color: "white",
                                            })
                                        );
                                    }}
                                    icon={
                                        status.bgColor ===
                                        "linear-gradient(rgb(0, 153, 153), rgb(54, 9, 109))"
                                            ? true
                                            : false
                                    }
                                />
                                <CustomPaper
                                    backgroundColor="linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)"
                                    action={() => {
                                        dispatch(
                                            sideBarSettings({
                                                bgColor:
                                                    "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)",
                                                color: "white",
                                            })
                                        );
                                    }}
                                    icon={
                                        status.bgColor ===
                                        "linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)"
                                            ? true
                                            : false
                                    }
                                />
                                <CustomPaper
                                    backgroundColor="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)"
                                    action={() => {
                                        dispatch(
                                            sideBarSettings({
                                                bgColor:
                                                    "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)",
                                                color: "white",
                                            })
                                        );
                                    }}
                                    icon={
                                        status.bgColor ===
                                        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 100%)"
                                            ? true
                                            : false
                                    }
                                />
                                <CustomPaper
                                    backgroundColor=" linear-gradient(to right, #c33764, #1d2671)"
                                    action={() => {
                                        dispatch(
                                            sideBarSettings({
                                                bgColor:
                                                    " linear-gradient(to right, #c33764, #1d2671)",
                                                color: "white",
                                            })
                                        );
                                    }}
                                    icon={
                                        status.bgColor ===
                                        " linear-gradient(to right, #c33764, #1d2671)"
                                            ? true
                                            : false
                                    }
                                />
                                <CustomPaper
                                    backgroundColor=" linear-gradient(to right, #34e89e, #0f3443)"
                                    action={() => {
                                        dispatch(
                                            sideBarSettings({
                                                bgColor:
                                                    " linear-gradient(to right, #34e89e, #0f3443)",
                                                color: "white",
                                            })
                                        );
                                    }}
                                    icon={
                                        status.bgColor ===
                                        "linear-gradient(to right, #34e89e, #0f3443)"
                                            ? true
                                            : false
                                    }
                                />
                            </Stack>
                        </Box>
                        <Box margin={2} paddingBottom={3}>
                            <Button
                                onClick={() => {
                                    dispatch(
                                        sideBarSettings({
                                            bgColor: "default",
                                            color: "",
                                        })
                                    );
                                }}
                                size="small"
                                sx={{
                                    backgroundColor: "#7352c7",
                                    color: "white",
                                    "&:hover": {
                                        backgroundColor: "#7352c7",
                                    },
                                }}
                            >
                                <Icon icon="grommet-icons:power-reset" />
                                Reset Background
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </div>
    );
}
