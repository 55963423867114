import { Box, Paper, Stack, Table, TableCell, TableRow } from "@mui/material";
import { Icon } from "@iconify/react";

const StudentsDetailstable = ({ data }) => {
  const mappableArray = Object.entries(data).filter(
    ([key]) => !["studentImag", "name", "email"].includes(key) // Exclude specific keys
  );

  return (
    <Paper
      sx={{
        margin: 1,
        borderRadius: 4,
      }}
    >
      <Table>
        {mappableArray.map(([key, value]) => {
          return (
            <TableRow key={key}>
              <TableCell variant="head" sx={{ padding: 1 }}>
                <Stack direction={"row"} spacing={1}>
                  <Box>
                    {/* <Icon
                      icon="uil:arrow-up"
                      rotate={1}
                      
                      width="25"
                      height="25"
                    /> */}
                    <Icon
                      color="#0089d0"
                      icon="hugeicons:paragraph-bullets-point-01"
                      width="20"
                      height="20"
                    />
                  </Box>

                  <Box>{key}</Box>
                </Stack>
              </TableCell>
              <TableCell sx={{ padding: 0.5 }}>{value}</TableCell>
            </TableRow>
          );
        })}
      </Table>
    </Paper>
  );
};

export { StudentsDetailstable };
