import React, { useEffect, useState } from "react";
import { Box, Stack, Paper, Typography, Grid } from "@mui/material";
import dayjs from "dayjs";
import GlobalWrapper from "../../../../components/Wrapper/GlobalWrapper";
import DatePickerCustom from "../../../../components/DatePicker/DatePickerCustom";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { FoodTableHead } from "../../../../components/datagrid/EmployeeService/Food/FoodTableHead";
import MarkFood from "./MarkFood";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import useResponsive from "../../../../Hooks/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { getEveningFoodList, getFoodList } from "../../../../redux/features/foodSlice";


const Food = () => {
  const [value, setValue] = useState(dayjs().add(1, "day"));
  const [direction, setDirection] = useState("row");
  const mdDown = useResponsive("down", "md");
  const [gridSize, setGridSize] = useState(10);
  const [selectedFoodCategoryId, setSelectedFoodCategoryId] = useState(1);
  const dispatch = useDispatch();
  const {foodList,loading}= useSelector((store)=>store.food)
  const { profile } = JSON.parse(localStorage.getItem('cred'))

let category = [
    { id: 1, value: "Lunch" },
    { id: 2, value: "Evening Food" },
  ];

  useEffect(() => {
    dispatch(getFoodList({date:value.format("YYYY-MM-DD")})); //INITIALLY IT WILL CALL THE TOMORROWS LUNCH LIST
  }, []);


  useEffect(()=>{
    if(selectedFoodCategoryId==1){ //IF THE CATEGORY 1 MEANS LUNCH
      dispatch(getFoodList({date:value.format("YYYY-MM-DD")}));
    }else if(selectedFoodCategoryId==2){ //IF THE CATEGORY 2 MEANS EVENING FOOD 
      setValue(dayjs())
      dispatch(getEveningFoodList({date:dayjs().format("YYYY-MM-DD")}));
    } 
  },[selectedFoodCategoryId,value.format("YYYY-MM-DD")])


  const dateChoosed=(date)=>{ //DATE CHOOSED FROM DATE PICKER, THEN WITH THAT DATE FETCH THE LUNCH DETAILS.ALSO FOOD CATEGORY SELECT CHANGED TO 1 TO SHOW LUNCH AS SELECTED
    setValue(dayjs(date))
    setSelectedFoodCategoryId(1)
  }

  const handleChange = (e) => {
    setSelectedFoodCategoryId(e.target.value);
  };


  useEffect(() => {
    if (mdDown) {
      setDirection("column");
    } else {
      setDirection("row");
    }
  }, [mdDown]);


  
  return (
    <GlobalWrapper actions={<MarkFood dateChange={setValue} foodCategoryChange={setSelectedFoodCategoryId} selectedFoodCategory={selectedFoodCategoryId} />} title={"Food"}>
      <Stack
        gap={2}
        direction={direction}
        sx={{
          marginBottom: 2,
          justifyContent: "space-between",
          alignItems: mdDown ? "start" : "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <DatePickerCustom
            value={value}
            setValue={(date)=>dateChoosed(date)}
            viewDetails={["year", "month", "day"]}       
          />
          <Box sx={{ marginLeft: 1 }}>
            <FormControl sx={{ width: "160px" }}>
              <InputLabel>Food Category</InputLabel>
              <Select
                name="foodCategory"
                label="Food Category"
                value={selectedFoodCategoryId}
                onChange={handleChange}
              >
                {category?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ pl: 1 }}>
          <Typography variant="h6">
            {`${value.$y} ${value.format("MMMM")} ${value.$D} ${value.format("dddd")}`}
          </Typography>
          <Box>
            <Typography sx={{ textJustify: "center" }} variant="subtitle1">
              Pathanapuram : {foodList.length}
            </Typography>
          </Box>
        </Box>
      </Stack>

      <Box>
        <DataGrid
          components={{ Toolbar: CustomGridToolbar }}
          rows={foodList}
          columns={FoodTableHead}
          loading={loading}
          getRowId={(row) => row.serialNumber}
          pageSize={gridSize}
          autoHeight
          rowsPerPageOptions={[5, 10, 25, 50]}
          onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
        />
      </Box>
    </GlobalWrapper>
  );
};

export default Food;
