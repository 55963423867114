import { Button } from "@mui/material";
import ShareAndEditAction from "./ShareAndEditAction";
import { Profile } from "../../CRM/AdmissionRegister/StudentProfile/Profile";
import FeeStructurePopUp from "./FeeStructurePopUp";
import StudentTerm1EditPopUp from "./StudentTerm1EditPopUp";
import OgaRemarksDetailedView from "./OgaRemarksDetailedView";

export const REFERRAL_ADMSN_RGSTR_TBL_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        width: 50,
    },
    {
        field: "admnNo",
        headerName: "AdmissionNumber",
        width: 100,
    },
    {
        field: "studentName",
        headerName: "Name",
        width: 200,
    },
    {
        field: "contact",
        headerName: "Mobile",
        width: 200,
    },
    {
        field: "className",
        headerName: "Class",
        width: 80,
    },
    {
        field: "syllabus",
        headerName: "Syllabus",
        width: 100,
    },
    {
        field: "medium",
        headerName: "Medium",
        width: 80,
    },
    {
        field: "course",
        headerName: "Course",
        width: 200,
    },

    {
        field: "courseEnrolledOn",
        headerName: "Course Enrollment Date",
        width: 150,
    },
    {
        field: "croAssignmentId",
        headerName: "OGA Remarks",
        width: 80,
        renderCell: (params) => <OgaRemarksDetailedView row={params.row} />,
    },
    {
        field: "croName",
        headerName: "CRO",
        width: 150,
    },
    {
        field: "profile",
        headerName: "Profile",
        renderCell: (params) => {
            if (params.row.studentProfileStatus === "Completed") {
                return <Profile params={params.row.courseEnrollmentId} />;
            } else {
                return (
                    <Button variant="contained" color="error">
                        NA
                    </Button>
                );
            }
        },
        width: 100,
    },
    {
        field: "courseEnrollmentId",
        headerName: "Fee Structure",
        renderCell: (params) => (
            <FeeStructurePopUp
                courseEnrollmentId={params.row.courseEnrollmentId}
            />
        ),
    },
    {
        field: "editTermFee",
        headerName: "Edit Term 1 Fee",
        width: 150,
        renderCell: (params) => <StudentTerm1EditPopUp row={params.row} />,
    },
];
