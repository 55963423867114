import { Breadcrumbs, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function Breadcrumb({ sx }) {
  let location = useLocation();
  const [crumb, setCrumb] = useState([]);
  useEffect(() => {
    let breadCrumbItems = location.pathname.split("/").filter((elm) => {
      if (elm !== " ") {
        return elm;
      } else return null;
    });
    setCrumb(breadCrumbItems);
  }, []);
  return (
    <Box sx={sx} role="presentation" onClick={handleClick}>
      <Breadcrumbs variant={"body2"} aria-label="breadcrumb" separator="›">
        {crumb.map((item, index, crumb) => {
          return (
            <Link
              underline="hover"
              sx={{ textTransform: "capitalize" }}
              color="inherit"
              href="/"
              key={index}
              aria-current={index === crumb.length - 1 ? "page" : null}
            >
              {item}
            </Link>
          );
        })}
        {/* <Link
          underline="hover"
          color="text.primary"
          href="/material-ui/react-breadcrumbs/"
          aria-current="page"
        ></Link> */}
      </Breadcrumbs>
    </Box>
  );
}
