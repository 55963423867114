import { Box, Button, Grow, Paper, Slide, Typography } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import useResponsive from "../../../Hooks/useResponsive";

export default function SelectionHeader({ content }) {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const [checked, setChecked] = React.useState(true);
    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    return (
        <div>
            {checked === false ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        borderRadius: 2,
                        padding: 1,
                    }}
                >
                    <Button
                        size="small"
                        sx={{ borderRadius: 3, backgroundColor: "grey.200" }}
                        onClick={handleChange}
                    >
                        <Icon
                            icon="line-md:check-list-3-twotone"
                            width="22"
                            height="22"
                        />
                    </Button>
                </Box>
            ) : null}

            <Box
                mb={2}
                sx={{
                    display: "flex",
                    justifyContent: mdUp ? "flex-end" : "center",
                }}
            >
                <Grow
                    direction="down"
                    in={checked}
                    mountOnEnter
                    unmountOnExit
                    timeout={{ enter: 800, exit: 800 }}
                >
                    <Paper
                        sx={{
                            backgroundColor: "grey.200",
                            borderRadius: 2,
                            padding: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                sx={{ borderRadius: 3, height: 15, width: 2 }}
                                onClick={handleChange}
                            >
                                <Icon
                                    icon="ic:round-minus"
                                    width="15"
                                    height="25"
                                />
                            </Button>
                        </Box>
                        <Box
                        // sx={{
                        //     display: "flex",
                        //     justifyContent: mdUp ? "flex-end" : "center",
                        // }}
                        >
                            {content}
                        </Box>
                    </Paper>
                </Grow>
            </Box>
        </div>
    );
}
