import React from "react";
import { Route, Routes } from "react-router-dom";
import HodLeaveApplications from "../../../views/user/mytasks/leave/HodLeaveApplications";
import Page404 from "../../../views/user/Auth/Page404";
import ApproveLeave from "../../../views/user/mytasks/leave/ApproveLeave";
import ReportingHeadAttendance from "../../../views/user/mytasks/Attendance/ReportingHeadAttendance";

const HodRouter = () => {
    return (
        <Routes>
            <Route
                path="hod-leave-applications"
                element={<HodLeaveApplications />}
            />
            <Route
                path="hod-leave-approve/:leaveId"
                element={<ApproveLeave />}
            />
            <Route
                path="reporting-head-attendance"
                element={<ReportingHeadAttendance />}
            />
            <Route
                path="*"
                element={
                    <Page404
                        message={
                            "you don't have the permission to perform such an action"
                        }
                    />
                }
            />
        </Routes>
    );
};

export default HodRouter;
