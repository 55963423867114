import React from "react";
import {
    Paper,
    Box,
    Divider,
    Button,
    MenuItem,
    Select,
    Skeleton,
    InputLabel,
    FormControl,
    Stack,
    Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useFormik, Form, FormikProvider } from "formik";
import {
    getRolesOfUser,
    getPreData,
    addRole,
    deleteRolesOfUser,
} from "../../../redux/features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import useResponsive from "../../../../src/Hooks/useResponsive";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function AddRole({ singleUser, preData }) {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const dispatch = useDispatch();
    const role = useSelector((state) => state.users.roles);

    const { enqueueSnackbar } = useSnackbar();

    const params = useParams();

    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [deletedItems, setDeletedItems] = React.useState([]);

    const handleClickOpenDelete = (item) => {
        setOpenDelete(true);
        setDeletedItems([item]);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentId: singleUser?.departmentId || "",
            userRoles: role.roleResponse || [],
            userTypeId: "",
        },
        onSubmit: (values) => {
            let userRoles = [];
            values.userRoles.forEach((item) => {
                userRoles.push({
                    userTypeId: item.userTypeId,
                    deptId: values.departmentId,
                });
            });
            dispatch(
                addRole({ data: { roles: userRoles }, id: params.id })
            ).then((res) => {
                if (res.payload.status === "success") {
                    enqueueSnackbar("Successfuly Added...", {
                        variant: "success",
                    });
                    setTimeout(() => {
                        dispatch(getRolesOfUser(params.id));
                    }, 1000);
                }
            });
        },
    });

    const handleDesignation = () => {
        const userRoles = [...formik.values.userRoles];
        const role = preData.designations.filter(
            (item) => item.userTypeId === formik.values.userTypeId
        );

        userRoles.push({
            userTypeId: role[0].userTypeId,
            userType: role[0].userType,
        });

        const result = [
            ...new Set(userRoles.map((a) => JSON.stringify(a))),
        ].map((a) => JSON.parse(a));

        formik.setFieldValue("userRoles", result);
        formik.setFieldValue("userTypeId", "");

        handleClose();
    };

    const handleRemove = (userTypeId) => {
        const userRoles = [...formik.values.userRoles];
        const res = userRoles.filter((item) => item.userTypeId !== userTypeId);
        formik.setFieldValue("userRoles", res);
    };

    const handleDeleteRole = () => {
        dispatch(
            deleteRolesOfUser({ body: { roles: deletedItems }, id: params.id })
        ).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                enqueueSnackbar("Successfuly Deleted", { variant: "success" });
                dispatch(getRolesOfUser(params.id));
            }
        });
    };

    useEffect(() => {
        formik.setFieldValue("userRoles", role.roleResponse);
    }, [role]);

    return (
        <div>
            {smUp && (
                <Box sx={{ marginTop: 5 }}>
                    <Paper
                        elevation={3}
                        sx={{
                            width: "100%",
                            height: "auto",
                            backgroundColor: "grey.200",
                        }}
                    >
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Typography variant="h4" sx={{ padding: 3 }}>
                                    Add or Remove Roles..
                                </Typography>
                                <Divider sx={{ borderStyle: "dashed" }} />

                                <Box>
                                    <Stack direction={"row"}>
                                        <Box>
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    height: 50,
                                                    width: 150,
                                                    margin: 3,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: 0.2,
                                                }}
                                            >
                                                <Button
                                                    onClick={handleClickOpen}
                                                >
                                                    <Icon
                                                        icon="pajamas:file-addition"
                                                        width="35"
                                                        height="35"
                                                    />
                                                    Add Role
                                                </Button>
                                            </Paper>
                                        </Box>
                                        {/* --------------------------------------------------------- */}

                                        <Box>
                                            <Stack direction={"row"}>
                                                {formik.values.userRoles?.map(
                                                    (item, i) => {
                                                        return (
                                                            <Paper
                                                                key={
                                                                    item.userTypeId
                                                                }
                                                                elevation={3}
                                                                sx={{
                                                                    minHeight: 70,
                                                                    width: "auto",
                                                                    margin: 2,
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "center",
                                                                    paddingTop: 1,
                                                                    backgroundColor:
                                                                        "#006c9c69",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="p"
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bolder",
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        icon="ic:sharp-people-outline"
                                                                        width="30"
                                                                        height="30"
                                                                    />
                                                                    {
                                                                        item.userType
                                                                    }
                                                                </Typography>
                                                                <IconButton
                                                                    // onClick={() =>
                                                                    //     handleRemove(
                                                                    //         item.userTypeId
                                                                    //     )
                                                                    // }
                                                                    onClick={() =>
                                                                        handleClickOpenDelete(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        icon="material-symbols:cancel-rounded"
                                                                        width="20"
                                                                        height="20"
                                                                    />
                                                                </IconButton>
                                                            </Paper>
                                                        );
                                                    }
                                                )}
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>

                                <Box
                                    sx={{
                                        padding: 5,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    {/* <Button variant="outlined" size="medium">
                                    Cancel
                                </Button> */}
                                    <Button
                                        // variant="contained"
                                        size="medium"
                                        type="submit"
                                        value="Save"
                                        sx={{
                                            marginLeft: 2,
                                            backgroundColor: "#c8facd",
                                            color: "#0db05d",
                                        }}
                                    >
                                        <Icon
                                            icon="material-symbols:add"
                                            width="30"
                                            height="30"
                                        />
                                        Submit Update
                                    </Button>
                                </Box>

                                {/* -----------------------------------------dialogue------------------------- */}

                                <Box>
                                    <Dialog
                                        open={open}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleClose}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle>
                                            <Stack
                                                direction={"row"}
                                                marginTop={1}
                                            >
                                                <Box marginRight={1}>
                                                    <Icon
                                                        icon="mdi:people-add-outline"
                                                        width="30"
                                                        height="30"
                                                        color="#7e4d8b"
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        marginTop: 0.5,
                                                        color: "#7e4d8b",
                                                    }}
                                                >
                                                    {"Add New Role.."}
                                                </Box>
                                            </Stack>
                                        </DialogTitle>
                                        <Divider />
                                        <DialogContent>
                                            <Box sx={{ width: 450 }}>
                                                <Box sx={{ margin: 2 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Department
                                                        </InputLabel>
                                                        <Select
                                                            name="departmentId"
                                                            variant="outlined"
                                                            label="Department"
                                                            value={
                                                                formik.values
                                                                    .departmentId
                                                            }
                                                            onChange={(e) =>
                                                                formik.setFieldValue(
                                                                    "departmentId",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required
                                                        >
                                                            {preData.departments?.map(
                                                                (item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                item.departmentId
                                                                            }
                                                                            key={
                                                                                item.departmentName
                                                                            }
                                                                        >
                                                                            {
                                                                                item.departmentName
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                <Box sx={{ margin: 2 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Designation
                                                        </InputLabel>
                                                        <Select
                                                            name="userTypeId"
                                                            variant="outlined"
                                                            label="Designation"
                                                            value={
                                                                formik.values
                                                                    .userTypeId
                                                            }
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            required
                                                        >
                                                            {preData.designations?.map(
                                                                (item) => {
                                                                    if (
                                                                        item.deptId ===
                                                                        formik
                                                                            .values
                                                                            .departmentId
                                                                    ) {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.userTypeId
                                                                                }
                                                                                key={
                                                                                    item.userTypeId
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.userType
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </DialogContent>
                                        <Divider />
                                        <DialogActions>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#eff4f9",
                                                }}
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                // variant="contained"
                                                size="medium"
                                                type="submit"
                                                value="Save"
                                                onClick={handleDesignation}
                                                sx={{
                                                    marginLeft: 2,
                                                    backgroundColor: "#c8facd",
                                                    color: "#0db05d",
                                                }}
                                            >
                                                <Icon
                                                    icon="mdi:content-save-check-outline"
                                                    width="25"
                                                    height="25"
                                                />
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>

                                <div>
                                    <Dialog
                                        open={openDelete}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseDelete}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle>
                                            {"Delete Role..!"}
                                        </DialogTitle>
                                        <Divider />
                                        <DialogContent>
                                            <Box sx={{ width: 400 }}>
                                                Are you sure you want to delete
                                                this role ?
                                            </Box>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#eff4f9",
                                                }}
                                                onClick={handleCloseDelete}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="outlinrd"
                                                sx={{
                                                    backgroundColor: "#ffe4de",
                                                    color: "#c03530",
                                                }}
                                                onClick={handleDeleteRole}
                                            >
                                                <Icon
                                                    icon="material-symbols:delete-outline-rounded"
                                                    width="20"
                                                    height="20"
                                                />
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </Form>
                        </FormikProvider>
                    </Paper>
                </Box>
            )}
            {!smUp && (
                <Box sx={{ marginTop: 5 }}>
                    <Paper
                        elevation={3}
                        sx={{
                            width: "100%",
                            height: "auto",
                            backgroundColor: "grey.200",
                        }}
                    >
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Typography variant="h4" sx={{ padding: 3 }}>
                                    Add or Remove Roles..
                                </Typography>
                                <Divider sx={{ borderStyle: "dashed" }} />

                                <Box>
                                    <Stack direction={"column"}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    height: 50,
                                                    width: 150,
                                                    margin: 5,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: 0.2,
                                                }}
                                            >
                                                <Button
                                                    onClick={handleClickOpen}
                                                >
                                                    <Icon
                                                        icon="pajamas:file-addition"
                                                        width="35"
                                                        height="35"
                                                    />
                                                    Add Role
                                                </Button>
                                            </Paper>
                                        </Box>
                                        {/* --------------------------------------------------------- */}

                                        <Box>
                                            <Stack direction={"column"}>
                                                {formik.values.userRoles?.map(
                                                    (item, i) => {
                                                        return (
                                                            <Paper
                                                                key={
                                                                    item.userTypeId
                                                                }
                                                                elevation={3}
                                                                sx={{
                                                                    minHeight: 70,
                                                                    width: "auto",
                                                                    margin: 2,
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "center",
                                                                    paddingTop: 1,
                                                                    backgroundColor:
                                                                        "#006c9c69",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="p"
                                                                    fontSize={
                                                                        12
                                                                    }
                                                                    sx={{
                                                                        fontWeight:
                                                                            "bolder",
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        icon="ic:sharp-people-outline"
                                                                        width="30"
                                                                        height="30"
                                                                    />
                                                                    {
                                                                        item.userType
                                                                    }
                                                                </Typography>
                                                                <IconButton
                                                                    // onClick={() =>
                                                                    //     handleRemove(
                                                                    //         item.userTypeId
                                                                    //     )
                                                                    // }
                                                                    onClick={() =>
                                                                        handleClickOpenDelete(
                                                                            item
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        icon="material-symbols:cancel-rounded"
                                                                        width="20"
                                                                        height="20"
                                                                    />
                                                                </IconButton>
                                                            </Paper>
                                                        );
                                                    }
                                                )}
                                            </Stack>
                                        </Box>
                                    </Stack>
                                </Box>

                                <Box
                                    sx={{
                                        padding: 5,
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Button
                                        // variant="contained"
                                        size="medium"
                                        type="submit"
                                        value="Save"
                                        sx={{
                                            marginLeft: 2,
                                            backgroundColor: "#c8facd",
                                            color: "#0db05d",
                                        }}
                                    >
                                        <Icon
                                            icon="material-symbols:add"
                                            width="30"
                                            height="30"
                                        />
                                        Submit Update
                                    </Button>
                                </Box>

                                {/* -----------------------------------------dialogue------------------------- */}

                                <Box>
                                    <Dialog
                                        open={open}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleClose}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle>
                                            {"Add New Role.."}
                                        </DialogTitle>
                                        <Divider />
                                        <DialogContent>
                                            <Box sx={{ width: 280 }}>
                                                <Box sx={{ margin: 2 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Department
                                                        </InputLabel>
                                                        <Select
                                                            name="departmentId"
                                                            variant="outlined"
                                                            label="Department"
                                                            value={
                                                                formik.values
                                                                    .departmentId
                                                            }
                                                            onChange={(e) =>
                                                                formik.setFieldValue(
                                                                    "departmentId",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            required
                                                        >
                                                            {preData.departments?.map(
                                                                (item) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                item.departmentId
                                                                            }
                                                                            key={
                                                                                item.departmentName
                                                                            }
                                                                        >
                                                                            {
                                                                                item.departmentName
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Box>

                                                <Box sx={{ margin: 2 }}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>
                                                            Designation
                                                        </InputLabel>
                                                        <Select
                                                            name="userTypeId"
                                                            variant="outlined"
                                                            label="Designation"
                                                            value={
                                                                formik.values
                                                                    .userTypeId
                                                            }
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            required
                                                        >
                                                            {preData.designations?.map(
                                                                (item) => {
                                                                    if (
                                                                        item.deptId ===
                                                                        formik
                                                                            .values
                                                                            .departmentId
                                                                    ) {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    item.userTypeId
                                                                                }
                                                                                key={
                                                                                    item.userTypeId
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.userType
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                        </DialogContent>
                                        <Divider />
                                        <DialogActions>
                                            <Button
                                                variant="outlined"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={handleDesignation}
                                            >
                                                Save
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Box>

                                <div>
                                    <Dialog
                                        open={openDelete}
                                        TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseDelete}
                                        aria-describedby="alert-dialog-slide-description"
                                    >
                                        <DialogTitle>
                                            {"Delete Role"}
                                        </DialogTitle>
                                        <Divider />
                                        <DialogContent>
                                            Are you sure you want to delete this
                                            role ?
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#eff4f9",
                                                }}
                                                onClick={handleCloseDelete}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="outlinrd"
                                                sx={{
                                                    backgroundColor: "#ffe4de",
                                                    color: "#c03530",
                                                }}
                                                onClick={handleDeleteRole}
                                            >
                                                <Icon
                                                    icon="material-symbols:delete-outline-rounded"
                                                    width="20"
                                                    height="20"
                                                />
                                                Delete
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </div>
                            </Form>
                        </FormikProvider>
                    </Paper>
                </Box>
            )}
        </div>
    );
}
