// component
import Iconify from "../../components/color-utils/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
    {
        title: "dashboard",
        path: "/dashboard",
        icon: getIcon("eva:pie-chart-2-fill"),
    },
    {
        title: "Mytasks",
        path: "/mytasks",
        icon: getIcon("eva:shopping-bag-fill"),
        children: [
            {
                title: "Users",
                path: "/mytasks/employeeDetails",
            },
            {
                title: "Designation",
                path: "/mytasks/designation",
            },
            {
                title: "Department",
                path: "/mytasks/department",
            },
        ],
    },
    {
        title: "My Students",
        path: "/mystudents",
        icon: getIcon("eva:people-fill"),
    },
    {
        title: "Schedules",
        path: "/schedules",
        icon: getIcon("eva:file-text-fill"),
        children: [
            {
                title: "Create",
                path: "/schedules/create-additional-sessions",
            },
            {
                title: "Sessions",
                path: "/schedules/sessions",
            },
        ],
    },
    {
        title: "Notifications",
        path: "/notifications",
        icon: getIcon("ci:notification-active"),
        children: [
            {
                title: "Create",
                path: "/notifications/create",
            },
            {
                title: "Send",
                path: "/notifications/send",
            },
            {
                title: "View",
                path: "/notifications/view",
            },
        ],
    },
    {
        title: "Todo",
        path: "/todo",
        icon: getIcon("icons8:todo-list"),
        children: [
            {
                title: "My Tasks",
                path: "/todo/my-tasks",
            },
            {
                title: "Created Tasks",
                path: "/todo/created-tasks",
            },
            {
                title: "Department Tasks",
                path: "/todo/department-tasks",
            },
            {
                title: "Inward Tasks",
                path: "/todo/inward-tasks",
            },
            {
                title: "Outward Tasks",
                path: "/todo/outward-tasks",
            },
        ],
    },
    {
        title: "Reffer And Earn",
        path: "/reffer",
        icon: getIcon("eva:shopping-bag-fill"),
        children: [
            {
                title: "Add Refferel",
                path: "/reffer/addrefferel",
            },
            {
                title: "Refferel Status",
                path: "/reffer/refferelStatus",
            },
        ],
    },
    {
        title: "Privileges",
        path: "/privileges",
        icon: getIcon("icon-park-outline:permissions"),
    },
    {
        title: "Employee Services",
        path: "/employeeservices",
        icon: getIcon("clarity:employee-solid"),
        children: [
            {
                title: "Attendance",
                path: "/employeeservices/attendance",
            },
            {
                title: "Leave",
                path: "/employeeservices/leave",
            },
        ],
    },
];

export default navConfig;
