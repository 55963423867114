import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import useRoles from "../../Hooks/useRoles";
import {
    AddSchedule,
    CoursesView,
    CreateAdditionalSession,
    EditSchedule,
    EditSchedulePreferrence,
    Sessions,
    StudentSessionShedulesView,
} from "../../views/cro/Schedule";
import AditionalSchedules from "../../views/cro/Schedule/AditionalSchedules";
import PrefferedSchedulesView from "../../views/cro/Schedule/PrefferedSchedulesView";
import Page404 from "../../views/user/Auth/Page404";

const ScheduleRouter = () => {
    const { role } = useSelector((state) => state);
    useRoles("schedules");
    return (
        !role.loading && (
            <Routes>
                {role.navChild.includes("create additional sessions") && (
                    <Route
                        path="create-additional-sessions"
                        element={<CreateAdditionalSession />}
                    />
                )}
                {role.navChild.includes("sessions") && (
                    <Route path="sessions" element={<Sessions />} />
                )}
                {role.permissions.includes("edit sessions") && (
                    <Route
                        path="/session-edit/:id"
                        element={<EditSchedule />}
                    />
                )}
                {role.navChild.includes("preferrences") && (
                    <Route
                        path="/preferrences"
                        element={<StudentSessionShedulesView />}
                    />
                )}
                {role.permissions.includes("add sessions") && (
                    <Route path="/add-sessions" element={<AddSchedule />} />
                )}

                {role.navChild.includes("preferrences") && (
                    <Route
                        path="/student-courses/:adNo"
                        element={<CoursesView />}
                    />
                )}
                {role.navChild.includes("preferrences") && (
                    <Route
                        path="/student-preffered-shedules/:subEId"
                        element={<PrefferedSchedulesView />}
                    />
                )}
                {role.navChild.includes("preferrences") && (
                    <Route
                        path="/aditional-schedules"
                        element={<AditionalSchedules />}
                    />
                )}
                {role.navChild.includes("preferrences") && (
                    <Route
                        path="/edit-schedules-pref/:prefId"
                        element={<EditSchedulePreferrence />}
                    />
                )}
                <Route
                    path="*"
                    element={
                        <Page404
                            message={
                                "you don't have the permission to perform such an action"
                            }
                        />
                    }
                />
            </Routes>
        )
    );
};

export default ScheduleRouter;
