import React, { useEffect, useState } from "react";
import GlobalWrapper from "../../../components/Wrapper/GlobalWrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllObjectives } from "../../../redux/features/assessmentToolSlice";
import CreateUpdateLevel from "../../../components/datagrid/myworkspace/speakpro/level/CreateUpdateLevel";
import { OBJECTIVE_TABLE_HEAD } from "../../../components/datagrid/myworkspace/speakpro/objectives/ObjectiveTableHead";
import CreateUpdateObjective from "../../../components/datagrid/myworkspace/speakpro/objectives/CreateUpdateObjective";

const Objectives = () => {
  const [gridSize, setGridSize] = useState(10);
  const dispatch = useDispatch();
  const { objectives, loading } = useSelector((state) => state.assessmentTool);

  useEffect(() => {
    dispatch(getAllObjectives());
  }, []);

  return (
    <GlobalWrapper title={"Objectives"} actions={<CreateUpdateObjective />}>
      <Paper elevation={3}>
        <Box sx={{ width: "100%", padding: 0 }}>
          <DataGrid
            disableVirtualization
            components={{ Toolbar: CustomGridToolbar }}
            loading={loading}
            autoHeight
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
            }}
            // getRowHeight={() => "auto"}
            rows={objectives}
            columns={OBJECTIVE_TABLE_HEAD}
            getRowId={(row) => row.serialNumber}
            pageSize={gridSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
          />
        </Box>
      </Paper>
    </GlobalWrapper>
  );
};

export default Objectives;
