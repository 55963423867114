import { Box, TextField } from "@mui/material";
import React from "react";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { SQA_LEADS_TABLE_HEAD as columns } from "../../../../components/datagrid/myworkspace/sqa/SqaLeads/SqaLeadsTableHead";
import { Details } from "./Details";

const data = [
    {
        id: 1,
        admissionNo: 7106,
        name: "AZIM JANSHID",
        class: 6,
        course: "Classroom - Short Term",
        publication: "NCERT",
        contactNo: "+919746655359",
        place: "kollam",
        cameOn: "2023-01-02 10:29:02",
        oga: "AYISHA MINNA",
        ogaRemarks:
            "Total amount = 11699 ( admission fee included) , advance payment= 7000, bakki payment one term ayitt pay chyan paranjittund. Ipo class start chyanda Friday exam theerum ath kayinj contact chythal mathi enn paranjittund, ( class nte endh karyum parayan anelum avarude mother nea contact chytha mathi avar Dubai l Ann contact number =+91 81369 75143.	",
        ogaRemarksDetailed: "",
        cro: "PESSY THOMAS",
        croRemarks: "after 6.30pm. any trainer",
        sqaStatus: "Unassigned",
        assignedOn: "2023-05-02",
        assignedJqa: "",
        jqaStatus: "",
        jqaLastUpdatedOn: "",
        croAknowledgedOn: "",
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function SqaLeads() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [value, setValue] = React.useState(null);
    return (
        <div>
            <Wrapper
                title="SQA Leads"
                Content={
                    <>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Basic example"
                                    value={value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>

                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.admissionNo}
                                label="AdNo"
                                name={selectedRows[0]?.name}
                                details={<Details data={selectedRows[0]} />}
                                // actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
