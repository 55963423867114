import React, { useState } from "react";
import FeeStructureTable from "./FeeStructureTable";
import PopUps from "./PopUps";
import { Button } from "@mui/material";

const FeeStructurePopUp = ({ courseEnrollmentId }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const componetProps = {
        courseEnrollmentId: courseEnrollmentId,
    };

    return (
        <div>
            <Button variant="contained" color="success" onClick={handleClick}>
                View
            </Button>

            <PopUps
                open={open}
                setOpen={setOpen}
                componentProps={componetProps}
                component={FeeStructureTable}
            />
        </div>
    );
};

export default FeeStructurePopUp;
